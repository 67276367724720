define("cc-frontend/lib/actions/lesson/LESSON_CARD_ADD_COMMENT", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "date-fns"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_ADD_COMMENT = void 0;
  const LESSON_CARD_ADD_COMMENT = {
    name: "LESSON_CARD_ADD_COMMENT",
    params: {
      cardStackId: "string",
      sectionId: "string",
      comment: {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          text: {
            type: "string"
          },
          createdAt: {
            type: "string"
          },
          commenterId: {
            type: "string"
          },
          commenterName: {
            type: "string"
          }
        }
      }
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).push("embedded", "attributes.comments", payload.comment).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).pull("embedded", "attributes.comments", {
        id: payload.comment.id
      }).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);

      let card = _lodash.default.find(models === null || models === void 0 ? void 0 : models.cardStack.attributes.card, {
        id: payload.sectionId
      });

      let cardTitle = card === null || card === void 0 ? void 0 : card.attributes.title;
      let commenterName = payload === null || payload === void 0 ? void 0 : payload.comment.commenterName;
      let commenterId = payload === null || payload === void 0 ? void 0 : payload.comment.commenterId;
      let context = (0, _defaultLessonContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        cardTitle: cardTitle,
        commenterName,
        commenterId,
        commentText: payload.comment.text,
        commentCreatedAtFormattedDate: (0, _dateFns.format)((0, _dateFns.parseISO)(payload === null || payload === void 0 ? void 0 : payload.comment.createdAt), "EEE, LLL. d, yyyy"),
        commentCreatedAt: payload === null || payload === void 0 ? void 0 : payload.comment.createdAt,
        dateString: models.courseDate.attributes.dateString
      });

      return {
        title: "Added a comment",
        titleForAnalytics: "Comment - Add",
        description: `${commenterName} added a comment to the "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "created")
      };
    }

  };
  _exports.LESSON_CARD_ADD_COMMENT = LESSON_CARD_ADD_COMMENT;
  var _default = LESSON_CARD_ADD_COMMENT;
  _exports.default = _default;
});