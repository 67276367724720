define("cc-frontend/components/planner/perspectives/month/routine", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.sortedCards as |card|}}
    {{#if (eq card.type "card-course-slot")}}
      {{#if (contains card.attributes.courseId @hiddenCourseIds)}}{{else}}
        <Planner::Course::Provider @id={{card.attributes.courseId}} as |course|>
          {{#if course.isFulfilled}}
            <Planner::CardStacks::Lesson::Provider
              @course={{course.content}}
              @dateString={{@dateString}}
              @planbook={{@planbook}}
              @rotationCalendar={{@rotationCalendar}}
              as |courseDate cardStack units cardStackIsFulfilled|
            >
              {{#unless courseDate.hideFromCalendar}}
                {{#if cardStackIsFulfilled}}
                  <Planner::Perspectives::Month::LessonTitle
                    @modelsNeededForFirstEdit={{hash
                      rotationCalendar=@rotationCalendar
                      planbook=@planbook
                      course=course.content
                      dateString=courseDate.dateString
                      cardStack=cardStack
                    }}
                    @isNewLesson={{and courseDate.showLesson courseDate.isNewLesson}}
                    @lesson={{cardStack}}
                    @courseId={{course.id}}
                    @courseColor={{course.attributes.color}}
                    @dateString={{@dateString}}
                    @onLessonFocus={{fn @onLessonFocus @dateString course.content.id}}
                    @courseDate={{courseDate}}
                    @course={{course.content}}
                  />
                {{/if}}
              {{/unless}}
            </Planner::CardStacks::Lesson::Provider>
          {{/if}}
        </Planner::Course::Provider>
      {{/if}}
    {{/if}}
  {{/each}}
  */
  {
    "id": "2xQsWSeZ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"sortedCards\"]]],null]],null],null,[[[41,[28,[37,3],[[30,1,[\"type\"]],\"card-course-slot\"],null],[[[1,\"    \"],[41,[28,[37,4],[[30,1,[\"attributes\",\"courseId\"]],[30,2]],null],[[],[]],[[[1,\"\\n      \"],[8,[39,5],null,[[\"@id\"],[[30,1,[\"attributes\",\"courseId\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3,[\"isFulfilled\"]],[[[1,\"          \"],[8,[39,6],null,[[\"@course\",\"@dateString\",\"@planbook\",\"@rotationCalendar\"],[[30,3,[\"content\"]],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,7,[\"hideFromCalendar\"]]],[[[41,[30,10],[[[1,\"                \"],[8,[39,8],null,[[\"@modelsNeededForFirstEdit\",\"@isNewLesson\",\"@lesson\",\"@courseId\",\"@courseColor\",\"@dateString\",\"@onLessonFocus\",\"@courseDate\",\"@course\"],[[28,[37,9],null,[[\"rotationCalendar\",\"planbook\",\"course\",\"dateString\",\"cardStack\"],[[30,6],[30,5],[30,3,[\"content\"]],[30,7,[\"dateString\"]],[30,8]]]],[28,[37,10],[[30,7,[\"showLesson\"]],[30,7,[\"isNewLesson\"]]],null],[30,8],[30,3,[\"id\"]],[30,3,[\"attributes\",\"color\"]],[30,4],[28,[37,11],[[30,11],[30,4],[30,3,[\"content\",\"id\"]]],null],[30,7],[30,3,[\"content\"]]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"          \"]],[7,8,9,10]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"]],[3]]]]],[1,\"\\n\"]],[]]]],[]],null]],[1]],null]],[\"card\",\"@hiddenCourseIds\",\"course\",\"@dateString\",\"@planbook\",\"@rotationCalendar\",\"courseDate\",\"cardStack\",\"units\",\"cardStackIsFulfilled\",\"@onLessonFocus\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"contains\",\"planner/course/provider\",\"planner/card-stacks/lesson/provider\",\"unless\",\"planner/perspectives/month/lesson-title\",\"hash\",\"and\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/perspectives/month/routine.hbs",
    "isStrictMode": false
  });

  let MonthRoutineComponent = (_dec = Ember.computed("routine.attributes.cards", "routine.attributes._revision"), (_class = class MonthRoutineComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "routine", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "rotationCalendar", void 0);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "hiddenCourseIds", void 0);
    }

    // CPs & Relationships
    get sortedCards() {
      let cards = Ember.get(Ember.get(Ember.get(this, "routine"), "attributes"), "cards");
      return (0, _lodashEs.sortBy)(cards, card => {
        return card.attributes.position;
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "sortedCards", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sortedCards"), _class.prototype)), _class));
  _exports.default = MonthRoutineComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MonthRoutineComponent);
});