define("cc-frontend/components/icons/circle-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! circle check }}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ...attributes
  >
    <g>
      <path
        d="M11,16.999c-0.113,0 -0.224,-0.039 -0.312,-0.109l-5,-4c-0.215,-0.173 -0.25,-0.488 -0.078,-0.703c0.095,-0.12 0.238,-0.188 0.39,-0.188c0.113,0 0.224,0.039 0.312,0.109l4.594,3.675l6.692,-9.081c0.093,-0.127 0.243,-0.203 0.402,-0.203c0.108,0 0.21,0.034 0.297,0.098c0.107,0.079 0.177,0.195 0.198,0.327c0.021,0.132 -0.013,0.264 -0.092,0.372l-7,9.5c-0.08,0.109 -0.203,0.182 -0.338,0.199c-0.021,0.003 -0.043,0.004 -0.065,0.004Z"
      ></path>
      <path
        d="M12,23.999c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,1.33227e-15 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "yvKmgQ7S",
    "block": "[[[11,\"svg\"],[24,\"version\",\"1.1\"],[24,\"viewBox\",\"0 0 24 24\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[17,1],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M11,16.999c-0.113,0 -0.224,-0.039 -0.312,-0.109l-5,-4c-0.215,-0.173 -0.25,-0.488 -0.078,-0.703c0.095,-0.12 0.238,-0.188 0.39,-0.188c0.113,0 0.224,0.039 0.312,0.109l4.594,3.675l6.692,-9.081c0.093,-0.127 0.243,-0.203 0.402,-0.203c0.108,0 0.21,0.034 0.297,0.098c0.107,0.079 0.177,0.195 0.198,0.327c0.021,0.132 -0.013,0.264 -0.092,0.372l-7,9.5c-0.08,0.109 -0.203,0.182 -0.338,0.199c-0.021,0.003 -0.043,0.004 -0.065,0.004Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,23.999c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,1.33227e-15 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/circle-check.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});