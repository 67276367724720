define("cc-frontend/components/planner/menus/google-classroom/date-picker.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "schedule-date__body": "_schedule-date__body_1c2lvm",
    "schedule-date__relative": "_schedule-date__relative_1c2lvm",
    "schedule-date__date": "_schedule-date__date_1c2lvm",
    "schedule-date__date__relative": "_schedule-date__date__relative_1c2lvm",
    "schedule-date__specific": "_schedule-date__specific_1c2lvm",
    "schedule-date__calendar": "_schedule-date__calendar_1c2lvm"
  };
  _exports.default = _default;
});