define("cc-frontend/components/icons/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M.499 24c-.134 0-.259-.052-.354-.146 -.195-.195-.195-.512 0-.707l9.06-9.061 -3.439-3.439c-.585-.585-.585-1.536 0-2.121l.5-.5c.283-.283.66-.439 1.061-.439h3.172c.133 0 .259-.052.353-.146l4.207-4.207c.039-.039.061-.091.061-.147 0-.055-.021-.107-.061-.146 -.47-.471-.47-1.237 0-1.707l.793-.793c.283-.283.659-.438 1.061-.438 .401 0 .778.156 1.061.438l5.586 5.586c.585.585.585 1.536 0 2.121l-.793.792c-.228.228-.531.353-.853.353v0c-.323 0-.626-.125-.854-.353 -.039-.039-.091-.06-.146-.06 -.056 0-.108.021-.146.061l-4.208 4.207c-.093.093-.146.222-.146.354v3.171c0 .401-.156.777-.439 1.061l-.5.5c-.283.283-.659.438-1.061.438 -.402 0-.778-.156-1.061-.438l-3.439-3.439 -9.06 9.061c-.096.092-.221.144-.355.144Zm6.828-15.414c-.133 0-.259.052-.354.146l-.5.5c-.195.195-.195.512 0 .707l7.586 7.586c.094.094.22.146.354.146 .134 0 .259-.052.354-.146l.5-.5c.093-.093.146-.222.146-.354v-3.171c0-.401.156-.777.439-1.061l4.207-4.207c.228-.228.531-.354.854-.354 .32 0 .621.123.847.348 .002.002.005.005.005.005 .04.04.09.061.146.061 .056 0 .107-.021.146-.061l.793-.793c.195-.195.195-.512 0-.707l-5.586-5.586c-.094-.094-.22-.146-.354-.146 -.134 0-.259.052-.354.146l-.789.795c-.081.081-.081.212 0 .293 .47.47.471 1.236.001 1.707l-4.208 4.207c-.283.283-.66.439-1.06.439h-3.173Z"
    ></path>
  </svg>
  */
  {
    "id": "96H2h8bp",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M.499 24c-.134 0-.259-.052-.354-.146 -.195-.195-.195-.512 0-.707l9.06-9.061 -3.439-3.439c-.585-.585-.585-1.536 0-2.121l.5-.5c.283-.283.66-.439 1.061-.439h3.172c.133 0 .259-.052.353-.146l4.207-4.207c.039-.039.061-.091.061-.147 0-.055-.021-.107-.061-.146 -.47-.471-.47-1.237 0-1.707l.793-.793c.283-.283.659-.438 1.061-.438 .401 0 .778.156 1.061.438l5.586 5.586c.585.585.585 1.536 0 2.121l-.793.792c-.228.228-.531.353-.853.353v0c-.323 0-.626-.125-.854-.353 -.039-.039-.091-.06-.146-.06 -.056 0-.108.021-.146.061l-4.208 4.207c-.093.093-.146.222-.146.354v3.171c0 .401-.156.777-.439 1.061l-.5.5c-.283.283-.659.438-1.061.438 -.402 0-.778-.156-1.061-.438l-3.439-3.439 -9.06 9.061c-.096.092-.221.144-.355.144Zm6.828-15.414c-.133 0-.259.052-.354.146l-.5.5c-.195.195-.195.512 0 .707l7.586 7.586c.094.094.22.146.354.146 .134 0 .259-.052.354-.146l.5-.5c.093-.093.146-.222.146-.354v-3.171c0-.401.156-.777.439-1.061l4.207-4.207c.228-.228.531-.354.854-.354 .32 0 .621.123.847.348 .002.002.005.005.005.005 .04.04.09.061.146.061 .056 0 .107-.021.146-.061l.793-.793c.195-.195.195-.512 0-.707l-5.586-5.586c-.094-.094-.22-.146-.354-.146 -.134 0-.259.052-.354.146l-.789.795c-.081.081-.081.212 0 .293 .47.47.471 1.236.001 1.707l-4.208 4.207c-.283.283-.66.439-1.06.439h-3.173Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/pin.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});