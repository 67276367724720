define("cc-frontend/components/planner/card-stacks/lesson-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="lesson-outer">
    <div local-class="lesson">
      <div local-class="course-title" class="loading-bars"></div>
      <div local-class="lesson-title" class="loading-bars"></div>
      <div local-class="cards-area" class="loading-bars"></div>
    </div>
  </div>
  */
  {
    "id": "x0mCB2xb",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"lesson-outer\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson-loading.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"lesson\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson-loading.css\"]]]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"loading-bars \",[28,[37,0],[\"course-title\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson-loading.css\"]]]]]],[12],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"loading-bars \",[28,[37,0],[\"lesson-title\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson-loading.css\"]]]]]],[12],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"loading-bars \",[28,[37,0],[\"cards-area\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson-loading.css\"]]]]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"local-class\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/lesson-loading.hbs",
    "isStrictMode": false
  });

  class LessonLoading extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tagName", "");
    }

  } // @ts-ignore


  _exports.default = LessonLoading;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LessonLoading);
});