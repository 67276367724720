define("cc-frontend/reopenings/route", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.reopen({
    appState: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments); // We keep track of transition counts so we know now to scroll on the first one like when
      // ember rehydrates the app


      this._transitionCount = 0;
      Sentry.configureScope(scope => {
        scope.setTag("isFastBoot", Ember.get(this, "fastboot.isFastBoot"));
      });
    },

    activate: function () {
      var cssClass = this.toCssClass();
      if (cssClass.search(/foundation/).length === 0) return true;

      if (this._transitionCount > 0 && cssClass !== "template-browser" && cssClass !== "foundation-index" && cssClass !== "lesson-template" && window.scrollTo !== undefined) {
        window.scrollTo(0, 0);
      }

      Sentry.addBreadcrumb({
        message: this.routeName,
        category: "route"
      });
      this.incrementProperty("_transitionCount", 1);
    },
    // Maybe get the route state here?
    // afterModel(object, transition){
    // this._super(...arguments)
    // debugger;
    // // these seem like the things to use
    // t.targetName
    // t.queryParams
    // t.intent.url
    // },
    toCssClass: function () {
      return this.routeName.replace(/\./g, "-").dasherize();
    }
  });

  _exports.default = _default;
});