define("cc-frontend/lib/actions/lesson/LESSON_CHANGE_REVIEW_STATUS", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareChangeReviewStatus = prepareChangeReviewStatus;

  function prepareChangeReviewStatus(payload) {
    return {
      name: "LESSON_CHANGE_REVIEW_STATUS",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.reviews", payload.newReviews).patch;

    return [patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.reviews", payload.oldReviews).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let cardStackTitle = models.cardStack ? models.cardStack.attributes.title : null;
    let groupIds = (0, _lodashEs.map)(payload.newReviews, review => review.groupId);
    let context = (0, _lodashEs.assign)({}, (0, _defaultLessonContext.default)(payload, models), {
      groupIds: groupIds
    });
    return {
      title: null,
      titleForAnalytics: "Lesson - Changed Review Status",
      description: `Changed status "${cardStackTitle}"`,
      context: context,
      activity: null
    };
  }
});