define("cc-frontend/modifiers/target-blank", ["exports", "ember-modifier", "lodash-es"], function (_exports, _emberModifier, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, [selector = "a"]) => {
    let childElements = element.querySelectorAll(selector);
    (0, _lodashEs.each)(childElements, element => {
      element.setAttribute("target", "_blank");
    });
  });

  _exports.default = _default;
});