define("cc-frontend/models/course-calendar-date-custom", ["exports", "cc-frontend/lib/structor_2"], function (_exports, _structor_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CourseCalendarDateCustom extends _structor_.default {
    defineStruct() {
      return {
        dateString: null,
        cardStackId: null,
        isForcedOn: null,
        unitStart: [],
        unitEnd: [],
        unitIds: []
      };
    }

  }

  _exports.default = CourseCalendarDateCustom;
});