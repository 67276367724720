define("cc-frontend/lib/find-course-date", ["exports", "cc-frontend/utils/filter-dates/course"], function (_exports, _course) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "findCourseDateCustom", {
    enumerable: true,
    get: function () {
      return _course.findCourseDateCustom;
    }
  });
  Object.defineProperty(_exports, "findCourseDateDefault", {
    enumerable: true,
    get: function () {
      return _course.findCourseDateDefault;
    }
  });
});