define("cc-frontend/components/planner/menus/parts/outline-button", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    local-class="button
      {{if @isLinkStyle "--is-link-style"}}
  
      {{if @noHorizontalMargin "--no-horizontal-margin"}}"
    {{on "click" this.clickButton}}
    ...attributes
  >
    {{yield}}
  </button>
  */
  {
    "id": "Tf3G4Pl/",
    "block": "[[[11,\"button\"],[16,0,[29,[[28,[37,0],[[28,[37,1],[\"button\\n    \",[52,[30,1],\"--is-link-style\"],\"\\n\\n    \",[52,[30,2],\"--no-horizontal-margin\"]],null]],[[\"from\"],[\"cc-frontend/components/planner/menus/parts/outline-button.css\"]]]]]],[17,3],[4,[38,3],[\"click\",[30,0,[\"clickButton\"]]],null],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"@isLinkStyle\",\"@noHorizontalMargin\",\"&attrs\",\"&default\"],false,[\"local-class\",\"concat\",\"if\",\"on\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/menus/parts/outline-button.hbs",
    "isStrictMode": false
  });

  let OutlineButtonComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember._action, _dec(_class = (_class2 = class OutlineButtonComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "onClick", void 0);
    }

    clickButton() {
      if (this.onClick) this.onClick();
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "clickButton", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "clickButton"), _class2.prototype)), _class2)) || _class);
  _exports.default = OutlineButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OutlineButtonComponent);
});