define("cc-frontend/components/planner/card-stacks/cards/parts/plaintext-value", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (includes @permissions "can-edit-plan")}}
    <textarea
      value={{@value}}
      class="tw-resize-none tw-outline-none js-test-scribe-editor test-lesson-value tw-bg-transparent tw-text-xl"
      placeholder={{@placeholder}}
      aria-label={{@placeholder}}
      {{on "input" (pick "target.value" (fn this.updateSection @cardId "value"))}}
      {{on "change" (pick "target.value" (fn this.finishedEditing @cardId "value"))}}
      {{autoresize @value}}
    ></textarea>
  {{else}}
    <div local-class="value --read-only" class="test-lesson-section-value">
      {{{@value}}}
    </div>
  {{/if}}
  */
  {
    "id": "GfWDqHJF",
    "block": "[[[41,[28,[37,1],[[30,1],\"can-edit-plan\"],null],[[[1,\"  \"],[11,\"textarea\"],[16,2,[30,2]],[24,0,\"tw-resize-none tw-outline-none js-test-scribe-editor test-lesson-value tw-bg-transparent tw-text-xl\"],[16,\"placeholder\",[30,3]],[16,\"aria-label\",[30,3]],[4,[38,2],[\"input\",[28,[37,3],[\"target.value\",[28,[37,4],[[30,0,[\"updateSection\"]],[30,4],\"value\"],null]],null]],null],[4,[38,2],[\"change\",[28,[37,3],[\"target.value\",[28,[37,4],[[30,0,[\"finishedEditing\"]],[30,4],\"value\"],null]],null]],null],[4,[38,5],[[30,2]],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[\"test-lesson-section-value \",[28,[37,6],[\"value --read-only\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/plaintext-value.css\"]]]]]],[12],[1,\"\\n    \"],[2,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@permissions\",\"@value\",\"@placeholder\",\"@cardId\"],false,[\"if\",\"includes\",\"on\",\"pick\",\"fn\",\"autoresize\",\"local-class\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/parts/plaintext-value.hbs",
    "isStrictMode": false
  });

  let PlaintextValue = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PlaintextValue extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "lessonEditor", _descriptor, this);
    }

    updateSection(id, prop, value) {
      this.lessonEditor.editCard(this.args.modelsNeededForFirstEdit, this.args.cardStackId, id, prop, value);
    }

    finishedEditing(id, prop, value, originalValue, wordCountChange) {
      this.lessonEditor.finishEditingCard(this.args.modelsNeededForFirstEdit, this.args.cardStackId, id, prop, value, originalValue, wordCountChange);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "lessonEditor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateSection", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateSection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "finishedEditing", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "finishedEditing"), _class.prototype)), _class));
  _exports.default = PlaintextValue;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlaintextValue);
});