define("cc-frontend/components/icons/sad-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <path
      d="M399.283 283.797c-5.1-16.379-14.22-29.995-33.802-37.263s-35.265-2.877-49.868 6.15c-7.615 4.707-10.029 14.019-4.214 22.123 2.049 2.854 5.019 4.717 8.376 5.963 5.059 1.876 10.584 1.678 14.965-1.036 4.778-2.957 10.644-6.526 19.607-3.199 9.01 3.343 11.103 9.839 12.752 15.161 1.551 4.952 5.62 8.724 10.693 10.606 3.357 1.246 6.816 1.774 10.236.938 8.867-2.184 13.916-10.907 11.255-19.443zM196.549 252.685c-14.603-9.027-30.286-13.418-49.868-6.15s-28.702 20.884-33.802 37.263c-2.661 8.536 2.389 17.259 11.255 19.443 3.42.837 6.878.309 10.236-.938 5.073-1.883 9.143-5.654 10.693-10.606 1.649-5.322 3.743-11.818 12.752-15.161 8.964-3.327 14.829.242 19.607 3.199 4.381 2.714 9.907 2.912 14.965 1.036 3.357-1.246 6.327-3.108 8.376-5.963 5.815-8.105 3.401-17.416-4.214-22.123z"
    ></path>
    <path
      d="M256 32C132.288 32 32 132.288 32 256s100.288 224 224 224 224-100.288 224-224S379.712 32 256 32zm135.765 359.765C355.5 428.028 307.285 448 256 448s-99.5-19.972-135.765-56.235C83.972 355.5 64 307.285 64 256s19.972-99.5 56.235-135.765C156.5 83.972 204.715 64 256 64s99.5 19.972 135.765 56.235C428.028 156.5 448 204.715 448 256s-19.972 99.5-56.235 135.765z"
    ></path>
    <path
      d="M349.119 359.176C348.013 357.59 321 320 256 320c-65.261 0-92.014 37.59-93.121 39.176-5.057 7.247-3.283 17.221 3.963 22.278 2.789 1.946 5.982 2.881 9.143 2.881 5.052 0 10.024-2.388 13.135-6.845.176-.251 18.9-25.49 66.88-25.49 48 0 66.703 25.239 66.879 25.49 3.11 4.457 8.081 6.845 13.134 6.845 3.161 0 6.354-.935 9.144-2.881 7.245-5.058 9.02-15.031 3.962-22.278z"
    ></path>
  </svg>
  */
  {
    "id": "pDNppbz5",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M399.283 283.797c-5.1-16.379-14.22-29.995-33.802-37.263s-35.265-2.877-49.868 6.15c-7.615 4.707-10.029 14.019-4.214 22.123 2.049 2.854 5.019 4.717 8.376 5.963 5.059 1.876 10.584 1.678 14.965-1.036 4.778-2.957 10.644-6.526 19.607-3.199 9.01 3.343 11.103 9.839 12.752 15.161 1.551 4.952 5.62 8.724 10.693 10.606 3.357 1.246 6.816 1.774 10.236.938 8.867-2.184 13.916-10.907 11.255-19.443zM196.549 252.685c-14.603-9.027-30.286-13.418-49.868-6.15s-28.702 20.884-33.802 37.263c-2.661 8.536 2.389 17.259 11.255 19.443 3.42.837 6.878.309 10.236-.938 5.073-1.883 9.143-5.654 10.693-10.606 1.649-5.322 3.743-11.818 12.752-15.161 8.964-3.327 14.829.242 19.607 3.199 4.381 2.714 9.907 2.912 14.965 1.036 3.357-1.246 6.327-3.108 8.376-5.963 5.815-8.105 3.401-17.416-4.214-22.123z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M256 32C132.288 32 32 132.288 32 256s100.288 224 224 224 224-100.288 224-224S379.712 32 256 32zm135.765 359.765C355.5 428.028 307.285 448 256 448s-99.5-19.972-135.765-56.235C83.972 355.5 64 307.285 64 256s19.972-99.5 56.235-135.765C156.5 83.972 204.715 64 256 64s99.5 19.972 135.765 56.235C428.028 156.5 448 204.715 448 256s-19.972 99.5-56.235 135.765z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M349.119 359.176C348.013 357.59 321 320 256 320c-65.261 0-92.014 37.59-93.121 39.176-5.057 7.247-3.283 17.221 3.963 22.278 2.789 1.946 5.982 2.881 9.143 2.881 5.052 0 10.024-2.388 13.135-6.845.176-.251 18.9-25.49 66.88-25.49 48 0 66.703 25.239 66.879 25.49 3.11 4.457 8.081 6.845 13.134 6.845 3.161 0 6.354-.935 9.144-2.881 7.245-5.058 9.02-15.031 3.962-22.278z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/sad-outline.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});