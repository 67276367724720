define("cc-frontend/templates/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+yavFIv5",
    "block": "[[[10,0],[14,0,\"application-loading\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"application-loading__logo\"],[12],[1,\"\\n\"],[1,\"    \"],[10,\"img\"],[14,\"src\",\"/assets/images/word-mark-in-strokes-animated.svg\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/application-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});