define("cc-frontend/components/icons/cc-lightbulb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <path
      d="M254.6 480.8c30.8 0 55.8-25 55.8-55.8H198.9c0 30.8 24.9 55.8 55.7 55.8zM254.6 168.3c46.6 0 84.4 37.8 84.4 84.4 0 30.9-16.8 59.3-44 74.1l-16.7 9.1V364.8H231V336l-16.8-9.2c-27.1-14.8-44-43.3-44-74.1.1-46.6 37.9-84.4 84.4-84.4m0-32.3c-64.4 0-116.5 52.2-116.5 116.6 0 44.1 24.6 82.5 60.7 102.3v42h111.6v-42c36.2-19.8 60.7-58.1 60.7-102.3.1-64.4-52.1-116.6-116.5-116.6zM235.7 101.9l-5-77.7h47.9l-5 77.7zM143.2 149.3L89.6 93l36.6-30.7 46.1 62.7zM103 245.1l-77.4-8.7 8.3-47 75.7 18.4zM133.6 344.3l-64.8 43.2L45 346.1l69.7-34.6zM394.6 311.5l69.8 34.6-23.9 41.4-64.8-43.2zM399.7 207.8l75.8-18.4 8.2 47.1-77.3 8.6zM337 125l46.2-62.7L419.8 93 366 149.3z"
    ></path>
  </svg>
  */
  {
    "id": "XA7LmRn/",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M254.6 480.8c30.8 0 55.8-25 55.8-55.8H198.9c0 30.8 24.9 55.8 55.7 55.8zM254.6 168.3c46.6 0 84.4 37.8 84.4 84.4 0 30.9-16.8 59.3-44 74.1l-16.7 9.1V364.8H231V336l-16.8-9.2c-27.1-14.8-44-43.3-44-74.1.1-46.6 37.9-84.4 84.4-84.4m0-32.3c-64.4 0-116.5 52.2-116.5 116.6 0 44.1 24.6 82.5 60.7 102.3v42h111.6v-42c36.2-19.8 60.7-58.1 60.7-102.3.1-64.4-52.1-116.6-116.5-116.6zM235.7 101.9l-5-77.7h47.9l-5 77.7zM143.2 149.3L89.6 93l36.6-30.7 46.1 62.7zM103 245.1l-77.4-8.7 8.3-47 75.7 18.4zM133.6 344.3l-64.8 43.2L45 346.1l69.7-34.6zM394.6 311.5l69.8 34.6-23.9 41.4-64.8-43.2zM399.7 207.8l75.8-18.4 8.2 47.1-77.3 8.6zM337 125l46.2-62.7L419.8 93 366 149.3z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/cc-lightbulb.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});