define("cc-frontend/models/planbook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PLANBOOK_ROLES = void 0;
  const PLANBOOK_ROLES = [{
    id: "VIEWER",
    title: "Can View",
    officialId: "planbook:is-viewer"
  }, {
    id: "COMMENTER",
    title: "Can Comment",
    officialId: "planbook:is-commenter"
  }, {
    id: "EDITOR",
    title: "Can Edit",
    officialId: "planbook:is-editor"
  }, {
    id: "MANAGER",
    title: "Can Edit & Manage",
    officialId: "planbook:is-manager"
  }];
  _exports.PLANBOOK_ROLES = PLANBOOK_ROLES;
});