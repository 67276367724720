define("cc-frontend/components/planner/card-stacks/course-date-annotation.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "date-annotation": "_date-annotation_1hrxrg",
    "on-month": "_on-month_1hrxrg",
    "date-annotation-left": "_date-annotation-left_1hrxrg",
    "date-annotation-right": "_date-annotation-right_1hrxrg",
    "date-annotation--planbook-date": "_date-annotation--planbook-date_1hrxrg",
    "is-off": "_is-off_1hrxrg",
    "is-on": "_is-on_1hrxrg",
    "date-annotation__range": "_date-annotation__range_1hrxrg",
    "date-annotation__title": "_date-annotation__title_1hrxrg",
    "date-annotation__description": "_date-annotation__description_1hrxrg",
    "date-annotation__is-off-notice": "_date-annotation__is-off-notice_1hrxrg",
    "date-annotation__is-off-notice--course": "_date-annotation__is-off-notice--course_1hrxrg",
    "date-annotation__trigger": "_date-annotation__trigger_1hrxrg",
    "special-event-block__menu-trigger": "_special-event-block__menu-trigger_1hrxrg",
    "--has-no-template": "_--has-no-template_1hrxrg"
  };
  _exports.default = _default;
});