define("cc-frontend/components/planner/navs/customize-layout-nav.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "customize-layout-nav__wrapper": "_customize-layout-nav__wrapper_28471a",
    "customize-layout-nav": "_customize-layout-nav_28471a",
    "filter-class-wrapper": "_filter-class-wrapper_28471a",
    "customize-layout-nav__option": "_customize-layout-nav__option_28471a",
    "is-active": "_is-active_28471a",
    "customize-layout-nav__option__text": "_customize-layout-nav__option__text_28471a"
  };
  _exports.default = _default;
});