define("cc-frontend/lib/actions/print-configurator", ["exports", "cc-frontend/lib/actions/create-patch"], function (_exports, _createPatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    PRINT_CONFIGURATOR_SET_OPTION: {
      params: {
        sessionId: "string",
        componentId: "string",
        option: "string",
        value: {
          type: ["string", "boolean", "number"]
        }
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".settings.options." + payload.option, payload.value]);

        if (payload.option === "filterClasses") {
          patch.document.ops.set.push(["attributes." + payload.componentId + ".settings.options.lineUpClasses", payload.value]);
        }

        return [patch];
      }

    },
    PRINT_CONFIGURATOR_SET_LAYOUT: {
      params: {
        sessionId: "string",
        componentId: "string",
        layout: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".settings.layout", payload.layout]);
        return [patch];
      }

    },
    PRINT_CONFIGURATOR_SET_FILE_NAME: {
      params: {
        sessionId: "string",
        componentId: "string",
        fileName: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".settings.output.fileName", payload.fileName]);
        return [patch];
      }

    },
    PRINT_CONFIGURATOR_ADD_COURSE_ID: {
      params: {
        sessionId: "string",
        componentId: "string",
        courseId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.addToSet.push(["attributes." + payload.componentId + ".settings.model.courseIds", payload.courseId]);
        return [patch];
      }

    },
    PRINT_CONFIGURATOR_REMOVE_COURSE_ID: {
      params: {
        sessionId: "string",
        componentId: "string",
        courseId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.pull.push(["attributes." + payload.componentId + ".settings.model.courseIds", payload.courseId]);
        return [patch];
      }

    },
    PRINT_CONFIGURATOR_SET_MEDIUM: {
      params: {
        sessionId: "string",
        componentId: "string",
        medium: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".settings.output.medium", payload.medium]);
        return [patch];
      }

    }
  };
  _exports.default = _default;
});