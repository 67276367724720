define("cc-frontend/components/planner/card-stacks/lesson/header.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-header": "_lesson-header_cgefpq",
    "review": "_review_cgefpq",
    "review-icon": "_review-icon_cgefpq",
    "review-text": "_review-text_cgefpq",
    "review-header": "_review-header_cgefpq",
    "review-subheader": "_review-subheader_cgefpq",
    "units-block": "_units-block_cgefpq",
    "units-block__unit": "_units-block__unit_cgefpq",
    "lesson-block": "_lesson-block_cgefpq",
    "lesson-block__left": "_lesson-block__left_cgefpq",
    "--is-new-lesson": "_--is-new-lesson_cgefpq",
    "lesson-block__right": "_lesson-block__right_cgefpq",
    "lesson-block__move-dots": "_lesson-block__move-dots_cgefpq",
    "lesson-block__text": "_lesson-block__text_cgefpq",
    "lesson-block__text__course-title": "_lesson-block__text__course-title_cgefpq",
    "lesson-block__text__lesson-title-row": "_lesson-block__text__lesson-title-row_cgefpq",
    "lesson-block__text__lesson-title-row__title": "_lesson-block__text__lesson-title-row__title_cgefpq",
    "lesson-block__new-indicator": "_lesson-block__new-indicator_cgefpq",
    "lesson-block__menu-trigger": "_lesson-block__menu-trigger_cgefpq",
    "--has-no-template": "_--has-no-template_cgefpq"
  };
  _exports.default = _default;
});