define("cc-frontend/components/school/reviews/manager/list.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "list": "_list_lien6j",
    "spinner": "_spinner_lien6j",
    "header": "_header_lien6j",
    "body": "_body_lien6j",
    "filter-bar": "_filter-bar_lien6j",
    "filter-category": "_filter-category_lien6j",
    "filter-category__title": "_filter-category__title_lien6j",
    "filter-category__count": "_filter-category__count_lien6j",
    "filter-category--all": "_filter-category--all_lien6j",
    "review": "_review_lien6j",
    "review__left": "_review__left_lien6j",
    "lesson-title": "_lesson-title_lien6j",
    "user-name": "_user-name_lien6j",
    "course-name": "_course-name_lien6j",
    "review__center": "_review__center_lien6j",
    "review__top": "_review__top_lien6j",
    "review__bottom": "_review__bottom_lien6j"
  };
  _exports.default = _default;
});