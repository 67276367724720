define("cc-frontend/instance-initializers/ajax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let ajax = appInstance.lookup("service:ajax");
  }

  var _default = {
    name: "ajax",
    initialize: initialize,
    after: ["session"]
  };
  _exports.default = _default;
});