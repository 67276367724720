define("cc-frontend/templates/admin/fiscal-groups/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/6/BxUap",
    "block": "[[[10,0],[14,0,\"modal-manager-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-manager\"],[12],[1,\"\\n    \"],[10,0],[14,5,\"z-index: 1000\"],[12],[1,\"\\n      \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],null,[[\"@group\",\"@order\"],[[36,2],1]],[[\"default\"],[[[[1,\"\\n          \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"animated-container\",\"fiscal-group-admin-edit\",\"model\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/admin/fiscal-groups/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});