define("cc-frontend/adapters/csp-jurisdiction", ["exports", "lodash", "cc-frontend/config/environment"], function (_exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CspJurisdictionAdapter = (_dec = Ember.inject.service, (_class = class CspJurisdictionAdapter extends Ember.Object {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }

    find(ids = []) {
      return Ember.RSVP.Promise.all(_lodash.default.map(ids, id => {
        return Ember.get(this, "ajax").request(_environment.default.API_ROOT + "/csp/jurisdictions/" + id, {
          headers: {
            "Api-Key": "vZKoJwFB1PTJnozKBSANADc4"
          }
        }).then(response => {
          // Because  the api endpoint isn't returning a json-api doc,
          // we have to munge it into  the right format
          return {
            data: {
              type: "csp-jurisdiction",
              id: response.data.id,
              attributes: response.data
            }
          };
        });
      })).then(array => {
        return ["ok", {
          data: _lodash.default.map(array, el => el.data)
        }];
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CspJurisdictionAdapter;
});