define("cc-frontend/templates/components/planner/menus/day-menu/change-rotation/bodyy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "quuVVlFH",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/templates/components/planner/menus/day-menu/change-rotation/bodyy.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});