define("cc-frontend/components/icons/drag-dots-single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 10 4"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M3.70711,0.292893c0.390524,0.390524 0.390524,1.02369 0,1.41421c-0.390524,0.390524 -1.02369,0.390524 -1.41421,0c-0.390524,-0.390524 -0.390524,-1.02369 -4.44089e-16,-1.41421c0.390524,-0.390524 1.02369,-0.390524 1.41421,-2.22045e-16"
      ></path>
      <path
        d="M7.70711,0.292893c0.390524,0.390524 0.390524,1.02369 0,1.41421c-0.390524,0.390524 -1.02369,0.390524 -1.41421,0c-0.390524,-0.390524 -0.390524,-1.02369 0,-1.41421c0.390524,-0.390524 1.02369,-0.390524 1.41421,-2.22045e-16"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "pxN4Au48",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 10 4\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3.70711,0.292893c0.390524,0.390524 0.390524,1.02369 0,1.41421c-0.390524,0.390524 -1.02369,0.390524 -1.41421,0c-0.390524,-0.390524 -0.390524,-1.02369 -4.44089e-16,-1.41421c0.390524,-0.390524 1.02369,-0.390524 1.41421,-2.22045e-16\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M7.70711,0.292893c0.390524,0.390524 0.390524,1.02369 0,1.41421c-0.390524,0.390524 -1.02369,0.390524 -1.41421,0c-0.390524,-0.390524 -0.390524,-1.02369 0,-1.41421c0.390524,-0.390524 1.02369,-0.390524 1.41421,-2.22045e-16\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/drag-dots-single.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});