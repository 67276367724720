define("cc-frontend/components/planner/menus/add-card/google-classroom-post/body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header-spacer": "_header-spacer_oxc456"
  };
  _exports.default = _default;
});