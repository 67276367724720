define("cc-frontend/components/planner/modals/weekly-order.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_1ckq0z",
    "modal-inner": "_modal-inner_1ckq0z",
    "modal-inner__close": "_modal-inner__close_1ckq0z"
  };
  _exports.default = _default;
});