define("cc-frontend/lib/actions/planbook/date/default-planbook-date-models", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(payload, findFn, userId) {
    let planbook = findFn("planbook", payload.planbook.id);
    let rotationCalendar = findFn("rotation-calendar", planbook.relationships.rotationCalendar.data.id);

    let rotation = _lodash.default.find(rotationCalendar.attributes.rotations, rotation => {
      return rotation.id === payload.annotation.attributes.rotationId;
    });

    return {
      planbook: planbook,
      user: findFn("user", userId),
      rotation: rotation
    };
  }
});