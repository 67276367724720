define("cc-frontend/lib/actions/planbook/PLANBOOK_UPDATE_INTEGRATION", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/planbook/default-planbook-activity", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models"], function (_exports, _patchUpdater, _lodashEs, _defaultPlanbookActivity, _defaultPlanbookContext, _defaultPlanbookModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareUpdatePlanbookIntegration;

  function prepareUpdatePlanbookIntegration(payload) {
    return {
      name: "PLANBOOK_INTEGRATION_UPDATE",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  const patches = function (payload) {
    let patch = _patchUpdater.default.create("planbook", payload.planbookId).set(`attributes.integrations.${payload.integrationName}.${payload.prop}`, payload.value).patch;

    return [patch];
  };

  const undoPatches = null;

  const narrative = function (payload, findFn, userId) {
    let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
    return {
      title: `Updated GC Integration time zone to ${payload.value}`,
      titleForAnalytics: "Planbook - Integrations - Update Integration",
      description: `Updated ${payload.prop} in GC integration.`,
      context: (0, _defaultPlanbookContext.default)(payload, models),
      activity: (0, _defaultPlanbookActivity.default)(payload, models)
    };
  };
});