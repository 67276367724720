define("cc-frontend/services/undo", ["exports", "lodash", "cc-frontend/lib/actions/UNDO_ACTION"], function (_exports, _lodash, _UNDO_ACTION) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Undo = (_dec = Ember.inject.service, _dec2 = Ember.computed("undos.length"), _dec3 = Ember.computed.gt("undos.length", 0), _dec4 = Ember.computed.alias("undos.length"), (_class = class Undo extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "hasUndos", _descriptor2, this);

      _initializerDefineProperty(this, "nextUndoNumber", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      this.undos = [];
      this.undoCount = 0;
    }

    add(action, payload, narrative) {
      if (action.undoPatches && narrative) {
        this.undoCount++;
        let undoPatches = action.undoPatches(payload);
        let undoDoc = createUndoDoc(action.id, undoPatches, narrative, this.undoCount);
        this.undos.unshiftObject(undoDoc);
      }
    }

    addWithPatches(actionId, undoPatches, narrative) {
      if (undoPatches !== null && narrative) {
        this.undoCount++;
        let undoDoc = createUndoDoc(actionId, undoPatches, narrative, this.undoCount);
        this.undos.unshiftObject(undoDoc);
      }
    }

    get nextUndo() {
      return _lodash.default.last(this.undos);
    }

    pop() {
      let undo = this.undos.shiftObject();
      this.store.dispatchAction(_UNDO_ACTION.UNDO_ACTION, {
        patches: undo.attributes.patches,
        originalActionId: undo.attributes.originalActionId,
        originalActionNarrative: _lodash.default.pick(undo.attributes, ["title", "titleForAnalytics", "description", "context", "activity"])
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "nextUndo", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "nextUndo"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasUndos", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "nextUndoNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Undo;

  function createUndoDoc(id, undoPatches, narrative, undoSequenceNumber) {
    return {
      id: id,
      type: "undo",
      attributes: {
        title: narrative.title,
        titleForAnalytics: narrative.titleForAnalytics,
        description: narrative.description,
        context: narrative.context,
        activity: narrative.activity,
        originalActionId: id,
        sequenceNumber: undoSequenceNumber,
        patches: undoPatches
      }
    };
  }
});