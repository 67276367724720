define("cc-frontend/components/planner/menus/date-annotation-menu", ["exports", "cc-frontend/app", "cc-frontend/config/environment", "cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_DELETE"], function (_exports, _app, _environment, _PLANBOOK_DATE_ANNOTATION_DELETE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DS::DropdownMenu @iconColumns="4" @arrowPlacement="TOP_RIGHT">
    <div class="g-dropdown-menu__title" data-test-edit-special-event-menu-title>
      Special Event Menu
    </div>
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/pencil"
      @clickAction={{this.edit}}
      @isAtTopOfMenu={{true}}
      data-test-edit-special-event
    >
      Edit Special Event
    </DS::DropdownMenu::SingleButtonRow>
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/trash-can"
      @clickAction={{this.delete}}
      data-test-remove-special-event
    >
      Remove Special Event
    </DS::DropdownMenu::SingleButtonRow>
  </DS::DropdownMenu>
  */
  {
    "id": "0H+1PjU5",
    "block": "[[[8,[39,0],null,[[\"@iconColumns\",\"@arrowPlacement\"],[\"4\",\"TOP_RIGHT\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"g-dropdown-menu__title\"],[14,\"data-test-edit-special-event-menu-title\",\"\"],[12],[1,\"\\n    Special Event Menu\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[24,\"data-test-edit-special-event\",\"\"]],[[\"@iconName\",\"@clickAction\",\"@isAtTopOfMenu\"],[\"icons/pencil\",[30,0,[\"edit\"]],true]],[[\"default\"],[[[[1,\"\\n    Edit Special Event\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],[[24,\"data-test-remove-special-event\",\"\"]],[[\"@iconName\",\"@clickAction\"],[\"icons/trash-can\",[30,0,[\"delete\"]]]],[[\"default\"],[[[[1,\"\\n    Remove Special Event\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"d-s/dropdown-menu\",\"d-s/dropdown-menu/single-button-row\"]]",
    "moduleName": "cc-frontend/components/planner/menus/date-annotation-menu.hbs",
    "isStrictMode": false
  });

  let PlanbookDateAnnotationMenu = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class PlanbookDateAnnotationMenu extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "annotation", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modalManager", _descriptor2, this);

      _initializerDefineProperty(this, "planbookEditor", _descriptor3, this);
    }

    // Actions
    edit() {
      this.closeMenu();
      let startDate;
      let endDate;
      let dateIs;
      let datesAreEditable;
      let isRange = this.annotation.type === "date-annotation-planbook-custom-range";

      if (this.annotation.type === "date-annotation-planbook-custom-range") {
        startDate = this.annotation.attributes.startDate;
        endDate = this.annotation.attributes.endDate;
        dateIs = "FIXED_START_RANGE";
        datesAreEditable = true;
      } else if (this.annotation.type === "date-annotation-planbook-custom-single") {
        startDate = this.annotation.attributes.dateString;
        endDate = this.annotation.attributes.dateString;
        dateIs = "SINGLE_DATE";
        datesAreEditable = false;
      }

      this.modalManager.openModal("planner/modals/edit-annotation", {
        allowRangeToggle: false,
        dateIs: dateIs,
        datesAreEditable: datesAreEditable,
        description: this.annotation.attributes.description,
        endDate: endDate,
        isRange: isRange,
        minimumStartDate: startDate,
        modalTitle: "Edit Special Event",
        startDate: startDate,
        submitButtonText: "Update",
        title: this.annotation.attributes.title,
        submitAction: ({
          title,
          description,
          endDate,
          startDate
        }) => {
          this.planbookEditor.updateAnnotation({
            oldAnnotation: this.annotation,
            title: title,
            description: description,
            startDate: startDate,
            endDate: endDate,
            planbook: this.planbook
          });
        }
      });
    }

    delete() {
      this.closeMenu();

      let deleteIt = () => {
        this.store.dispatchAction(_PLANBOOK_DATE_ANNOTATION_DELETE.PLANBOOK_DATE_ANNOTATION_DELETE, {
          annotation: this.annotation,
          planbook: this.planbook,
          calledFrom: "planbook-date-annotation-menu"
        });
      }; // Because we can't test swal until we:
      // 1. upgrade the lib to the ember-sweetalert library
      // 2. change all our uses we are stuck doing this terrible thing.
      // Future self, I'm sorry.


      if (_environment.default.environment === "test") {
        deleteIt();
      } else {
        // @ts-ignore
        swal({
          title: "Remove Special Event?",
          text: "This will also remove the yellow note.",
          type: "warning",
          showCancelButton: true
        }).then(deleteIt).catch(error => {
          if (error instanceof Error) (0, _app.handleError)(error);
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "planbookEditor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = PlanbookDateAnnotationMenu;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookDateAnnotationMenu);
});