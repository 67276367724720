define("cc-frontend/transitions/fade-down-slight", ["exports", "cc-frontend/transitions/fade-direction-slight"], function (_exports, _fadeDirectionSlight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(opts = {}) {
    return _fadeDirectionSlight.default.call(this, "y", -1, opts, opts.offset);
  }
});