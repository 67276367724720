define("cc-frontend/templates/user/sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qAq7EpHi",
    "block": "[[[3,\" User Sign \"]],[],false,[]]",
    "moduleName": "cc-frontend/templates/user/sign.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});