define("cc-frontend/components/planner/card-stacks/cards/parts/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    local-class="lesson-section__body"
    class="js-lesson-section__body {{if (match-media "(max-width: 649px)") "--is-mobile"}}"
  >
    {{yield}}
  </div>
  */
  {
    "id": "bvaFUbTD",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"js-lesson-section__body \",[52,[28,[37,2],[\"(max-width: 649px)\"],null],\"--is-mobile\"]],null],\" \",[28,[37,3],[\"lesson-section__body\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/body.css\"]]]]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"concat\",\"if\",\"match-media\",\"local-class\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/parts/body.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});