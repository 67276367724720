define("cc-frontend/components/planner/planbook/create-edit/footers/create.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "main-screen": "_main-screen_xwcb3j _main-screen_1o1brh",
    "nav-box-wrapper": "_nav-box-wrapper_xwcb3j _nav-box--base_1o1brh",
    "EVALUATE_TEMPLATES": "_EVALUATE_TEMPLATES_xwcb3j",
    "ONBOARDING_VIDEO_1": "_ONBOARDING_VIDEO_1_xwcb3j",
    "ONBOARDING_VIDEO_2": "_ONBOARDING_VIDEO_2_xwcb3j",
    "ONBOARDING_VIDEO_3": "_ONBOARDING_VIDEO_3_xwcb3j",
    "ONBOARDING_VIDEO_4": "_ONBOARDING_VIDEO_4_xwcb3j",
    "nav-box__top--inherited": "_nav-box__top--inherited_xwcb3j _nav-box__top_1o1brh",
    "nav-box__top": "_nav-box__top_xwcb3j",
    "nav-box__head": "_nav-box__head_xwcb3j _nav-box__head_1o1brh",
    "--evaluate-templates": "_--evaluate-templates_xwcb3j",
    "nav-box__subhead": "_nav-box__subhead_xwcb3j _nav-box__subhead_1o1brh",
    "--list": "_--list_xwcb3j",
    "nav-box--grid": "_nav-box--grid_xwcb3j",
    "centered-nav-grid": "_centered-nav-grid_xwcb3j",
    "first-screen": "_first-screen_xwcb3j",
    "nav-box--edit-template-grid": "_nav-box--edit-template-grid_xwcb3j",
    "evaluate-button": "_evaluate-button_xwcb3j",
    "back-button": "_back-button_xwcb3j",
    "edit-template": "_edit-template_xwcb3j",
    "nav-box__main": "_nav-box__main_xwcb3j",
    "nav-box__back-arrow": "_nav-box__back-arrow_xwcb3j d-s-shadowed-button",
    "nav-box__next-arrow": "_nav-box__next-arrow_xwcb3j d-s-shadowed-button",
    "nav-box__next-arrow__text": "_nav-box__next-arrow__text_xwcb3j",
    "nav-box__next-arrow-wrapper": "_nav-box__next-arrow-wrapper_xwcb3j",
    "nav-box__back-arrow-wrapper": "_nav-box__back-arrow-wrapper_xwcb3j",
    "nav-box__evaluate-buttons": "_nav-box__evaluate-buttons_xwcb3j",
    "default-focus": "_default-focus_xwcb3j",
    "evaluate-button__icon": "_evaluate-button__icon_xwcb3j",
    "evaluate-button__text": "_evaluate-button__text_xwcb3j",
    "final-screen": "_final-screen_xwcb3j",
    "final-screen__video": "_final-screen__video_xwcb3j",
    "nav-box--final-screen": "_nav-box--final-screen_xwcb3j"
  };
  _exports.default = _default;
});