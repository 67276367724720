define("cc-frontend/components/planner/card-stacks/routine/provider", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Planbook::PlanbookDateCombined
    @planbook={{@planbook}}
    @rotationCalendar={{@rotationCalendar}}
    @dateString={{@dateString}}
    as |planbookDate|
  >
    <Planner::CardStacks::Provider
      @cardStackId={{planbookDate.cardStackId}}
      @cardStackTemplateId={{planbookDate.cardStackTemplateId}}
      @dateString={{planbookDate.dateString}}
      @ownerId={{@planbook.id}}
      @usedAs="routine"
      @defaultPermissions={{@planbook.meta.permissions}}
      @relationships={{array (array "planbook" @planbook.id)}}
      as |routineIsFulfilled routine|
    >
      {{yield planbookDate routine routineIsFulfilled}}
    </Planner::CardStacks::Provider>
  </Planner::Planbook::PlanbookDateCombined>
  */
  {
    "id": "ks/LZe6D",
    "block": "[[[8,[39,0],null,[[\"@planbook\",\"@rotationCalendar\",\"@dateString\"],[[30,1],[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@cardStackId\",\"@cardStackTemplateId\",\"@dateString\",\"@ownerId\",\"@usedAs\",\"@defaultPermissions\",\"@relationships\"],[[30,4,[\"cardStackId\"]],[30,4,[\"cardStackTemplateId\"]],[30,4,[\"dateString\"]],[30,1,[\"id\"]],\"routine\",[30,1,[\"meta\",\"permissions\"]],[28,[37,2],[[28,[37,2],[\"planbook\",[30,1,[\"id\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[18,7,[[30,4],[30,6],[30,5]]],[1,\"\\n  \"]],[5,6]]]]],[1,\"\\n\"]],[4]]]]]],[\"@planbook\",\"@rotationCalendar\",\"@dateString\",\"planbookDate\",\"routineIsFulfilled\",\"routine\",\"&default\"],false,[\"planner/planbook/planbook-date-combined\",\"planner/card-stacks/provider\",\"array\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/routine/provider.hbs",
    "isStrictMode": false
  });

  class RoutineProviderComponent extends _component.default {}

  _exports.default = RoutineProviderComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RoutineProviderComponent);
});