define("cc-frontend/templates/new-group/show/edit/card-stack-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ld0d9x5J",
    "block": "[[[41,[30,1,[\"attributes\",\"isEnabled\"]],[[[41,[28,[37,1],[[30,1,[\"meta\",\"permissions\"]],\"can-manage-group\"],null],[[[1,\"    \"],[8,[39,2],null,[[\"@group\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"tw-text-3xl tw-text-center tw-mt-8 tw-px-12\"],[12],[1,\"\\n      \"],[8,[39,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@stateName\"],[\"group-closed\"]],null],[1,\"\\n\"]],[]]]],[\"@model\"],false,[\"if\",\"includes\",\"school/edit/templates\",\"school/setup-permissions-notice\",\"school/edit/payment/notice-bar\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/edit/card-stack-templates.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});