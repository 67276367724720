define("cc-frontend/components/planner/course/create-edit/onboarding-videos/base.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "rewatch-wrapper": "_rewatch-wrapper_s5h58b",
    "--is-visible": "_--is-visible_s5h58b",
    "rewatch-button": "_rewatch-button_s5h58b"
  };
  _exports.default = _default;
});