define("cc-frontend/components/icons/ellipses-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    <g>
      <path
        d="M12,6c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3Zm0,-5c-1.103,0 -2,0.897 -2,2c0,1.103 0.897,2 2,2c1.103,0 2,-0.897 2,-2c0,-1.103 -0.897,-2 -2,-2Z"
      ></path>
      <path
        d="M12,15c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3Zm0,-5c-1.103,0 -2,0.897 -2,2c0,1.103 0.897,2 2,2c1.103,0 2,-0.897 2,-2c0,-1.103 -0.897,-2 -2,-2Z"
      ></path>
      <path
        d="M12,24c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3Zm0,-5c-1.103,0 -2,0.897 -2,2c0,1.103 0.897,2 2,2c1.103,0 2,-0.897 2,-2c0,-1.103 -0.897,-2 -2,-2Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "rxa6lM5J",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,6c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3Zm0,-5c-1.103,0 -2,0.897 -2,2c0,1.103 0.897,2 2,2c1.103,0 2,-0.897 2,-2c0,-1.103 -0.897,-2 -2,-2Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,15c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3Zm0,-5c-1.103,0 -2,0.897 -2,2c0,1.103 0.897,2 2,2c1.103,0 2,-0.897 2,-2c0,-1.103 -0.897,-2 -2,-2Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,24c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3Zm0,-5c-1.103,0 -2,0.897 -2,2c0,1.103 0.897,2 2,2c1.103,0 2,-0.897 2,-2c0,-1.103 -0.897,-2 -2,-2Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/ellipses-vertical.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});