define("cc-frontend/helpers/has-classification", ["exports", "@glint/environment-ember-loose/ember-component/helper", "lodash-es"], function (_exports, _helper, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasClassification = hasClassification;
  _exports.default = void 0;

  function hasClassification(args) {
    let [model, classification] = args;
    if ((0, _lodashEs.isNil)(model)) return false;
    return (0, _lodashEs.includes)(Ember.get(model, "attributes.classifications"), classification);
  }

  const hasClassificationHelper = (0, _helper.helper)(hasClassification);
  var _default = hasClassificationHelper;
  _exports.default = _default;
});