define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_PASTE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_STANDARDS_PASTE = void 0;
  const LESSON_STANDARDS_PASTE = {
    name: "LESSON_STANDARDS_PASTE",
    params: {
      cardStackId: "string",
      standards: {
        type: "array",
        items: {
          type: "object"
        }
      }
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).addToSet("embedded", "attributes.standards", ["$each", payload.standards]).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).pullAll("embedded", "attributes.standards", JSON.parse(JSON.stringify(payload.standards))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);

      let standards = _lodash.default.map(payload.standards, standard => {
        return standard.code;
      });

      let standardSets = _lodash.default.map(payload.standards, standard => {
        return `${standard.jurisdiction} - ${standard.grades} - ${standard.subject}`;
      });

      let newContext = _lodash.default.assign({}, context, {
        standards,
        standardSets
      });

      let count = Ember.get(payload, "standards.length");
      let noun = count === 1 ? "standard" : "standards";
      let description = `Pasted ${count} ${noun}`;
      return {
        title: "Pasted Standards",
        titleForAnalytics: "Standards - paste",
        context: context,
        activity: (0, _defaultLessonActivity.default)(payload, models),
        description: description
      };
    }

  };
  _exports.LESSON_STANDARDS_PASTE = LESSON_STANDARDS_PASTE;
  var _default = LESSON_STANDARDS_PASTE;
  _exports.default = _default;
});