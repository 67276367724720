define("cc-frontend/services/algolia", ["exports", "algoliasearch"], function (_exports, _algoliasearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Algolia = (_dec = Ember.computed(), _dec2 = Ember.computed(), (_class = class Algolia extends Ember.Service {
    get index() {
      var client = (0, _algoliasearch.default)("O7L4OQENOZ", "10cdfef85b6690cfe4a7bb1f030d8fd9");
      return client.initIndex("common-standards-project");
    }

    get help() {
      var client = (0, _algoliasearch.default)("O7L4OQENOZ", "593c807db82cd9b033bca564730bb871");
      return client.initIndex("common-curriculum-kb");
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "index", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "index"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "help", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "help"), _class.prototype)), _class));
  _exports.default = Algolia;
});