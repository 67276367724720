define("cc-frontend/instance-initializers/body-class-overwrite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(_instance) {
    /**
     * I'm ovwriting this function so we can only show the full route -- e.g.
     * I want "planbook-show-class-settings" instead of
     * "planbook show class-settings planbook-show-class-settings"
     */
    Ember.Route.reopen({
      classNames: [],
      bodyClasses: Object.freeze([]),

      _getRouteDepthClasses() {
        let routeParts = this.routeName.split(".");
        return [routeParts.join("-")];
      }

    });
  }

  var _default = {
    name: "body-class-overwrite",
    initialize: initialize,
    after: ["body-class"]
  };
  _exports.default = _default;
});