define("cc-frontend/templates/print/course-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Fd0Mu6o",
    "block": "[[[10,0],[14,0,\"ccp-page-header\"],[12],[1,\"\\n  \"],[10,2],[14,0,\"ccp-page-header__title\"],[12],[1,\"\\n    \"],[1,[34,0]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"ccp-page-header__date-range\"],[12],[1,\"\\n    \"],[1,[33,1,[\"attributes\",\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[33,1,[\"content\",\"meta\",\"permissions\"]],\"can-view-plan\"],null]],null],[[[1,\"  You don't have permission to print this plan.\\n\"]],[]],[[[41,[33,5,[\"isFulfilled\"]],[[[1,\"  \"],[8,[39,6],null,[[\"@course\",\"@planbook\",\"@rotationCalendar\",\"@endDate\",\"@isPrinting\",\"@startDate\"],[[33,1,[\"content\"]],[33,7,[\"content\"]],[33,5,[\"content\"]],[36,8],true,[36,9]]],null],[1,\"\\n\\n\"]],[]],null]],[]]]],[],false,[\"headerText\",\"course\",\"if\",\"not\",\"includes\",\"rotationCalendar\",\"planner/course/outline-and-detail/outline\",\"planbook\",\"endDate\",\"startDate\"]]",
    "moduleName": "cc-frontend/templates/print/course-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});