define("cc-frontend/components/container-unit", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.unit.isFulfilled}}
    {{yield
      (hash
        lessonIsFulfilled=this.unit.isFulfilled
        lesson=this.unit.content
        course=@course
        planbook=@planbook
        rotationCalendar=@rotationCalendar
        unitInfo=this.unitInfo
      )
      this.state
    }}
  {{else}}
    <DS::ThreeDotSpinner />
  {{/if}}
  */
  {
    "id": "egQ6EGlc",
    "block": "[[[41,[30,0,[\"unit\",\"isFulfilled\"]],[[[1,\"  \"],[18,4,[[28,[37,2],null,[[\"lessonIsFulfilled\",\"lesson\",\"course\",\"planbook\",\"rotationCalendar\",\"unitInfo\"],[[30,0,[\"unit\",\"isFulfilled\"]],[30,0,[\"unit\",\"content\"]],[30,1],[30,2],[30,3],[30,0,[\"unitInfo\"]]]]],[30,0,[\"state\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]]]],[\"@course\",\"@planbook\",\"@rotationCalendar\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"d-s/three-dot-spinner\"]]",
    "moduleName": "cc-frontend/components/container-unit.hbs",
    "isStrictMode": false
  });

  let ContainerUnit = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed("unitId"), _dec4 = Ember.computed("course.attributes.calendar.version", "unitId"), _dec5 = Ember.computed("course.id", "dateString"), (_class = class ContainerUnit extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);
    }

    get unit() {
      class ObjectPromiseProxy extends Ember.ObjectProxy.extend(Ember.PromiseProxyMixin) {}

      let promise = this.store.find("card-stack", this.unitId);
      return ObjectPromiseProxy.create({
        promise: promise
      });
    }

    get unitInfo() {
      return _lodash.default.find(Ember.get(this, "course.attributes.calendar.units"), {
        id: this.unitId
      });
    }

    get componentId() {
      return "single-unit-" + Ember.get(this, "course.id") + "-" + this.unitId;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appState", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "unit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "unit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unitInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "unitInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "componentId", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "componentId"), _class.prototype)), _class));
  _exports.default = ContainerUnit;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerUnit);
});