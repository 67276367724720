define("cc-frontend/lib/actions/planbook/PLANBOOK_CHANGE_PERMISSION", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/planbook/default-planbook-activity", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models"], function (_exports, _patchUpdater, _defaultPlanbookActivity, _defaultPlanbookContext, _defaultPlanbookModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_CHANGE_PERMISSION = void 0;
  const PLANBOOK_CHANGE_PERMISSION = {
    name: "PLANBOOK_CHANGE_PERMISSION",
    params: {
      planbookId: "string",
      collaboratorId: "string",
      roles: "array",
      // this isn't sent over when dispatched, but neither is collaboratorId, or if so, it's dispatched in a funky way
      originalRoles: "array"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId, "attributes.collaborators", {
        id: payload.permissionId
      }).set("embedded", "attributes.roles", payload.roles).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId, "attributes.collaborators", {
        id: payload.permissionId
      }).set("embedded", "attributes.roles", payload.originalRoles).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
      return {
        title: "Changed Planbook Permissions for Group",
        titleForAnalytics: "Planbook - Change Permission",
        description: ``,
        context: (0, _defaultPlanbookContext.default)(payload, models),
        activity: (0, _defaultPlanbookActivity.default)(payload, models)
      };
    }

  };
  _exports.PLANBOOK_CHANGE_PERMISSION = PLANBOOK_CHANGE_PERMISSION;
  var _default = PLANBOOK_CHANGE_PERMISSION;
  _exports.default = _default;
});