define("cc-frontend/services/stripe", ["exports", "cc-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Stripe extends Ember.Service {
    init() {
      super.init(...arguments);

      if (typeof StripeCheckout !== "undefined") {
        this.handler = StripeCheckout.configure({
          key: _environment.default.STRIPE_PK,
          name: "Common Curriculum",
          allowRememberMe: false,
          image: "https://s3.amazonaws.com/stripe-uploads/acct_1slDKG2dCO1jWXaYWC9tmerchant-icon-1439149859517-logo-300-square.png"
        });
      } else {
        this.handler = null;
      }
    }

  }

  _exports.default = Stripe;
});