define("cc-frontend/components/d-s/full-screen-menu", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Portal @target="body-top">
    <div local-class="full-screen-menu__backdrop" class="fadeIn" {{on "click" @closeMenu}}></div>
  
    <div local-class="full-screen-close-space" class="fadeIn" {{on "click" @closeMenu}}>
      <div local-class="full-screen-close-btn" class="slideout__header__close">
        <Icons::IosCloseEmpty />
      </div>
    </div>
    <div
      local-class="full-screen-menu"
      class="fadeIn"
      {{did-insert this.addBodyClass}}
      {{will-destroy this.removeBodyClass}}
    >
      {{yield}}
    </div>
  </Portal>
  */
  {
    "id": "2WqdE9fA",
    "block": "[[[8,[39,0],null,[[\"@target\"],[\"body-top\"]],[[\"default\"],[[[[1,\"\\n  \"],[11,0],[16,0,[29,[\"fadeIn \",[28,[37,1],[\"full-screen-menu__backdrop\"],[[\"from\"],[\"cc-frontend/components/d-s/full-screen-menu.css\"]]]]]],[4,[38,2],[\"click\",[30,1]],null],[12],[13],[1,\"\\n\\n  \"],[11,0],[16,0,[29,[\"fadeIn \",[28,[37,1],[\"full-screen-close-space\"],[[\"from\"],[\"cc-frontend/components/d-s/full-screen-menu.css\"]]]]]],[4,[38,2],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"slideout__header__close \",[28,[37,1],[\"full-screen-close-btn\"],[[\"from\"],[\"cc-frontend/components/d-s/full-screen-menu.css\"]]]]]],[12],[1,\"\\n      \"],[8,[39,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,0],[16,0,[29,[\"fadeIn \",[28,[37,1],[\"full-screen-menu\"],[[\"from\"],[\"cc-frontend/components/d-s/full-screen-menu.css\"]]]]]],[4,[38,4],[[30,0,[\"addBodyClass\"]]],null],[4,[38,5],[[30,0,[\"removeBodyClass\"]]],null],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeMenu\",\"&default\"],false,[\"portal\",\"local-class\",\"on\",\"icons/ios-close-empty\",\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "cc-frontend/components/d-s/full-screen-menu.hbs",
    "isStrictMode": false
  });

  let FullScreenMenuComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class FullScreenMenuComponent extends _component.default {
    addBodyClass() {
      if (document && document.body) {
        document.body.classList.add("full-screen-modal-open");
      }
    }

    removeBodyClass() {
      if (document && document.body) {
        document.body.classList.remove("full-screen-modal-open");
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "addBodyClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addBodyClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeBodyClass", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "removeBodyClass"), _class.prototype)), _class));
  _exports.default = FullScreenMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FullScreenMenuComponent);
});