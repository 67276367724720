define("cc-frontend/components/planner/card-stacks/cards/parts/title.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "title": "_title_d9i22d"
  };
  _exports.default = _default;
});