define("cc-frontend/components/planner/google-classroom/parts/find-or-create-class-setting", ["exports", "@glint/environment-ember-loose/glimmer-component", "lodash-es"], function (_exports, _glimmerComponent, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.classSetting}}
  */
  {
    "id": "MD2v/8PG",
    "block": "[[[18,1,[[30,0,[\"classSetting\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/google-classroom/parts/find-or-create-class-setting.hbs",
    "isStrictMode": false
  });

  class GoogleClassroomFindClassSetting extends _glimmerComponent.default {
    get classSetting() {
      let existingClassSetting = (0, _lodashEs.find)(this.args.classSettings, setting => Ember.get(setting, "googleClassId") === Ember.get(this.args.googleClass, "id"));

      if ((0, _lodashEs.isNil)(existingClassSetting)) {
        return {
          googleClassId: this.args.googleClass.id,
          isEnabled: false,
          scheduledAtTime: {
            hour: 6,
            minute: 0
          },
          dueAt: {
            date: "SAME_DAY",
            hour: 23,
            minute: 59
          }
        };
      } else {
        return existingClassSetting;
      }
    }

  }

  _exports.default = GoogleClassroomFindClassSetting;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GoogleClassroomFindClassSetting);
});