define("cc-frontend/components/icons/home-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M12,14.502c-2.068,0 -3.75,-1.682 -3.75,-3.75c0,-2.068 1.682,-3.75 3.75,-3.75c2.068,0 3.75,1.682 3.75,3.75c0,2.068 -1.682,3.75 -3.75,3.75Zm0,-6.5c-1.516,0 -2.75,1.234 -2.75,2.75c0,1.516 1.234,2.75 2.75,2.75c1.516,0 2.75,-1.234 2.75,-2.75c0,-1.516 -1.234,-2.75 -2.75,-2.75Z"
      ></path>
      <path
        d="M17.056,19.502c-0.209,0 -0.398,-0.132 -0.47,-0.329c-0.44,-1.21 -1.326,-2.176 -2.493,-2.72c-0.646,-0.301 -1.331,-0.454 -2.034,-0.454c-0.561,0 -1.117,0.099 -1.653,0.293c-1.342,0.489 -2.392,1.539 -2.881,2.881c-0.071,0.197 -0.26,0.329 -0.47,0.329c-0.058,0 -0.116,-0.01 -0.171,-0.03c-0.126,-0.046 -0.226,-0.138 -0.282,-0.259c-0.056,-0.121 -0.062,-0.257 -0.017,-0.382c0.59,-1.621 1.858,-2.888 3.479,-3.478c0.645,-0.235 1.316,-0.354 1.994,-0.354c0.85,0 1.676,0.185 2.457,0.548c1.409,0.657 2.478,1.824 3.01,3.285c0.046,0.125 0.04,0.261 -0.017,0.382c-0.056,0.121 -0.157,0.213 -0.282,0.259c-0.054,0.018 -0.111,0.029 -0.17,0.029Z"
      ></path>
      <path
        d="M1.5,24.002c-0.827,0 -1.5,-0.673 -1.5,-1.5v-13.7c0,-0.491 0.24,-0.951 0.643,-1.232l10.5,-7.3c0.252,-0.175 0.548,-0.267 0.857,-0.267c0.309,-2.60209e-18 0.605,0.093 0.857,0.268l10.5,7.3c0.403,0.28 0.643,0.74 0.643,1.231v13.7c0,0.827 -0.673,1.5 -1.5,1.5h-21Zm10.5,-23c-0.103,1.11022e-15 -0.202,0.031 -0.286,0.089l-10.5,7.3c-0.134,0.094 -0.214,0.247 -0.214,0.411v13.7c0,0.276 0.224,0.5 0.5,0.5h21c0.276,0 0.5,-0.224 0.5,-0.5v-13.7c0,-0.164 -0.08,-0.317 -0.215,-0.411l-10.5,-7.3c-0.083,-0.058 -0.182,-0.089 -0.285,-0.089Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "n5fFxpca",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,14.502c-2.068,0 -3.75,-1.682 -3.75,-3.75c0,-2.068 1.682,-3.75 3.75,-3.75c2.068,0 3.75,1.682 3.75,3.75c0,2.068 -1.682,3.75 -3.75,3.75Zm0,-6.5c-1.516,0 -2.75,1.234 -2.75,2.75c0,1.516 1.234,2.75 2.75,2.75c1.516,0 2.75,-1.234 2.75,-2.75c0,-1.516 -1.234,-2.75 -2.75,-2.75Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M17.056,19.502c-0.209,0 -0.398,-0.132 -0.47,-0.329c-0.44,-1.21 -1.326,-2.176 -2.493,-2.72c-0.646,-0.301 -1.331,-0.454 -2.034,-0.454c-0.561,0 -1.117,0.099 -1.653,0.293c-1.342,0.489 -2.392,1.539 -2.881,2.881c-0.071,0.197 -0.26,0.329 -0.47,0.329c-0.058,0 -0.116,-0.01 -0.171,-0.03c-0.126,-0.046 -0.226,-0.138 -0.282,-0.259c-0.056,-0.121 -0.062,-0.257 -0.017,-0.382c0.59,-1.621 1.858,-2.888 3.479,-3.478c0.645,-0.235 1.316,-0.354 1.994,-0.354c0.85,0 1.676,0.185 2.457,0.548c1.409,0.657 2.478,1.824 3.01,3.285c0.046,0.125 0.04,0.261 -0.017,0.382c-0.056,0.121 -0.157,0.213 -0.282,0.259c-0.054,0.018 -0.111,0.029 -0.17,0.029Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M1.5,24.002c-0.827,0 -1.5,-0.673 -1.5,-1.5v-13.7c0,-0.491 0.24,-0.951 0.643,-1.232l10.5,-7.3c0.252,-0.175 0.548,-0.267 0.857,-0.267c0.309,-2.60209e-18 0.605,0.093 0.857,0.268l10.5,7.3c0.403,0.28 0.643,0.74 0.643,1.231v13.7c0,0.827 -0.673,1.5 -1.5,1.5h-21Zm10.5,-23c-0.103,1.11022e-15 -0.202,0.031 -0.286,0.089l-10.5,7.3c-0.134,0.094 -0.214,0.247 -0.214,0.411v13.7c0,0.276 0.224,0.5 0.5,0.5h21c0.276,0 0.5,-0.224 0.5,-0.5v-13.7c0,-0.164 -0.08,-0.317 -0.215,-0.411l-10.5,-7.3c-0.083,-0.058 -0.182,-0.089 -0.285,-0.089Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/home-user.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});