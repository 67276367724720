define("cc-frontend/styles/d-s-global/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "d-s-shadowed-button": "_d-s-shadowed-button_tp2wh1",
    "--med": "_--med_tp2wh1",
    "--lg": "_--lg_tp2wh1",
    "--link-blue": "_--link-blue_tp2wh1",
    "g-menu-header-bar__back-arrow": "_g-menu-header-bar__back-arrow_tp2wh1"
  };
  _exports.default = _default;
});