define("cc-frontend/components/icons/book-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M3.5 24C2.121 24 1 22.87 1 21.5v-19C1 1.121 2.121 0 3.5 0h17c.27 0 .5.224.5.5s-.23.5-.5.5h-17C2.673 1 2 1.673 2 2.5 2 3.327 2.673 4 3.5 4h16c.82 0 1.5.673 1.5 1.5v6.526c0 .27-.23.5-.5.5 -.28 0-.5-.23-.5-.5V5.5c0-.28-.23-.5-.5-.5h-16c-.55 0-1.072-.18-1.5-.51v17c0 .82.673 1.5 1.5 1.5h8.581c.27 0 .5.22.5.5 0 .27-.23.5-.5.5H3.5Z"
      ></path>
      <path
        d="M3.5 3c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h16c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-16Z"
      ></path>
      <path
        d="M22.5 24c-.14 0-.26-.06-.36-.15l-2.94-2.94c-.9.73-2.02 1.14-3.18 1.14 -2.78 0-5.029-2.26-5.029-5.029 0-2.78 2.25-5.029 5.03-5.029 2.77 0 5.029 2.25 5.029 5.029 0 1.16-.41 2.28-1.15 3.18l2.93 2.93c.09.09.14.21.14.35 0 .13-.06.25-.15.35 -.1.09-.22.14-.36.14Zm-6.471-11C13.799 13 12 14.8 12 17.029c0 2.22 1.8 4.029 4.029 4.029 2.22 0 4.029-1.81 4.029-4.029 0-2.23-1.81-4.029-4.029-4.029Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "YEuE1MXK",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3.5 24C2.121 24 1 22.87 1 21.5v-19C1 1.121 2.121 0 3.5 0h17c.27 0 .5.224.5.5s-.23.5-.5.5h-17C2.673 1 2 1.673 2 2.5 2 3.327 2.673 4 3.5 4h16c.82 0 1.5.673 1.5 1.5v6.526c0 .27-.23.5-.5.5 -.28 0-.5-.23-.5-.5V5.5c0-.28-.23-.5-.5-.5h-16c-.55 0-1.072-.18-1.5-.51v17c0 .82.673 1.5 1.5 1.5h8.581c.27 0 .5.22.5.5 0 .27-.23.5-.5.5H3.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3.5 3c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h16c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-16Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M22.5 24c-.14 0-.26-.06-.36-.15l-2.94-2.94c-.9.73-2.02 1.14-3.18 1.14 -2.78 0-5.029-2.26-5.029-5.029 0-2.78 2.25-5.029 5.03-5.029 2.77 0 5.029 2.25 5.029 5.029 0 1.16-.41 2.28-1.15 3.18l2.93 2.93c.09.09.14.21.14.35 0 .13-.06.25-.15.35 -.1.09-.22.14-.36.14Zm-6.471-11C13.799 13 12 14.8 12 17.029c0 2.22 1.8 4.029 4.029 4.029 2.22 0 4.029-1.81 4.029-4.029 0-2.23-1.81-4.029-4.029-4.029Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/book-search.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});