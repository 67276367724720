define("cc-frontend/components/modal-manager", ["exports", "@glimmer/component", "ember-animated/easings/cosine", "ember-animated/motions/move", "ember-animated/motions/opacity", "lodash-es"], function (_exports, _component, _cosine, _move, _opacity, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="modal-manager-wrapper modal-manager-wrapper--absolute-top"
    {{did-update this.updateOpenedModals this.modalManager.openedModals}}
    {{did-update this.checkOpenedModals @openedModals}}
  >
    {{#each this.modalManager.modals as |modal|}}
      <div style={{html-safe (concat "z-index: " (mult 1000 modal.order))}}>
        {{!-- TODO BUG  willDestroy is not called at right time for animated-if when used within {{each}} --}}
        {{#if (eq modal.modalType "FULL_SCREEN")}}
          {{#animated-if modal.isVisible use=this.fullScreenTransition}}
            {{!-- {{#if modal.isVisible}} --}}
            {{component
              modal.modalName
              model=modal.model
              order=modal.order
              closeModal=(fn this.closeModal modal.modalName)
            }}
            {{!-- {{/if}} --}}
          {{/animated-if}}
        {{else}}
          {{#animated-if modal.isVisible use=this.slideoutTransition}}
            {{!-- {{#if modal.isVisible}} --}}
            {{component
              modal.modalName
              model=modal.model
              order=modal.order
              closeModal=(fn this.closeModal modal.modalName)
            }}
            {{!-- {{/if}} --}}
          {{/animated-if}}
        {{/if}}
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "xCsz1l9u",
    "block": "[[[11,0],[24,0,\"modal-manager-wrapper modal-manager-wrapper--absolute-top\"],[4,[38,0],[[30,0,[\"updateOpenedModals\"]],[30,0,[\"modalManager\",\"openedModals\"]]],null],[4,[38,0],[[30,0,[\"checkOpenedModals\"]],[30,1]],null],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"modalManager\",\"modals\"]]],null]],null],null,[[[1,\"    \"],[10,0],[15,5,[28,[37,3],[[28,[37,4],[\"z-index: \",[28,[37,5],[1000,[30,2,[\"order\"]]],null]],null]],null]],[12],[1,\"\\n\"],[41,[28,[37,7],[[30,2,[\"modalType\"]],\"FULL_SCREEN\"],null],[[[6,[39,8],[[30,2,[\"isVisible\"]]],[[\"use\"],[[30,0,[\"fullScreenTransition\"]]]],[[\"default\"],[[[[1,\"          \"],[46,[30,2,[\"modalName\"]],null,[[\"model\",\"order\",\"closeModal\"],[[30,2,[\"model\"]],[30,2,[\"order\"]],[28,[37,10],[[30,0,[\"closeModal\"]],[30,2,[\"modalName\"]]],null]]],null],[1,\"\\n\"]],[]]]]]],[]],[[[6,[39,8],[[30,2,[\"isVisible\"]]],[[\"use\"],[[30,0,[\"slideoutTransition\"]]]],[[\"default\"],[[[[1,\"          \"],[46,[30,2,[\"modalName\"]],null,[[\"model\",\"order\",\"closeModal\"],[[30,2,[\"model\"]],[30,2,[\"order\"]],[28,[37,10],[[30,0,[\"closeModal\"]],[30,2,[\"modalName\"]]],null]]],null],[1,\"\\n\"]],[]]]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"@openedModals\",\"modal\"],false,[\"did-update\",\"each\",\"-track-array\",\"html-safe\",\"concat\",\"mult\",\"if\",\"eq\",\"animated-if\",\"component\",\"fn\"]]",
    "moduleName": "cc-frontend/components/modal-manager.hbs",
    "isStrictMode": false
  });

  let ModalManagerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ModalManagerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modalManager", _descriptor2, this);

      _defineProperty(this, "slideoutTransition", function* ({
        insertedSprites,
        keptSprites,
        removedSprites
      }) {
        insertedSprites.forEach(sprite => {
          sprite.startAtPixel({
            x: window.innerWidth
          });
          (0, _opacity.fadeIn)(sprite, {
            easing: _cosine.easeOut,
            duration: 150
          });
          (0, _move.default)(sprite, {
            easing: _cosine.easeOut,
            duration: 150
          });
        });
        keptSprites.forEach(_move.default);
        removedSprites.forEach(sprite => {
          sprite.endAtPixel({
            x: window.innerWidth
          });
          (0, _move.default)(sprite, {
            easing: _cosine.easeIn,
            duration: 150
          });
          (0, _opacity.fadeOut)(sprite, {
            easing: _cosine.easeIn,
            duration: 150
          });
        });
      });

      _defineProperty(this, "fullScreenTransition", function* ({
        insertedSprites,
        removedSprites
      }) {
        insertedSprites.forEach(sprite => {
          (0, _opacity.fadeIn)(sprite, {
            easing: _cosine.easeOut,
            duration: 150
          });
        });
        removedSprites.forEach(sprite => {
          (0, _opacity.fadeOut)(sprite, {
            easing: _cosine.easeIn,
            duration: 150
          });
        });
      });
    }

    closeModal(modalName) {
      this.modalManager.closeModal(modalName);
    }

    updateOpenedModals() {
      this.args.updateOpenedModals(this.modalManager.openedModals);
    }

    checkOpenedModals() {
      let closedModals = (0, _lodashEs.difference)(this.modalManager.openedModals, this.args.openedModals);
      (0, _lodashEs.forEach)(closedModals, modalName => this.modalManager.closeModal(modalName));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOpenedModals", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateOpenedModals"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkOpenedModals", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "checkOpenedModals"), _class.prototype)), _class));
  _exports.default = ModalManagerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModalManagerComponent);
});