define("cc-frontend/utils/planbook/generate-calendar", ["exports", "lodash-es/map", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/object-id-gen"], function (_exports, _map, _datesOffCalculator, _objectIdGen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generatePlanbookCalendar = generatePlanbookCalendar;

  function generatePlanbookCalendar(opts) {
    let semester = opts.rotationCalendarAttributes.semesters[0];
    let dates = (0, _map.default)(opts.rotationCalendarAttributes.rotations, rotation => {
      return {
        id: `${semester.id}:${rotation.id}`,
        type: "planbook-date-default",
        attributes: {
          cardStackId: _objectIdGen.default.create(),
          semesterId: semester.id,
          rotationId: rotation.id
        }
      };
    });
    let datesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(opts.dateAnnotations);
    return {
      dates,
      dateAnnotations: opts.dateAnnotations,
      datesOff,
      version: 1
    };
  }
});