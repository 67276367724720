define("cc-frontend/lib/action-creators/app-state", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.constructComponentStateId = constructComponentStateId;
  _exports.payloadForComponent = payloadForComponent;

  function constructComponentStateId(component, componentId) {
    return component.toString().split("@")[1].split(":")[1] + "-" + componentId;
  }

  function payloadForComponent(appState, componentId, payload) {
    return _lodash.default.merge({}, payload, {
      sessionId: Ember.get(appState, "id"),
      componentId: componentId
    });
  }
});