define("cc-frontend/components/d-s/dropdown-menu/single-button-row", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    disabled={{@isDisabled}}
    local-class="single-button-row
      {{if @isAtTopOfMenu "--is-at-top-of-menu"}}
  
      {{if @isSecondary "single-button-row--is-secondary"}}"
    {{on "click" this.onClick}}
    ...attributes
  >
    {{#if @iconName}}
      <div local-class="single-button-row__icon">
        {{component @iconName}}
      </div>
    {{/if}}
    <div local-class="single-button-row__text">
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "I/r2aH3o",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[[28,[37,1],[\"single-button-row\\n    \",[52,[30,1],\"--is-at-top-of-menu\"],\"\\n\\n    \",[52,[30,2],\"single-button-row--is-secondary\"]],null]],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/single-button-row.css\"]]]]]],[16,\"disabled\",[30,3]],[17,4],[4,[38,3],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,0],[15,0,[29,[[28,[37,0],[\"single-button-row__icon\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/single-button-row.css\"]]]]]],[12],[1,\"\\n      \"],[46,[30,5],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[29,[[28,[37,0],[\"single-button-row__text\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/single-button-row.css\"]]]]]],[12],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isAtTopOfMenu\",\"@isSecondary\",\"@isDisabled\",\"&attrs\",\"@iconName\",\"&default\"],false,[\"local-class\",\"concat\",\"if\",\"on\",\"component\",\"yield\"]]",
    "moduleName": "cc-frontend/components/d-s/dropdown-menu/single-button-row.hbs",
    "isStrictMode": false
  });

  let SingleButtonRow = (_dec = Ember._action, (_class = class SingleButtonRow extends _glimmerComponent.default {
    onClick() {
      if (this.args.clickAction) {
        this.args.clickAction();
      }

      if (this.args.closeMenu) {
        this.args.closeMenu();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = SingleButtonRow;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SingleButtonRow);
});