define("cc-frontend/components/planner/modals/google-classroom.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "template-sidebar": "_template-sidebar_1bwqkq",
    "header": "_header_1bwqkq",
    "header__close": "_header__close_1bwqkq",
    "header__text": "_header__text_1bwqkq",
    "header__text-large": "_header__text-large_1bwqkq",
    "header__text-medium": "_header__text-medium_1bwqkq",
    "header__secondary-text": "_header__secondary-text_1bwqkq",
    "header__secondary-text__link": "_header__secondary-text__link_1bwqkq",
    "header__text-small": "_header__text-small_1bwqkq",
    "header__text-small__link": "_header__text-small__link_1bwqkq",
    "trial-notice": "_trial-notice_1bwqkq",
    "body": "_body_1bwqkq",
    "body__time-zone": "_body__time-zone_1bwqkq",
    "error-box": "_error-box_1bwqkq",
    "error-header": "_error-header_1bwqkq",
    "error-description": "_error-description_1bwqkq",
    "sign-in-btn": "_sign-in-btn_1bwqkq",
    "schedule-date": "_schedule-date_1bwqkq",
    "schedule-time": "_schedule-time_1bwqkq",
    "select-time-zone": "_select-time-zone_1bwqkq",
    "schedule-button": "_schedule-button_1bwqkq"
  };
  _exports.default = _default;
});