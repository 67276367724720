define("cc-frontend/models/card-stack-attributes", ["exports", "cc-frontend/lib/structor"], function (_exports, _structor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CardStackAttributes extends _structor.default {
    defineStruct() {
      this.field("title", null);
      this.field("subtitle", null);
      this.field("cards", []);
      this.field("usedAs", "lesson");
      this.field("parentCardStack", {});
      this.field("_revision", 0);
    }

  }

  _exports.default = CardStackAttributes;
});