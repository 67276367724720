define("cc-frontend/lib/actions/user", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/create-patch"], function (_exports, _patchUpdater, _createPatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    USER_CHANGE_NAME: {
      name: "USER_CHANGE_NAME",
      params: {
        userId: "string",
        field: "string",
        value: "string"
      },

      patches(payload) {
        // prevent arbitrary field settings
        let field = payload.field === "firstName" ? "firstName" : "lastName";

        let patch = _patchUpdater.default.create("user", payload.userId).set(`attributes.${field}`, payload.value).patch;

        return [patch];
      }

    },
    USER_CHANGE_AVATAR_COLOR: {
      name: "USER_CHANGE_AVATAR_COLOR",
      params: {
        userId: "string",
        value: "string"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("user", payload.userId).set(`attributes.avatarColor`, payload.value).patch;

        return [patch];
      }

    },
    USER_SET_FLAG: {
      name: "USER_SET_FLAG",
      params: {
        userId: "string",
        field: "string",
        value: "string"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("user", payload.userId).set(`attributes.flags.${payload.field}`, payload.value).patch;

        return [patch];
      }

    },
    USER_ONBOARDING_UPDATE_ACCOUNT: {
      params: {
        userId: "string",
        salutation: "object",
        firstName: "object",
        lastName: "string",
        educationRole: "string",
        lastOnboardingStep: "number"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("user", payload.userId);
        patch.document.ops.set.push([`attributes.salutation`, payload.salutation]);
        patch.document.ops.set.push([`attributes.firstName`, payload.firstName]);
        patch.document.ops.set.push([`attributes.lastName`, payload.lastName]);
        patch.document.ops.set.push([`attributes.educationRole`, payload.educationRole]);
        patch.document.ops.set.push([`attributes.lastOnboardingStep`, payload.lastOnboardingStep]);
        return [patch];
      }

    }
  };
  _exports.default = _default;
});