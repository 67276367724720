define("cc-frontend/components/icons/streamline-task-checklist-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.498 24c-.827 0-1.5-.673-1.5-1.5v-19c0-.827.673-1.5 1.5-1.5h2c.276 0 .5.224.5.5s-.224.5-.5.5h-2c-.276 0-.5.224-.5.5v19c0 .276.224.5.5.5h8c.276 0 .5.224.5.5s-.224.5-.5.5h-8zM18.498 11c-.276 0-.5-.224-.5-.5v-7c0-.276-.224-.5-.5-.5h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.827 0 1.5.673 1.5 1.5v7c0 .276-.224.5-.5.5z"
    ></path>
    <path
      d="M2.998 22c-.551 0-1-.448-1-1V5c0-.551.449-1 1-1h2V2.5c0-.276.224-.5.5-.5h1.55c.234-1.155 1.25-2 2.45-2 1.2 0 2.216.845 2.45 2h1.55c.276 0 .5.224.5.5V4h2c.552 0 1 .449 1 1v5.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5V5h-2v.5c0 .276-.224.5-.5.5h-8c-.276 0-.5-.224-.5-.5V5h-2v16h6.5c.276 0 .5.224.5.5s-.224.5-.5.5h-6.5zm10-17V3h-1.5c-.276 0-.5-.224-.5-.5 0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5c0 .276-.224.5-.5.5h-1.5v2h7z"
    ></path>
    <path
      d="M5.498 8.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5h-8zM5.498 11.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5h-8zM5.498 14.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h4c.276 0 .5.224.5.5s-.224.5-.5.5h-4zM11.498 24c-.134 0-.259-.052-.354-.147-.128-.128-.177-.316-.127-.491l1.2-4.199c.004-.014.012-.036.019-.048l.01-.024c.022-.047.046-.084.075-.118l.024-.027 6.265-6.264.014-.015.015-.013.885-.885c.494-.497 1.153-.769 1.853-.769s1.359.273 1.854.768c1.022 1.022 1.022 2.685 0 3.707l-7.179 7.179c-.042.042-.093.076-.155.103l-.026.01c0 .002-.02.01-.04.015l-4.196 1.199c-.045.013-.091.019-.137.019zm.728-1.228l2.518-.719-1.799-1.799-.719 2.518zm3.473-1.179l5.572-5.572-2.293-2.293-5.572 5.572 2.293 2.293zm6.279-6.28l.546-.546c.632-.632.632-1.661 0-2.293-.306-.305-.713-.474-1.147-.474-.434 0-.841.168-1.146.474l-.546.546 2.293 2.293z"
    ></path>
  </svg>
  */
  {
    "id": "igzHmZyR",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M1.498 24c-.827 0-1.5-.673-1.5-1.5v-19c0-.827.673-1.5 1.5-1.5h2c.276 0 .5.224.5.5s-.224.5-.5.5h-2c-.276 0-.5.224-.5.5v19c0 .276.224.5.5.5h8c.276 0 .5.224.5.5s-.224.5-.5.5h-8zM18.498 11c-.276 0-.5-.224-.5-.5v-7c0-.276-.224-.5-.5-.5h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.827 0 1.5.673 1.5 1.5v7c0 .276-.224.5-.5.5z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M2.998 22c-.551 0-1-.448-1-1V5c0-.551.449-1 1-1h2V2.5c0-.276.224-.5.5-.5h1.55c.234-1.155 1.25-2 2.45-2 1.2 0 2.216.845 2.45 2h1.55c.276 0 .5.224.5.5V4h2c.552 0 1 .449 1 1v5.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5V5h-2v.5c0 .276-.224.5-.5.5h-8c-.276 0-.5-.224-.5-.5V5h-2v16h6.5c.276 0 .5.224.5.5s-.224.5-.5.5h-6.5zm10-17V3h-1.5c-.276 0-.5-.224-.5-.5 0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5c0 .276-.224.5-.5.5h-1.5v2h7z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M5.498 8.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5h-8zM5.498 11.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5h-8zM5.498 14.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h4c.276 0 .5.224.5.5s-.224.5-.5.5h-4zM11.498 24c-.134 0-.259-.052-.354-.147-.128-.128-.177-.316-.127-.491l1.2-4.199c.004-.014.012-.036.019-.048l.01-.024c.022-.047.046-.084.075-.118l.024-.027 6.265-6.264.014-.015.015-.013.885-.885c.494-.497 1.153-.769 1.853-.769s1.359.273 1.854.768c1.022 1.022 1.022 2.685 0 3.707l-7.179 7.179c-.042.042-.093.076-.155.103l-.026.01c0 .002-.02.01-.04.015l-4.196 1.199c-.045.013-.091.019-.137.019zm.728-1.228l2.518-.719-1.799-1.799-.719 2.518zm3.473-1.179l5.572-5.572-2.293-2.293-5.572 5.572 2.293 2.293zm6.279-6.28l.546-.546c.632-.632.632-1.661 0-2.293-.306-.305-.713-.474-1.147-.474-.434 0-.841.168-1.146.474l-.546.546 2.293 2.293z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/streamline-task-checklist-edit.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});