define("cc-frontend/utils/filter-dates/planbook", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPlanbookDateCustom = isPlanbookDateCustom;
  _exports.isPlanbookDateDefault = isPlanbookDateDefault;
  _exports.isPlanbookDateAnnotationCustomSingle = isPlanbookDateAnnotationCustomSingle;
  _exports.isMatchingPlanbookDateCustom = isMatchingPlanbookDateCustom;
  _exports.isMatchingPlanbookDateDefault = isMatchingPlanbookDateDefault;
  _exports.filterPlanbookDefaultDates = filterPlanbookDefaultDates;
  _exports.filterPlanbookCustomDates = filterPlanbookCustomDates;
  _exports.findPlanbookDefaultDate = findPlanbookDefaultDate;
  _exports.findPlanbookCustomDate = findPlanbookCustomDate;

  /*
  Tests for is-ness
  */
  function isPlanbookDateCustom(cd) {
    return cd.type === "planbook-date-custom";
  }

  function isPlanbookDateDefault(cd) {
    return cd.type === "planbook-date-default";
  }

  function isPlanbookDateAnnotationCustomSingle(ann) {
    return ann.type === "date-annotation-planbook-custom-single";
  }
  /*
    Tests for matching
  */


  function isMatchingPlanbookDateCustom(dateString, cd) {
    return cd.type === "planbook-date-custom" && cd.attributes.dateString === dateString;
  }

  function isMatchingPlanbookDateDefault(rotationId, semesterId, cd) {
    return cd.type === "planbook-date-default" && cd.attributes.rotationId === rotationId && cd.attributes.semesterId === semesterId;
  }
  /*
    Functionn to filter for desired types of dates
  */


  function filterPlanbookDefaultDates(planbook) {
    return (0, _lodashEs.filter)(Ember.get(planbook.attributes.calendar, "dates"), isPlanbookDateDefault);
  }

  function filterPlanbookCustomDates(planbook) {
    return (0, _lodashEs.sortBy)((0, _lodashEs.filter)(Ember.get(planbook.attributes.calendar, "dates"), isPlanbookDateCustom), "attributes.dateString");
  }
  /*
  
    Functions to find the date
  */


  function findPlanbookDefaultDate(planbook, {
    rotationId,
    semesterId
  }) {
    return (0, _lodashEs.find)(Ember.get(planbook.attributes.calendar, "dates"), isMatchingPlanbookDateDefault.bind(this, rotationId, semesterId));
  }

  function findPlanbookCustomDate(planbook, dateString) {
    return (0, _lodashEs.find)(Ember.get(planbook.attributes.calendar, "dates"), isMatchingPlanbookDateCustom.bind(this, dateString));
  }
});