define("cc-frontend/helpers/find-many", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = findMany;
  const ArrayPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  /**
   * A macro that creates a computed property that returns a single promise object that resolves
   * to an array of objects of the type specified in the relationshipType parameter when fulfilled
   *
   * @param  {String} keyName
   * @param  {String} relationshipType
   * @return {ObjectPromiseProxy} returns a promise object that resolves to an array of objects when all values in the array are fulfilled.
   * The array can be accessed via the content object.
   */

  function findMany(keyName, relationshipType) {
    /**
     * Example:
       ``` javascript
        class exampleComponent extends Component {
          visibleCourseIds = [ 1, 2, 3, 4 ]
          @findMany("visibleCourseIds", "course") visibleCourses
        }
      ```
     */
    return Ember.computed(`${keyName}.[]`, `${keyName}.length`, function () {
      let ids = Ember.get(this, keyName);
      if (Ember.get(this, "store") === undefined) throw Error("You need to inject the store to use the findMany function");
      return ArrayPromiseProxy.create({
        promise: Ember.RSVP.all(_lodash.default.map(ids, id => {
          return Ember.get(this, "store").find(relationshipType, id);
        }))
      });
    });
  }
});