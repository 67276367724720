define("cc-frontend/lib/actions/create-patch", ["exports", "lodash", "cc-frontend/lib/object-id-gen"], function (_exports, _lodash, _objectIdGen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createBaseDocument = createBaseDocument;
  _exports.createEmbeddedDocument = createEmbeddedDocument;
  _exports.default = createPatch;

  function createBaseDocument(model, id) {
    return {
      id: id,
      modelType: model,
      ops: {
        set: [],
        unset: [],
        inc: [],
        push: [],
        pull: [],
        pullAll: [],
        addToSet: []
      }
    };
  }

  function createEmbeddedDocument(embeddedPath, embeddedElemMatch) {
    return {
      path: embeddedPath,
      elemMatch: embeddedElemMatch,
      ops: {
        set: [],
        unset: [],
        inc: [],
        push: [],
        pull: [],
        pullAll: [],
        addToSet: []
      }
    };
  }

  function createPatch(model, id, embeddedPath, embeddedElemMatch) {
    return {
      id: _objectIdGen.default.create(),
      document: createBaseDocument(model, id),
      embeddedDocument: _lodash.default.isUndefined(embeddedPath) ? null : createEmbeddedDocument(embeddedPath, embeddedElemMatch),
      actionId: null,
      timeAtCreation: null,
      revisionAtCreation: null,
      isCreatePatch: false
    };
  }
});