define("cc-frontend/components/icons/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5 24c-.14 0-.26-.06-.36-.15L14.13 14.84c-1.56 1.388-3.56 2.15-5.65 2.15 -4.687 0-8.5-3.82-8.5-8.5 0-4.687 3.81-8.5 8.5-8.5 4.68 0 8.5 3.81 8.5 8.5 0 2.08-.77 4.08-2.151 5.64l9 9c.09.09.14.22.14.35 0 .13-.06.25-.15.35 -.1.09-.22.14-.36.14ZM8.5 1C4.36 1 1 4.36 1 8.5 1 12.63 4.36 16 8.5 16c4.13 0 7.5-3.365 7.5-7.5C16 4.365 12.635 1 8.5 1Z"
    ></path>
  </svg>
  */
  {
    "id": "FbjhbeRo",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M23.5 24c-.14 0-.26-.06-.36-.15L14.13 14.84c-1.56 1.388-3.56 2.15-5.65 2.15 -4.687 0-8.5-3.82-8.5-8.5 0-4.687 3.81-8.5 8.5-8.5 4.68 0 8.5 3.81 8.5 8.5 0 2.08-.77 4.08-2.151 5.64l9 9c.09.09.14.22.14.35 0 .13-.06.25-.15.35 -.1.09-.22.14-.36.14ZM8.5 1C4.36 1 1 4.36 1 8.5 1 12.63 4.36 16 8.5 16c4.13 0 7.5-3.365 7.5-7.5C16 4.365 12.635 1 8.5 1Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/search.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});