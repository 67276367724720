define("cc-frontend/components/offline-notice", ["exports", "@glimmer/component", "cc-frontend/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isOffline}}
    <div class="offline-notice">
      <div class="offline-notice__inner">
        {{! Only show if the connection is down }}
        {{#if this.computerIsOffline}}
          You're offline. Waiting for the internet to reconnect...<br />
        {{else}}
          {{#if this.serverIsOffline}}
            Looks like there's a problem with your connection to our servers. If this continues, try
            <span {{on "click" this.reloadPage}}> reloading.</span>
          {{/if}}
        {{/if}}
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "NDJK16Cv",
    "block": "[[[41,[30,0,[\"isOffline\"]],[[[1,\"  \"],[10,0],[14,0,\"offline-notice\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"offline-notice__inner\"],[12],[1,\"\\n\"],[41,[30,0,[\"computerIsOffline\"]],[[[1,\"        You're offline. Waiting for the internet to reconnect...\"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"serverIsOffline\"]],[[[1,\"          Looks like there's a problem with your connection to our servers. If this continues, try\\n          \"],[11,1],[4,[38,1],[\"click\",[30,0,[\"reloadPage\"]]],null],[12],[1,\" reloading.\"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"on\"]]",
    "moduleName": "cc-frontend/components/offline-notice.hbs",
    "isStrictMode": false
  });

  let OfflineNotice = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class OfflineNotice extends _component.default {
    constructor(owner, args) {
      super(owner, args); // This causes our tests to stall forever.

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "socket", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _defineProperty(this, "_pollTimer", null);

      _initializerDefineProperty(this, "serverIsOffline", _descriptor4, this);

      _initializerDefineProperty(this, "computerIsOffline", _descriptor5, this);

      _defineProperty(this, "isOnlineChecks", [true, true, true]);

      if (_environment.default.environment !== "test") {
        this._pollTimer = Ember.run.later(this, this.checkOfflineStatus, 1000); // check for window.addEventListener

        if (window && window.addEventListener) {
          window.addEventListener("offline", e => {
            this.computerIsOffline = true;
          });
          window.addEventListener("online", e => {
            this.computerIsOffline = false;
          });
        }
      }
    }

    get isOffline() {
      return this.serverIsOffline || this.computerIsOffline;
    }

    // Only check offline every 3 seconds so short stuff like
    // switching over the server doesn't trigger the notice
    checkOfflineStatus() {
      if (this.isDestroyed) return; // Remove the oldest check

      this.isOnlineChecks.shift(); // Push in a new check

      this.isOnlineChecks.push(this.socket.isOnline); // Check if we've been online during the last 3 checks

      if (this.isOnlineChecks.includes(true)) {
        this.serverIsOffline = false;
      } else {
        // this means we haven't been online for the last 3 checks so let's show the notice
        this.serverIsOffline = true;
      }

      this._pollTimer = Ember.run.later(this, this.checkOfflineStatus, 1000);
    }

    willDestroy() {
      if (this._pollTimer) Ember.run.cancel(this._pollTimer);
      super.willDestroy();
    }

    reloadPage() {
      window.location.reload();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "serverIsOffline", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "computerIsOffline", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "reloadPage", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "reloadPage"), _class.prototype)), _class));
  _exports.default = OfflineNotice;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OfflineNotice);
});