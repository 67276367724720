define("cc-frontend/templates/freestyle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f/i0XeFA",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Cc\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@name\"],[\"Lessons\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@section\"],[\"Simple\",[30,1]]],null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@name\"],[\"Course Dates\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@section\"],[\"Week\",[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@date\",\"@courseDate\",\"@componentId\",\"@lesson\",\"@course\",\"@perspective\",\"@planbookId\",\"@state\"],[\"2016-04-10\",[36,4],\"course-date-week\",[33,5,[\"lesson\"]],[33,5,[\"course\"]],\"week\",\"1\",[36,6]]],null],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@section\"],[\"Month\",[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"lesson-part-title take-course-background-color condensed-course-date\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@date\",\"@courseDate\",\"@componentId\",\"@lesson\",\"@course\",\"@perspective\",\"@state\",\"@planbookId\"],[\"2016-04-10\",[36,4],\"course-date-month\",[33,5,[\"lesson\"]],[33,5,[\"course\"]],\"month\",[36,6],\"1\"]],null],[1,\"\\n\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@name\"],[\"Template magic\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,[[\"@lesson\",\"@courses\",\"@rotation\",\"@courseCalendars\"],[[36,8],[36,9],[36,10],[36,11]]],null],[1,\"\\n\\n  \"]],[3]]]]],[1,\"\\n\"]],[]]]]]],[\"section\",\"section\",\"section\"],false,[\"freestyle-guide\",\"freestyle-section\",\"freestyle-subsection\",\"course-date\",\"courseDate\",\"simpleLesson\",\"courseDateState\",\"template-magic\",\"lesson\",\"courses\",\"rotation\",\"courseCalendars\"]]",
    "moduleName": "cc-frontend/templates/freestyle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});