define("cc-frontend/components/route-watcher", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "dnuUU/EU",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/route-watcher.hbs",
    "isStrictMode": false
  });

  let RouteWatcher = (_dec = (0, _object.on)("didReceiveAttrs"), (_class = class RouteWatcher extends Ember.Component {
    watchForRoute({
      oldAttrs,
      newAttrs
    }) {
      if (oldAttrs !== undefined && newAttrs !== undefined && (oldAttrs.route.value !== newAttrs.route.value || oldAttrs.routeModels.value !== newAttrs.routeModels.value || oldAttrs.routeParams.value !== newAttrs.routeParams.value) && newAttrs.route.value !== undefined) {
        Ember.getOwner(this).lookup("router:main").transitionTo(newAttrs.route.value, newAttrs.routeModels.value, newAttrs.routeParams.value);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "watchForRoute", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "watchForRoute"), _class.prototype)), _class));
  _exports.default = RouteWatcher;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RouteWatcher);
});