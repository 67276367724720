define("cc-frontend/lib/actions/planbook/lessons/PLANBOOK_COPY_ALL_LESSONS_FOR_DAY", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-activity"], function (_exports, _patchUpdater, _lodashEs, _defaultPlanbookLessonsActivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareCopyAllLessonsForDay = prepareCopyAllLessonsForDay;
  _exports.default = _exports.PLANBOOK_COPY_ALL_LESSONS_FOR_DAY = void 0;

  function prepareCopyAllLessonsForDay(payload) {
    return {
      name: PLANBOOK_COPY_ALL_LESSONS_FOR_DAY.name,
      payload: payload,
      patches: PLANBOOK_COPY_ALL_LESSONS_FOR_DAY.patches(payload),
      undoPatches: PLANBOOK_COPY_ALL_LESSONS_FOR_DAY.undoPatches,
      narrative: (0, _lodashEs.partial)(PLANBOOK_COPY_ALL_LESSONS_FOR_DAY.narrative, payload)
    };
  }

  const PLANBOOK_COPY_ALL_LESSONS_FOR_DAY = {
    name: "PLANBOOK_COPY_ALL_LESSONS_FOR_DAY",

    patches(payload) {
      // See LESSON_COPY
      // IF use this again, abstract!
      let patch = _patchUpdater.default.create("user", payload.currentUserId).set("attributes.clipboard.day", {
        lessonsForCourses: payload.lessonsForCourses
      }).patch;

      return [patch];
    },

    undoPatches: null,

    narrative(payload, _findFn, userId) {
      // let planbook = findFn("planbook", payload.planbookId)
      return {
        title: null,
        titleForAnalytics: "Lesson - Copy Entire Day",
        description: null,
        context: {},
        activity: (0, _defaultPlanbookLessonsActivity.default)(payload.planbookId, userId)
      };
    }

  };
  _exports.PLANBOOK_COPY_ALL_LESSONS_FOR_DAY = PLANBOOK_COPY_ALL_LESSONS_FOR_DAY;
  var _default = PLANBOOK_COPY_ALL_LESSONS_FOR_DAY;
  _exports.default = _default;
});