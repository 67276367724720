define("cc-frontend/components/school/reviews/manager/detail.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "detail": "_detail_nu17yg",
    "header": "_header_nu17yg",
    "user": "_user_nu17yg",
    "user-name": "_user-name_nu17yg",
    "actions": "_actions_nu17yg",
    "action-btn": "_action-btn_nu17yg",
    "action-btn__icon": "_action-btn__icon_nu17yg",
    "--green": "_--green_nu17yg",
    "--coral": "_--coral_nu17yg",
    "action-btn__text": "_action-btn__text_nu17yg"
  };
  _exports.default = _default;
});