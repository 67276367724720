define("cc-frontend/lib/actions/lesson/LESSON_CARD_COPY", ["exports", "lodash", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _lodash, _patchUpdater, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_COPY = void 0;
  const LESSON_CARD_COPY = {
    name: "LESSON_CARD_COPY",
    params: {
      currentUserId: "string",
      cardStackId: "string",
      section: "object"
    },

    patches(payload) {
      let card = _lodash.default.omit(payload.section, ["id"]);

      let patch = _patchUpdater.default.create("user", payload.currentUserId).set("attributes.clipboard.card", _lodash.default.cloneDeep(card)).patch;

      return [patch];
    },

    undoPatches: null,

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let cardTitle = Ember.get(payload, "section.title");
      return {
        title: null,
        titleForAnalytics: "Lesson Card - Copy",
        description: `Copied the "${cardTitle}" card`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: null
      };
    }

  };
  _exports.LESSON_CARD_COPY = LESSON_CARD_COPY;
  var _default = LESSON_CARD_COPY;
  _exports.default = _default;
});