define("cc-frontend/components/admin/skeleton", ["exports", "tracked-built-ins"], function (_exports, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.session.isImpersonating}}
    <div
      class="tw-container tw-mx-auto tw-px-4 tw-pt-4 tw-bg-yellow tw-flex tw-justify-center tw-flex-row tw-items-center"
    >
      <div class="tw-text-xl tw-mr-2">
        Currently Impersonating
        {{this.session.user.attributes.firstName}}
        {{this.session.user.attributes.lastName}}
        {{this.session.user.attributes.email}}
      </div>
      <div class="sierra-button" {{on "click" this.stopImpersonating}}>
        Stop Impersonating
      </div>
    </div>
  {{/if}}
  <div class="tw-mx-auto tw-px-12 tw-pt-4 tw-w-full">
    <div class="tw-flex tw-flex-row">
      <div class="tw-w-1/4 tw-flex-no-shrink tw-overflow-x-scroll">
        <div class="admin-doc-info-box">
          {{component
            @sidebarComponent
            model=@model
            selectVersion=this.selectVersion
            currentVersion=this.currentVersion
          }}
        </div>
      </div>
      <div class="admin-skeleton__main">
        <Admin::Detail
          @model={{@model}}
          @modelToStringify={{this.modelToStringify}}
          @selectVersion={{this.selectVersion}}
        >
          {{component
            @detailComponent
            model=@model
            modelToStringify=this.modelToStringify
            selectVersion=this.selectVersion
          }}
        </Admin::Detail>
      </div>
    </div>
  </div>
  {{yield}}
  */
  {
    "id": "2okJbJQ/",
    "block": "[[[41,[30,0,[\"session\",\"isImpersonating\"]],[[[1,\"  \"],[10,0],[14,0,\"tw-container tw-mx-auto tw-px-4 tw-pt-4 tw-bg-yellow tw-flex tw-justify-center tw-flex-row tw-items-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tw-text-xl tw-mr-2\"],[12],[1,\"\\n      Currently Impersonating\\n      \"],[1,[30,0,[\"session\",\"user\",\"attributes\",\"firstName\"]]],[1,\"\\n      \"],[1,[30,0,[\"session\",\"user\",\"attributes\",\"lastName\"]]],[1,\"\\n      \"],[1,[30,0,[\"session\",\"user\",\"attributes\",\"email\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,0],[24,0,\"sierra-button\"],[4,[38,1],[\"click\",[30,0,[\"stopImpersonating\"]]],null],[12],[1,\"\\n      Stop Impersonating\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[10,0],[14,0,\"tw-mx-auto tw-px-12 tw-pt-4 tw-w-full\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"tw-flex tw-flex-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tw-w-1/4 tw-flex-no-shrink tw-overflow-x-scroll\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"admin-doc-info-box\"],[12],[1,\"\\n        \"],[46,[30,1],null,[[\"model\",\"selectVersion\",\"currentVersion\"],[[30,2],[30,0,[\"selectVersion\"]],[30,0,[\"currentVersion\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"admin-skeleton__main\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@model\",\"@modelToStringify\",\"@selectVersion\"],[[30,2],[30,0,[\"modelToStringify\"]],[30,0,[\"selectVersion\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[46,[30,3],null,[[\"model\",\"modelToStringify\",\"selectVersion\"],[[30,2],[30,0,[\"modelToStringify\"]],[30,0,[\"selectVersion\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,4,null]],[\"@sidebarComponent\",\"@model\",\"@detailComponent\",\"&default\"],false,[\"if\",\"on\",\"component\",\"admin/detail\",\"yield\"]]",
    "moduleName": "cc-frontend/components/admin/skeleton.hbs",
    "isStrictMode": false
  });

  class ObjectPromiseProxy extends Ember.ObjectProxy.extend(Ember.PromiseProxyMixin) {}

  let AdminSkeleton = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed("currentVersion", "model.id", "model.type"), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class AdminSkeleton extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "currentVersion", _descriptor3, this);
    }

    get modelToStringify() {
      let version = Ember.get(this, "currentVersion");
      let type = this.model.type;
      let id = this.model.id;
      let promise;
      if (Ember.isNone(type) || Ember.isNone(id)) return null;

      if (version === "master") {
        promise = Ember.get(this, "store").find(type, id).then(doc => doc);
      } else {
        promise = Ember.get(this, "store").find("document-version", `${type}:${id}:${version}`).then(doc => {
          return doc ? doc.attributes.originalDocument : null;
        });
      }

      return ObjectPromiseProxy.create({
        promise: promise
      });
    }

    selectVersion(version) {
      Ember.set(this, "currentVersion", version);
    }

    stopImpersonating() {
      Ember.get(this, "session").stopImpersonating();
      window.location.reload();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentVersion", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "master";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "modelToStringify", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "modelToStringify"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectVersion", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectVersion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopImpersonating", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "stopImpersonating"), _class.prototype)), _class));
  _exports.default = AdminSkeleton;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AdminSkeleton);
});