define("cc-frontend/components/planner/template-chooser/template-chooser", ["exports", "@ember-decorators/component", "ember-animated/easings/cosine", "ember-animated/motions/move", "ember-animated/motions/opacity"], function (_exports, _component, _cosine, _move, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="main-screen screen-{{this.screen}}">
    <AnimatedContainer>
      {{#animated-value this.screen rules=this.animationRules as |screen|}}
        {{#if (eq screen 1)}}
          <Planner::TemplateChooser::ChooseFromGallery
            @planbook={{@planbook}}
            @rotationCalendar={{@rotationCalendar}}
            @selectTemplate={{this.selectTemplate}}
          />
  
        {{else if (eq screen 2)}}
          <Planner::TemplateChooser::SelectCoursesAndDays
            @cardStackId={{this.selectedTemplateId}}
            @planbook={{@planbook}}
            @rotationCalendar={{@rotationCalendar}}
            @courses={{@courses}}
            @semester={{@rotationCalendar.attributes.semesters.firstObject}}
          />
  
        {{/if}}
      {{/animated-value}}
    </AnimatedContainer>
  </div>
  */
  {
    "id": "/MczPDH0",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[[28,[37,1],[\"main-screen screen-\",[30,0,[\"screen\"]]],null]],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/template-chooser.css\"]]]]]],[12],[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,3],[[30,0,[\"screen\"]]],[[\"rules\"],[[30,0,[\"animationRules\"]]]],[[\"default\"],[[[[41,[28,[37,5],[[30,1],1],null],[[[1,\"        \"],[8,[39,6],null,[[\"@planbook\",\"@rotationCalendar\",\"@selectTemplate\"],[[30,2],[30,3],[30,0,[\"selectTemplate\"]]]],null],[1,\"\\n\\n\"]],[]],[[[41,[28,[37,5],[[30,1],2],null],[[[1,\"        \"],[8,[39,7],null,[[\"@cardStackId\",\"@planbook\",\"@rotationCalendar\",\"@courses\",\"@semester\"],[[30,0,[\"selectedTemplateId\"]],[30,2],[30,3],[30,4],[30,3,[\"attributes\",\"semesters\",\"firstObject\"]]]],null],[1,\"\\n\\n      \"]],[]],null]],[]]]],[1]]]]],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[\"screen\",\"@planbook\",\"@rotationCalendar\",\"@courses\"],false,[\"local-class\",\"concat\",\"animated-container\",\"animated-value\",\"if\",\"eq\",\"planner/template-chooser/choose-from-gallery\",\"planner/template-chooser/select-courses-and-days\"]]",
    "moduleName": "cc-frontend/components/planner/template-chooser/template-chooser.hbs",
    "isStrictMode": false
  });

  const forwardTransition = function* ({
    insertedSprites,
    keptSprites,
    removedSprites
  }) {
    insertedSprites.forEach(sprite => {
      sprite.startAtPixel({
        x: window.innerWidth
      });
      (0, _opacity.fadeIn)(sprite, {
        easing: _cosine.easeOut,
        duration: 500
      }); // move(sprite, { easing: easeOut, duration: 500 })
    });
    keptSprites.forEach(_move.default);
    removedSprites.forEach(sprite => {
      sprite.endAtPixel({
        x: -window.innerWidth
      }); // move(sprite, { easing: easeIn, duration: 500 })

      (0, _opacity.fadeOut)(sprite, {
        easing: _cosine.easeIn,
        duration: 500
      });
    });
  };

  const backwardsTransition = function* ({
    insertedSprites,
    keptSprites,
    removedSprites
  }) {
    insertedSprites.forEach(sprite => {
      sprite.startAtPixel({
        x: -window.innerWidth
      });
      (0, _opacity.fadeIn)(sprite, {
        easing: _cosine.easeOut,
        duration: 500
      }); // move(sprite, { easing: easeOut, duration: 500 })
    });
    keptSprites.forEach(_move.default);
    removedSprites.forEach(sprite => {
      sprite.endAtPixel({
        x: window.innerWidth
      }); // move(sprite, { easing: easeIn, duration: 500 })

      (0, _opacity.fadeOut)(sprite, {
        easing: _cosine.easeIn,
        duration: 500
      });
    });
  };

  let TemplateChooser = (_dec = (0, _component.tagName)(""), _dec2 = Ember._action, _dec3 = Ember._action, _dec(_class = (_class2 = class TemplateChooser extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "setSmallWidth", void 0);

      _defineProperty(this, "screen", 1);

      _defineProperty(this, "selectedTemplateId", null);
    }

    animationRules({
      oldItems,
      newItems
    }) {
      let newVal = newItems[0];
      let oldVal = oldItems[0];

      if (oldVal < newVal) {
        // return toLeft
        // return toUp
        return forwardTransition;
      } else {
        // return toRight
        // return toDown
        return backwardsTransition;
      }
    }

    selectTemplate(id) {
      this.set("screen", 2);
      this.set("selectedTemplateId", id);
      this.setSmallWidth();
    }

    onBack() {
      this.set("screen", 1);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "selectTemplate", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "selectTemplate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onBack", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "onBack"), _class2.prototype)), _class2)) || _class);
  _exports.default = TemplateChooser;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TemplateChooser);
});