define("cc-frontend/components/planner/menus/perspective-trigger.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "perspective-trigger-wrapper": "_perspective-trigger-wrapper_1uewuv",
    "perspective-trigger": "_perspective-trigger_1uewuv",
    "perspective-trigger__text": "_perspective-trigger__text_1uewuv",
    "perspective-trigger__icon": "_perspective-trigger__icon_1uewuv"
  };
  _exports.default = _default;
});