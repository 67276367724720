define("cc-frontend/components/planner/menus/add-card/primary/title", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Menus::Titles::Primary @title="Add Card" />
  */
  {
    "id": "lRkdzkeq",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Add Card\"]],null]],[],false,[\"planner/menus/titles/primary\"]]",
    "moduleName": "cc-frontend/components/planner/menus/add-card/primary/title.hbs",
    "isStrictMode": false
  });

  class AddCardTitle extends _glimmerComponent.default {}

  _exports.default = AddCardTitle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AddCardTitle);
});