define("cc-frontend/components/kb/prismic-article.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "_content_fn8vig",
    "video": "_video_fn8vig",
    "text-block": "_text-block_fn8vig"
  };
  _exports.default = _default;
});