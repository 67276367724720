define("cc-frontend/components/kb/entry/part/article-summary", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/has-many-altogether", "ember-could-get-used-to-this"], function (_exports, _glimmerComponent, _hasManyAltogether, _emberCouldGetUsedToThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul>
    {{#each (take 5 this.children.content) as |child|}}
      <li><span>{{child.attributes.title}}</span></li>
    {{/each}}
    {{#if (gt this.children.content.length 5)}}
      {{#if (eq (sub children.length 5) 1)}}
        <li><span>...Plus {{sub this.children.content.length 5}} other topic</span></li>
      {{else}}
        <li><span>...Plus {{sub this.children.content.length 5}} other topics</span></li>
      {{/if}}
    {{/if}}
  </ul>
  */
  {
    "id": "LA2uoEbq",
    "block": "[[[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[5,[30,0,[\"children\",\"content\"]]],null]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[10,1],[12],[1,[30,1,[\"attributes\",\"title\"]]],[13],[13],[1,\"\\n\"]],[1]],null],[41,[28,[37,4],[[30,0,[\"children\",\"content\",\"length\"]],5],null],[[[41,[28,[37,5],[[28,[37,6],[[33,7,[\"length\"]],5],null],1],null],[[[1,\"      \"],[10,\"li\"],[12],[10,1],[12],[1,\"...Plus \"],[1,[28,[35,6],[[30,0,[\"children\",\"content\",\"length\"]],5],null]],[1,\" other topic\"],[13],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"li\"],[12],[10,1],[12],[1,\"...Plus \"],[1,[28,[35,6],[[30,0,[\"children\",\"content\",\"length\"]],5],null]],[1,\" other topics\"],[13],[13],[1,\"\\n\"]],[]]]],[]],null],[13]],[\"child\"],false,[\"each\",\"-track-array\",\"take\",\"if\",\"gt\",\"eq\",\"sub\",\"children\"]]",
    "moduleName": "cc-frontend/components/kb/entry/part/article-summary.hbs",
    "isStrictMode": false
  });

  let KbEntryPartArticleSummary = (_dec = Ember.inject.service, (_class = class KbEntryPartArticleSummary extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "children", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "children", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _hasManyAltogether.default(() => [this.args.entry, "children", "kb-entry"]);
    }
  })), _class));
  _exports.default = KbEntryPartArticleSummary;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KbEntryPartArticleSummary);
});