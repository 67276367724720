define("cc-frontend/templates/student-course-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rw7D4Fl7",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[[30,1,[\"dateString\"]],\"MMM. Do YYYY\"],null],\" | \",[30,1,[\"course\",\"attributes\",\"title\"]]],null]],[1,\"\\n\"],[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-8 col-sm-offset-2\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[30,1,[\"course\"]],[30,1,[\"planbook\"]],[30,1,[\"rotationCalendar\"]]],null],[[[1,\"        \"],[8,[39,4],null,[[\"@planbook\",\"@course\",\"@useStudentVersion\",\"@dateString\",\"@rotationCalendar\"],[[30,1,[\"planbook\"]],[30,1,[\"course\"]],true,[30,1,[\"dateString\"]],[30,1,[\"rotationCalendar\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,5],null,[[\"@courseDate\",\"@lesson\",\"@course\",\"@perspective\",\"@showDate\"],[[30,2],[30,3],[30,1,[\"course\"]],\"day\",true]],null],[1,\"\\n        \"]],[2,3,4]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@model\",\"courseDate\",\"lesson\",\"lessonIsFulfilled\"],false,[\"page-title\",\"moment-format\",\"if\",\"and\",\"planner/card-stacks/lesson/provider\",\"planner/class-website/course-date\"]]",
    "moduleName": "cc-frontend/templates/student-course-date.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});