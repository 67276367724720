define("cc-frontend/templates/help/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JIYcRkT8",
    "block": "[[[8,[39,0],null,[[\"@entry\"],[[30,1]]],null]],[\"@model\"],false,[\"kb/entry/book\"]]",
    "moduleName": "cc-frontend/templates/help/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});