define("cc-frontend/components/planner/perspectives/week.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "week-grid__day-of-weeks__date": "_week-grid__day-of-weeks__date_124tcm"
  };
  _exports.default = _default;
});