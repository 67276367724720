define("cc-frontend/lib/phoenix/index", ["exports", "cc-frontend/lib/phoenix/channel", "cc-frontend/lib/phoenix/longpoll", "cc-frontend/lib/phoenix/presence", "cc-frontend/lib/phoenix/serializer", "cc-frontend/lib/phoenix/socket"], function (_exports, _channel, _longpoll, _presence, _serializer, _socket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Channel", {
    enumerable: true,
    get: function () {
      return _channel.default;
    }
  });
  Object.defineProperty(_exports, "LongPoll", {
    enumerable: true,
    get: function () {
      return _longpoll.default;
    }
  });
  Object.defineProperty(_exports, "Presence", {
    enumerable: true,
    get: function () {
      return _presence.default;
    }
  });
  Object.defineProperty(_exports, "Serializer", {
    enumerable: true,
    get: function () {
      return _serializer.default;
    }
  });
  Object.defineProperty(_exports, "Socket", {
    enumerable: true,
    get: function () {
      return _socket.default;
    }
  });
});