define("cc-frontend/helpers/find-many-altogether", ["exports", "ember-could-get-used-to-this", "lodash-es"], function (_exports, _emberCouldGetUsedToThis, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FindManyAltogether = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = class FindManyAltogether extends _emberCouldGetUsedToThis.Resource {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "isPending", _descriptor2, this);

      _initializerDefineProperty(this, "isSettled", _descriptor3, this);

      _initializerDefineProperty(this, "isRejected", _descriptor4, this);

      _initializerDefineProperty(this, "isFulfilled", _descriptor5, this);

      _initializerDefineProperty(this, "content", _descriptor6, this);
    }

    setup() {
      if (this.args.positional) {
        this.type = this.args.positional[0];
        this.ids = this.args.positional[1];
      }

      this._find();
    }

    _find() {
      if ((0, _lodashEs.isNil)(this.type) || (0, _lodashEs.isNil)(this.ids)) return;
      let type = this.type;
      this.isPending = true;
      this.isFulfilled = false;
      this.isSettled = false;
      Ember.RSVP.all((0, _lodashEs.map)(this.ids, id => this.store.find(type, id))).then(docs => {
        this.isPending = false;
        this.isFulfilled = true;
        this.content = docs;
      }).catch(() => {
        this.isRejected = true;
      }).finally(() => {
        this.isSettled = true;
      });
    }

    update() {
      if (this.args.positional) {
        let newType = this.args.positional[0];
        let newIds = this.args.positional[1]; // The entire query changes

        if (newType !== this.type || newIds !== this.ids) {
          this.type = newType;
          this.ids = newIds;

          this._find();
        }
      }
    }

    teardown() {// we'll unsubscribe from it here.
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSettled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = FindManyAltogether;
});