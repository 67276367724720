define("cc-frontend/components/icons/ios-gear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <path
      d="M416.349 256.046c-.001-21.013 13.143-38.948 31.651-46.062-4.887-20.466-12.957-39.7-23.664-57.139-6.375 2.836-13.23 4.254-20.082 4.254-12.621 0-25.238-4.811-34.871-14.442-14.863-14.863-18.248-36.846-10.18-54.97C341.768 76.973 322.54 68.895 302.074 64 294.971 82.529 277.027 95.69 256 95.69c-21.025 0-38.969-13.161-46.073-31.69-20.466 4.895-39.693 12.973-57.128 23.688 8.068 18.122 4.683 40.104-10.181 54.97-9.631 9.631-22.25 14.443-34.871 14.443-6.854 0-13.706-1.419-20.083-4.255C76.958 170.284 68.886 189.517 64 209.984c18.509 7.112 31.652 25.049 31.652 46.062 0 21.008-13.132 38.936-31.63 46.054 4.898 20.466 12.976 39.692 23.692 57.128 6.361-2.821 13.198-4.232 20.032-4.232 12.622 0 25.239 4.812 34.871 14.443 14.841 14.841 18.239 36.781 10.215 54.889 17.438 10.71 36.664 18.783 57.13 23.673 7.128-18.479 25.046-31.596 46.038-31.596 20.992 0 38.91 13.115 46.037 31.596 20.468-4.89 39.693-12.964 57.132-23.675-8.023-18.106-4.626-40.046 10.216-54.887 9.629-9.632 22.248-14.444 34.868-14.444 6.836 0 13.67 1.411 20.033 4.233 10.716-17.436 18.794-36.662 23.692-57.128-18.498-7.119-31.629-25.048-31.629-46.054zM256.9 335.9c-44.3 0-80-35.9-80-80 0-44.101 35.7-80 80-80 44.299 0 80 35.899 80 80 0 44.1-35.701 80-80 80z"
    ></path>
  </svg>
  */
  {
    "id": "NVUUExqu",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M416.349 256.046c-.001-21.013 13.143-38.948 31.651-46.062-4.887-20.466-12.957-39.7-23.664-57.139-6.375 2.836-13.23 4.254-20.082 4.254-12.621 0-25.238-4.811-34.871-14.442-14.863-14.863-18.248-36.846-10.18-54.97C341.768 76.973 322.54 68.895 302.074 64 294.971 82.529 277.027 95.69 256 95.69c-21.025 0-38.969-13.161-46.073-31.69-20.466 4.895-39.693 12.973-57.128 23.688 8.068 18.122 4.683 40.104-10.181 54.97-9.631 9.631-22.25 14.443-34.871 14.443-6.854 0-13.706-1.419-20.083-4.255C76.958 170.284 68.886 189.517 64 209.984c18.509 7.112 31.652 25.049 31.652 46.062 0 21.008-13.132 38.936-31.63 46.054 4.898 20.466 12.976 39.692 23.692 57.128 6.361-2.821 13.198-4.232 20.032-4.232 12.622 0 25.239 4.812 34.871 14.443 14.841 14.841 18.239 36.781 10.215 54.889 17.438 10.71 36.664 18.783 57.13 23.673 7.128-18.479 25.046-31.596 46.038-31.596 20.992 0 38.91 13.115 46.037 31.596 20.468-4.89 39.693-12.964 57.132-23.675-8.023-18.106-4.626-40.046 10.216-54.887 9.629-9.632 22.248-14.444 34.868-14.444 6.836 0 13.67 1.411 20.033 4.233 10.716-17.436 18.794-36.662 23.692-57.128-18.498-7.119-31.629-25.048-31.629-46.054zM256.9 335.9c-44.3 0-80-35.9-80-80 0-44.101 35.7-80 80-80 44.299 0 80 35.899 80 80 0 44.1-35.701 80-80 80z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/ios-gear.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});