define("cc-frontend/components/d-s/dropdown-menu/two-line-icon-row", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="two-line-row {{@additionalClass}}" {{on "click" this.onClick}} ...attributes>
    {{#if @iconName}}
      <div local-class="two-line-row__icon">
        {{component @iconName}}
      </div>
    {{/if}}
    <div local-class="two-line-row__text">
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "KbYQB/5L",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[[28,[37,1],[\"two-line-row \",[30,1]],null]],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/two-line-icon-row.css\"]]]]]],[17,2],[4,[38,2],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,0],[15,0,[29,[[28,[37,0],[\"two-line-row__icon\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/two-line-icon-row.css\"]]]]]],[12],[1,\"\\n      \"],[46,[30,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[29,[[28,[37,0],[\"two-line-row__text\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/two-line-icon-row.css\"]]]]]],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@additionalClass\",\"&attrs\",\"@iconName\",\"&default\"],false,[\"local-class\",\"concat\",\"on\",\"if\",\"component\",\"yield\"]]",
    "moduleName": "cc-frontend/components/d-s/dropdown-menu/two-line-icon-row.hbs",
    "isStrictMode": false
  });

  let TwoLineIconRow = (_dec = (0, _component.tagName)(""), _dec2 = Ember._action, _dec(_class = (_class2 = class TwoLineIconRow extends Ember.Component {
    onClick() {
      if (this.clickAction) {
        this.clickAction();
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "onClick", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "onClick"), _class2.prototype)), _class2)) || _class);
  _exports.default = TwoLineIconRow;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TwoLineIconRow);
});