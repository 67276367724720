define("cc-frontend/services/callbacks/patch/card-stack-template-callback", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(actionStream) {
    actionStream.onValue(([patch, _actionName]) => {
      _lodash.default.chain(Ember.get(this, "cardStackTemplateUpdater.subscriptions")).toPairs().filter(function (tuple, _index) {
        let [_cardStackId, cardStackToObserveId] = tuple;
        return patch.document.id === cardStackToObserveId;
      }).forEach((tuple, _index) => {
        let [cardStackId, cardStackTemplateId] = tuple;
        this.phantomCardStacks.applyTemplateCardsToCardStackIfNeeded(cardStackId, cardStackTemplateId);
      }).value();
    });
  }
});