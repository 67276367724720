define("cc-frontend/helpers/fast-date-object", ["exports", "cc-frontend/lib/date-formatter"], function (_exports, _dateFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fastDateObject = fastDateObject;
  _exports.default = void 0;

  function fastDateObject(params, hash) {
    let dateString = params[0];
    return (0, _dateFormatter.dateFormatMap)(dateString);
  }

  var _default = Ember.Helper.helper(fastDateObject);

  _exports.default = _default;
});