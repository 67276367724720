define("cc-frontend/templates/icons/-streamline-multiple-person-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "elNwj+zh",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M18.5 24c-3.033 0-5.5-2.467-5.5-5.5s2.467-5.5 5.5-5.5 5.5 2.467 5.5 5.5-2.467 5.5-5.5 5.5zm0-10c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M18.5 21c-.276 0-.5-.224-.5-.5V19h-1.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5H18v-1.5c0-.276.224-.5.5-.5s.5.224.5.5V18h1.5c.276 0 .5.224.5.5s-.224.5-.5.5H19v1.5c0 .276-.224.5-.5.5zM.5 16c-.276 0-.5-.224-.5-.5C0 11.916 2.916 9 6.5 9s6.5 2.916 6.5 6.5c0 .276-.224.5-.5.5H.5zm11.478-1c-.253-2.824-2.611-5-5.478-5-2.867 0-5.224 2.176-5.478 5h10.956zM6.5 8.5c-2.344 0-4.25-1.906-4.25-4.25C2.25 1.906 4.156 0 6.5 0c2.344 0 4.25 1.906 4.25 4.25 0 2.344-1.906 4.25-4.25 4.25zm0-7.5C4.708 1 3.25 2.458 3.25 4.25S4.708 7.5 6.5 7.5s3.25-1.458 3.25-3.25S8.292 1 6.5 1zM16 8.5c-2.344 0-4.25-1.906-4.25-4.25C11.75 1.906 13.656 0 16 0c2.344 0 4.25 1.906 4.25 4.25 0 2.344-1.906 4.25-4.25 4.25zM16 1c-1.792 0-3.25 1.458-3.25 3.25S14.208 7.5 16 7.5s3.25-1.458 3.25-3.25S17.792 1 16 1zM19.47 11.105c-.104 0-.205-.032-.289-.092-.932-.661-2.033-1.01-3.183-1.01-.961 0-1.907.251-2.736.727-.076.043-.162.066-.249.066-.179 0-.345-.096-.434-.251-.067-.116-.084-.251-.049-.38s.118-.236.234-.303c.979-.562 2.098-.86 3.234-.86 1.36 0 2.661.413 3.762 1.194.225.159.278.472.119.697-.094.133-.247.212-.409.212z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-streamline-multiple-person-add.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});