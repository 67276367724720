define("cc-frontend/components/planner/planbook/class-website/settings-wrapper", ["exports", "@ember-decorators/component", "cc-frontend/lib/object-id-gen"], function (_exports, _component, _objectIdGen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SettingsWrapper = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
  
  {{#each classWebsites as |classWebsite|}}
    {{#if classWebsite.isFulfilled}}
      <Planner::Planbook::ClassWebsite::Settings @classWebsite={{classWebsite.content}} @courses={{courses}} @planbook={{planbook}} />
    {{/if}}
  {{/each}}
  <div class="class-website-settings-modal__footer">
    {{#if creatingWebsite}}
      <DS::ThreeDotSpinner />
    {{else}}
      <div class="btn btn-default btn-lg class-website-settings-modal__new-website test-create-class-website" {{action "createNewClassWebsite"}}>
        Create a class website
      </div>
    {{/if}}
  </div>
  {{yield}}
  
  */
  {
    "id": "Wkdhr12x",
    "block": "[[[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[30,1,[\"isFulfilled\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@classWebsite\",\"@courses\",\"@planbook\"],[[30,1,[\"content\"]],[36,5],[36,6]]],null],[1,\"\\n\"]],[]],null]],[1]],null],[10,0],[14,0,\"class-website-settings-modal__footer\"],[12],[1,\"\\n\"],[41,[33,7],[[[1,\"    \"],[8,[39,8],null,null,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,0],[24,0,\"btn btn-default btn-lg class-website-settings-modal__new-website test-create-class-website\"],[4,[38,9],[[30,0],\"createNewClassWebsite\"],null],[12],[1,\"\\n      Create a class website\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[18,2,null],[1,\"\\n\"]],[\"classWebsite\",\"&default\"],false,[\"each\",\"-track-array\",\"classWebsites\",\"if\",\"planner/planbook/class-website/settings\",\"courses\",\"planbook\",\"creatingWebsite\",\"d-s/three-dot-spinner\",\"action\",\"yield\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec(_class = (_class2 = class SettingsWrapper extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);
    }

    createNewClassWebsite() {
      Ember.get(this, "store").dispatch("CREATE_CLASS_WEBSITE", {
        planbookId: Ember.get(this, "planbook.id"),
        websiteId: _objectIdGen.default.create().toLowerCase()
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appState", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "createNewClassWebsite", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "createNewClassWebsite"), _class2.prototype)), _class2)) || _class);
  _exports.default = SettingsWrapper;
});