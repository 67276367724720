define("cc-frontend/transitions/fade-down-slight-center", ["exports", "cc-frontend/transitions/fade-direction-slight-center"], function (_exports, _fadeDirectionSlightCenter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(opts = {}) {
    return _fadeDirectionSlightCenter.default.call(this, "y", -1, opts, opts.offset);
  }
});