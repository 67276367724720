define("cc-frontend/styles/components/planner/course/create-edit/edit-main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "main-screen": "_main-screen_18iiyv",
    "nav-box--base": "_nav-box--base_18iiyv",
    "nav-box": "_nav-box_18iiyv _nav-box--base_18iiyv",
    "nav-box__top": "_nav-box__top_18iiyv",
    "nav-box__head": "_nav-box__head_18iiyv",
    "nav-box__subhead": "_nav-box__subhead_18iiyv",
    "--is-link": "_--is-link_18iiyv",
    "nav-box__nav": "_nav-box__nav_18iiyv",
    "nav-box__nav__item": "_nav-box__nav__item_18iiyv",
    "is-selected": "_is-selected_18iiyv"
  };
  _exports.default = _default;
});