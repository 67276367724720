define("cc-frontend/lib/actions/lesson/LESSON_CARD_MOVE_WITHIN_LESSON", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_MOVE_WITHIN_LESSON = void 0;
  const LESSON_CARD_MOVE_WITHIN_LESSON = {
    name: "LESSON_CARD_MOVE_WITHIN_LESSON",
    params: {
      cardStackId: "string",
      positionDiffs: "array",
      newCards: "array",
      sectionBeingMoved: "object"
    },

    patches(payload) {
      return _lodash.default.map(payload.positionDiffs, tuple => {
        return _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
          id: tuple[0]
        }).set("embedded", "attributes.position", tuple[1]).patch;
      });
    },

    undoPatches(payload) {
      return _lodash.default.map(payload.positionDiffs, tuple => {
        return _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
          id: tuple[0]
        }).set("embedded", "attributes.position", tuple[2]).patch;
      });
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let cardTitle = Ember.get(payload, "sectionBeingMoved.attributes.title") || "Untitled";
      let context = (0, _defaultLessonContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        moveType: "withinLesson",
        cardTitle: cardTitle
      });

      return {
        title: "Moved a Lesson Card",
        titleForAnalytics: "Lesson Card - Move",
        description: `Moved "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "moved")
      };
    }

  };
  _exports.LESSON_CARD_MOVE_WITHIN_LESSON = LESSON_CARD_MOVE_WITHIN_LESSON;
  var _default = LESSON_CARD_MOVE_WITHIN_LESSON;
  _exports.default = _default;
});