define("cc-frontend/components/admin/group/membership-table/group-children", ["exports", "cc-frontend/app"], function (_exports, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  {{#if (gt @model.length 0)}}
    <EmberTable class="group-admin-membership-table" as |t|>
      <t.head @widthConstraint="gte-container" @resizeMode="fluid" @columns={{this.columns}} />
      <t.body @rows={{@model}} @onSelect={{this.onRowClick}} @rowSelectionMode="single" as |b|>
        <b.row as |r|>
          <r.cell as |cellValue columnValue rowValue|>
            {{#if columnValue.cellComponent}}
              {{component
                columnValue.cellComponent
                value=cellValue
                deleteGroup=this.deleteChildGroup
              }}
            {{else}}
              {{cellValue}}
            {{/if}}
          </r.cell>
        </b.row>
      </t.body>
    </EmberTable>
  {{else}}
    <div class="tw-text-base">
      No groups
    </div>
  {{/if}}
  */
  {
    "id": "pphoI/pz",
    "block": "[[[18,8,null],[1,\"\\n\"],[41,[28,[37,2],[[30,1,[\"length\"]],0],null],[[[1,\"  \"],[8,[39,3],[[24,0,\"group-admin-membership-table\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"head\"]],null,[[\"@widthConstraint\",\"@resizeMode\",\"@columns\"],[\"gte-container\",\"fluid\",[30,0,[\"columns\"]]]],null],[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,[[\"@rows\",\"@onSelect\",\"@rowSelectionMode\"],[[30,1],[30,0,[\"onRowClick\"]],\"single\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"row\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"cell\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,6,[\"cellComponent\"]],[[[1,\"            \"],[46,[30,6,[\"cellComponent\"]],null,[[\"value\",\"deleteGroup\"],[[30,5],[30,0,[\"deleteChildGroup\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,5]],[1,\"\\n\"]],[]]],[1,\"        \"]],[5,6,7]]]]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"tw-text-base\"],[12],[1,\"\\n    No groups\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@model\",\"t\",\"b\",\"r\",\"cellValue\",\"columnValue\",\"rowValue\",\"&default\"],false,[\"yield\",\"if\",\"gt\",\"ember-table\",\"component\"]]",
    "moduleName": "cc-frontend/components/admin/group/membership-table/group-children.hbs",
    "isStrictMode": false
  });

  let GroupAdminMembershipTableGroupChildren = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class GroupAdminMembershipTableGroupChildren extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _initializerDefineProperty(this, "dialog", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _defineProperty(this, "columns", [{
        name: "Name",
        valuePath: "attributes.groupId",
        cellComponent: "admin/group/table/group-cell"
      }, {
        name: "Roles",
        valuePath: "attributes.roles"
      }, {
        name: "Group Id",
        valuePath: "attributes.groupId",
        sortable: false,
        width: "200px"
      }, {
        name: "Actions",
        valuePath: "attributes.groupId",
        cellComponent: "admin/group/table/group-cell-actions"
      }]);
    }

    onRowClick(row) {
      Ember.getOwner(this).lookup("router:main").transitionTo("admin.groups-old-admin.show", Ember.get(row, "attributes.groupId"));
    }

    deleteChildGroup(id) {
      Ember.get(this, "dialog").blank("dialogs/confirm-delete-team", {
        className: "dialog--create-annotation"
      }).then(() => {
        Ember.get(this, "rpc").execute("group_delete_child_group", {
          groupId: Ember.get(this, "group.id"),
          childGroupId: id
        });
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onRowClick", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onRowClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteChildGroup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "deleteChildGroup"), _class.prototype)), _class));
  _exports.default = GroupAdminMembershipTableGroupChildren;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupAdminMembershipTableGroupChildren);
});