define("cc-frontend/components/busy-syncing-modal", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.socket.showBusySyncingModal}}
    <div class="busy-syncing-modal-backdrop"></div>
    <div class="busy-syncing-modal-wrapper">
      <div class="busy-syncing-modal">
        <div class="busy-syncing-modal__heading">
          Hold on while we sync changes...
        </div>
        <div class="busy-syncing-modal__subheading">
          Syncing
          {{this.socket.actions.length}}
          actions.
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "djb4/pjF",
    "block": "[[[41,[30,0,[\"socket\",\"showBusySyncingModal\"]],[[[1,\"  \"],[10,0],[14,0,\"busy-syncing-modal-backdrop\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"busy-syncing-modal-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"busy-syncing-modal\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"busy-syncing-modal__heading\"],[12],[1,\"\\n        Hold on while we sync changes...\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"busy-syncing-modal__subheading\"],[12],[1,\"\\n        Syncing\\n        \"],[1,[30,0,[\"socket\",\"actions\",\"length\"]]],[1,\"\\n        actions.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "cc-frontend/components/busy-syncing-modal.hbs",
    "isStrictMode": false
  });

  let BusySyncingModal = (_dec = Ember.inject.service, (_class = class BusySyncingModal extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "socket", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BusySyncingModal;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BusySyncingModal);
});