define("cc-frontend/components/group/members-table/cells/name", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @row.type "group-membership-invited")}}
    <div class="group-member-table__info">
      <div class="group-member-table__info__text">
        <div class="group-member-table__info__invited tw-self-start">
          Has not accepted invite
        </div>
        <div class="group-member-table__info__email">
          {{@row.attributes.email}}
        </div>
      </div>
    </div>
  {{/if}}
  
  {{#if (eq @row.type "group-membership-accepted")}}
    {{#let (find-doc "user-summary" @row.attributes.userId) as |userSummary|}}
      <div class="group-member-table__info">
        <div class="group-member-table__info__text">
          <div class="group-member-table__info__text__name">
            <div class="group-member-table__info__text__name__first">
              {{userSummary.content.attributes.firstName}}
            </div>
            <div class="group-member-table__info__text__name__last">
              {{userSummary.content.attributes.lastName}}
            </div>
          </div>
          <div class="group-member-table__info__email">
            {{userSummary.content.attributes.email}}
          </div>
        </div>
      </div>
    {{/let}}
  {{/if}}
  */
  {
    "id": "b8CS6kG1",
    "block": "[[[41,[28,[37,1],[[30,1,[\"type\"]],\"group-membership-invited\"],null],[[[1,\"  \"],[10,0],[14,0,\"group-member-table__info\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"group-member-table__info__text\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"group-member-table__info__invited tw-self-start\"],[12],[1,\"\\n        Has not accepted invite\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"group-member-table__info__email\"],[12],[1,\"\\n        \"],[1,[30,1,[\"attributes\",\"email\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,1],[[30,1,[\"type\"]],\"group-membership-accepted\"],null],[[[44,[[28,[37,3],[\"user-summary\",[30,1,[\"attributes\",\"userId\"]]],null]],[[[1,\"    \"],[10,0],[14,0,\"group-member-table__info\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"group-member-table__info__text\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"group-member-table__info__text__name\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"group-member-table__info__text__name__first\"],[12],[1,\"\\n            \"],[1,[30,2,[\"content\",\"attributes\",\"firstName\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"group-member-table__info__text__name__last\"],[12],[1,\"\\n            \"],[1,[30,2,[\"content\",\"attributes\",\"lastName\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"group-member-table__info__email\"],[12],[1,\"\\n          \"],[1,[30,2,[\"content\",\"attributes\",\"email\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]]]],[]],null]],[\"@row\",\"userSummary\"],false,[\"if\",\"eq\",\"let\",\"find-doc\"]]",
    "moduleName": "cc-frontend/components/group/members-table/cells/name.hbs",
    "isStrictMode": false
  });

  class GroupMembersTableNameCell extends _glimmerComponent.default {}

  _exports.default = GroupMembersTableNameCell;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupMembersTableNameCell);
});