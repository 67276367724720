define("cc-frontend/components/kb/header", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="kb-header-wrapper">
    <div class="kb-nav-bar kb-nav-bar--normal">
      <div class="kb-nav-bar__top">
        <div class="kb-nav-bar__left-side">
          <LinkTo @route="help" class="kb-nav-bar__logo kb-nav-bar--normal__logo">
            <Icons::CcWordMark />
          </LinkTo>
        </div>
        <div class="kb-nav-bar__right-side">
          <Kb::Search::Main @searchBarClass="kb-search--navbar" />
          <LinkTo @route="help.contact" class="kb-nav-bar__btn kb-nav-bar__btn--box">Contact Us</LinkTo>
        </div>
      </div>
    </div>
  
  </div>
  */
  {
    "id": "BREbwFRx",
    "block": "[[[10,0],[14,0,\"kb-header-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"kb-nav-bar kb-nav-bar--normal\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"kb-nav-bar__top\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"kb-nav-bar__left-side\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"kb-nav-bar__logo kb-nav-bar--normal__logo\"]],[[\"@route\"],[\"help\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,1],null,null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"kb-nav-bar__right-side\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@searchBarClass\"],[\"kb-search--navbar\"]],null],[1,\"\\n        \"],[8,[39,0],[[24,0,\"kb-nav-bar__btn kb-nav-bar__btn--box\"]],[[\"@route\"],[\"help.contact\"]],[[\"default\"],[[[[1,\"Contact Us\"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13]],[],false,[\"link-to\",\"icons/cc-word-mark\",\"kb/search/main\"]]",
    "moduleName": "cc-frontend/components/kb/header.hbs",
    "isStrictMode": false
  });

  class KbHeader extends _glimmerComponent.default {}

  _exports.default = KbHeader;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KbHeader);
});