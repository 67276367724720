define("cc-frontend/helpers/accept-invite", ["exports", "ember-could-get-used-to-this", "lodash-es", "tracked-built-ins"], function (_exports, _emberCouldGetUsedToThis, _lodashEs, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AcceptInvite = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class AcceptInvite extends _emberCouldGetUsedToThis.Resource {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "sessionStore", _descriptor2, this);

      _initializerDefineProperty(this, "rpc", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _defineProperty(this, "invite", void 0);

      _initializerDefineProperty(this, "isProcessing", _descriptor5, this);

      _initializerDefineProperty(this, "result", _descriptor6, this);

      _initializerDefineProperty(this, "isAccepted", _descriptor7, this);
    }

    setup() {
      this.invite = this.args.positional[0];

      this._acceptInvite();
    }

    get isAuthenticating() {
      return this.session.isAuthenticating;
    }

    _acceptInvite() {
      if (this.session.isAuthenticating === null || this.session.isAuthenticating === true) {
        Ember.run.later(() => {
          this._acceptInvite();
        }, 200);
        return;
      }

      if (!(0, _lodashEs.isNil)(this.invite.attributes.acceptedOn)) {
        this.isProcessing = false;
        this.result = "ALREADY_ACCEPTED";
        return;
      }

      if (this.session.isAuthenticated === false) {
        // We'll know this immediately, so we don't wnat the screen to immediately flash.
        Ember.run.later(() => {
          // Set a property on localStorage to record this invite
          this.sessionStore.persistObject("invite", {
            id: this.invite.id
          });
          this.isProcessing = false;
          this.result = "MUST_SIGN_IN";
        }, 2000);
      } else if (this.session.isAuthenticated === true) {
        this.rpc.invite_accept({
          id: this.invite.id
        }).then(() => {
          Ember.run.later(() => {
            this.isProcessing = false;
            this.result = "SUCCESS";
          }, 1000);
        });
      }
    }

    update() {
      let invite = this.args.positional[0]; // The entire query changes

      if (invite !== this.invite.id) {
        this.invite = invite;

        this._acceptInvite();
      }
    }

    teardown() {// we'll unsubscribe from it here.
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionStore", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isProcessing", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "result", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "IS_PROCESSING";
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isAccepted", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = AcceptInvite;
});