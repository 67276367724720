define("cc-frontend/lib/actions/course/COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK", ["exports", "lodash", "lodash-es", "cc-frontend/lib/actions/course/calendar/COURSE_DATE_SAVE_AS_TEMPLATE", "cc-frontend/lib/actions/course/calendar/COURSE_ROTATION_SET_TEMPLATE_ID", "cc-frontend/lib/actions/course/default-course-activity", "cc-frontend/lib/actions/course/default-course-context", "cc-frontend/lib/actions/course/default-course-models"], function (_exports, _lodash, _lodashEs, _COURSE_DATE_SAVE_AS_TEMPLATE, _COURSE_ROTATION_SET_TEMPLATE_ID, _defaultCourseActivity, _defaultCourseContext, _defaultCourseModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareCourseAssignLessonTemplateToWeek = prepareCourseAssignLessonTemplateToWeek;
  _exports.default = _exports.COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK = void 0;

  function prepareCourseAssignLessonTemplateToWeek(payload) {
    return {
      name: COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK.name,
      payload: payload,
      patches: COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK.patches(payload),
      undoPatches: COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK.undoPatches(payload),
      narrative: (0, _lodash.partial)(COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK.narrative, payload)
    };
  }

  const COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK = {
    name: "COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK",

    patches(payload) {
      let patches = (0, _lodash.flatten)((0, _lodash.map)((0, _lodash.filter)(payload.course.attributes.calendar.dates, date => date.type === "course-date-default"), courseDate => {
        return _COURSE_ROTATION_SET_TEMPLATE_ID.default.patches({
          courseId: payload.course.id,
          rotationId: courseDate.attributes.rotationId,
          semesterId: courseDate.attributes.semesterId,
          planbookId: payload.planbookId,
          dateId: courseDate.id,
          cardStackId: payload.cardStackId
        });
      }));

      if (payload.shouldCreateNewTemplate === true && payload.newTemplateAttrs !== undefined && payload.cardStackId) {
        (0, _lodashEs.forEach)(_COURSE_DATE_SAVE_AS_TEMPLATE.COURSE_DATE_SAVE_AS_TEMPLATE.patches({
          newId: payload.cardStackId,
          planbookId: payload.planbookId,
          cardStackAttributes: payload.newTemplateAttrs
        }), patch => patches.unshift(patch));
      }

      return patches;
    },

    undoPatches(payload) {
      return (0, _lodash.flatten)((0, _lodash.map)((0, _lodash.filter)(payload.course.attributes.calendar.dates, date => date.type === "course-date-default"), courseDate => {
        return _COURSE_ROTATION_SET_TEMPLATE_ID.default.undoPatches({
          courseId: payload.course.id,
          rotationId: courseDate.attributes.rotationId,
          semesterId: courseDate.attributes.semesterId,
          planbookId: payload.planbookId,
          dateId: courseDate.id,
          cardStackId: payload.cardStackId
        });
      }));
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseModels.default)(payload, findFn, userId);
      return {
        title: "Assigned Template",
        titleForAnalytics: "Course - Assigned Template to Week",
        description: `Added a template to your ${payload.course.attributes.title} class`,
        context: (0, _defaultCourseContext.default)(payload, models),
        activity: (0, _defaultCourseActivity.default)(payload, models)
      };
    }

  };
  _exports.COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK = COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK;
  var _default = COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK;
  _exports.default = _default;
});