define("cc-frontend/templates/icons/-print-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bJmo+cZ/",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"baseProfile\",\"tiny\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"512px\"],[14,\"height\",\"512px\"],[14,\"viewBox\",\"0 0 512 512\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M433.5,452.5h-354v-393h354V452.5z M94.5,437.5h324v-363h-324V437.5z\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"359.5\"],[14,\"y\",\"119\"],[14,\"width\",\"15\"],[14,\"height\",\"167\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"303.5\"],[14,\"y\",\"119\"],[14,\"width\",\"15\"],[14,\"height\",\"211\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"247.5\"],[14,\"y\",\"119\"],[14,\"width\",\"15\"],[14,\"height\",\"129.5\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"191.5\"],[14,\"y\",\"119\"],[14,\"width\",\"15\"],[14,\"height\",\"175\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"137.5\"],[14,\"y\",\"119\"],[14,\"width\",\"15\"],[14,\"height\",\"242\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-print-columns.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});