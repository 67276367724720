define("cc-frontend/templates/icons/-ios7-browser-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aIEd/b6C",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"512px\"],[14,\"height\",\"512px\"],[14,\"viewBox\",\"0 0 512 512\"],[14,5,\"enable-background:new 0 0 512 512;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M64,144v304h303.9V144H64z M351.9,432H80V160h271.9V432z\"],[12],[13],[1,\"\\n    \"],[10,\"g\"],[12],[1,\"\\n      \"],[10,\"polygon\"],[14,\"points\",\"448,64 144,64 144,128 160,128 160,80 432,80 432,352 384,352 384,368 448,368\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ios7-browser-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});