define("cc-frontend/templates/icons/-spinning-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/3+laEAm",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"viewBox\",\"0 0 50 50\"],[14,5,\"enable-background:new 0 0 50 50;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z\"],[14,\"transform\",\"rotate(339.122 25 25)\"],[12],[1,\"\\n    \"],[10,\"animateTransform\"],[14,\"attributeType\",\"xml\"],[14,\"attributeName\",\"transform\"],[14,\"from\",\"0 25 25\"],[14,\"to\",\"360 25 25\"],[14,\"dur\",\"0.6s\"],[14,\"repeatCount\",\"indefinite\"],[14,4,\"rotate\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-spinning-circle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});