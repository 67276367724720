define("cc-frontend/helpers/-eat-rounded", ["exports", "ember-animated-tools/helpers/rounded"], function (_exports, _rounded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rounded.default;
    }
  });
  Object.defineProperty(_exports, "eatRounded", {
    enumerable: true,
    get: function () {
      return _rounded.eatRounded;
    }
  });
});