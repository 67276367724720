define("cc-frontend/templates/help/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hbjk/Sjq",
    "block": "[[[8,[39,0],null,[[\"@entry\"],[[36,1]]],null]],[],false,[\"kb/contact-us\",\"model\"]]",
    "moduleName": "cc-frontend/templates/help/contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});