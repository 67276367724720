define("cc-frontend/templates/icons/-ios-home-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bWspkiQx",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M256 112L96 240v208h112V320h96v128h112V240L256 112zm144 320h-80V304H192v128h-80V247.69l144-115.2 144 115.2V432z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M256 64l-96 76.8V96H96v95.999l-32 25.499 11.51 11.384L256 84.49l180.49 144.393L448 217.498 256 64zm-112 89.599l-32 25.6V112h32v41.599z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ios-home-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});