define("cc-frontend/components/planner/template-chooser/choose-courses-new.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cardstack-template-container": "_cardstack-template-container_hhh8z3",
    "--no-template": "_--no-template_hhh8z3",
    "cardstack-template-list": "_cardstack-template-list_hhh8z3",
    "cardstack-template-list__course": "_cardstack-template-list__course_hhh8z3",
    "course-pill": "_course-pill_hhh8z3",
    "course-pill__title": "_course-pill__title_hhh8z3",
    "course-pill__checkbox": "_course-pill__checkbox_hhh8z3"
  };
  _exports.default = _default;
});