define("cc-frontend/components/planner/course/create-edit/customize-templ", ["exports", "@ember-decorators/component", "lodash-es"], function (_exports, _component, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="customize-template" data-test-edit-template-modal>
    <Planner::CardStacks::CardStackTitle
      @placeholder="Template Title"
      @value={{@cardStack.attributes.title}}
      @cardStackId={{@cardStack.id}}
      @modelsNeededForFirstEdit={{hash cardStack=@cardStack course=@course planbook=@planbook}}
      @class="js-test-card-stack-title {{local-class "lesson-title"}}"
      @isEditable={{includes @cardStack.meta.permissions "can-edit-plan"}}
    />
    <Planner::CardStacks::Cards
      @isFullScreen={{true}}
      @usedAs={{@cardStack.attributes.usedAs}}
      @cardStackId={{@cardStack.id}}
      @permissions={{@cardStack.meta.permissions}}
      @planbookId={{@planbook.id}}
      @courseId={{@course.id}}
      @courseColor={{@course.attributes.color}}
      @modelsNeededForFirstEdit={{hash cardStack=@cardStack course=@course planbook=@planbook}}
      @courseStandardSetIds={{@course.attributes.standardSetIds}}
      @planbookRecentStandardSetIds={{@planbook.attributes.recentStandardSetIds}}
      @sortedCards={{this.sortedCards}}
      @sortableModel={{this.sortedCards}}
    />
  </div>
  */
  {
    "id": "d9SyS3v+",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"customize-template\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/customize-templ.css\"]]]]]],[14,\"data-test-edit-template-modal\",\"\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@placeholder\",\"@value\",\"@cardStackId\",\"@modelsNeededForFirstEdit\",\"@class\",\"@isEditable\"],[\"Template Title\",[30,1,[\"attributes\",\"title\"]],[30,1,[\"id\"]],[28,[37,2],null,[[\"cardStack\",\"course\",\"planbook\"],[[30,1],[30,2],[30,3]]]],[29,[\"js-test-card-stack-title \",[28,[37,0],[\"lesson-title\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/customize-templ.css\"]]]]],[28,[37,3],[[30,1,[\"meta\",\"permissions\"]],\"can-edit-plan\"],null]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@isFullScreen\",\"@usedAs\",\"@cardStackId\",\"@permissions\",\"@planbookId\",\"@courseId\",\"@courseColor\",\"@modelsNeededForFirstEdit\",\"@courseStandardSetIds\",\"@planbookRecentStandardSetIds\",\"@sortedCards\",\"@sortableModel\"],[true,[30,1,[\"attributes\",\"usedAs\"]],[30,1,[\"id\"]],[30,1,[\"meta\",\"permissions\"]],[30,3,[\"id\"]],[30,2,[\"id\"]],[30,2,[\"attributes\",\"color\"]],[28,[37,2],null,[[\"cardStack\",\"course\",\"planbook\"],[[30,1],[30,2],[30,3]]]],[30,2,[\"attributes\",\"standardSetIds\"]],[30,3,[\"attributes\",\"recentStandardSetIds\"]],[30,0,[\"sortedCards\"]],[30,0,[\"sortedCards\"]]]],null],[1,\"\\n\"],[13]],[\"@cardStack\",\"@course\",\"@planbook\"],false,[\"local-class\",\"planner/card-stacks/card-stack-title\",\"hash\",\"includes\",\"planner/card-stacks/cards\"]]",
    "moduleName": "cc-frontend/components/planner/course/create-edit/customize-templ.hbs",
    "isStrictMode": false
  });

  let CustomizeTemplate = (_dec = (0, _component.tagName)(""), _dec2 = Ember.computed("cardStack.attributes.cards.[]"), _dec3 = Ember.computed("cardAttributes.@each.position"), _dec(_class = (_class2 = class CustomizeTemplate extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "cardStack", void 0);
    }

    get cardAttributes() {
      if ((0, _lodashEs.isNil)(this.cardStack) || (0, _lodashEs.isNil)(this.cardStack.attributes)) return [];
      let cards = this.cardStack.attributes.cards;
      return (0, _lodashEs.map)(cards, "attributes");
    }

    get sortedCards() {
      let cardAttributes = this.cardAttributes; // have to trigger the computed property above

      if ((0, _lodashEs.isArray)(cardAttributes) !== true || (0, _lodashEs.isNil)(this.cardStack) || (0, _lodashEs.isNil)(this.cardStack.attributes)) return undefined;
      let cards = this.cardStack.attributes.cards;
      return (0, _lodashEs.sortBy)(cards, "attributes.position");
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "cardAttributes", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "cardAttributes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sortedCards", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "sortedCards"), _class2.prototype)), _class2)) || _class);
  _exports.default = CustomizeTemplate;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CustomizeTemplate);
});