define("cc-frontend/lib/actions/course/calendar/COURSE_ROTATION_SET_TEMPLATE_ID", ["exports", "cc-frontend/lib/patch-updater", "lodash", "moment"], function (_exports, _patchUpdater, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "COURSE_ROTATION_SET_TEMPLATE_ID",
    params: {
      courseId: "string",
      rotationId: "string",
      semesterId: "string",
      dateId: "string",
      cardStackId: {
        type: ["string", "null"]
      },
      currentTemplateId: "string"
    },

    patches(payload) {
      return [_patchUpdater.default.create("course", payload.courseId, "attributes.calendar.dates", {
        id: payload.dateId
      }).set("embedded", "attributes.cardStackId", payload.cardStackId).inc("attributes.calendar.version", 1).patch];
    },

    undoPatches(payload) {
      return [_patchUpdater.default.create("course", payload.courseId, "attributes.calendar.dates", {
        id: payload.dateId
      }).set("embedded", "attributes.cardStackId", payload.currentTemplateId).inc("attributes.calendar.version", 1).patch];
    },

    narrative(payload, findFn, userId) {
      let course = findFn("course", payload.courseId);
      let planbook = findFn("planbook", course.relationships.planbook.data.id);
      let rotationCalendar = findFn("rotation-calendar", planbook.relationships.rotationCalendar.data.id);
      let models = {
        template: findFn("card-stack", payload.lessonTemplateId),
        course: course,
        planbook: planbook,
        rotationCalendar
      };

      let templateTitle = _lodash.default.get(models, "template.attributes.title");

      let rotation = _lodash.default.find(Ember.get(models, "rotationCalendar.attributes.rotations"), {
        id: payload.rotationId
      });

      let title = _lodash.default.get(rotation, "title");

      let formattedDate = (0, _moment.default)(payload.changeAfterDate).format("ddd, MMM. D, YYYY");
      return {
        title: `Changed the template`,
        titleForAnalytics: "Course - Change Template for Rotation",
        description: `Change the template to "${templateTitle}" for all lessons that happen on ${title}`,
        context: {
          templateTitle: Ember.get(models, "template.attributes.title"),
          courseTitle: Ember.get(models, "course.attributes.title"),
          planbookTitle: Ember.get(models, "planbook.attributes.title"),
          courseId: Ember.get(models, "course.id"),
          planbookId: Ember.get(models, "planbook.id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${models.course.id}`,
          to: [`course-edits:course-${models.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        }
      };
    }

  };
  _exports.default = _default;
});