define("cc-frontend/components/planner/navs/planbook-main.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "planbook-nav": "_planbook-nav_solxtw",
    "planbook-nav__left-nav": "_planbook-nav__left-nav_solxtw",
    "planbook-nav__right-nav": "_planbook-nav__right-nav_solxtw",
    "icon-button": "_icon-button_solxtw",
    "--home": "_--home_solxtw",
    "--undo": "_--undo_solxtw",
    "--help": "_--help_solxtw",
    "planbook-nav__center-nav": "_planbook-nav__center-nav_solxtw",
    "planbook-nav__center-nav__main": "_planbook-nav__center-nav__main_solxtw",
    "planbook-nav__center-nav__sub": "_planbook-nav__center-nav__sub_solxtw",
    "planbook-nav__center-nav__sub__left": "_planbook-nav__center-nav__sub__left_solxtw",
    "planbook-nav__center-nav__sub__right": "_planbook-nav__center-nav__sub__right_solxtw",
    "planbook-nav__center-nav__sub__trigger": "_planbook-nav__center-nav__sub__trigger_solxtw",
    "owner-info__avatar": "_owner-info__avatar_solxtw",
    "owner-info": "_owner-info_solxtw",
    "owner-info__right": "_owner-info__right_solxtw",
    "owner-info__name": "_owner-info__name_solxtw",
    "owner-info__planbook-title": "_owner-info__planbook-title_solxtw",
    "top-nav-buttons": "_top-nav-buttons_solxtw",
    "top-nav-button": "_top-nav-button_solxtw",
    "last-child": "_last-child_solxtw",
    "top-nav-button__icon": "_top-nav-button__icon_solxtw",
    "top-nav-button__main-title": "_top-nav-button__main-title_solxtw",
    "top-nav-button__right": "_top-nav-button__right_solxtw",
    "planbook-menu-trigger-wrapper": "_planbook-menu-trigger-wrapper_solxtw",
    "planbook-menu-trigger": "_planbook-menu-trigger_solxtw",
    "planbook-menu-text": "_planbook-menu-text_solxtw",
    "planbook-menu-trigger__icon": "_planbook-menu-trigger__icon_solxtw",
    "perspective-trigger__text": "_perspective-trigger__text_solxtw",
    "sign-in": "_sign-in_solxtw"
  };
  _exports.default = _default;
});