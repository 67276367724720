define("cc-frontend/templates/icons/-ios-cloud-download-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3V0ymCWl",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M193.2 381.2l-11.6 11.4 74.5 73.4 74.5-73.4-11.6-11.4-54.7 53.8V192.9h-16.4V435z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M399.2 164.4c0-1.2.2-2.3.2-3.5C399.4 97.4 346.5 46 282.6 46c-46.1 0-85.8 26.8-104.4 65.4-8.1-4-17.1-6.3-26.8-6.3-29.6 0-54.1 23.3-58.9 51.3-35.2 12-60.6 43.4-60.6 82.4 0 49.1 40.1 89.2 89.6 89.2H213v-16h-90.7c-40.9 0-74.2-33.2-74.2-73.7 0-31.4 20.2-57.5 50.2-67.7l8.4-2.8 1.5-8.7c3.6-21.3 22.1-38.8 43.9-38.8 6.9 0 13.7 1.6 19.9 4.7l13.5 6.7 6.5-13.6c16.6-34.4 52.1-56.6 90.4-56.6 55.3 0 100.9 42.8 100.9 97.6 0 13.1-.2 20.1-.2 20.1l15.2.1c36.7.5 65.6 30 65.6 66.4 0 36.3-29.8 66.1-66.5 66.3l-3.2.1H297v16h101c45 0 81.9-37.2 81.9-82.1 0-45-35.4-81.5-80.7-81.6z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ios-cloud-download-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});