define("cc-frontend/components/kb/entry/chapter.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "chapter": "_chapter_7dqfq4",
    "chapter-overview": "_chapter-overview_7dqfq4",
    "--sidebar": "_--sidebar_7dqfq4",
    "section": "_section_7dqfq4",
    "sections-list": "_sections-list_7dqfq4",
    "header__arrow": "_header__arrow_7dqfq4",
    "--full-width": "_--full-width_7dqfq4",
    "chapter-header": "_chapter-header_7dqfq4",
    "header__text-block": "_header__text-block_7dqfq4",
    "active": "_active_7dqfq4",
    "header__text-block__title": "_header__text-block__title_7dqfq4",
    "header__text-block__category": "_header__text-block__category_7dqfq4",
    "header__text-block__icon": "_header__text-block__icon_7dqfq4",
    "disabled": "_disabled_7dqfq4",
    "article-wrapper": "_article-wrapper_7dqfq4",
    "--collapsed": "_--collapsed_7dqfq4"
  };
  _exports.default = _default;
});