define("cc-frontend/templates/user/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s6e/m1+F",
    "block": "[[[10,0],[14,0,\"tw-h-12 tw-w-12 tw-ml-auto tw-mr-auto tw-flex tw-flex-row tw-items-center tw-justify-center tw-h-full\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n\"],[13]],[],false,[\"icons/spinning-circle\"]]",
    "moduleName": "cc-frontend/templates/user/sign-out.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});