define("cc-frontend/components/icons/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M12.743,23.5c-0.122,0 -0.239,-0.044 -0.33,-0.124l-11.644,-10.251c-0.047,-0.042 -0.092,-0.087 -0.134,-0.134c-0.218,-0.247 -0.344,-0.555 -0.365,-0.893c-0.006,-0.026 -0.013,-0.059 -0.013,-0.098c0,-0.026 0.003,-0.048 0.007,-0.066c0,-0.009 0,-0.02 0,-0.03c0.025,-0.399 0.204,-0.765 0.505,-1.03l11.643,-10.249c0.092,-0.081 0.209,-0.125 0.331,-0.125c0.144,0 0.28,0.062 0.375,0.169c0.182,0.207 0.162,0.524 -0.045,0.706l-11.502,10.125h21.671c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-21.668l11.5,10.125c0.207,0.182 0.227,0.499 0.045,0.706c-0.096,0.107 -0.233,0.169 -0.376,0.169Z"
    ></path>
  </svg>
  */
  {
    "id": "iP2XeR7A",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M12.743,23.5c-0.122,0 -0.239,-0.044 -0.33,-0.124l-11.644,-10.251c-0.047,-0.042 -0.092,-0.087 -0.134,-0.134c-0.218,-0.247 -0.344,-0.555 -0.365,-0.893c-0.006,-0.026 -0.013,-0.059 -0.013,-0.098c0,-0.026 0.003,-0.048 0.007,-0.066c0,-0.009 0,-0.02 0,-0.03c0.025,-0.399 0.204,-0.765 0.505,-1.03l11.643,-10.249c0.092,-0.081 0.209,-0.125 0.331,-0.125c0.144,0 0.28,0.062 0.375,0.169c0.182,0.207 0.162,0.524 -0.045,0.706l-11.502,10.125h21.671c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-21.668l11.5,10.125c0.207,0.182 0.227,0.499 0.045,0.706c-0.096,0.107 -0.233,0.169 -0.376,0.169Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/arrow-left.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});