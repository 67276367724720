define("cc-frontend/components/d-s/dropdown-menu/single-button-row.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "single-button-row": "_single-button-row_1g11o7",
    "--is-at-top-of-menu": "_--is-at-top-of-menu_1g11o7",
    "single-button-row__text": "_single-button-row__text_1g11o7",
    "single-button-row__icon": "_single-button-row__icon_1g11o7",
    "single-button-row--is-secondary": "_single-button-row--is-secondary_1g11o7"
  };
  _exports.default = _default;
});