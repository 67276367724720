define("cc-frontend/components/planner/planbook/clickable-pill", ["exports", "cc-frontend/helpers/find-document"], function (_exports, _findDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.planbookSummary.id}}
    <div class="planbook-pill" {{on "click" @onPlanbookClick}}>
      <ContainerUserSummary @userId={{this.planbookSummary.relationships.owner.data.id}} as |user|>
        <User::Avatar @userSummary={{user.content}} @avatarSizeClass="user-avatar--lg" />
        <div class="planbook-pill__text">
          <div class="planbook-pill__owner-name">
            {{user.content.attributes.firstName}}
            {{user.content.attributes.lastName}}
          </div>
          <div class="planbook-pill__planbook-title">
            {{this.planbookSummary.attributes.title}}
          </div>
        </div>
        <div class="planbook-pill__background">
          <div class="planbook-pill__background__fade"></div>
          <div
            class="planbook-pill__background__image {{this.planbookSummary.attributes.cover}}"
          ></div>
        </div>
      </ContainerUserSummary>
    </div>
  {{/if}}
  */
  {
    "id": "k2lmXirM",
    "block": "[[[41,[30,0,[\"planbookSummary\",\"id\"]],[[[1,\"  \"],[11,0],[24,0,\"planbook-pill\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@userId\"],[[30,0,[\"planbookSummary\",\"relationships\",\"owner\",\"data\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@userSummary\",\"@avatarSizeClass\"],[[30,2,[\"content\"]],\"user-avatar--lg\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"planbook-pill__text\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"planbook-pill__owner-name\"],[12],[1,\"\\n          \"],[1,[30,2,[\"content\",\"attributes\",\"firstName\"]]],[1,\"\\n          \"],[1,[30,2,[\"content\",\"attributes\",\"lastName\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"planbook-pill__planbook-title\"],[12],[1,\"\\n          \"],[1,[30,0,[\"planbookSummary\",\"attributes\",\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"planbook-pill__background\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"planbook-pill__background__fade\"],[12],[13],[1,\"\\n        \"],[10,0],[15,0,[29,[\"planbook-pill__background__image \",[30,0,[\"planbookSummary\",\"attributes\",\"cover\"]]]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@onPlanbookClick\",\"user\"],false,[\"if\",\"on\",\"container-user-summary\",\"user/avatar\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/clickable-pill.hbs",
    "isStrictMode": false
  });

  let ClickablePillComponent = (_dec = Ember.inject.service, _dec2 = (0, _findDocument.default)("planbookId", "planbook-summary"), (_class = class ClickablePillComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "planbookSummary", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "planbookSummary", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ClickablePillComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ClickablePillComponent);
});