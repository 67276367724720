define("cc-frontend/components/icons/add-no-circle-heavier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! ionicons 4 - ios add }}
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M21.6,10.6h-8.3V2.4C13.4,1.6,12.8,1,12,1s-1.4,0.6-1.4,1.4v8.3H2.4C1.6,10.6,1,11.2,1,12c0,0.4,0.2,0.7,0.4,1 c0.2,0.2,0.6,0.4,1,0.4h8.3v8.3c0,0.4,0.2,0.7,0.4,1c0.2,0.2,0.6,0.4,1,0.4c0.8,0,1.4-0.6,1.4-1.4v-8.3h8.3c0.8,0,1.4-0.6,1.4-1.4 S22.4,10.6,21.6,10.6z"
    ></path>
  </svg>
  */
  {
    "id": "qGCj+41l",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"24px\"],[14,\"height\",\"24px\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M21.6,10.6h-8.3V2.4C13.4,1.6,12.8,1,12,1s-1.4,0.6-1.4,1.4v8.3H2.4C1.6,10.6,1,11.2,1,12c0,0.4,0.2,0.7,0.4,1 c0.2,0.2,0.6,0.4,1,0.4h8.3v8.3c0,0.4,0.2,0.7,0.4,1c0.2,0.2,0.6,0.4,1,0.4c0.8,0,1.4-0.6,1.4-1.4v-8.3h8.3c0.8,0,1.4-0.6,1.4-1.4 S22.4,10.6,21.6,10.6z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/add-no-circle-heavier.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});