define("cc-frontend/components/admin/go-to-document", ["exports", "@glint/environment-ember-loose/glimmer-component", "tracked-built-ins"], function (_exports, _glimmerComponent, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.session.user.attributes.isSupport}}
    <form>
      <div class="tw-flex tw-flex-row">
        <select
          class="tw-w-1/3 sierra-subtle-dropdown-trigger tw-rounded-full"
          {{on "change" (pick "target.value" (set this "documentType"))}}
        >
          <option value>
            Select an option below
          </option>
          <option value="planbook">
            Planbook
          </option>
          <option value="course">
            Course
          </option>
          <option value="user">
            User
          </option>
          <option value="card-stack">
            Card Stack
          </option>
          <option value="action">
            Action
          </option>
        </select>
        <Input
          @value={{this.documentId}}
          placeholder="Enter the id"
          class="tw-w-1/3 tw-border-dividers tw-border tw-text-center tw-rounded-full tw-mx-8"
        />
        <button class="tw-p-8 tw-w-1/3 sierra-button" type="button" {{on "click" this.goToDocument}}>
          Go to document
        </button>
      </div>
    </form>
  {{else}}
    Must be logged into a support account
  {{/if}}
  */
  {
    "id": "Cx9lz7u1",
    "block": "[[[41,[30,0,[\"session\",\"user\",\"attributes\",\"isSupport\"]],[[[1,\"  \"],[10,\"form\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tw-flex tw-flex-row\"],[12],[1,\"\\n      \"],[11,\"select\"],[24,0,\"tw-w-1/3 sierra-subtle-dropdown-trigger tw-rounded-full\"],[4,[38,1],[\"change\",[28,[37,2],[\"target.value\",[28,[37,3],[[30,0],\"documentType\"],null]],null]],null],[12],[1,\"\\n        \"],[10,\"option\"],[14,2,\"\"],[12],[1,\"\\n          Select an option below\\n        \"],[13],[1,\"\\n        \"],[10,\"option\"],[14,2,\"planbook\"],[12],[1,\"\\n          Planbook\\n        \"],[13],[1,\"\\n        \"],[10,\"option\"],[14,2,\"course\"],[12],[1,\"\\n          Course\\n        \"],[13],[1,\"\\n        \"],[10,\"option\"],[14,2,\"user\"],[12],[1,\"\\n          User\\n        \"],[13],[1,\"\\n        \"],[10,\"option\"],[14,2,\"card-stack\"],[12],[1,\"\\n          Card Stack\\n        \"],[13],[1,\"\\n        \"],[10,\"option\"],[14,2,\"action\"],[12],[1,\"\\n          Action\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,4],[[24,\"placeholder\",\"Enter the id\"],[24,0,\"tw-w-1/3 tw-border-dividers tw-border tw-text-center tw-rounded-full tw-mx-8\"]],[[\"@value\"],[[30,0,[\"documentId\"]]]],null],[1,\"\\n      \"],[11,\"button\"],[24,0,\"tw-p-8 tw-w-1/3 sierra-button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"goToDocument\"]]],null],[12],[1,\"\\n        Go to document\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  Must be logged into a support account\\n\"]],[]]]],[],false,[\"if\",\"on\",\"pick\",\"set\",\"input\"]]",
    "moduleName": "cc-frontend/components/admin/go-to-document.hbs",
    "isStrictMode": false
  });

  let AdminGoToDocument = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class AdminGoToDocument extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "documentId", _descriptor4, this);

      _initializerDefineProperty(this, "documentType", _descriptor5, this);
    }

    goToDocument() {
      Ember.getOwner(this).lookup("router:main").transitionTo(`admin.${this.documentType}`, this.documentId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "documentId", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "documentType", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "goToDocument", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "goToDocument"), _class.prototype)), _class));
  _exports.default = AdminGoToDocument;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AdminGoToDocument);
});