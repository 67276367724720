define("cc-frontend/components/planner/menus/perspective-trigger", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="perspective-trigger-wrapper">
    <div local-class="perspective-trigger">
      <div local-class="perspective-trigger__text">
        {{@text}}
      </div>
      <div local-class="perspective-trigger__icon">
        <Icons::EllipsesVertical />
      </div>
    </div>
  </div>
  */
  {
    "id": "CH2puO5w",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"perspective-trigger-wrapper\"],[[\"from\"],[\"cc-frontend/components/planner/menus/perspective-trigger.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"perspective-trigger\"],[[\"from\"],[\"cc-frontend/components/planner/menus/perspective-trigger.css\"]]]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,0],[\"perspective-trigger__text\"],[[\"from\"],[\"cc-frontend/components/planner/menus/perspective-trigger.css\"]]]]]],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,0],[\"perspective-trigger__icon\"],[[\"from\"],[\"cc-frontend/components/planner/menus/perspective-trigger.css\"]]]]]],[12],[1,\"\\n      \"],[8,[39,1],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@text\"],false,[\"local-class\",\"icons/ellipses-vertical\"]]",
    "moduleName": "cc-frontend/components/planner/menus/perspective-trigger.hbs",
    "isStrictMode": false
  });

  let PerspectiveTrigger = (_dec = (0, _component.tagName)(""), _dec(_class = class PerspectiveTrigger extends Ember.Component {}) || _class);
  _exports.default = PerspectiveTrigger;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PerspectiveTrigger);
});