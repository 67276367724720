define("cc-frontend/models/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CourseColor = void 0;
  let CourseColor;
  _exports.CourseColor = CourseColor;

  (function (CourseColor) {
    CourseColor["Yellow"] = "yellow";
    CourseColor["MutedYellow"] = "muted-yellow";
    CourseColor["MutedGreen"] = "muted-green";
    CourseColor["Green"] = "green";
    CourseColor["Turquoise"] = "turquoise";
    CourseColor["MutedTurquoise"] = "muted-turquoise";
    CourseColor["MutedCyan"] = "muted-cyan";
    CourseColor["Cyan"] = "cyan";
    CourseColor["Blue"] = "blue";
    CourseColor["MutedBlue"] = "muted-blue";
    CourseColor["MutedPurple"] = "muted-purple";
    CourseColor["Purple"] = "purple";
    CourseColor["Pink"] = "pink";
    CourseColor["MutedPink"] = "muted-pink";
    CourseColor["MutedCoral"] = "muted-coral";
    CourseColor["Coral"] = "coral";
  })(CourseColor || (_exports.CourseColor = CourseColor = {}));
});