define("cc-frontend/components/planner/menus/day-menu/add-special-event", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToElsewhere
    @named="day-menu-title"
    @send={{component
      "planner/menus/date-annotation/edit-annotation-title"
      goBack=@goBack
      title="Add a Special Event"
    }}
  />
  
  <ToElsewhere
    @named="day-menu-body"
    @send={{component
      "planner/modals/edit-annotation-body"
      rotationCalendar=@rotationCalendar
      planbook=@planbook
      startDate=@dateString
      endDate=@dateString
      title=null
      description=null
      submitAction=this.createAnnotation
      submitButtonText="Create"
      allowRangeToggle=true
      datesAreEditable=true
      dateIs="FIXED_START_RANGE"
    }}
  />
  */
  {
    "id": "oEJAoKKs",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"day-menu-title\",[50,\"planner/menus/date-annotation/edit-annotation-title\",0,null,[[\"goBack\",\"title\"],[[30,1],\"Add a Special Event\"]]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@named\",\"@send\"],[\"day-menu-body\",[50,\"planner/modals/edit-annotation-body\",0,null,[[\"rotationCalendar\",\"planbook\",\"startDate\",\"endDate\",\"title\",\"description\",\"submitAction\",\"submitButtonText\",\"allowRangeToggle\",\"datesAreEditable\",\"dateIs\"],[[30,2],[30,3],[30,4],[30,4],null,null,[30,0,[\"createAnnotation\"]],\"Create\",true,true,\"FIXED_START_RANGE\"]]]]],null]],[\"@goBack\",\"@rotationCalendar\",\"@planbook\",\"@dateString\"],false,[\"to-elsewhere\",\"component\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/add-special-event.hbs",
    "isStrictMode": false
  });

  let DayMenuAddSpecialEvent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec(_class = (_class2 = class DayMenuAddSpecialEvent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "courses", void 0);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "alreadyReceivedCourse", false);

      _defineProperty(this, "goBack", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _defineProperty(this, "rotationCalendar", void 0);

      _initializerDefineProperty(this, "planbookEditor", _descriptor, this);
    }

    createAnnotation({
      title,
      description,
      startDate,
      endDate
    }) {
      this.planbookEditor.addAnnotation({
        title: title,
        description: description,
        startDate: startDate,
        endDate: endDate,
        isOff: false,
        planbook: this.planbook
      });
      this.closeMenu();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "planbookEditor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "createAnnotation", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "createAnnotation"), _class2.prototype)), _class2)) || _class);
  _exports.default = DayMenuAddSpecialEvent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DayMenuAddSpecialEvent);
});