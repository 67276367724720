define("cc-frontend/components/planner/menus/share-link/show-link-body", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="show-link">
    <div local-class="link-box">
      <a href={{@linkUrl}} target="_blank" rel="noopener">
        {{@linkUrl}}
      </a>
    </div>
    <CopyButton
      class={{local-class "copy-box-wrapper"}}
      @clipboardText={{@linkUrl}}
      @success={{this.copyLink}}
    >
      <Planner::Menus::Parts::OutlineButton data-test-copy-link-button>
        {{this.linkButtonText}}
      </Planner::Menus::Parts::OutlineButton>
    </CopyButton>
  </div>
  */
  {
    "id": "PFuiLB9d",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"show-link\"],[[\"from\"],[\"cc-frontend/components/planner/menus/share-link/show-link-body.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"link-box\"],[[\"from\"],[\"cc-frontend/components/planner/menus/share-link/show-link-body.css\"]]]]]],[12],[1,\"\\n    \"],[10,3],[15,6,[30,1]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[16,0,[28,[37,0],[\"copy-box-wrapper\"],[[\"from\"],[\"cc-frontend/components/planner/menus/share-link/show-link-body.css\"]]]]],[[\"@clipboardText\",\"@success\"],[[30,1],[30,0,[\"copyLink\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,\"data-test-copy-link-button\",\"\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,0,[\"linkButtonText\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@linkUrl\"],false,[\"local-class\",\"copy-button\",\"planner/menus/parts/outline-button\"]]",
    "moduleName": "cc-frontend/components/planner/menus/share-link/show-link-body.hbs",
    "isStrictMode": false
  });

  let CannotShareBodyComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember._action, _dec(_class = (_class2 = class CannotShareBodyComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "goBack", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _defineProperty(this, "linkButtonText", "Copy Link");
    }

    // actions
    copyLink() {
      this.set("linkButtonText", "Copied!");
      Ember.run.later(() => {
        this.closeMenu();
      }, 1000);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "copyLink", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "copyLink"), _class2.prototype)), _class2)) || _class);
  _exports.default = CannotShareBodyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CannotShareBodyComponent);
});