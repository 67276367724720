define("cc-frontend/templates/admin/card-stack/version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "62gCPtwi",
    "block": "[[[10,\"h1\"],[12],[1,\"\\n  Version\\n  \"],[1,[33,0,[\"attributes\",\"version\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[12],[1,\"\\n  \"],[1,[33,0,[\"attributes\",\"narrative\",\"title\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h3\"],[12],[1,\"\\n  \"],[1,[33,0,[\"attributes\",\"narrative\",\"description\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[11,\"button\"],[4,[38,1],[[30,0],\"rollBack\",[33,0,[\"id\"]]],null],[12],[1,\"\\n  Rollback\\n\"],[13],[1,\"\\n\"],[10,\"pre\"],[12],[1,\"  \"],[10,\"code\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[33,0,[\"attributes\",\"originalDocument\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"model\",\"action\",\"json-stringify\"]]",
    "moduleName": "cc-frontend/templates/admin/card-stack/version.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});