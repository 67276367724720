define("cc-frontend/components/planner/menus/day-menu/change-end-of-school-year/alert-too-long-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="g-dropdown-menu__header">
    A planbook cannot be longer than 365 days.
  </div>
  <div class="g-dropdown-menu__subheader">
    This date is
    {{@daysAfterStartDate}}
    days after the start of your school year.
  </div>
  <div class="tw-flex tw-flex-col">
    <Planner::Menus::Parts::OutlineButton @onClick={{fn @confirmButton}}>
      Okay
    </Planner::Menus::Parts::OutlineButton>
  </div>
  */
  {
    "id": "UxFGXcf4",
    "block": "[[[10,0],[14,0,\"g-dropdown-menu__header\"],[12],[1,\"\\n  A planbook cannot be longer than 365 days.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"g-dropdown-menu__subheader\"],[12],[1,\"\\n  This date is\\n  \"],[1,[30,1]],[1,\"\\n  days after the start of your school year.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"tw-flex tw-flex-col\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@onClick\"],[[28,[37,1],[[30,2]],null]]],[[\"default\"],[[[[1,\"\\n    Okay\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@daysAfterStartDate\",\"@confirmButton\"],false,[\"planner/menus/parts/outline-button\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/change-end-of-school-year/alert-too-long-body.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});