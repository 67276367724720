define("cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_UPDATE", ["exports", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models", "moment"], function (_exports, _datesOffCalculator, _patchUpdater, _lodash, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_DATE_ANNOTATION_UPDATE = void 0;
  const COURSE_DATE_ANNOTATION_UPDATE = {
    name: "COURSE_DATE_ANNOTATION_UPDATE ",
    params: {
      annotation: "object",
      course: "object"
    },

    patches(payload) {
      let newAnnotations = _lodash.default.map(payload.course.attributes.calendar.dateAnnotations, annotation => {
        return annotation.id === payload.annotation.id ? payload.annotation : annotation;
      });

      let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(newAnnotations);

      let patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dateAnnotations", {
        id: payload.annotation.id
      }).set("embedded", `attributes`, payload.annotation.attributes).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatches(payload) {
      let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(payload.course.attributes.calendar.dateAnnotations); // this feels heavy handed. Would be better if we just find that single annotation.

      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dateAnnotations", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dateAnnotations))).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
      let context = (0, _defaultCourseDateContext.default)(payload, models);
      let formattedDateString = (0, _moment.default)(payload.annotation.attributes.dateString).format("ddd, MMM. D, YYYY");

      let originalAnnotation = _lodash.default.find(payload.course.attributes.calendar.dateAnnotations, annotation => {
        return annotation.id === payload.annotation.id;
      });

      let fieldsEdited = payload.annotation.attributes.title !== originalAnnotation.title ? payload.annotation.attributes.description !== originalAnnotation.description ? "title and description" : "title" : payload.annotation.attributes.description !== originalAnnotation.description ? "description" : "";

      let newContext = _lodash.default.assign({}, context, {
        fieldsEdited: fieldsEdited
      });

      return {
        // janelle doesn't love this but she also doesn't hate it
        title: "Edited Note for Turned-off Class",
        titleForAnalytics: "Lesson - Update Annotation",
        description: `Changed the ${fieldsEdited} for the note on your "${context.courseTitle}" class on ${formattedDateString}`,
        context: newContext,
        activity: (0, _defaultCourseDateActivity.default)(payload, models)
      };
    }

  };
  _exports.COURSE_DATE_ANNOTATION_UPDATE = COURSE_DATE_ANNOTATION_UPDATE;
  var _default = COURSE_DATE_ANNOTATION_UPDATE;
  _exports.default = _default;
});