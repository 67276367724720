define("cc-frontend/services/mouse-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MouseEvents extends Ember.Service.extend(Ember.Evented) {
    init() {
      super.init(...arguments);
      Ember.$(document).on("mousedown", e => this.trigger("mouseDown", e)); // this.on('mouseDown', function(e){
      //   console.log('e', e)
      // })
    }

    willDestroy() {
      super.willDestroy(...arguments);
      Ember.$(document).off("mousedown", this.trigger.bind("mouseDown"));
    }

  }

  _exports.default = MouseEvents;
});