define("cc-frontend/components/icons/office-file-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M3.5 24c-.827 0-1.5-.68-1.5-1.5v-21C2 .67 2.673 0 3.5 0h13.293c.42.012.78.163 1.06.43l3.7 3.71c.28.28.43.66.43 1.06v17.293c0 .82-.68 1.5-1.5 1.5h-17Zm0-23c-.28 0-.5.22-.5.5v21c0 .27.22.5.5.5h17c.27 0 .5-.23.5-.5V5.2c0-.14-.06-.26-.15-.36L17.14 1.13c-.1-.1-.22-.15-.36-.15H3.48Z"
      ></path>
      <path
        d="M14.5 5.99c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h4c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-4Z"
      ></path>
      <path
        d="M5.5 14.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h13c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-13Z"
      ></path>
      <path
        d="M5.5 17.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h13c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-13Z"
      ></path>
      <path
        d="M5.5 20.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h13c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-13Z"
      ></path>
      <path
        d="M14.5 8.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h4c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-4Z"
      ></path>
      <path
        d="M14.5 11.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h4c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-4Z"
      ></path>
      <path
        d="M6.5 11.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5H8v-6H6v.5c0 .27-.23.5-.5.5 -.28 0-.5-.23-.5-.5v-1c0-.28.22-.5.5-.5h6c.27 0 .5.22.5.5v1c0 .27-.23.5-.5.5 -.28 0-.5-.23-.5-.5v-.5H9v6h1.5c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-4Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "j7FUO6GZ",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3.5 24c-.827 0-1.5-.68-1.5-1.5v-21C2 .67 2.673 0 3.5 0h13.293c.42.012.78.163 1.06.43l3.7 3.71c.28.28.43.66.43 1.06v17.293c0 .82-.68 1.5-1.5 1.5h-17Zm0-23c-.28 0-.5.22-.5.5v21c0 .27.22.5.5.5h17c.27 0 .5-.23.5-.5V5.2c0-.14-.06-.26-.15-.36L17.14 1.13c-.1-.1-.22-.15-.36-.15H3.48Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M14.5 5.99c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h4c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-4Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M5.5 14.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h13c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-13Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M5.5 17.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h13c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-13Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M5.5 20.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h13c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-13Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M14.5 8.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h4c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-4Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M14.5 11.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5h4c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-4Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M6.5 11.999c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5H8v-6H6v.5c0 .27-.23.5-.5.5 -.28 0-.5-.23-.5-.5v-1c0-.28.22-.5.5-.5h6c.27 0 .5.22.5.5v1c0 .27-.23.5-.5.5 -.28 0-.5-.23-.5-.5v-.5H9v6h1.5c.27 0 .5.22.5.5 0 .27-.23.5-.5.5h-4Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/office-file-text.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});