define("cc-frontend/templates/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V2gpHYav",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[33,3],\"user.sign\"],null],[28,[37,2],[[33,3],\"user.sign-in\"],null],[28,[37,2],[[33,3],\"user.sign-up\"],null],[28,[37,2],[[33,3],\"user.reset-password\"],null]],null],[[[1,\"  \"],[8,[39,4],null,[[\"@title\"],[[36,3]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@isSignRoute\",\"@isSignInRoute\",\"@isSignUpRoute\",\"@isResetPasswordRoute\",\"@signUpStep\"],[[28,[37,2],[[33,3],\"user.sign\"],null],[28,[37,2],[[33,3],\"user.sign-in\"],null],[28,[37,2],[[33,3],\"user.sign-up\"],null],[28,[37,2],[[33,3],\"user.reset-password\"],null],[36,6]]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[[33,3],\"user.reset-password-confirmation\"],null],[[[1,\"  \"],[8,[39,4],null,[[\"@title\"],[\"user.reset-password-confirmation\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"user-sign-wrapper user-sign-wrapper--reset-password\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"user-sign__title\"],[12],[1,\"\\n      Your password reset email is on the way!\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"user-sign__text\"],[12],[1,\"\\n      We just sent you a password reset password email. You should receive it in the next minute. If\\n      you don't see it, check your spam folder. If you still don't see it, email\\n      \"],[10,3],[14,6,\"mailto:support@commoncurriculum.com\"],[12],[1,\"\\n        support@commoncurriculum.com\\n      \"],[13],[1,\"\\n      and we'll help you out.\\n    \"],[13],[1,\"\\n    \"],[8,[39,7],[[24,0,\"btn btn-default user-sign__submit-btn user-sign__submit-btn--reset-password\"]],[[\"@route\"],[\"user.sign-in\"]],[[\"default\"],[[[[1,\"\\n      Back to Sign In\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[46,[28,[37,9],null,null],null,null,null]],[],false,[\"if\",\"or\",\"eq\",\"currentPath\",\"record-page-visit\",\"user/signs\",\"step\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/user.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});