define("cc-frontend/helpers/pluralize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pluralize = pluralize;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function pluralize(params) {
    let len = params[0];
    let singular = params[1];
    let plural = params[2];
    return len === null || len === undefined || len === 0 || len > 1 ? plural : singular;
  }

  class Pluralize extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "compute", pluralize);
    }

  }

  _exports.default = Pluralize;
});