define("cc-frontend/lib/actions/user/USER_SIGN_ERROR", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = USER_SIGN_ERROR;

  function USER_SIGN_ERROR(payload) {
    return {
      name: "USER_SIGN_ERROR",
      payload: payload,
      patches: null,
      undoPatches: null,

      narrative(findFn, _userId) {
        return {
          titleForAnalytics: payload.titleForAnalytics
        };
      }

    };
  }
});