define("cc-frontend/lib/actions/course/calendar/MOVE_MULTIPLE_LESSONS", ["exports", "lodash", "lodash-es", "cc-frontend/lib/actions/course/calendar/MOVE_LESSON_BY_AMOUNT"], function (_exports, _lodash, _lodashEs, _MOVE_LESSON_BY_AMOUNT) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareMoveMultipleLessons = prepareMoveMultipleLessons;
  _exports.MOVE_MULTIPLE_LESSONS = void 0;

  function prepareMoveMultipleLessons(payload) {
    return {
      name: MOVE_MULTIPLE_LESSONS.name,
      payload: payload,
      patches: MOVE_MULTIPLE_LESSONS.patches(payload),
      undoPatches: MOVE_MULTIPLE_LESSONS.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(MOVE_MULTIPLE_LESSONS.narrative, payload)
    };
  }

  const MOVE_MULTIPLE_LESSONS = {
    name: "MOVE_MULTIPLE_LESSONS",

    patches(payload) {
      return _lodash.default.flatMap(payload.courses, course => {
        return _MOVE_LESSON_BY_AMOUNT.MOVE_LESSON_BY_AMOUNT.patches({
          amount: payload.amount,
          dateString: payload.dateString,
          course: course,
          planbook: payload.planbook,
          rotationCalendar: payload.rotationCalendar
        });
      });
    },

    undoPatches(payload) {
      return _lodash.default.flatMap(payload.courses, course => {
        return _MOVE_LESSON_BY_AMOUNT.MOVE_LESSON_BY_AMOUNT.undoPatches({
          amount: payload.amount,
          dateString: payload.dateString,
          course: course,
          planbook: payload.planbook,
          rotationCalendar: payload.rotationCalendar
        });
      });
    },

    narrative(payload, findFn, userId) {
      let planbookRelationship = payload.courses[0].relationships.planbook;
      if (planbookRelationship === undefined) throw Error("The Course's doesn't have a planbook specified. If we start having courses without planbooks, we need to change this."); // @ts-ignore error thrown above in case undefined

      findFn("planbook", payload.courses[0].relationships.planbook.data.id);
      let planbookId = payload.planbook.id;

      let activityToValues = _lodash.default.map(payload.courses, course => {
        ;
        `course-edits:course-${course.id}`;
      }); // @ts-ignore not sure how it's getting type void for this - map returns a value


      activityToValues.push(`planbook-edits:planbook-${planbookId}`);
      let amount = Math.abs(payload.amount);
      let courseCount = payload.courses.length;
      let direction = payload.amount > 0 ? "forward" : "backwards";
      let dayOrDays = payload.amount === 1 ? "day" : "days"; // @ts-ignore to types for moment

      let formattedDate = moment(payload.dateString).format("ddd, MMM. D, YYYY");
      return {
        title: "Bumped Lesson Forward for the day",
        titleForAnalytics: "Day - Bumped",
        description: `Moved ${courseCount} lessons ${direction} on ${formattedDate} ${amount} ${dayOrDays}`,
        context: {
          courseIds: _lodash.default.map(payload.courses, "id"),
          planbookTitle: payload.planbook.attributes.title,
          planbookId: payload.planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `planbook-${planbookId}`,
          to: activityToValues
        }
      };
    }

  };
  _exports.MOVE_MULTIPLE_LESSONS = MOVE_MULTIPLE_LESSONS;
});