define("cc-frontend/models/planbook-calendar-date-combined", ["exports", "@marcj/marshal"], function (_exports, _marshal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlanbookCalendarDateCombined = (_class = class PlanbookCalendarDateCombined {
    constructor() {
      _initializerDefineProperty(this, "planbookDateId", _descriptor, this);

      _initializerDefineProperty(this, "rotationCalendarException", _descriptor2, this);

      _initializerDefineProperty(this, "isBlockSchedule", _descriptor3, this);

      _initializerDefineProperty(this, "dateString", _descriptor4, this);

      _initializerDefineProperty(this, "cardStackId", _descriptor5, this);

      _initializerDefineProperty(this, "cardStackTemplateId", _descriptor6, this);

      _initializerDefineProperty(this, "isUpdatingFromTemplate", _descriptor7, this);

      _initializerDefineProperty(this, "rotationDayTitle", _descriptor8, this);

      _initializerDefineProperty(this, "forceIsOn", _descriptor9, this);

      _initializerDefineProperty(this, "isOff", _descriptor10, this);

      _initializerDefineProperty(this, "isBeforeSchoolYear", _descriptor11, this);

      _initializerDefineProperty(this, "isAfterSchoolYear", _descriptor12, this);

      _initializerDefineProperty(this, "isInsideSchoolYear", _descriptor13, this);

      _initializerDefineProperty(this, "firstDayOfSchoolYear", _descriptor14, this);

      _initializerDefineProperty(this, "lastDayOfSchoolYear", _descriptor15, this);

      _initializerDefineProperty(this, "daysBeforeFirstDay", _descriptor16, this);

      _initializerDefineProperty(this, "daysAfterLastDay", _descriptor17, this);

      _initializerDefineProperty(this, "dateAnnotations", _descriptor18, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "planbookDateId", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rotationCalendarException", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isBlockSchedule", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dateString", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cardStackId", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cardStackTemplateId", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isUpdatingFromTemplate", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rotationDayTitle", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "forceIsOn", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isOff", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isBeforeSchoolYear", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isAfterSchoolYear", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isInsideSchoolYear", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "firstDayOfSchoolYear", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "lastDayOfSchoolYear", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "daysBeforeFirstDay", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "daysAfterLastDay", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "dateAnnotations", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = PlanbookCalendarDateCombined;
});