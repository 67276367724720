define("cc-frontend/routes/new-group/show/edit", ["exports", "ember-animated/easings/cosine", "ember-animated/motions/move", "ember-animated/motions/opacity"], function (_exports, _cosine, _move, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class EditRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modalManager", _descriptor2, this);
    }

    *transition({
      insertedSprites,
      keptSprites,
      removedSprites
    }) {
      insertedSprites.forEach(sprite => {
        sprite.startAtPixel({
          x: window.innerWidth
        });
        (0, _move.default)(sprite, {
          easing: _cosine.easeOut,
          duration: 150
        });
        (0, _opacity.fadeIn)(sprite, {
          easing: _cosine.easeOut,
          duration: 150
        });
      });
      keptSprites.forEach(_move.default);
      removedSprites.forEach(sprite => {
        sprite.endAtPixel({
          x: window.innerWidth
        });
        (0, _move.default)(sprite, {
          easing: _cosine.easeIn,
          duration: 150
        });
        (0, _opacity.fadeOut)(sprite, {
          easing: _cosine.easeIn,
          duration: 150
        });
      });
    }

    model(params) {
      let transition = function* ({
        insertedSprites,
        keptSprites,
        removedSprites
      }) {
        insertedSprites.forEach(sprite => {
          sprite.startAtPixel({
            x: window.innerWidth
          });
          (0, _move.default)(sprite, {
            easing: _cosine.easeOut,
            duration: 150
          });
          (0, _opacity.fadeIn)(sprite, {
            easing: _cosine.easeOut,
            duration: 150
          });
        });
        keptSprites.forEach(_move.default);
        removedSprites.forEach(sprite => {
          sprite.endAtPixel({
            x: window.innerWidth
          });
          (0, _move.default)(sprite, {
            easing: _cosine.easeIn,
            duration: 150
          });
          (0, _opacity.fadeOut)(sprite, {
            easing: _cosine.easeIn,
            duration: 150
          });
        });
      }; // alert("showScreen: false")


      return this.modelFor("new-group.show"); //
      // return new Ember.RSVP.Promise(resolve => {
      //   get(this, "store")
      //     .find("group", params.id)
      //     .then(group => {
      //       console.log("group", group)
      //       resolve({
      //         group: group,
      //         transition: transition,
      //         showScreen: true
      //       })
      //     })
      // })
    }

    afterModel(model) {
      Ember.run.later(() => {
        // alert("showScreen: true")
        Ember.get(this, "modalManager").openModalOutsideManager();
        Ember.set(model, "showScreen", true);
      }, 50); // get(this, "modalManager").openModal("school/setup", {
      //   school: get(this, "model")
      // })
    }

    deactivate() {
      Ember.get(this, "modalManager").closeModalOutsideManager(); // get(this, "modalManager").closeModal("school/setup")
    }

    didTransition() {
      // BLOGGABLE TRICK
      Ember.run.scheduleOnce("afterRender", () => {
        let applicationController = Ember.getOwner(this).lookup("controller:application");
        let currentRouteName = Ember.get(applicationController, "currentRouteName");
        let group = Ember.get(this, "currentModel");

        if (currentRouteName === "new-group.show.edit.index") {
          // If it's a school network...
          if (group.attributes.usedAs === "school-network") {
            if ( // ...we want to prevent them from getting to the "Add Teachers" screen at all and instead take them to the "Add Admins" screen
            Ember.isNone(this.lastRouteVisited) || this.lastRouteVisited === "new-group.show.edit.members" || this.lastRouteVisited === "new-group.show.edit.members.index") {
              this.lastRouteVisited = "new-group.show.edit.admins";
            } // ... but if it's NOT a school network...

          } else {
            if ( // ... we want to prevent them from getting to the "Add Admins" screen at all and instead take them to the "Add Teachers" screen.
            Ember.isNone(this.lastRouteVisited) || this.lastRouteVisited === "new-group.show.edit.admins" || this.lastRouteVisited === "new-group.show.edit.admins.index") {
              this.lastRouteVisited = "new-group.show.edit.members";
            }
          }

          this.replaceWith(this.lastRouteVisited);
        } else {
          this.lastRouteVisited = currentRouteName;
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class));
  _exports.default = EditRoute;
});