define("cc-frontend/utils/rotation-calendar/create-attributes", ["exports", "lodash-es", "cc-frontend/utils/rotation-calendar/dates"], function (_exports, _lodashEs, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createRotationCalendarAttributes = createRotationCalendarAttributes;

  function createRotationCalendarAttributes(opts) {
    // Set the rotations
    let rotations;

    if (opts.rotationType === "weekly") {
      rotations = [{
        id: "monday",
        title: "Monday",
        abbreviation: "M",
        position: 1000
      }, {
        id: "tuesday",
        title: "Tuesday",
        abbreviation: "Tu",
        position: 2000
      }, {
        id: "wednesday",
        title: "Wednesday",
        abbreviation: "W",
        position: 3000
      }, {
        id: "thursday",
        title: "Thursday",
        abbreviation: "Th",
        position: 4000
      }, {
        id: "friday",
        title: "Friday",
        abbreviation: "F",
        position: 5000
      }];
    } else if (opts.rotationType === "block") {
      if ((0, _lodashEs.isNil)(opts.rotations)) {
        throw new Error("Block rotations must also pass in rotations");
      }

      rotations = opts.rotations;
    } else {
      throw new Error("Bi-weekly rotationType not yet supported");
    } // Create the dates


    let dates = (0, _dates.generateDates)({
      semesters: [opts.semester],
      dateAnnotations: [],
      schoolDays: opts.schoolDays,
      rotationType: opts.rotationType,
      rotations: rotations
    });
    return {
      rotationType: opts.rotationType,
      schoolDays: opts.schoolDays,
      rotations: rotations,
      dateAnnotations: [],
      dates: dates,
      schoolYearId: opts.schoolYearId,
      datesLastChangedOn: opts.datesLastChangedOn || null,
      datesLastUpdatedAtVersion: null,
      datesUpdatedToParentVersion: null,
      parentRotationCalendarId: opts.parentRotationCalendarId || null,
      semesters: [opts.semester]
    };
  }
});