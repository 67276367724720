define("cc-frontend/models/planbook-calendar-date-custom", ["exports", "cc-frontend/lib/structor"], function (_exports, _structor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PlanbookCalendarDateCustom extends _structor.default {
    defineStruct() {
      this.field("dateString");
      this.field("cardStackId");
      this.field("forceIsOn", false); // These aren't coming from the server

      this.field("routineTemplateId");
      this.field("isOff", false);
      this.field("isBeforeSchoolYear", false);
      this.field("isAfterSchoolYear", false);
    }

  }

  _exports.default = PlanbookCalendarDateCustom;
});