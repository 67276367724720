define("cc-frontend/components/planner/menus/google-classroom/time-picker", ["exports", "@glint/environment-ember-loose/glimmer-component", "lodash-es"], function (_exports, _glimmerComponent, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <select
    id="time-select"
    local-class="time-select__set-time"
    {{on "change" (pick "target.value" this.onChange)}}
  >
    {{#each this.timeOptions as |time|}}
      <option value={{time.id}} selected={{eq time.id (convert-time-to-id @selectedTime)}}>
        {{format-time time "12hrs"}}
      </option>
    {{/each}}
  </select>
  */
  {
    "id": "eCh4p15V",
    "block": "[[[11,\"select\"],[16,0,[29,[[28,[37,0],[\"time-select__set-time\"],[[\"from\"],[\"cc-frontend/components/planner/menus/google-classroom/time-picker.css\"]]]]]],[24,1,\"time-select\"],[4,[38,1],[\"change\",[28,[37,2],[\"target.value\",[30,0,[\"onChange\"]]],null]],null],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"timeOptions\"]]],null]],null],null,[[[1,\"    \"],[10,\"option\"],[15,2,[30,1,[\"id\"]]],[15,\"selected\",[28,[37,5],[[30,1,[\"id\"]],[28,[37,6],[[30,2]],null]],null]],[12],[1,\"\\n      \"],[1,[28,[35,7],[[30,1],\"12hrs\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"time\",\"@selectedTime\"],false,[\"local-class\",\"on\",\"pick\",\"each\",\"-track-array\",\"eq\",\"convert-time-to-id\",\"format-time\"]]",
    "moduleName": "cc-frontend/components/planner/menus/google-classroom/time-picker.hbs",
    "isStrictMode": false
  });

  let GoogleClassroomTimePickerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class GoogleClassroomTimePickerComponent extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "lessonEditor", _descriptor2, this);
    }

    /** returns an array of 24 hour time options for scheduled/due time dropdown */
    get timeOptions() {
      let minuteIntervals = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
      let timesArray = [];

      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 12; j++) {
          timesArray.push({
            hour: i,
            minute: minuteIntervals[j],
            id: `${i}:${(0, _lodashEs.padStart)(minuteIntervals[j].toString(), 2, "0")}`
          });
        }
      } // We add an option for the end of the day (the default dueAt)


      timesArray.push({
        hour: 23,
        minute: 59,
        id: `23:59`
      });
      return timesArray;
    }

    onChange(value) {
      let time = (0, _lodashEs.find)(this.timeOptions, {
        id: value
      });
      if ((0, _lodashEs.isNil)(time)) return;
      let {
        hour,
        minute
      } = time;
      this.args.onChange({
        hour,
        minute
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lessonEditor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
  _exports.default = GoogleClassroomTimePickerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GoogleClassroomTimePickerComponent);
});