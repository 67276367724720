define("cc-frontend/lib/actions/lesson/LESSON_CARD_MOVE_TO_NEW_LESSON", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_MOVE_TO_NEW_LESSON = void 0;
  const LESSON_CARD_MOVE_TO_NEW_LESSON = {
    name: "LESSON_CARD_MOVE_TO_NEW_LESSON",
    params: {
      oldCardStackId: "string",
      cardStackId: "string",
      card: "object",
      oldCard: "object"
    },

    patches(payload) {
      let oldLessonPatch = _patchUpdater.default.create("card-stack", payload.oldCardStackId).pull("attributes.cards", {
        id: payload.oldCard.id
      }).patch; // should this be .set? If the array is all the sections including the old one then that makes sense, but if not then shouldn't it be addToSet?


      let newLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).push("attributes.cards", payload.card).patch;

      return [oldLessonPatch, newLessonPatch];
    },

    undoPatches(payload) {
      //Is addToSet correct here? I don't think this accounts for position...
      let oldLessonPatch = _patchUpdater.default.create("card-stack", payload.oldCardStackId).addToSet("attributes.cards", JSON.parse(JSON.stringify(payload.oldCard))).patch;

      let newLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).pull("attributes.cards", {
        id: payload.card.id
      }).patch;

      return [oldLessonPatch, newLessonPatch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);
      let cardTitle = Ember.get(payload, "oldCard.attributes.title");
      let oldLesson = findFn("card-stack", payload.oldCardStackId);
      let oldLessonTitle = Ember.get(oldLesson, "attributes.title");
      let newLessonTitle = Ember.get(context, "cardStackTitle");

      let newContext = _lodash.default.assign({}, context, {
        moveType: "toNewLesson",
        cardTitle: cardTitle,
        oldLessonTitle: oldLessonTitle,
        oldLessonId: oldLesson.id
      });

      return {
        title: "Moved a Lesson Card",
        titleForAnalytics: "Lesson Card - Move",
        verbForStream: "edited",
        description: `Moved "${cardTitle}" card from ${oldLessonTitle} to ${newLessonTitle}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "moved")
      };
    }

  };
  _exports.LESSON_CARD_MOVE_TO_NEW_LESSON = LESSON_CARD_MOVE_TO_NEW_LESSON;
  var _default = LESSON_CARD_MOVE_TO_NEW_LESSON;
  _exports.default = _default;
});