define("cc-frontend/components/icons/reply-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    <path
      d="M22.5,19.954c-0.185,0 -0.354,-0.101 -0.441,-0.265c-2.272,-4.26 -6.021,-5.154 -8.766,-5.154c-1.258,0 -2.278,0.189 -2.793,0.307v3.612c0,0.193 -0.113,0.37 -0.287,0.452c-0.066,0.031 -0.14,0.048 -0.213,0.048c-0.116,0 -0.229,-0.041 -0.318,-0.115l-8.5,-7c-0.116,-0.094 -0.182,-0.235 -0.182,-0.385c0,-0.15 0.066,-0.291 0.182,-0.386l8.5,-7c0.089,-0.073 0.202,-0.114 0.318,-0.114c0.073,0 0.147,0.016 0.213,0.048c0.174,0.082 0.287,0.26 0.287,0.452v3.459c0.206,-0.011 0.466,-0.02 0.768,-0.02c1.938,0 5.6,0.364 8.349,2.803c2.245,1.991 3.383,4.938 3.383,8.758c0,0.23 -0.156,0.429 -0.379,0.485c-0.038,0.01 -0.079,0.015 -0.121,0.015Zm-9.289,-6.384c2.612,0 6.155,0.72 8.684,4.017c-0.308,-2.618 -1.295,-4.681 -2.939,-6.141c-2.494,-2.214 -5.936,-2.545 -7.766,-2.545c-0.699,0 -1.127,0.049 -1.132,0.049c-0.022,0.002 -0.039,0.003 -0.056,0.003c-0.125,0 -0.243,-0.045 -0.335,-0.126c-0.106,-0.094 -0.167,-0.23 -0.167,-0.373v-2.94l-7.213,5.94l7.213,5.94v-2.94c0,-0.22 0.148,-0.417 0.359,-0.479c0.012,-0.004 1.404,-0.405 3.352,-0.405Z"
    ></path>
  </svg>
  */
  {
    "id": "G5BCT3/q",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M22.5,19.954c-0.185,0 -0.354,-0.101 -0.441,-0.265c-2.272,-4.26 -6.021,-5.154 -8.766,-5.154c-1.258,0 -2.278,0.189 -2.793,0.307v3.612c0,0.193 -0.113,0.37 -0.287,0.452c-0.066,0.031 -0.14,0.048 -0.213,0.048c-0.116,0 -0.229,-0.041 -0.318,-0.115l-8.5,-7c-0.116,-0.094 -0.182,-0.235 -0.182,-0.385c0,-0.15 0.066,-0.291 0.182,-0.386l8.5,-7c0.089,-0.073 0.202,-0.114 0.318,-0.114c0.073,0 0.147,0.016 0.213,0.048c0.174,0.082 0.287,0.26 0.287,0.452v3.459c0.206,-0.011 0.466,-0.02 0.768,-0.02c1.938,0 5.6,0.364 8.349,2.803c2.245,1.991 3.383,4.938 3.383,8.758c0,0.23 -0.156,0.429 -0.379,0.485c-0.038,0.01 -0.079,0.015 -0.121,0.015Zm-9.289,-6.384c2.612,0 6.155,0.72 8.684,4.017c-0.308,-2.618 -1.295,-4.681 -2.939,-6.141c-2.494,-2.214 -5.936,-2.545 -7.766,-2.545c-0.699,0 -1.127,0.049 -1.132,0.049c-0.022,0.002 -0.039,0.003 -0.056,0.003c-0.125,0 -0.243,-0.045 -0.335,-0.126c-0.106,-0.094 -0.167,-0.23 -0.167,-0.373v-2.94l-7.213,5.94l7.213,5.94v-2.94c0,-0.22 0.148,-0.417 0.359,-0.479c0.012,-0.004 1.404,-0.405 3.352,-0.405Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/reply-simple.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});