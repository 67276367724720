define("cc-frontend/templates/invites/accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JDD5jtrv",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,1]]],null]],[\"@model\"],false,[\"invites/accept\"]]",
    "moduleName": "cc-frontend/templates/invites/accept.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});