define("cc-frontend/services/callbacks/action/redux-dev-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(actionStream) {
    actionStream.onValue(([actionName, payload, _actionDoc]) => {
      if (Ember.get(this, "store.REDUX_DEV_TOOLS") && window._ENABLE_REDUX_DEV_TOOLS) {
        Ember.get(this, "store.REDUX_DEV_TOOLS").send({
          type: actionName,
          payload
        }, Ember.get(this, "store.memoryEngine.__store__"));
      }
    });
  }
});