define("cc-frontend/templates/icons/-ios-compose-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6FQ/8pqJ",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"512px\"],[14,\"height\",\"512px\"],[14,\"viewBox\",\"0 0 512 512\"],[14,\"enable-background\",\"new 0 0 512 512\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"polygon\"],[14,\"points\",\"368,416 80,416 80,144 304.117,144 320.117,128 64,128 64,432 384,432 384,191.883 368,207.883\"],[12],[13],[1,\"\\n    \"],[10,\"polygon\"],[14,\"points\",\"237.623,284.084 227.917,274.377 399.24,103.054 387.927,91.74 208,271.651 208,304 240.216,304 420.261,124.016\\n\\t\\t408.947,112.73\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M444.213,80.312l-12.465-12.466C429.084,65.467,425.556,64,421.746,64c-3.812,0-7.304,1.468-9.929,3.85L399.666,80\\n\\t\\tl0.08,0.08l11.293,11.293l21.02,21.02l12.15-12.15c2.383-2.625,3.791-6.117,3.791-9.929C448,86.504,446.592,82.975,444.213,80.312z,[object Object]\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ios-compose-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});