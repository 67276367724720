define("cc-frontend/lib/actions/course/calendar/COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS", ["exports", "lodash-es", "cc-frontend/lib/actions/course/calendar/COURSE_ROTATION_SET_TEMPLATE_ID"], function (_exports, _lodashEs, _COURSE_ROTATION_SET_TEMPLATE_ID) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareCourseSetTemplateForMultipleRotations = prepareCourseSetTemplateForMultipleRotations;
  _exports.default = _exports.COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS = void 0;

  function prepareCourseSetTemplateForMultipleRotations(payload) {
    return {
      name: COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS.name,
      payload: payload,
      patches: COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS.patches(payload),
      undoPatches: COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS.narrative, payload)
    };
  }

  const COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS = {
    name: "COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS",

    patches(payload) {
      let patches = (0, _lodashEs.flatMap)(payload.changes, change => _COURSE_ROTATION_SET_TEMPLATE_ID.default.patches(change));
      return patches;
    },

    undoPatches(payload) {
      return (0, _lodashEs.flatMap)(payload.changes, change => _COURSE_ROTATION_SET_TEMPLATE_ID.default.undoPatches(change));
    },

    narrative(payload, findFn, userId) {
      let courseId = payload.changes[0].courseId;
      let course = findFn("course", courseId);
      let planbook = findFn("planbook", course.relationships.planbook.data.id);
      return {
        title: payload.createNewTemplate ? `Created new Template` : `Changed the template`,
        titleForAnalytics: "Course - Change Template for All Rotations",
        description: `Change the template for all lessons in the week`,
        context: {
          courseTitle: course.attributes.title,
          planbookTitle: planbook.attributes.title,
          courseId: course.id,
          planbookId: planbook.id,
          didCreateNewTemplate: payload.createNewTemplate
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${course.id}`,
          to: [`course-edits:course-${course.id}`, `planbook-edits:planbook-${planbook.id}`]
        }
      };
    }

  };
  _exports.COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS = COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS;
  var _default = COURSE_SET_TEMPLATE_FOR_MULTIPLE_ROTATIONS;
  _exports.default = _default;
});