define("cc-frontend/components/fiscal-group-admin-edit", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <SlideoutWrapper @order={{@order}} @closeModal={{this.closeModal}}>
    <div class="create-team">
      <div class="slideout__header">
        <h1 class="slideout__header__title">
          Edit Fiscal Group
        </h1>
        <div class="slideout__header__close" {{on "click" this.closeModal}}>
          <Icons::IosCloseEmpty />
        </div>
      </div>
      <div class="slideout__body">
      </div>
    </div>
  </SlideoutWrapper>
  {{yield}}
  */
  {
    "id": "XHLFu9By",
    "block": "[[[8,[39,0],null,[[\"@order\",\"@closeModal\"],[[30,1],[30,0,[\"closeModal\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"create-team\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"slideout__header\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"slideout__header__title\"],[12],[1,\"\\n        Edit Fiscal Group\\n      \"],[13],[1,\"\\n      \"],[11,0],[24,0,\"slideout__header__close\"],[4,[38,1],[\"click\",[30,0,[\"closeModal\"]]],null],[12],[1,\"\\n        \"],[8,[39,2],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"slideout__body\"],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[18,2,null]],[\"@order\",\"&default\"],false,[\"slideout-wrapper\",\"on\",\"icons/ios-close-empty\",\"yield\"]]",
    "moduleName": "cc-frontend/components/fiscal-group-admin-edit.hbs",
    "isStrictMode": false
  });

  let FiscalGroupAdminEdit = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class FiscalGroupAdminEdit extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    closeModal() {
      this.router.transitionTo("admin.fiscal-groups.index");
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = FiscalGroupAdminEdit;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FiscalGroupAdminEdit);
});