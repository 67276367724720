define("cc-frontend/components/planner/planbook/planbook-date-combined", ["exports", "@ember-decorators/component", "@marcj/marshal", "cc-frontend/models/planbook-calendar-date-combined", "cc-frontend/utils/filter-dates/planbook", "lodash-es"], function (_exports, _component, _marshal, _planbookCalendarDateCombined, _planbook, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.planbookDateCombined}}
  */
  {
    "id": "ddrpDBuS",
    "block": "[[[18,1,[[30,0,[\"planbookDateCombined\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/planbook-date-combined.hbs",
    "isStrictMode": false
  });

  let PlanbookDateCombinedComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.computed("dateString", "planbook.attributes.calendar.dates", "planbook.attributes.calendar.dateAnnotations", "planbook.attributes.calendar.version", "rotationCalendar.attributes._revision"), _dec(_class = (_class2 = class PlanbookDateCombinedComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "rotationCalendar", void 0);

      _defineProperty(this, "planbook", void 0);
    }

    get planbookDateCombined() {
      if ((0, _lodashEs.isNil)(this.rotationCalendar)) return null;
      let dateString = this.dateString;
      let rotationDate = (0, _lodashEs.find)(this.rotationCalendar.attributes.dates, date => date.attributes.dateString === dateString);
      let planbookDateCustom = (0, _planbook.findPlanbookCustomDate)(this.planbook, dateString);
      let planbookDateDefault = (0, _planbook.findPlanbookDefaultDate)(this.planbook, {
        rotationId: rotationDate === null || rotationDate === void 0 ? void 0 : rotationDate.attributes.rotationId,
        semesterId: rotationDate === null || rotationDate === void 0 ? void 0 : rotationDate.attributes.semesterId
      });
      let firstDay = (0, _lodashEs.min)((0, _lodashEs.map)(this.rotationCalendar.attributes.semesters, "startDate"));
      let lastDay = (0, _lodashEs.max)((0, _lodashEs.map)(this.rotationCalendar.attributes.semesters, "endDate"));
      if (firstDay === undefined || lastDay === undefined) throw Error("No start or end date");
      let planbookDateDefaultIsOff = (0, _lodashEs.includes)(this.planbook.attributes.calendar.datesOff, `${rotationDate === null || rotationDate === void 0 ? void 0 : rotationDate.attributes.semesterId}:${rotationDate === null || rotationDate === void 0 ? void 0 : rotationDate.attributes.rotationId}`);
      let planbookDateCustomIsOff = (0, _lodashEs.includes)(this.planbook.attributes.calendar.datesOff, dateString);
      let isOff = planbookDateCustomIsOff ? planbookDateCustomIsOff : planbookDateDefaultIsOff;
      let dateAnnotations = (0, _lodashEs.filter)(this.planbook.attributes.calendar.dateAnnotations, annotation => {
        if (annotation.type === "date-annotation-planbook-custom-single") {
          return annotation.attributes.dateString === dateString;
        }

        if (annotation.type === "date-annotation-planbook-custom-range") {
          return annotation.attributes.startDate <= dateString && annotation.attributes.endDate >= dateString;
        }

        return false;
      });
      let daysBeforeFirstDay = dateFns.differenceInDays(dateFns.parse(firstDay), dateFns.parse(dateString));
      let daysAfterLastDay = dateFns.differenceInDays(dateFns.parse(dateString), dateFns.parse(lastDay));
      let cardStackId = planbookDateCustom ? planbookDateCustom.attributes.cardStackId : null;
      let rotationCalendarException = (0, _lodashEs.find)(this.rotationCalendar.attributes.dateAnnotations, annotation => {
        return annotation.type === "date-annotation-rotation-calendar-single" && annotation.attributes.dateString === dateString && (annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_RESET" || annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_BUMP_OVER_DISRUPTION" || annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_CONTINUE_UNINTERRUPTED");
      });
      let rotation = (0, _lodashEs.find)(this.rotationCalendar.attributes.rotations, rotation => rotation.id === (rotationDate === null || rotationDate === void 0 ? void 0 : rotationDate.attributes.rotationId));
      let rotationDayTitle = (0, _lodashEs.isNil)(rotation) !== true ? rotation === null || rotation === void 0 ? void 0 : rotation.title : "";
      return (0, _marshal.plainToClass)(_planbookCalendarDateCombined.default, {
        planbookDateId: planbookDateCustom ? planbookDateCustom.id : null,
        rotationCalendarException: rotationCalendarException,
        dateString: dateString,
        cardStackId: cardStackId,
        isBlockSchedule: this.rotationCalendar.attributes.rotationType !== "weekly",
        isUpdatingFromTemplate: cardStackId === null,
        rotationDayTitle: rotationDayTitle,
        cardStackTemplateId: planbookDateDefault ? planbookDateDefault.attributes.cardStackId : null,
        isBeforeSchoolYear: dateString < firstDay,
        isAfterSchoolYear: dateString > lastDay,
        isInsideSchoolYear: dateString >= firstDay && dateString <= lastDay,
        firstDayOfSchoolYear: firstDay,
        lastDayOfSchoolYear: lastDay,
        daysBeforeFirstDay: daysBeforeFirstDay,
        daysAfterLastDay: daysAfterLastDay,
        isOff: isOff,
        dateAnnotations: dateAnnotations
      });
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "planbookDateCombined", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "planbookDateCombined"), _class2.prototype)), _class2)) || _class);
  _exports.default = PlanbookDateCombinedComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookDateCombinedComponent);
});