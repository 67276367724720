define("cc-frontend/lib/actions/course/calendar/PULL_MULTIPLE_LESSONS_BACKWARDS", ["exports", "lodash", "lodash-es", "cc-frontend/lib/actions/lesson/PULL_LESSON_BACKWARDS"], function (_exports, _lodash, _lodashEs, _PULL_LESSON_BACKWARDS) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePullMulitpleLessonsBackwards = preparePullMulitpleLessonsBackwards;
  _exports.PULL_MULTIPLE_LESSONS_BACKWARDS = void 0;

  function preparePullMulitpleLessonsBackwards(payload) {
    return {
      name: PULL_MULTIPLE_LESSONS_BACKWARDS.name,
      payload: payload,
      patches: PULL_MULTIPLE_LESSONS_BACKWARDS.patches(payload),
      undoPatches: PULL_MULTIPLE_LESSONS_BACKWARDS.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(PULL_MULTIPLE_LESSONS_BACKWARDS.narrative, payload)
    };
  }

  const PULL_MULTIPLE_LESSONS_BACKWARDS = {
    name: "PULL_MULTIPLE_LESSONS_BACKWARDS",

    patches(payload) {
      return _lodash.default.flatMap(payload.courses, course => {
        return _PULL_LESSON_BACKWARDS.PULL_LESSON_BACKWARDS.patches({
          dateString: payload.dateString,
          course: course,
          planbook: payload.planbook,
          rotationCalendar: payload.rotationCalendar
        });
      });
    },

    undoPatches(payload) {
      return _lodash.default.flatMap(payload.courses, course => {
        return _PULL_LESSON_BACKWARDS.PULL_LESSON_BACKWARDS.undoPatches({
          dateString: payload.dateString,
          course: course,
          planbook: payload.planbook,
          rotationCalendar: payload.rotationCalendar
        });
      });
    },

    narrative(payload, findFn, userId) {
      let planbookId = payload.planbook.id;

      let activityToValues = _lodash.default.map(payload.courses, course => {
        return `course-edits:course-${course.id}`;
      });

      let courseDateArray = _lodash.default.compact(_lodash.default.map(payload.courses, course => {
        return _lodash.default.find(course.attributes.calendar.dates, date => date.attributes.dateString === payload.dateString);
      }));

      let cardStackIds = _lodash.default.map(courseDateArray, date => {
        return date.attributes.cardStackId;
      });

      let cardStackArray = _lodash.default.compact(_lodash.default.map(courseDateArray, date => {
        return findFn("card-stack", date.attributes.cardStackId);
      }));

      let cardStackTitles = _lodash.default.map(cardStackArray, cardStack => {
        return cardStack.attributes.title;
      });

      activityToValues.push(`planbook-edits:planbook-${planbookId}`);
      let courseCount = payload.courses.length; // @ts-ignore  no types for moment

      let formattedDate = moment(payload.dateString).format("ddd, MMM. D, YYYY");
      return {
        title: "Pulled Back Lessons",
        titleForAnalytics: "Day - Pull Back",
        description: `Moved ${courseCount} lessons backwards to ${formattedDate}`,
        context: {
          courseIds: _lodash.default.map(payload.courses, "id"),
          planbookTitle: payload.planbook.attributes.title,
          planbookId: payload.planbook.id,
          cardStackIds,
          cardStackTitles
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `planbook-${planbookId}`,
          to: activityToValues
        }
      };
    }

  };
  _exports.PULL_MULTIPLE_LESSONS_BACKWARDS = PULL_MULTIPLE_LESSONS_BACKWARDS;
});