define("cc-frontend/components/icons/calendar-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! Calendar edit 1}}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M11.5,24c-0.134,0 -0.259,-0.052 -0.354,-0.147c-0.128,-0.128 -0.177,-0.316 -0.127,-0.491l1.2,-4.199c0.004,-0.014 0.013,-0.038 0.02,-0.051l0.005,-0.013c0.027,-0.061 0.06,-0.111 0.102,-0.153l7.179,-7.179c0.495,-0.495 1.153,-0.768 1.854,-0.768c0.701,0 1.359,0.273 1.854,0.768c0.495,0.495 0.767,1.154 0.767,1.854c0,0.7 -0.273,1.358 -0.768,1.854c0,0 -7.175,7.176 -7.179,7.18c-0.009,0.009 -0.021,0.019 -0.032,0.027c-0.03,0.025 -0.065,0.048 -0.104,0.067c-0.019,0.009 -0.032,0.015 -0.045,0.02c-0.012,0.005 -0.026,0.01 -0.037,0.013l-4.198,1.199c-0.045,0.012 -0.091,0.019 -0.137,0.019Zm0.728,-1.228l2.517,-0.719l-1.798,-1.798l-0.719,2.517Zm3.472,-1.179l5.572,-5.572l-2.293,-2.293l-5.572,5.572l2.293,2.293Zm6.278,-6.279l0.547,-0.547c0.632,-0.632 0.632,-1.661 0,-2.293c-0.305,-0.305 -0.712,-0.474 -1.146,-0.474c-0.434,0 -0.841,0.168 -1.146,0.474l-0.547,0.547l2.292,2.293Z"
      ></path>
      <path
        d="M1.5,19c-0.827,0 -1.5,-0.673 -1.5,-1.5v-14c0,-0.827 0.673,-1.5 1.5,-1.5h3.5v-1.499c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v1.499h9v-1.499c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v1.499h3.5c0.827,0 1.5,0.673 1.5,1.5v6c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-1.499h-19v9.499c0,0.275 0.224,0.5 0.5,0.5h8c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-8Zm18.5,-11.999v-3.501c0,-0.276 -0.224,-0.5 -0.5,-0.5h-3.5v2.501c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.501h-9v2.501c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.501h-3.5c-0.276,0 -0.5,0.224 -0.5,0.5v3.501h19Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "FF+QKJqK",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M11.5,24c-0.134,0 -0.259,-0.052 -0.354,-0.147c-0.128,-0.128 -0.177,-0.316 -0.127,-0.491l1.2,-4.199c0.004,-0.014 0.013,-0.038 0.02,-0.051l0.005,-0.013c0.027,-0.061 0.06,-0.111 0.102,-0.153l7.179,-7.179c0.495,-0.495 1.153,-0.768 1.854,-0.768c0.701,0 1.359,0.273 1.854,0.768c0.495,0.495 0.767,1.154 0.767,1.854c0,0.7 -0.273,1.358 -0.768,1.854c0,0 -7.175,7.176 -7.179,7.18c-0.009,0.009 -0.021,0.019 -0.032,0.027c-0.03,0.025 -0.065,0.048 -0.104,0.067c-0.019,0.009 -0.032,0.015 -0.045,0.02c-0.012,0.005 -0.026,0.01 -0.037,0.013l-4.198,1.199c-0.045,0.012 -0.091,0.019 -0.137,0.019Zm0.728,-1.228l2.517,-0.719l-1.798,-1.798l-0.719,2.517Zm3.472,-1.179l5.572,-5.572l-2.293,-2.293l-5.572,5.572l2.293,2.293Zm6.278,-6.279l0.547,-0.547c0.632,-0.632 0.632,-1.661 0,-2.293c-0.305,-0.305 -0.712,-0.474 -1.146,-0.474c-0.434,0 -0.841,0.168 -1.146,0.474l-0.547,0.547l2.292,2.293Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M1.5,19c-0.827,0 -1.5,-0.673 -1.5,-1.5v-14c0,-0.827 0.673,-1.5 1.5,-1.5h3.5v-1.499c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v1.499h9v-1.499c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v1.499h3.5c0.827,0 1.5,0.673 1.5,1.5v6c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-1.499h-19v9.499c0,0.275 0.224,0.5 0.5,0.5h8c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-8Zm18.5,-11.999v-3.501c0,-0.276 -0.224,-0.5 -0.5,-0.5h-3.5v2.501c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.501h-9v2.501c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.501h-3.5c-0.276,0 -0.5,0.224 -0.5,0.5v3.501h19Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/calendar-edit.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});