define("cc-frontend/lib/actions/course/calendar/MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "lodash", "moment"], function (_exports, _patchUpdater, _unitUtils, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE",
    params: {
      cardStackId: "string",
      toDate: "string",
      fromCourse: "object",
      toCourse: "object",
      planbook: "object",
      rotationCalendar: "object"
    },

    patches(payload) {
      // --------------------------------------------------
      // Remove lesson from old course
      // --------------------------------------------------
      let courseDatesWithoutLesson = _lodash.default.map(payload.fromCourse.attributes.calendar.dates, courseDate => {
        if (courseDate.attributes.cardStackId === payload.cardStackId) {
          let cd = _lodash.default.cloneDeep(courseDate);

          cd.attributes.cardStackId = null;
          return cd;
        } else {
          return courseDate;
        }
      }); // --------------------------------------------------
      // Insert lesson
      // --------------------------------------------------


      let transferrableArray = [{
        cardStackId: payload.cardStackId,
        unitStart: [],
        unitEnd: []
      }];
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.toCourse, payload.planbook, payload.rotationCalendar);
      let newCourseDates = (0, _unitUtils.insertRange)(transferrableArray, payload.toDate, 0, {}, payload.toCourse.attributes.calendar.dates, consolidatedCourseDatesOff, payload.rotationCalendar.attributes.schoolDays);
      let {
        courseDates,
        units
      } = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, payload.rotationCalendar.attributes.schoolDays); // --------------------------------------------------
      // Create patches
      // --------------------------------------------------
      // We might be creating a lesson here, so we need to set this

      let updateLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("relationships.course.data.id", payload.toCourse.id).set("relationships.planbook.data.id", payload.planbook.id).set("attributes.usedAs", "lesson").set("meta.permissions", payload.toCourse.meta.permissions).patch; // to make sure we have the right permissions if this is new


      let removeFromOldCoursePatch = _patchUpdater.default.create("course", payload.fromCourse.id).set("attributes.calendar.dates", courseDatesWithoutLesson).inc("attributes.calendar.version", 1).patch;

      let addToNewCoursePatch = _patchUpdater.default.create("course", payload.toCourse.id).set("attributes.calendar.dates", courseDates).set("attributes.calendar.units", units).inc("attributes.calendar.version", 1).patch;

      return [updateLessonPatch, removeFromOldCoursePatch, addToNewCoursePatch];
    },

    undoPatches(payload) {
      let updateLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("relationships.course.data.id", payload.fromCourse.id).patch;

      let removeFromOldCoursePatch = _patchUpdater.default.create("course", payload.fromCourse.id).set("attributes.calendar.dates", payload.fromCourse.attributes.calendar.dates).inc("attributes.calendar.version", 1).patch;

      let addToNewCoursePatch = _patchUpdater.default.create("course", payload.toCourse.id).set("attributes.calendar.dates", payload.toCourse.attributes.calendar.dates).set("attributes.calendar.units", payload.toCourse.attributes.calendar.units).inc("attributes.calendar.version", 1).patch;

      return [updateLessonPatch, removeFromOldCoursePatch, addToNewCoursePatch];
    },

    narrative(payload, findFn, userId) {
      let fromCourse = payload.fromCourse;
      let toCourse = payload.toCourse;
      let models = {
        fromCourse: fromCourse,
        toCourse: toCourse,
        planbook: findFn("planbook", fromCourse.relationships.planbook.data.id),
        lesson: findFn("card-stack", payload.fromCardStackId) || findFn("card-stack-summary", payload.fromCardStackId)
      };
      let lessonTitle = Ember.get(models, "lesson.attributes.title");
      let fromCourseTitle = Ember.get(models, "fromCourse.attributes.title");
      let toCourseTitle = Ember.get(models, "toCourse.attributes.title");

      let fromDate = _lodash.default.find(payload.fromDates, {
        dateString: payload.fromDate
      });

      let formattedFromDate = (0, _moment.default)(fromDate).format("ddd, MMM. D, YYYY");

      let toDate = _lodash.default.find(payload.toDates, {
        dateString: payload.toDate
      });

      let formattedToDate = (0, _moment.default)(toDate).format("ddd, MMM. D, YYYY");
      return {
        title: "Moved Lesson",
        titleForAnalytics: "Lesson - Move to different class",
        description: `Moved "${lessonTitle}" from ${formattedFromDate} in "${fromCourseTitle}" to ${formattedToDate} in "${toCourseTitle}"`,
        context: {
          lessonTitle: Ember.get(models, "lesson.attributes.title"),
          fromCourseTitle: Ember.get(models, "fromCourse.attributes.title"),
          toCourseTitle: Ember.get(models, "toCourse.attributes.title"),
          planbookTitle: Ember.get(models, "planbook.attributes.title"),
          cardStackId: Ember.get(models, "lesson.id"),
          fromCourseId: Ember.get(models, "fromCourse.id"),
          toCourseId: Ember.get(models, "toCourse.id"),
          planbookId: Ember.get(models, "planbook.id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${models.fromCourse.id}`,
          to: [`course-edits:course-${Ember.get(models, "fromCourse.id")}`, `planbook-edits:planbook-${Ember.get(models, "planbook.id")}`]
        }
      };
    }

  };
  _exports.default = _default;
});