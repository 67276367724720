define("cc-frontend/components/planner/menus/planbook-menu/edit-title/body", ["exports", "@ember-decorators/component", "cc-frontend/lib/actions/planbook/PLANBOOK_CHANGE_TITLE"], function (_exports, _component, _PLANBOOK_CHANGE_TITLE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="edit-form">
    <SimpleContentEditable
      @value={{@planbook.attributes.title}}
      @class={{local-class "title"}}
      @placeholder="Title"
      @isEditable={{true}}
      @onFinishedEditing={{this.updateTitle}}
    />
  
    <br />
    <Planner::Menus::Parts::OutlineButton @onClick={{@goBack}} @noHorizontalMargin={{true}}>
      Done
    </Planner::Menus::Parts::OutlineButton>
  </div>
  */
  {
    "id": "gqaA+Tzu",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"edit-form\"],[[\"from\"],[\"cc-frontend/components/planner/menus/planbook-menu/edit-title/body.css\"]]]]]],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@class\",\"@placeholder\",\"@isEditable\",\"@onFinishedEditing\"],[[30,1,[\"attributes\",\"title\"]],[28,[37,0],[\"title\"],[[\"from\"],[\"cc-frontend/components/planner/menus/planbook-menu/edit-title/body.css\"]]],\"Title\",true,[30,0,[\"updateTitle\"]]]],null],[1,\"\\n\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n  \"],[8,[39,2],null,[[\"@onClick\",\"@noHorizontalMargin\"],[[30,2],true]],[[\"default\"],[[[[1,\"\\n    Done\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@planbook\",\"@goBack\"],false,[\"local-class\",\"simple-content-editable\",\"planner/menus/parts/outline-button\"]]",
    "moduleName": "cc-frontend/components/planner/menus/planbook-menu/edit-title/body.hbs",
    "isStrictMode": false
  });

  let PlanbookMenuEditTitleBodyComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec(_class = (_class2 = class PlanbookMenuEditTitleBodyComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "planbook", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    updateTitle(title) {
      this.store.dispatchAction(_PLANBOOK_CHANGE_TITLE.default, {
        originalTitle: this.planbook.attributes.title,
        title: title,
        planbookId: this.planbook.id
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "updateTitle", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "updateTitle"), _class2.prototype)), _class2)) || _class);
  _exports.default = PlanbookMenuEditTitleBodyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookMenuEditTitleBodyComponent);
});