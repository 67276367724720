define("cc-frontend/services/memory-engine", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.applyPatchToModel = applyPatchToModel;
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MemoryEngine = (_dec = Ember.inject.service("fastboot"), (_class = class MemoryEngine extends Ember.Service {
    // @ts-ignore

    /**
     * Runs immediately when the service is created. Sets properties on the window to properties on the
     * memoryEngine service and makes sure they're empty. Binds the context of undo and redo to memoryEngine class
     *  Checks if it's fastboot, declares and sets shoebox variable to fastboot shoebox.
     *  Shoebox can improve performance time by reducing potentially duplicate server API calls - if calls/responses
     *  are already available via fastboot shoebox, the browser does not need to make these calls.
     *  The "my-store" object in shoebox is set to an empty object.
     */
    constructor(...args) {
      super(...args); // super(...arguments)
      // @ts-ignore

      _initializerDefineProperty(this, "fastboot", _descriptor, this);

      _defineProperty(this, "__store__", Object.create(null));

      _defineProperty(this, "__history", []);

      _defineProperty(this, "__redos", []);

      window.__STORE__ = this.__store__; // @ts-ignore

      window.__UNDO = this.undo.bind(this); // @ts-ignore

      window.__REDO = this.redo.bind(this); // @ts-ignore:

      window.__HISTORY = this.__history;
      this.__store__ = Object.create(null);
      this.__history = [];
      this.__redos = []; // Init the shoebox store
      // @ts-ignore

      if (Ember.get(this, "fastboot.isFastBoot")) {
        // @ts-ignore
        let shoebox = Ember.get(this, "fastboot.shoebox"); // @ts-ignore

        shoebox.put("my-store", {});
      }
    }

    get rawStore() {
      return this.__store__;
    }
    /**
     * Resets/clears all instance properties
     *
     * @return undefined
     */


    reset() {
      Ember.set(this, "__store__", Object.create(null));
      Ember.set(this, "__history", []);
      Ember.set(this, "__redos", []);
    } // previously used in conjunction with redux store


    checkpoint() {// this.__history.push(_.cloneDeep(this.__store__))
    }
    /**
     * Previously used in conjunction with redux store
     * Removes last element from history property array and pushes it into the redos property array.
     * Calls applyUndo for that history object.
     */


    undo() {
      if (this.__history.length === 0) return;

      let lastHistory = this.__history.pop(); // @ts-ignore


      this.__redos.push(lastHistory);

      Ember.run.begin();
      console.time("applyUndo");
      this.applyUndo("", lastHistory);
      console.timeEnd("applyUndo");
      Ember.run.end();
    }
    /**
     * Previously used in conjunction with redux store
     * Removes last element from redos property array and pushes it into the history property array
     * Calls applyUndo for that history object
     */


    redo() {
      if (this.__redos.length === 0) return;

      let lastUndo = this.__redos.pop(); // @ts-ignore


      this.__history.push(lastUndo);

      Ember.run.begin();
      this.applyUndo("", lastUndo);
      Ember.run.end();
    }
    /**
     * Previously used in conjunction with redux store
     *
     * Checks the diff of the item (can be various data structures) in the store with what is being passed to it,
     * and then sets the store with the updated version
     * If the item is an object, it checks the value of the corresponding key,
     * If the item is an array, it repeats the process above, checking the first value in the array first as it was likely added most recently
     * If the item in the store is not the same as the value, we set it to be that way
     *
     * @param path [string] root path
     * @param hash [object]
     *
     * hash should be renamed to be the object we're comparing.
     */


    applyUndo(path, hash) {
      (0, _lodashEs.each)(hash, (value, key) => {
        // console.log(`${path}${key}`)
        if ((0, _lodashEs.isPlainObject)(value)) {
          this.applyUndo(`${path}${key}.`, value);
          return;
        }

        if ((0, _lodashEs.isArray)(value) && value.length && (0, _lodashEs.isPlainObject)((0, _lodashEs.first)(value))) {
          (0, _lodashEs.each)(value, (val, index) => {
            this.applyUndo(`${path}${key}.${index}.`, val);
          });
          return;
        } // @ts-ignore


        if (!(0, _lodashEs.isEqual)(Ember.get(this.__store__, `${path}${key}`), value)) {
          // @ts-ignore
          console.log("setting value", `${path}${key}`, Ember.get(this.__store__, `${path}${key}`), value); // @ts-ignore

          Ember.set(this.__store__, `${path}${key}`, value);
        }
      });
    }
    /**
     * This is called from the store to check if the patch in the argument is in memoryEngine's local memory.
     * Makes sure patch exists and has right info. Checks store instance property to see if a model
     * type exists, if not, make one and set it to an empty object. If there's no model type in the store that has
     * the patch document id, set that to the id and set the attributes portion of the patch to {}, apply it to the match model and up the
     * internal version
     *
     * @param  patch PatchModel - references model type, id, actionId
     * @return model updated withe the applied patch
     */


    patch(patch) {
      if (patch === undefined) throw Error("The patch function received undefined. Likely, this is because you didn't return the patch from the patch function.");
      let id = patch.document.id;

      if (Ember.isNone(id) || id === "") {
        console.error("Erroneous Patch", patch);
        throw Error("No ID given. Cannot process.");
      } // @ts-ignore


      if (Ember.isNone(Ember.get(this, `__store__.${patch.document.modelType}`))) {
        // @ts-ignore
        Ember.set(this, `__store__.${patch.document.modelType}`, {});
      } // @ts-ignore


      if (Ember.isNone(Ember.get(this, `__store__.${patch.document.modelType}.${patch.document.id}`))) {
        // @ts-ignore
        Ember.set(this, `__store__.${patch.document.modelType}.${patch.document.id}`, {
          type: patch.document.modelType,
          id: patch.document.id,
          attributes: {}
        });
      } // checks embedded document path, if there isn't one, set it to an Ember native array


      if (patch.embeddedDocument && patch.embeddedDocument.path) {
        let model;

        if (Ember.isNone(Ember.get(this, // @ts-ignore
        `__store__.${patch.document.modelType}.${patch.document.id}.${patch.embeddedDocument.path}`))) {
          Ember.set(this, // @ts-ignore
          `__store__.${patch.document.modelType}.${patch.document.id}.${patch.embeddedDocument.path}`, Ember.A([]));
        }

        let array = Ember.get(this.__store__, // @ts-ignore
        `${patch.document.modelType}.${patch.document.id}.${patch.embeddedDocument.path}`);
        model = (0, _lodashEs.find)(array, patch.embeddedDocument.elemMatch); // model = _.find(array, el => el[embeddedMatchKey] === embeddedMatchValue)
        // Not sure about this but I think it's right
        // if there's no model push the elemMatch into the native array/embeddedDoc path

        if (Ember.isNone(model)) {
          array.pushObject((0, _lodashEs.cloneDeep)(patch.embeddedDocument.elemMatch));
          model = (0, _lodashEs.last)(array);
        }

        applyPatchToModel(model, patch.embeddedDocument.ops);
      } // @ts-ignore


      let model = Ember.get(this, `__store__.${patch.document.modelType}.${patch.document.id}`); // @ts-ignore

      applyPatchToModel(model, patch.document.ops); // @ts-ignore

      Ember.set(model, "internalVersion", (Ember.get(model, "internalVersion") || 0) + 1); // model.internalVersion++
    }
    /**
     * Keeps local memoryEngine storage current by adding previously unavailable models retrieved through fetch calls
     * or updating existing models with new updates from the server.
     * Check local store for model of document type, make an empty object if it doesn't exist,
     * declare exisitingDoc variable in local store with document type and id, redeclare fastboot shoebox and retrieve
     * document object that was passed in. No exisitingDocument?, make a new document in the local store
     * @param  doc [object] - document object
     * @return     [object] - updated (or new) document object
     */


    insert(doc) {
      if (Ember.get(this, "isDestroyed") || Ember.get(this, "isDestroying")) return; // If we're inserting nothing, return nothing

      if (doc === undefined) return null; // Make sure we have a hash for this type of model

      if (Ember.isNone(this.__store__[doc.type])) this.__store__[doc.type] = Object.create(null); // get the document
      // @ts-ignore

      let existingDoc = Ember.get(this, `__store__.${doc.type}.${doc.id}`); // Store in fastboot
      // @ts-ignore

      if (Ember.get(this, "fastboot.isFastBoot")) {
        // @ts-ignore
        let shoebox = Ember.get(this, "fastboot.shoebox"); // @ts-ignore

        shoebox.retrieve("my-store")[`${doc.type}.${doc.id}`] = doc;
      }

      function deepFreeze(object) {
        // Retrieve the property names defined on object
        var propNames = Object.getOwnPropertyNames(object); // Freeze properties before freezing self

        for (let name of propNames) {
          let value = object[name];

          if (value && typeof value === "object") {
            deepFreeze(value);
          }
        }

        return Object.freeze(object);
      } // If there is an existing doc, update it with the fresh data from the server


      if (existingDoc !== undefined) {
        Ember.setProperties(existingDoc, doc);
        return existingDoc; // if there isn't an existing doc and we actually are dealing with a doc, add it to teh Store
        // and then return it
      } else if (doc && doc.type && doc.id) {
        // Probably not necessary but can make debugging easier to see if
        // it's the same object. This can probably be taken out.
        Ember.guidFor(doc);
        Ember.guidFor(doc.attributes); // Set an internal version for tracking

        doc.internalVersion = 0; // @ts-ignore

        Ember.set(this, `__store__.${doc.type}.${doc.id}`, doc);
        return doc;
      }
    }
    /**
     * Makes sure requested item is in local memory and shoebox.  Will contact the server
     * using memoryEngine.insert() if local memory and shoebox don't have the requested item.
     * Declares shoebox variable as fastboot shoebox, checks dasherized model/id in memoryEngine and dasherized model/id in shoebox store.
     * No shoebox doc? Return the one from the store.
     * No localDoc but have shoebox doc? Insert that shoebox doc into the local store
     *
     * @param  modelName [string]
     * @param  id        [string]
     * @return           [object] - The requested object from either the memoryEngine or shoebox store or undefined if the object does not yet exists
     */


    find(modelName, id) {
      // if (modelName === undefined) debugger;
      // @ts-ignore
      let shoebox = Ember.get(this, "fastboot.shoebox"); // @ts-ignore

      let shoeboxStore = shoebox.retrieve("my-store") || {}; // @ts-ignore

      let localDoc = Ember.get(this, `__store__.${Ember.String.dasherize(modelName)}.${id}`);
      let shoeboxedDoc = shoeboxStore[`${Ember.String.dasherize(modelName)}.${id}`]; // If we found it in the shoebox, we need it to our memory store

      if (shoeboxedDoc === undefined) {
        // @ts-ignore
        return Ember.get(this, `__store__.${Ember.String.dasherize(modelName)}.${id}`);
      } else if (shoeboxedDoc && localDoc === undefined) {
        return this.insert(shoeboxedDoc);
      } else {
        // @ts-ignore
        return Ember.get(this, `__store__.${Ember.String.dasherize(modelName)}.${id}`);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MemoryEngine;

  function applyPatchToModel(model, ops) {
    // Bizarrely, putting this on one line BREAKS. I have no earthly clue why.
    (0, _lodashEs.each)((0, _lodashEs.fromPairs)(ops["set"]), (value, key) => {
      ensurePath(model, key); // The reason we need to clone this is so that other patch don't modify the value
      // coming from the patch. E.g.
      // - Patch 1 sets "val" to [1, 2, 3]
      // - Patch 2 pushes 4 into "val"
      // - Patch 1 will have it's "val" set to [1,2,3,4]
      // Thus, by cloning we keep the patch's values not affected
      // @ts-ignore

      Ember.set(model, key, (0, _lodashEs.cloneDeep)(value));
    });
    (0, _lodashEs.each)((0, _lodashEs.fromPairs)(ops["unset"]), (_value, key) => {
      // @ts-ignore
      Ember.set(model, key, undefined);
    });
    (0, _lodashEs.each)((0, _lodashEs.fromPairs)(ops["inc"]), (value, key) => {
      // @ts-ignore
      ensurePath(model, key, null); // @ts-ignore

      let previousValue = Ember.get(model, key) || 0; // @ts-ignore

      Ember.set(model, key, previousValue + value);
    });
    (0, _lodashEs.each)((0, _lodashEs.fromPairs)(ops["push"]), (value, key) => {
      ensurePath(model, key, Ember.A([])); // push each

      if ((0, _lodashEs.isArray)(value) && value[0] === "$each") {
        (0, _lodashEs.each)(value[1], val => {
          // @ts-ignore
          Ember.get(model, key).pushObject((0, _lodashEs.cloneDeep)(val));
        }); // push one
      } else {
        // @ts-ignore
        if ((0, _lodashEs.isArray)(Ember.get(model, key)) !== true) {
          // @ts-ignore
          Ember.set(model, key, []);
        } // @ts-ignore


        Ember.get(model, key).pushObject((0, _lodashEs.cloneDeep)(value));
      }
    });
    (0, _lodashEs.each)((0, _lodashEs.fromPairs)(ops["pull"]), (value, key) => {
      ensurePath(model, key, []); // @ts-ignore

      let array = Ember.get(model, key); // We compare properties or identities depending on what it is.

      let index = (0, _lodashEs.findIndex)(array, el => {
        if (value && (0, _lodashEs.isArray)(value) && (0, _lodashEs.first)(value) === "$_dottedPath_") {
          return (0, _lodashEs.get)(el, value[1]) === value[2];
        } else {
          // @ts-ignore
          return (0, _lodashEs.isPlainObject)(el) ? (0, _lodashEs.isMatch)(el, value) : (0, _lodashEs.isEqual)(el, value);
        }
      }); // @ts-ignore

      if (index > -1) Ember.get(model, key).removeAt(index, 1);
    });
    (0, _lodashEs.each)((0, _lodashEs.fromPairs)(ops["pullAll"]), (values, key) => {
      // @ts-ignore
      ensurePath(model, key, []); // @ts-ignore

      let array = Ember.get(model, key); // We compare properties or identities depending on what it is.

      (0, _lodashEs.each)(values, value => {
        let index = (0, _lodashEs.findIndex)(array, el => {
          return (0, _lodashEs.isPlainObject)(el) ? (0, _lodashEs.matches)(value)(el) : (0, _lodashEs.isEqual)(el, value);
        }); // @ts-ignore

        if (index > -1) Ember.get(model, key).removeAt(index, 1);
      });
    });
    (0, _lodashEs.each)((0, _lodashEs.fromPairs)(ops["addToSet"]), (value, key) => {
      ensurePath(model, key, Ember.A([]));
      let valsToAdd = (0, _lodashEs.isArray)(value) && value[0] === "$each" ? value[1] : [value];
      (0, _lodashEs.each)(valsToAdd, val => {
        // @ts-ignore
        let doesNotContainVal = (0, _lodashEs.find)(Ember.get(model, key), obj => (0, _lodashEs.isEqual)(obj, val)) === undefined;

        if (doesNotContainVal) {
          // @ts-ignore
          Ember.get(model, key).addObject((0, _lodashEs.cloneDeep)(val));
        }
      });
    });
    return model;
  } // this is a bit messy but if we have a nested path (e.g.
  // attributes.clipboard.sections), all the paths are a map if they're
  // undefined or null. Should probably be pulled out, rewritten, and used
  // in the unset, inc, and push commands.


  function ensurePath(object, path, lastType = {}) {
    if ((0, _lodashEs.get)(object, path) === undefined) {
      let paths = path.split(".");
      let acc = [];
      (0, _lodashEs.each)(paths, part => {
        // @ts-ignore
        acc.push(part);

        if (Ember.isNone((0, _lodashEs.get)(object, acc.join(".")))) {
          acc.length === paths.length ? // prettier-ignore
          // @ts-ignore
          Ember.set(object, acc.join("."), lastType) : // prettier-ignore
          // @ts-ignore
          Ember.set(object, acc.join("."), {});
        }
      });
    }
  }
});