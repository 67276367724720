define("cc-frontend/components/planner/perspectives/shared/header.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "planbook-header": "_planbook-header_uauawk",
    "planbook-header--no-nav-bar": "_planbook-header--no-nav-bar_uauawk",
    "planbook-header__head": "_planbook-header__head_uauawk",
    "planbook-header__headline-date": "_planbook-header__headline-date_uauawk",
    "planbook-header__headline": "_planbook-header__headline_uauawk",
    "planbook-header__head-arrow-button": "_planbook-header__head-arrow-button_uauawk",
    "svg-icon": "_svg-icon_uauawk"
  };
  _exports.default = _default;
});