define("cc-frontend/templates/-planbook-setup-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VWkDM2My",
    "block": "[[[10,0],[14,0,\"planbook-setup-notice\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"planbook-setup-notice__headline\"],[12],[1,\"\\n    This planbook isn't\\n    \"],[8,[39,0],null,[[\"@route\"],[\"planbook.setup.classes\"]],[[\"default\"],[[[[1,\"\\n      setup\\n    \"]],[]]]]],[1,\"\\n    , yet!\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"planbook-setup-notice__description\"],[12],[1,\"\\n    This planbook doesn't have any classes yet. Without classes, your lessons won't have a place to\\n    call home. Head over to\\n    \"],[8,[39,0],null,[[\"@route\"],[\"planbook.setup.classes\"]],[[\"default\"],[[[[1,\"\\n      Settings & Schedules\\n    \"]],[]]]]],[1,\"\\n    to finish setup.\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link-to\"]]",
    "moduleName": "cc-frontend/templates/-planbook-setup-notice.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});