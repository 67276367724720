define("cc-frontend/components/icons/circle-no-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! circle no check }}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ...attributes
  >
    <g>
      <path
        d="M12,23.999c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,1.33227e-15 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "+eZdn/Kt",
    "block": "[[[11,\"svg\"],[24,\"version\",\"1.1\"],[24,\"viewBox\",\"0 0 24 24\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[17,1],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,23.999c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,1.33227e-15 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/circle-no-check.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});