define("cc-frontend/components/admin/action/detail", ["exports", "cc-frontend/helpers/has-many-collectively"], function (_exports, _hasManyCollectively) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.patches.content as |patch|}}
    <div>
      <h1 class="tw-font-bold">
        {{patch.id}}
      </h1>
  
      <h2>
        Document
      </h2>
      <Admin::PatchOps
        @ops={{patch.attributes.document.ops}}
        @path={{patch.attributes.document.modelType}}
        @elemMatch={{concat "{'id':" patch.attributes.document.id "}"}}
      />
  
      <h2>
        Embedded Document
      </h2>
      <Admin::PatchOps
        @ops={{patch.attributes.embeddedDocument.ops}}
        @path={{patch.attributes.embeddedDocument.path}}
        @elemMatch={{patch.attributes.embeddedDocument.elemMatch}}
      />
    </div>
  {{/each}}
  {{yield}}
  */
  {
    "id": "rFap58d1",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"patches\",\"content\"]]],null]],null],null,[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"tw-font-bold\"],[12],[1,\"\\n      \"],[1,[30,1,[\"id\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"h2\"],[12],[1,\"\\n      Document\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@ops\",\"@path\",\"@elemMatch\"],[[30,1,[\"attributes\",\"document\",\"ops\"]],[30,1,[\"attributes\",\"document\",\"modelType\"]],[28,[37,3],[\"{'id':\",[30,1,[\"attributes\",\"document\",\"id\"]],\"}\"],null]]],null],[1,\"\\n\\n    \"],[10,\"h2\"],[12],[1,\"\\n      Embedded Document\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@ops\",\"@path\",\"@elemMatch\"],[[30,1,[\"attributes\",\"embeddedDocument\",\"ops\"]],[30,1,[\"attributes\",\"embeddedDocument\",\"path\"]],[30,1,[\"attributes\",\"embeddedDocument\",\"elemMatch\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[18,2,null]],[\"patch\",\"&default\"],false,[\"each\",\"-track-array\",\"admin/patch-ops\",\"concat\",\"yield\"]]",
    "moduleName": "cc-frontend/components/admin/action/detail.hbs",
    "isStrictMode": false
  });

  let AdminDetailAction = (_dec = Ember.inject.service, _dec2 = (0, _hasManyCollectively.default)("modelToStringify.content", "patch", "patches"), (_class = class AdminDetailAction extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "patches", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "patches", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AdminDetailAction;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AdminDetailAction);
});