define("cc-frontend/components/planner/course/date/course-toggle", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DS::Toggle
    @isOn={{not @courseDate.isOff}}
    @color={{@course.attributes.color}}
    @onToggle={{this.toggleIsOff}}
  />
  */
  {
    "id": "QKTBTvQM",
    "block": "[[[8,[39,0],null,[[\"@isOn\",\"@color\",\"@onToggle\"],[[28,[37,1],[[30,1,[\"isOff\"]]],null],[30,2,[\"attributes\",\"color\"]],[30,0,[\"toggleIsOff\"]]]],null]],[\"@courseDate\",\"@course\"],false,[\"d-s/toggle\",\"not\"]]",
    "moduleName": "cc-frontend/components/planner/course/date/course-toggle.hbs",
    "isStrictMode": false
  });

  let CourseToggle = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class CourseToggle extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "courseCalendarEditor", _descriptor2, this);
    }

    toggleIsOff() {
      this.courseCalendarEditor.toggleCustomDate(this.args.course, this.args.dateString, this.args.courseDate.isOff, this.args.courseDate.isForcedOn); // @ts-ignore

      if (document && document.activeElement) document.activeElement.blur();
      if (this.args.onToggle) this.args.onToggle();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "courseCalendarEditor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleIsOff", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsOff"), _class.prototype)), _class));
  _exports.default = CourseToggle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CourseToggle);
});