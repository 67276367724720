define("cc-frontend/lib/actions/lesson/default-lesson-activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(_payload, models, verb = "edited") {
    let planbook = models.planbook || {};
    let to = [`planbook-edits:planbook-${planbook.id}`];

    if (models.course) {
      to.push(`course-edits:course-${models.course.id}`);
    }

    let user = models.user || {};
    return {
      actor: `user-${user.id}`,
      verb: verb,
      object: `lesson-${Ember.get(models, "cardStack.id")}`,
      to: to
    };
  }
});