define("cc-frontend/lib/actions/session", ["exports", "cc-frontend/lib/patch-updater"], function (_exports, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    START_SESSION: {
      params: {
        id: "string"
      },

      patches(payload) {
        return _patchUpdater.default.create({
          type: "session",
          id: payload.id
        }).patches;
      }

    }
  };
  _exports.default = _default;
});