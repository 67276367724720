define("cc-frontend/templates/icons/-ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GSTl6Xrt",
    "block": "[[[10,\"svg\"],[14,\"baseProfile\",\"tiny\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 128 128\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"fill\",\"#231F20\"],[12],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"20\"],[14,\"cy\",\"62.3\"],[14,\"r\",\"12.7\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"64.9\"],[14,\"cy\",\"62.3\"],[14,\"r\",\"12.7\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"109.9\"],[14,\"cy\",\"62.3\"],[14,\"r\",\"12.7\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ellipsis.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});