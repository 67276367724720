define("cc-frontend/instance-initializers/app-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let session = appInstance.lookup("service:session");
    appInstance.lookup("service:app-state");
    let store = appInstance.lookup("service:store");
    store.applyPatchToStore({
      document: {
        id: Ember.get(session, "id"),
        modelType: "app-state",
        ops: {}
      },
      isCreatePatch: true
    });
  }

  var _default = {
    name: "app-state",
    initialize: initialize,
    after: ["session"]
  };
  _exports.default = _default;
});