define("cc-frontend/components/icons/alarm-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19.504 24.004c-.14 0-.26-.06-.36-.15l-2.55-2.55c-1.41.78-3 1.19-4.61 1.19 -1.609 0-3.2-.42-4.601-1.2l-2.55 2.54c-.1.09-.22.14-.36.14 -.14 0-.26-.06-.36-.15 -.2-.2-.2-.52 0-.71l2.38-2.39c-2.53-1.79-4.04-4.67-4.04-7.77 0-5.238 4.262-9.5 9.5-9.5 5.23 0 9.5 4.262 9.5 9.5 0 3.09-1.51 5.97-4.04 7.76l2.38 2.38c.19.19.19.51 0 .7 -.1.09-.22.14-.35.14ZM12 4.5C7.31 4.5 3.5 8.31 3.5 13c0 4.68 3.81 8.5 8.5 8.5 4.68 0 8.5-3.82 8.5-8.5 0-4.69-3.82-8.5-8.5-8.5Z"
      ></path>
      <path
        d="M12.004 13.504c-.28 0-.5-.23-.5-.5v-6.5c0-.28.22-.5.5-.5 .27 0 .5.22.5.5v6h3c.27 0 .5.22.5.5 0 .27-.23.5-.5.5H12Z"
      ></path>
      <path
        d="M1.948 9.057c-.12 0-.23-.05-.32-.12 -.22-.18-.42-.39-.6-.6 -1.58-1.91-1.31-4.74.59-6.32 .8-.67 1.81-1.03 2.85-1.03 1.34 0 2.606.59 3.46 1.62 .09.12.13.28.1.43 -.04.15-.15.28-.29.34 -2.4 1.06-4.3 2.96-5.36 5.355 -.07.14-.2.24-.35.28 -.04 0-.08.01-.12.01Zm2.53-7.06c-.81 0-1.6.28-2.22.79C1.53 3.37 1.09 4.21 1 5.13c-.09.92.19 1.83.78 2.54 0 0 0 0 0 0 1.09-2.093 2.81-3.82 4.9-4.91 -.63-.52-1.4-.8-2.23-.8Z"
      ></path>
      <path
        d="M22.059 9.057c-.04 0-.08-.01-.12-.02 -.16-.04-.28-.15-.35-.29 -1.061-2.4-2.97-4.294-5.36-5.36 -.15-.07-.25-.2-.29-.35 -.04-.16 0-.32.1-.44C16.88 1.56 18.149.97 19.49.97c1.03 0 2.05.36 2.85 1.02 .92.76 1.49 1.84 1.6 3.03 .11 1.19-.25 2.35-1.01 3.28 -.18.21-.39.42-.6.59 -.09.07-.21.11-.32.11Zm-4.76-6.26c2.093 1.089 3.81 2.81 4.9 4.9 0-.01 0-.01 0-.01 .593-.72.87-1.63.78-2.55 -.09-.93-.54-1.763-1.25-2.36 -.63-.52-1.41-.8-2.22-.8 -.83-.001-1.61.28-2.23.79Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "PkgElk4C",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M19.504 24.004c-.14 0-.26-.06-.36-.15l-2.55-2.55c-1.41.78-3 1.19-4.61 1.19 -1.609 0-3.2-.42-4.601-1.2l-2.55 2.54c-.1.09-.22.14-.36.14 -.14 0-.26-.06-.36-.15 -.2-.2-.2-.52 0-.71l2.38-2.39c-2.53-1.79-4.04-4.67-4.04-7.77 0-5.238 4.262-9.5 9.5-9.5 5.23 0 9.5 4.262 9.5 9.5 0 3.09-1.51 5.97-4.04 7.76l2.38 2.38c.19.19.19.51 0 .7 -.1.09-.22.14-.35.14ZM12 4.5C7.31 4.5 3.5 8.31 3.5 13c0 4.68 3.81 8.5 8.5 8.5 4.68 0 8.5-3.82 8.5-8.5 0-4.69-3.82-8.5-8.5-8.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12.004 13.504c-.28 0-.5-.23-.5-.5v-6.5c0-.28.22-.5.5-.5 .27 0 .5.22.5.5v6h3c.27 0 .5.22.5.5 0 .27-.23.5-.5.5H12Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M1.948 9.057c-.12 0-.23-.05-.32-.12 -.22-.18-.42-.39-.6-.6 -1.58-1.91-1.31-4.74.59-6.32 .8-.67 1.81-1.03 2.85-1.03 1.34 0 2.606.59 3.46 1.62 .09.12.13.28.1.43 -.04.15-.15.28-.29.34 -2.4 1.06-4.3 2.96-5.36 5.355 -.07.14-.2.24-.35.28 -.04 0-.08.01-.12.01Zm2.53-7.06c-.81 0-1.6.28-2.22.79C1.53 3.37 1.09 4.21 1 5.13c-.09.92.19 1.83.78 2.54 0 0 0 0 0 0 1.09-2.093 2.81-3.82 4.9-4.91 -.63-.52-1.4-.8-2.23-.8Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M22.059 9.057c-.04 0-.08-.01-.12-.02 -.16-.04-.28-.15-.35-.29 -1.061-2.4-2.97-4.294-5.36-5.36 -.15-.07-.25-.2-.29-.35 -.04-.16 0-.32.1-.44C16.88 1.56 18.149.97 19.49.97c1.03 0 2.05.36 2.85 1.02 .92.76 1.49 1.84 1.6 3.03 .11 1.19-.25 2.35-1.01 3.28 -.18.21-.39.42-.6.59 -.09.07-.21.11-.32.11Zm-4.76-6.26c2.093 1.089 3.81 2.81 4.9 4.9 0-.01 0-.01 0-.01 .593-.72.87-1.63.78-2.55 -.09-.93-.54-1.763-1.25-2.36 -.63-.52-1.41-.8-2.22-.8 -.83-.001-1.61.28-2.23.79Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/alarm-clock.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});