define("cc-frontend/transitions/fade-direction-slight-center", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fade;

  function fade(dimension, direction, opts, offset = 10) {
    // const oldParams = { opacity: 0, scaleX: "0.92", scaleY: "0.92" },
    //       newParams = { opacity: [[(opts.maxOpacity || 1), 0], "linear"], scaleX: [["1", "0.92"], [500, 30]], scaleY: [["1", "0.92"], [400, 15]]},
    //       fadingElement = findFadingElement(this);
    const oldParams = {
      opacity: 0,
      scaleX: 0.9,
      scaleY: 0.9
    },
          // newParams = { scaleX: [1, [400, 20], 0.85], scaleY: [1, [400, 20], .85]},
    // newParams = { scaleX: [1, "easeOutExpo", 0.80], scaleY: [1, "easeOutExpo", .50], opacity: [1, "easeOutCubic", .85]},
    newParams = {
      // scaleX: [1, [0.175, 0.885, 0.32, 1.275], 0.95],
      // scaleY: [1, [0.175, 0.885, 0.32, 1.275], 0.95],
      // scaleX: [1, [0.175, 0.885, 0.32, 1.275], 0.95],
      // scaleY: [1, [0.175, 0.885, 0.32, 1.275], 0.95],
      scaleX: [1, 0.9],
      scaleY: [1, 0.9],
      opacity: [1, "easeOutCubic", 0]
    },
          fadingElement = findFadingElement(this);
    let outOpts = opts,
        firstStep; // if (dimension.toLowerCase() === 'x') {
    //   oldParams.translateX = `${(direction * offset)}px`;
    //   newParams.translateX = ['0px', `${(direction * offset)}px`];
    // } else {
    //   oldParams.translateY = `${(direction * offset)}px`;
    //   newParams.translateY = ['0px', `${(direction * offset)}px`];
    // }

    if (this.oldElement) {
      this.oldElement.css("transform-origin", "top center");
    }

    if (this.newElement) {
      this.newElement.css("transform-origin", "top center");
    }

    if (fadingElement) {
      // We still have some older version that is in the process of
      // fading out, so out first step is waiting for it to finish.
      firstStep = (0, _liquidFire.finish)(fadingElement, "fade-out");
    } else {
      if ((0, _liquidFire.isAnimating)(this.oldElement, "fade-in")) {
        // if the previous view is partially faded in, scale its
        // fade-out duration appropriately.
        outOpts = {
          duration: (0, _liquidFire.timeSpent)(this.oldElement, "fade-in")
        };
      }

      (0, _liquidFire.stop)(this.oldElement);
      firstStep = (0, _liquidFire.animate)(this.oldElement, oldParams, outOpts, "fade-out");
    }

    return firstStep.then(() => {
      return (0, _liquidFire.animate)(this.newElement, newParams, opts, "fade-in");
    });
  }

  function findFadingElement(context) {
    for (var i = 0; i < context.older.length; i++) {
      var entry = context.older[i];

      if ((0, _liquidFire.isAnimating)(entry.element, "fade-out")) {
        return entry.element;
      }
    }

    if ((0, _liquidFire.isAnimating)(context.oldElement, "fade-out")) {
      return context.oldElement;
    }
  }
});