define("cc-frontend/components/planner/card-stacks/cards/parts/add-comment-form.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "add-comment-form": "_add-comment-form_hvhe41",
    "comment-box": "_comment-box_hvhe41",
    "add-comment-form-buttons": "_add-comment-form-buttons_hvhe41",
    "add-comment-form-buttons__submit": "_add-comment-form-buttons__submit_hvhe41",
    "add-comment-form-buttons__cancel": "_add-comment-form-buttons__cancel_hvhe41"
  };
  _exports.default = _default;
});