define("cc-frontend/utils/filter-dates/course", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isCourseDateCustom = isCourseDateCustom;
  _exports.isCourseDateDefault = isCourseDateDefault;
  _exports.filterCourseDateCustom = filterCourseDateCustom;
  _exports.findCourseDateCustom = findCourseDateCustom;
  _exports.filterCourseDateDefault = filterCourseDateDefault;
  _exports.findCourseDateDefault = findCourseDateDefault;
  _exports.extractCardStackTemplateIds = extractCardStackTemplateIds;

  function isCourseDateCustom(cd) {
    return cd.type === "course-date-custom";
  }

  function isCourseDateDefault(cd) {
    return cd.type === "course-date-default";
  }

  function filterCourseDateCustom(course) {
    return (0, _lodashEs.sortBy)((0, _lodashEs.filter)(Ember.get(course.attributes.calendar, "dates"), isCourseDateCustom), "attributes.dateString");
  }

  function findCourseDateCustom(course, dateString) {
    return (0, _lodashEs.find)(filterCourseDateCustom(course), date => date.attributes.dateString === dateString);
  }

  function filterCourseDateDefault(course) {
    return (0, _lodashEs.filter)(Ember.get(course.attributes.calendar, "dates"), isCourseDateDefault);
  }

  function findCourseDateDefault(course, rotationId, semesterId) {
    return (0, _lodashEs.find)(filterCourseDateDefault(course), date => date.attributes.rotationId === rotationId && date.attributes.semesterId === semesterId);
  }

  function extractCardStackTemplateIds(course) {
    return (0, _lodashEs.compact)((0, _lodashEs.uniq)((0, _lodashEs.map)(filterCourseDateDefault(course), date => Ember.get(date.attributes, "cardStackId"))));
  }
});