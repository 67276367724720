define("cc-frontend/modifiers/window-resize", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO type handler - this is cheap shortcut, Marika
  var _default = (0, _emberModifier.modifier)((element, [handler]) => {
    if (window) window.addEventListener("resize", handler);
    if (window) window.addEventListener("orientationchange", handler);
    return () => {
      if (window) window.removeEventListener("resize", handler);
      if (window) window.removeEventListener("orientationchange", handler);
    };
  });

  _exports.default = _default;
});