define("cc-frontend/lib/actions/course/COURSE_CHANGE_TITLE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/course/default-course-activity", "cc-frontend/lib/actions/course/default-course-context", "cc-frontend/lib/actions/course/default-course-models"], function (_exports, _patchUpdater, _defaultCourseActivity, _defaultCourseContext, _defaultCourseModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_CHANGE_TITLE = void 0;
  const COURSE_CHANGE_TITLE = {
    name: "COURSE_CHANGE_TITLE",
    params: {
      courseId: "string",
      planbookId: {
        type: "string",
        optional: true
      },
      title: "string",
      originalTitle: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("course", payload.courseId).set("attributes.title", payload.title).patch;

      return [patch];
    },

    undoPatches: null,

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseModels.default)(payload, findFn, userId);
      let newTitle = payload.title;
      let oldTitle = payload.originalTitle;
      return {
        title: "Renamed course",
        titleForAnalytics: "Course -  Change Title",
        description: `Renamed your "${oldTitle}" class "${newTitle}"`,
        context: (0, _defaultCourseContext.default)(payload, models),
        activity: (0, _defaultCourseActivity.default)(payload, models)
      };
    }

  };
  _exports.COURSE_CHANGE_TITLE = COURSE_CHANGE_TITLE;
  var _default = COURSE_CHANGE_TITLE;
  _exports.default = _default;
});