define("cc-frontend/components/planner/planbook/create-edit/create-modal", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="modal">
    <div local-class="modal-inner {{if this.isSmall "--is-small"}}">
      <Planner::Planbook::CreateEdit::Main
        @rotationCalendar={{@rotationCalendar}}
        @planbook={{@planbook}}
        @close={{@close}}
        @setSmallWidth={{this.setSmallWidth}}
        @setNormalWidth={{this.setNormalWidth}}
        @isCreatingFlow={{true}}
        @stepName={{@stepName}}
        @updateStepName={{@updateStepName}}
      />
    </div>
  </div>
  */
  {
    "id": "oTgvvSoU",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"modal\"],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/create-modal.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[[28,[37,1],[\"modal-inner \",[52,[30,0,[\"isSmall\"]],\"--is-small\"]],null]],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/create-modal.css\"]]]]]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@rotationCalendar\",\"@planbook\",\"@close\",\"@setSmallWidth\",\"@setNormalWidth\",\"@isCreatingFlow\",\"@stepName\",\"@updateStepName\"],[[30,1],[30,2],[30,3],[30,0,[\"setSmallWidth\"]],[30,0,[\"setNormalWidth\"]],true,[30,4],[30,5]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@rotationCalendar\",\"@planbook\",\"@close\",\"@stepName\",\"@updateStepName\"],false,[\"local-class\",\"concat\",\"if\",\"planner/planbook/create-edit/main\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/create-edit/create-modal.hbs",
    "isStrictMode": false
  });

  let CreatModalComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class CreatModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isSmall", _descriptor, this);
    }

    setSmallWidth() {
      this.isSmall = true;
    }

    setNormalWidth() {
      this.isSmall = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isSmall", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setSmallWidth", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setSmallWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setNormalWidth", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setNormalWidth"), _class.prototype)), _class));
  _exports.default = CreatModalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CreatModalComponent);
});