define("cc-frontend/templates/icons/-paperclip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4d6LV/kt",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"500\"],[14,\"height\",\"500\"],[14,\"viewBox\",\"-145.5 -20 500 500\"],[14,\"enable-background\",\"new -145.5 -20 500 500\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M172 423.7c-17.9 17.9-42.4 27.1-66.5 27.1-46.8 0-94.5-34.6-94.5-100.2v-270.4c0-27.6 12.2-50.3 33.2-62.1 20.6-11.8 47.7-11.8 68.2 0 21 12.2 33.2 35 33.2 62.1v258.6c0 14.9-3.9 27.6-11.4 36.8-7.4 9.2-17.9 14.4-29.3 14.4-20.1 0-40.2-17.5-40.2-50.8v-181.5c0-7.4 6.1-13.6 13.6-13.6 7.4 0 13.6 6.1 13.6 13.6v181.6c0 15.8 6.6 24.1 13.6 24.1 3.1 0 5.7-1.8 8.3-4.4 3.5-4.4 5.2-11.4 5.2-19.7v-258.6c0-17.9-7-31.5-19.7-38.9s-28.9-7.4-41.6 0-19.7 21-19.7 38.9v270.4c0 50.3 35 73.5 67.4 73.5s67.4-22.8 67.4-73.5v-270.4c0-7.4 6.1-13.6 13.6-13.6 7.4 0 13.6 6.1 13.6 13.6v270.4c0 31.5-11 56-28 72.6z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-paperclip.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});