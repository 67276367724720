define("cc-frontend/lib/actions/course/date/COURSE_DATE_FORCE_ON_MULTIPLE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models"], function (_exports, _patchUpdater, _lodash, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_DATE_FORCE_ON_MULTIPLE = void 0;
  const COURSE_DATE_FORCE_ON_MULTIPLE = {
    name: "COURSE_DATE_FORCE_ON_MULTIPLE",
    params: {
      datesForcedOn: "array",
      course: "object"
    },

    patches(payload) {
      let newCourseDates = _lodash.default.map(payload.course.attributes.calendar.dates, courseDate => {
        if (courseDate.type === "course-date-custom" && courseDate.attributes.dateString === payload.dateString) {
          let cd = _lodash.default.cloneDeep(courseDate);

          cd.attributes.isForcedOn = true;
          return cd;
        } else {
          return courseDate;
        }
      });

      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", newCourseDates).push("attributes.calendar.datesForcedOn", ["$each", payload.datesForcedOn]).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatches(payload) {
      let courseDates = JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates));

      let newCourseDates = _lodash.default.map(courseDates, courseDate => {
        if (courseDate.type === "course-date-custom" && courseDate.attributes.dateString === payload.dateString) {
          let cd = _lodash.default.cloneDeep(courseDate);

          cd.attributes.isForcedOn = false;
          return cd;
        } else {
          return courseDate;
        }
      });

      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", newCourseDates).pull("attributes.calendar.datesForcedOn", ["$each", payload.datesForcedOn]).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
      let courseTitle = payload.course.attributes.title;
      let numberOfDates = payload.datesForcedOn.length;
      let context = (0, _defaultCourseDateContext.default)(payload, models);

      let newContext = _lodash.default.omit(_lodash.default.assign({}, context, {
        dates: numberOfDates
      }), ["date"]);

      return {
        // TODO see if we can make this undoPatch also trigger DATE_ANNOTATION_CREATE so both are undone together
        title: "Kept Multiple Lessons Turned On",
        titleForAnalytics: "Lessons - Keep Multiple On - Schedule Modal",
        description: `Kept ${numberOfDates} lessons in your ${courseTitle} class turned on while editing that class' schedule`,
        context: newContext,
        activity: (0, _defaultCourseDateActivity.default)(payload, models)
      };
    }

  };
  _exports.COURSE_DATE_FORCE_ON_MULTIPLE = COURSE_DATE_FORCE_ON_MULTIPLE;
  var _default = COURSE_DATE_FORCE_ON_MULTIPLE;
  _exports.default = _default;
});