define("cc-frontend/models/google-classroom-post", ["exports", "reflect-metadata", "@marcj/marshal"], function (_exports, _reflectMetadata, _marshal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PublishLog = _exports.ClassSetting = _exports.Status = void 0;

  var _class, _descriptor, _descriptor2, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _class5, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _dec, _dec2, _dec3, _class7, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Status = (_class = class Status {
    constructor() {
      _initializerDefineProperty(this, "unpublishedClassesCount", _descriptor, this);

      _initializerDefineProperty(this, "publishedClassesCount", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "unpublishedClassesCount", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "publishedClassesCount", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.Status = Status;
  let ClassSetting = (_class3 = class ClassSetting {
    constructor() {
      _initializerDefineProperty(this, "googleClassId", _descriptor3, this);

      _initializerDefineProperty(this, "googlePostId", _descriptor4, this);

      _initializerDefineProperty(this, "dueAtDate", _descriptor5, this);

      _initializerDefineProperty(this, "dueAtTime", _descriptor6, this);

      _initializerDefineProperty(this, "scheduledAtTime", _descriptor7, this);

      _initializerDefineProperty(this, "createdInGoogleClassroomAt", _descriptor8, this);

      _initializerDefineProperty(this, "updatedInGoogleClassroomAt", _descriptor9, this);
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "googleClassId", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "googlePostId", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "dueAtDate", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "dueAtTime", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "scheduledAtTime", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "createdInGoogleClassroomAt", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "updatedInGoogleClassroomAt", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3);
  _exports.ClassSetting = ClassSetting;
  let PublishLog = (_class5 = class PublishLog {
    constructor() {
      _initializerDefineProperty(this, "publishedAt", _descriptor10, this);

      _initializerDefineProperty(this, "googleClassIdsPublished", _descriptor11, this);

      _initializerDefineProperty(this, "cardRevision", _descriptor12, this);

      _initializerDefineProperty(this, "errors", _descriptor13, this);
    }

  }, (_descriptor10 = _applyDecoratedDescriptor(_class5.prototype, "publishedAt", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class5.prototype, "googleClassIdsPublished", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class5.prototype, "cardRevision", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class5.prototype, "errors", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class5);
  _exports.PublishLog = PublishLog;
  let GoogleClassroomPost = (_dec = _marshal.field.type(Status), _dec2 = _marshal.field.array(ClassSetting), _dec3 = _marshal.field.array(PublishLog), (_class7 = class GoogleClassroomPost {
    constructor() {
      _initializerDefineProperty(this, "id", _descriptor14, this);

      _initializerDefineProperty(this, "cardRevisionAtLastUpdate", _descriptor15, this);

      _initializerDefineProperty(this, "status", _descriptor16, this);

      _initializerDefineProperty(this, "classSetting", _descriptor17, this);

      _initializerDefineProperty(this, "publishLogs", _descriptor18, this);
    }

  }, (_descriptor14 = _applyDecoratedDescriptor(_class7.prototype, "id", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class7.prototype, "cardRevisionAtLastUpdate", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class7.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class7.prototype, "classSetting", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class7.prototype, "publishLogs", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class7));
  _exports.default = GoogleClassroomPost;
});