define("cc-frontend/components/planner/menus/week-menu.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "planbook-header__menu-trigger": "_planbook-header__menu-trigger_yalw34"
  };
  _exports.default = _default;
});