define("cc-frontend/components/group/team-for-teamless-members", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/query", "ember-could-get-used-to-this"], function (_exports, _glimmerComponent, _query, _emberCouldGetUsedToThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.membersQuery.results.length}}
    {{#if this.planbooksQuery.isFetching}}
      <div class="tw-py-8">
        <DS::ThreeDotSpinner />
      </div>
    {{else}}
      <div class="group-team__header" data-group-id={{@group.id}} ...attributes>
        <div class="group-team__header__group-title">
          <div class="group-team__header__group-title__title">
            Members not in Teams
          </div>
        </div>
        {{!-- <div class="group-team__header__members">
          {{#each this.membersQuery.results as |result|}}
            {{#if result.id}}
              <LinkTo @route="user.show" @model={{result.id}}>
                <ContainerUserSummary @userId={{result.id}} as |userSummary|>
                  {{#if userSummary.content}}
                    <User::Avatar
                      @userSummary={{userSummary.content}}
                      @avatarSizeClass="user-avatar--sm"
                      @enableTooltip={{true}}
                    />
                  {{/if}}
                </ContainerUserSummary>
              </LinkTo>
            {{/if}}
          {{/each}}
        </div> --}}
      </div>
      <div class="group-team__planbooks">
        {{#each this.planbooksQuery.results as |result|}}
          <LinkTo
            @route="planbookv5.planner"
            @model={{result.id}}
            class="planbook-pill test-planbook-pill-link"
          >
            <Planner::Planbook::Pill @planbookId={{result.id}} />
          </LinkTo>
        {{/each}}
      </div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "uyWzPQ2g",
    "block": "[[[41,[30,0,[\"membersQuery\",\"results\",\"length\"]],[[[41,[30,0,[\"planbooksQuery\",\"isFetching\"]],[[[1,\"    \"],[10,0],[14,0,\"tw-py-8\"],[12],[1,\"\\n      \"],[8,[39,1],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,0],[24,0,\"group-team__header\"],[16,\"data-group-id\",[30,1,[\"id\"]]],[17,2],[12],[1,\"\\n      \"],[10,0],[14,0,\"group-team__header__group-title\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"group-team__header__group-title__title\"],[12],[1,\"\\n          Members not in Teams\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"group-team__planbooks\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"planbooksQuery\",\"results\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],[[24,0,\"planbook-pill test-planbook-pill-link\"]],[[\"@route\",\"@model\"],[\"planbookv5.planner\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,5],null,[[\"@planbookId\"],[[30,3,[\"id\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"@group\",\"&attrs\",\"result\"],false,[\"if\",\"d-s/three-dot-spinner\",\"each\",\"-track-array\",\"link-to\",\"planner/planbook/pill\"]]",
    "moduleName": "cc-frontend/components/group/team-for-teamless-members.hbs",
    "isStrictMode": false
  });

  let GroupTeamForTeamlessMembers = (_class = class GroupTeamForTeamlessMembers extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "planbooksQuery", _descriptor, this);

      _initializerDefineProperty(this, "membersQuery", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "planbooksQuery", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _query.default(() => {
        return ["planbooks_for_members_not_in_teams_for_school_year", `${this.args.group.id}:${this.args.currentSchoolYearId}`];
      });
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "membersQuery", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _query.default(() => ["members_not_in_teams", this.args.group.id]);
    }
  })), _class);
  _exports.default = GroupTeamForTeamlessMembers;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupTeamForTeamlessMembers);
});