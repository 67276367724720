define("cc-frontend/routes/user/edit-password", ["exports", "cc-frontend/lib/jwt-decode"], function (_exports, _jwtDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditPasswordRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class EditPasswordRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "finder", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "rpc", _descriptor4, this);
    }

    model(params) {
      /**
      I should probably just do this in the background?
      I should put the app into the loading state while I fetch a link?
       That way, if I send a link with a token in it, like for an email,
      I do the same thing -- I request an official token from
      the client. So, in that way, sending a PW reset email is the same
      as sending a check your comment email -- it's just an email.
       We could also do the check into localStorage to see if we stored it there
      and, if so, not request one from the server. Otherwise, request a new
      token.
       So, flow:
      - send token to server
      - 200
        - set new session token, continue
      - 401
        - show error message that token is expired and have them send a new token
      - _
        - show generic error message
        For the application,
      - send token to server
      - 200
        - set new session token, continue
      - 401
        - send to the login page
        - on login, check if there was a URL to go to.
        - This is where it gets tricky -- maybe we just send them to the page anyways?
          and have a sign in modal that's really easy to use? I'd be for that.
          */
      if (params.resetPasswordToken) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          Ember.get(this, "rpc").execute("EXCHANGE_TOKEN", Ember.get(this, "session.id"), params.resetPasswordToken).then(result => {
            Ember.get(this, "finder").insertResponseDocs(result);
            this.session.setTokenAndAuthenticate(result.data); // this.transitionTo({queryParams: {resetPasswordToken: null, completed: false}})

            return resolve(result);
          }).catch(error => {
            console.log("error", error);
            let err = error && error.errors ? error.errors[0] : null;
            return reject(err);
          });
        });
        /**
          - Ajax to server
          - 200
            - log in user
          - 401
            - redirect to edit-password-error.js
          */
      } // return this.transitionTo('user.edit-password.error')
      // return new Error("Expired")
      // throw "Expired"


      if (params.resetPasswordToken) {
        // Check if expired
        let decodedJwt = (0, _jwtDecode.default)(params.resetPasswordToken);
        Ember.get(this, "finder").insertResponseDocs({
          data: {
            id: decodedJwt.sessionId,
            type: "session",
            attributes: {
              token: params.resetPasswordToken
            }
          }
        });
        Ember.get(this, "session").set("sessionId", decodedJwt.sessionId);
      } else {
        this.transitionTo("user.sign");
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "finder", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditPasswordRoute;
});