define("cc-frontend/components/icons/school-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
    <g>
      <path
        d="M1,24c-0.551,0-1-0.448-1-1v-9c0-0.552,0.449-1,1-1h6v-2c0-2.396,1.67-4.422,4-4.894V0.5C11,0.224,11.224,0,11.5,0h4
  		c0.552,0,1,0.449,1,1v2c0,0.551-0.448,1-1,1H12v2c2.757,0,5,2.243,5,5v2h6c0.552,0,1,0.448,1,1v9c0,0.552-0.448,1-1,1H1z M23,23v-9
  		h-6v9H23z M16,23V11c0-2.206-1.794-4-4-4s-4,1.794-4,4v12h2v-4c0-1.103,0.897-2,2-2s2,0.897,2,2v4H16z M13,23v-4
  		c0-0.552-0.448-1-1-1c-0.551,0-1,0.448-1,1v4H13z M1,23h6v-9l-6,0L1,23z M15.5,3V1H12v2H15.5z"
      ></path>
      <path
        d="M19,18c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v1c0,0.552-0.448,1-1,1H19z M19,17h2v-1h-2
  		L19,17z"
      ></path>
      <path
        d="M18.5,22c-0.276,0-0.5-0.224-0.5-0.5V20c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v1.5c0,0.276-0.224,0.5-0.5,0.5H18.5z
  		 M21,21v-1h-2l0,1H21z"
      ></path>
      <path
        d="M3,18c-0.551,0-1-0.448-1-1v-1c0-0.552,0.449-1,1-1h2c0.551,0,1,0.448,1,1v1c0,0.552-0.449,1-1,1H3z M3,17h2v-1H3L3,17z"
      ></path>
      <path
        d="M3,22c-0.551,0-1-0.448-1-1v-1c0-0.552,0.449-1,1-1h2c0.551,0,1,0.448,1,1v1c0,0.552-0.449,1-1,1H3z M3,21h2v-1H3L3,21z"
      ></path>
      <path
        d="M12,13c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2S13.103,13,12,13z M12,10c-0.551,0-1,0.449-1,1s0.449,1,1,1
  		c0.552,0,1-0.449,1-1S12.552,10,12,10z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "HECukMtj",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,5,\"enable-background:new 0 0 24 24;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M1,24c-0.551,0-1-0.448-1-1v-9c0-0.552,0.449-1,1-1h6v-2c0-2.396,1.67-4.422,4-4.894V0.5C11,0.224,11.224,0,11.5,0h4\\n\\t\\tc0.552,0,1,0.449,1,1v2c0,0.551-0.448,1-1,1H12v2c2.757,0,5,2.243,5,5v2h6c0.552,0,1,0.448,1,1v9c0,0.552-0.448,1-1,1H1z M23,23v-9\\n\\t\\th-6v9H23z M16,23V11c0-2.206-1.794-4-4-4s-4,1.794-4,4v12h2v-4c0-1.103,0.897-2,2-2s2,0.897,2,2v4H16z M13,23v-4\\n\\t\\tc0-0.552-0.448-1-1-1c-0.551,0-1,0.448-1,1v4H13z M1,23h6v-9l-6,0L1,23z M15.5,3V1H12v2H15.5z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M19,18c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v1c0,0.552-0.448,1-1,1H19z M19,17h2v-1h-2\\n\\t\\tL19,17z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M18.5,22c-0.276,0-0.5-0.224-0.5-0.5V20c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v1.5c0,0.276-0.224,0.5-0.5,0.5H18.5z\\n\\t\\t M21,21v-1h-2l0,1H21z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3,18c-0.551,0-1-0.448-1-1v-1c0-0.552,0.449-1,1-1h2c0.551,0,1,0.448,1,1v1c0,0.552-0.449,1-1,1H3z M3,17h2v-1H3L3,17z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3,22c-0.551,0-1-0.448-1-1v-1c0-0.552,0.449-1,1-1h2c0.551,0,1,0.448,1,1v1c0,0.552-0.449,1-1,1H3z M3,21h2v-1H3L3,21z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,13c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2S13.103,13,12,13z M12,10c-0.551,0-1,0.449-1,1s0.449,1,1,1\\n\\t\\tc0.552,0,1-0.449,1-1S12.552,10,12,10z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/school-building.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});