define("cc-frontend/lib/actions/planbook/PLANBOOK_UPDATE_RECENT_STANDARD_SETS", ["exports", "cc-frontend/lib/patch-updater"], function (_exports, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_UPDATE_RECENT_STANDARD_SETS = void 0;
  const PLANBOOK_UPDATE_RECENT_STANDARD_SETS = {
    name: "PLANBOOK_UPDATE_RECENT_STANDARD_SETS",
    params: {
      planbookId: "string",
      standardSetIds: {
        type: "array",
        items: {
          type: "string"
        }
      }
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).set("attributes.recentStandardSetIds", payload.standardSetIds).patch;

      return [patch];
    },

    undoPatches: null,
    narrative: null
  };
  _exports.PLANBOOK_UPDATE_RECENT_STANDARD_SETS = PLANBOOK_UPDATE_RECENT_STANDARD_SETS;
  var _default = PLANBOOK_UPDATE_RECENT_STANDARD_SETS;
  _exports.default = _default;
});