define("cc-frontend/components/planner/menus/parts/outline-button.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button": "_button_zgi8ea",
    "--no-horizontal-margin": "_--no-horizontal-margin_zgi8ea",
    "--is-link-style": "_--is-link-style_zgi8ea"
  };
  _exports.default = _default;
});