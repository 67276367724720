define("cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_CREATE", ["exports", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models"], function (_exports, _datesOffCalculator, _patchUpdater, _lodash, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareCreateAnnotation = prepareCreateAnnotation;
  _exports.default = _exports.COURSE_DATE_ANNOTATION_CREATE = void 0;

  function prepareCreateAnnotation(payload) {
    return {
      name: COURSE_DATE_ANNOTATION_CREATE.name,
      payload: payload,
      patches: COURSE_DATE_ANNOTATION_CREATE.patches(payload),
      undoPatches: COURSE_DATE_ANNOTATION_CREATE.undoPatches(payload),
      narrative: (0, _lodash.partial)(COURSE_DATE_ANNOTATION_CREATE.narrative, payload)
    };
  }

  const COURSE_DATE_ANNOTATION_CREATE = {
    name: "COURSE_DATE_ANNOTATION_CREATE",

    patches(payload) {
      // create patch for annotation
      let newAnnotations = payload.course.attributes.calendar.dateAnnotations.concat([payload.annotation]);
      let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(newAnnotations);

      let annotationPatch = _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dateAnnotations", payload.annotation).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1).patch; // create patch for dates that are forced on, but only use if that array isn't null.


      let newCourseDates = _lodash.default.map(payload.course.attributes.calendar.dates, courseDate => {
        if (courseDate.type === "course-date-custom" && _lodash.default.includes(payload.datesForcedOn, courseDate.attributes.dateString)) {
          let cd = _lodash.default.cloneDeep(courseDate);

          cd.attributes.isForcedOn = true;
          return cd;
        } else {
          return courseDate;
        }
      });

      let forceOnPatch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", newCourseDates).push("attributes.calendar.datesForcedOn", ["$each", payload.datesForcedOn]).inc("attributes.calendar.version", 1).patch;

      if (payload.datesForcedOn) {
        return [annotationPatch, forceOnPatch];
      } else {
        return [annotationPatch];
      }
    },

    undoPatches(payload) {
      //create patch for annotation
      let annotationPatch = _patchUpdater.default.create("course", payload.course.id).pull("attributes.calendar.dateAnnotations", payload.annotation).set("attributes.calendar.datesOff", JSON.parse(JSON.stringify(payload.originalDatesOff || []))).inc("attributes.calendar.version", 1).patch; // create patch for dates that are forced on, but only use if that array isn't null.


      let courseDates = JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates));

      let newCourseDates = _lodash.default.map(courseDates, courseDate => {
        if (courseDate.type === "course-date-custom" && _lodash.default.includes(payload.datesForcedOn, courseDate.attributes.dateString)) {
          let cd = _lodash.default.cloneDeep(courseDate);

          cd.attributes.isForcedOn = false;
          return cd;
        } else {
          return courseDate;
        }
      });

      let forceOnPatch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", newCourseDates).pull("attributes.calendar.datesForcedOn", [JSON.parse(JSON.stringify(payload.datesForcedOn || []))]).inc("attributes.calendar.version", 1);

      if (payload.datesForcedOn) {
        return [annotationPatch, forceOnPatch];
      } else {
        return [annotationPatch];
      }
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId); // @ts-ignore no types for moment

      let formattedDateString = moment(payload.annotation.attributes.dateString).format("ddd, MMM. D, YYYY");
      let courseTitle = payload.course.attributes.title;
      let numberOfDates;

      if (payload.datesForcedOn) {
        numberOfDates = payload.datesForcedOn.length;
      } else {
        numberOfDates = "";
      }

      let dateMessage = payload.annotation.type === "date-annotation-course-default" ? `every ${payload.rotationTitle}` : `${formattedDateString}`;
      let forceOnMessage = payload.datesForcedOn ? ` But kept ${numberOfDates} lessons that you had already edited turned on.` : "";
      let actionTitle = // @ts-ignore please fix later when Scott is on parental leave
      payload.annotation.attributes.showAnnotation === false ? "Turned Class Off" : "Turned Class Off and Added Note"; // @ts-ignore parental leave

      let addedNote = payload.annotation.attributes.showAnnotation === false ? false : true;
      let descriptionStarter = // @ts-ignore parental leave
      payload.annotation.attributes.showAnnotation === false ? "Turned off" : "Turned off and added a note to";
      let context = (0, _defaultCourseDateContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        addedNote: addedNote,
        //@ts-ignore parental leave - separate narrative for default and custom
        noteTitle: payload.annotation.attributes.title,
        alsoForcedDatesOn: payload.datesForcedOn ? true : false,
        datesForcedOn: payload.datesForcedOn,
        type: payload.annotation.type
      });

      return {
        title: actionTitle,
        titleForAnalytics: "Lesson - Toggle Off",
        description: `${descriptionStarter} your ${courseTitle} class on ${dateMessage}.${forceOnMessage}`,
        context: newContext,
        activity: (0, _defaultCourseDateActivity.default)(payload, models, "added")
      };
    }

  };
  _exports.COURSE_DATE_ANNOTATION_CREATE = COURSE_DATE_ANNOTATION_CREATE;
  var _default = COURSE_DATE_ANNOTATION_CREATE;
  _exports.default = _default;
});