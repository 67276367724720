define("cc-frontend/components/admin/group/index-table", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.session.user.attributes.isSupport}}
    <div class="tw-flex tw-w-1/5 tw-bg-grey-lightest tw-p-4 tw-flex-col">
      {{!-- <div class="admin-sidebar-button" {{action "addGroup"}}>Add Group</div> --}}
      <LinkTo @route="admin.groups-old-admin.new" class="admin-sidebar-button">
        Add Group
      </LinkTo>
      <div class="tw-flex tw-flex-col">
        <div class="tw-py-2 tw-font-bold">
          Filter by Type
        </div>
        <div class="tw-py-2 tw-cursor-pointer" {{on "click" this.filterUsedAs}}>
          All
        </div>
        <div class="tw-py-2 tw-cursor-pointer" {{on "click" (fn this.filterUsedAs "school")}}>
          Schools
        </div>
        <div class="tw-py-2 tw-cursor-pointer" {{on "click" (fn this.filterUsedAs "team")}}>
          Teams
        </div>
        <div
          class="tw-py-2 tw-cursor-pointer"
          {{on "click" (fn this.filterUsedAs "legacy-group-free")}}
        >
          Legacy Free Group
        </div>
      </div>
    </div>
    <EmberTable class="group-admin-index-table tw-w-4/5 tw-p-4 tw-ml-4 tw-text-base" as |t|>
      <t.head @widthConstraint="gte-container" @resizeMode="fluid" @columns={{this.columns}} />
      <t.body @rows={{@model}} @onSelect={{this.onRowClick}} @rowSelectionMode="single" />
    </EmberTable>
  {{else}}
    Must be logged into a support account
  {{/if}}
  */
  {
    "id": "oLV4oZbX",
    "block": "[[[41,[30,0,[\"session\",\"user\",\"attributes\",\"isSupport\"]],[[[1,\"  \"],[10,0],[14,0,\"tw-flex tw-w-1/5 tw-bg-grey-lightest tw-p-4 tw-flex-col\"],[12],[1,\"\\n\"],[1,\"    \"],[8,[39,1],[[24,0,\"admin-sidebar-button\"]],[[\"@route\"],[\"admin.groups-old-admin.new\"]],[[\"default\"],[[[[1,\"\\n      Add Group\\n    \"]],[]]]]],[1,\"\\n    \"],[10,0],[14,0,\"tw-flex tw-flex-col\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"tw-py-2 tw-font-bold\"],[12],[1,\"\\n        Filter by Type\\n      \"],[13],[1,\"\\n      \"],[11,0],[24,0,\"tw-py-2 tw-cursor-pointer\"],[4,[38,2],[\"click\",[30,0,[\"filterUsedAs\"]]],null],[12],[1,\"\\n        All\\n      \"],[13],[1,\"\\n      \"],[11,0],[24,0,\"tw-py-2 tw-cursor-pointer\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"filterUsedAs\"]],\"school\"],null]],null],[12],[1,\"\\n        Schools\\n      \"],[13],[1,\"\\n      \"],[11,0],[24,0,\"tw-py-2 tw-cursor-pointer\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"filterUsedAs\"]],\"team\"],null]],null],[12],[1,\"\\n        Teams\\n      \"],[13],[1,\"\\n      \"],[11,0],[24,0,\"tw-py-2 tw-cursor-pointer\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"filterUsedAs\"]],\"legacy-group-free\"],null]],null],[12],[1,\"\\n        Legacy Free Group\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,4],[[24,0,\"group-admin-index-table tw-w-4/5 tw-p-4 tw-ml-4 tw-text-base\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"head\"]],null,[[\"@widthConstraint\",\"@resizeMode\",\"@columns\"],[\"gte-container\",\"fluid\",[30,0,[\"columns\"]]]],null],[1,\"\\n    \"],[8,[30,1,[\"body\"]],null,[[\"@rows\",\"@onSelect\",\"@rowSelectionMode\"],[[30,2],[30,0,[\"onRowClick\"]],\"single\"]],null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],[[[1,\"  Must be logged into a support account\\n\"]],[]]]],[\"t\",\"@model\"],false,[\"if\",\"link-to\",\"on\",\"fn\",\"ember-table\"]]",
    "moduleName": "cc-frontend/components/admin/group/index-table.hbs",
    "isStrictMode": false
  });

  let GroupAdminIndexTable = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.oneWay("fetchRecords.isRunning"), _dec6 = Ember.computed, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class GroupAdminIndexTable extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "isLoading", _descriptor5, this);

      _defineProperty(this, "canLoadMore", true);

      _defineProperty(this, "enableSync", true);

      _defineProperty(this, "model", []);

      _defineProperty(this, "meta", null);

      _defineProperty(this, "offset", 0);

      _defineProperty(this, "limit", 20);

      _defineProperty(this, "sorts", []);

      _defineProperty(this, "filter", []);

      _defineProperty(this, "fetchRecords", (0, _emberConcurrency.task)(function* () {
        if (Ember.get(this, "canLoadMore") === false) return;

        let sortFields = _lodash.default.map(Ember.get(this, "sorts"), hash => `${hash.dir}${hash.sortField}`).join(",");

        let filters = _lodash.default.map(Ember.get(this, "filters"), (val, key) => `[${key}]=${val}`).join("&");

        let offset = Ember.get(this, "offset") || 0;
        let records = yield this.rpc.execute("GROUP_ADMIN_INDEX", offset, Ember.get(this, "limit"), sortFields, filters); // let records = yield this.get('store').query('user', this.getProperties(['page', 'limit', 'sort', 'dir']));

        this.set("canLoadMore", Ember.get(records, "meta.nextOffset") !== null);
        this.model.pushObjects(records.data);

        if (Ember.get(this, "meta.nextOffset") === null) {
          Ember.set(this, "canLoadMore", false);
        } else {
          Ember.set(this, "offset", Ember.get(records, "meta.nextOffset"));
        }
      }).keepLatest());
    }

    get columns() {
      return [{
        name: "#",
        valuePath: "meta.offset",
        sortable: false,
        width: "30px"
      }, {
        name: "Title",
        valuePath: "attributes.title",
        sortField: "title",
        sortable: true
      }, {
        name: "Type",
        valuePath: "attributes.usedAs",
        sortField: "usedAs",
        sortable: true
      }, {
        name: "Unlocked?",
        valuePath: "attributes.isUnlocked",
        sortField: "isUnlocked",
        sortable: true
      }];
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      this.fetchRecords.perform();
    }

    onScrolledToBottom() {
      if (this.canLoadMore) {
        this.fetchRecords.perform();
      }
    }

    onColumnClick(column) {
      if (column.sorted) {
        // remove the  old one if its there
        _lodash.default.remove(Ember.get(this, "sorts"), sort => sort.field === column.sortField);

        Ember.get(this, "sorts").unshift({
          sortField: column.sortField,
          dir: column.ascending ? "" : "-"
        });
      }

      this.setProperties({
        canLoadMore: true,
        offset: 0
      });
      Ember.get(this, "model").clear();
      Ember.set(column, "sorted", true);
      Ember.get(this, "fetchRecords").perform();
    }

    filterUsedAs(type) {
      if (type === undefined) {
        Ember.set(this, "filters", _lodash.default.omit(Ember.get(this, "filters"), ["usedAs"]));
      } else {
        Ember.get(this, "filters")["usedAs"] = type;
      }

      this.setProperties({
        canLoadMore: true,
        offset: 0
      });
      Ember.get(this, "model").clear();
      Ember.get(this, "fetchRecords").perform();
    }

    onRowClick(row) {
      this.router.transitionTo("admin.groups-old-admin.show", Ember.get(row, "id"));
    }

    addGroup() {// Put up dialog asking which type
      // RPC - CREATE_GROUP_ADMIN
      // rediret to new group page
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "columns", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onScrolledToBottom", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onScrolledToBottom"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onColumnClick", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onColumnClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterUsedAs", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "filterUsedAs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRowClick", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onRowClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addGroup", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "addGroup"), _class.prototype)), _class));
  _exports.default = GroupAdminIndexTable;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupAdminIndexTable);
});