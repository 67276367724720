define("cc-frontend/utils/default-value", ["exports", "ember-notifier/utils/default-value"], function (_exports, _defaultValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _defaultValue.default;
    }
  });
});