define("cc-frontend/components/planner/menus/day-menu/toggle-courses/body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header-bar": "_header-bar_z2lnrl",
    "header-bar__back-arrow": "_header-bar__back-arrow_z2lnrl",
    "header-bar__text": "_header-bar__text_z2lnrl",
    "class-list": "_class-list_z2lnrl",
    "toggle-course-is-on": "_toggle-course-is-on_z2lnrl",
    "is-off": "_is-off_z2lnrl",
    "toggle-course-is-on__course-title": "_toggle-course-is-on__course-title_z2lnrl"
  };
  _exports.default = _default;
});