define("cc-frontend/components/icons/notebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M9,10c-0.6,0 -1,-0.4 -1,-1v-3c0,-0.6 0.4,-1 1,-1h5c0.6,0 1,0.4 1,1v3c0,0.6 -0.4,1 -1,1h-5Zm0,-1h5v-3h-5v3Z"
      ></path>
      <path
        d="M4.5,23c-0.8,0 -1.5,-0.7 -1.5,-1.5v-1.5h-0.5c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5h0.5v-4h-0.5c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5h0.5v-4h-0.5c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5h0.5v-4h-0.5c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5h0.5v-1.5c0,-0.8 0.7,-1.5 1.5,-1.5h13c0.8,0 1.5,0.7 1.5,1.5v0.5h1.5c0.8,0 1.5,0.7 1.5,1.5v14c0,0.8 -0.7,1.5 -1.5,1.5h-1.5v1.5c0,0.8 -0.7,1.5 -1.5,1.5h-13Zm-0.5,-1.5c0,0.3 0.2,0.5 0.5,0.5h13c0.3,0 0.5,-0.2 0.5,-0.5v-19c0,-0.3 -0.2,-0.5 -0.5,-0.5h-13c-0.3,0 -0.5,0.2 -0.5,0.5v1.5h1.5c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5h-1.5v4h1.5c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5h-1.5v4h1.5c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5h-1.5v4h1.5c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5h-1.5v1.5Zm16.5,-2.5c0.3,0 0.5,-0.2 0.5,-0.5v-2.5h-2v3h1.5Zm0.5,-4v-3h-2v3h2Zm0,-4v-3h-2v3h2Zm0,-4v-2.5c0,-0.3 -0.2,-0.5 -0.5,-0.5h-1.5v3h2Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "rLtr/NSG",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M9,10c-0.6,0 -1,-0.4 -1,-1v-3c0,-0.6 0.4,-1 1,-1h5c0.6,0 1,0.4 1,1v3c0,0.6 -0.4,1 -1,1h-5Zm0,-1h5v-3h-5v3Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M4.5,23c-0.8,0 -1.5,-0.7 -1.5,-1.5v-1.5h-0.5c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5h0.5v-4h-0.5c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5h0.5v-4h-0.5c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5h0.5v-4h-0.5c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5h0.5v-1.5c0,-0.8 0.7,-1.5 1.5,-1.5h13c0.8,0 1.5,0.7 1.5,1.5v0.5h1.5c0.8,0 1.5,0.7 1.5,1.5v14c0,0.8 -0.7,1.5 -1.5,1.5h-1.5v1.5c0,0.8 -0.7,1.5 -1.5,1.5h-13Zm-0.5,-1.5c0,0.3 0.2,0.5 0.5,0.5h13c0.3,0 0.5,-0.2 0.5,-0.5v-19c0,-0.3 -0.2,-0.5 -0.5,-0.5h-13c-0.3,0 -0.5,0.2 -0.5,0.5v1.5h1.5c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5h-1.5v4h1.5c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5h-1.5v4h1.5c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5h-1.5v4h1.5c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5h-1.5v1.5Zm16.5,-2.5c0.3,0 0.5,-0.2 0.5,-0.5v-2.5h-2v3h1.5Zm0.5,-4v-3h-2v3h2Zm0,-4v-3h-2v3h2Zm0,-4v-2.5c0,-0.3 -0.2,-0.5 -0.5,-0.5h-1.5v3h2Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/notebook.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});