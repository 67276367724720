define("cc-frontend/lib/phoenix/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.closure = void 0;

  // wraps value in closure or returns closure
  let closure = value => {
    if (typeof value === "function") {
      return value;
    } else {
      let closure = function () {
        return value;
      };

      return closure;
    }
  };

  _exports.closure = closure;
});