define("cc-frontend/lib/actions/planbook/date/default-planbook-date-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultPlanbookDateContext;

  function defaultPlanbookDateContext(payload, models) {
    return {
      planbookId: Ember.get(models, "planbook.id"),
      planbookTitle: Ember.get(models, "planbook.attributes.title"),
      userId: Ember.get(models, "user.id"),
      dateString: payload.annotation.type === "date-annotation-planbook-custom-single" ? payload.annotation.attributes.dateString : null,
      startDate: payload.annotation.type === "date-annotation-planbook-custom-range" ? payload.annotation.attributes.startDate : null,
      endDate: payload.annotation.type === "date-annotation-planbook-custom-range" ? payload.annotation.attributes.endDate : null,
      weekdayTitle: Ember.get(models, "rotation.attributes.title"),
      calledFrom: Ember.get(payload, "calledFrom"),
      title: Ember.get(payload, "annotation.attributes.title"),
      isOff: Ember.get(payload, "annotation.attributes.isOff"),
      type: payload.annotation.type === "date-annotation-planbook-custom-single" ? "custom-single" : payload.annotation.type === "date-annotation-planbook-custom-range" ? "custom-range" : "default"
    };
  }
});