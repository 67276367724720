define("cc-frontend/components/x-modal-dialog", ["exports", "ember-modal-dialog/components/modal-dialog"], function (_exports, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- {{#ember-wormhole to=destinationElementId renderInPlace=false}}
    <div class="{{wrapperClassNamesString}} {{wrapper-class}}">
      {{#modal-dialog-overlay
        classNameBindings='overlayClassNamesString translucentOverlay:translucent overlayClass'
        action='close'}}
        {{#ember-modal-dialog-positioned-container classNameBindings="containerClassNamesString targetAttachmentClass container-class"
          targetAttachment=targetAttachment
          target=target}}
          {{yield}}
        {{/ember-modal-dialog-positioned-container}}
      {{/modal-dialog-overlay}}
    </div>
  {{/ember-wormhole}} --}}
  {{!-- {{#ember-wormhole to=destinationElementId}}
    {{#if isOverlaySibling}}
      <div class="{{wrapperClassNamesString}} {{wrapperClass}}">
        {{#if hasOverlay}}
          <div class={{overlayClassNamesString}} onclick={{action onClickOverlay}} tabindex="-1" data-emd-overlay></div>
        {{/if}}
        {{#ember-modal-dialog-positioned-container
            class=containerClassNamesString
            targetAttachment=targetAttachment
            target=legacyTarget
        }}
          {{yield}}
        {{/ember-modal-dialog-positioned-container}}
      </div>
    {{else}}
      <div class="{{wrapperClassNamesString}} {{wrapperClass}}">
        {{#if hasOverlay}}
          <div class={{overlayClassNamesString}} onclick={{action (ignore-children onClickOverlay)}} tabindex="-1" data-emd-overlay>
            {{#ember-modal-dialog-positioned-container
                class=containerClassNamesString
                targetAttachment=targetAttachment
                target=legacyTarget
            }}
              {{yield}}
            {{/ember-modal-dialog-positioned-container}}
          </div>
        {{else}}
          {{#ember-modal-dialog-positioned-container
              class=containerClassNamesString
              targetAttachment=targetAttachment
              target=legacyTarget
          }}
            {{yield}}
          {{/ember-modal-dialog-positioned-container}}
        {{/if}}
      </div>
    {{/if}}
  {{/ember-wormhole}} --}}
  {{#component
    this.modalDialogComponentName
    wrapperClass=this.wrapperClass
    wrapperClassNames=this.wrapperClassNames
    overlayClass=this.overlayClass
    overlayClassNames=this.overlayClassNames
    containerClass=this.containerClass
    containerClassNames=this.containerClassNames
    hasOverlay=this.hasOverlay
    translucentOverlay=this.translucentOverlay
    clickOutsideToClose=this.clickOutsideToClose
    destinationElementId=this.destinationElementId
    overlayPosition=this.overlayPosition
    tetherTarget=this.tetherTarget
    legacyTarget=this.target
    attachment=this.attachment
    targetAttachment=this.targetAttachment
    targetModifier=this.targetModifier
    targetOffset=this.targetOffset
    offset=this.offset
    tetherClassPrefix=this.tetherClassPrefix
    constraints=this.constraints
    attachmentClass=this.attachmentClass
    targetAttachmentClass=this.targetAttachmentClass
    onClickOverlay=(action "onClickOverlay")
    onClose=(action "onClose")
  }}
    {{yield}}
  {{/component}}
  */
  {
    "id": "4+kKT97G",
    "block": "[[[46,[30,0,[\"modalDialogComponentName\"]],null,[[\"wrapperClass\",\"wrapperClassNames\",\"overlayClass\",\"overlayClassNames\",\"containerClass\",\"containerClassNames\",\"hasOverlay\",\"translucentOverlay\",\"clickOutsideToClose\",\"destinationElementId\",\"overlayPosition\",\"tetherTarget\",\"legacyTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"constraints\",\"attachmentClass\",\"targetAttachmentClass\",\"onClickOverlay\",\"onClose\"],[[30,0,[\"wrapperClass\"]],[30,0,[\"wrapperClassNames\"]],[30,0,[\"overlayClass\"]],[30,0,[\"overlayClassNames\"]],[30,0,[\"containerClass\"]],[30,0,[\"containerClassNames\"]],[30,0,[\"hasOverlay\"]],[30,0,[\"translucentOverlay\"]],[30,0,[\"clickOutsideToClose\"]],[30,0,[\"destinationElementId\"]],[30,0,[\"overlayPosition\"]],[30,0,[\"tetherTarget\"]],[30,0,[\"target\"]],[30,0,[\"attachment\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"targetModifier\"]],[30,0,[\"targetOffset\"]],[30,0,[\"offset\"]],[30,0,[\"tetherClassPrefix\"]],[30,0,[\"constraints\"]],[30,0,[\"attachmentClass\"]],[30,0,[\"targetAttachmentClass\"]],[28,[37,1],[[30,0],\"onClickOverlay\"],null],[28,[37,1],[[30,0],\"onClose\"],null]]],[[\"default\"],[[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"component\",\"action\",\"yield\"]]",
    "moduleName": "cc-frontend/components/x-modal-dialog.hbs",
    "isStrictMode": false
  });

  class XModalDialog extends _modalDialog.default {
    // acceptsKeyResponder: true,
    init() {
      super.init(...arguments);
      this.translucentOverlay = true;
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      Ember.$("body").addClass("modal-open");
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      Ember.$("body").removeClass("modal-open");
    } // cancel: function() {
    //   this.sendAction('close');
    // }


  }

  _exports.default = XModalDialog;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, XModalDialog);
});