define("cc-frontend/templates/print/course-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZkrePZca",
    "block": "[[[10,0],[14,0,\"ccp-page-header ccp-page-header--for-each-day\"],[12],[1,\"\\n  \"],[10,2],[14,0,\"ccp-page-header__title\"],[12],[1,\"\\n    \"],[1,[34,0]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,1],null,[[\"@unitId\",\"@course\",\"@planbook\",\"@rotationCalendar\"],[[36,2],[33,3,[\"content\"]],[33,4,[\"content\"]],[33,5,[\"content\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,6],null,[[\"@lesson\",\"@showStandardDescriptions\",\"@showComments\",\"@isFilteringCards\",\"@visibleCards\",\"@course\"],[[30,1,[\"lesson\"]],[36,7],[36,8],[36,9],[36,10],[36,3]]],null],[1,\"\\n\\n\"]],[1,2]]]]],[1,\"\\n\"],[41,[33,12],[[[1,\"  \"],[10,0],[14,0,\"ccp-print-outline page-break-before\"],[12],[1,\"\\n\"],[41,[33,5],[[[1,\"      \"],[8,[39,13],null,[[\"@course\",\"@planbook\",\"@rotationCalendar\",\"@endDate\",\"@isPrinting\",\"@startDate\"],[[33,3,[\"content\"]],[33,4,[\"content\"]],[33,5,[\"content\"]],[36,14],true,[36,15]]],null],[1,\"\\n\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"models\",\"state\"],false,[\"headerText\",\"container-unit\",\"unitId\",\"course\",\"planbook\",\"rotationCalendar\",\"print/lesson\",\"showStandardDescriptions\",\"showComments\",\"isFilteringCards\",\"visibleCards\",\"if\",\"shouldPrintOutline\",\"planner/course/outline-and-detail/outline\",\"endDate\",\"startDate\"]]",
    "moduleName": "cc-frontend/templates/print/course-unit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});