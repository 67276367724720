define("cc-frontend/templates/pricing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m/qm1MqN",
    "block": "[[[1,[28,[35,0],[\"Pricing | Planbook & Lesson Planner. Free for Teachers. Common Curriculum\"],[[\"replace\"],[true]]]],[1,\"\\n\"],[8,[39,1],null,[[\"@title\"],[\"marketing.pricing\"]],null],[1,\"\\n\"],[8,[39,2],null,null,null],[1,\"\\n\"],[8,[39,3],null,null,null],[1,\"\\n\"],[8,[39,4],null,null,null]],[],false,[\"page-title\",\"record-page-visit\",\"home-page/navbar\",\"home-page/pricing\",\"home-page/pricing-calculator\"]]",
    "moduleName": "cc-frontend/templates/pricing.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});