define("cc-frontend/mirage/config", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // import course_calendars from "../fixtures/api/course_calendars"
  // import courses from "../fixtures/api/courses"
  // import jurisdiction from "../fixtures/api/jurisdiction"
  // import jurisdictions from "../fixtures/api/jurisdictions"
  // import lessons from "../fixtures/api/lessons"
  // import planbook_calendars from "../fixtures/api/planbook_calendars"
  // import planbooks from "../fixtures/api/planbooks"
  // import routines from "../fixtures/api/routines"
  // import standard_sets from "../fixtures/api/standard_sets"
  // import standard_sets_local from "../fixtures/api/standard_sets_local"
  // import users from "../fixtures/api/users"
  // let fixtures = {
  //   course_calendars: course_calendars,
  //   courses: courses,
  //   jurisdiction: jurisdiction,
  //   jurisdictions: jurisdictions,
  //   lessons: lessons,
  //   planbook_calendars: planbook_calendars,
  //   planbooks: planbooks,
  //   routines: routines,
  //   standard_sets: standard_sets,
  //   standard_sets_local: standard_sets_local,
  //   users: users
  // }
  //
  function _default() {
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    this.urlPrefix = "http://localhost:4000"; // make this `http://localhost:8080`, for example, if your API is on a different server

    this.namespace = "api"; // make this `api`, for example, if your API is namespaced

    this.timing = 20; // delay for each request, automatically set to 0 during testing

    /*
      Route shorthand cheatsheet
    */

    /*
      GET shorthands
       // Collections
      this.get('/contacts');
      this.get('/contacts', 'users');
      this.get('/contacts', ['contacts', 'addresses']);
       // Single objects
      this.get('/contacts/:id');
      this.get('/contacts/:id', 'user');
      this.get('/contacts/:id', ['contact', 'addresses']);
    */

    let fetcher = function (model) {
      return function (_db, request) {
        let ids = request.params.id.split(",");
        let db = fixtures[model];
        return {
          data: _lodash.default.map(ids, id => _lodash.default.find(db, {
            id: id
          }))
        };
      };
    }.bind(this);

    this.get("/planbooks/:id", fetcher("planbooks"));
    this.get("/planbook_calendars/:id", fetcher("planbook_calendars"));
    this.get("/routines/:id", fetcher("routines"));
    this.get("/course_calendars/:id", fetcher("course_calendars"));
    this.get("/courses/:id", fetcher("courses"));
    this.get("/lessons/:id", fetcher("lessons"));
    this.get("/users/:id", fetcher("users"));
    this.get("/standard_sets/:id", fetcher("standard_sets_local"));
    this.get("api/csp/jurisdictions", function () {
      return jurisdictions;
    });
    this.get("/api/csp/jurisdictions/:id", function (db, request) {
      return _lodash.default.find(jurisdiction, {
        id: request.params.id
      });
    });
    this.get("/api/csp/standard_sets/:id", function (db, request) {
      return _lodash.default.find(standard_sets, {
        id: request.params.id
      });
    });
    /*
      POST shorthands
       this.post('/contacts');
      this.post('/contacts', 'user'); // specify the type of resource to be created
    */

    /*
      PUT shorthands
       this.put('/contacts/:id');
      this.put('/contacts/:id', 'user'); // specify the type of resource to be updated
    */

    /*
      DELETE shorthands
       this.del('/contacts/:id');
      this.del('/contacts/:id', 'user'); // specify the type of resource to be deleted
       // Single object + related resources. Make sure parent resource is first.
      this.del('/contacts/:id', ['contact', 'addresses']);
    */

    /*
      Function fallback. Manipulate data in the db via
         - db.{collection}
        - db.{collection}.find(id)
        - db.{collection}.where(query)
        - db.{collection}.update(target, attrs)
        - db.{collection}.remove(target)
       // Example: return a single object with related models
      this.get('/contacts/:id', function(db, request) {
        var contactId = +request.params.id;
         return {
          contact: db.contacts.find(contactId),
          addresses: db.addresses.where({contact_id: contactId})
        };
      });
     */

    this.passthrough();
  }
  /*
  You can optionally export a config that is only loaded during tests
  export function testConfig() {
  
  }
  */

});