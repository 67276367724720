define("cc-frontend/components/planner/card-stacks/cards/parts/actions.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "section-actions-group-wrapper": "_section-actions-group-wrapper_52q4rc",
    "slide-down": "_slide-down_52q4rc",
    "section-actions-group": "_section-actions-group_52q4rc",
    "btn-section-action": "_btn-section-action_52q4rc",
    "comment-box": "_comment-box_52q4rc",
    "add-comment-form-buttons": "_add-comment-form-buttons_52q4rc",
    "add-comment-form-buttons__submit": "_add-comment-form-buttons__submit_52q4rc",
    "add-comment-form-buttons__cancel": "_add-comment-form-buttons__cancel_52q4rc"
  };
  _exports.default = _default;
});