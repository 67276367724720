define("cc-frontend/components/icons/trash-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! Bin trash}}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ...attributes
  >
    <g>
      <path
        d="M6.8,24c-1.283,0 -2.352,-0.962 -2.486,-2.239l-1.764,-16.761h-2.05c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h6.5v-2.5c0,-0.827 0.673,-1.5 1.5,-1.5h7c0.827,0 1.5,0.673 1.5,1.5v2.5h6.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-2.05l-1.765,16.761c-0.134,1.276 -1.202,2.239 -2.485,2.239h-10.4Zm-1.492,-2.343c0.081,0.766 0.722,1.343 1.492,1.343h10.4c0.769,0 1.41,-0.578 1.491,-1.343l1.754,-16.657h-16.89l1.753,16.657Zm10.692,-17.657v-2.5c0,-0.276 -0.224,-0.5 -0.5,-0.5h-7c-0.276,0 -0.5,0.224 -0.5,0.5v2.5h8Z"
      ></path>
      <path
        d="M12,20c-0.276,0 -0.5,-0.224 -0.5,-0.5v-10.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v10.5c0,0.276 -0.224,0.5 -0.5,0.5Z"
      ></path>
      <path
        d="M15.973,19.999c-0.273,-0.013 -0.486,-0.248 -0.473,-0.523l0.5,-10.5c0.013,-0.267 0.237,-0.476 0.51,-0.476c0.147,0.007 0.27,0.065 0.36,0.163c0.09,0.098 0.136,0.227 0.13,0.361l-0.5,10.5c-0.013,0.267 -0.232,0.476 -0.5,0.476c-0.005,0 -0.022,0 -0.027,-0.001Z"
      ></path>
      <path
        d="M8,20c-0.267,0 -0.486,-0.209 -0.499,-0.476l-0.501,-10.5c-0.012,-0.276 0.201,-0.51 0.476,-0.524c0,0 0.001,0 0.001,0c0.289,0 0.509,0.209 0.523,0.476l0.5,10.5c0.013,0.276 -0.201,0.51 -0.476,0.523c-0.004,0.001 -0.02,0.001 -0.024,0.001Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "MuHbyW1w",
    "block": "[[[11,\"svg\"],[24,\"version\",\"1.1\"],[24,\"viewBox\",\"0 0 24 24\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[17,1],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M6.8,24c-1.283,0 -2.352,-0.962 -2.486,-2.239l-1.764,-16.761h-2.05c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h6.5v-2.5c0,-0.827 0.673,-1.5 1.5,-1.5h7c0.827,0 1.5,0.673 1.5,1.5v2.5h6.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-2.05l-1.765,16.761c-0.134,1.276 -1.202,2.239 -2.485,2.239h-10.4Zm-1.492,-2.343c0.081,0.766 0.722,1.343 1.492,1.343h10.4c0.769,0 1.41,-0.578 1.491,-1.343l1.754,-16.657h-16.89l1.753,16.657Zm10.692,-17.657v-2.5c0,-0.276 -0.224,-0.5 -0.5,-0.5h-7c-0.276,0 -0.5,0.224 -0.5,0.5v2.5h8Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,20c-0.276,0 -0.5,-0.224 -0.5,-0.5v-10.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v10.5c0,0.276 -0.224,0.5 -0.5,0.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M15.973,19.999c-0.273,-0.013 -0.486,-0.248 -0.473,-0.523l0.5,-10.5c0.013,-0.267 0.237,-0.476 0.51,-0.476c0.147,0.007 0.27,0.065 0.36,0.163c0.09,0.098 0.136,0.227 0.13,0.361l-0.5,10.5c-0.013,0.267 -0.232,0.476 -0.5,0.476c-0.005,0 -0.022,0 -0.027,-0.001Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M8,20c-0.267,0 -0.486,-0.209 -0.499,-0.476l-0.501,-10.5c-0.012,-0.276 0.201,-0.51 0.476,-0.524c0,0 0.001,0 0.001,0c0.289,0 0.509,0.209 0.523,0.476l0.5,10.5c0.013,0.276 -0.201,0.51 -0.476,0.523c-0.004,0.001 -0.02,0.001 -0.024,0.001Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/trash-can.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});