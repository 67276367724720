define("cc-frontend/components/admin/school-year/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.session.user.attributes.isSupport}}
    <div class="tw-container tw-mx-auto tw-flex tw-flex-col tw-h-screen tw-pt-8">
      <div class="">
        <h1 class="tw-text-4xl tw-font-extrabold tw-pl-4 tw-pb-8 tw-m-0">
          School Years
          <LinkTo @route="admin.school-years.new" class="admin-inline-button">
            Add School Year
          </LinkTo>
        </h1>
      </div>
      <div>
        <EmberTable class="group-members-table" as |t|>
          <t.head @widthConstraint="gte-container" @resizeMode="fluid" @columns={{this.columns}} />
          <t.body @rows={{@model}} @onSelect={{this.onRowClick}} @rowSelectionMode="single" />
        </EmberTable>
      </div>
    </div>
  {{else}}
    Must be logged into a support account
  {{/if}}
  */
  {
    "id": "9fYeOsAv",
    "block": "[[[41,[30,0,[\"session\",\"user\",\"attributes\",\"isSupport\"]],[[[1,\"  \"],[10,0],[14,0,\"tw-container tw-mx-auto tw-flex tw-flex-col tw-h-screen tw-pt-8\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"tw-text-4xl tw-font-extrabold tw-pl-4 tw-pb-8 tw-m-0\"],[12],[1,\"\\n        School Years\\n        \"],[8,[39,1],[[24,0,\"admin-inline-button\"]],[[\"@route\"],[\"admin.school-years.new\"]],[[\"default\"],[[[[1,\"\\n          Add School Year\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"group-members-table\"]],null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"head\"]],null,[[\"@widthConstraint\",\"@resizeMode\",\"@columns\"],[\"gte-container\",\"fluid\",[30,0,[\"columns\"]]]],null],[1,\"\\n        \"],[8,[30,1,[\"body\"]],null,[[\"@rows\",\"@onSelect\",\"@rowSelectionMode\"],[[30,2],[30,0,[\"onRowClick\"]],\"single\"]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  Must be logged into a support account\\n\"]],[]]]],[\"t\",\"@model\"],false,[\"if\",\"link-to\",\"ember-table\"]]",
    "moduleName": "cc-frontend/components/admin/school-year/index.hbs",
    "isStrictMode": false
  });

  let AdminSchoolYearIndex = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.oneWay("fetchRecords.isRunning"), _dec6 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), _dec7 = Ember._action, (_class = class AdminSchoolYearIndex extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "isLoading", _descriptor5, this);

      _defineProperty(this, "columns", [{
        name: "ID",
        valuePath: "id"
      }, {
        name: "Title",
        valuePath: "attributes.title"
      }, {
        name: "Long Abbreviation",
        valuePath: "attributes.longAbbreviation"
      }, {
        name: "Short Abbreviation",
        valuePath: "attributes.shortAbbreviation"
      }, {
        name: "Years",
        valuePath: "attributes.years"
      }]);

      _defineProperty(this, "model", []);

      _defineProperty(this, "enableSync", true);
    }

    didInsertElement() {
      Ember.get(this, "fetchRecords").perform();
    }

    *fetchRecords() {
      let records = yield this.rpc.execute("ADMIN_SCHOOL_YEAR_INDEX"); // let records = yield this.get('store').query('user', this.getProperties(['page', 'limit', 'sort', 'dir']));

      this.model.pushObjects(records.data);
    }

    onRowClick(row) {
      this.router.transitionTo("admin.school-years.show", Ember.get(row, "id"));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchRecords", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "fetchRecords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRowClick", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onRowClick"), _class.prototype)), _class));
  _exports.default = AdminSchoolYearIndex;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AdminSchoolYearIndex);
});