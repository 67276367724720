define("cc-frontend/utils/rotation-calendar/update-rotation-calendar-to-new-version", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateToNewVersion = updateToNewVersion;

  function updateToNewVersion(parentRc, childRc) {
    // Const is necesary so TS believes the check in the next line
    const datesLastChangedOn = parentRc.attributes.datesLastChangedOn;

    if (datesLastChangedOn === null) {
      throw new Error("Parent Rotation Calendar must have `datesLastChangedOn` set");
    }

    let oldDates = (0, _lodashEs.filter)(childRc.attributes.dates, date => {
      return date.attributes.dateString < datesLastChangedOn;
    });
    let newDates = (0, _lodashEs.map)((0, _lodashEs.filter)(parentRc.attributes.dates, date => {
      return date.attributes.dateString >= datesLastChangedOn;
    }), date => (0, _lodashEs.cloneDeep)(date)); // Returning this as fields so we can apply them in a patch or
    // set them on the rotationCalendar.attributes.

    return {
      dateAnnotations: (0, _lodashEs.cloneDeep)(parentRc.attributes.dateAnnotations),
      rotations: (0, _lodashEs.cloneDeep)(parentRc.attributes.rotations),
      schoolDays: (0, _lodashEs.cloneDeep)(parentRc.attributes.schoolDays),
      semesters: (0, _lodashEs.cloneDeep)(parentRc.attributes.semesters),
      dates: [...oldDates, ...newDates]
    };
  }
});