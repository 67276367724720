define("cc-frontend/templates/print/planbook-standards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "daWuc/ZZ",
    "block": "[[[10,0],[14,0,\"ccp-page-header ccp-page-header--standards-report\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ccp-page-header__standards-report-planbook-title\"],[12],[1,\"\\n    \"],[1,[33,0,[\"attributes\",\"fullName\"]]],[1,\"\\n    -\\n    \"],[1,[33,1,[\"planbook\",\"attributes\",\"title\"]]],[1,\"\\n    Planbook\\n  \"],[13],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"ccp-page-header__standards-report-header\"],[12],[1,\"\\n    Standards Coverage Report\\n  \"],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"ccp-page-header__standards-report-sub-header\"],[12],[1,\"\\n    Standards Covered in\\n    \"],[1,[28,[35,2],[[33,3]],null]],[1,\"\\n    as of\\n    \"],[1,[28,[35,4],[[33,5],\"ddd. MMM. Do\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"material-card-header -no-border\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[1,\"\\n    \"],[1,[33,6,[\"attributes\",\"jurisdiction\",\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[1,\"\\n    \"],[1,[33,6,[\"attributes\",\"title\"]]],[1,\"\\n    \"],[1,[33,6,[\"attributes\",\"subject\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,7],null,[[\"@hierarchicalStandardsReport\",\"@standards\",\"@planbookId\",\"@showStandardsNotCovered\"],[[36,8],[36,9],[33,1,[\"planbook\",\"id\"]],[36,10]]],null]],[],false,[\"owner\",\"model\",\"join-words\",\"courseTitles\",\"moment-format\",\"currentDate\",\"selectedStandardSet\",\"planner/standards-tracker/standard-usage\",\"hierarchicalStandardsReport\",\"standards\",\"showStandardsNotCoveredAsBool\"]]",
    "moduleName": "cc-frontend/templates/print/planbook-standards.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});