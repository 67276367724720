define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_DELETE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_STANDARDS_DELETE = void 0;
  const LESSON_STANDARDS_DELETE = {
    name: "LESSON_STANDARDS_DELETE",
    params: {
      cardStackId: "string",
      cardId: "string",
      standard: "object"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).pull("embedded", "attributes.standards", {
        id: payload.standard.id
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).addToSet("embedded", "attributes.standards", JSON.parse(JSON.stringify(payload.standard))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, findFn, models);
      let deletedStandard = payload.standard;
      let deletedStandardId = deletedStandard.id;
      let deletedStandardCode = deletedStandard.code;
      let deletedStandardJurisdiction = deletedStandard.jurisdiction;
      let deletedStandardGrades = deletedStandard.grades;
      let deletedStandardSubject = deletedStandard.subject;

      let newContext = _lodash.default.assign({}, context, {
        deletedStandardId,
        deletedStandardCode,
        deletedStandardJurisdiction,
        deletedStandardGrades,
        deletedStandardSubject
      });

      return {
        title: "Deleted a Standard in a Lesson",
        titleForAnalytics: "Standards - delete",
        description: `Removed a standard from "${context.cardStackTitle}"`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models)
      };
    }

  };
  _exports.LESSON_STANDARDS_DELETE = LESSON_STANDARDS_DELETE;
  var _default = LESSON_STANDARDS_DELETE;
  _exports.default = _default;
});