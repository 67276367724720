define("cc-frontend/components/planner/card-stacks/lesson-editable.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-outer-wrapper": "_lesson-outer-wrapper_e5uued",
    "lesson-outer": "_lesson-outer_e5uued",
    "--room-for-collapse-button": "_--room-for-collapse-button_e5uued",
    "lesson": "_lesson_e5uued",
    "lesson-drop-message": "_lesson-drop-message_e5uued",
    "lesson-body": "_lesson-body_e5uued",
    "lesson-date": "_lesson-date_e5uued",
    "date-is-off-but-lesson": "_date-is-off-but-lesson_e5uued",
    "date-is-off-but-lesson__title": "_date-is-off-but-lesson__title_e5uued",
    "date-is-off-but-lesson__description": "_date-is-off-but-lesson__description_e5uued",
    "course-title-for-annotations": "_course-title-for-annotations_e5uued",
    "expand-collapse-button-wrapper": "_expand-collapse-button-wrapper_e5uued",
    "--is-new-lesson": "_--is-new-lesson_e5uued",
    "expand-collapse-button": "_expand-collapse-button_e5uued"
  };
  _exports.default = _default;
});