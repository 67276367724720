define("cc-frontend/helpers/join-words", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.joinWords = joinWords;
  _exports.default = void 0;

  function joinWords(params
  /*, hash*/
  ) {
    var array = params[0] || [];

    var size = _lodash.default.size(array);

    var result = "";

    switch (size) {
      case 0:
        break;

      case 1:
        result = _lodash.default.first(array);
        break;

      case 2:
        result = _lodash.default.first(array) + " and " + _lodash.default.last(array);
        break;

      default:
        result = _lodash.default.reduce(array, (acc, el, index) => {
          if (index === 0) {
            return acc + el;
          } else if (index + 1 === size) {
            return acc + ", and " + el;
          } else {
            return acc + ", " + el;
          }
        }, "");
        break;
    }

    return result;
  }

  var _default = Ember.Helper.helper(joinWords);

  _exports.default = _default;
});