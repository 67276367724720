define("cc-frontend/components/planner/menus/titles/primary", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="primary-title" data-test-primary-menu-title>
    {{@title}}
    {{yield}}
  </div>
  */
  {
    "id": "IotrJdJs",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"primary-title\"],[[\"from\"],[\"cc-frontend/components/planner/menus/titles/primary.css\"]]]]]],[14,\"data-test-primary-menu-title\",\"\"],[12],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@title\",\"&default\"],false,[\"local-class\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/menus/titles/primary.hbs",
    "isStrictMode": false
  });

  let MenuPrimaryTitle = (_dec = (0, _component.tagName)(""), _dec(_class = class MenuPrimaryTitle extends Ember.Component {}) || _class);
  _exports.default = MenuPrimaryTitle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MenuPrimaryTitle);
});