define("cc-frontend/components/school/edit/payment/status", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="school-edit-payment__status-members__status">
    <div class="school-edit-payment__status-members__heading">
      Account Status
    </div>
    <div class="school-edit-payment__status-members__status__status-notice">
      <span class="school-edit-payment__status-members__status__status-notice__status">
        {{this.statusString}}
      </span>
      {{#unless (eq @stateName "group-closed")}}
        until
        {{moment-format @fiscalGroup.attributes.state.endsAt "MMM. Do YYYY"}}.
      {{/unless}}
    </div>
  </div>
  */
  {
    "id": "YY71Xu10",
    "block": "[[[10,0],[14,0,\"school-edit-payment__status-members__status\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"school-edit-payment__status-members__heading\"],[12],[1,\"\\n    Account Status\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"school-edit-payment__status-members__status__status-notice\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"school-edit-payment__status-members__status__status-notice__status\"],[12],[1,\"\\n      \"],[1,[30,0,[\"statusString\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[51,[28,[37,1],[[30,1],\"group-closed\"],null]],[[[1,\"      until\\n      \"],[1,[28,[35,2],[[30,2,[\"attributes\",\"state\",\"endsAt\"]],\"MMM. Do YYYY\"],null]],[1,\".\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@stateName\",\"@fiscalGroup\"],false,[\"unless\",\"eq\",\"moment-format\"]]",
    "moduleName": "cc-frontend/components/school/edit/payment/status.hbs",
    "isStrictMode": false
  });

  class SchoolEditPaymentStatus extends _glimmerComponent.default {
    get statusString() {
      return {
        trialing: "In a Trial",
        "trialing-warning": "In a Trial",
        "trial-expired": "Trial Expired Grace Period",
        "awaiting-purchase-order": "Awaiting Purchase Order",
        "awaiting-purchase-order-warning": "Awaiting Purchase Order",
        "awaiting-purchase-order-expired": "Expired Purchase Order Grace Period",
        "awaiting-payment": "Awaiting Payment",
        "awaiting-payment-warning": "Awaiting Payment",
        "awaiting-payment-expired": "Expired Awaiting Payment Grace Period",
        "paid-up": "Paid",
        "paid-up-warning": "Paid",
        "renewal-period": "Renewal Period",
        "renewal-period-warning": "Renewal Period",
        "renewal-period-expired": "Renewal Period Grace Period",
        "group-closed": "Group Closed"
      }[this.args.stateName];
    }

  }

  _exports.default = SchoolEditPaymentStatus;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SchoolEditPaymentStatus);
});