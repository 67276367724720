define("cc-frontend/models/planbook-calendar-date", ["exports", "cc-frontend/lib/structor", "cc-frontend/models/planbook-calendar-date-custom", "cc-frontend/models/planbook-calendar-date-default"], function (_exports, _structor, _planbookCalendarDateCustom, _planbookCalendarDateDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PlanbookCalendarDate extends _structor.default {
    defineStruct() {
      this.field("type");
      this.field("attributes", model => {
        if (model.type === "planbook-date-custom") return new _planbookCalendarDateCustom.default(model.attributes);
        if (model.type === "planbook-date-default") return new _planbookCalendarDateDefault.default(model.attributes);
      });
    }

  }

  _exports.default = PlanbookCalendarDate;
});