define("cc-frontend/components/school/setup-permissions-notice", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-text-3xl tw-text-center tw-mt-8 tw-px-12">
    {{#if this.session.userId}}
      You don't have the correct permissions.
    {{else}}
      You're not signed in.
      <a href="#" {{on "click" this.signInOrUp}}>
        Click here to sign in.
      </a>
    {{/if}}
  </div>
  */
  {
    "id": "SdBNRt70",
    "block": "[[[10,0],[14,0,\"tw-text-3xl tw-text-center tw-mt-8 tw-px-12\"],[12],[1,\"\\n\"],[41,[30,0,[\"session\",\"userId\"]],[[[1,\"    You don't have the correct permissions.\\n\"]],[]],[[[1,\"    You're not signed in.\\n    \"],[11,3],[24,6,\"#\"],[4,[38,1],[\"click\",[30,0,[\"signInOrUp\"]]],null],[12],[1,\"\\n      Click here to sign in.\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"if\",\"on\"]]",
    "moduleName": "cc-frontend/components/school/setup-permissions-notice.hbs",
    "isStrictMode": false
  });

  let SetupPermissionsNotice = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class SetupPermissionsNotice extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);
    }

    signInOrUp() {
      let path = this.router.currentURL;
      this.router.transitionTo("user.sign", {
        queryParams: {
          afterSignInRedirectTo: path
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "signInOrUp", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "signInOrUp"), _class.prototype)), _class));
  _exports.default = SetupPermissionsNotice;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SetupPermissionsNotice);
});