define("cc-frontend/templates/icons/-print-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4oD69bTt",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"baseProfile\",\"tiny\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"512px\"],[14,\"height\",\"512px\"],[14,\"viewBox\",\"-383 145 512 512\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M51.5,594.5h-354v-393h354V594.5z M-287.5,579.5h324v-363h-324V579.5z\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"-247\"],[14,\"y\",\"265.5\"],[14,\"width\",\"167\"],[14,\"height\",\"15\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"-247\"],[14,\"y\",\"321.5\"],[14,\"width\",\"211\"],[14,\"height\",\"15\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"-247\"],[14,\"y\",\"377.5\"],[14,\"width\",\"129.5\"],[14,\"height\",\"15\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"-247\"],[14,\"y\",\"433.5\"],[14,\"width\",\"175\"],[14,\"height\",\"15\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"-247\"],[14,\"y\",\"487.5\"],[14,\"width\",\"242\"],[14,\"height\",\"15\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-print-vertical.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});