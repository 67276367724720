define("cc-frontend/components/planner/menus/google-classroom/time-picker.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "time-select": "_time-select_yuqsfq",
    "time-select__set-time": "_time-select__set-time_yuqsfq"
  };
  _exports.default = _default;
});