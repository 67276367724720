define("cc-frontend/components/dialogs/alert-user-already-added-to-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-error" style="display: block;">
    <span class="x-mark">
      <span class="line left"></span>
      <span class="line right"></span>
    </span>
  </div>
  <h1 class="create-annotation__title">
    This person was already added to the group.
  </h1>
  {{! <h2 class="create-annotation__description">
    Deleting the planbook will remove all the courses and lessons.
  </h2> }}
  <div class="create-annotation__submit-buttons">
    <div class="create-annotation__submit-button" {{action @accept}}>
      Okay
    </div>
  </div>
  */
  {
    "id": "aGrBdhx6",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-error\"],[14,5,\"display: block;\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"x-mark\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"line left\"],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"line right\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  This person was already added to the group.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[[30,0],[30,1]],null],[12],[1,\"\\n    Okay\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\"],false,[\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/alert-user-already-added-to-group.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});