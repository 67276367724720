define("cc-frontend/components/planner/menus/titles/primary.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "primary-title": "_primary-title_1vgtv0"
  };
  _exports.default = _default;
});