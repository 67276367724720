define("cc-frontend/lib/actions/planbook/PLANBOOK_UNDELETE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/planbook/default-planbook-activity", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models"], function (_exports, _patchUpdater, _defaultPlanbookActivity, _defaultPlanbookContext, _defaultPlanbookModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_UNDELETE = void 0;
  const PLANBOOK_UNDELETE = {
    name: "PLANBOOK_UNDELETE",
    params: {
      planbookId: "string",
      timestamp: "string",
      userId: "string"
    },

    patches(payload) {
      let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).set("attributes.deletedAt", null).patch;

      let userPatch = _patchUpdater.default.create("user", payload.userId).push("relationships.planbooks.data", {
        id: payload.planbookId,
        type: "planbook"
      }).pull("relationships.deletedPlanbooks.data", {
        id: payload.planbookId,
        type: "planbook"
      }).push("attributes.planbookIds", payload.planbookId).pull("attributes.deletedPlanbookIds", payload.planbookId).patch;

      return [planbookPatch, userPatch];
    },

    undoPatches(payload) {
      let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).set("attributes.deletedAt", payload.timestamp).patch;

      let userPatch = _patchUpdater.default.create("user", payload.userId).pull("relationships.planbooks.data", {
        id: payload.planbookId,
        type: "planbook"
      }).push("relationships.deletedPlanbooks.data", {
        id: payload.planbookId,
        type: "planbook"
      }).pull("attributes.planbookIds", payload.planbookId).push("attributes.deletedPlanbookIds", payload.planbookId).patch;

      return [planbookPatch, userPatch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
      return {
        title: "Undeleted Planbook",
        titleForAnalytics: "Planbook - Undelete",
        description: `Undeleted ${models.planbook.attributes.title} planbook`,
        context: (0, _defaultPlanbookContext.default)(payload, models),
        activity: (0, _defaultPlanbookActivity.default)(payload, models)
      };
    }

  };
  _exports.PLANBOOK_UNDELETE = PLANBOOK_UNDELETE;
  var _default = PLANBOOK_UNDELETE;
  _exports.default = _default;
});