define("cc-frontend/lib/actions/lesson/GC_SETTINGS_UPDATE", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareGCSettingsUpdate;

  function prepareGCSettingsUpdate(payload) {
    return {
      name: "GC_SETTINGS_UPDATE",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  const patches = function (payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.googleCardId
    }).set("embedded", `attributes.classSettings`, payload.updatedClassSettings).inc("embedded", `attributes._revision`, 1).patch;

    return [patch];
  };

  const narrative = function (payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    return {
      title: `Changed GC Post ${payload.prop}`,
      titleForAnalytics: "Lesson Card - GC Post - Set Time",
      description: `Updated ${payload.prop} in GC class settings.`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models)
    };
  };
});