define("cc-frontend/components/icons/double-arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    {{! Navigation Arrows UP 1 }}
    <g>
      <path
        d="M19.501,15c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h2.474l-9.974,-12.691l-9.971,12.689h2.471c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-2.471c-0.223,0 -0.443,-0.076 -0.618,-0.214c-0.433,-0.34 -0.508,-0.97 -0.168,-1.403l9.971,-12.689c0.049,-0.062 0.105,-0.119 0.168,-0.168c0.175,-0.138 0.394,-0.214 0.617,-0.214c0.31,0 0.596,0.139 0.787,0.381l9.974,12.691c0.138,0.175 0.214,0.394 0.214,0.617c0,0.552 -0.448,1.001 -1,1.001h-2.474Z"
      ></path>
      <path
        d="M2.03,22.998c-0.223,0 -0.443,-0.076 -0.618,-0.214c-0.433,-0.34 -0.508,-0.97 -0.168,-1.403l9.971,-12.689c0.049,-0.062 0.105,-0.119 0.168,-0.168c0.175,-0.138 0.394,-0.214 0.617,-0.214c0.31,0 0.596,0.139 0.787,0.382l9.971,12.691c0.138,0.175 0.214,0.395 0.214,0.618c0,0.552 -0.448,1 -1,1l-19.942,-0.003Zm0,-1l19.942,0.002l-9.971,-12.69l-9.971,12.688Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "TUIMhCkI",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n\"],[1,\"  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M19.501,15c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h2.474l-9.974,-12.691l-9.971,12.689h2.471c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-2.471c-0.223,0 -0.443,-0.076 -0.618,-0.214c-0.433,-0.34 -0.508,-0.97 -0.168,-1.403l9.971,-12.689c0.049,-0.062 0.105,-0.119 0.168,-0.168c0.175,-0.138 0.394,-0.214 0.617,-0.214c0.31,0 0.596,0.139 0.787,0.381l9.974,12.691c0.138,0.175 0.214,0.394 0.214,0.617c0,0.552 -0.448,1.001 -1,1.001h-2.474Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M2.03,22.998c-0.223,0 -0.443,-0.076 -0.618,-0.214c-0.433,-0.34 -0.508,-0.97 -0.168,-1.403l9.971,-12.689c0.049,-0.062 0.105,-0.119 0.168,-0.168c0.175,-0.138 0.394,-0.214 0.617,-0.214c0.31,0 0.596,0.139 0.787,0.382l9.971,12.691c0.138,0.175 0.214,0.395 0.214,0.618c0,0.552 -0.448,1 -1,1l-19.942,-0.003Zm0,-1l19.942,0.002l-9.971,-12.69l-9.971,12.688Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/double-arrow-up.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});