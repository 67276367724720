define("cc-frontend/components/scroll-to", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "+Ms/HPvL",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/scroll-to.hbs",
    "isStrictMode": false
  });

  let ScrollTo = (_dec = (0, _object.on)("didReceiveAttrs"), (_class = class ScrollTo extends Ember.Component {
    onDidReceiveAttrs({
      oldAttrs,
      newAttrs
    }) {
      if (oldAttrs === undefined || oldAttrs.top.value !== newAttrs.top.value && newAttrs.top.value !== undefined) {// Ember.run.scheduleOnce('afterRender', function(){
        //   window.scrollTo(0, newAttrs.top.value)
        // })
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onDidReceiveAttrs", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onDidReceiveAttrs"), _class.prototype)), _class));
  _exports.default = ScrollTo;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ScrollTo);
});