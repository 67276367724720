define("cc-frontend/services/finder", ["exports", "lodash", "cc-frontend/config/environment"], function (_exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Finder = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class Finder extends Ember.Service {
    /**
     *  Runs immediately when the service is called. Sets params.
     *  Invokes _processRequests many times a second until finder service is torn down/destroyed
     */
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fastboot", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "inFlightRequests", []);

      _defineProperty(this, "requestedModels", []);

      _defineProperty(this, "_processRequestTimer", null);

      if (_environment.default.environment === "test") return;

      let interval = () => {
        this._processRequestTimer = Ember.run.later(() => {
          this._processRequests();

          interval();
        }, 15);
      };

      interval();
    }

    willDestroy() {
      if (this._processRequestTimer) Ember.run.cancel(this._processRequestTimer);
      super.willDestroy(...arguments);
    }
    /**
     * Queues/stores requested models from BE in an array that is being constantly checked.
     * Requests are made to BE based on the models in the array by _processRequests()
     *
     * @param  {String} modelName
     * @param  {String} id
     * @param  {Number} failureCount=0
     * @return {Promise}  Waiting for document to be returned/resolved from BE
     */


    find(modelName, id, failureCount = 0) {
      if (id === null || id === undefined) {
        return Ember.RSVP.resolve(null);
      }

      let promise = new Ember.RSVP.Promise((resolve, reject) => {
        let startTime = new Date();
        this.requestedModels.push({
          resolve,
          reject,
          modelName,
          id,
          startTime,
          failureCount
        });
      });

      if (Ember.get(this, "fastboot.isFastBoot")) {
        Ember.get(this, "fastboot").deferRendering(promise);
      }

      return promise;
    }

    _processRequests() {
      if (Ember.get(this, "isDestroying") || Ember.get(this, "isDestroyed")) return; // Grab the documents we're already requesting

      let inFlightRequestedDocuments = _lodash.default.chain(this.inFlightRequests).groupBy("modelName").reduce((acc, array, modelName) => {
        acc[modelName] = _lodash.default.map(array, "id");
        return acc;
      }, {}).value(); // Grab the models we'll be working with


      let requestedModels = Ember.get(this, "requestedModels"); // Push the requested models into the inFlight array

      this.inFlightRequests = _lodash.default.concat(Ember.get(this, "inFlightRequests"), requestedModels); // Empty the requestedModels

      Ember.set(this, "requestedModels", []); // Loop through the requested models and reject any that we're already inFlight

      _lodash.default.chain(requestedModels).groupBy("modelName").each((array, modelName) => {
        _lodash.default.chain(array).map("id").uniq().reject(id => _lodash.default.includes(inFlightRequestedDocuments[modelName], id)).compact().chunk(60).forEach(ids => {
          let adapter = Ember.getOwner(this).lookup(`adapter:${Ember.String.dasherize(modelName)}`);
          if (adapter === undefined) throw Error("Missing Adapter for " + modelName);
          adapter.find(ids).then(([status, response]) => {
            if (status === "ok") {
              return this.insertResponseDocs(response, modelName, ids);
            } else if (status === "error") {
              this._handlePromises("reject", modelName, ids, response);
            }
          }).catch(response => {
            console.log(response); // console.log('response', response.stack)
            // console.error("Ajax Error", arguments);

            this._handlePromises("reject", modelName, ids, response);
          });
        }).value();
      }).value();
    }
    /**
     * Inserts each returned document into the memoryEngine store. ModelName and id is sent to _handlePromise()
     * to check status of promise
     *
     * @param  {promise} response  Promise object from find() - see above
     * @param  {String} modelName
     * @param  {String} ids
     * @return {undefined}
     */


    insertResponseDocs(response, modelName, ids) {
      Ember.run.begin();
      let data = Ember.isArray(response.data) ? response.data : [response.data];
      data = _lodash.default.reject(data, datum => datum === null);
      if (!Ember.isNone(response.included)) data = data.concat(response.included);

      _lodash.default.forEach(data, datum => Ember.get(this, "store").insertDocument(datum));

      _lodash.default.forEach(ids, id => this._handlePromise(modelName, id));

      Ember.run.end();
    }
    /**
     * Filters through inFLightRequests to find matching model. Resolves pending promises and finds them in memoryEngine.
     * Puts models that don't match the requested name and id back in inFlightRequests array
     * inFlightRequests array to be tried again
     *
     * @param  {[type]} modelName [description]
     * @param  {[type]} id        [description]
     * @return {[type]}           [description]
     */


    _handlePromise(modelName, id) {
      if (Ember.get(this, "isDestroying") || Ember.get(this, "isDestroyed")) return;

      _lodash.default.chain(Ember.get(this, "inFlightRequests")).filter({
        modelName: modelName,
        id: id
      }).filter(req => req.resolve !== null && req.reject !== null).each(req => {
        // console.log("Request Time", new Date() - req.startTime)
        req.resolve(Ember.get(this, "store").findInMemory(req.modelName, req.id));
      }).value();

      let ifr = _lodash.default.reject(Ember.get(this, "inFlightRequests"), {
        modelName: modelName,
        id: id
      });

      Ember.set(this, "inFlightRequests", ifr);
    }

    _handlePromises(type, modelName, ids, response) {
      if (Ember.get(this, "isDestroying") || Ember.get(this, "isDestroyed")) return; // Process our requests

      (0, _lodash.default)(Ember.get(this, "inFlightRequests")).filter(req => req.modelName === modelName && _lodash.default.includes(ids, req.id)).filter(req => req.resolve !== null && req.reject !== null).each(req => {
        // console.log("Request Time", new Date() - req.startTime)
        if (type === "resolve") {
          req.resolve(Ember.get(this, "store").findInMemory(req.modelName, req.id));
        } else {
          let timeout = [1000, 2000, 5000, 10000, 20000, 30000][req.failureCount] || 60000;
          Ember.run.later(() => {
            req.resolve(this.find(req.modelName, req.id, req.failureCount + 1));
          }, timeout);
        }

        let ifr = _lodash.default.reject(Ember.get(this, "inFlightRequests"), {
          modelName: req.modelName,
          id: req.id
        });

        Ember.set(this, "inFlightRequests", ifr);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Finder;
});