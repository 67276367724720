define("cc-frontend/components/icons/lightning-bolt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! flash }}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M8,23.998c-0.072,0 -0.141,-0.015 -0.207,-0.045c-0.224,-0.102 -0.34,-0.349 -0.276,-0.586l2.828,-10.369h-3.345c-0.155,0 -0.299,-0.07 -0.394,-0.193c-0.095,-0.122 -0.128,-0.278 -0.091,-0.428l3,-12c0.056,-0.223 0.255,-0.379 0.485,-0.379h8c0.181,0 0.349,0.099 0.437,0.257c0.088,0.16 0.083,0.354 -0.014,0.508l-4.521,7.235h4.098c0.185,0 0.354,0.101 0.441,0.264c0.087,0.163 0.078,0.36 -0.025,0.514l-10,15c-0.093,0.139 -0.248,0.222 -0.416,0.222Zm3,-12c0.155,0 0.303,0.073 0.397,0.196c0.094,0.124 0.126,0.286 0.085,0.436l-2.189,8.028l7.773,-11.659h-4.066c-0.181,0 -0.349,-0.099 -0.437,-0.257c-0.088,-0.16 -0.083,-0.354 0.014,-0.508l4.521,-7.235h-6.708l-2.75,11h3.36Z"
    ></path>
  </svg>
  */
  {
    "id": "+wPtuQlo",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M8,23.998c-0.072,0 -0.141,-0.015 -0.207,-0.045c-0.224,-0.102 -0.34,-0.349 -0.276,-0.586l2.828,-10.369h-3.345c-0.155,0 -0.299,-0.07 -0.394,-0.193c-0.095,-0.122 -0.128,-0.278 -0.091,-0.428l3,-12c0.056,-0.223 0.255,-0.379 0.485,-0.379h8c0.181,0 0.349,0.099 0.437,0.257c0.088,0.16 0.083,0.354 -0.014,0.508l-4.521,7.235h4.098c0.185,0 0.354,0.101 0.441,0.264c0.087,0.163 0.078,0.36 -0.025,0.514l-10,15c-0.093,0.139 -0.248,0.222 -0.416,0.222Zm3,-12c0.155,0 0.303,0.073 0.397,0.196c0.094,0.124 0.126,0.286 0.085,0.436l-2.189,8.028l7.773,-11.659h-4.066c-0.181,0 -0.349,-0.099 -0.437,-0.257c-0.088,-0.16 -0.083,-0.354 0.014,-0.508l4.521,-7.235h-6.708l-2.75,11h3.36Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/lightning-bolt.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});