define("cc-frontend/components/group/members-table/headers/teams", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="group-members-table__header__header">
    Teams
  </div>
  <div class="group-members-table__header__subheader"></div>
  */
  {
    "id": "gc1P5H54",
    "block": "[[[10,0],[14,0,\"group-members-table__header__header\"],[12],[1,\"\\n  Teams\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"group-members-table__header__subheader\"],[12],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/group/members-table/headers/teams.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});