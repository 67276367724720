define("cc-frontend/components/planner/course/outline-and-detail.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "course-outline-with-detail": "_course-outline-with-detail_abx2l1",
    "course-outline-with-detail__row": "_course-outline-with-detail__row_abx2l1",
    "course-outline-with-detail__detail": "_course-outline-with-detail__detail_abx2l1",
    "single-lesson-wrapper": "_single-lesson-wrapper_abx2l1"
  };
  _exports.default = _default;
});