define("cc-frontend/components/user/create-school-form/pane-three.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "form-header": "_form-header_wyv13q",
    "form-pane": "_form-pane_wyv13q",
    "text-input-block": "_text-input-block_wyv13q",
    "text-input": "_text-input_wyv13q",
    "form-toggle": "_form-toggle_wyv13q",
    "payment-method": "_payment-method_wyv13q",
    "payment-method__label": "_payment-method__label_wyv13q",
    "payment-method__methods": "_payment-method__methods_wyv13q",
    "payment-method__method": "_payment-method__method_wyv13q",
    "--is-active": "_--is-active_wyv13q",
    "call-checkbox": "_call-checkbox_wyv13q",
    "footer": "_footer_wyv13q",
    "nav-button": "_nav-button_wyv13q",
    "no-school-name": "_no-school-name_wyv13q",
    "next": "_next_wyv13q",
    "back": "_back_wyv13q"
  };
  _exports.default = _default;
});