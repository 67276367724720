define("cc-frontend/components/user/create-school-form/pane-one.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "form-pane": "_form-pane_1khaay",
    "header": "_header_1khaay",
    "header-text-main": "_header-text-main_1khaay",
    "header-text-subheader": "_header-text-subheader_1khaay",
    "button-grouping": "_button-grouping_1khaay",
    "school-type-button": "_school-type-button_1khaay",
    "district": "_district_1khaay",
    "school": "_school_1khaay",
    "team": "_team_1khaay",
    "learn-more-section": "_learn-more-section_1khaay",
    "link": "_link_1khaay"
  };
  _exports.default = _default;
});