define("cc-frontend/components/icons/book-pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    <path
      d="M12.998,23.76c-0.189,0 -0.376,-0.036 -0.554,-0.107l-8.001,-3.2c-0.572,-0.229 -0.942,-0.775 -0.943,-1.391v-17.323c0,-0.481 0.234,-0.937 0.626,-1.218c0.01,-0.01 0.04,-0.031 0.072,-0.048c0.01,-0.008 0.041,-0.024 0.072,-0.041c0.002,-0.001 0.032,-0.017 0.033,-0.018c0.222,-0.117 0.455,-0.175 0.697,-0.175h14c0.827,0 1.5,0.673 1.5,1.5v17c0,0.827 -0.673,1.5 -1.5,1.5h-1.5v0.5c0,0.827 -0.673,1.5 -1.5,1.5h-1.5v0.023c0,0.401 -0.157,0.778 -0.441,1.061c-0.283,0.282 -0.659,0.437 -1.059,0.437h-0.002Zm-8.438,-22.259c-0.039,0.072 -0.06,0.153 -0.06,0.238v17.323c0,0.205 0.124,0.386 0.315,0.463l8,3.2c0.059,0.024 0.121,0.036 0.184,0.036c0.135,0 0.26,-0.052 0.354,-0.146c0.095,-0.094 0.147,-0.22 0.147,-0.353v-16.848c0,-0.205 -0.124,-0.387 -0.314,-0.464l-8.626,-3.449Zm11.44,19.738c0.276,0 0.5,-0.224 0.5,-0.5v-16.675c0,-0.239 -0.171,-0.446 -0.406,-0.491l-7.048,-1.355l4.511,1.803c0.572,0.229 0.943,0.776 0.943,1.393v15.825h1.5Zm3,-2c0.276,0 0.5,-0.224 0.5,-0.5v-17c0,-0.276 -0.224,-0.5 -0.5,-0.5h-9.75l7.032,1.352c0.706,0.135 1.218,0.754 1.218,1.473v15.175h1.5Z"
      fill="#000"
    ></path>
  </svg>
  */
  {
    "id": "l+MjG5gm",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M12.998,23.76c-0.189,0 -0.376,-0.036 -0.554,-0.107l-8.001,-3.2c-0.572,-0.229 -0.942,-0.775 -0.943,-1.391v-17.323c0,-0.481 0.234,-0.937 0.626,-1.218c0.01,-0.01 0.04,-0.031 0.072,-0.048c0.01,-0.008 0.041,-0.024 0.072,-0.041c0.002,-0.001 0.032,-0.017 0.033,-0.018c0.222,-0.117 0.455,-0.175 0.697,-0.175h14c0.827,0 1.5,0.673 1.5,1.5v17c0,0.827 -0.673,1.5 -1.5,1.5h-1.5v0.5c0,0.827 -0.673,1.5 -1.5,1.5h-1.5v0.023c0,0.401 -0.157,0.778 -0.441,1.061c-0.283,0.282 -0.659,0.437 -1.059,0.437h-0.002Zm-8.438,-22.259c-0.039,0.072 -0.06,0.153 -0.06,0.238v17.323c0,0.205 0.124,0.386 0.315,0.463l8,3.2c0.059,0.024 0.121,0.036 0.184,0.036c0.135,0 0.26,-0.052 0.354,-0.146c0.095,-0.094 0.147,-0.22 0.147,-0.353v-16.848c0,-0.205 -0.124,-0.387 -0.314,-0.464l-8.626,-3.449Zm11.44,19.738c0.276,0 0.5,-0.224 0.5,-0.5v-16.675c0,-0.239 -0.171,-0.446 -0.406,-0.491l-7.048,-1.355l4.511,1.803c0.572,0.229 0.943,0.776 0.943,1.393v15.825h1.5Zm3,-2c0.276,0 0.5,-0.224 0.5,-0.5v-17c0,-0.276 -0.224,-0.5 -0.5,-0.5h-9.75l7.032,1.352c0.706,0.135 1.218,0.754 1.218,1.473v15.175h1.5Z\"],[14,\"fill\",\"#000\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/book-pages.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});