define("cc-frontend/lib/assign-new-card-ids", ["exports", "lodash", "cc-frontend/lib/object-id-gen"], function (_exports, _lodash, _objectIdGen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(cardStackAttributes) {
    if (_lodash.default.isNil(cardStackAttributes)) return;

    let attrs = _lodash.default.cloneDeep(cardStackAttributes);

    let newCards = _lodash.default.map(attrs.cards, (card, _index) => {
      return Object.assign({}, card, {
        id: _objectIdGen.default.create()
      });
    });

    attrs.cards = newCards;
    return attrs;
  }
});