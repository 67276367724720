define("cc-frontend/components/icons/remove-no-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M16.241,16.743c-0.134,0 -0.259,-0.052 -0.354,-0.146l-3.889,-3.889l-3.889,3.889c-0.094,0.094 -0.22,0.146 -0.354,0.146c-0.134,0 -0.259,-0.052 -0.354,-0.146c-0.195,-0.195 -0.195,-0.512 0,-0.707l3.891,-3.89l-3.89,-3.888c-0.195,-0.195 -0.195,-0.512 0,-0.707c0.094,-0.094 0.22,-0.146 0.354,-0.146v0c0.134,0 0.259,0.052 0.354,0.146l3.889,3.889l3.889,-3.889c0.094,-0.094 0.22,-0.146 0.354,-0.146c0.134,0 0.258,0.051 0.352,0.146c0.195,0.195 0.195,0.512 0,0.707l-3.888,3.888l3.889,3.889c0.195,0.195 0.195,0.512 0,0.707c-0.095,0.095 -0.22,0.147 -0.354,0.147Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "CUBwLDQ6",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M16.241,16.743c-0.134,0 -0.259,-0.052 -0.354,-0.146l-3.889,-3.889l-3.889,3.889c-0.094,0.094 -0.22,0.146 -0.354,0.146c-0.134,0 -0.259,-0.052 -0.354,-0.146c-0.195,-0.195 -0.195,-0.512 0,-0.707l3.891,-3.89l-3.89,-3.888c-0.195,-0.195 -0.195,-0.512 0,-0.707c0.094,-0.094 0.22,-0.146 0.354,-0.146v0c0.134,0 0.259,0.052 0.354,0.146l3.889,3.889l3.889,-3.889c0.094,-0.094 0.22,-0.146 0.354,-0.146c0.134,0 0.258,0.051 0.352,0.146c0.195,0.195 0.195,0.512 0,0.707l-3.888,3.888l3.889,3.889c0.195,0.195 0.195,0.512 0,0.707c-0.095,0.095 -0.22,0.147 -0.354,0.147Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/remove-no-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});