define("cc-frontend/instance-initializers/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let session = appInstance.lookup("service:session");
    let socket = appInstance.lookup("service:socket");
    session.initiate();
    socket.initiate();
    socket.connect();
    let token = Ember.get(session, "token");
    let finder = appInstance.lookup("service:finder");
    let fastboot = appInstance.lookup("service:fastboot");

    if (Ember.get(fastboot, "isFastBoot") !== true) {
      // This is a messy and it knows more about the internals than I'd like.
      // I'm not sure the better way to do this at the moment, but if I have to employ this
      // pattern again, I should figure it out.
      // The session.get('user') triggers the observer on the session which identifies the
      // user in the analytics
      if (token) {
        socket.authenticateWithToken(token).then(result => finder.insertResponseDocs(result)).then(() => session.sendUserToAnalytics()).catch(error => console.error(error));
      }
    }
  }

  var _default = {
    name: "session",
    initialize: initialize,
    before: ["app-state", "store", "socket", "ajax"]
  };
  _exports.default = _default;
});