define("cc-frontend/components/icons/ellipses-in-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! Navigation Menu Horizontal 1 }}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M7,13.75c-0.965,0 -1.75,-0.785 -1.75,-1.75c0,-0.965 0.785,-1.75 1.75,-1.75c0.965,0 1.75,0.785 1.75,1.75c0,0.965 -0.785,1.75 -1.75,1.75Zm0,-2.5c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75Z"
      ></path>
      <path
        d="M12,13.75c-0.965,0 -1.75,-0.785 -1.75,-1.75c0,-0.965 0.785,-1.75 1.75,-1.75c0.965,0 1.75,0.785 1.75,1.75c0,0.965 -0.785,1.75 -1.75,1.75Zm0,-2.5c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75Z"
      ></path>
      <path
        d="M17,13.75c-0.965,0 -1.75,-0.785 -1.75,-1.75c0,-0.965 0.785,-1.75 1.75,-1.75c0.965,0 1.75,0.785 1.75,1.75c0,0.965 -0.785,1.75 -1.75,1.75Zm0,-2.5c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75Z"
      ></path>
      <path
        d="M12,24c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,0 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "Dw9QrOvN",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M7,13.75c-0.965,0 -1.75,-0.785 -1.75,-1.75c0,-0.965 0.785,-1.75 1.75,-1.75c0.965,0 1.75,0.785 1.75,1.75c0,0.965 -0.785,1.75 -1.75,1.75Zm0,-2.5c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,13.75c-0.965,0 -1.75,-0.785 -1.75,-1.75c0,-0.965 0.785,-1.75 1.75,-1.75c0.965,0 1.75,0.785 1.75,1.75c0,0.965 -0.785,1.75 -1.75,1.75Zm0,-2.5c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M17,13.75c-0.965,0 -1.75,-0.785 -1.75,-1.75c0,-0.965 0.785,-1.75 1.75,-1.75c0.965,0 1.75,0.785 1.75,1.75c0,0.965 -0.785,1.75 -1.75,1.75Zm0,-2.5c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,24c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,0 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/ellipses-in-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});