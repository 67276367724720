define("cc-frontend/styles/planbookv5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "planbook-wrapper": "_planbook-wrapper_16gec9",
    "dialog-is-open": "_dialog-is-open_16gec9",
    "planbook-wrapper__no-access": "_planbook-wrapper__no-access_16gec9",
    "planbook-wrapper__no-access-h1": "_planbook-wrapper__no-access-h1_16gec9",
    "planbook-wrapper__no-access-h2": "_planbook-wrapper__no-access-h2_16gec9",
    "planbook__no-permission-to-edit-class-websites": "_planbook__no-permission-to-edit-class-websites_16gec9",
    "planbook-header-background": "_planbook-header-background_16gec9"
  };
  _exports.default = _default;
});