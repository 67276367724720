define("cc-frontend/components/planner/card-stacks/cards/parts/html-value.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "value": "_value_r5c7im",
    "--read-only": "_--read-only_r5c7im"
  };
  _exports.default = _default;
});