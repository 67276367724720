define("cc-frontend/components/school/reviews/manager.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "manager": "_manager_1w26sn",
    "back": "_back_1w26sn",
    "back-btn": "_back-btn_1w26sn",
    "list": "_list_1w26sn",
    "detail": "_detail_1w26sn"
  };
  _exports.default = _default;
});