define("cc-frontend/components/user/create-school-form.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_mk0x40",
    "modal-inner": "_modal-inner_mk0x40",
    "modal-inner__close": "_modal-inner__close_mk0x40"
  };
  _exports.default = _default;
});