define("cc-frontend/components/default-navbar", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <nav class="planbook-top-nav__wrapper js-nav-bar no-print" role="navigation">
    <div class="planbook-top-nav__home-nav">
      <div class="planbook-top-nav__home-nav-logo">
        {{#if this.session.userId}}
          <LinkTo @route="user.show" @model={{this.session.userId}}>
            {{partial "icons/ios-home-outline"}}
          </LinkTo>
        {{/if}}
      </div>
    </div>
    <div class="planbook-top-nav__help-nav">
      <span class="btn planbook-top-nav__btn planbook-top-nav__btn-md">
        <HelpMenuButton />
      </span>
      {{#if this.session.isAuthenticated}}
        <LinkTo
          @route="user.sign-out"
          class="btn planbook-top-nav__btn planbook-top-nav__btn--sync-button planbook-top-nav__btn-md hint--bottom"
          aria-label="Sign Out"
        >
          <div class="sync-button__icon">
            {{partial "icons/nucleo-power-outline"}}
          </div>
        </LinkTo>
      {{else}}
        <div
          class="btn btn-default btn-sign-in btn-sign-in__planbook-top-nav hint--bottom"
          aria-label="Sign In"
          {{on "click" this.signInOrUp}}
        >
          Sign In or Up
        </div>
      {{/if}}
    </div>
  </nav>
  */
  {
    "id": "nSb3Keh9",
    "block": "[[[10,\"nav\"],[14,0,\"planbook-top-nav__wrapper js-nav-bar no-print\"],[14,\"role\",\"navigation\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"planbook-top-nav__home-nav\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"planbook-top-nav__home-nav-logo\"],[12],[1,\"\\n\"],[41,[30,0,[\"session\",\"userId\"]],[[[1,\"        \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"user.show\",[30,0,[\"session\",\"userId\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[19,\"icons/ios-home-outline\",[]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"planbook-top-nav__help-nav\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"btn planbook-top-nav__btn planbook-top-nav__btn-md\"],[12],[1,\"\\n      \"],[8,[39,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"session\",\"isAuthenticated\"]],[[[1,\"      \"],[8,[39,1],[[24,0,\"btn planbook-top-nav__btn planbook-top-nav__btn--sync-button planbook-top-nav__btn-md hint--bottom\"],[24,\"aria-label\",\"Sign Out\"]],[[\"@route\"],[\"user.sign-out\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"sync-button__icon\"],[12],[1,\"\\n          \"],[19,\"icons/nucleo-power-outline\",[]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,0],[24,0,\"btn btn-default btn-sign-in btn-sign-in__planbook-top-nav hint--bottom\"],[24,\"aria-label\",\"Sign In\"],[4,[38,4],[\"click\",[30,0,[\"signInOrUp\"]]],null],[12],[1,\"\\n        Sign In or Up\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],true,[\"if\",\"link-to\",\"partial\",\"help-menu-button\",\"on\"]]",
    "moduleName": "cc-frontend/components/default-navbar.hbs",
    "isStrictMode": false
  });

  let DefaultNavbar = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class DefaultNavbar extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);
    }

    signInOrUp() {
      let path = this.router.currentURL;
      this.router.transitionTo("user.sign", {
        queryParams: {
          afterSignInRedirectTo: path
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "signInOrUp", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "signInOrUp"), _class.prototype)), _class));
  _exports.default = DefaultNavbar;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DefaultNavbar);
});