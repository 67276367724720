define("cc-frontend/lib/actions/lesson/default-lesson-models", ["exports", "lodash", "cc-frontend/lib/unit-utils"], function (_exports, _lodash, _unitUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(payload, findFn, userId) {
    let cardStack = findFn("card-stack", payload.cardStackId) || findFn("card-stack-summary", payload.cardStackId);

    let cardStackType = _lodash.default.get(cardStack, "attributes.usedAs");

    let course;
    let courseDate;

    if (!_lodash.default.isNil(_lodash.default.get(cardStack, "relationships.course.data.id"))) {
      course = findFn("course", _lodash.default.get(cardStack, "relationships.course.data.id"));

      if (course) {
        courseDate = (0, _unitUtils.getCourseDateForCardStackId)(course.attributes.calendar.dates, payload.cardStackId);
      }
    }

    let planbookId = payload.planbookId || _lodash.default.get(payload, "planbook.id") || _lodash.default.get(cardStack, "relationships.planbook.data.id");

    let planbook = findFn("planbook", planbookId);
    let unit = cardStackType === "unit" ? _lodash.default.find(course.attributes.calendar.units, {
      id: cardStack.id
    }) : null;
    let user = findFn("user", userId);
    return {
      cardStack,
      cardStackType,
      course,
      courseDate,
      planbook,
      unit,
      user
    };
  }
});