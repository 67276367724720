define("cc-frontend/components/dialogs/alert-must-sign-in-to-proceed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-info" style="display: block;">
    i
  </div>
  <h1 class="create-annotation__title">
    You need to sign in first!
  </h1>
  <h2 class="create-annotation__description">
    Click "Sign In or Up" in the upper right hand corner to sign in or create an account.
  </h2>
  <div class="create-annotation__submit-buttons">
    <div class="create-annotation__submit-button" {{action @accept}}>
      Okay
    </div>
  </div>
  */
  {
    "id": "00r7zCXo",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-info\"],[14,5,\"display: block;\"],[12],[1,\"\\n  i\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  You need to sign in first!\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description\"],[12],[1,\"\\n  Click \\\"Sign In or Up\\\" in the upper right hand corner to sign in or create an account.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[[30,0],[30,1]],null],[12],[1,\"\\n    Okay\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\"],false,[\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/alert-must-sign-in-to-proceed.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});