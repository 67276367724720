define("cc-frontend/routes/application", ["exports", "cc-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, (_class = class ApplicationRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        token: {
          replace: true
        }
      });

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "headData", _descriptor3, this);

      _initializerDefineProperty(this, "finder", _descriptor4, this);

      _initializerDefineProperty(this, "query", _descriptor5, this);

      _initializerDefineProperty(this, "fastboot", _descriptor6, this);

      _initializerDefineProperty(this, "sessionStore", _descriptor7, this);
    }

    init() {
      super.init(...arguments);
      this.token = null;

      if (window && typeof window.addEventListener === "function") {
        window.addEventListener("storage", e => {
          if (e.key === "signOutNow") {
            this.transitionTo("user.sign-out");
          }
        });
      }

      if (Ember.get(this, "fastboot") && Ember.get(this, "fastboot.isFastBoot")) {
        let fullHostname = _environment.default.API_HOSTNAME_FOR_FASTBOOT;
        fullHostname = fullHostname.replace("https://", "");
        Ember.set(this, "fastboot.request._host", fullHostname);
        Ember.set(this, "fastboot.request.host", fullHostname); // Just to trigger it

        Ember.get(this, "fastboot.request.host");
      }

      if (Ember.get(this, "fastboot") && !Ember.get(this, "fastboot.isFastBoot")) {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        if (vw < 649) {
          document.body.className += " less-than-649px";
        }
      }
    }

    model(params, transition) {
      Ember.set(this, "headData.robots", "all");

      if (Ember.get(this, "fastboot") && Ember.get(this, "fastboot.isFastBoot") === false) {}

      if (Ember.get(this, "fastboot") && Ember.get(this, "fastboot.isFastBoot")) {
        Ember.set(this, "headData.isPausingForFastboot", true);
        let promise = new Ember.RSVP.Promise((resolve, reject) => {
          let poll = () => {
            Ember.run.later(() => {
              if (Ember.get(this, "finder.inFlightRequests.length") === 0 && Ember.get(this, "query.inFlightQueriesCount") === 0) {
                // Wait until the last possible moment ot render the title
                // We do it here because if it renders more than once, it doens't render at all. (no idea why)
                Ember.set(this, "headData.isPausingForFastboot", false);
                Ember.run.scheduleOnce("afterRender", this, resolve);
                return;
              } else {
                poll();
              }
            }, 50);
          };

          poll();
        });
        Ember.get(this, "fastboot").deferRendering(promise);
      }

      let queryParams = transition.to.queryParams || {};

      if (!Ember.isNone(queryParams.token)) {
        Ember.run.later(() => {
          return this.transitionTo({
            queryParams: {
              token: null
            }
          });
        }, 1000);
      }

      if (queryParams.promotionCode) {
        Ember.get(this, "sessionStore").persistObject("promotionCode", {
          promotionCode: queryParams.promotionCode
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "finder", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sessionStore", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationRoute;
});