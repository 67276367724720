define("cc-frontend/components/planner/planbook/create-edit/onboarding/after-first-temp.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "notice": "_notice_5vy0lw",
    "icon-side": "_icon-side_5vy0lw",
    "icon": "_icon_5vy0lw",
    "text-side": "_text-side_5vy0lw",
    "header": "_header_5vy0lw",
    "bullet": "_bullet_5vy0lw",
    "emp": "_emp_5vy0lw"
  };
  _exports.default = _default;
});