define("cc-frontend/services/import-lessons-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ImportLessonsModal extends Ember.Service.extend(Ember.Evented) {
    openModal(dateString, course) {
      Ember.set(this, "isOpen", true);
      Ember.set(this, "dateString", dateString);
      Ember.set(this, "course", course);
      this.trigger("openModal", dateString, course);
    }

  }

  _exports.default = ImportLessonsModal;
});