define("cc-frontend/components/planner/menus/day-menu/primary/body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "routine-not-updating-from-template-notice": "_routine-not-updating-from-template-notice_ju57qk",
    "rotation-exception": "_rotation-exception_ju57qk",
    "rotation-exception__icon": "_rotation-exception__icon_ju57qk",
    "routine-not-updating-from-template-notice__header": "_routine-not-updating-from-template-notice__header_ju57qk",
    "rotation-exception__header": "_rotation-exception__header_ju57qk",
    "rotation-exception__text": "_rotation-exception__text_ju57qk",
    "routine-not-updating-from-template-notice__subheader": "_routine-not-updating-from-template-notice__subheader_ju57qk",
    "rotation-exception__subheader": "_rotation-exception__subheader_ju57qk",
    "routine-not-updating-from-template-notice__action-link": "_routine-not-updating-from-template-notice__action-link_ju57qk",
    "rotation-exception__subheader__link": "_rotation-exception__subheader__link_ju57qk"
  };
  _exports.default = _default;
});