define("cc-frontend/models/date-annotation-single-date", ["exports", "cc-frontend/lib/structor"], function (_exports, _structor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateAnnotationSingleDate extends _structor.default {
    defineStruct() {
      this.field("title");
      this.field("description");
      this.field("dateString");
      this.field("isOff");
    }

  }

  _exports.default = DateAnnotationSingleDate;
});