define("cc-frontend/components/planner/menus/titles/blank", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div></div>
  */
  {
    "id": "ZluEXh3N",
    "block": "[[[10,0],[12],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/planner/menus/titles/blank.hbs",
    "isStrictMode": false
  });

  let DayMenuTitleBlank = (_dec = (0, _component.tagName)(""), _dec(_class = class DayMenuTitleBlank extends Ember.Component {}) || _class);
  _exports.default = DayMenuTitleBlank;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DayMenuTitleBlank);
});