define("cc-frontend/components/planner/course/outline-and-detail/search", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="card-stack-search-bar-wrapper">
    <input
      value={{this._query}}
      type="text"
      placeholder="Search for..."
      class="card-stack-search-bar card-stack-search-bar--in-outline"
      {{on "focus" this.onSearchFocus}}
      {{on "input" this.search value="target.value"}}
    />
  
    {{#if @isSearching}}
      <div class="card-stack-search-bar-wrapper__spinner">
        <DS::ThreeDotSpinner />
      </div>
    {{/if}}
    {{#if @hasQuery}}
      <div class="card-stack-search-bar-wrapper__clear-query" {{on "click" this.clearSearchQuery}}>
        <Icons::IosCloseEmpty />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "aScAlPbf",
    "block": "[[[10,0],[14,0,\"card-stack-search-bar-wrapper\"],[12],[1,\"\\n  \"],[11,\"input\"],[16,2,[30,0,[\"_query\"]]],[24,\"placeholder\",\"Search for...\"],[24,0,\"card-stack-search-bar card-stack-search-bar--in-outline\"],[24,4,\"text\"],[4,[38,0],[\"focus\",[30,0,[\"onSearchFocus\"]]],null],[4,[38,0],[\"input\",[30,0,[\"search\"]]],[[\"value\"],[\"target.value\"]]],[12],[13],[1,\"\\n\\n\"],[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"card-stack-search-bar-wrapper__spinner\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,2],[[[1,\"    \"],[11,0],[24,0,\"card-stack-search-bar-wrapper__clear-query\"],[4,[38,0],[\"click\",[30,0,[\"clearSearchQuery\"]]],null],[12],[1,\"\\n      \"],[8,[39,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@isSearching\",\"@hasQuery\"],false,[\"on\",\"if\",\"d-s/three-dot-spinner\",\"icons/ios-close-empty\"]]",
    "moduleName": "cc-frontend/components/planner/course/outline-and-detail/search.hbs",
    "isStrictMode": false
  });

  let Outline = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class Outline extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _defineProperty(this, "onNewFocusedItemId", void 0);

      _defineProperty(this, "focusedItemId", void 0);

      _defineProperty(this, "course", void 0);

      _defineProperty(this, "rotationCalendar", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "startDate", void 0);

      _defineProperty(this, "endDate", void 0);

      _defineProperty(this, "startedSearch", void 0);

      _defineProperty(this, "finishedSearch", void 0);

      _defineProperty(this, "onClearSearchQuery", void 0);

      _defineProperty(this, "isSearching", false);

      _defineProperty(this, "hasQuery", false);

      _defineProperty(this, "results", []);

      _defineProperty(this, "selectedResultId", null);

      _defineProperty(this, "query", void 0);

      _defineProperty(this, "_query", void 0);

      _initializerDefineProperty(this, "hitSearchBackend", _descriptor3, this);
    }

    search(event) {
      let query = event.target.value;
      if (this._query === null) this.set("_query", query); // @ts-ignore

      this.hitSearchBackend.perform(query);
    }

    clearSearchQuery() {
      this.set("_query", null);
      this.onClearSearchQuery();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hitSearchBackend", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (query) {
        this.set("query", query);
        this.startedSearch(query);

        if (query === "" || null) {
          this.finishedSearch(null);
        } else {
          yield this.rpc.execute("COURSE_SEARCH", query, this.course.id).then(data => {
            console.log("data", data, query);
            this.finishedSearch(data.data);
          });
        }

        yield (0, _emberConcurrency.timeout)(30);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSearchQuery", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearchQuery"), _class.prototype)), _class));
  _exports.default = Outline;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Outline);
});