define("cc-frontend/components/slideout-wrapper", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/easings/cosine", "ember-animated/motions/opacity"], function (_exports, _glimmerComponent, _cosine, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div style="{{html-safe (concat "z-index: " (mult 1001 @order))}}">
    <div
      class="slideout-wrapper slideout-wrapper--order-{{@order}}
        {{if @isSidebar "slideout-wrapper--fixed-sidebar"}}
        {{if @isHalfScreen "slideout-wrapper--half-screen"}}"
      style="{{html-safe (concat "z-index: " (mult 1001 @order))}}"
      {{did-insert this.insertElementCallback}}
    >
      {{#animated-if this.elementInserted use=this.transition}}
        {{yield}}
      {{/animated-if}}
    </div>
    {{#unless @noBackground}}
      <div
        class="slideout-background"
        style="{{html-safe (concat "z-index: " (mult 1000 @order))}}"
        {{on "click" @closeModal}}
      ></div>
    {{/unless}}
  </div>
  */
  {
    "id": "GoH/q5FX",
    "block": "[[[10,0],[15,5,[28,[37,0],[[28,[37,1],[\"z-index: \",[28,[37,2],[1001,[30,1]],null]],null]],null]],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"slideout-wrapper slideout-wrapper--order-\",[30,1],\"\\n      \",[52,[30,2],\"slideout-wrapper--fixed-sidebar\"],\"\\n      \",[52,[30,3],\"slideout-wrapper--half-screen\"]]]],[16,5,[28,[37,0],[[28,[37,1],[\"z-index: \",[28,[37,2],[1001,[30,1]],null]],null]],null]],[4,[38,4],[[30,0,[\"insertElementCallback\"]]],null],[12],[1,\"\\n\"],[6,[39,5],[[30,0,[\"elementInserted\"]]],[[\"use\"],[[30,0,[\"transition\"]]]],[[\"default\"],[[[[1,\"      \"],[18,6,null],[1,\"\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[51,[30,4]],[[[1,\"    \"],[11,0],[24,0,\"slideout-background\"],[16,5,[28,[37,0],[[28,[37,1],[\"z-index: \",[28,[37,2],[1000,[30,1]],null]],null]],null]],[4,[38,8],[\"click\",[30,5]],null],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"@order\",\"@isSidebar\",\"@isHalfScreen\",\"@noBackground\",\"@closeModal\",\"&default\"],false,[\"html-safe\",\"concat\",\"mult\",\"if\",\"did-insert\",\"animated-if\",\"yield\",\"unless\",\"on\"]]",
    "moduleName": "cc-frontend/components/slideout-wrapper.hbs",
    "isStrictMode": false
  });

  let SlideoutWrapper = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class SlideoutWrapper extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "elementInserted", _descriptor, this);
    }

    insertElementCallback() {
      Ember.run.later(() => {
        this.elementInserted = true;
      }, 250);
    }

    *transition({
      insertedSprites,
      keptSprites,
      removedSprites
    }) {
      insertedSprites.forEach(sprite => {
        sprite.startAtPixel({
          x: window.innerWidth
        });
        (0, _opacity.fadeIn)(sprite, {
          easing: _cosine.easeOut,
          duration: 150
        });
      });
      keptSprites.forEach(_opacity.fadeIn);
      removedSprites.forEach(sprite => {
        (0, _opacity.fadeOut)(sprite, {
          easing: _cosine.easeIn,
          duration: 150
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "elementInserted", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "insertElementCallback", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "insertElementCallback"), _class.prototype)), _class));
  _exports.default = SlideoutWrapper;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SlideoutWrapper);
});