define("cc-frontend/components/dialogs/confirm-pull-backwards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-warning pulse-warning" style="display: block;">
    !
  </div>
  {{! <h1 class="create-annotation__title">How thorougly would you like to delete this lesson?</h1> }}
  <h1 class="create-annotation__title">
    To pull future lessons back, we need to delete this date's lessons in
    {{@options.courseTitles}}
    .
  </h1>
  <h2 class="create-annotation__description">
    Are you sure you want to delete these lessons?
  </h2>
  <div class="create-annotation__confirm-cancel-buttons">
    <div
      class="btn btn-link create-annotation__confirm-cancel__cancel-button"
      {{on "click" @decline}}
    >
      Cancel
    </div>
    <div
      class="create-annotation__confirm-cancel__confirm-button"
      {{on "click" (fn @accept @shouldRemoveHistory)}}
    >
      Yes, Delete them
    </div>
  </div>
  {{!-- <div class="create-annotation__cancel-button-wrapper">
    <div {{action "decline"}} class="btn btn-link create-annotation__cancel-button">Cancel</div>
  </div> --}}
  */
  {
    "id": "voJcP1u/",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-warning pulse-warning\"],[14,5,\"display: block;\"],[12],[1,\"\\n  !\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  To pull future lessons back, we need to delete this date's lessons in\\n  \"],[1,[30,1,[\"courseTitles\"]]],[1,\"\\n  .\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description\"],[12],[1,\"\\n  Are you sure you want to delete these lessons?\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__confirm-cancel-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"btn btn-link create-annotation__confirm-cancel__cancel-button\"],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__confirm-cancel__confirm-button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,3],[30,4]],null]],null],[12],[1,\"\\n    Yes, Delete them\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@options\",\"@decline\",\"@accept\",\"@shouldRemoveHistory\"],false,[\"on\",\"fn\"]]",
    "moduleName": "cc-frontend/components/dialogs/confirm-pull-backwards.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});