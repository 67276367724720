define("cc-frontend/helpers/belongs-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = belongsTo;

  /**
   * A macro that creates a computed property that watches for changes in the relationship
   * property of modelName and returns the specified, related object by extracting the id
   * and type of model in the JSON:API object specified in the modelName field,
   * queries the store, and returns a promise proxy that resolves to the related model.
  
   * @param  {String} modelName - name of the model whose relationship property being looked at by the CP mentioned above
   * @param  {String} relationship - name of document/model expected in the relationship property of the modelName
   * @return {PromiseProxyObject} - this CP returns a proxy whose value can be accessed via the content property
   */
  function belongsTo(modelName, relationship) {
    var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
    /**
     * Example
       ``` javascript
        class exampleComponent extends Component {
          planbook = { id: 1, type: "planbook", relationships: { rotationCalendar: { data: { type: "rotation-calendar"; id: 2 } } } }
          @belongsTo("planbook", "rotationCalendar") rotationCalendar
        }
         when promise is fulfilled will return rotationCalendar = {id: 2, type: "rotation-calendar", relationships: {planbook: { id: 1} } }
      ```
      */

    return Ember.computed(`${modelName}.relationships.${relationship}.data.id`, function () {
      let model = Ember.get(this, "modelName");
      let relation = Ember.get(this, `${modelName}.relationships.${relationship}`) || {};
      let type = Ember.get(relation, "data.type");
      let id = Ember.get(relation, "data.id"); // let promise = (
      //   isNone(get(this, `${modelName}.relationships.${relationship}.data.id`))
      //     ? null
      //     : get(this, 'store').find(type, id)

      let promise = type === undefined ? new Ember.RSVP.Promise(() => {}) : Ember.get(this, "store").find(type, id);
      return ObjectPromiseProxy.create({
        promise: promise
      });
    });
  } // export default Ember.Helper.helper(belongsTo);

});