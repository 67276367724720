define("cc-frontend/templates/icons/-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z9Bm/SwP",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M160 160h192c-1.7-20-9.7-35.2-27.9-40.1-.4-.1-.9-.3-1.3-.4-12-3.4-20.8-7.5-20.8-20.7V78.2c0-25.5-20.5-46.3-46-46.3s-46 20.7-46 46.3v20.6c0 13.1-8.8 17.2-20.8 20.6-.4.1-.9.4-1.4.5-18.2 4.9-25.9 20.1-27.8 40.1zm96-95.6c7.6 0 13.8 6.2 13.8 13.8 0 7.7-6.2 13.8-13.8 13.8-7.6 0-13.8-6.2-13.8-13.8 0-7.6 6.2-13.8 13.8-13.8zM404.6 63H331v14.5c0 10.6 8.7 18.5 19 18.5h37.2c6.7 0 12.1 5.7 12.4 12.5l.1 327.2c-.3 6.4-5.3 11.6-11.5 12.1l-264.4.1c-6.2-.5-11.1-5.7-11.5-12.1l-.1-327.3c.3-6.8 5.9-12.5 12.5-12.5H162c10.3 0 19-7.9 19-18.5V63h-73.6C92.3 63 80 76.1 80 91.6V452c0 15.5 12.3 28 27.4 28h297.2c15.1 0 27.4-12.5 27.4-28V91.6c0-15.5-12.3-28.6-27.4-28.6zM144 192h112v16H144zm0 96h160v16H144zm0 96h129v16H144zm0-48h176v16H144zm0-96h208v16H144z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-clipboard.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});