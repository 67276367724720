define("cc-frontend/templates/admin/fiscal-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JDUugxbd",
    "block": "[[[8,[39,0],null,[[\"@excludedStates\",\"@includedStates\"],[[30,0,[\"excludedStates\"]],[30,0,[\"includedStates\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"fiscal-groups-admin\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/admin/fiscal-groups.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});