define("cc-frontend/templates/icons/-ios-lock-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aUqtWpLJ",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M360 224v-72c0-57.43-46.562-104-103.984-104C198.562 48 152 94.57 152 152v72H96v240h320V224h-56zm-192-72c0-48.523 39.484-88 88.016-88C304.531 64 344 103.477 344 152v72H168v-72zm232 296H112V240h288v208z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M256 288c-17.673 0-32 14.327-32 32 0 14.91 10.198 27.439 24 30.992V384h16v-33.008c13.802-3.553 24-16.082 24-30.992 0-17.673-14.327-32-32-32zm0 48c-8.822 0-16-7.178-16-16s7.178-16 16-16 16 7.178 16 16-7.178 16-16 16z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ios-lock-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});