define("cc-frontend/helpers/embedded-belongs-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = embeddedBelongsTo;

  function embeddedBelongsTo(path, relationshipType) {
    var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
    return Ember.computed(path, function () {
      let id = Ember.get(this, path);
      if (Ember.isNone(id)) return;

      if (this[path + relationshipType] && this[path + relationshipType].value === Ember.get(this, path) + relationshipType) {
        return this[path + relationshipType].proxy;
      }

      this[path + relationshipType] = {
        value: Ember.get(this, path) + relationshipType,
        proxy: ObjectPromiseProxy.create({
          promise: Ember.get(this, "store").find(relationshipType, id)
        })
      };
      return this[path + relationshipType].proxy;
    });
  }
});