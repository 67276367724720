define("cc-frontend/components/school-network/edit-members", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/utils/group", "remeda", "cc-frontend/components/group/members-table"], function (_exports, _glimmerComponent, _group, R, _membersTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="slideout__body__heading tw-mt-8">
    <h2 class="slideout__body__heading__title tw-text-link-blue">
      Admins
    </h2>
    <button
      class="slideout__body__heading__add tw-ml-4 slideout__body__heading__add--subtler"
      type="button"
      {{on "click" (fn this.addMember @group)}}
    >
      <Icons::IosPlus />
      Add Admin
    </button>
  </div>
  <h3 class="school-setup__h3">
    Can create/delete schools, share lesson templates all teachers, and manage payment.
  </h3>
  <Group::MembersTable
    @group={{@group}}
    @columns={{this.membersTableColumns}}
    @parentGroup={{null}}
    @memberships={{this.adminMemberships}}
  />
  */
  {
    "id": "xNl8iYey",
    "block": "[[[10,0],[14,0,\"slideout__body__heading tw-mt-8\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"slideout__body__heading__title tw-text-link-blue\"],[12],[1,\"\\n    Admins\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"slideout__body__heading__add tw-ml-4 slideout__body__heading__add--subtler\"],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"addMember\"]],[30,1]],null]],null],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n    Add Admin\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h3\"],[14,0,\"school-setup__h3\"],[12],[1,\"\\n  Can create/delete schools, share lesson templates all teachers, and manage payment.\\n\"],[13],[1,\"\\n\"],[8,[39,3],null,[[\"@group\",\"@columns\",\"@parentGroup\",\"@memberships\"],[[30,1],[30,0,[\"membersTableColumns\"]],null,[30,0,[\"adminMemberships\"]]]],null]],[\"@group\"],false,[\"on\",\"fn\",\"icons/ios-plus\",\"group/members-table\"]]",
    "moduleName": "cc-frontend/components/school-network/edit-members.hbs",
    "isStrictMode": false
  });

  let SchoolNetworkEditMembers = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class SchoolNetworkEditMembers extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modalManager", _descriptor, this);

      _defineProperty(this, "membersTableColumns", [_membersTable.AVATAR_COLUMN, _membersTable.NAME_COLUMN, _membersTable.UPGRADED_ADMIN_COLUMN, _membersTable.ACTIONS_ALL_COLUMN]);
    }

    get adminMemberships() {
      return R.pipe(Ember.get(this.args.group.attributes, "memberships"), R.filter(_group.membersWithAdminRole), R.filter(_group.isGroupMembershipIndividual));
    }

    addMember(group) {
      this.modalManager.openModal("group/add-members", {
        group: group,
        showOnlyAdmins: true,
        makeAdmin: true,
        parentGroup: null
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addMember", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addMember"), _class.prototype)), _class));
  _exports.default = SchoolNetworkEditMembers;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SchoolNetworkEditMembers);
});