define("cc-frontend/components/planner/modals/print-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <XModalDialog translucentOverlay="true" overlayClass="overlayClasses">
    <Planner::Modals::InteriorPrintModal
      @perspective={{@model.layout}}
      @startDate={{@model.startDate}}
      @endDate={{@model.endDate}}
      @medium={{@medium}}
      @currentUserId={{@model.currentUserId}}
      @courseIds={{@model.courseIds}}
      @unitId={{@model.unitId}}
      @courseId={{@model.courseId}}
      @planbookId={{@model.planbookId}}
      @dateRangeString={{@model.dateRangeString}}
      @isFilteringClasses={{@model.isFilteringClasses}}
      @classesLinedUp={{@model.classesLinedUp}}
      @canPrint={{@model.canPrint}}
      @closeModal={{@closeModal}}
    />
  </XModalDialog>
  */
  {
    "id": "9z7YL/1B",
    "block": "[[[8,[39,0],[[24,\"translucentOverlay\",\"true\"],[24,\"overlayClass\",\"overlayClasses\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@perspective\",\"@startDate\",\"@endDate\",\"@medium\",\"@currentUserId\",\"@courseIds\",\"@unitId\",\"@courseId\",\"@planbookId\",\"@dateRangeString\",\"@isFilteringClasses\",\"@classesLinedUp\",\"@canPrint\",\"@closeModal\"],[[30,1,[\"layout\"]],[30,1,[\"startDate\"]],[30,1,[\"endDate\"]],[30,2],[30,1,[\"currentUserId\"]],[30,1,[\"courseIds\"]],[30,1,[\"unitId\"]],[30,1,[\"courseId\"]],[30,1,[\"planbookId\"]],[30,1,[\"dateRangeString\"]],[30,1,[\"isFilteringClasses\"]],[30,1,[\"classesLinedUp\"]],[30,1,[\"canPrint\"]],[30,3]]],null],[1,\"\\n\"]],[]]]]]],[\"@model\",\"@medium\",\"@closeModal\"],false,[\"x-modal-dialog\",\"planner/modals/interior-print-modal\"]]",
    "moduleName": "cc-frontend/components/planner/modals/print-download.hbs",
    "isStrictMode": false
  });

  let PrintDownload = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class PrintDownload extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modalManager", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PrintDownload;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PrintDownload);
});