define("cc-frontend/components/icons/search-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M23.5,24c-0.134,0 -0.259,-0.052 -0.354,-0.146l-9.004,-9.004c-1.56,1.388 -3.554,2.15 -5.642,2.15c-4.687,0 -8.5,-3.813 -8.5,-8.5c0,-4.687 3.813,-8.5 8.5,-8.5c4.687,0 8.5,3.813 8.5,8.5c0,2.088 -0.762,4.082 -2.151,5.642l9.004,9.004c0.095,0.095 0.147,0.22 0.147,0.354c0,0.134 -0.052,0.259 -0.146,0.354c-0.094,0.095 -0.22,0.146 -0.354,0.146Zm-15,-23c-4.135,0 -7.5,3.365 -7.5,7.5c0,4.135 3.365,7.5 7.5,7.5c4.135,0 7.5,-3.365 7.5,-7.5c0,-4.135 -3.365,-7.5 -7.5,-7.5Z"
    ></path>
  </svg>
  */
  {
    "id": "xJHuazb1",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M23.5,24c-0.134,0 -0.259,-0.052 -0.354,-0.146l-9.004,-9.004c-1.56,1.388 -3.554,2.15 -5.642,2.15c-4.687,0 -8.5,-3.813 -8.5,-8.5c0,-4.687 3.813,-8.5 8.5,-8.5c4.687,0 8.5,3.813 8.5,8.5c0,2.088 -0.762,4.082 -2.151,5.642l9.004,9.004c0.095,0.095 0.147,0.22 0.147,0.354c0,0.134 -0.052,0.259 -0.146,0.354c-0.094,0.095 -0.22,0.146 -0.354,0.146Zm-15,-23c-4.135,0 -7.5,3.365 -7.5,7.5c0,4.135 3.365,7.5 7.5,7.5c4.135,0 7.5,-3.365 7.5,-7.5c0,-4.135 -3.365,-7.5 -7.5,-7.5Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/search-simple.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});