define("cc-frontend/components/user/home/school", ["exports", "@ember-decorators/component", "cc-frontend/helpers/find-many", "lodash"], function (_exports, _component, _findMany, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserHomeSchool = (_dec = (0, _component.classNames)("user-home__body__schools__school"), _dec2 = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
  
    <div class="user-home__body__schools__school__header">
      <div class="user-home__body__schools__school__header__title">
        {{group.attributes.title}}
      </div>
      <LinkTo @route="new-group.show" @model={{group.id}} class="user-home__body__schools__school__header__go-to-group test-go-to-school-link">
        Go to School
      </LinkTo>
    </div>
  
    {{#if teams}}
      <div class="user-home__body__schools__school__body">
        {{#each teams.content as |team|}}
          <Group::Team @parentGroup={{group}} @groupId={{team.id}} @currentSchoolYearId={{selectedSchoolYearId}} @class="user-home__body__schools__school__body__team" />
          {{!-- {{team.attributes.title}} --}}
        {{/each}}
  
      </div>
    {{/if}}
  
    {{yield}}
  
  */
  {
    "id": "41yo9S4H",
    "block": "[[[1,\"\\n\\n  \"],[10,0],[14,0,\"user-home__body__schools__school__header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"user-home__body__schools__school__header__title\"],[12],[1,\"\\n      \"],[1,[33,0,[\"attributes\",\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],[[24,0,\"user-home__body__schools__school__header__go-to-group test-go-to-school-link\"]],[[\"@route\",\"@model\"],[\"new-group.show\",[33,0,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      Go to School\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[33,3],[[[1,\"    \"],[10,0],[14,0,\"user-home__body__schools__school__body\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,3,[\"content\"]]],null]],null],null,[[[1,\"        \"],[8,[39,6],null,[[\"@parentGroup\",\"@groupId\",\"@currentSchoolYearId\",\"@class\"],[[36,0],[30,1,[\"id\"]],[36,7],\"user-home__body__schools__school__body__team\"]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[\"team\",\"&default\"],false,[\"group\",\"link-to\",\"if\",\"teams\",\"each\",\"-track-array\",\"group/team\",\"selectedSchoolYearId\",\"yield\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec3 = Ember.inject.service, _dec4 = Ember.computed("user.attributes.cachedGroupMemberships.length", "group.attributes.memberships.length"), _dec5 = (0, _findMany.default)("userTeamIds", "group"), _dec(_class = _dec2(_class = (_class2 = class UserHomeSchool extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "teams", _descriptor2, this);
    }

    get userTeamIds() {
      let userCachedGroupMemberships = Ember.get(this, "user.attributes.cachedGroupMemberships");

      let userGroupIds = _lodash.default.map(userCachedGroupMemberships, membership => membership.attributes.groupId);

      let teams = _lodash.default.filter(Ember.get(this, "group.attributes.memberships"), membership => membership.type === "group-membership-group");

      return _lodash.default.chain(teams).filter(team => _lodash.default.includes(userGroupIds, team.attributes.groupId)).map(team => team.attributes.groupId).value();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "userTeamIds", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "userTeamIds"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "teams", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = UserHomeSchool;
});