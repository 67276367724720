define("cc-frontend/components/icons/pull-backwards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! Diagram Arrow Bend Down Reversed }}
  <svg
    width="23"
    height="24"
    version="1.1"
    viewBox="0 0 23 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g transform="scale(0.80)">
      <path
        d="M12.63,7.11l-2.18557e-08,-8.88178e-16c-0.276142,-1.20706e-08 -0.5,-0.223858 -0.5,-0.5c0,0 0,0 0,0l5.32907e-15,7.54979e-08c-4.16963e-08,-0.276142 0.223858,-0.5 0.5,-0.5h10.5l-2.18557e-08,8.88178e-16c0.276142,-1.20706e-08 0.5,0.223858 0.5,0.5v0c0,0.276142 -0.223858,0.5 -0.5,0.5Z"
        transform="matrix(1,0,0,1,-0.37,-0.11)"
      ></path>
      <path
        d="M7.13,23.89l1.56044e-08,-2.0804e-10c-0.235891,0.003145 -0.464342,-0.0825238 -0.64,-0.24l-7.09736e-09,-5.71108e-09c-0.0257834,-0.0207473 -0.0492527,-0.0442166 -0.07,-0.07l-5.82,-6.26l6.42442e-08,5.67708e-08c-0.226257,-0.199937 -0.298816,-0.522421 -0.18,-0.8l5.10779e-09,-1.10905e-08c0.125875,-0.273311 0.410364,-0.437593 0.71,-0.41h2.5v-10.5c0,-3.5 2,-5.5 5.41,-5.5h14.09h-2.18557e-08c0.276142,-1.20706e-08 0.5,0.223858 0.5,0.5c1.20706e-08,0.276142 -0.223858,0.5 -0.5,0.5h-5c-3.71,0 -7.5,1.07 -7.5,9v6h2.5l2.35903e-08,-1.93975e-09c0.298968,-0.0245832 0.581852,0.138772 0.71,0.41l-4.18265e-09,-1.0916e-08c0.106739,0.278571 0.0357694,0.593993 -0.18,0.8l-5.82,6.26l3.48058e-08,-3.9349e-08c-0.167911,0.189828 -0.406693,0.301983 -0.66,0.31Zm5.35,-6.78h-2.35h-2.18557e-08c-0.276142,-1.20706e-08 -0.5,-0.223858 -0.5,-0.5c0,0 0,0 0,0v-6.5c0,-3.3 0.46,-7.2 3.84,-9h-4.47c-2.8,0 -4.41,1.64 -4.41,4.5v11v0c0,0.276142 -0.223858,0.5 -0.5,0.5h-2.31l5.35,5.76Z"
        transform="matrix(1,0,0,1,-0.37,-0.11)"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "cx+s2J0c",
    "block": "[[[10,\"svg\"],[14,\"width\",\"23\"],[14,\"height\",\"24\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 23 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"transform\",\"scale(0.80)\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12.63,7.11l-2.18557e-08,-8.88178e-16c-0.276142,-1.20706e-08 -0.5,-0.223858 -0.5,-0.5c0,0 0,0 0,0l5.32907e-15,7.54979e-08c-4.16963e-08,-0.276142 0.223858,-0.5 0.5,-0.5h10.5l-2.18557e-08,8.88178e-16c0.276142,-1.20706e-08 0.5,0.223858 0.5,0.5v0c0,0.276142 -0.223858,0.5 -0.5,0.5Z\"],[14,\"transform\",\"matrix(1,0,0,1,-0.37,-0.11)\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M7.13,23.89l1.56044e-08,-2.0804e-10c-0.235891,0.003145 -0.464342,-0.0825238 -0.64,-0.24l-7.09736e-09,-5.71108e-09c-0.0257834,-0.0207473 -0.0492527,-0.0442166 -0.07,-0.07l-5.82,-6.26l6.42442e-08,5.67708e-08c-0.226257,-0.199937 -0.298816,-0.522421 -0.18,-0.8l5.10779e-09,-1.10905e-08c0.125875,-0.273311 0.410364,-0.437593 0.71,-0.41h2.5v-10.5c0,-3.5 2,-5.5 5.41,-5.5h14.09h-2.18557e-08c0.276142,-1.20706e-08 0.5,0.223858 0.5,0.5c1.20706e-08,0.276142 -0.223858,0.5 -0.5,0.5h-5c-3.71,0 -7.5,1.07 -7.5,9v6h2.5l2.35903e-08,-1.93975e-09c0.298968,-0.0245832 0.581852,0.138772 0.71,0.41l-4.18265e-09,-1.0916e-08c0.106739,0.278571 0.0357694,0.593993 -0.18,0.8l-5.82,6.26l3.48058e-08,-3.9349e-08c-0.167911,0.189828 -0.406693,0.301983 -0.66,0.31Zm5.35,-6.78h-2.35h-2.18557e-08c-0.276142,-1.20706e-08 -0.5,-0.223858 -0.5,-0.5c0,0 0,0 0,0v-6.5c0,-3.3 0.46,-7.2 3.84,-9h-4.47c-2.8,0 -4.41,1.64 -4.41,4.5v11v0c0,0.276142 -0.223858,0.5 -0.5,0.5h-2.31l5.35,5.76Z\"],[14,\"transform\",\"matrix(1,0,0,1,-0.37,-0.11)\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/pull-backwards.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});