define("cc-frontend/components/planner/menus/unit-menu/primary/title", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Menus::Titles::Primary @title="Unit Menu" />
  */
  {
    "id": "h8jYN7Bv",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Unit Menu\"]],null]],[],false,[\"planner/menus/titles/primary\"]]",
    "moduleName": "cc-frontend/components/planner/menus/unit-menu/primary/title.hbs",
    "isStrictMode": false
  });

  class UnitMenuSecondaryTitle extends _component.default {} // @ts-ignore


  _exports.default = UnitMenuSecondaryTitle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UnitMenuSecondaryTitle);
});