define("cc-frontend/lib/dates-off-calculator", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findPlanbookDatesOff = findPlanbookDatesOff;
  _exports.findCourseDatesOff = findCourseDatesOff;

  function findPlanbookDatesOff(newAnnotations) {
    let defaultDatesOff = _lodash.default.chain(newAnnotations).filter(annotation => annotation.type === "date-annotation-planbook-default").filter(annotation => annotation.attributes.isOff === true).map(annotation => `${annotation.attributes.semesterId}:${annotation.attributes.rotationId}`).value();

    let customRangesOff = _lodash.default.chain(newAnnotations).filter(annotation => annotation.type === "date-annotation-planbook-custom-range").filter(annotation => annotation.attributes.isOff === true).flatMap(annotation => dateFns.eachDay(annotation.attributes.startDate, annotation.attributes.endDate)).map(dateObject => dateFns.format(dateObject, "YYYY-MM-DD")).value();

    let customDatesOff = _lodash.default.chain(newAnnotations).filter(annotation => annotation.type === "date-annotation-planbook-custom-single").filter(annotation => annotation.attributes.isOff === true).map(annotation => annotation.attributes.dateString).value();

    return _lodash.default.sortBy(_lodash.default.uniq(defaultDatesOff.concat(customRangesOff).concat(customDatesOff)));
  }

  function findCourseDatesOff(newAnnotations) {
    let defaultDatesOff = _lodash.default.chain(newAnnotations).filter(annotation => annotation.type === "date-annotation-course-default").filter(annotation => annotation.attributes.isOff === true).map(annotation => `${annotation.attributes.semesterId}:${annotation.attributes.rotationId}`).value();

    let customDatesOff = _lodash.default.chain(newAnnotations).filter(annotation => annotation.type === "date-annotation-course-custom-single").filter(annotation => annotation.attributes.isOff === true).map(annotation => annotation.attributes.dateString).value();

    return _lodash.default.sortBy(_lodash.default.uniq(defaultDatesOff.concat(customDatesOff)));
  }
});