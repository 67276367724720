define("cc-frontend/components/admin/group/table/group-cell-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{on "click" (fn this.deleteGroup @value) bubbles=false}}>
    Delete
  </div>
  */
  {
    "id": "N1POG+Li",
    "block": "[[[11,0],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"deleteGroup\"]],[30,1]],null]],[[\"bubbles\"],[false]]],[12],[1,\"\\n  Delete\\n\"],[13]],[\"@value\"],false,[\"on\",\"fn\"]]",
    "moduleName": "cc-frontend/components/admin/group/table/group-cell-actions.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});