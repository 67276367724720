define("cc-frontend/components/planner/card-stacks/unit/color-selector", ["exports", "@ember-decorators/component", "cc-frontend/lib/actions/course/calendar/CHANGE_UNIT_COLOR"], function (_exports, _component, _CHANGE_UNIT_COLOR) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UnitColorSelector = (_dec = (0, _component.tagName)(""), _dec2 = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    <div id="color-picker-{{unit.id}}" {{action "openMenu"}} class="unit-popover__color-picker-anchor take-unit-background-color take-unit-border-color {{unit.attributes.color}}">
      {{#if @isEditable}}
        <Icons::ArrowDown />
      {{/if}}
    </div>
    {{#if this.state.colorMenuIsOpen}}
      <LiquidWormhole @class="dialog-backdrop" @click={{action "closeMenu"}} />
      <LiquidTether @target={{concat "#color-picker-" unit.id}} @offset="0 0px" @targetAttachment="bottom center" @attachment="top center" @class="undo-menu">
        <div class="course-color-selection-list material-depth-1 bopit-allow-click">
          {{#each courseColors as |color|}}
            <div class="take-course-background-color course-color-selection-list-item {{color}} test-change-course-color"
                  {{action "setUnitColor" unit.id color courseId unit.attributes.color}}></div>
          {{/each}}
          <div class="course-color-selection-list-item course-color-selection-list-item--full-width" {{action "setUnitColor" unit.id null courseId unit.attributes.color}}></div>
        </div>
      </LiquidTether>
    {{/if}}
  
  
  */
  {
    "id": "oJcY0Pvp",
    "block": "[[[1,\"\\n  \"],[11,0],[16,1,[29,[\"color-picker-\",[33,0,[\"id\"]]]]],[16,0,[29,[\"unit-popover__color-picker-anchor take-unit-background-color take-unit-border-color \",[33,0,[\"attributes\",\"color\"]]]]],[4,[38,1],[[30,0],\"openMenu\"],null],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"state\",\"colorMenuIsOpen\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@class\",\"@click\"],[\"dialog-backdrop\",[28,[37,1],[[30,0],\"closeMenu\"],null]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@target\",\"@offset\",\"@targetAttachment\",\"@attachment\",\"@class\"],[[28,[37,6],[\"#color-picker-\",[33,0,[\"id\"]]],null],\"0 0px\",\"bottom center\",\"top center\",\"undo-menu\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"course-color-selection-list material-depth-1 bopit-allow-click\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[33,9]],null]],null],null,[[[1,\"          \"],[11,0],[16,0,[29,[\"take-course-background-color course-color-selection-list-item \",[30,2],\" test-change-course-color\"]]],[4,[38,1],[[30,0],\"setUnitColor\",[33,0,[\"id\"]],[30,2],[33,10],[33,0,[\"attributes\",\"color\"]]],null],[12],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[11,0],[24,0,\"course-color-selection-list-item course-color-selection-list-item--full-width\"],[4,[38,1],[[30,0],\"setUnitColor\",[33,0,[\"id\"]],null,[33,10],[33,0,[\"attributes\",\"color\"]]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[\"@isEditable\",\"color\"],false,[\"unit\",\"action\",\"if\",\"icons/arrow-down\",\"liquid-wormhole\",\"liquid-tether\",\"concat\",\"each\",\"-track-array\",\"courseColors\",\"courseId\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class UnitColorSelector extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "courseColors", ["yellow", "muted-yellow", "muted-green", "green", "turquoise", "muted-turquoise", "muted-cyan", "cyan", "blue", "muted-blue", "muted-purple", "purple", "pink", "muted-pink", "muted-coral", "coral"]);
    }

    init() {
      super.init(...arguments);
      this.state = {
        colorMenuIsOpen: false
      };
    }

    openMenu(id) {
      if (!this.isEditable) return;
      Ember.set(this, "state.colorMenuIsOpen", true);
    }

    closeMenu(id) {
      Ember.set(this, "state.colorMenuIsOpen", false);
    }

    setUnitColor(unitId, color, courseId, originalColor) {
      if (!this.isEditable) return;
      Ember.get(this, "store").dispatchAction(_CHANGE_UNIT_COLOR.CHANGE_UNIT_COLOR, {
        courseId: courseId,
        cardStackId: unitId,
        color: color,
        originalValue: originalColor
      });
      Ember.set(this, "state.colorMenuIsOpen", false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "openMenu", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "openMenu"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeMenu", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "closeMenu"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setUnitColor", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "setUnitColor"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = UnitColorSelector;
});