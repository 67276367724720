define("cc-frontend/templates/icons/past-versions-thin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0gR0AB5G",
    "block": "[[[10,\"svg\"],[14,\"baseProfile\",\"tiny\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"624 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M909.3 475.3c-36.5 0-70.3-7.5-100.6-22.2-30.8-15-56.3-36.8-75.8-64.8l26.3-18.3c16.3 23.4 37.7 41.7 63.5 54.3 25.9 12.6 55 19 86.6 19 101.5 0 184-82.5 184-184s-82.5-184-184-184c-51.8 0-98.9 20.3-132.7 57.1-33.1 36.1-51.3 84.9-51.3 137.5h-32c0-60.7 21.2-117.2 59.7-159.1 39.9-43.5 95.4-67.5 156.3-67.5 57.7 0 111.9 22.5 152.7 63.3 40.8 40.8 63.3 95 63.3 152.7S1102.8 371.2 1062 412s-95 63.3-152.7 63.3zM635.5 260.1h147.7l-73.9 73.8zm343.9 72.1L883 272.7V146h24v113.3l85 52.5z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M624 0h512v512H624z\"],[14,\"fill\",\"none\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/past-versions-thin.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});