define("cc-frontend/components/planner/planbook/create-edit/templates-by-name/list", ["exports", "@glimmer/component", "cc-frontend/helpers/find-many", "cc-frontend/utils/filter-dates/course", "lodash-es"], function (_exports, _component, _findMany, _course, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="templates-by-name">
    <div local-class="list" data-test-template-by-name>
      {{#each this.allTemplates as |temp index|}}
        <Planner::Planbook::CreateEdit::TemplatesByName::Item
          @cardStackTemplate={{temp}}
          @planbook={{@planbook}}
          @courses={{@courses}}
          @deleteTemplate={{fn @deleteTemplate temp.id}}
          @editTemplate={{fn @editTemplate temp.id}}
          @toggleTemplateForCourse={{@toggleTemplateForCourse}}
          @templateIndex={{index}}
          data-test-template-name-index={{index}}
        />
  
      {{/each}}
    </div>
    <button local-class="create-button" type="button" {{on "click" @createTemplate}}>
      <Icons::MagicWand />
      Create a Template
    </button>
  </div>
  */
  {
    "id": "/EJ1ZY7x",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"templates-by-name\"],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/templates-by-name/list.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"list\"],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/templates-by-name/list.css\"]]]]]],[14,\"data-test-template-by-name\",\"\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"allTemplates\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],[[16,\"data-test-template-name-index\",[30,2]]],[[\"@cardStackTemplate\",\"@planbook\",\"@courses\",\"@deleteTemplate\",\"@editTemplate\",\"@toggleTemplateForCourse\",\"@templateIndex\"],[[30,1],[30,3],[30,4],[28,[37,4],[[30,5],[30,1,[\"id\"]]],null],[28,[37,4],[[30,6],[30,1,[\"id\"]]],null],[30,7],[30,2]]],null],[1,\"\\n\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n  \"],[11,\"button\"],[16,0,[29,[[28,[37,0],[\"create-button\"],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/templates-by-name/list.css\"]]]]]],[24,4,\"button\"],[4,[38,5],[\"click\",[30,8]],null],[12],[1,\"\\n    \"],[8,[39,6],null,null,null],[1,\"\\n    Create a Template\\n  \"],[13],[1,\"\\n\"],[13]],[\"temp\",\"index\",\"@planbook\",\"@courses\",\"@deleteTemplate\",\"@editTemplate\",\"@toggleTemplateForCourse\",\"@createTemplate\"],false,[\"local-class\",\"each\",\"-track-array\",\"planner/planbook/create-edit/templates-by-name/item\",\"fn\",\"on\",\"icons/magic-wand\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/create-edit/templates-by-name/list.hbs",
    "isStrictMode": false
  });

  let PlanbookTemplatesByNameListComponent = (_dec = Ember.inject.service, _dec2 = (0, _findMany.default)("templateIds", "card-stack-summary"), _dec3 = Ember.computed("args.courses.content.length", "args.planbook.relationships.cardStackTemplates.data.length"), _dec4 = Ember._tracked, (_class = class PlanbookTemplatesByNameListComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "usedTemplates", _descriptor2, this);

      _initializerDefineProperty(this, "_fetchedCardStacks", _descriptor3, this);

      _defineProperty(this, "lastTemplateIds", []);
    }

    // Must use a CP to watch and compare courses.length _and_ planbook.relationships.cardStackTemplates.data.length length to make sure DOM updates upon deleting a template from the template library
    get templateIds() {
      var _this$args$planbook, _this$args$planbook$r;

      if ((0, _lodashEs.isNil)((_this$args$planbook = this.args.planbook) === null || _this$args$planbook === void 0 ? void 0 : (_this$args$planbook$r = _this$args$planbook.relationships) === null || _this$args$planbook$r === void 0 ? void 0 : _this$args$planbook$r.cardStackTemplates)) return [];
      let planbookTemplateIds = (0, _lodashEs.map)(Ember.get(this.args.planbook.relationships.cardStackTemplates, "data"), datum => Ember.get(datum, "id"));
      let courseTemplateIds = (0, _lodashEs.uniq)((0, _lodashEs.compact)((0, _lodashEs.flatMap)(Ember.get(this.args.courses, "content"), course => (0, _course.extractCardStackTemplateIds)(course))));
      return (0, _lodashEs.reject)((0, _lodashEs.uniq)((0, _lodashEs.concat)(planbookTemplateIds, courseTemplateIds)), id => id === "");
    }

    fetchTemplates() {
      if (this.isDestroyed || this.isDestroying) return; // Check if they're the same as last time so we don'd do a circular
      // loop as this will refire when we set this._fetchedCardStacks

      if (!(0, _lodashEs.isEqual)(this.templateIds.sort(), this.lastTemplateIds.sort())) {
        this.lastTemplateIds = this.templateIds;
        return Ember.RSVP.all((0, _lodashEs.map)(this.templateIds, id => this.store.find("card-stack-summary", id))).then(cardStacks => this._fetchedCardStacks = cardStacks);
      }

      return null;
    }

    get allTemplates() {
      let promise = this.fetchTemplates();
      return this._fetchedCardStacks;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "usedTemplates", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateIds", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "templateIds"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_fetchedCardStacks", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = PlanbookTemplatesByNameListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookTemplatesByNameListComponent);
});