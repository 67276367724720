define("cc-frontend/components/d-s/dropdown-menu/icon-row", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="icon-row">
    {{yield}}
  </div>
  */
  {
    "id": "0WzpWwTe",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"icon-row\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/icon-row.css\"]]]]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"yield\"]]",
    "moduleName": "cc-frontend/components/d-s/dropdown-menu/icon-row.hbs",
    "isStrictMode": false
  });

  let IconRow = (_dec = (0, _component.tagName)(""), _dec(_class = class IconRow extends Ember.Component {}) || _class);
  _exports.default = IconRow;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, IconRow);
});