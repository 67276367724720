define("cc-frontend/components/planner/google-classroom/main.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "schedule-button": "_schedule-button_12ject",
    "headers": "_headers_12ject",
    "headers--classes": "_headers--classes_12ject",
    "headers__attachment-edit-notice": "_headers__attachment-edit-notice_12ject",
    "attachment-section": "_attachment-section_12ject",
    "attachments__buttons": "_attachments__buttons_12ject",
    "attachment-button": "_attachment-button_12ject"
  };
  _exports.default = _default;
});