define("cc-frontend/components/auth/sign-in-with-google.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "_wrapper_1tuusf",
    "sign-in-btn": "_sign-in-btn_1tuusf",
    "icon": "_icon_1tuusf",
    "text": "_text_1tuusf"
  };
  _exports.default = _default;
});