define("cc-frontend/templates/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "StTdUkMF",
    "block": "[[[10,\"h1\"],[12],[1,\"\\n  Session\\n\"],[13],[1,\"\\n\"],[1,[34,0]],[1,\"\\n\"],[1,[54,[[33,0]]]],[1,\"\\n\"],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"model\",\"log\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/session.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});