define("cc-frontend/utils/filter-dates/shared", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findDate = findDate;
  _exports.filterForDatesBefore = filterForDatesBefore;

  /**
   * Find a date by Date String
   */
  function findDate(customDates, dateString) {
    return (0, _lodashEs.find)(customDates, date => date.attributes.dateString === dateString);
  }

  function filterForDatesBefore(customDates, effectiveDate) {
    return (0, _lodashEs.filter)(customDates, date => date.attributes.dateString < effectiveDate);
  }
});