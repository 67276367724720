define("cc-frontend/helpers/includes-sub-string", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.includes = includes;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function includes(params) {
    let string = params[0];
    let substring = params[1];
    return _lodash.default.includes(string, substring);
  }

  class IncludesSubString extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "compute", includes);
    }

  }

  _exports.default = IncludesSubString;
});