define("cc-frontend/components/bopit/popover", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let Popover = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{yield (hash
      anchor=(component @anchor
        parentElementId=elementId
        onClick=@onAnchorClick
        isOpenClass=@isOpenClass
        isOpen=@isOpen
        mouseHasEntered=@mouseHasEnteredAnchor
        mouseHasLeft=@mouseHasLeftAnchor
     )
     body=(component @body
       anchorElementId=(concat elementId "-anchor")
       classPrefix=@classPrefix
       attachment=@attachment
       targetAttachment=@targetAttachment
       constraints=@constraints
       isInteractive=@isInteractive
       offset=@offset
       isOpen=@isOpen
       isOpenClass=@isOpenClass
       afterOpenClass=@afterOpenClass
       clickedOutside=@clickedOutside
       bodyClasses=@bodyClasses
       mouseHasEntered=@mouseHasEnteredBody
       mouseHasLeft=@mouseHasLeftBody
     )
    )}}
  
  */
  {
    "id": "lDOfrgK0",
    "block": "[[[1,\"\\n  \"],[18,19,[[28,[37,1],null,[[\"anchor\",\"body\"],[[50,[30,1],0,null,[[\"parentElementId\",\"onClick\",\"isOpenClass\",\"isOpen\",\"mouseHasEntered\",\"mouseHasLeft\"],[[33,3],[30,2],[30,3],[30,4],[30,5],[30,6]]]],[50,[30,7],0,null,[[\"anchorElementId\",\"classPrefix\",\"attachment\",\"targetAttachment\",\"constraints\",\"isInteractive\",\"offset\",\"isOpen\",\"isOpenClass\",\"afterOpenClass\",\"clickedOutside\",\"bodyClasses\",\"mouseHasEntered\",\"mouseHasLeft\"],[[28,[37,4],[[33,3],\"-anchor\"],null],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,4],[30,3],[30,14],[30,15],[30,16],[30,17],[30,18]]]]]]]]],[1,\"\\n\"]],[\"@anchor\",\"@onAnchorClick\",\"@isOpenClass\",\"@isOpen\",\"@mouseHasEnteredAnchor\",\"@mouseHasLeftAnchor\",\"@body\",\"@classPrefix\",\"@attachment\",\"@targetAttachment\",\"@constraints\",\"@isInteractive\",\"@offset\",\"@afterOpenClass\",\"@clickedOutside\",\"@bodyClasses\",\"@mouseHasEnteredBody\",\"@mouseHasLeftBody\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"elementId\",\"concat\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec(_class = class Popover extends Ember.Component {}) || _class);
  _exports.default = Popover;
});