define("cc-frontend/lib/actions/course/calendar/BUMP_RANGE_TO_DATE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils"], function (_exports, _patchUpdater, _unitUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareBumpRangeToDate = prepareBumpRangeToDate;
  _exports.BUMP_RANGE_TO_DATE = void 0;

  function prepareBumpRangeToDate(payload) {
    return {
      name: BUMP_RANGE_TO_DATE.name,
      payload: payload,
      patches: BUMP_RANGE_TO_DATE.patches(payload),
      undoPatches: BUMP_RANGE_TO_DATE.undoPatches(payload),
      narrative: BUMP_RANGE_TO_DATE.narrative
    };
  }

  const BUMP_RANGE_TO_DATE = {
    name: "BUMP_RANGE_TO_DATE",

    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let {
        transferrableArray,
        courseDatesWithoutRange
      } = (0, _unitUtils.extractRange)(payload.startDate, payload.endDate, payload.course.attributes.calendar.dates, consolidatedCourseDatesOff, schoolDays); // We want to put it on the day after the end date. E.g. if spring break goes through April 10,
      // we want to insert on April 11

      let newStartDate = (0, _unitUtils.findNewDate)(1, payload.endDate, // @ts-ignore unsure why it's erroring Scott says ok to ignore for now
      consolidatedCourseDatesOff, schoolDays);
      let newCourseDates = (0, _unitUtils.insertRange)(transferrableArray, newStartDate, 1, // this is just to show we're dragging forward. Should be renamed to drgging forward
      {}, courseDatesWithoutRange, consolidatedCourseDatesOff, schoolDays);
      let ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
      let {
        courseDates,
        units
      } = ret;

      let patch = _patchUpdater.default.create("course", payload.course.id).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).inc(`attributes.calendar.version`, 1).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).inc("attributes.calendar.version", 1).patch;

      return [patch];
    },

    narrative: null
  };
  _exports.BUMP_RANGE_TO_DATE = BUMP_RANGE_TO_DATE;
});