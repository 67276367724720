define("cc-frontend/templates/icons/-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "coBrH6Oq",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M257 143c-92.9 0-165.2 55.3-254 145.1 76.4 76.8 140.6 145.1 254 145.1 113.3 0 196.6-86.6 254-143.6-58.7-66.5-142-146.6-254-146.6zm0 248.7c-56 0-101.6-46.5-101.6-103.5 0-57.2 45.6-103.5 101.6-103.5s101.6 46.5 101.6 103.5c0 57.1-45.6 103.5-101.6 103.5zm0-139.8c0-9 3.3-17.1 8.6-23.5-2.8-.5-5.7-.7-8.6-.7-32.7 0-59.3 27.1-59.3 60.4s26.6 60.4 59.3 60.4 59.3-27.1 59.3-60.4c0-2.6-.2-5.2-.5-7.8-6.2 4.9-13.9 7.8-22.5 7.8-20.1 0-36.3-16.2-36.3-36.2z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-eye.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});