define("cc-frontend/components/kb/breadcrumbs", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/has-many-altogether", "ember-could-get-used-to-this", "lodash-es"], function (_exports, _glimmerComponent, _hasManyAltogether, _emberCouldGetUsedToThis, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="kb-breadcrumbs">
    <LinkTo @route="help.article" @model={{this.home.id}} class="kb-breadcrumbs__crumb">
      Home
    </LinkTo>
    <div class="kb-breadcrumbs__crumb-arrow">
      <Icons::IosArrowRight />
    </div>
    {{#each (drop 1 this.ancestors.content) as |ancestor|}}
      <LinkTo @route="help.article" @model={{ancestor.id}} class="kb-breadcrumbs__crumb">
        {{ancestor.attributes.title}}
      </LinkTo>
      <div class="kb-breadcrumbs__crumb-arrow">
        <Icons::IosArrowRight />
      </div>
    {{/each}}
    <div class="kb-breadcrumbs__crumb kb-breadcrumbs__crumb--current">
      {{@entry.attributes.title}}
    </div>
  </div>
  */
  {
    "id": "HSBvjJqn",
    "block": "[[[10,0],[14,0,\"kb-breadcrumbs\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"kb-breadcrumbs__crumb\"]],[[\"@route\",\"@model\"],[\"help.article\",[30,0,[\"home\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n    Home\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"kb-breadcrumbs__crumb-arrow\"],[12],[1,\"\\n    \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[28,[37,4],[1,[30,0,[\"ancestors\",\"content\"]]],null]],null]],null],null,[[[1,\"    \"],[8,[39,0],[[24,0,\"kb-breadcrumbs__crumb\"]],[[\"@route\",\"@model\"],[\"help.article\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,1,[\"attributes\",\"title\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[10,0],[14,0,\"kb-breadcrumbs__crumb-arrow\"],[12],[1,\"\\n      \"],[8,[39,1],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[10,0],[14,0,\"kb-breadcrumbs__crumb kb-breadcrumbs__crumb--current\"],[12],[1,\"\\n    \"],[1,[30,2,[\"attributes\",\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"ancestor\",\"@entry\"],false,[\"link-to\",\"icons/ios-arrow-right\",\"each\",\"-track-array\",\"drop\"]]",
    "moduleName": "cc-frontend/components/kb/breadcrumbs.hbs",
    "isStrictMode": false
  });

  let KbBreadcrumbs = (_dec = Ember.inject.service, (_class = class KbBreadcrumbs extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "ancestors", _descriptor2, this);
    }

    get home() {
      return (0, _lodashEs.find)(this.ancestors.content, ancestor => {
        return ancestor.meta.type === "book";
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ancestors", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _hasManyAltogether.default(() => [this.args.entry, "ancestors", "kb-entry"]);
    }
  })), _class));
  _exports.default = KbBreadcrumbs;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KbBreadcrumbs);
});