define("cc-frontend/templates/admin/groups/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8RAxrK0o",
    "block": "[[[10,0],[14,0,\"tw-absolute tw-pin-l tw-pin-t tw-h-screen tw-w-screen tw-z-50 tw-pl-8\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"tw-bg-white tw-ml-8 tw-p-8 tw-h-screen tw-shadow-lg tw-overflow-y-scroll\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,null],[1,\"\\n    \"],[8,[39,1],[[24,0,\"tw-pin-r tw-absolute tw-pin-r tw-pin-t tw-h-12 tw-w-12 tw-m-4 tw-rounded-full tw-border-grey tw-border tw-flex tw-items-center tw-justify-center hover:tw-bg-link-blue hover:tw-text-white tw-text-grey\"]],[[\"@route\"],[\"admin.groups-old-admin\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"svg\"],[14,0,\"tw-fill-current tw-inline-block tw-h-8 tw-w-8\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n        \"],[10,\"path\"],[14,0,\"st0\"],[14,\"d\",\"M340.2 160l-84.4 84.3-84-83.9-11.8 11.8 84 83.8-84 83.9 11.8 11.7 84-83.8 84.4 84.2 11.8-11.7-84.4-84.3 84.4-84.2z\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"admin/group/new\",\"link-to\"]]",
    "moduleName": "cc-frontend/templates/admin/groups/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});