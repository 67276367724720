define("cc-frontend/lib/actions/lesson/LESSON_CARD_ADD", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_ADD = void 0;
  const LESSON_CARD_ADD = {
    name: "LESSON_CARD_ADD",
    params: {
      id: "string",
      cardStackId: "string",
      position: "number"
    },

    patches(payload) {
      let card;

      if (payload.type === "card-standards") {
        card = {
          id: payload.id,
          type: "card-standards",
          attributes: {
            title: "Standards",
            standards: [],
            comments: [],
            attachments: [],
            position: payload.position,
            color: payload.color,
            isPublic: false
          }
        };
      } else if (payload.type === "card-google-classroom") {
        if (payload.postType === undefined) throw Error("Undefined post type");
        let extraPostProperties = payload.postType === "MULTIPLE_CHOICE_QUESTION" ? {
          choices: ["Option 1"]
        } : {};
        card = {
          id: payload.id,
          type: "card-google-classroom",
          attributes: {
            title: "",
            value: "",
            maxPoints: null,
            position: payload.position,
            color: payload.color,
            comments: [],
            attachments: [],
            postType: payload.postType,
            attachmentSettings: {},
            isPublic: false,
            classSettings: [],
            enabledGoogleClassIds: [],
            _revision: 0,
            extraPostProperties: extraPostProperties
          }
        };
      } else if (payload.type === "card-title-value-html") {
        card = {
          id: payload.id,
          type: "card-title-value-html",
          attributes: {
            title: "",
            value: "",
            position: payload.position,
            color: payload.color,
            isPublic: false,
            attachments: [],
            comments: []
          }
        };
      } else {
        throw new Error(`Incorrect Card Type. Tried to created ${payload.type}`);
      }

      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).push("attributes.cards", card).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).pull("attributes.cards", {
        id: payload.id
      }).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);
      context.cardType = payload.type;
      return {
        title: "Added Card to Lesson",
        titleForAnalytics: "Lesson Card - Add",
        description: `Added card to "${context.cardStackTitle}" on "${context.date}"`,
        context: context,
        activity: (0, _defaultLessonActivity.default)(payload, models, "created")
      };
    }

  };
  _exports.LESSON_CARD_ADD = LESSON_CARD_ADD;
  var _default = LESSON_CARD_ADD;
  _exports.default = _default;
});