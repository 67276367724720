define("cc-frontend/components/icons/circle-check-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.08 7.54C15.08 11.7042 11.7042 15.08 7.54 15.08C3.37577 15.08 0 11.7042 0 7.54C0 3.37577 3.37577 0 7.54 0C11.7042 0 15.08 3.37577 15.08 7.54Z"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6226 3.83629C11.9375 4.08515 11.991 4.54212 11.7421 4.85698L6.99626 10.8615C6.86575 11.0311 6.69707 11.1676 6.50386 11.2599C6.30816 11.3534 6.09296 11.3988 5.87618 11.3923C5.6594 11.3859 5.44729 11.3277 5.25752 11.2227C5.07076 11.1194 4.91095 10.9736 4.79092 10.7972L3.31136 8.69747C3.0802 8.36941 3.15875 7.91606 3.48682 7.6849C3.81488 7.45373 4.26823 7.53229 4.49939 7.86035L5.9211 9.87802L10.6019 3.95579C10.8508 3.64093 11.3078 3.58743 11.6226 3.83629Z"
      fill="white"
    ></path>
  </svg>
  */
  {
    "id": "pDCAMM8x",
    "block": "[[[10,\"svg\"],[14,\"width\",\"16\"],[14,\"height\",\"16\"],[14,\"viewBox\",\"0 0 16 16\"],[14,\"fill\",\"none\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M15.08 7.54C15.08 11.7042 11.7042 15.08 7.54 15.08C3.37577 15.08 0 11.7042 0 7.54C0 3.37577 3.37577 0 7.54 0C11.7042 0 15.08 3.37577 15.08 7.54Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M11.6226 3.83629C11.9375 4.08515 11.991 4.54212 11.7421 4.85698L6.99626 10.8615C6.86575 11.0311 6.69707 11.1676 6.50386 11.2599C6.30816 11.3534 6.09296 11.3988 5.87618 11.3923C5.6594 11.3859 5.44729 11.3277 5.25752 11.2227C5.07076 11.1194 4.91095 10.9736 4.79092 10.7972L3.31136 8.69747C3.0802 8.36941 3.15875 7.91606 3.48682 7.6849C3.81488 7.45373 4.26823 7.53229 4.49939 7.86035L5.9211 9.87802L10.6019 3.95579C10.8508 3.64093 11.3078 3.58743 11.6226 3.83629Z\"],[14,\"fill\",\"white\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/circle-check-filled.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});