define("cc-frontend/components/planner/csp/standard", ["exports", "@ember-decorators/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CspStandardComponent = (_dec = (0, _component.classNames)("csp-standard"), _dec2 = (0, _component.classNameBindings)("classNameDepth", "isSelected", "standardIsHidden"), _dec3 = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
  
    <div class="csp-standard__text" {{on "click" (fn this.toggleStandard @standard) }}>
      <div class="csp-standard__selected-icon {{if this.isSelected "--is-selected" "--is-not-selected"}} ">
        {{#if this.isSelected}}
          <Icons::IosCheckmark />
        {{else}}
          <Icons::IosPlus />
        {{/if}}
      </div>
  
      <div class="csp-standard__description-and-list-id">
        <div class="csp-standard__description">
          <span class="csp-standard__list-id">{{@standard.listId}}</span>
          {{{@standard.description}}}
          <span class="csp-standard__statement-notation">{{@standard.statementNotation}}</span>
        </div>
      </div>
  
    </div>
    {{#if @showStandardCoverage}}
      <div class="csp-standard__tracking">
        {{#with (get @standardsCoverage @standard.id) as |coverage|}}
          {{coverage.uses.length}}
        {{/with}}
      </div>
    {{else}}
      <div class="csp-standard__tracking">
        <span class="label label-default" style="font-size: 13px;">?</span>
      </div>
  
    {{/if}}
  
  */
  {
    "id": "O02n6NOV",
    "block": "[[[1,\"\\n\\n  \"],[11,0],[24,0,\"csp-standard__text\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"toggleStandard\"]],[30,1]],null]],null],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"csp-standard__selected-icon \",[52,[30,0,[\"isSelected\"]],\"--is-selected\",\"--is-not-selected\"],\" \"]]],[12],[1,\"\\n\"],[41,[30,0,[\"isSelected\"]],[[[1,\"        \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"csp-standard__description-and-list-id\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"csp-standard__description\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"csp-standard__list-id\"],[12],[1,[30,1,[\"listId\"]]],[13],[1,\"\\n        \"],[2,[30,1,[\"description\"]]],[1,\"\\n        \"],[10,1],[14,0,\"csp-standard__statement-notation\"],[12],[1,[30,1,[\"statementNotation\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"csp-standard__tracking\"],[12],[1,\"\\n\"],[44,[[28,[37,6],[[30,3],[30,1,[\"id\"]]],null]],[[[41,[30,4],[[[1,\"        \"],[1,[30,4,[\"uses\",\"length\"]]],[1,\"\\n\"]],[]],null]],[4]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"csp-standard__tracking\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"label label-default\"],[14,5,\"font-size: 13px;\"],[12],[1,\"?\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"]],[]]]],[\"@standard\",\"@showStandardCoverage\",\"@standardsCoverage\",\"coverage\"],false,[\"on\",\"fn\",\"if\",\"icons/ios-checkmark\",\"icons/ios-plus\",\"let\",\"get\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec4 = Ember.computed("standard.description"), _dec5 = Ember.computed("standard.depth"), _dec6 = Ember.computed("selectedStandardIds.@each", "standard.id"), _dec7 = Ember.computed("results.@each"), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class CspStandardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "toggleStandard", () => {});
    }

    get textToCopy() {
      var statementNotation = this.get("standard.statementNotation") || "";
      return [statementNotation, this.get("standard.description")].join(" ");
    }

    get classNameDepth() {
      return "csp-standard--depth-" + this.get("standard.depth");
    }

    get isSelected() {
      return _lodash.default.includes(this.selectedStandardIds, this.get("standard.id"));
    }

    get standardIsHidden() {
      if (this.results == null) return false;
      return !_lodash.default.includes(this.results, this.get("standard.id"));
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "textToCopy", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "textToCopy"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "classNameDepth", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "classNameDepth"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isSelected", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "standardIsHidden", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "standardIsHidden"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = CspStandardComponent;
});