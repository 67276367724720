define("cc-frontend/components/icons/social-dropbox-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
  >
    <path
      d="M150.61 21.09L0 119.42l104.14 83.39L256 109zM0 286.22l150.61 98.34 105.39-88-151.86-93.74zm256 10.37l105.39 88L512 286.22l-104.14-83.4z"
      fill="#007ee5"
    ></path>
    <path
      d="M512 119.42L361.39 21.09 256 109l151.86 93.77zm-255.69 196.1l-105.7 87.71-45.23-29.53v33.1l150.93 90.5 150.93-90.5v-33.11L362 403.22z"
      fill="#007ee5"
    ></path>
  </svg>
  */
  {
    "id": "lvPYyHSO",
    "block": "[[[10,\"svg\"],[14,\"data-name\",\"Layer 1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M150.61 21.09L0 119.42l104.14 83.39L256 109zM0 286.22l150.61 98.34 105.39-88-151.86-93.74zm256 10.37l105.39 88L512 286.22l-104.14-83.4z\"],[14,\"fill\",\"#007ee5\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M512 119.42L361.39 21.09 256 109l151.86 93.77zm-255.69 196.1l-105.7 87.71-45.23-29.53v33.1l150.93 90.5 150.93-90.5v-33.11L362 403.22z\"],[14,\"fill\",\"#007ee5\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/social-dropbox-color.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});