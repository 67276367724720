define("cc-frontend/components/planner/menus/date-annotation/ask-if-bump-body", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-flex tw-flex-col">
    <Planner::Menus::Parts::OutlineButton @onClick={{@bumpButton}} data-test-bump-lesson-button>
      Yes, I want to bump
    </Planner::Menus::Parts::OutlineButton>
    <Planner::Menus::Parts::OutlineButton @onClick={{@deleteButton}} data-test-erase-lesson-button>
      No, I want to erase
    </Planner::Menus::Parts::OutlineButton>
  </div>
  */
  {
    "id": "rhF935MA",
    "block": "[[[10,0],[14,0,\"tw-flex tw-flex-col\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-bump-lesson-button\",\"\"]],[[\"@onClick\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    Yes, I want to bump\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-erase-lesson-button\",\"\"]],[[\"@onClick\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n    No, I want to erase\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@bumpButton\",\"@deleteButton\"],false,[\"planner/menus/parts/outline-button\"]]",
    "moduleName": "cc-frontend/components/planner/menus/date-annotation/ask-if-bump-body.hbs",
    "isStrictMode": false
  });

  let DateAnnotationAskIfBumpBody = (_dec = (0, _component.tagName)(""), _dec(_class = class DateAnnotationAskIfBumpBody extends Ember.Component {}) || _class);
  _exports.default = DateAnnotationAskIfBumpBody;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DateAnnotationAskIfBumpBody);
});