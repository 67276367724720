define("cc-frontend/lib/actions/course/calendar/RESIZE_UNIT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "lodash"], function (_exports, _patchUpdater, _unitUtils, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "RESIZE_UNIT",
    params: {
      unitId: "string",
      amount: "number",
      course: "object",
      startOrEndOfUnit: "string",
      planbook: "object",
      rotationCalendar: "object"
    },

    /**
      @param {{
        unit: Object,
        amount: Number,
        newDate?: String,
        courseId: String,
        courseDates: Object[],
        startOrEndOfUnit: "START" | "END",
        consolidatedCourseDatesOff: Object[],
        schoolDays: Number[]
      }} payload [description]
      @return {Array} Patches
     */
    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;

      let unit = _lodash.default.find(payload.course.attributes.calendar.units, {
        id: payload.unitId
      });

      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let newDate = payload.newDate || (0, _unitUtils.findNewDate)(payload.amount, unit.endDate, consolidatedCourseDatesOff, schoolDays);
      let newCourseDates;

      if (payload.startOrEndOfUnit === "END") {
        newCourseDates = (0, _unitUtils.moveEndOfUnit)(payload.amount, newDate, unit, payload.course.attributes.calendar.dates, payload.course.attributes.calendar.units, consolidatedCourseDatesOff, schoolDays);
      }

      if (payload.startOrEndOfUnit === "START") {
        newCourseDates = (0, _unitUtils.moveStartOfUnit)(payload.amount, newDate, unit, payload.course.attributes.calendar.dates, payload.course.attributes.calendar.units, consolidatedCourseDatesOff, schoolDays);
      }

      let ret;

      try {
        ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
      } catch (e) {
        console.error(e);
        return [];
      }

      let {
        courseDates,
        units
      } = ret;
      let unitIdsOnTheMove = _lodash.default.get(_lodash.default.find(units, {
        id: unit.id
      }), "descendantIds") || [];
      unitIdsOnTheMove = unitIdsOnTheMove.concat([unit.id]);
      let cardStackIdsOnTheMove = [];

      let patch = _patchUpdater.default.create("course", payload.course.id).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).inc(`attributes.calendar.version`, 1).patch;

      patch.unitIdsOnTheMove = unitIdsOnTheMove;
      patch.cardStackIdsOnTheMove = cardStackIdsOnTheMove;
      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let course = payload.course;
      let models = {
        course: course,
        planbook: findFn("planbook", course.relationships.planbook.data.id),
        unit: findFn("card-stack-summary", payload.unitId)
      };
      let unitTitle = Ember.get(models, "unit.attributes.title");
      let amount = Math.abs(payload.amount);
      let direction = payload.amount > 0 ? "longer" : "shorter";

      let unit = _lodash.default.find(course.attributes.calendar.units, {
        id: payload.unitId
      });

      let dayOrDays = payload.amount === 1 ? "day" : "days";
      return {
        title: "Resized Unit",
        titleForAnalytics: "Unit - Resize",
        verbForStream: "edited",
        description: `Resized "${unitTitle}" to make it ${direction} by ${amount} ${dayOrDays}.`,
        context: {
          unitTitle: Ember.get(models, "unit.attributes.title"),
          courseTitle: Ember.get(models, "course.attributes.title"),
          planbookTitle: Ember.get(models, "planbook.attributes.title"),
          unitId: Ember.get(models, "unit.id"),
          courseId: Ember.get(models, "course.id"),
          planbookId: Ember.get(models, "planbook.id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${models.course.id}`,
          to: [`course-edits:course-${models.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        }
      };
    }

  };
  _exports.default = _default;
});