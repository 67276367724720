define("cc-frontend/components/icons/lock-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! Lock 1 }}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M12,19.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.586c-0.59,-0.209 -1,-0.774 -1,-1.414c0,-0.827 0.673,-1.5 1.5,-1.5c0.827,0 1.5,0.673 1.5,1.5c0,0.64 -0.41,1.205 -1,1.414v2.586c0,0.276 -0.224,0.5 -0.5,0.5Zm0,-5c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.276 0.224,0.5 0.5,0.5c0.276,0 0.5,-0.224 0.5,-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5Z"
      ></path>
      <path
        d="M4.5,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-12c0,-0.827 0.673,-1.5 1.5,-1.5h1.5v-3c0,-3.308 2.692,-6 6,-6c3.308,0 6,2.692 6,6v3h1.5c0.827,0 1.5,0.673 1.5,1.5v12c0,0.827 -0.673,1.5 -1.5,1.5h-15Zm0,-14c-0.276,0 -0.5,0.224 -0.5,0.5v12c0,0.276 0.224,0.5 0.5,0.5h15c0.276,0 0.5,-0.224 0.5,-0.5v-12c0,-0.276 -0.224,-0.5 -0.5,-0.5h-15Zm12.5,-1v-3c0,-2.757 -2.243,-5 -5,-5c-2.757,0 -5,2.243 -5,5v3h10Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "URP7/8fl",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,19.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.586c-0.59,-0.209 -1,-0.774 -1,-1.414c0,-0.827 0.673,-1.5 1.5,-1.5c0.827,0 1.5,0.673 1.5,1.5c0,0.64 -0.41,1.205 -1,1.414v2.586c0,0.276 -0.224,0.5 -0.5,0.5Zm0,-5c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.276 0.224,0.5 0.5,0.5c0.276,0 0.5,-0.224 0.5,-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M4.5,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-12c0,-0.827 0.673,-1.5 1.5,-1.5h1.5v-3c0,-3.308 2.692,-6 6,-6c3.308,0 6,2.692 6,6v3h1.5c0.827,0 1.5,0.673 1.5,1.5v12c0,0.827 -0.673,1.5 -1.5,1.5h-15Zm0,-14c-0.276,0 -0.5,0.224 -0.5,0.5v12c0,0.276 0.224,0.5 0.5,0.5h15c0.276,0 0.5,-0.224 0.5,-0.5v-12c0,-0.276 -0.224,-0.5 -0.5,-0.5h-15Zm12.5,-1v-3c0,-2.757 -2.243,-5 -5,-5c-2.757,0 -5,2.243 -5,5v3h10Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/lock-square.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});