define("cc-frontend/components/d-s/full-screen-menu.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "full-screen-menu": "_full-screen-menu_teg7ep",
    "full-screen-menu__backdrop": "_full-screen-menu__backdrop_teg7ep",
    "full-screen-close-space": "_full-screen-close-space_teg7ep",
    "full-screen-close-btn": "_full-screen-close-btn_teg7ep"
  };
  _exports.default = _default;
});