define("cc-frontend/components/planner/course/outline-and-detail/detail", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isUnit}}
    <Planner::CardStacks::UnitProvider
      @course={{@course}}
      @unitId={{this.localFocusedItemId}}
      as |cardStackIsFulfilled cardStack unitInfo units|
    >
      {{#if cardStackIsFulfilled}}
        <Planner::CardStacks::Unit
          @models={{hash
            cardStack=cardStack
            unitInfo=unitInfo
            rotationCalendar=@rotationCalendar
            lessonIsFulfilled=cardStackIsFulfilled
            course=@course
            planbook=@planbook
            units=units
          }}
          @editCourseTitleAndOrder={{@editCourseTitleAndOrder}}
        />
      {{else}}
        <Planner::CardStacks::LessonLoading />
      {{/if}}
    </Planner::CardStacks::UnitProvider>
  {{/if}}
  {{#if this.isLesson}}
    <Planner::CardStacks::Lesson::Provider
      @course={{@course}}
      @planbook={{@planbook}}
      @dateString={{this.localFocusedItemId}}
      @rotationCalendar={{@rotationCalendar}}
      as |courseDate cardStack units cardStackIsFulfilled|
    >
      {{#unless courseDate.hideFromCalendar}}
        {{#if cardStackIsFulfilled}}
          <Planner::CardStacks::LessonEditable
            @models={{hash
              courseDate=courseDate
              lesson=cardStack
              units=units
              rotationCalendar=@rotationCalendar
              lessonIsFulfilled=cardStackIsFulfilled
              course=@course
              planbook=@planbook
              dateString=courseDate.dateString
            }}
            @perspective={{this.perspective}}
            @classNames="take-course-background-color"
            @editCourseTitleAndOrder={{@editCourseTitleAndOrder}}
          />
        {{else}}
          <Planner::CardStacks::LessonLoading />
        {{/if}}
      {{/unless}}
    </Planner::CardStacks::Lesson::Provider>
  {{/if}}
  {{yield}}
  */
  {
    "id": "/MGot+up",
    "block": "[[[41,[30,0,[\"isUnit\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@course\",\"@unitId\"],[[30,1],[30,0,[\"localFocusedItemId\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[8,[39,2],null,[[\"@models\",\"@editCourseTitleAndOrder\"],[[28,[37,3],null,[[\"cardStack\",\"unitInfo\",\"rotationCalendar\",\"lessonIsFulfilled\",\"course\",\"planbook\",\"units\"],[[30,3],[30,4],[30,6],[30,2],[30,1],[30,7],[30,5]]]],[30,8]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]]],[1,\"  \"]],[2,3,4,5]]]]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"isLesson\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@course\",\"@planbook\",\"@dateString\",\"@rotationCalendar\"],[[30,1],[30,7],[30,0,[\"localFocusedItemId\"]],[30,6]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,9,[\"hideFromCalendar\"]]],[[[41,[30,12],[[[1,\"        \"],[8,[39,7],null,[[\"@models\",\"@perspective\",\"@classNames\",\"@editCourseTitleAndOrder\"],[[28,[37,3],null,[[\"courseDate\",\"lesson\",\"units\",\"rotationCalendar\",\"lessonIsFulfilled\",\"course\",\"planbook\",\"dateString\"],[[30,9],[30,10],[30,11],[30,6],[30,12],[30,1],[30,7],[30,9,[\"dateString\"]]]]],[30,0,[\"perspective\"]],\"take-course-background-color\",[30,8]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"]],[9,10,11,12]]]]],[1,\"\\n\"]],[]],null],[18,13,null]],[\"@course\",\"cardStackIsFulfilled\",\"cardStack\",\"unitInfo\",\"units\",\"@rotationCalendar\",\"@planbook\",\"@editCourseTitleAndOrder\",\"courseDate\",\"cardStack\",\"units\",\"cardStackIsFulfilled\",\"&default\"],false,[\"if\",\"planner/card-stacks/unit-provider\",\"planner/card-stacks/unit\",\"hash\",\"planner/card-stacks/lesson-loading\",\"planner/card-stacks/lesson/provider\",\"unless\",\"planner/card-stacks/lesson-editable\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/course/outline-and-detail/detail.hbs",
    "isStrictMode": false
  });

  let OutlineDetails = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec(_class = (_class2 = class OutlineDetails extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "focusedItemId", void 0);

      _defineProperty(this, "localFocusedItemId", null);

      _defineProperty(this, "isUnit", false);

      _defineProperty(this, "isLesson", false);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    didReceiveAttrs() {
      Ember.run.later(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.setProperties({
          localFocusedItemId: this.focusedItemId,
          isUnit: this.focusedItemId && this.focusedItemId.toString().length > 10,
          isLesson: this.focusedItemId && this.focusedItemId.toString().length === 10
        });
      }, 30);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = OutlineDetails;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OutlineDetails);
});