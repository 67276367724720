define("cc-frontend/components/planner/course/outline-and-detail/list-for-printing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="course-outline-wrapper">
    <div class="course-outline">
      {{#each this.dates as |dateOrUnit index|}}
        <Planner::CardStacks::SimpleProvider
          @cardStackId={{dateOrUnit.id}}
          as |cardStack cardStackIsPending|
        >
          <div class="print-outline__row {{if dateOrUnit.isUnit "is-unit" "is-date"}}">
            {{#if dateOrUnit.isUnit}}
              <span
                class="print-outline__row-icon print-outline__icon--depth-
                  {{dateOrUnit.ancestorIds.length}}"
              >
                <Icons::IosFolderOutline />
              </span>
            {{else}}
              <span
                class="print-outline__row-icon print-outline__icon--depth-
                  {{dateOrUnit.unitIds.length}}"
              >
                <Icons::Document />
              </span>
            {{/if}}
            <span class="print-outline__row-title">
              {{cardStack.attributes.title}}
            </span>
            <span class="print-outline__row-date-range">
              {{#if dateOrUnit.isUnit}}
                {{date-range dateOrUnit.startDate dateOrUnit.endDate}}
              {{else}}
                {{moment-format dateOrUnit.dateString "dd M/D"}}
              {{/if}}
            </span>
          </div>
        </Planner::CardStacks::SimpleProvider>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "VZnQSMMd",
    "block": "[[[10,0],[14,0,\"course-outline-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"course-outline\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"dates\"]]],null]],null],null,[[[1,\"      \"],[8,[39,2],null,[[\"@cardStackId\"],[[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[29,[\"print-outline__row \",[52,[30,1,[\"isUnit\"]],\"is-unit\",\"is-date\"]]]],[12],[1,\"\\n\"],[41,[30,1,[\"isUnit\"]],[[[1,\"            \"],[10,1],[15,0,[29,[\"print-outline__row-icon print-outline__icon--depth-\\n                \",[30,1,[\"ancestorIds\",\"length\"]]]]],[12],[1,\"\\n              \"],[8,[39,4],null,null,null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,1],[15,0,[29,[\"print-outline__row-icon print-outline__icon--depth-\\n                \",[30,1,[\"unitIds\",\"length\"]]]]],[12],[1,\"\\n              \"],[8,[39,5],null,null,null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"          \"],[10,1],[14,0,\"print-outline__row-title\"],[12],[1,\"\\n            \"],[1,[30,3,[\"attributes\",\"title\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,1],[14,0,\"print-outline__row-date-range\"],[12],[1,\"\\n\"],[41,[30,1,[\"isUnit\"]],[[[1,\"              \"],[1,[28,[35,6],[[30,1,[\"startDate\"]],[30,1,[\"endDate\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[35,7],[[30,1,[\"dateString\"]],\"dd M/D\"],null]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[3,4]]]]],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"dateOrUnit\",\"index\",\"cardStack\",\"cardStackIsPending\"],false,[\"each\",\"-track-array\",\"planner/card-stacks/simple-provider\",\"if\",\"icons/ios-folder-outline\",\"icons/document\",\"date-range\",\"moment-format\"]]",
    "moduleName": "cc-frontend/components/planner/course/outline-and-detail/list-for-printing.hbs",
    "isStrictMode": false
  });

  class Outline extends Ember.Component {} // @ts-ignore


  _exports.default = Outline;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Outline);
});