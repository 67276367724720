define("cc-frontend/lib/phoenix/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.XHR_STATES = _exports.TRANSPORTS = _exports.CHANNEL_EVENTS = _exports.CHANNEL_STATES = _exports.WS_CLOSE_NORMAL = _exports.DEFAULT_TIMEOUT = _exports.SOCKET_STATES = _exports.DEFAULT_VSN = _exports.global = _exports.phxWindow = _exports.globalSelf = void 0;
  const globalSelf = typeof self !== "undefined" ? self : null;
  _exports.globalSelf = globalSelf;
  const phxWindow = typeof window !== "undefined" ? window : null;
  _exports.phxWindow = phxWindow;
  const global = globalSelf || phxWindow || global;
  _exports.global = global;
  const DEFAULT_VSN = "2.0.0";
  _exports.DEFAULT_VSN = DEFAULT_VSN;
  const SOCKET_STATES = {
    connecting: 0,
    open: 1,
    closing: 2,
    closed: 3
  };
  _exports.SOCKET_STATES = SOCKET_STATES;
  const DEFAULT_TIMEOUT = 10000;
  _exports.DEFAULT_TIMEOUT = DEFAULT_TIMEOUT;
  const WS_CLOSE_NORMAL = 1000;
  _exports.WS_CLOSE_NORMAL = WS_CLOSE_NORMAL;
  const CHANNEL_STATES = {
    closed: "closed",
    errored: "errored",
    joined: "joined",
    joining: "joining",
    leaving: "leaving"
  };
  _exports.CHANNEL_STATES = CHANNEL_STATES;
  const CHANNEL_EVENTS = {
    close: "phx_close",
    error: "phx_error",
    join: "phx_join",
    reply: "phx_reply",
    leave: "phx_leave"
  };
  _exports.CHANNEL_EVENTS = CHANNEL_EVENTS;
  const TRANSPORTS = {
    longpoll: "longpoll",
    websocket: "websocket"
  };
  _exports.TRANSPORTS = TRANSPORTS;
  const XHR_STATES = {
    complete: 4
  };
  _exports.XHR_STATES = XHR_STATES;
});