define("cc-frontend/helpers/find-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Returns a computed property that returns a promise proxy object containing the requested model
   * (specified in the second parameter) in the JSON:API using the path parameter
   *
   * @param  {String} path - the path to id of the desired object
   * @param  {String} model - name of the collection to look under in the JSON:API
   * @return {PromiseProxyObject} CP returns a  a promise proxy object containing the requested model
   * in the content property when fulfilled. Once resolved (either via the store and the memoryEngine or an ajax call),
   * content property will point to the requested object.
   */
  function _default(path, model) {
    /**
     Example:
       ```javascript
      class exampleComponent extends Component {
        cardStack = { id: 1}
        @findDocument("cardStack.id", "card-stack") lesson
      }
      ```
      */
    return Ember.computed(path, function () {
      //makes ObjectProxy promise aware
      //objectProxy is why we have to call .content on these returned CPs in components
      //forwards all properties not defined by the proxy itself to a proxied content object
      //extend creates a new subclass that will use PromiseProxyMixin as values for new class
      const ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin); // gets the value of a property (id) on the object (this function)

      let id = Ember.get(this, path); //makes a new promise, if id is null, resolve with null
      //if not, go to the store and use the find method
      // return the Object with the resolved promise inside

      let promise = id === null || id === undefined ? new Ember.RSVP.Promise((resolve, _reject) => resolve(null)) : Ember.get(this, "store").find(model, id); // if (id === null) return {}
      // let promise = get(this, 'store').find(model, id)
      // makes new object containing resolved promise

      return ObjectPromiseProxy.create({
        promise: promise
      });
    });
  }
});