define("cc-frontend/components/icons/pencil-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path fill="#ffce31" d="M7.934 41.132L39.828 9.246l14.918 14.922-31.895 31.886z"></path>
    <path
      fill="#ed4c5c"
      d="M61.3 4.6l-1.9-1.9C55.8-.9 50-.9 46.3 2.7l-6.5 6.5 15 15 6.5-6.5c3.6-3.6 3.6-9.5 0-13.1"
    ></path>
    <path fill="#93a2aa" d="M35.782 13.31l4.1-4.102 14.92 14.92-4.1 4.101z"></path>
    <path fill="#c7d3d8" d="M37.338 14.865l4.1-4.101 11.739 11.738-4.102 4.1z"></path>
    <path fill="#fed0ac" d="M7.9 41.1l-6.5 17 4.5 4.5 17-6.5z"></path>
    <path fill="#333" d="M.3 61.1c-.9 2.4.3 3.5 2.7 2.6l8.2-3.1-7.7-7.7-3.2 8.2"></path>
    <path fill="#ffdf85" d="M7.89 41.175l27.86-27.86 4.95 4.95-27.86 27.86z"></path>
    <path fill="#ff8736" d="M17.904 51.142l27.86-27.86 4.95 4.95-27.86 27.86z"></path>
  </svg>
  */
  {
    "id": "kev6OIss",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 64 64\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#ffce31\"],[14,\"d\",\"M7.934 41.132L39.828 9.246l14.918 14.922-31.895 31.886z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#ed4c5c\"],[14,\"d\",\"M61.3 4.6l-1.9-1.9C55.8-.9 50-.9 46.3 2.7l-6.5 6.5 15 15 6.5-6.5c3.6-3.6 3.6-9.5 0-13.1\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#93a2aa\"],[14,\"d\",\"M35.782 13.31l4.1-4.102 14.92 14.92-4.1 4.101z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#c7d3d8\"],[14,\"d\",\"M37.338 14.865l4.1-4.101 11.739 11.738-4.102 4.1z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#fed0ac\"],[14,\"d\",\"M7.9 41.1l-6.5 17 4.5 4.5 17-6.5z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#333\"],[14,\"d\",\"M.3 61.1c-.9 2.4.3 3.5 2.7 2.6l8.2-3.1-7.7-7.7-3.2 8.2\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#ffdf85\"],[14,\"d\",\"M7.89 41.175l27.86-27.86 4.95 4.95-27.86 27.86z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#ff8736\"],[14,\"d\",\"M17.904 51.142l27.86-27.86 4.95 4.95-27.86 27.86z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/pencil-color.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});