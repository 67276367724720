define("cc-frontend/lib/actions/course/calendar/DELETE_UNIT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "lodash"], function (_exports, _patchUpdater, _unitUtils, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareDeleteUnit = prepareDeleteUnit;
  _exports.default = _exports.DELETE_UNIT = void 0;

  function prepareDeleteUnit(payload) {
    return {
      name: DELETE_UNIT.name,
      payload: payload,
      patches: DELETE_UNIT.patches(payload),
      undoPatches: DELETE_UNIT.undoPatches(payload),
      narrative: (0, _lodash.partial)(DELETE_UNIT.narrative, payload)
    };
  }

  const DELETE_UNIT = {
    name: "DELETE_UNIT",

    patches(payload) {
      let newCourseDates = _lodash.default.map(payload.course.attributes.calendar.dates, courseDate => {
        let cd = courseDate;

        if (courseDate.type === "course-date-custom") {
          if (_lodash.default.includes(courseDate.attributes.unitStart, payload.unitId)) {
            cd = _lodash.default.cloneDeep(cd); // @ts-ignore

            _lodash.default.pull(cd.attributes.unitStart, payload.unitId);
          }

          if (_lodash.default.includes(courseDate.attributes.unitEnd, payload.unitId)) {
            cd = _lodash.default.cloneDeep(cd); // @ts-ignore

            _lodash.default.pull(cd.attributes.unitEnd, payload.unitId);
          }

          if (payload.shouldDeleteLessons && _lodash.default.includes(courseDate.attributes.unitIds, payload.unitId)) {
            cd = _lodash.default.cloneDeep(cd);
            cd.attributes.cardStackId = null;
          }
        }

        return cd;
      });

      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let {
        courseDates,
        units
      } = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, payload.rotationCalendar.attributes.schoolDays);
      return _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).wrapInArray;
    },

    undoPatches(payload) {
      return _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).wrapInArray;
    },

    narrative(payload, findFn, userId) {
      let models = {
        course: payload.course,
        planbook: payload.planbook,
        unit: findFn("card-stack", payload.unitId) || findFn("card-stack-summary", payload.unitId)
      };
      return {
        title: "Deleted Unit",
        titleForAnalytics: "Unit - Remove",
        description: `Deleted "${models.unit.attributes.title}"`,
        context: {
          unitTitle: models.unit.attributes.title,
          courseTitle: models.course.attributes.title,
          planbookTitle: models.planbook.attributes.title,
          unitId: models.unit.id,
          courseId: models.course.id,
          planbookId: models.planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${models.course.id}`,
          to: [`course-edits:course-${models.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        }
      };
    }

  };
  _exports.DELETE_UNIT = DELETE_UNIT;
  var _default = DELETE_UNIT;
  _exports.default = _default;
});