define("cc-frontend/components/planner/menus/share-link/cannot-share-body", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="g-dropdown-menu__header" data-test-link-share-menu>
    You can’t share a lesson you haven’t written!
  </div>
  <div class="g-dropdown-menu__subheader">
    Edit this lesson and then share it.
  </div>
  <div class="tw-flex tw-flex-col">
    <Planner::Menus::Parts::OutlineButton @onClick={{fn @confirmButton}}>
      Okay
    </Planner::Menus::Parts::OutlineButton>
  </div>
  */
  {
    "id": "SUjc33c1",
    "block": "[[[10,0],[14,0,\"g-dropdown-menu__header\"],[14,\"data-test-link-share-menu\",\"\"],[12],[1,\"\\n  You can’t share a lesson you haven’t written!\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"g-dropdown-menu__subheader\"],[12],[1,\"\\n  Edit this lesson and then share it.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"tw-flex tw-flex-col\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@onClick\"],[[28,[37,1],[[30,1]],null]]],[[\"default\"],[[[[1,\"\\n    Okay\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@confirmButton\"],false,[\"planner/menus/parts/outline-button\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/menus/share-link/cannot-share-body.hbs",
    "isStrictMode": false
  });

  let CannotShareBodyComponent = (_dec = (0, _component.tagName)(""), _dec(_class = class CannotShareBodyComponent extends Ember.Component {}) || _class);
  _exports.default = CannotShareBodyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CannotShareBodyComponent);
});