define("cc-frontend/components/attachment-preview-modal", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <XModalDialog @translucentOverlay={{true}}>
    <span class="btn-exit-modal-string test-close-lesson-modal" {{on "click" @closeModal}}>
      Close
      <span class="close-modal-times">
        &times;
      </span>
    </span>
    <div class="modal is-visible js-modal">
      <div class="modal-dialog modal-dialog--attachment-preview">
        <div class="modal-content">
          <div class="attachment-preview">
            <div class="attachment-preview__download-button">
              {{partial "icons/ios-cloud-download-outline"}}
              Download
              {{@model.attachmentTitle}}
            </div>
            {{#if this.isFilepickerUrl}}
              <div class="attachment-preview__viewer--iframe">
                <iframe
                  title="File Preview {{@model.attachmentId}}"
                  src={{concat "https://cdn.filestackcontent.com/api/preview/" @model.attachmentId}}
                ></iframe>
              </div>
            {{else}}
              <div class="attachment-preview__viewer--image">
                <img
                  style="width: 100%"
                  src={{concat
                    "https://process.filestackapi.com/AcTuk5i2R9OYgN972g8oBz/output=f:jpg,d:130,p:1/"
                    @model.attachmentUrl
                  }}
                />
              </div>
            {{/if}}
          </div>
        </div>
      </div>
    </div>
  </XModalDialog>
  */
  {
    "id": "eTYxbWTV",
    "block": "[[[8,[39,0],null,[[\"@translucentOverlay\"],[true]],[[\"default\"],[[[[1,\"\\n  \"],[11,1],[24,0,\"btn-exit-modal-string test-close-lesson-modal\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n    Close\\n    \"],[10,1],[14,0,\"close-modal-times\"],[12],[1,\"\\n      ×\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"modal is-visible js-modal\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-dialog modal-dialog--attachment-preview\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"modal-content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"attachment-preview\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"attachment-preview__download-button\"],[12],[1,\"\\n            \"],[19,\"icons/ios-cloud-download-outline\",[]],[1,\"\\n            Download\\n            \"],[1,[30,2,[\"attachmentTitle\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"],[41,[30,0,[\"isFilepickerUrl\"]],[[[1,\"            \"],[10,0],[14,0,\"attachment-preview__viewer--iframe\"],[12],[1,\"\\n              \"],[10,\"iframe\"],[15,\"title\",[29,[\"File Preview \",[30,2,[\"attachmentId\"]]]]],[15,\"src\",[28,[37,4],[\"https://cdn.filestackcontent.com/api/preview/\",[30,2,[\"attachmentId\"]]],null]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,0],[14,0,\"attachment-preview__viewer--image\"],[12],[1,\"\\n              \"],[10,\"img\"],[14,5,\"width: 100%\"],[15,\"src\",[28,[37,4],[\"https://process.filestackapi.com/AcTuk5i2R9OYgN972g8oBz/output=f:jpg,d:130,p:1/\",[30,2,[\"attachmentUrl\"]]],null]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"@model\"],true,[\"x-modal-dialog\",\"on\",\"partial\",\"if\",\"concat\"]]",
    "moduleName": "cc-frontend/components/attachment-preview-modal.hbs",
    "isStrictMode": false
  });

  class AttachmentPreviewModal extends _glimmerComponent.default {
    get isFilepickerUrl() {
      let url = this.args.model.attachmentUrl;
      return url.match("filepicker") !== null || url.match("filestack") !== null;
    }

  }

  _exports.default = AttachmentPreviewModal;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AttachmentPreviewModal);
});