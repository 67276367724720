define("cc-frontend/components/icons/add-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    <g>
      <path
        d="M1.5,18c-0.827,0 -1.5,-0.673 -1.5,-1.5v-15c0,-0.827 0.673,-1.5 1.5,-1.5h5.382c0.572,0 1.086,0.318 1.341,0.829l0.948,1.894c0.085,0.171 0.257,0.277 0.447,0.277h9.882c0.827,0 1.5,0.673 1.5,1.5v4c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-4c0,-0.276 -0.224,-0.5 -0.5,-0.5h-9.882c-0.572,0 -1.086,-0.318 -1.341,-0.829l-0.948,-1.894c-0.085,-0.171 -0.257,-0.277 -0.447,-0.277h-5.382c-0.276,0 -0.5,0.224 -0.5,0.5v15c0,0.276 0.224,0.5 0.5,0.5h7c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-7Z"
      ></path>
      <path
        d="M17.5,24c-3.584,0 -6.5,-2.916 -6.5,-6.5c0,-3.584 2.916,-6.5 6.5,-6.5c3.584,0 6.5,2.916 6.5,6.5c0,3.584 -2.916,6.5 -6.5,6.5Zm0,-12c-3.033,0 -5.5,2.467 -5.5,5.5c0,3.033 2.467,5.5 5.5,5.5c3.033,0 5.5,-2.467 5.5,-5.5c0,-3.033 -2.467,-5.5 -5.5,-5.5Z"
      ></path>
      <path
        d="M17.5,21c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.5h-2.5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h2.5v-2.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v2.5h2.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-2.5v2.5c0,0.276 -0.224,0.5 -0.5,0.5Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "RHbxuLnv",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M1.5,18c-0.827,0 -1.5,-0.673 -1.5,-1.5v-15c0,-0.827 0.673,-1.5 1.5,-1.5h5.382c0.572,0 1.086,0.318 1.341,0.829l0.948,1.894c0.085,0.171 0.257,0.277 0.447,0.277h9.882c0.827,0 1.5,0.673 1.5,1.5v4c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-4c0,-0.276 -0.224,-0.5 -0.5,-0.5h-9.882c-0.572,0 -1.086,-0.318 -1.341,-0.829l-0.948,-1.894c-0.085,-0.171 -0.257,-0.277 -0.447,-0.277h-5.382c-0.276,0 -0.5,0.224 -0.5,0.5v15c0,0.276 0.224,0.5 0.5,0.5h7c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-7Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M17.5,24c-3.584,0 -6.5,-2.916 -6.5,-6.5c0,-3.584 2.916,-6.5 6.5,-6.5c3.584,0 6.5,2.916 6.5,6.5c0,3.584 -2.916,6.5 -6.5,6.5Zm0,-12c-3.033,0 -5.5,2.467 -5.5,5.5c0,3.033 2.467,5.5 5.5,5.5c3.033,0 5.5,-2.467 5.5,-5.5c0,-3.033 -2.467,-5.5 -5.5,-5.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M17.5,21c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.5h-2.5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h2.5v-2.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v2.5h2.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-2.5v2.5c0,0.276 -0.224,0.5 -0.5,0.5Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/add-folder.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});