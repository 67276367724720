define("cc-frontend/components/planner/menus/day-menu/confirm-rotation-reset/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @isDelete}}
    <Planner::Menus::Titles::WithBackArrow
      @title="Are you sure you want to remove this?"
      @goBack={{@goBack}}
    />
  
  {{else}}
    {{#if (eq @additionType "SKIP")}}
      <Planner::Menus::Titles::WithBackArrow
        @title="Are you sure you want to skip this day?"
        @goBack={{@goBack}}
      />
    {{/if}}
    {{#if (eq @additionType "RESTART")}}
      <Planner::Menus::Titles::WithBackArrow
        @title="Are you sure you want to restart the rotation cycle?"
        @goBack={{@goBack}}
      />
    {{/if}}
  {{/if}}
  */
  {
    "id": "2sbvX+AQ",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@title\",\"@goBack\"],[\"Are you sure you want to remove this?\",[30,2]]],null],[1,\"\\n\\n\"]],[]],[[[41,[28,[37,2],[[30,3],\"SKIP\"],null],[[[1,\"    \"],[8,[39,1],null,[[\"@title\",\"@goBack\"],[\"Are you sure you want to skip this day?\",[30,2]]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[[30,3],\"RESTART\"],null],[[[1,\"    \"],[8,[39,1],null,[[\"@title\",\"@goBack\"],[\"Are you sure you want to restart the rotation cycle?\",[30,2]]],null],[1,\"\\n\"]],[]],null]],[]]]],[\"@isDelete\",\"@goBack\",\"@additionType\"],false,[\"if\",\"planner/menus/titles/with-back-arrow\",\"eq\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/confirm-rotation-reset/title.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});