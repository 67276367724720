define("cc-frontend/templates/admin/course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6nGN3/ZV",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[[33,2,[\"attributes\",\"title\"]],\" Course\"],null]],null]],[1,\"\\n\"],[8,[39,3],null,[[\"@model\",\"@sidebarComponent\",\"@detailComponent\"],[[36,2],\"admin/course/sidebar\",\"admin/course/detail\"]],null]],[],false,[\"page-title\",\"concat\",\"model\",\"admin/skeleton\"]]",
    "moduleName": "cc-frontend/templates/admin/course.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});