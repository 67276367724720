define("cc-frontend/components/planner/card-stacks/cards/parts/is-public-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <!-- Is Public Notice -->
  {{#if @card.attributes.isPublic}}
    <div class="lesson-section-public-notice">
      <Icons::IosGlobe />
      Visible on class website
    </div>
  {{/if}}
  */
  {
    "id": "PguU34GH",
    "block": "[[[3,\" Is Public Notice \"],[1,\"\\n\"],[41,[30,1,[\"attributes\",\"isPublic\"]],[[[1,\"  \"],[10,0],[14,0,\"lesson-section-public-notice\"],[12],[1,\"\\n    \"],[8,[39,1],null,null,null],[1,\"\\n    Visible on class website\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@card\"],false,[\"if\",\"icons/ios-globe\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/parts/is-public-notice.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});