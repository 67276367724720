define("cc-frontend/components/dialogs/ask-print-or-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-question" style="display: block;">
    ?
  </div>
  <h1 class="create-annotation__title">
    Would you like to print or download your standards?
  </h1>
  <h2 class="create-annotation__description"></h2>
  <div class="create-annotation__submit-buttons">
    <div
      class="create-annotation__submit-button create-annotation__submit-button--deemphasized"
      {{action @accept "print"}}
    >
      <span>
        Print
      </span>
    </div>
    <div class="create-annotation__submit-button" {{action @accept "download"}}>
      <span>
        Download
      </span>
    </div>
  </div>
  <div class="create-annotation__cancel-button-wrapper">
    <div class="btn btn-link create-annotation__cancel-button" {{action @decline}}>
      Cancel
    </div>
  </div>
  */
  {
    "id": "87qDrlYZ",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-question\"],[14,5,\"display: block;\"],[12],[1,\"\\n  ?\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Would you like to print or download your standards?\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button create-annotation__submit-button--deemphasized\"],[4,[38,0],[[30,0],[30,1],\"print\"],null],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      Print\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[[30,0],[30,1],\"download\"],null],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      Download\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__cancel-button-wrapper\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"btn btn-link create-annotation__cancel-button\"],[4,[38,0],[[30,0],[30,2]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\",\"@decline\"],false,[\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/ask-print-or-download.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});