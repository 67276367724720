define("cc-frontend/services/filestack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Filestack extends Ember.Service {
    constructor() {
      super(...arguments); // @ts-ignore

      this.client = filestack.init("AcTuk5i2R9OYgN972g8oBz");
    }

  }

  _exports.default = Filestack;
});