define("cc-frontend/components/planner/menus/standards-tracker-menu", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DS::DropdownMenu @iconColumns="2">
    <div class="g-dropdown-menu__title">
      Tracker Menu
    </div>
    <Planner::Menus::Parts::ShareLink
      @planbook={{@planbook}}
      @date={{@startDate}}
      @perspective="standards-tracker"
      @closeMenu={{@closeMenu}}
      @classNames="tw-py-4"
    >
      <DS::DropdownMenu::SingleButtonRow @iconName="icons/link-simple">
        Link
      </DS::DropdownMenu::SingleButtonRow>
    </Planner::Menus::Parts::ShareLink>
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/print-text"
      @clickAction={{this.printOrDownload}}
    >
      Print or Download
    </DS::DropdownMenu::SingleButtonRow>
  </DS::DropdownMenu>
  */
  {
    "id": "lVF7Fm9i",
    "block": "[[[8,[39,0],null,[[\"@iconColumns\"],[\"2\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"g-dropdown-menu__title\"],[12],[1,\"\\n    Tracker Menu\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@planbook\",\"@date\",\"@perspective\",\"@closeMenu\",\"@classNames\"],[[30,1],[30,2],\"standards-tracker\",[30,3],\"tw-py-4\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@iconName\"],[\"icons/link-simple\"]],[[\"default\"],[[[[1,\"\\n      Link\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,2],null,[[\"@iconName\",\"@clickAction\"],[\"icons/print-text\",[30,0,[\"printOrDownload\"]]]],[[\"default\"],[[[[1,\"\\n    Print or Download\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@planbook\",\"@startDate\",\"@closeMenu\"],false,[\"d-s/dropdown-menu\",\"planner/menus/parts/share-link\",\"d-s/dropdown-menu/single-button-row\"]]",
    "moduleName": "cc-frontend/components/planner/menus/standards-tracker-menu.hbs",
    "isStrictMode": false
  });

  let StandardsTrackerMenuComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec(_class = (_class2 = class StandardsTrackerMenuComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "courses", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "modalManager", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "modalManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = StandardsTrackerMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, StandardsTrackerMenuComponent);
});