define("cc-frontend/templates/admin/impersonate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/bERQ0TD",
    "block": "[[[41,[30,0,[\"session\",\"isImpersonating\"]],[[[1,\"  \"],[10,\"h1\"],[12],[1,\"\\n\"],[41,[30,0,[\"session\",\"userId\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"user.show\",[30,0,[\"session\",\"userId\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"session\",\"user\",\"attributes\",\"firstName\"]]],[1,\"\\n        \"],[1,[30,0,[\"session\",\"user\",\"attributes\",\"lastName\"]]],[1,\"\\n        \"],[1,[30,0,[\"session\",\"user\",\"attributes\",\"email\"]]],[1,\"\\n        Page\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-lg btn-primary\"],[4,[38,2],[[30,0],\"stopImpersonating\"],null],[12],[1,\"\\n    Stop Impersonating\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"session\",\"user\",\"attributes\",\"isSupport\"]],[[[1,\"  \"],[10,\"form\"],[14,0,\"form\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      Email:\\n      \"],[8,[39,3],[[24,0,\"form-control\"]],[[\"@value\"],[[36,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-lg btn-primary\"],[4,[38,2],[[30,0],\"startImpersonatingByEmail\"],null],[12],[1,\"\\n      Start Impersonating By Email\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"form\"],[14,0,\"form\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      User Id:\\n      \"],[8,[39,3],[[24,0,\"form-control\"]],[[\"@value\"],[[36,5]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-lg btn-primary\"],[4,[38,2],[[30,0],\"startImpersonatingByUserId\"],null],[12],[1,\"\\n      Start Impersonating By UserId\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  Must be logged into a support account.\\n\"]],[]]]],[]]]],[],false,[\"if\",\"link-to\",\"action\",\"input\",\"impersonatingEmail\",\"impersonatingUserId\"]]",
    "moduleName": "cc-frontend/templates/admin/impersonate.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});