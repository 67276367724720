define("cc-frontend/components/planner/menus/planbook-menu/confirm-planbook-delete/body", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-mx-8 tw-items-stretch tw-flex tw-flex-col">
    <Planner::Menus::Parts::OutlineButton
      @onClick={{this.deletePlanbook}}
      @noHorizontalMargin={{true}}
    >
      Yes, Delete the Planbook
    </Planner::Menus::Parts::OutlineButton>
    <Planner::Menus::Parts::OutlineButton @onClick={{@goBack}} @noHorizontalMargin={{true}}>
      No, Keep the planbook
    </Planner::Menus::Parts::OutlineButton>
  </div>
  */
  {
    "id": "sP+RLOSC",
    "block": "[[[10,0],[14,0,\"tw-mx-8 tw-items-stretch tw-flex tw-flex-col\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@onClick\",\"@noHorizontalMargin\"],[[30,0,[\"deletePlanbook\"]],true]],[[\"default\"],[[[[1,\"\\n    Yes, Delete the Planbook\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,0],null,[[\"@onClick\",\"@noHorizontalMargin\"],[[30,1],true]],[[\"default\"],[[[[1,\"\\n    No, Keep the planbook\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@goBack\"],false,[\"planner/menus/parts/outline-button\"]]",
    "moduleName": "cc-frontend/components/planner/menus/planbook-menu/confirm-planbook-delete/body.hbs",
    "isStrictMode": false
  });

  let PlanbookMenuEditTitleBodyComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec(_class = (_class2 = class PlanbookMenuEditTitleBodyComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "planbook", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);
    }

    deletePlanbook() {
      this.rpc.execute("PLANBOOK_DELETE", this.planbook.id).then(() => {
        window.location.href = "/users/" + this.planbook.relationships.owner.data.id; // Ember.getOwner(this).lookup('router:main').transitionTo('user.show.index', get(this, "owner.id"))
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "rpc", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "deletePlanbook", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "deletePlanbook"), _class2.prototype)), _class2)) || _class);
  _exports.default = PlanbookMenuEditTitleBodyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookMenuEditTitleBodyComponent);
});