define("cc-frontend/components/planner/standards-tracker/redirect-to-setup.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "planbook-setup-notice": "_planbook-setup-notice_10z753",
    "planbook-setup-notice__headline": "_planbook-setup-notice__headline_10z753",
    "planbook-setup-notice__description": "_planbook-setup-notice__description_10z753",
    "add-button": "_add-button_10z753"
  };
  _exports.default = _default;
});