define("cc-frontend/components/d-s/dropdown-menu/icon-row/item.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "icon-row__item": "_icon-row__item_zbv1xt",
    "icon-row__item__text": "_icon-row__item__text_zbv1xt",
    "icon-row__item__icon": "_icon-row__item__icon_zbv1xt"
  };
  _exports.default = _default;
});