define("cc-frontend/helpers/kb-link-id", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.kbLinkId = kbLinkId;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function kbLinkId(params) {
    let title = _lodash.default.kebabCase(params[0]);

    let id = params[1];
    return `${title}-${id}`;
  }

  class KbLinkId extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "compute", kbLinkId);
    }

  }

  _exports.default = KbLinkId;
});