define("cc-frontend/utils/filter-dates/rotation-calendar", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterForRelevantDates = filterForRelevantDates;
  _exports.findRotationDate = findRotationDate;
  _exports.pluckNewRotationIds = pluckNewRotationIds;

  function filterForRelevantDates(rotationCalendar, effectiveDate, shouldFilterForRotationIds, includedRotationIds) {
    return (0, _lodashEs.sortBy)((0, _lodashEs.filter)(rotationCalendar.attributes.dates, date => date.attributes.dateString >= effectiveDate && (shouldFilterForRotationIds ? (0, _lodashEs.includes)(includedRotationIds, date.attributes.rotationId) : true)), date => date.attributes.dateString);
  }

  function findRotationDate(rotationCalendar, dateString) {
    return (0, _lodashEs.find)(rotationCalendar.attributes.dates, date => date.attributes.dateString === dateString);
  }
  /**
   * Generate the IDs of Rotations we don't have yet in this course/planbook yet
   */


  function pluckNewRotationIds(sourceRotationCalendar, defaultDates) {
    return (0, _lodashEs.difference)((0, _lodashEs.map)(sourceRotationCalendar.attributes.rotations, rotation => rotation.id), // source rotation ids
    (0, _lodashEs.map)(defaultDates, date => date.attributes.rotationId) // existingRotatinIds
    );
  }
});