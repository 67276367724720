define("cc-frontend/components/bopit/body", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let BopitPopoverBody = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{#if @isOpen}}
      <Bopit::TetheredBody
        class={{@bodyClasses}}
        @parent={{this}}
        @target={{concat "#" @anchorElementId}}
        @attachment={{@attachment}}
        @targetAttachment={{@targetAttachment}}
        @classPrefix={{or classPrefix "ember-tether"}}
        @isOpen={{@isOpen}}
        @offset={{@offset}}
        @isOpenClass={{@isOpenClass}}
        @afterOpenClass={{@afterOpenClass}}
        @clickedOutside={{@clickedOutside}}
        @isInteractive={{@isInteractive}}
        @mouseHasEntered={{@mouseHasEntered}}
        @mouseHasLeft={{@mouseHasLeft}}>
        {{yield}}
      </Bopit::TetheredBody>
    {{/if}}
  
  */
  {
    "id": "LToh6UHt",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,1],[[16,0,[30,2]]],[[\"@parent\",\"@target\",\"@attachment\",\"@targetAttachment\",\"@classPrefix\",\"@isOpen\",\"@offset\",\"@isOpenClass\",\"@afterOpenClass\",\"@clickedOutside\",\"@isInteractive\",\"@mouseHasEntered\",\"@mouseHasLeft\"],[[30,0],[28,[37,2],[\"#\",[30,3]],null],[30,4],[30,5],[28,[37,3],[[33,4],\"ember-tether\"],null],[30,1],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12]]],[[\"default\"],[[[[1,\"\\n      \"],[18,13,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@isOpen\",\"@bodyClasses\",\"@anchorElementId\",\"@attachment\",\"@targetAttachment\",\"@offset\",\"@isOpenClass\",\"@afterOpenClass\",\"@clickedOutside\",\"@isInteractive\",\"@mouseHasEntered\",\"@mouseHasLeft\",\"&default\"],false,[\"if\",\"bopit/tethered-body\",\"concat\",\"or\",\"classPrefix\",\"yield\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec(_class = class BopitPopoverBody extends Ember.Component {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);

      if (Ember.get(this, "isOpen") === true) {
        Ember.set(this, "showTetheredBody", true);
      }

      if (Ember.get(this, "isOpen") === false) {
        // if (get(this, 'noAnimation') === true) {
        Ember.set(this, "showTetheredBody", false); // } else {
        //   Ember.run.later(() => {
        //     if (this.isDestroyed || this.isDestroying) return;
        //     if (get(this, 'isOpen') === true) return;
        //     set(this, 'showTetheredBody', false)
        //   }, 300)
        // }
      }
    }

  }) || _class);
  _exports.default = BopitPopoverBody;
});