define("cc-frontend/services/course-calendar-editor", ["exports", "cc-frontend/app", "cc-frontend/lib/actions/course/calendar/COURSE_DATE_BUMP_LESSON", "cc-frontend/lib/actions/course/calendar/MOVE_LESSON_BY_AMOUNT", "cc-frontend/lib/actions/course/calendar/MOVE_MULTIPLE_LESSONS", "cc-frontend/lib/actions/course/calendar/PULL_MULTIPLE_LESSONS_BACKWARDS", "cc-frontend/lib/actions/course/calendar/TURN_DATE_OFF_AND", "cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_DELETE_MULTIPLE", "cc-frontend/lib/actions/lesson/LESSON_ERASE", "cc-frontend/lib/actions/lesson/PULL_LESSON_BACKWARDS", "cc-frontend/lib/find-course-date", "cc-frontend/lib/object-id-gen", "cc-frontend/models/course-calendar-date-custom", "lodash", "lodash-es", "cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_CREATE", "cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_DELETE", "cc-frontend/lib/actions/course/date/COURSE_DATE_FORCE_ON", "cc-frontend/lib/actions/course/date/COURSE_DATE_UNFORCE_ON", "cc-frontend/models/date-annotation"], function (_exports, _app, _COURSE_DATE_BUMP_LESSON, _MOVE_LESSON_BY_AMOUNT, _MOVE_MULTIPLE_LESSONS, _PULL_MULTIPLE_LESSONS_BACKWARDS, _TURN_DATE_OFF_AND, _COURSE_DATE_ANNOTATION_DELETE_MULTIPLE, _LESSON_ERASE, _PULL_LESSON_BACKWARDS, _findCourseDate, _objectIdGen, _courseCalendarDateCustom, _lodash, _lodashEs, _COURSE_DATE_ANNOTATION_CREATE, _COURSE_DATE_ANNOTATION_DELETE, _COURSE_DATE_FORCE_ON, _COURSE_DATE_UNFORCE_ON, _dateAnnotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CourseCalendarEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class CourseCalendarEditor extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "dialog", _descriptor2, this);
    }

    /*-------------------------------------------------------------------------------------------------------
    Toggle Course On/Off
    --------------------------------------------------------------------------------------------------------*/
    toggleDefaultDate(course, rotationCalendar, rotation, semester, isOn) {
      if (isOn) {
        this._turnOffDefault(course, rotation, semester);
      } else {
        this._turnOnDefault(course, rotationCalendar, rotation, semester);
      }
    }

    toggleCustomDate(course, dateString, courseDateIsOff, courseDateIsForcedOn) {
      if (courseDateIsOff) {
        this._turnCourseOn(course, dateString, courseDateIsForcedOn);
      } else {
        this._turnCourseOff(course, dateString, courseDateIsForcedOn);
      }
    }

    turnOffAndBumpDeleteOrNothing({
      dateString,
      cardStackId,
      course,
      planbook,
      rotationCalendar,
      dateAnnotation,
      bumpOrDelete
    }) {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, dateString);
      let dateIsForcedOn = _lodash.default.includes(course.attributes.calendar.datesForcedOn, dateString) || courseDate && courseDate.attributes.isForcedOn; // check if it's forced on

      let unforceOnAction = dateIsForcedOn ? (0, _COURSE_DATE_UNFORCE_ON.prepareUnforceOn)({
        dateString: dateString,
        course: course
      }) : null; // bump patches -- this needs to be renamed

      let bumpOrDeleteAction;

      if (bumpOrDelete === "BUMP") {
        bumpOrDeleteAction = (0, _COURSE_DATE_BUMP_LESSON.prepareBumpLesson)({
          dateString: dateString,
          course: course,
          planbook: planbook,
          rotationCalendar: rotationCalendar
        });
      } else if (bumpOrDelete === "DELETE") {
        bumpOrDeleteAction = (0, _LESSON_ERASE.prepareLessonErase)({
          course: course,
          dateString: dateString,
          cardStackId: cardStackId
        });
      }

      let unforceOnPatches = unforceOnAction && unforceOnAction.patches ? unforceOnAction.patches : [];
      let unforceOnUndoPatches = unforceOnAction && unforceOnAction.undoPatches ? unforceOnAction.undoPatches : [];
      let bumpOrDeletePatches = bumpOrDeleteAction && bumpOrDeleteAction.patches ? bumpOrDeleteAction.patches : [];
      let bumpOrDeleteUndoPatches = bumpOrDeleteAction && bumpOrDeleteAction.undoPatches ? bumpOrDeleteAction.undoPatches : []; // turn off patches

      this.store.dispatchPreparedAction((0, _TURN_DATE_OFF_AND.default)({
        dateAnnotation: dateAnnotation,
        course: course,
        dateString: dateString,
        bumpOrDelete: bumpOrDelete,
        bumpOrDeletePatches: unforceOnPatches.concat(bumpOrDeletePatches),
        bumpOrDeleteUndoPatches: unforceOnUndoPatches.concat(bumpOrDeleteUndoPatches)
      }));
    }
    /**
    -------------------------------------------------------------------------------------------------------
    Bumping Lessons
    --------------------------------------------------------------------------------------------------------
    */

    /**
     * This will push a single lesson forward a specified amount of days.
     * Can be used anywhere the user desires to move a lesson forward. For example, on the lesson-menu
     * This shifts all cardStackIds in the course.attributes.calendar.dates down
     a day, skipping over days turned off.
     */


    bumpLesson(course, dateString, planbook, rotationCalendar, amount) {
      this.store.dispatchPreparedAction((0, _MOVE_LESSON_BY_AMOUNT.prepareBumpLessonForward)({
        course: course,
        dateString: dateString,
        planbook: planbook,
        rotationCalendar: rotationCalendar,
        amount: amount
      }));
    }
    /**
     * This pushes all lessons forward by a specified amount
     * Used as a button on the day-menu, but can be used anywhere the user desires to move lessons forward
     * It iterates through each course and then uses the same action that's used for bumping a single lesson
     */


    bumpDayForward(dateString, courses, planbook, rotationCalendar, amount) {
      this.store.dispatchPreparedAction((0, _MOVE_MULTIPLE_LESSONS.prepareMoveMultipleLessons)({
        dateString: dateString,
        courses: courses,
        planbook: planbook,
        rotationCalendar: rotationCalendar,
        amount: amount
      }));
    }
    /*-------------------------------------------------------------------------------------------------------
    Pull Lesson Back
    --------------------------------------------------------------------------------------------------------*/

    /**
     * Check if the coursedate has a lesson.
     */


    doesCourseDateHaveLesson(course, dateString) {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, dateString); // IF there's not course Date, there's definitely no lessons

      if (courseDate === undefined) return false; // If the cardStackId is not a string, it means there's no cardStack which means means no lesson

      return courseDate.attributes.cardStackId !== null;
    }
    /**
     * Check if the courseDate has a unit that starts and ends on the same day
     */


    doesCourseDateHaveTinyUnit(course, dateString) {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, dateString); //if there's not a courseDate there's definitely no unit

      if (courseDate === undefined) return false;

      let hasAOneDayUnit = _lodash.default.size(_lodash.default.intersection(courseDate.attributes.unitStart, courseDate.attributes.unitEnd)); // if there is a small unit, the user must take action


      return hasAOneDayUnit > 0;
    }
    /**
     * This pulls a single lesson backward to desired date from which the action was called.
     * If there is a lesson on the target date the user wants to pull back to, this lesson is deleted - notify the user the lesson will be lost
     * Useful as a button on a menu, such as lesson-menu
     * This can't be executed if there is a unit that starts and ends on the SAME date - the user must manually go and remove it on their own
     * Before calling this method, we have to call `doesCourseDateHaveTinyUnit` to avoid error mentioned above
     */


    pullLessonBackwards(dateString, lessonTitle, course, planbook, rotationCalendar) {
      if (this.doesCourseDateHaveTinyUnit(course, dateString) === false) {
        this.store.dispatchPreparedAction((0, _PULL_LESSON_BACKWARDS.preparePullLessonBackwards)({
          dateString: dateString,
          course: course,
          planbook: planbook,
          rotationCalendar: rotationCalendar
        }));
      }
    }
    /**
     * This pulls all lessons backward to desired date from which the action was called
     * It's useful as a button on a menu, such as day-menu
     * Iterates through each lesson and pulls it back, using the code that pulls back an individual lesson
     * If there are lessons on the target date the user wants to pull back to, these lessons are deleted - notify the user the lessons will be lost
     * This cannot be executed if a unit starts or ends on target date - user must delete unit first.
     * We check for a unit on pullLessonBackwards to avoid errors
     */


    pullMultipleLessonsBackwards(dateString, courses, planbook, rotationCalendar) {
      this.store.dispatchPreparedAction((0, _PULL_MULTIPLE_LESSONS_BACKWARDS.preparePullMulitpleLessonsBackwards)({
        dateString: dateString,
        courses: courses,
        planbook: planbook,
        rotationCalendar: rotationCalendar
      }));
    } // if it's off b/c of a course date annotation, we need to remove the annotation
    // if it's off b/c of the defaults, we need to set isForcedOn


    _turnCourseOn(course, dateString, courseDateIsForcedOn) {
      let isOffFromCustom = (0, _lodashEs.includes)(course.attributes.calendar.datesOff, dateString) && courseDateIsForcedOn === false;

      if (isOffFromCustom) {
        let annotation = (0, _lodashEs.find)(course.attributes.calendar.dateAnnotations, ann => ann.attributes.dateString === dateString);
        this.store.dispatchAction(_COURSE_DATE_ANNOTATION_DELETE.COURSE_DATE_ANNOTATION_DELETE, {
          course: course,
          annotation: annotation,
          calledFrom: "day-menu-quick-toggle"
        });
      } else {
        let courseDate = (0, _lodash.chain)(course.attributes.calendar.dates).filter(date => date.type === "course-date-custom").find(date => date.attributes.dateString === dateString).value();
        let shouldAddCourseDate = Ember.isNone(courseDate);

        if (shouldAddCourseDate) {
          courseDate = {
            id: dateString,
            type: "course-date-custom",
            attributes: new _courseCalendarDateCustom.default({
              dateString: dateString,
              isForcedOn: true
            })
          };
        }

        this.store.dispatchAction(_COURSE_DATE_FORCE_ON.COURSE_DATE_FORCE_ON, {
          dateString: dateString,
          course: course,
          shouldAddCourseDate: shouldAddCourseDate,
          courseDateToAdd: courseDate
        });
      }
    } // if it's on b/c of a isForcedOn, we need to set that to false
    // if it's on b/c of a default, we need to create a date annotation


    _turnCourseOff(course, dateString, courseDateIsForcedOn) {
      if (courseDateIsForcedOn) {
        this.store.dispatchAction(_COURSE_DATE_UNFORCE_ON.COURSE_DATE_UNFORCE_ON, {
          dateString: dateString,
          course: course
        });
      } else {
        let annotation = new _dateAnnotation.default({
          id: _objectIdGen.default.create(),
          type: "date-annotation-course-custom-single",
          attributes: {
            dateString: dateString,
            showAnnotation: false,
            isOff: true
          }
        });
        this.store.dispatchAction(_COURSE_DATE_ANNOTATION_CREATE.COURSE_DATE_ANNOTATION_CREATE, {
          course: course,
          annotation: annotation,
          originalDatesOff: course.attributes.calendar.datesOff,
          source: "day-menu-quick-toggle"
        });
      }
    }

    _turnOffDefault(course, rotation, semester) {
      let rotationId = rotation.id;
      let rotationTitle = rotation.title;
      let semesterId = semester.id;
      let annotation = new _dateAnnotation.default({
        id: `${semesterId}:${rotationId}`,
        type: "date-annotation-course-default",
        attributes: {
          rotationId: rotationId,
          semesterId: semesterId,
          isOff: true
        }
      }); // dispatch annotation WITHOUT dates to force on

      this.store.dispatchAction(_COURSE_DATE_ANNOTATION_CREATE.COURSE_DATE_ANNOTATION_CREATE, {
        course: course,
        annotation: annotation,
        originalDatesOff: course.attributes.calendar.datesOff,
        calledFrom: "schedule-screen",
        rotationTitle: rotationTitle,
        datesForcedOn: null
      });
    }

    _turnOnDefault(course, rotationCalendar, rotation, semester) {
      let semesterId = semester.id;
      let rotationId = rotation.id;
      let courseDatesOff = course.attributes.calendar.datesOff;
      let foundDates = (0, _lodash.chain)(rotationCalendar.attributes.dates).filter(date => date.attributes.semesterId === semesterId && date.attributes.rotationId === rotationId).map(date => date.attributes.dateString).filter(dateString => (0, _lodashEs.includes)(courseDatesOff, dateString)).value();
      let formattedFoundDates = (0, _lodashEs.map)(foundDates, dateString => // @ts-ignore
      dateFns.format(dateString, "ddd, MMM D"));
      let hasDateAnnotation = (0, _lodashEs.find)(course.attributes.calendar.dateAnnotations, annotation => {
        return annotation.type === "date-annotation-course-default" && // @ts-ignore
        annotation.attributes.rotationId === rotation.id && // @ts-ignore
        annotation.attributes.semesterId === semester.id;
      });

      if (hasDateAnnotation === undefined) {
        let rotationId = rotation.id;
        let rotationTitle = rotation.title;
        let semesterId = semester.id;
        let annotation = new _dateAnnotation.default({
          id: `${semesterId}:${rotationId}`,
          type: "date-annotation-course-default",
          attributes: {
            rotationId: rotationId,
            semesterId: semesterId,
            isOff: true
          }
        });
        this.store.dispatchAction(_COURSE_DATE_ANNOTATION_CREATE.COURSE_DATE_ANNOTATION_CREATE, {
          course: course,
          annotation: annotation,
          originalDatesOff: course.attributes.calendar.datesOff,
          rotationTitle: rotationTitle,
          calledFrom: "schedule-screen"
        });
      } else {
        this.store.dispatchAction(_COURSE_DATE_ANNOTATION_DELETE.COURSE_DATE_ANNOTATION_DELETE, {
          course: course,
          annotation: hasDateAnnotation,
          calledFrom: "schedule-screen"
        });
      }

      if (foundDates.length > 0) {
        return this.dialog.blank("dialogs/ask-to-turn-dates-on", {
          lessonCount: foundDates.length,
          foundDates: formattedFoundDates,
          rotationTitle: rotation.title,
          className: "dialog--create-annotation"
        }).then(turnDatesOn => {
          if (turnDatesOn) {
            this.store.dispatchAction(_COURSE_DATE_ANNOTATION_DELETE_MULTIPLE.default, {
              course: course,
              dateStrings: foundDates
            });
          }
        }).catch(error => {
          if (error instanceof Error) (0, _app.handleError)(error);
        });
      } else {
        return undefined;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CourseCalendarEditor;
});