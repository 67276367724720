define("cc-frontend/components/planner/menus/add-menu.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "course-rows-header": "_course-rows-header_7295l9",
    "course-rows": "_course-rows_7295l9",
    "course-row": "_course-row_7295l9",
    "course-row__course-title": "_course-row__course-title_7295l9",
    "button": "_button_7295l9",
    "button__icon": "_button__icon_7295l9 _single-button-row__icon_1g11o7",
    "button__text": "_button__text_7295l9 _single-button-row__text_1g11o7"
  };
  _exports.default = _default;
});