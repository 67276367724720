define("cc-frontend/lib/actions/rotation-calendar/CHANGE_CALENDAR", ["exports", "cc-frontend/lib/patch-updater", "lodash-es"], function (_exports, _patchUpdater, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeCalendar = changeCalendar;

  function changeCalendar(payload) {
    return {
      name: "CHANGE_CALENDAR_WITH_DATE_ANNOTATION",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let rcPatch = _patchUpdater.default.create("rotation-calendar", payload.rotationCalendar.id).set("attributes.dates", payload.rotationCalendar.updatedAttributes.dates).set("attributes.dateAnnotations", payload.rotationCalendar.updatedAttributes.dateAnnotations).set("attributes.schoolDays", payload.rotationCalendar.updatedAttributes.schoolDays).set("attributes.semesters", payload.rotationCalendar.updatedAttributes.semesters).set("attributes.rotations", payload.rotationCalendar.updatedAttributes.rotations).patch;

    let planbookPatch = null;

    if (payload.planbook !== null) {
      planbookPatch = _patchUpdater.default.create("planbook", payload.planbook.id).set("attributes.calendar", payload.planbook.updatedCalendar).patch;
    }

    let coursePatches = [];

    if (payload.courses !== null) {
      coursePatches = (0, _lodashEs.map)(payload.courses, ({
        id,
        originalCalendar,
        updatedCalendar
      }) => {
        return _patchUpdater.default.create("course", id).set("attributes.calendar", updatedCalendar).patch;
      });
    } // TODO Add patches for creating card stacks


    return (0, _lodashEs.compact)((0, _lodashEs.concat)([rcPatch, planbookPatch], coursePatches));
  }

  function undoPatches(payload) {
    let rcPatch = _patchUpdater.default.create("rotation-calendar", payload.rotationCalendar.id).set("attributes.dates", payload.rotationCalendar.originalAttributes.dates).set("attributes.dateAnnotations", payload.rotationCalendar.originalAttributes.dateAnnotations).set("attributes.schoolDays", payload.rotationCalendar.originalAttributes.schoolDays).set("attributes.semesters", payload.rotationCalendar.originalAttributes.semesters).set("attributes.rotations", payload.rotationCalendar.originalAttributes.rotations).patch;

    let planbookPatch = null;

    if (payload.planbook !== null) {
      planbookPatch = _patchUpdater.default.create("planbook", payload.planbook.id).set("attributes.calendar", payload.planbook.originalCalendar).patch;
    }

    let coursePatches = [];

    if (payload.courses !== null) {
      coursePatches = (0, _lodashEs.map)(payload.courses, ({
        id,
        originalCalendar,
        updatedCalendar
      }) => {
        return _patchUpdater.default.create("course", id).set("attributes.calendar", originalCalendar).patch;
      });
    }

    return (0, _lodashEs.compact)((0, _lodashEs.concat)([rcPatch, planbookPatch], coursePatches));
  }

  function narrative(payload) {
    var _payload$planbook;

    let titleForAnalytics;
    let title;

    if (payload.causeOfChange === "ADD_IS_OFF") {
      titleForAnalytics = "Rotation Calendar - Add is Off";
      title = "Add Day Off";
    } else if (payload.causeOfChange === "ADD_NOTE") {
      titleForAnalytics = "Rotation Calendar - Add Note";
      title = "Add Note";
    } else if (payload.causeOfChange === "CHANGE_ROTATION_ID") {
      titleForAnalytics = "Rotation Calendar - Changed Rotation Id";
      title = "Changed Rotation Day";
    } else if (payload.causeOfChange === "RESET_ROTATION") {
      titleForAnalytics = "Rotation Calendar - Reset Rotation";
      title = "Reset Rotation";
    } else if (payload.causeOfChange === "SKIP_ROTATION_DAY") {
      titleForAnalytics = "Rotation Calendar - Skip Rotation";
      title = "Skipped Rotation";
    } else if (payload.causeOfChange === "REMOVE_DATE_ANNOTATION") {
      titleForAnalytics = "Rotation Calendar - Remove Annotation";
      title = "Removed Calendar Event";
    }

    return {
      activity: {},
      context: {
        planbookId: (_payload$planbook = payload.planbook) === null || _payload$planbook === void 0 ? void 0 : _payload$planbook.id,
        rotationCalendarId: payload.rotationCalendar.id,
        causeOfChange: payload.causeOfChange
      },
      description: payload.changeDescription,
      title: title,
      titleForAnalytics: titleForAnalytics
    };
  }
});