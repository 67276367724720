define("cc-frontend/helpers/has-capability", ["exports", "@glint/environment-ember-loose/ember-component/helper", "lodash-es"], function (_exports, _helper, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasCapability = hasCapability;
  _exports.default = void 0;

  function hasCapability(args) {
    let [model, capability] = args;
    if ((0, _lodashEs.isNil)(model)) return false;
    return (0, _lodashEs.includes)(Ember.get(model, "attributes.capabilities"), capability);
  }

  const hasCapabilityHelper = (0, _helper.helper)(hasCapability);
  var _default = hasCapabilityHelper;
  _exports.default = _default;
});