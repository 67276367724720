define("cc-frontend/components/school-year-select-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @select.loading}}
    <div class="tw-pr-2">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        style="enable-background:new 0 0 50 50;"
        xml:space="preserve"
      >
        <path
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          transform="rotate(339.122 25 25)"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
      </svg>
    </div>
  {{/if}}
  {{#if @select.selected}}
    {{@select.selected.attributes.longAbbreviation}}
    <Icons::IosArrowDown />
  {{/if}}
  {{yield}}
  */
  {
    "id": "SBS2yXYn",
    "block": "[[[41,[30,1,[\"loading\"]],[[[1,\"  \"],[10,0],[14,0,\"tw-pr-2\"],[12],[1,\"\\n    \"],[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"viewBox\",\"0 0 50 50\"],[14,5,\"enable-background:new 0 0 50 50;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"d\",\"M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z\"],[14,\"transform\",\"rotate(339.122 25 25)\"],[12],[1,\"\\n        \"],[10,\"animateTransform\"],[14,\"attributeType\",\"xml\"],[14,\"attributeName\",\"transform\"],[14,\"from\",\"0 25 25\"],[14,\"to\",\"360 25 25\"],[14,\"dur\",\"0.6s\"],[14,\"repeatCount\",\"indefinite\"],[14,4,\"rotate\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"selected\"]],[[[1,\"  \"],[1,[30,1,[\"selected\",\"attributes\",\"longAbbreviation\"]]],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null],[18,2,null]],[\"@select\",\"&default\"],false,[\"if\",\"icons/ios-arrow-down\",\"yield\"]]",
    "moduleName": "cc-frontend/components/school-year-select-trigger.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});