define("cc-frontend/components/planner/planbook/create-edit/courses-editor.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "_wrapper_1vz4vn",
    "--edit-modal": "_--edit-modal_1vz4vn",
    "add-course-button": "_add-course-button_1vz4vn d-s-shadowed-button",
    "--is-creating": "_--is-creating_1vz4vn",
    "course-list": "_course-list_1vz4vn",
    "course": "_course_1vz4vn",
    "color-picker": "_color-picker_1vz4vn",
    "course-title-input": "_course-title-input_1vz4vn",
    "course__left": "_course__left_1vz4vn",
    "course__right": "_course__right_1vz4vn",
    "delete-course": "_delete-course_1vz4vn",
    "course-color-picker": "_course-color-picker_1vz4vn",
    "course-color-picker__color": "_course-color-picker__color_1vz4vn"
  };
  _exports.default = _default;
});