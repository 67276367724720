define("cc-frontend/lib/actions/lesson/LESSON_CARD_REMOVE_ATTACHMENT", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_REMOVE_ATTACHMENT = void 0;
  const LESSON_CARD_REMOVE_ATTACHMENT = {
    name: "LESSON_CARD_REMOVE_ATTACHMENT",
    params: {
      cardStackId: "string",
      sectionId: "string",
      attachment: "object"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).pull("embedded", "attributes.attachments", {
        id: payload.attachment.id
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).addToSet("embedded", "attributes.attachments", payload.attachment).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);

      let card = _lodash.default.find(_lodash.default.get(models, "cardStack.attributes.cards"), {
        id: payload.sectionId
      });

      let cardTitle = _lodash.default.get(card, "attributes.title");

      let attachment = payload.attachment;

      let attachmentTitle = _lodash.default.get(attachment, "title");

      let context = (0, _defaultLessonContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        cardTitle: cardTitle,
        attachmentTitle
      });

      return {
        title: "Removed a File",
        titleForAnalytics: "Attachment - Remove",
        description: `Removed 1 file from "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "removed")
      };
    }

  };
  _exports.LESSON_CARD_REMOVE_ATTACHMENT = LESSON_CARD_REMOVE_ATTACHMENT;
  var _default = LESSON_CARD_REMOVE_ATTACHMENT;
  _exports.default = _default;
});