define("cc-frontend/components/icons/cc-word-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="480" height="480" viewBox="0 0 480 480">
    <path
      fill="#fff"
      d="M147.5 438.3c-84.8 0-134.7-54.8-134.7-131.4 0-77 55.1-131.4 126.6-131.4 27 0 48.9 7.8 72.2 24.4l6.7-20h30v101h-31.8c-4.4-47-27.8-72.5-66.2-72.5-40.7 0-68.1 34.4-68.1 98.5 0 64 26.3 98.8 69.6 98.8 36.3 0 60.3-25.5 62.6-70.7h35.2c-2.1 66.3-38.8 103.3-102.1 103.3zm296.5-71.5c0 45.2-27.4 71.1-75.1 71.1-55.1 0-92.2-34.8-92.2-94 0-56.2 41.1-94 97-94 44.4 0 74 25.9 74 58.5 0 21.1-14.1 34.8-33.7 34.8-17.4 0-29.6-11.5-29.6-27.8 0-13.3 8.9-23.3 23.3-25.5-9.6-7.8-18.9-11.5-30-11.5-28.1 0-42.2 21.1-42.2 62.5 0 43.3 11.8 64.4 37.8 64.4 22.6 0 35.1-13.7 38.1-41.1h32.6v2.6zm-68.8-141.8c12.4 0 22.4-10 22.4-22.4h-44.8c.1 12.3 10.1 22.4 22.4 22.4zm0-125.5c18.7 0 33.9 15.2 33.9 33.9 0 12.4-6.8 23.8-17.6 29.8l-6.7 3.7v11.7h-19v-11.5l-6.7-3.7c-10.9-6-17.7-17.4-17.7-29.8 0-18.9 15.2-34.1 33.8-34.1m0-12.9c-25.8 0-46.8 21-46.8 46.8 0 17.7 9.9 33.1 24.4 41.1v16.9h44.8v-16.9c14.5-7.9 24.4-23.3 24.4-41.1 0-25.8-20.9-46.8-46.8-46.8zm-7.5-13.7l-2.1-31.2h19.3l-2 31.2zm-37.2 19l-21.5-22.6 14.7-12.3 18.5 25.2zm-16.1 38.5l-31.1-3.5 3.4-18.9 30.3 7.4zm12.3 39.8l-26 17.3-9.6-16.6 28-13.9zm104.7-13.2l28 13.9-9.6 16.6-26-17.3zm2.1-41.6l30.4-7.4 3.3 18.9-31.1 3.5zm-25.2-33.2l18.5-25.2 14.7 12.3-21.5 22.6z"
    ></path>
  </svg>
  */
  {
    "id": "ybehvkju",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"480\"],[14,\"height\",\"480\"],[14,\"viewBox\",\"0 0 480 480\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#fff\"],[14,\"d\",\"M147.5 438.3c-84.8 0-134.7-54.8-134.7-131.4 0-77 55.1-131.4 126.6-131.4 27 0 48.9 7.8 72.2 24.4l6.7-20h30v101h-31.8c-4.4-47-27.8-72.5-66.2-72.5-40.7 0-68.1 34.4-68.1 98.5 0 64 26.3 98.8 69.6 98.8 36.3 0 60.3-25.5 62.6-70.7h35.2c-2.1 66.3-38.8 103.3-102.1 103.3zm296.5-71.5c0 45.2-27.4 71.1-75.1 71.1-55.1 0-92.2-34.8-92.2-94 0-56.2 41.1-94 97-94 44.4 0 74 25.9 74 58.5 0 21.1-14.1 34.8-33.7 34.8-17.4 0-29.6-11.5-29.6-27.8 0-13.3 8.9-23.3 23.3-25.5-9.6-7.8-18.9-11.5-30-11.5-28.1 0-42.2 21.1-42.2 62.5 0 43.3 11.8 64.4 37.8 64.4 22.6 0 35.1-13.7 38.1-41.1h32.6v2.6zm-68.8-141.8c12.4 0 22.4-10 22.4-22.4h-44.8c.1 12.3 10.1 22.4 22.4 22.4zm0-125.5c18.7 0 33.9 15.2 33.9 33.9 0 12.4-6.8 23.8-17.6 29.8l-6.7 3.7v11.7h-19v-11.5l-6.7-3.7c-10.9-6-17.7-17.4-17.7-29.8 0-18.9 15.2-34.1 33.8-34.1m0-12.9c-25.8 0-46.8 21-46.8 46.8 0 17.7 9.9 33.1 24.4 41.1v16.9h44.8v-16.9c14.5-7.9 24.4-23.3 24.4-41.1 0-25.8-20.9-46.8-46.8-46.8zm-7.5-13.7l-2.1-31.2h19.3l-2 31.2zm-37.2 19l-21.5-22.6 14.7-12.3 18.5 25.2zm-16.1 38.5l-31.1-3.5 3.4-18.9 30.3 7.4zm12.3 39.8l-26 17.3-9.6-16.6 28-13.9zm104.7-13.2l28 13.9-9.6 16.6-26-17.3zm2.1-41.6l30.4-7.4 3.3 18.9-31.1 3.5zm-25.2-33.2l18.5-25.2 14.7 12.3-21.5 22.6z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/cc-word-mark.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});