define("cc-frontend/components/planner/card-stacks/unit/header.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-header": "_lesson-header_17z1z7",
    "units-block": "_units-block_17z1z7",
    "units-block__unit": "_units-block__unit_17z1z7",
    "lesson-block": "_lesson-block_17z1z7",
    "lesson-block__left": "_lesson-block__left_17z1z7",
    "--is-new-lesson": "_--is-new-lesson_17z1z7",
    "lesson-block__unit-color": "_lesson-block__unit-color_17z1z7",
    "lesson-block__right": "_lesson-block__right_17z1z7",
    "lesson-block__text": "_lesson-block__text_17z1z7",
    "lesson-block__text__course-title": "_lesson-block__text__course-title_17z1z7",
    "lesson-block__text__lesson-title-row": "_lesson-block__text__lesson-title-row_17z1z7",
    "lesson-block__text__lesson-title-row__title": "_lesson-block__text__lesson-title-row__title_17z1z7",
    "lesson-block__menu-trigger": "_lesson-block__menu-trigger_17z1z7",
    "--has-no-template": "_--has-no-template_17z1z7"
  };
  _exports.default = _default;
});