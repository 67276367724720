define("cc-frontend/components/icons/time-clock-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M11.999 24.001c-6.617 0-12-5.383-12-12 0-6.617 5.383-12 12-12 6.617 0 12 5.383 12 12 -3.55271e-15 6.617-5.383 12-12 12Zm0-23c-6.065-1.33227e-15-11 4.935-11 11 0 6.065 4.935 11 11 11 6.065 0 11-4.935 11-11 0-6.065-4.935-11-11-11Z"
      ></path>
      <path
        d="M18 18.001c-.125 0-.245-.047-.338-.131l-6-5.5c-.103-.094-.162-.229-.162-.369v-5.5c0-.276.224-.5.5-.5 .276 0 .5.224.5.5v5.28l5.838 5.351c.098.09.156.213.162.347 .006.133-.041.261-.131.36 -.095.103-.229.162-.369.162Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "tbL5zCM9",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M11.999 24.001c-6.617 0-12-5.383-12-12 0-6.617 5.383-12 12-12 6.617 0 12 5.383 12 12 -3.55271e-15 6.617-5.383 12-12 12Zm0-23c-6.065-1.33227e-15-11 4.935-11 11 0 6.065 4.935 11 11 11 6.065 0 11-4.935 11-11 0-6.065-4.935-11-11-11Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M18 18.001c-.125 0-.245-.047-.338-.131l-6-5.5c-.103-.094-.162-.229-.162-.369v-5.5c0-.276.224-.5.5-.5 .276 0 .5.224.5.5v5.28l5.838 5.351c.098.09.156.213.162.347 .006.133-.041.261-.131.36 -.095.103-.229.162-.369.162Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/time-clock-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});