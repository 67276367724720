define("cc-frontend/lib/actions/course/default-course-activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(_payload, models, verb = "edited") {
    let user = models.user || {};
    return {
      actor: `user-${user.id}`,
      verb: verb,
      object: `course-${models.course.id}`,
      to: [`course-edits:course-${models.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
    };
  }
});