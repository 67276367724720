define("cc-frontend/helpers/difference-in-days", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.differenceInDays = differenceInDays;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function differenceInDays(params) {
    let startDate = dateFns.parse(params[0]);
    let endDate = dateFns.parse(params[1] || new Date());
    return dateFns.differenceInDays(startDate, endDate);
  }

  class DifferenceInDays extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "compute", differenceInDays);
    }

  }

  _exports.default = DifferenceInDays;
});