define("cc-frontend/components/planner/planbook/create-edit/templates-by-name/item.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "item": "_item_1xvazn",
    "header": "_header_1xvazn",
    "template-name": "_template-name_1xvazn",
    "delete-button": "_delete-button_1xvazn",
    "edit-button": "_edit-button_1xvazn",
    "course-list": "_course-list_1xvazn",
    "course-list-wrapper": "_course-list-wrapper_1xvazn",
    "course-pill": "_course-pill_1xvazn",
    "used-status": "_used-status_1xvazn",
    "--has-templates": "_--has-templates_1xvazn",
    "used-status-title": "_used-status-title_1xvazn",
    "used-status-used": "_used-status-used_1xvazn",
    "cannot-delete-notice": "_cannot-delete-notice_1xvazn",
    "confirm-delete-button": "_confirm-delete-button_1xvazn"
  };
  _exports.default = _default;
});