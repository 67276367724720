define("cc-frontend/templates/new-group/show/edit/admins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7kjjeSBY",
    "block": "[[[41,[28,[37,1],[[33,2,[\"meta\",\"permissions\"]],\"can-manage-group\"],null],[[[1,\"  \"],[8,[39,3],null,[[\"@group\"],[[36,2]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"tw-text-3xl tw-text-center tw-mt-8 tw-px-12\"],[12],[1,\"\\n    \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"includes\",\"model\",\"school-network/edit-members\",\"school/setup-permissions-notice\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/edit/admins.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});