define("cc-frontend/components/d-s/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="toggle {{if @whiteCircle "--white-circle"}}">
    <XToggle
      @value={{@isOn}}
      @onToggle={{@onToggle}}
      @theme="light take-course-background-color-after"
      class={{local-class "toggle-course-is-on__toggle"}}
      @size="medium"
      @onLabel="::true"
      @offLabel="::false"
      as |toggle|
    >
      <toggle.switch
        @theme={{concat "light take-course-background-color-after " (if @isOn @color)}}
        @size="medium"
        @onLabel="on"
        @offLabel="off"
      />
    </XToggle>
  </div>
  */
  {
    "id": "cgL18Gph",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[[28,[37,1],[\"toggle \",[52,[30,1],\"--white-circle\"]],null]],[[\"from\"],[\"cc-frontend/components/d-s/toggle.css\"]]]]]],[12],[1,\"\\n  \"],[8,[39,3],[[16,0,[28,[37,0],[\"toggle-course-is-on__toggle\"],[[\"from\"],[\"cc-frontend/components/d-s/toggle.css\"]]]]],[[\"@value\",\"@onToggle\",\"@theme\",\"@size\",\"@onLabel\",\"@offLabel\"],[[30,2],[30,3],\"light take-course-background-color-after\",\"medium\",\"::true\",\"::false\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,4,[\"switch\"]],null,[[\"@theme\",\"@size\",\"@onLabel\",\"@offLabel\"],[[28,[37,1],[\"light take-course-background-color-after \",[52,[30,2],[30,5]]],null],\"medium\",\"on\",\"off\"]],null],[1,\"\\n  \"]],[4]]]]],[1,\"\\n\"],[13]],[\"@whiteCircle\",\"@isOn\",\"@onToggle\",\"toggle\",\"@color\"],false,[\"local-class\",\"concat\",\"if\",\"x-toggle\"]]",
    "moduleName": "cc-frontend/components/d-s/toggle.hbs",
    "isStrictMode": false
  });

  class DSToggle extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isOn", void 0);

      _defineProperty(this, "onToggle", void 0);

      _defineProperty(this, "color", void 0);
    } // isOn
    // color
    // onToggle


  } // @ts-ignore


  _exports.default = DSToggle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DSToggle);
});