define("cc-frontend/lib/actions/routine", ["exports", "lodash", "cc-frontend/lib/patch-updater", "moment"], function (_exports, _lodash, _patchUpdater, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let defaultRoutineModels = function (payload, findFn, _userId) {
    return {
      planbook: payload.planbook ? payload.planbook : findFn("planbook", payload.planbookId),
      cardStack: findFn("card-stack", payload.cardStackId)
    };
  };

  let defaultContext = function (payload, models) {
    // We use get as some of these values might be null/undefined and we want to not break
    return {
      planbookTitle: Ember.get(models, "planbook.attributes.title"),
      cardStackId: Ember.get(payload, "cardStackId"),
      cardsStackType: Ember.get(models, "cardStack.attributes.usedAs"),
      cardId: Ember.get(payload, "cardId"),
      date: Ember.get(payload, "dateString") === "template" ? "a daily routine" : (0, _moment.default)(Ember.get(payload, "dateString")).format("ddd, MMM. D, YYYY")
    };
  };

  let defaultRoutineActivity = function (_payload, models, userId, verb = "edited") {
    return {
      actor: `user-${userId}`,
      verb,
      object: `planbook-${models.planbook.id}`,
      to: [`planbook-edits:planbook-${models.planbook.id}`]
    };
  };

  var _default = {
    ROUTINE_FIRST_EDIT: {
      params: {},

      patches(payload) {
        let planbookPatch;

        let oldPlanbookDate = _lodash.default.find(payload.planbook.attributes.calendar.dates, date => {
          return date.attributes.dateString === payload.dateString;
        });

        if (oldPlanbookDate) {
          planbookPatch = _patchUpdater.default.create("planbook", payload.planbook.id, "attributes.calendar.dates", {
            id: oldPlanbookDate.id
          }).set("embedded", "attributes.cardStackId", payload.cardStackId).inc(`attributes.calendar.version`, 1);
        } else {
          planbookPatch = _patchUpdater.default.create("planbook", payload.planbook.id).push("attributes.calendar.dates", {
            id: payload.dateString,
            type: "planbook-date-custom",
            attributes: {
              dateString: payload.dateString,
              cardStackId: payload.cardStackId
            }
          }).inc("attributes.calendar.version", 1);
        }

        let routinePatch = _patchUpdater.default.create("card-stack", payload.cardStackId).set(`attributes.cards`, payload.cards).set(`meta.permissions`, ["can-edit-plan", "can-view-plan"]).set(`meta.cardsUpdatingFromTemplate`, false).set(`relationships.planbook.data`, {
          type: "planbook",
          id: payload.planbook.id
        });

        return [routinePatch.patch, planbookPatch.patch];
      },

      undoPatches(payload) {
        let planbookPatch = _patchUpdater.default.create("planbook", payload.planbook.id).pull("attributes.calendar.dates", {
          id: payload.dateString
        }).inc("attributes.calendar.version", 1);

        return [planbookPatch.patch];
      }

    },
    ROUTINE_ADD_DAY_CARD: {
      params: {
        cardStackId: "string",
        planbookId: "string",
        position: "number",
        cardId: "string",
        value: "string",
        dateString: "string"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).push("attributes.cards", {
          id: payload.cardId,
          type: "card-value-html",
          attributes: {
            position: payload.position,
            value: payload.value
          }
        }).set("meta.cardsUpdatingFromTemplate", false);

        return [patch.patch];
      },

      undoPatches(payload) {
        let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).pull("attributes.cards", {
          id: payload.cardId
        }).patch;

        return [patch];
      },

      narrative(payload, findFn, userId) {
        let models = defaultRoutineModels(payload, findFn, userId);
        let context = defaultContext(payload, models);
        let formattedDateString = Ember.get(context, "date");
        return {
          title: `Added a card to ${formattedDateString}`,
          titleForAnalytics: "Day Card - Add",
          description: "",
          activity: defaultRoutineActivity(payload, models, userId, "created"),
          context
        };
      }

    },
    ROUTINE_INTERMEDIATE_UPDATE_CARD_VALUE: {
      params: {
        cardStackId: "string",
        planbookId: "string",
        cardId: "string",
        value: "string"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
          id: payload.cardId
        }).set("embedded", "attributes.value", payload.value).patch;

        return [patch];
      },

      undoPatches: null,
      narrative: null
    },
    ROUTINE_UPDATE_CARD_VALUE: {
      params: {
        cardStackId: "string",
        planbookId: "string",
        cardId: "string",
        value: "string"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
          id: payload.cardId
        }).set("embedded", "attributes.value", payload.value).patch;

        return [patch];
      },

      // TODO
      undoPatches(payload) {
        let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
          id: payload.cardId
        }).set("embedded", "attributes.value", payload.originalValue).patch;

        return [patch];
      },

      narrative(payload, findFn, userId) {
        let models = defaultRoutineModels(payload, findFn, userId);
        let context = defaultContext(payload, models);
        let formattedDateString = Ember.get(context, "date");
        return {
          title: "Edited day card",
          titleForAnalytics: "Day Card - Edit Value",
          description: `Edited a card on ${formattedDateString}`,
          activity: defaultRoutineActivity(payload, models),
          context
        };
      }

    },
    ROUTINE_REMOVE_SECTION: {
      params: {
        cardStackId: "string",
        planbookId: "string",
        card: "object"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).pull("attributes.cards", {
          id: payload.card.id
        }).patch;

        return [patch];
      },

      undoPatches(payload) {
        let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).push("attributes.cards", payload.card).patch;

        return [patch];
      },

      narrative(payload, findFn, userId) {
        let models = defaultRoutineModels(payload, findFn, userId);
        let context = defaultContext(payload, models);
        let formattedDateString = Ember.get(context, "date");
        return {
          title: "Removed day card",
          titleForAnalytics: "Day Card - Remove",
          description: `Removed a card from ${formattedDateString}`,
          activity: defaultRoutineActivity(payload, models, userId, "removed"),
          context
        };
      }

    },
    ROUTINE_REORDER_SECTIONS: {
      params: {
        cardStackId: "string",
        planbookId: "string",
        positionDiffs: "array"
      },

      patches(payload) {
        return _lodash.default.map(payload.positionDiffs, tuple => {
          return _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
            id: tuple[0]
          }).set("embedded", "attributes.position", tuple[1]).patch;
        });
      },

      undoPatches(payload) {
        return _lodash.default.map(payload.positionDiffs, tuple => {
          return _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
            id: tuple[0]
          }).set("embedded", "attributes.position", tuple[2]).patch;
        });
      },

      narrative(payload, findFn, userId) {
        let models = defaultRoutineModels(payload, findFn, userId);
        let context = defaultContext(payload, models);
        let formattedDateString = Ember.get(context, "date");
        return {
          title: "Reordered a daily schedule",
          titleForAnalytics: "Routine - Reorder",
          description: `Changed the order of classes and/or cards on ${formattedDateString}`,
          activity: defaultRoutineActivity(payload, models, userId),
          context
        };
      }

    }
  };
  _exports.default = _default;
});