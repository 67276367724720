define("cc-frontend/controllers/print/planbook-standards", ["exports", "cc-frontend/helpers/belongs-to", "lodash", "cc-frontend/helpers/find-document", "cc-frontend/helpers/find-many"], function (_exports, _belongsTo, _lodash, _findDocument, _findMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  class ObjectPromiseProxy extends Ember.ObjectProxy.extend(Ember.PromiseProxyMixin) {}

  let PlanbookStandardsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed("showStandardsNotCovered"), _dec4 = Ember.computed, _dec5 = Ember.computed.alias("model.planbook"), _dec6 = (0, _belongsTo.default)("planbook", "owner"), _dec7 = (0, _findMany.default)("courseIds", "course"), _dec8 = (0, _findDocument.default)("standardSetId", "standard-set"), _dec9 = Ember.computed("courses.isFulfilled"), _dec10 = Ember.computed("standardSetId", "courseIds.[]"), _dec11 = Ember.computed("selectedStandardSet.attributes.standards"), (_class = class PlanbookStandardsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _defineProperty(this, "queryParams", ["courseIds", "standardSetId", "showStandardsNotCovered"]);

      _defineProperty(this, "courseIds", []);

      _initializerDefineProperty(this, "planbook", _descriptor3, this);

      _initializerDefineProperty(this, "owner", _descriptor4, this);

      _initializerDefineProperty(this, "courses", _descriptor5, this);

      _initializerDefineProperty(this, "selectedStandardSet", _descriptor6, this);
    }

    get showStandardsNotCoveredAsBool() {
      return Ember.get(this, "showStandardsNotCovered") === "true";
    }

    get currentDate() {
      return new Date();
    }

    get courseTitles() {
      return _lodash.default.map(Ember.get(this, "courses.content"), course => Ember.get(course, "attributes.title"));
    }

    get hierarchicalStandardsReport() {
      let courseIds = Ember.get(this, "courseIds");
      let standardSetId = Ember.get(this, "standardSetId");

      if (courseIds.length > 0 && standardSetId) {
        return ObjectPromiseProxy.create({
          promise: Ember.get(this, "rpc").execute("STANDARDS_REPORT", courseIds, [standardSetId])
        });
      }
    }

    get standards() {
      return (0, _lodash.default)(Ember.get(this, "selectedStandardSet.attributes.standards")).values().sortBy("position").value();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showStandardsNotCoveredAsBool", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "showStandardsNotCoveredAsBool"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentDate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "currentDate"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "planbook", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "courses", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedStandardSet", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "courseTitles", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "courseTitles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hierarchicalStandardsReport", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "hierarchicalStandardsReport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "standards", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "standards"), _class.prototype)), _class));
  _exports.default = PlanbookStandardsController;
});