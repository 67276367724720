define("cc-frontend/lib/actions/lesson/LESSON_CARD_DELETE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_DELETE = void 0;
  const LESSON_CARD_DELETE = {
    name: "LESSON_CARD_DELETE",
    params: {
      cardStackId: "string",
      card: "object"
    },

    patches(payload) {
      if (_lodash.default.isNil(payload.card)) throw Error(`Nil Card trying to be deleted on ${payload.cardStackId}`);

      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).pull("attributes.cards", {
        id: payload.card.id
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).addToSet("attributes.cards", JSON.parse(JSON.stringify(payload.card))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let card = payload.card;
      let cardTitle = card.attributes.title;
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = Object.assign({}, context, {
        cardTitle: cardTitle
      });
      let lessonTitle = Ember.get(models, "lessonTitle");
      return {
        title: "Deleted a Lesson Card",
        titleForAnalytics: "Lesson Card - Remove",
        description: `Removed "${cardTitle}" card from ${lessonTitle}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "removed")
      };
    }

  };
  _exports.LESSON_CARD_DELETE = LESSON_CARD_DELETE;
  var _default = LESSON_CARD_DELETE;
  _exports.default = _default;
});