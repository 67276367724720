define("cc-frontend/templates/admin/send-in-app-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "znH3InEh",
    "block": "[[[41,[33,1,[\"user\",\"attributes\",\"isSupport\"]],[[[1,\"  \"],[10,\"form\"],[14,0,\"form tw-p-8 tw-flex tw-flex-col tw-h-screen tw-items-stretch\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      Message\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],[[24,0,\"tw-border tw-border-dividers tw-w-50 tw-h-screen tw-font-mono tw-mb-8\"]],[[\"@value\"],[[36,3]]],null],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-lg btn-primary\"],[4,[38,4],[[30,0],\"sendMessage\",[30,0,[\"inAppMessageJson\"]]],null],[12],[1,\"\\n      Send Message\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  Must be logged into a support account.\\n\"]],[]]]],[],false,[\"if\",\"session\",\"textarea\",\"inAppMessageJson\",\"action\"]]",
    "moduleName": "cc-frontend/templates/admin/send-in-app-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});