define("cc-frontend/routes/planbook/onboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OnboardRoute extends Ember.Route {
    // This handles old bookmarks people have
    beforeModel
    /* transition */
    () {
      this.transitionTo("planbook.show.planner.week"); // Implicitly aborts the on-going transition.
    }

  }

  _exports.default = OnboardRoute;
});