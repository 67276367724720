define("cc-frontend/components/admin/group/table/user-cell-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{on "click" (fn @deleteMembership @value) bubbles=false}}>
    Delete
  </div>
  */
  {
    "id": "elQMm7g9",
    "block": "[[[11,0],[4,[38,0],[\"click\",[28,[37,1],[[30,1],[30,2]],null]],[[\"bubbles\"],[false]]],[12],[1,\"\\n  Delete\\n\"],[13]],[\"@deleteMembership\",\"@value\"],false,[\"on\",\"fn\"]]",
    "moduleName": "cc-frontend/components/admin/group/table/user-cell-actions.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});