define("cc-frontend/components/dialogs/alert-reload-on-new-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-info" style="display: block;">
    i
  </div>
  <h1 class="create-annotation__title">
    Cc has been updated! We need to refresh your browser.
  </h1>
  <h2 class="create-annotation__description">
    All your work will still be there after we reload.
  </h2>
  <div class="create-annotation__submit-buttons">
    <div class="create-annotation__submit-button" {{action @accept}}>
      Okay
    </div>
  </div>
  */
  {
    "id": "R4VTcF0s",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-info\"],[14,5,\"display: block;\"],[12],[1,\"\\n  i\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Cc has been updated! We need to refresh your browser.\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description\"],[12],[1,\"\\n  All your work will still be there after we reload.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[[30,0],[30,1]],null],[12],[1,\"\\n    Okay\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\"],false,[\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/alert-reload-on-new-version.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});