define("cc-frontend/components/bopit/popover-container", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BopitPopoverContainer = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{yield (hash
      popover=(component "bopit/popover"
        isOpen=@isOpen
        isOpenClass=@isOpenClass
        afterOpenClass=@afterOpenClass
        attachment=@attachment
        targetAttachment=@targetAttachment
        constraints=@constraints
        isInteractive=@isInteractive
        bodyClasses=@bodyClasses
        offset=@offset
        classPrefix=@classPrefix
        onAnchorClick=(action "onAnchorClick")
        clickedOutside=(action "clickedOutside")
        mouseHasEnteredAnchor=(action "mouseHasEnteredAnchor")
        mouseHasLeftAnchor=(action "mouseHasLeftAnchor")
        mouseHasEnteredBody=(action "mouseHasEnteredBody")
        mouseHasLeftBody=(action "mouseHasLeftBody")
        body=(component "bopit/body")
        anchor=(component "bopit/anchor")
      )
    )}}
  
  */
  {
    "id": "WGdVKmGN",
    "block": "[[[1,\"\\n  \"],[18,11,[[28,[37,1],null,[[\"popover\"],[[50,\"bopit/popover\",0,null,[[\"isOpen\",\"isOpenClass\",\"afterOpenClass\",\"attachment\",\"targetAttachment\",\"constraints\",\"isInteractive\",\"bodyClasses\",\"offset\",\"classPrefix\",\"onAnchorClick\",\"clickedOutside\",\"mouseHasEnteredAnchor\",\"mouseHasLeftAnchor\",\"mouseHasEnteredBody\",\"mouseHasLeftBody\",\"body\",\"anchor\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[28,[37,3],[[30,0],\"onAnchorClick\"],null],[28,[37,3],[[30,0],\"clickedOutside\"],null],[28,[37,3],[[30,0],\"mouseHasEnteredAnchor\"],null],[28,[37,3],[[30,0],\"mouseHasLeftAnchor\"],null],[28,[37,3],[[30,0],\"mouseHasEnteredBody\"],null],[28,[37,3],[[30,0],\"mouseHasLeftBody\"],null],[50,\"bopit/body\",0,null,null],[50,\"bopit/anchor\",0,null,null]]]]]]]]],[1,\"\\n\"]],[\"@isOpen\",\"@isOpenClass\",\"@afterOpenClass\",\"@attachment\",\"@targetAttachment\",\"@constraints\",\"@isInteractive\",\"@bodyClasses\",\"@offset\",\"@classPrefix\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = class BopitPopoverContainer extends Ember.Component {
    onAnchorClick() {
      if (this.mouseLeaveTimeout) clearTimeout(this.mouseLeaveTimeout);
      Ember.get(this, "isOpen") === true && !this.mouseLeaveTimeout ? this.closeMenu() : this.openMenu();
    }

    clickedOutside(e) {
      if ($(e.target).parents(".js-opened-from-menu").length > 0) return;
      console.log("clicked outside");
      this.closeMenu(); // this.sendAction("closeMenu")
    }

    mouseHasEnteredBody(e) {
      if (Ember.get(this, "openOnHover") !== true) return;
      this.mouseIsInBody = true; // console.log("FIRE: body enter", get(this, 'popoverUnitTitle'))

      if (this.mouseLeaveTimeout) clearTimeout(this.mouseLeaveTimeout);
    }

    mouseHasLeftBody() {
      if (this.openOnHoverTimeout) clearTimeout(this.openOnHoverTimeout);
      if (Ember.get(this, "openOnHover") !== true) return;
      this.mouseIsInBody = false;
      if (this.mouseLeaveTimeout) clearTimeout(this.mouseLeaveTimeout);
      this.mouseLeaveTimeout = setTimeout(() => {
        if (this.mouseIsInBody) return;
        if (this.mouseIsInAnchor) return; // console.log('FIRE: body leave', get(this, 'popoverUnitTitle'))

        this.closeMenu();
      }, 400);
    }

    mouseHasEnteredAnchor() {
      if (Ember.get(this, "openOnHover") !== true) return;
      this.mouseIsInAnchor = true;
      if (this.mouseLeaveTimeout) clearTimeout(this.mouseLeaveTimeout); // console.log('FIRE: anchor enter', get(this, 'popoverUnitTitle'))

      this.openOnHoverTimeout = setTimeout(() => {
        this.openMenu();
      }, 400);
    }

    mouseHasLeftAnchor() {
      if (this.openOnHoverTimeout) clearTimeout(this.openOnHoverTimeout);
      if (Ember.get(this, "openOnHover") !== true) return;
      this.mouseIsInAnchor = false;
      if (this.mouseIsInBody) return;
      if (this.mouseLeaveTimeout) clearTimeout(this.mouseLeaveTimeout);
      this.mouseLeaveTimeout = setTimeout(() => {
        if (this.mouseIsInBody) return;
        if (this.mouseIsInAnchor) return; // console.log('FIRE: anchor leave', get(this, 'popoverUnitTitle'))

        this.closeMenu();
      }, 400);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "onAnchorClick", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "onAnchorClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clickedOutside", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "clickedOutside"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mouseHasEnteredBody", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "mouseHasEnteredBody"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mouseHasLeftBody", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "mouseHasLeftBody"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mouseHasEnteredAnchor", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "mouseHasEnteredAnchor"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mouseHasLeftAnchor", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "mouseHasLeftAnchor"), _class2.prototype)), _class2)) || _class);
  _exports.default = BopitPopoverContainer;
});