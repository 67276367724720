define("cc-frontend/components/planner/perspectives/day-week/day-marker.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "day-marker": "_day-marker_23uov0",
    "day-marker__info-wrapper": "_day-marker__info-wrapper_23uov0",
    "day-marker__info": "_day-marker__info_23uov0",
    "day-marker__info__month-date": "_day-marker__info__month-date_23uov0",
    "day-marker__day-menu-icon": "_day-marker__day-menu-icon_23uov0",
    "day-marker__info__rotation-day": "_day-marker__info__rotation-day_23uov0",
    "day-marker__info__day-number": "_day-marker__info__day-number_23uov0",
    "day-marker__info__day__rotation": "_day-marker__info__day__rotation_23uov0"
  };
  _exports.default = _default;
});