define("cc-frontend/components/planner/course/create-edit/name-and-color.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "name-and-color": "_name-and-color_10ekzh",
    "course-title-input": "_course-title-input_10ekzh",
    "colors": "_colors_10ekzh",
    "color-circle": "_color-circle_10ekzh",
    "--is-selected": "_--is-selected_10ekzh",
    "delete-button": "_delete-button_10ekzh"
  };
  _exports.default = _default;
});