define("cc-frontend/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YCbKA92p",
    "block": "[[[10,\"meta\"],[14,\"property\",\"og:title\"],[15,\"content\",[33,0,[\"title\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"robots\"],[15,\"content\",[33,0,[\"robots\"]]],[12],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"model\",\"yield\"]]",
    "moduleName": "cc-frontend/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});