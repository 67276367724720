define("cc-frontend/components/planner/modals/lesson-history", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <SlideoutWrapper @order={{@order}} @closeModal={{@closeModal}}>
    <div class="create-team">
      <div class="slideout__header">
        <h1 class="slideout__header__title slideout__header__title--less-bold">
          Editing History
        </h1>
        <div class="slideout__header__close" {{on "click" @closeModal}}>
          <Icons::IosCloseEmpty />
        </div>
      </div>
      <div class="slideout__body">
        <Planner::Modals::BrowseLessonHistory
          @cardStack={{@model.cardStack}}
          @onClose={{@closeModal}}
        />
      </div>
    </div>
  </SlideoutWrapper>
  */
  {
    "id": "o9Osu/qj",
    "block": "[[[8,[39,0],null,[[\"@order\",\"@closeModal\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"create-team\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"slideout__header\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"slideout__header__title slideout__header__title--less-bold\"],[12],[1,\"\\n        Editing History\\n      \"],[13],[1,\"\\n      \"],[11,0],[24,0,\"slideout__header__close\"],[4,[38,1],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[8,[39,2],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"slideout__body\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@cardStack\",\"@onClose\"],[[30,3,[\"cardStack\"]],[30,2]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@order\",\"@closeModal\",\"@model\"],false,[\"slideout-wrapper\",\"on\",\"icons/ios-close-empty\",\"planner/modals/browse-lesson-history\"]]",
    "moduleName": "cc-frontend/components/planner/modals/lesson-history.hbs",
    "isStrictMode": false
  });

  class LessonHistory extends _glimmerComponent.default {}

  _exports.default = LessonHistory;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LessonHistory);
});