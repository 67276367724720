define("cc-frontend/components/icons/document-with-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg version="1.2" x="0px" y="0px" viewBox="-383 145 512 512">
    <path
      d="M16.3,313.9c-0.7-2.9-2-5-3.5-6.8l-83.7-91.7c-1.9-2.1-4.1-3.1-6.6-4.4c-2.9-1.5-6.1-1.6-9.4-1.6h-159.9
  	c-12.4,0-23.7,9.6-23.7,22.9v335.2c0,13.4,11.3,25.9,23.7,25.9H-3.7c12.4,0,21.2-12.5,21.2-25.9V323.4
  	C17.5,319.8,17.1,317.2,16.3,313.9z M-77.5,256l58,63.5h-58V256z M-238.5,561.5v-320h129v81.7c0,14.8,13.4,28.3,28.1,28.3h66.9v210
  	H-238.5z"
    ></path>
    <path
      d="M-74.7,408.7h-126c-5.5,0-10-4.5-10-10s4.5-10,10-10h126c5.5,0,10,4.5,10,10S-69.1,408.7-74.7,408.7z"
    ></path>
    <path
      d="M-104.9,463h-95.7c-5.5,0-10-4.5-10-10s4.5-10,10-10h95.7c5.5,0,10,4.5,10,10S-99.4,463-104.9,463z"
    ></path>
    <path
      d="M-52.7,517.3h-148c-5.5,0-10-4.5-10-10s4.5-10,10-10h148c5.5,0,10,4.5,10,10S-47.1,517.3-52.7,517.3z"
    ></path>
  </svg>
  */
  {
    "id": "L2tp0ydu",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.2\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"viewBox\",\"-383 145 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M16.3,313.9c-0.7-2.9-2-5-3.5-6.8l-83.7-91.7c-1.9-2.1-4.1-3.1-6.6-4.4c-2.9-1.5-6.1-1.6-9.4-1.6h-159.9\\n\\tc-12.4,0-23.7,9.6-23.7,22.9v335.2c0,13.4,11.3,25.9,23.7,25.9H-3.7c12.4,0,21.2-12.5,21.2-25.9V323.4\\n\\tC17.5,319.8,17.1,317.2,16.3,313.9z M-77.5,256l58,63.5h-58V256z M-238.5,561.5v-320h129v81.7c0,14.8,13.4,28.3,28.1,28.3h66.9v210\\n\\tH-238.5z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M-74.7,408.7h-126c-5.5,0-10-4.5-10-10s4.5-10,10-10h126c5.5,0,10,4.5,10,10S-69.1,408.7-74.7,408.7z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M-104.9,463h-95.7c-5.5,0-10-4.5-10-10s4.5-10,10-10h95.7c5.5,0,10,4.5,10,10S-99.4,463-104.9,463z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M-52.7,517.3h-148c-5.5,0-10-4.5-10-10s4.5-10,10-10h148c5.5,0,10,4.5,10,10S-47.1,517.3-52.7,517.3z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/document-with-text.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});