define("cc-frontend/components/planner/course/timeline/dates-off", ["exports", "@ember-decorators/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CourseTimelineDatesOff = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    <div class="timeline-dates-off">
      {{#each datesOff key="dateString" as |date|}}
        <div class="timeline-dates-off__date"
             style={{safe-string "left: " (mult date.index dateWidth) "px; top: " date.depthOffset "px; width: " dateWidth "px; height: " height "px;"}}>
        </div>
      {{/each}}
    </div>
  
  */
  {
    "id": "YuiNOOAS",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"timeline-dates-off\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],\"dateString\",[[[1,\"      \"],[10,0],[14,0,\"timeline-dates-off__date\"],[15,5,[28,[37,3],[\"left: \",[28,[37,4],[[30,1,[\"index\"]],[33,5]],null],\"px; top: \",[30,1,[\"depthOffset\"]],\"px; width: \",[33,5],\"px; height: \",[33,6],\"px;\"],null]],[12],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"date\"],false,[\"each\",\"-track-array\",\"datesOff\",\"safe-string\",\"mult\",\"dateWidth\",\"height\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.computed("dates"), _dec(_class = (_class2 = class CourseTimelineDatesOff extends Ember.Component.extend() {
    get datesOff() {
      return _lodash.default.filter(Ember.get(this, "dates"), date => date.isOff === true);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "datesOff", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "datesOff"), _class2.prototype)), _class2)) || _class);
  _exports.default = CourseTimelineDatesOff;
});