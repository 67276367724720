define("cc-frontend/components/planner/menus/google-classroom/attachment-menus/add-attachment", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  */
  {
    "id": "e33AHLJn",
    "block": "[[],[],false,[]]",
    "moduleName": "cc-frontend/components/planner/menus/google-classroom/attachment-menus/add-attachment.hbs",
    "isStrictMode": false
  });

  class GoogleClassroomAddAttachmentMenuComponent extends _glimmerComponent.default {}

  _exports.default = GoogleClassroomAddAttachmentMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GoogleClassroomAddAttachmentMenuComponent);
});