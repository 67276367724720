define("cc-frontend/components/planner/menus/add-card/google-classroom-post/title", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Menus::Titles::WithBackArrow @goBack={{@goBack}} @title="What Type of Card?" />
  */
  {
    "id": "zVXn6erB",
    "block": "[[[8,[39,0],null,[[\"@goBack\",\"@title\"],[[30,1],\"What Type of Card?\"]],null]],[\"@goBack\"],false,[\"planner/menus/titles/with-back-arrow\"]]",
    "moduleName": "cc-frontend/components/planner/menus/add-card/google-classroom-post/title.hbs",
    "isStrictMode": false
  });

  class AddGCPostTitle extends _glimmerComponent.default {}

  _exports.default = AddGCPostTitle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AddGCPostTitle);
});