define("cc-frontend/templates/suggestions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CY+Lr64E",
    "block": "[[[10,\"script\"],[12],[1,\"\\n  !function(w,d,i,s){function l(){if(!d.getElementById(i)){var\\n  f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type=\\\"text/javascript\\\",e.async=!0,e.src=\\\"https://canny.io/sdk.js\\\",f.parentNode.insertBefore(e,f)}}if(\\\"function\\\"!=typeof\\n  w.Canny){var\\n  c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,\\\"complete\\\"===d.readyState?l():w.attachEvent?w.attachEvent(\\\"onload\\\",l):w.addEventListener(\\\"load\\\",l,!1)}}(window,document,\\\"canny-jssdk\\\",\\\"script\\\");\\n\"],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"session\",\"isAuthenticated\"]],[30,0,[\"session\",\"user\",\"isFulfilled\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@user\"],[[30,0,[\"session\",\"user\",\"content\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"tw-flex tw-items-center tw-justify-center w-screen h-screen tw-p-24\"],[12],[1,\"\\n\"],[41,[30,0,[\"session\",\"user\",\"isPending\"]],[[[1,\"      \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h1\"],[14,0,\"tw-font-bold tw-text-5xl\"],[12],[1,\"\\n        You need to be signed in to submit ideas.\\n        \"],[11,3],[24,6,\"/users/sign_in\"],[4,[38,4],[\"click\",[30,0,[\"signInOrUp\"]]],null],[12],[1,\"\\n          Sign In here\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"and\",\"canny-feature-requests\",\"d-s/three-dot-spinner\",\"on\"]]",
    "moduleName": "cc-frontend/templates/suggestions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});