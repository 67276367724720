define("cc-frontend/components/user/create-school-form/pane-two.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "form-header": "_form-header_1maohu",
    "form-pane": "_form-pane_1maohu",
    "text-input-block": "_text-input-block_1maohu",
    "text-input": "_text-input_1maohu",
    "footer": "_footer_1maohu",
    "nav-button": "_nav-button_1maohu",
    "no-school-name": "_no-school-name_1maohu",
    "next": "_next_1maohu",
    "back": "_back_1maohu"
  };
  _exports.default = _default;
});