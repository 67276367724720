define("cc-frontend/templates/icons/-ios7-compose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yUICV2sf",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"512px\"],[14,\"height\",\"512px\"],[14,\"viewBox\",\"0 0 512 512\"],[14,5,\"enable-background:new 0 0 512 512;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"polygon\"],[14,\"points\",\"368,400 96,400 96,144 304.1,144 320.1,128 80,128 80,416 384,416 384,191.9 368,207.9\"],[12],[13],[1,\"\\n    \"],[10,\"polygon\"],[14,\"points\",\"237.6,284.1 227.9,274.4 399.2,103.1 387.9,91.7 208,271.7 208,304 240.2,304 420.3,124 408.9,112.7\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M444.2,80.3l-12.5-12.5c-2.7-2.4-6.2-3.8-10-3.8s-7.3,1.5-9.9,3.8L399.7,80l0.1,0.1L411,91.4l21,21l12.2-12.2\\n\\t\\tc2.4-2.6,3.8-6.1,3.8-9.9C448.1,86.5,446.6,83,444.2,80.3z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ios7-compose.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});