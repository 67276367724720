define("cc-frontend/components/icons/ios-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    ...attributes
  >
    <path d="M396.6 160l19.4 20.7L256 352 96 180.7l19.3-20.7L256 310.5z"></path>
  </svg>
  */
  {
    "id": "eFiETYH1",
    "block": "[[[11,\"svg\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"width\",\"512\"],[24,\"height\",\"512\"],[24,\"viewBox\",\"0 0 512 512\"],[17,1],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M396.6 160l19.4 20.7L256 352 96 180.7l19.3-20.7L256 310.5z\"],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/ios-arrow-down.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});