define("cc-frontend/components/dialogs/confirm-delete-all-lessons-on-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-warning pulse-warning" style="display: block;">
    !
  </div>
  {{! <h1 class="create-annotation__title">How thorougly would you like to delete this lesson?</h1> }}
  <h1 class="create-annotation__title">
    Would you like to delete all lessons for this day?
  </h1>
  <h2 class="create-annotation__description">
    Simply deleting these lessons will remove all titles and cards, but keep version histories intact.
  </h2>
  <label
    class="create-annotation__checkbox-label"
    {{action (mut @options.shouldRemoveHistory) (not @options.shouldRemoveHistory)}}
  >
    {{!-- <input type="checkbox" checked={{shouldRemoveHistory}} {{action (mut shouldRemoveHistory) (not shouldRemoveHistory) }} > --}}
    {{#if @options.shouldRemoveHistory}}
      <div class="create-annotation__checkbox-label-icon">
        <Icons::IosCheckmarkOutline />
      </div>
    {{else}}
      <div
        class="create-annotation__checkbox-label-icon create-annotation__checkbox-label-icon--circle"
      >
        <Icons::IosCircleOutline />
      </div>
    {{/if}}
    Also delete version histories so I can bump lessons into these slots?
  </label>
  <div class="create-annotation__submit-buttons">
    <div class="create-annotation__submit-button" {{action @accept @options.shouldRemoveHistory}}>
      Delete the lessons
    </div>
    {{!-- <div {{action "accept" "nope"}} class="create-annotation__submit-button">Delete the cards & history</div> --}}
  </div>
  <div class="create-annotation__cancel-button-wrapper">
    <div class="btn btn-link create-annotation__cancel-button" {{action @decline}}>
      Cancel
    </div>
  </div>
  */
  {
    "id": "lP+T4lqn",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-warning pulse-warning\"],[14,5,\"display: block;\"],[12],[1,\"\\n  !\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Would you like to delete all lessons for this day?\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description\"],[12],[1,\"\\n  Simply deleting these lessons will remove all titles and cards, but keep version histories intact.\\n\"],[13],[1,\"\\n\"],[11,\"label\"],[24,0,\"create-annotation__checkbox-label\"],[4,[38,0],[[30,0],[28,[37,1],[[30,1,[\"shouldRemoveHistory\"]]],null],[28,[37,2],[[30,1,[\"shouldRemoveHistory\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,1,[\"shouldRemoveHistory\"]],[[[1,\"    \"],[10,0],[14,0,\"create-annotation__checkbox-label-icon\"],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"create-annotation__checkbox-label-icon create-annotation__checkbox-label-icon--circle\"],[12],[1,\"\\n      \"],[8,[39,5],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  Also delete version histories so I can bump lessons into these slots?\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[[30,0],[30,2],[30,1,[\"shouldRemoveHistory\"]]],null],[12],[1,\"\\n    Delete the lessons\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__cancel-button-wrapper\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"btn btn-link create-annotation__cancel-button\"],[4,[38,0],[[30,0],[30,3]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@options\",\"@accept\",\"@decline\"],false,[\"action\",\"mut\",\"not\",\"if\",\"icons/ios-checkmark-outline\",\"icons/ios-circle-outline\"]]",
    "moduleName": "cc-frontend/components/dialogs/confirm-delete-all-lessons-on-day.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});