define("cc-frontend/components/icons/people-multiple-one-smaller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Multiple Neutral 2 -->
    <g>
      <path
        d="M7.5,12.5c-2.619,0 -4.75,-2.131 -4.75,-4.75c0,-2.619 2.131,-4.75 4.75,-4.75c2.619,0 4.75,2.131 4.75,4.75c0,2.619 -2.131,4.75 -4.75,4.75Zm0,-8.5c-2.068,0 -3.75,1.682 -3.75,3.75c0,2.068 1.682,3.75 3.75,3.75c2.068,0 3.75,-1.682 3.75,-3.75c0,-2.068 -1.682,-3.75 -3.75,-3.75Z"
      ></path>
      <path
        d="M0.5,21c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-4.135 3.365,-7.5 7.5,-7.5c4.135,0 7.5,3.365 7.5,7.5c0,0.276 -0.224,0.5 -0.5,0.5h-14Zm13.481,-1c-0.255,-3.385 -3.054,-6 -6.481,-6c-3.427,0 -6.226,2.615 -6.481,6h12.962Z"
      ></path>
      <path
        d="M16.5,21c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h6.475c-0.251,-2.544 -2.388,-4.5 -4.975,-4.5c-0.796,0 -1.591,0.194 -2.297,0.559c-0.072,0.037 -0.151,0.056 -0.229,0.056c-0.188,0 -0.358,-0.104 -0.444,-0.27c-0.127,-0.245 -0.03,-0.547 0.215,-0.674c0.847,-0.439 1.8,-0.671 2.755,-0.671c3.308,0 6,2.692 6,6c0,0.276 -0.224,0.5 -0.5,0.5h-7Z"
      ></path>
      <path
        d="M17.944,14c-2.068,0 -3.75,-1.682 -3.75,-3.75c0,-2.068 1.682,-3.75 3.75,-3.75c2.068,0 3.75,1.682 3.75,3.75c0,2.068 -1.682,3.75 -3.75,3.75Zm0,-6.5c-1.516,0 -2.75,1.234 -2.75,2.75c0,1.516 1.234,2.75 2.75,2.75c1.516,0 2.75,-1.234 2.75,-2.75c0,-1.516 -1.234,-2.75 -2.75,-2.75Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "GXU33hV6",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\" Multiple Neutral 2 \"],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M7.5,12.5c-2.619,0 -4.75,-2.131 -4.75,-4.75c0,-2.619 2.131,-4.75 4.75,-4.75c2.619,0 4.75,2.131 4.75,4.75c0,2.619 -2.131,4.75 -4.75,4.75Zm0,-8.5c-2.068,0 -3.75,1.682 -3.75,3.75c0,2.068 1.682,3.75 3.75,3.75c2.068,0 3.75,-1.682 3.75,-3.75c0,-2.068 -1.682,-3.75 -3.75,-3.75Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M0.5,21c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-4.135 3.365,-7.5 7.5,-7.5c4.135,0 7.5,3.365 7.5,7.5c0,0.276 -0.224,0.5 -0.5,0.5h-14Zm13.481,-1c-0.255,-3.385 -3.054,-6 -6.481,-6c-3.427,0 -6.226,2.615 -6.481,6h12.962Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M16.5,21c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h6.475c-0.251,-2.544 -2.388,-4.5 -4.975,-4.5c-0.796,0 -1.591,0.194 -2.297,0.559c-0.072,0.037 -0.151,0.056 -0.229,0.056c-0.188,0 -0.358,-0.104 -0.444,-0.27c-0.127,-0.245 -0.03,-0.547 0.215,-0.674c0.847,-0.439 1.8,-0.671 2.755,-0.671c3.308,0 6,2.692 6,6c0,0.276 -0.224,0.5 -0.5,0.5h-7Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M17.944,14c-2.068,0 -3.75,-1.682 -3.75,-3.75c0,-2.068 1.682,-3.75 3.75,-3.75c2.068,0 3.75,1.682 3.75,3.75c0,2.068 -1.682,3.75 -3.75,3.75Zm0,-6.5c-1.516,0 -2.75,1.234 -2.75,2.75c0,1.516 1.234,2.75 2.75,2.75c1.516,0 2.75,-1.234 2.75,-2.75c0,-1.516 -1.234,-2.75 -2.75,-2.75Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/people-multiple-one-smaller.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});