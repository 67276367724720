define("cc-frontend/lib/actions/planbook/PLANBOOK_DELETE_TEMPLATE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/planbook/default-planbook-activity", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models"], function (_exports, _patchUpdater, _defaultPlanbookActivity, _defaultPlanbookContext, _defaultPlanbookModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PLANBOOK_DELETE_TEMPLATE = void 0;
  const PLANBOOK_DELETE_TEMPLATE = {
    name: "PLANBOOK_DELETE_TEMPLATE",
    params: {
      cardStackId: "string",
      planbookId: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).pull("relationships.cardStackTemplates.data", {
        id: payload.cardStackId,
        type: "card-stack"
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).push("relationships.cardStackTemplates.data", {
        id: payload.cardStackId,
        type: "card-stack"
      }).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
      let planbook = Ember.get(models, "planbook");
      let planbookTitle = Ember.get(planbook, "attributes.title");
      let template = findFn("card-stack", payload.cardStackId) || findFn("card-stack-summary", payload.cardStackId);
      let templateTitle = Ember.get(template, "attributes.title");
      return {
        title: "Deleted a template",
        titleForAnalytics: "Planbook - Delete Template",
        description: `Removed the "${templateTitle}" template from your ${planbookTitle} planbook`,
        context: (0, _defaultPlanbookContext.default)(payload, models),
        activity: (0, _defaultPlanbookActivity.default)(payload, models)
      };
    }

  };
  _exports.PLANBOOK_DELETE_TEMPLATE = PLANBOOK_DELETE_TEMPLATE;
});