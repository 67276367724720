define("cc-frontend/components/dialogs/ask-to-share-planbook-publicly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-question" style="display: block;">
    ?
  </div>
  <h1 class="create-annotation__title">
    To share this particular link, you'll need to let anyone who has the link to your whole planbook
    view it, even if they're not signed in.
  </h1>
  {{! <h2 class="create-annotation__description">
  You can turn this class back on from the "Day Menu".
  </h2> }}
  <div class="create-annotation__submit-buttons">
    <div
      class="create-annotation__submit-button create-annotation__submit-button--full-width"
      {{action @accept true}}
    >
      Yes, let anyone with the link view my planbook
    </div>
    {{!-- <div {{action (action "accept" false)}} class="create-annotation__submit-button">No, completely hide the class</div> --}}
  </div>
  <div class="create-annotation__cancel-button-wrapper">
    <div class="btn btn-link create-annotation__cancel-button" {{action @decline}}>
      Cancel
    </div>
  </div>
  */
  {
    "id": "ejyglA40",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-question\"],[14,5,\"display: block;\"],[12],[1,\"\\n  ?\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  To share this particular link, you'll need to let anyone who has the link to your whole planbook\\n  view it, even if they're not signed in.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button create-annotation__submit-button--full-width\"],[4,[38,0],[[30,0],[30,1],true],null],[12],[1,\"\\n    Yes, let anyone with the link view my planbook\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__cancel-button-wrapper\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"btn btn-link create-annotation__cancel-button\"],[4,[38,0],[[30,0],[30,2]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\",\"@decline\"],false,[\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/ask-to-share-planbook-publicly.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});