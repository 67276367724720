define("cc-frontend/components/planner/planbook/import-lessons/select-recent-course", ["exports", "@ember-decorators/component", "cc-frontend/helpers/find-document"], function (_exports, _component, _findDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ImportLessonsSelectCourseRecentCourse = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
  <div class="importable-courses-list__recent-course">
    <div class="importable-courses-list__recent-course__owner-name">{{planbook.attributes.ownerSummary.fullName}}</div>
    <div class="importable-courses-list__recent-course__planbook">{{planbook.attributes.title}}</div>
    <div class="importable-courses-list__recent-course__course">{{course.attributes.title}}</div>
  </div>
  
  
  */
  {
    "id": "YbtPB5tF",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"importable-courses-list__recent-course\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"importable-courses-list__recent-course__owner-name\"],[12],[1,[33,0,[\"attributes\",\"ownerSummary\",\"fullName\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"importable-courses-list__recent-course__planbook\"],[12],[1,[33,0,[\"attributes\",\"title\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"importable-courses-list__recent-course__course\"],[12],[1,[33,1,[\"attributes\",\"title\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[\"planbook\",\"course\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec3 = (0, _findDocument.default)("recent.courseId", "course-summary"), _dec4 = (0, _findDocument.default)("recent.planbookId", "planbook-summary"), _dec(_class = (_class2 = class ImportLessonsSelectCourseRecentCourse extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "course", _descriptor2, this);

      _initializerDefineProperty(this, "planbook", _descriptor3, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "course", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "planbook", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ImportLessonsSelectCourseRecentCourse;
});