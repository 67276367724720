define("cc-frontend/templates/admin/class-website", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gJP8JklM",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@sidebarComponent\",\"@detailComponent\"],[[36,1],\"admin/class-website/sidebar\",\"admin/class-website/detail\"]],null]],[],false,[\"admin/skeleton\",\"model\"]]",
    "moduleName": "cc-frontend/templates/admin/class-website.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});