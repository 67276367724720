define("cc-frontend/controllers/class-website", ["exports", "cc-frontend/helpers/belongs-to", "lodash", "moment"], function (_exports, _belongsTo, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ClassWebsiteController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = (0, _belongsTo.default)("model", "classWebsite"), _dec5 = (0, _belongsTo.default)("classWebsite", "planbook"), _dec6 = (0, _belongsTo.default)("planbook", "rotationCalendar"), _dec7 = Ember.computed("date", "rotationCalendar.attributes.schoolDays"), _dec8 = Ember.computed("dateStrings.[]"), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ClassWebsiteController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ["date", "perspective"]);

      _initializerDefineProperty(this, "date", _descriptor, this);

      _initializerDefineProperty(this, "perspective", _descriptor2, this);

      _defineProperty(this, "date", (0, _moment.default)().format("YYYY-MM-DD"));

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "classWebsite", _descriptor4, this);

      _initializerDefineProperty(this, "planbook", _descriptor5, this);

      _initializerDefineProperty(this, "rotationCalendar", _descriptor6, this);
    }

    // FLAT OUT COPIED FROM PERSPECTIVE-WEEK. SUPER TACKY, SCOTT.
    get dateStrings() {
      let validSchoolDays = Ember.get(this, "rotationCalendar.attributes.schoolDays");
      let date = Ember.get(this, "date");
      let startDate = (0, _moment.default)(date).startOf("week");
      let endDate = (0, _moment.default)(date).endOf("week");

      let range = _moment.default.range(startDate, endDate);

      let acc = [];
      Array.from(range.by("days")).map(moment => {
        if (_lodash.default.includes(validSchoolDays, moment.weekday())) acc.push(moment);
      });

      let newDates = _lodash.default.uniq(_lodash.default.map(acc, m => m.format("YYYY-MM-DD")));

      return newDates;
    }

    get dateRangeString() {
      var start = (0, _moment.default)(Ember.get(this, "dateStrings.firstObject"));
      var end = (0, _moment.default)(Ember.get(this, "dateStrings.lastObject"));
      var startMonth = start.format("MMMM");
      var endMonth = end.format("MMMM");

      if (startMonth === endMonth) {
        return start.format("MMMM D") + " - " + end.format("D, YYYY");
      } else {
        return start.format("MMM. D") + " - " + end.format("MMM. D, YYYY");
      }
    }

    previousDate() {
      var toSubtract = 1;

      if (Ember.get(this, "perspective") === "week") {
        toSubtract = toSubtract + 6;
      } else {
        if ((0, _moment.default)(this.date).weekday() === 1) toSubtract = 3;
      }

      var previousDate = (0, _moment.default)(this.date).subtract(toSubtract, "days");
      this.set("date", previousDate.format("YYYY-MM-DD"));
    }

    nextDate() {
      var toAdd = 1;

      if (Ember.get(this, "perspective") === "week") {
        toAdd = toAdd + 6;
      } else {
        if ((0, _moment.default)(this.date).weekday() === 5) toAdd = 3;
      }

      var nextDate = (0, _moment.default)(this.date).add(toAdd, "days");
      this.set("date", nextDate.format("YYYY-MM-DD"));
    }

    setNewDate(date) {
      this.set("date", (0, _moment.default)(date).format("YYYY-MM-DD"));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "date", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "perspective", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "week";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "classWebsite", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "planbook", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "rotationCalendar", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dateStrings", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "dateStrings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateRangeString", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dateRangeString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previousDate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "previousDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextDate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "nextDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setNewDate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setNewDate"), _class.prototype)), _class));
  _exports.default = ClassWebsiteController;
});