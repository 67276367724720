define("cc-frontend/templates/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cSkXBwN9",
    "block": "[[[1,[28,[35,0],[\"Cc Admin\"],[[\"replace\"],[true]]]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[10,\"link\"],[14,6,\"https://cdnjs.cloudflare.com/ajax/libs/prism/1.6.0/themes/prism.min.css\"],[14,\"rel\",\"stylesheet\"],[12],[13]],[],false,[\"page-title\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/admin.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});