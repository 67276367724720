define("cc-frontend/lib/actions/course/date/COURSE_DATE_FORCE_ON", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models", "moment"], function (_exports, _patchUpdater, _lodash, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_DATE_FORCE_ON = void 0;
  const COURSE_DATE_FORCE_ON = {
    name: "COURSE_DATE_FORCE_ON",
    params: {
      dateString: "string",
      course: "object",
      shouldAddCourseDate: "boolean",
      courseDateToAdd: "object"
    },

    patches(payload) {
      // Add courseDateToAdd if necessary
      let patch;

      if (payload.shouldAddCourseDate) {
        patch = _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dates", payload.courseDateToAdd);
      } else {
        let courseDate = _lodash.default.chain(payload.course.attributes.calendar.dates).filter(date => date.type === "course-date-custom").find(date => date.attributes.dateString === payload.dateString).value();

        patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
          id: courseDate.id
        }).set("embedded", "attributes.isForcedOn", true);
      }

      patch.push("attributes.calendar.datesForcedOn", payload.dateString).inc("attributes.calendar.version", 1);
      return [patch.patch];
    },

    undoPatches(payload) {
      let patch;

      if (payload.shouldAddCourseDate) {
        patch = _patchUpdater.default.create("course", payload.course.id).pull("attributes.calendar.dates", {
          id: payload.courseDateToAdd.id
        });
      } else {
        let courseDate = _lodash.default.chain(payload.course.attributes.calendar.dates).filter(date => date.type === "course-date-custom").find(date => date.attributes.dateString === payload.dateString).value();

        patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
          id: courseDate.id
        }).set("embedded", "attributes.isForcedOn", false);
      }

      patch.pull("attributes.calendar.datesForcedOn", payload.dateString).inc("attributes.calendar.version", 1);
      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
      let formattedDateString = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
      let courseTitle = payload.course.attributes.title;
      let context = (0, _defaultCourseDateContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        forcedOn: true
      });

      return {
        title: "Turned Class Back On",
        titleForAnalytics: "Lesson - Toggle On",
        description: `Turned your ${courseTitle} class back on for ${formattedDateString}`,
        context: newContext,
        activity: (0, _defaultCourseDateActivity.default)(payload, models)
      };
    }

  };
  _exports.COURSE_DATE_FORCE_ON = COURSE_DATE_FORCE_ON;
  var _default = COURSE_DATE_FORCE_ON;
  _exports.default = _default;
});