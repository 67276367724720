define("cc-frontend/lib/actions/base", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultAnalyticsEvent = defaultAnalyticsEvent;

  function defaultAnalyticsEvent(narrative, context, models) {
    let humanizedContext = _lodash.default.reduce(context, (acc, k, v) => {
      _lodash.default.assign(acc, {
        [_lodash.default.map(_lodash.default.words(k), _lodash.default.capitalize).join(" ")]: v
      });
    }, {});

    return [narrative.analyticsTitle, _lodash.default.assign({}, humanizedContext, {
      Description: Ember.get(narrative, "description"),
      "Action Id": Ember.get(models, "actionId"),
      "Session Id": Ember.get(models, "session.id"),
      "Sequence Number": Ember.get(models, "session.sequenceNumber"),
      "Seconds Since Last Action": Ember.get(models, "session.secondsSinceLastAction")
    })];
  }
});