define("cc-frontend/lib/actions/lesson/LESSON_CARD_REMOVE_COMMENT", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_REMOVE_COMMENT = void 0;
  const LESSON_CARD_REMOVE_COMMENT = {
    name: "LESSON_CARD_REMOVE_COMMENT",
    params: {
      cardStackId: "string",
      cardId: "string",
      comment: "object",
      deletedOn: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).pull("embedded", "attributes.comments", {
        id: payload.comment.id
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).addToSet("embedded", "attributes.comments", JSON.parse(JSON.stringify(payload.comment))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);

      let card = _lodash.default.find(_lodash.default.get(models, "lesson.attributes.cards"), {
        id: payload.cardId
      });

      let cardTitle = _lodash.default.get(card, "title");

      let context = (0, _defaultLessonContext.default)(payload, models);

      let commenterName = _lodash.default.get(payload, "comment.commenterName");

      let commenterId = _lodash.default.get(payload, "comment.commenterId");

      let newContext = _lodash.default.assign({}, context, {
        cardTitle: cardTitle,
        commenterName,
        commenterId
      });

      return {
        title: "Removed a Comment",
        titleForAnalytics: "Comment - Remove",
        description: `Removed a comment from the "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "removed")
      };
    }

  };
  _exports.LESSON_CARD_REMOVE_COMMENT = LESSON_CARD_REMOVE_COMMENT;
  var _default = LESSON_CARD_REMOVE_COMMENT;
  _exports.default = _default;
});