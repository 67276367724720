define("cc-frontend/components/planner/menus/help-menu.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "help-menu-button__anchor": "_help-menu-button__anchor_ds4i1p",
    "trigger-text": "_trigger-text_ds4i1p",
    "dropdown-body": "_dropdown-body_ds4i1p",
    "row": "_row_ds4i1p",
    "row-icon": "_row-icon_ds4i1p",
    "row-text": "_row-text_ds4i1p",
    "row-text-headline": "_row-text-headline_ds4i1p",
    "row-text-description": "_row-text-description_ds4i1p",
    "sync-status-row": "_sync-status-row_ds4i1p"
  };
  _exports.default = _default;
});