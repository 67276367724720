define("cc-frontend/helpers/convert-time-to-id", ["exports", "@glint/environment-ember-loose/ember-component/helper", "lodash-es"], function (_exports, _helper, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertTimeToId;

  function convertTimeToId(time) {
    if ((0, _lodashEs.isNil)(time)) return null;
    let {
      hour,
      minute
    } = time;
    if (hour == null || minute == null) return null;
    return `${hour}:${(0, _lodashEs.padStart)(minute.toString(), 2, "0")}`;
  } // We only have this so we can get typing with glint.


  const convertTimeToIdHelper = (0, _helper.helper)(params => {
    return convertTimeToId(params[0]);
  });
});