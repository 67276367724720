define("cc-frontend/components/planner/planbook/create-edit/templates-by-name/course-pill", ["exports", "@glimmer/component", "cc-frontend/utils/filter-dates/course", "lodash-es"], function (_exports, _component, _course, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    local-class="course-pill {{if this.isSelected "--is-selected"}}"
    class="{{if this.isSelected "take-course-background-color take-course-border-color"}}
      {{@course.attributes.color}}"
    ...attributes
    {{on "click" (fn @toggleTemplateForCourse @course.id @cardStackTemplateId)}}
  >
    <div local-class="course-pill__icon">
      {{#if this.isSelected}}
        <Icons::CircleCheck data-test-selected />
      {{else}}
        <Icons::CircleNoCheck data-test-not-selected />
      {{/if}}
    </div>
    <div local-class="course-pill__title" data-test-course-title>
      {{@course.attributes.title}}
    </div>
  </div>
  */
  {
    "id": "V0z3D+JM",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[[52,[30,0,[\"isSelected\"]],\"take-course-background-color take-course-border-color\"],\"\\n    \",[30,1,[\"attributes\",\"color\"]]],null],\" \",[28,[37,2],[[28,[37,0],[\"course-pill \",[52,[30,0,[\"isSelected\"]],\"--is-selected\"]],null]],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/templates-by-name/course-pill.css\"]]]]]],[17,2],[4,[38,3],[\"click\",[28,[37,4],[[30,3],[30,1,[\"id\"]],[30,4]],null]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,2],[\"course-pill__icon\"],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/templates-by-name/course-pill.css\"]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"isSelected\"]],[[[1,\"      \"],[8,[39,5],[[24,\"data-test-selected\",\"\"]],null,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],[[24,\"data-test-not-selected\",\"\"]],null,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,2],[\"course-pill__title\"],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/templates-by-name/course-pill.css\"]]]]]],[14,\"data-test-course-title\",\"\"],[12],[1,\"\\n    \"],[1,[30,1,[\"attributes\",\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@course\",\"&attrs\",\"@toggleTemplateForCourse\",\"@cardStackTemplateId\"],false,[\"concat\",\"if\",\"local-class\",\"on\",\"fn\",\"icons/circle-check\",\"icons/circle-no-check\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/create-edit/templates-by-name/course-pill.hbs",
    "isStrictMode": false
  });

  const ArrayPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  let CoursePillComponent = (_dec = Ember.computed("args.course.attributes.calendar.version", "args.cardStackTemplateId"), (_class = class CoursePillComponent extends _component.default {
    get isSelected() {
      return (0, _lodashEs.includes)((0, _course.extractCardStackTemplateIds)(this.args.course), this.args.cardStackTemplateId);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isSelected"), _class.prototype)), _class));
  _exports.default = CoursePillComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CoursePillComponent);
});