define("cc-frontend/components/kb/all-chapter", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/has-many-altogether", "ember-could-get-used-to-this"], function (_exports, _glimmerComponent, _hasManyAltogether, _emberCouldGetUsedToThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="kb-sections-list">
    {{#each this.sections.content as |section|}}
      <Kb::Entry::Section @entry={{section}} />
    {{/each}}
  </div>
  */
  {
    "id": "Q4lSSjUP",
    "block": "[[[10,0],[14,0,\"kb-sections-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"sections\",\"content\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@entry\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"section\"],false,[\"each\",\"-track-array\",\"kb/entry/section\"]]",
    "moduleName": "cc-frontend/components/kb/all-chapter.hbs",
    "isStrictMode": false
  });

  let KbAllChapter = (_dec = Ember.inject.service, (_class = class KbAllChapter extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "sections", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sections", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _hasManyAltogether.default(() => [this.args.entry, "children", "kb-entry"]);
    }
  })), _class));
  _exports.default = KbAllChapter;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KbAllChapter);
});