define("cc-frontend/services/ajax", ["exports", "@sentry/browser", "fetch", "js-cookie", "cc-frontend/config/environment", "ember-fetch/errors", "lodash-es"], function (_exports, Sentry, _fetch, _jsCookie, _environment, _errors, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Ajax = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed("session.token"), (_class = class Ajax extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "fastboot", _descriptor2, this);
    }

    get headers() {
      // console.log('HI', this.get('session.session.attributes.token'))
      let headers = {
        "Content-Type": "application/json"
      };
      let authToken = this.get("session.token");

      if (authToken) {
        headers["Authorization"] = `Bearer ${authToken}`;
        headers["X-SESSION-ID"] = Ember.get(this, "session.id");
        headers["X-CLIENT-VERSION"] = _environment.default.CLIENT_VERSION; // headers["sentry-trace"] =
      }

      return headers;
    }

    logResponse(method, response) {
      Sentry.setTag("requestId", response.headers.get("x-request-id"));
      Sentry.addBreadcrumb({
        // message: response.url,
        category: "xhr",
        type: "http",
        data: {
          method: method,
          url: response.url,
          status_code: response.status,
          "x-request-id": response.headers.get("x-request-id")
        }
      });
    }

    request(url, opts = {}) {
      if (Ember.get(this, "fastboot.isFastBoot")) url = _environment.default.API_HOSTNAME_FOR_FASTBOOT + url;
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        headers: Object.assign({}, Ember.get(this, "headers"), opts.headers)
      })).then(response => {
        this.logResponse("GET", response);
        let requestId = Ember.get(response, "headers.map.x-request-id");
        return response.text().then(body => {
          try {
            return JSON.parse(body);
          } catch (e) {
            Sentry.withScope(function (scope) {
              scope.setContext("Response Data", {
                url: url,
                status: response.status,
                body: body
              });
              console.log("Response body", body);
              console.error(`Error parsing JSON: ${response.status}. ${url}. RequestId: ${requestId}`, e);
              Sentry.captureException(e);
            }); // Rethrow so it's caught again

            throw e;
          }
        });
      }).catch(error => {
        console.log("FAILED RESPONSE", error); // This didn't prove to be useful and just ran up our exception budget in our error monitoring.
        // I always ignored these b/c the signal to noise ratio was too low.
        // Sentry.withScope((scope) => {
        //   scope.setContext("Response Data", {
        //     url: url,
        //     error: error,
        //   })
        // Sentry.captureMessage(`Failed Response: ${url}`, "error")
        // })

        return error;
      });
    }
    /**
     * I created this because I needed way in the store to get at what type of error it is.
     * This let's that happen easily.
     *
     * The problem is `catch` only happesn for errors, but 4xx responses aren't errors so they
     * return as if they're 2xx errors. That was causing issues where we were trying to parse
     * something we thought was a successful JSON response but was a "Too many requests" string
     *
     * @param {*} url
     * @param {*} opts
     * @return ["ok", response] | ["error", error]
     */


    requestFromAdapter(_adapterName, url, opts = {}) {
      if (Ember.get(this, "fastboot.isFastBoot")) url = _environment.default.API_HOSTNAME_FOR_FASTBOOT + url;
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        headers: Object.assign({}, Ember.get(this, "headers"), opts.headers)
      })).then(response => {
        this.logResponse("GET", response);
        let requestId = response.headers.get("x-request-id");
        let responseDataContext = {
          url: url,
          status: response.status,
          requestId: requestId
        };
        return response.text().then(body => {
          if (response.status < 200 || response.status > 299) {
            console.error(`Non 2xx response: ${response.status}. ${url}. RequestId: ${requestId}`); // We don't send over the body at the top as we don't always want to send the body to Sentry --
            // that would be a waste of data. instead, we only send it on non 2xx responses and errors
            // Also, log the cookies as we're getting 431 and it's the most likely culprit
            // If it's a 429 (too many requests), we don't  worry as we will refetch that

            if (response.status !== 429) {
              Sentry.withScope(scope => {
                scope.setTag("requestId", requestId);
                scope.setContext("Response Data", (0, _lodashEs.assign)({}, responseDataContext, {
                  response: body,
                  cookies: _jsCookie.default.get()
                }));
                Sentry.captureMessage(`${response.status} response: ${url}`);
              });
            }

            return ["error", {
              status: response.status
            }];
          } else {
            try {
              let json = JSON.parse(body);
              return ["ok", json];
            } catch (e) {
              console.log("Reponse body", body);
              console.error(`Error parsing JSON: ${response.status}. ${url}. RequestId: ${requestId}`); // We don't do this at the top as we don't always want to send the body to Sentry --
              // that would be a waste of data. instead, we only send it on non 2xx responses and errors

              Sentry.withScope(scope => {
                scope.setContext("Response Data", (0, _lodashEs.assign)({}, responseDataContext, {
                  response: body,
                  cookies: _jsCookie.default.get()
                }));
                Sentry.captureException(e);
              });
              throw e;
            }
          }
        });
      }).catch(error => {
        // We don't need to log aborted responses
        if (!(0, _errors.isAbortError)(error)) {
          console.log("FAILED RESPONSE", error); // Sentry.captureException(error)
        }

        return ["error", error];
      });
    }

    requestRaw(url, opts) {
      if (Ember.get(this, "fastboot.isFastBoot")) url = _environment.default.API_HOSTNAME_FOR_FASTBOOT + url;
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        headers: Ember.get(this, "headers")
      })).then(response => {
        this.logResponse("GET", response);
        return response;
      }).catch(error => {
        // We don't need to log aborted responses
        if (!(0, _errors.isAbortError)(error)) {
          console.log("FAILED RESPONSE", error); // Sentry.captureException(error)
        }

        return error;
      });
    }

    post(url, opts) {
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        method: "POST",
        headers: Ember.get(this, "headers")
      })).then(response => {
        this.logResponse("POST", response);
        return response.json();
      });
    }

    postRaw(url, opts) {
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        method: "POST",
        headers: Ember.get(this, "headers")
      })).then(response => {
        this.logResponse("POST", response);
        return response;
      });
    }

    put(url, opts) {
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        method: "PUT",
        headers: Ember.get(this, "headers")
      })).then(response => {
        this.logResponse("PUT", response);
        return response.json();
      });
    }

    putRaw(url, opts) {
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        method: "PUT",
        headers: Ember.get(this, "headers")
      })).then(response => {
        this.logResponse("PUT", response);
        return response;
      });
    }

    del(url, opts) {
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        method: "DELETE",
        headers: Ember.get(this, "headers")
      })).then(response => {
        this.logResponse("DELETE", response);
        return response.json();
      });
    }

    delRaw(url, opts) {
      return (0, _fetch.default)(url, Object.assign({}, opts, {
        method: "DELETE",
        headers: Ember.get(this, "headers")
      })).then(response => {
        this.logResponse("DELETE", response);
        return response;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = Ajax;
});