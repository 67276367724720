define("cc-frontend/components/planner/modals/edit-annotation-body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "edit-screen": "_edit-screen_14iqaw",
    "toggle-row": "_toggle-row_14iqaw",
    "toggle-row__label": "_toggle-row__label_14iqaw",
    "title": "_title_14iqaw",
    "description": "_description_14iqaw",
    "submit-row": "_submit-row_14iqaw",
    "submit": "_submit_14iqaw",
    "calendar": "_calendar_14iqaw"
  };
  _exports.default = _default;
});