define("cc-frontend/components/planner/menus/titles/with-back-arrow", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="header-bar" {{on "click" @goBack}} data-test-menu-title-back-button>
    <div local-class="header-bar__back-arrow">
      <Icons::ArrowLeft />
    </div>
    <div local-class="header-bar__title">
      {{@title}}
    </div>
  </div>
  */
  {
    "id": "sshcnh5R",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[\"header-bar\"],[[\"from\"],[\"cc-frontend/components/planner/menus/titles/with-back-arrow.css\"]]]]]],[24,\"data-test-menu-title-back-button\",\"\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"header-bar__back-arrow\"],[[\"from\"],[\"cc-frontend/components/planner/menus/titles/with-back-arrow.css\"]]]]]],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"header-bar__title\"],[[\"from\"],[\"cc-frontend/components/planner/menus/titles/with-back-arrow.css\"]]]]]],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@goBack\",\"@title\"],false,[\"local-class\",\"on\",\"icons/arrow-left\"]]",
    "moduleName": "cc-frontend/components/planner/menus/titles/with-back-arrow.hbs",
    "isStrictMode": false
  });

  let DayMenuTitleWithBackArrow = (_dec = (0, _component.tagName)(""), _dec(_class = class DayMenuTitleWithBackArrow extends Ember.Component {}) || _class);
  _exports.default = DayMenuTitleWithBackArrow;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DayMenuTitleWithBackArrow);
});