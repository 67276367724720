define("cc-frontend/lib/actions/class-website", ["exports", "cc-frontend/lib/patch-updater", "lodash"], function (_exports, _patchUpdater, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let defaultRelatedModels = function (findFn, payload, actionId, session) {
    return {
      classWebsite: findFn("class-website", payload.websiteId),
      planbook: findFn("planbook", payload.planbookId),
      session,
      actionId,
      course: payload.courseId ? findFn("course", payload.courseId) : null
    };
  };

  let defaultContext = function (payload, models) {
    // We use get as some of these values might be null/undefined and we want to not break
    let websiteSlug = Ember.get(models, "classWebsite.attributes.slug");
    return {
      planbookTitle: Ember.get(models, "planbook.attributes.title"),
      websiteId: Ember.get(payload, "websiteId"),
      websiteURL: websiteSlug ? `www.commoncurriculum.com/sites/${websiteSlug}` : null
    };
  };

  let defaultClassWebsiteActivity = function (payload, models, verb = "edited") {
    let idForObject = Ember.get(models, "planbook.id");
    if (Ember.isNone(idForObject)) return;
    return {
      actor: `user-${models.session.userId}`,
      verb: verb,
      object: `planbook-${idForObject}`,
      to: [`planbook-edits:planbook-${models.planbook.id}`]
    };
  };

  var _default = {
    CREATE_CLASS_WEBSITE: {
      params: {
        planbookId: "string",
        websiteId: "string"
      },

      patches(payload) {
        let createWebsitePatch = _patchUpdater.default.create("class-website", payload.websiteId).set("attributes.title", "My Fantastic Website").set("attributes.slug", `my-fantastic-website-url-${payload.websiteId}`).set("relationships.planbook.data", {
          id: payload.planbookId,
          type: "planbook"
        }).patch;

        createWebsitePatch.isCreatePatch = true;

        let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).push("relationships.classWebsites.data", {
          id: payload.websiteId,
          type: "class-website"
        }).patch;

        return [createWebsitePatch, planbookPatch];
      },

      undoPatches(payload) {
        let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).pull("relationships.classWebsites.data", {
          id: payload.websiteId,
          type: "class-website"
        }).patch;

        return [planbookPatch];
      },

      narrative(payload) {
        let models = defaultRelatedModels;
        return {
          title: "Created Class Website",
          titleForAnalytics: "Class Website - Created",
          description: "",
          context: defaultContext,
          activity: defaultClassWebsiteActivity(payload, models, "created")
        };
      }

    },
    DELETE_CLASS_WEBSITE: {
      params: {
        planbookId: "string",
        websiteId: "string"
      },

      patches(payload) {
        let websitePatch = _patchUpdater.default.create("class-website", payload.websiteId).set("attributes.isDeleted", true).patch;

        let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).pull("relationships.classWebsites.data", {
          id: payload.websiteId
        }).patch;

        return [websitePatch, planbookPatch];
      },

      undoPatches(payload) {
        let websitePatch = _patchUpdater.default.create("class-website", payload.websiteId).set("attributes.isDeleted", false).patch;

        let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).push("relationships.classWebsites.data", {
          id: payload.websiteId
        }).patch;

        return [websitePatch, planbookPatch];
      },

      narrative(payload) {
        let models = defaultRelatedModels;
        let websiteTitle = Ember.get(models, "classWebsite.attributes.title");
        let websiteSlug = Ember.get(models, "classWebsite.attributes.slug");

        let context = _lodash.default.assign({}, defaultContext, {
          websiteTitle,
          websiteSlug
        });

        return {
          title: "Deleted Class Website",
          titleForAnalytics: "Class Website - Deleted",
          activity: defaultClassWebsiteActivity(payload, models, "removed"),
          context,
          description: `Deleted your "${websiteTitle}" class website`
        };
      }

    },
    UPDATE_CLASS_WEBSITE_TITLE: {
      params: {
        planbookId: "string",
        websiteId: "string",
        title: "string",
        originalTitle: "string"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("class-website", payload.websiteId).set("attributes.title", payload.title).patch;

        return [patch];
      },

      undoPatches(payload) {
        let patch = _patchUpdater.default.create("class-website", payload.websiteId).set("attributes.title", payload.originalTitle).patch;
      },

      narrative(payload) {
        let models = defaultRelatedModels;
        let newTitle = payload.title;
        let originalTitle = payload.originalTitle;
        let websiteSlug = Ember.get(models, "classWebsite.attributes.slug");

        let context = _lodash.default.assign({}, defaultContext, {
          newTitle,
          originalTitle,
          websiteSlug
        });

        return {
          title: "Renamed Class Website",
          titleForAnalytics: "Class Website - Edit Title",
          description: `Change your website's name from "${originalTitle}" to "${originalTitle}"`,
          activity: defaultClassWebsiteActivity(payload, models),
          context
        };
      }

    },
    ADD_COURSE_TO_CLASS_WEBSITE: {
      params: {
        planbookId: "string",
        websiteId: "string",
        courseId: "string"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("class-website", payload.websiteId).addToSet("relationships.courses.data", {
          id: payload.courseId,
          type: "course"
        }).patch;

        return [patch];
      },

      undoPatches(payload) {
        let patch = _patchUpdater.default.create("class-website", payload.websiteId).pull("relationships.courses.data", {
          id: payload.courseId,
          type: "course"
        }).patch;

        return [patch];
      },

      narrative(payload, models) {
        let courseTitle = Ember.get(models, "course.attributes.title");
        let websiteTitle = Ember.get(models, "classWebsite.attributes.title");
        let websiteSlug = Ember.get(models, "classWebsite.attributes.slug");

        let context = _lodash.default.assign({}, defaultContext, {
          websiteTitle,
          courseTitle,
          websiteSlug
        });

        return {
          title: "Added class to class website",
          titleForAnalytics: "Class Website - Add Course",
          description: `Added ${courseTitle} to your "${websiteTitle}" website`,
          activity: defaultClassWebsiteActivity(payload, models, "updated"),
          context
        };
      }

    },
    REMOVE_COURSE_FROM_CLASS_WEBSITE: {
      params: {
        planbookId: "string",
        websiteId: "string",
        courseId: "string"
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("class-website", payload.websiteId).pull("relationships.courses.data", {
          id: payload.courseId,
          type: "course"
        }).patch;

        return [patch];
      },

      undoPatches(payload) {
        let patch = _patchUpdater.default.create("class-website", payload.websiteId).push("relationships.courses.data", {
          id: payload.courseId,
          type: "course"
        }).patch;

        return [patch];
      },

      narrative(payload, models) {
        let courseTitle = Ember.get(models, "course.attributes.title");
        let websiteTitle = Ember.get(models, "classWebsite.attributes.title");
        let websiteSlug = Ember.get(models, "classWebsite.attributes.slug");

        let context = _lodash.default.assign({}, defaultContext, {
          websiteTitle,
          courseTitle,
          websiteSlug
        });

        return {
          title: "Removed class from class website",
          titleForAnalytics: "Class Website - Remove Course",
          activity: defaultClassWebsiteActivity(payload, models, "removed"),
          description: `Removed ${courseTitle} from the "${websiteTitle}" website`,
          context
        };
      }

    },
    UPDATE_CLASS_WEBSITE_SLUG: {
      params: {
        planbookId: "string",
        websiteId: "string",
        slug: "string",
        originalSlug: "string"
      },
      patches: null,
      // patches(payload) {
      //   let patch = Patch.create("class-website", payload.websiteId).set("attributes.slug", payload.slug).patch
      //
      //   return [patch]
      // },
      // We don't allow undos because we actually change the slug on the server.
      undoPatches: null,

      narrative(payload, models) {
        let websiteTitle = Ember.get(models, "classWebsite.attributes.title");
        let newSlug = payload.slug;
        let oldSlug = payload.originalSlug;

        let context = _lodash.default.assign({}, defaultContext, {
          websiteTitle,
          newSlug,
          oldSlug
        });

        return {
          title: "Changed URL for class website",
          titleForAnalytics: "Class Website - Changed Slug",
          activity: defaultClassWebsiteActivity(payload, models),
          description: `Changed the custom url for your "${websiteTitle}" class website from "${oldSlug}" to "${newSlug}"`,
          context
        };
      }

    }
  };
  _exports.default = _default;
});