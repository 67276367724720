define("cc-frontend/components/icons/social-google-drive-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
  >
    <path d="M97.79 455.13l79.1-137h316.43l-79.11 137z" fill="#3777e3"></path>
    <path d="M335.11 318.09h158.21L335.11 44H176.89z" fill="#ffcf63"></path>
    <path d="M18.68 318.09l79.11 137L256 181 176.89 44z" fill="#11a861"></path>
  </svg>
  */
  {
    "id": "D/JnqGCe",
    "block": "[[[10,\"svg\"],[14,\"data-name\",\"Layer 1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M97.79 455.13l79.1-137h316.43l-79.11 137z\"],[14,\"fill\",\"#3777e3\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M335.11 318.09h158.21L335.11 44H176.89z\"],[14,\"fill\",\"#ffcf63\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M18.68 318.09l79.11 137L256 181 176.89 44z\"],[14,\"fill\",\"#11a861\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/social-google-drive-color.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});