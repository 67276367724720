define("cc-frontend/lib/actions/lesson/LESSON_PASTE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePasteLesson = preparePasteLesson;
  _exports.default = _exports.LESSON_PASTE = void 0;

  function preparePasteLesson(payload) {
    return {
      name: LESSON_PASTE.name,
      payload: payload,
      patches: LESSON_PASTE.patches(payload),
      undoPatches: LESSON_PASTE.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(LESSON_PASTE.narrative, payload)
    };
  }

  const LESSON_PASTE = {
    name: "LESSON_PASTE",
    params: {
      cardStackId: "string",
      lessonAttributes: "object",
      originalLessonAttributes: "object",
      copiedFrom: "string"
    },

    // This method doesn't apply new card ids because it's called from the  paste all lessons for Day
    // and that got super hard to  do. So, the  card ids need to be changed at the call site...which is a total bummer
    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId);

      patch.set(`attributes.cards`, payload.lessonAttributes.cards);
      patch.set(`attributes.title`, payload.lessonAttributes.title);
      patch.set(`attributes.subtitle`, payload.lessonAttributes.subtitle);
      return [patch.patch];
    },

    undoPatches(payload) {
      let originalLessonAttributes = payload.originalLessonAttributes || {
        cards: null,
        title: "",
        subtitle: ""
      };

      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId);

      patch.set(`attributes.cards`, originalLessonAttributes.cards);
      patch.set(`attributes.title`, originalLessonAttributes.title);
      patch.set(`attributes.subtitle`, originalLessonAttributes.subtitle);
      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);
      let donorCardStackTitle = models.cardStack ? models.cardStack.attributes.title : null;
      let recipientLessonTitle = context.cardStackTitle;
      let title = payload.copiedFrom === "template" ? "Replaced a Lesson with a Template" : "Pasted a Lesson";
      let titleForAnalytics = payload.copiedFrom === "template" ? "Lesson - Replace With Template" : "Lesson - Paste";
      let description = payload.copiedFrom === "template" ? `Replaced ${recipientLessonTitle} with your "${donorCardStackTitle}" template` : `Pasted ${donorCardStackTitle} over ${recipientLessonTitle}`;

      let newContext = _lodash.default.assign({}, context, {
        donorCardStackTitle,
        donorCardStackId: payload.lessonAttributes.parentCardStack
      });

      return {
        title: title,
        titleForAnalytics: titleForAnalytics,
        description: description,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models)
      };
    }

  };
  _exports.LESSON_PASTE = LESSON_PASTE;
  var _default = LESSON_PASTE;
  _exports.default = _default;
});