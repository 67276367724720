define("cc-frontend/components/icons/info-i", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.1582 8.9082C8.1582 8.49399 8.49399 8.1582 8.9082 8.1582H9.51654C10.2563 8.1582 10.8749 8.77675 10.8749 9.51654V12.5582C10.8749 12.682 10.9241 12.8008 11.0115 12.8882C11.099 12.9757 11.2177 13.0249 11.3415 13.0249H11.9499C12.3641 13.0249 12.6999 13.3607 12.6999 13.7749C12.6999 14.1891 12.3641 14.5249 11.9499 14.5249H11.3415C10.82 14.5249 10.3197 14.3178 9.95086 13.9489C9.58199 13.58 9.37487 13.0797 9.37487 12.5582V9.6582C8.5 9.6582 8.1582 9.62033 8.1582 8.9082Z"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.82077 6.61621C10.067 6.61621 10.2666 6.41661 10.2666 6.17038C10.2666 5.92414 10.067 5.72454 9.82077 5.72454V5.11621C9.23859 5.11621 8.7666 5.58816 8.7666 6.17038C8.7666 6.75259 9.23859 7.22454 9.82077 7.22454V6.61621Z"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.82083 5.11621C10.403 5.11621 10.875 5.58816 10.875 6.17038C10.875 6.75259 10.403 7.22454 9.82083 7.22454V6.61621C9.57461 6.61621 9.375 6.41661 9.375 6.17038C9.375 5.92414 9.57461 5.72454 9.82083 5.72454V5.11621Z"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.25 10.125C0.25 4.67119 4.67119 0.25 10.125 0.25C15.5788 0.25 20 4.67119 20 10.125C20 15.5788 15.5788 20 10.125 20C4.67119 20 0.25 15.5788 0.25 10.125ZM10.125 1.75C5.49962 1.75 1.75 5.49962 1.75 10.125C1.75 14.7504 5.49962 18.5 10.125 18.5C14.7504 18.5 18.5 14.7504 18.5 10.125C18.5 5.49962 14.7504 1.75 10.125 1.75Z"
    ></path>
  </svg>
  */
  {
    "id": "oq6IAWtn",
    "block": "[[[10,\"svg\"],[14,\"width\",\"20\"],[14,\"height\",\"20\"],[14,\"viewBox\",\"0 0 20 20\"],[14,\"fill\",\"none\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M8.1582 8.9082C8.1582 8.49399 8.49399 8.1582 8.9082 8.1582H9.51654C10.2563 8.1582 10.8749 8.77675 10.8749 9.51654V12.5582C10.8749 12.682 10.9241 12.8008 11.0115 12.8882C11.099 12.9757 11.2177 13.0249 11.3415 13.0249H11.9499C12.3641 13.0249 12.6999 13.3607 12.6999 13.7749C12.6999 14.1891 12.3641 14.5249 11.9499 14.5249H11.3415C10.82 14.5249 10.3197 14.3178 9.95086 13.9489C9.58199 13.58 9.37487 13.0797 9.37487 12.5582V9.6582C8.5 9.6582 8.1582 9.62033 8.1582 8.9082Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M9.82077 6.61621C10.067 6.61621 10.2666 6.41661 10.2666 6.17038C10.2666 5.92414 10.067 5.72454 9.82077 5.72454V5.11621C9.23859 5.11621 8.7666 5.58816 8.7666 6.17038C8.7666 6.75259 9.23859 7.22454 9.82077 7.22454V6.61621Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M9.82083 5.11621C10.403 5.11621 10.875 5.58816 10.875 6.17038C10.875 6.75259 10.403 7.22454 9.82083 7.22454V6.61621C9.57461 6.61621 9.375 6.41661 9.375 6.17038C9.375 5.92414 9.57461 5.72454 9.82083 5.72454V5.11621Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M0.25 10.125C0.25 4.67119 4.67119 0.25 10.125 0.25C15.5788 0.25 20 4.67119 20 10.125C20 15.5788 15.5788 20 10.125 20C4.67119 20 0.25 15.5788 0.25 10.125ZM10.125 1.75C5.49962 1.75 1.75 5.49962 1.75 10.125C1.75 14.7504 5.49962 18.5 10.125 18.5C14.7504 18.5 18.5 14.7504 18.5 10.125C18.5 5.49962 14.7504 1.75 10.125 1.75Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/info-i.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});