define("cc-frontend/helpers/has-many-collectively", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasManyCollectively;

  function hasManyCollectively(modelName, relationshipType, relationship) {
    return Ember.computed(`${modelName}.relationships.${relationship}.data.@each.id`, function () {
      const ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
      if (Ember.get(this, "store") === null) throw new Error("You need to inject the store before using hasManyCollectively: " + this.toString());

      let ids = _lodash.default.reject(_lodash.default.map(Ember.get(this, `${modelName}.relationships.${relationship}.data`), r => Ember.get(r, "id")), id => id === "" || id === null);

      return ObjectPromiseProxy.create({
        promise: Ember.RSVP.all(_lodash.default.map(ids, id => Ember.get(this, "store").find(relationshipType, id)))
      });
    });
  }
});