define("cc-frontend/components/user/avatar", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/lib/object-id-gen", "lodash-es/first"], function (_exports, _glimmerComponent, _objectIdGen, _first) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class={{this.cssClasses}}
    id={{this.elementId}}
    ...attributes
    {{on "mouseenter" this.onMouseEnter}}
    {{on "mouseleave" this.onMouseLeave}}
  >
    {{#if @invitedUser}}
      @
    {{else}}
      {{this.initials}}
    {{/if}}
    {{! </div> }}
    {{yield}}
  </div>
  */
  {
    "id": "zAgqA5F9",
    "block": "[[[11,0],[16,0,[30,0,[\"cssClasses\"]]],[16,1,[30,0,[\"elementId\"]]],[17,1],[4,[38,0],[\"mouseenter\",[30,0,[\"onMouseEnter\"]]],null],[4,[38,0],[\"mouseleave\",[30,0,[\"onMouseLeave\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    @\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"initials\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@invitedUser\",\"&default\"],false,[\"on\",\"if\",\"yield\"]]",
    "moduleName": "cc-frontend/components/user/avatar.hbs",
    "isStrictMode": false
  });

  let UserAvatar = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.computed("args.userSummary.attributes.{firstName,lastName}"), _dec5 = Ember.computed("args.email", "args.userSummary.attributes.{firstName,lastName}"), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class UserAvatar extends _glimmerComponent.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "basicTooltip", _descriptor, this);

      _initializerDefineProperty(this, "elementId", _descriptor2, this);

      _initializerDefineProperty(this, "showTooltip", _descriptor3, this);

      this.elementId = `guid-${_objectIdGen.default.create()}`;
    } // @computed("args.userSummary.attributes.avatarColor")


    get cssClasses() {
      let color = this.args.userSummary ? this.args.userSummary.attributes.avatarColor : "white";
      return `user-avatar ${this.args.avatarSizeClass} user-avatar--color-${color}`;
    }

    get initials() {
      if (this.args.userSummary) {
        let firstI = (0, _first.default)(this.args.userSummary.attributes.firstName || " ");
        let lastI = (0, _first.default)(this.args.userSummary.attributes.lastName || " ");
        return `${firstI}${lastI}`;
      } else {
        return "";
      }
    }

    get tooltipText() {
      if (this.args.invitedUser) {
        return this.args.email;
      } else if (this.args.userSummary) {
        var _this$args$userSummar, _this$args$userSummar2;

        return `${(_this$args$userSummar = this.args.userSummary) === null || _this$args$userSummar === void 0 ? void 0 : _this$args$userSummar.attributes.firstName} ${(_this$args$userSummar2 = this.args.userSummary) === null || _this$args$userSummar2 === void 0 ? void 0 : _this$args$userSummar2.attributes.lastName}`;
      } else {
        return "";
      }
    }

    onMouseEnter() {
      if (this.args.enableTooltip) {
        this.basicTooltip.show({
          targetId: this.elementId,
          message: this.tooltipText
        });
      }
    }

    onMouseLeave() {
      if (this.args.enableTooltip) {
        this.basicTooltip.hide({
          targetId: this.elementId
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basicTooltip", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "elementId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showTooltip", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initials", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "initials"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tooltipText", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "tooltipText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseEnter", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseLeave", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseLeave"), _class.prototype)), _class));
  _exports.default = UserAvatar;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UserAvatar);
});