define("cc-frontend/components/planner/perspectives/month/lesson-title.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-title": "_lesson-title_t8pr9z",
    "--has-focus": "_--has-focus_t8pr9z",
    "move-pad": "_move-pad_t8pr9z",
    "lesson-title__inner": "_lesson-title__inner_t8pr9z",
    "lesson-title__input": "_lesson-title__input_t8pr9z"
  };
  _exports.default = _default;
});