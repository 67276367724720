define("cc-frontend/components/d-s/simple-select", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <SelectBox
    @classNamePrefix="simple-select"
    @value={{@value}}
    @onPressKey={{this.handlePressKey}}
    @onPressDown={{this.handlePressDown}}
    @onPressUp={{this.handlePressUp}}
    @onPressEscape={{this.close}}
    @onClickOutside={{this.close}}
    @onSelect={{this.select}}
    ...attributes
    as |sb|
  >
    {{yield (merge sb (hash SelectedOption=(component sb.SelectedOption onclick=sb.open)))}}
  </SelectBox>
  */
  {
    "id": "fdSGdTfC",
    "block": "[[[8,[39,0],[[17,1]],[[\"@classNamePrefix\",\"@value\",\"@onPressKey\",\"@onPressDown\",\"@onPressUp\",\"@onPressEscape\",\"@onClickOutside\",\"@onSelect\"],[\"simple-select\",[30,2],[30,0,[\"handlePressKey\"]],[30,0,[\"handlePressDown\"]],[30,0,[\"handlePressUp\"]],[30,0,[\"close\"]],[30,0,[\"close\"]],[30,0,[\"select\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,4,[[28,[37,2],[[30,3],[28,[37,3],null,[[\"SelectedOption\"],[[50,[30,3,[\"SelectedOption\"]],0,null,[[\"onclick\"],[[30,3,[\"open\"]]]]]]]]],null]]],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@value\",\"sb\",\"&default\"],false,[\"select-box\",\"yield\",\"merge\",\"hash\",\"component\"]]",
    "moduleName": "cc-frontend/components/d-s/simple-select.hbs",
    "isStrictMode": false
  });

  let SimpleSelect = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class SimpleSelect extends _component.default {
    handlePressKey(e, sb) {
      sb.activateOptionForKeyCode(e.keyCode);

      if (!sb.isOpen) {
        sb.selectActiveOption();
      }
    }

    handlePressUp(e, sb) {
      e.preventDefault();
      sb.activatePreviousOption();
    }

    handlePressDown(e, sb) {
      e.preventDefault();
      sb.activateNextOption();
      sb.open();
    }

    close(e, sb) {
      sb.close();
    }

    select(value, sb) {
      this.args.onSelect(value);
      sb.close();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handlePressKey", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handlePressKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePressUp", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handlePressUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePressDown", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handlePressDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = SimpleSelect;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SimpleSelect);
});