define("cc-frontend/helpers/date-range", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateRange = dateRange;
  _exports.default = void 0;

  function dateRange(params
  /*, hash*/
  ) {
    var startDate = (0, _moment.default)(params[0]);
    var endDate = (0, _moment.default)(params[1]);
    var startMonth = startDate.format("MMMM");
    var endMonth = endDate.format("MMMM");

    if (startDate.format("MMMM D") === endDate.format("MMMM D")) {
      return startDate.format("MMMM D");
    } else if (startMonth === endMonth) {
      return startDate.format("MMM. D") + " - " + endDate.format("D");
    } else {
      return startDate.format("MMM. D") + " - " + endDate.format("MMM. D");
    }

    return params;
  }

  var _default = Ember.Helper.helper(dateRange);

  _exports.default = _default;
});