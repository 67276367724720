define("cc-frontend/components/user/stripe-downgrade", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StripeDowngradeUser = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
  {{#if isUnsubscribing}}
    <DS::ThreeDotSpinner />
  {{else}}
    {{buttonText}}
  {{/if}}
  
  */
  {
    "id": "jRVF4DUd",
    "block": "[[[1,\"\\n\"],[41,[33,1],[[[1,\"  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"isUnsubscribing\",\"d-s/three-dot-spinner\",\"buttonText\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec(_class = (_class2 = class StripeDowngradeUser extends Ember.Component.extend() {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stripe", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "rpc", _descriptor3, this);
    }

    click() {
      this.set("isUnsubscribing", true);
      let userId = Ember.get(this, "session.user.id");
      Ember.get(this, "rpc").execute("user_cancel_subscription_from_app", {
        userId: userId,
        subscriptionId: Ember.get(this, "subscriptionId")
      }).then(result => {
        this.onSuccess(userId);
      });
    }

    onSuccess() {// analytics.track("Subscription - Started Trial", {
      //   price: this.get("price")
      // })
      // If we find isPro is being encoded in the documents e.g. (cardStack.meta.permissions),
      // Then we'll want to re-enable this. Otherwise, looks like we're good.
      // window.location.reload()
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stripe", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "rpc", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = StripeDowngradeUser;
});