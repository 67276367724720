define("cc-frontend/transitions/fade-down", ["exports", "cc-frontend/transitions/fade-direction"], function (_exports, _fadeDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(opts = {}) {
    return _fadeDirection.default.call(this, "y", -1, opts, opts.offset);
  }
});