define("cc-frontend/components/portal-target", ["exports", "ember-stargate/components/portal-target"], function (_exports, _portalTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _portalTarget.default;
    }
  });
});