define("cc-frontend/components/planner/menus/day-menu/change-rotation/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Menus::Titles::WithBackArrow @goBack={{@goBack}} @title={{@title}} />
  */
  {
    "id": "XfUONU/7",
    "block": "[[[8,[39,0],null,[[\"@goBack\",\"@title\"],[[30,1],[30,2]]],null]],[\"@goBack\",\"@title\"],false,[\"planner/menus/titles/with-back-arrow\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/change-rotation/title.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});