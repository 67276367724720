define("cc-frontend/components/group/members-table/cells/upgraded-admin", ["exports", "@glint/environment-ember-loose/glimmer-component", "tracked-built-ins"], function (_exports, _glimmerComponent, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="group-member-table__info__admin-upgraded"
    id={{concat "admin-upgraded-" @row.id}}
    {{on "mouseenter" (set this "isHovering" true)}}
    {{on "mouseleave" (set this "isHovering" false)}}
  >
    Upgraded
    <br />
    for Free
  </div>
  {{#if this.isHovering}}
    <LiquidTether
      @target={{concat "#admin-upgraded-" @row.id}}
      @offset="-20px 0"
      @targetAttachment="bottom center"
      @attachment="top center"
      @class="popping-explainer-wrapper"
    >
      <div class="popping-explainer">
        <div class="popping-explainer__body">
          <div class="popping-explainer__header">
            Admins are upgraded for free
          </div>
          <div class="popping-explainer__subheader">
            Schools can have up to 5 admins
          </div>
        </div>
      </div>
    </LiquidTether>
  {{/if}}
  */
  {
    "id": "ZyO2Tmtb",
    "block": "[[[11,0],[24,0,\"group-member-table__info__admin-upgraded\"],[16,1,[28,[37,0],[\"admin-upgraded-\",[30,1,[\"id\"]]],null]],[4,[38,1],[\"mouseenter\",[28,[37,2],[[30,0],\"isHovering\",true],null]],null],[4,[38,1],[\"mouseleave\",[28,[37,2],[[30,0],\"isHovering\",false],null]],null],[12],[1,\"\\n  Upgraded\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n  for Free\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"isHovering\"]],[[[1,\"  \"],[8,[39,4],null,[[\"@target\",\"@offset\",\"@targetAttachment\",\"@attachment\",\"@class\"],[[28,[37,0],[\"#admin-upgraded-\",[30,1,[\"id\"]]],null],\"-20px 0\",\"bottom center\",\"top center\",\"popping-explainer-wrapper\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"popping-explainer\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"popping-explainer__body\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"popping-explainer__header\"],[12],[1,\"\\n          Admins are upgraded for free\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"popping-explainer__subheader\"],[12],[1,\"\\n          Schools can have up to 5 admins\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@row\"],false,[\"concat\",\"on\",\"set\",\"if\",\"liquid-tether\"]]",
    "moduleName": "cc-frontend/components/group/members-table/cells/upgraded-admin.hbs",
    "isStrictMode": false
  });

  let GroupMembersTableUpgradedCellAdmin = (_class = class GroupMembersTableUpgradedCellAdmin extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isHovering", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isHovering", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.default = GroupMembersTableUpgradedCellAdmin;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupMembersTableUpgradedCellAdmin);
});