define("cc-frontend/components/kb/prismic-article/wistia-video", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <script
    src="https://fast.wistia.com/embed/medias/{{@slice.primary.wistia_video_id}}.jsonp"
    async
  ></script>
  <div
    local-class="wistia_responsive_padding"
    style="padding:{{this.aspectRatio}}% 0 0 0;position:relative;"
  >
    <div
      class="wistia_responsive_wrapper"
      style="height:100%;left:0;position:absolute;top:0;width:100%;"
    >
      <div
        class="wistia_embed wistia_async_{{@slice.primary.wistia_video_id}} videoFoam=true"
        style="height:100%;position:relative;width:100%"
      ></div>
    </div>
  </div>
  */
  {
    "id": "unY0kYGY",
    "block": "[[[10,\"script\"],[15,\"src\",[29,[\"https://fast.wistia.com/embed/medias/\",[30,1,[\"primary\",\"wistia_video_id\"]],\".jsonp\"]]],[14,\"async\",\"\"],[12],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"wistia_responsive_padding\"],[[\"from\"],[\"cc-frontend/components/kb/prismic-article/wistia-video.css\"]]]]]],[15,5,[29,[\"padding:\",[30,0,[\"aspectRatio\"]],\"% 0 0 0;position:relative;\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"wistia_responsive_wrapper\"],[14,5,\"height:100%;left:0;position:absolute;top:0;width:100%;\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"wistia_embed wistia_async_\",[30,1,[\"primary\",\"wistia_video_id\"]],\" videoFoam=true\"]]],[14,5,\"height:100%;position:relative;width:100%\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@slice\"],false,[\"local-class\"]]",
    "moduleName": "cc-frontend/components/kb/prismic-article/wistia-video.hbs",
    "isStrictMode": false
  });

  class WistiaVideoComponent extends _component.default {
    get aspectRatio() {
      return this.args.slice.primary.height / this.args.slice.primary.width * 100;
    }

  }

  _exports.default = WistiaVideoComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, WistiaVideoComponent);
});