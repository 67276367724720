define("cc-frontend/components/planner/menus/lesson-menu/primary/title", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Menus::Titles::Primary @title="Lesson Menu" />
  */
  {
    "id": "7f7BN9g5",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Lesson Menu\"]],null]],[],false,[\"planner/menus/titles/primary\"]]",
    "moduleName": "cc-frontend/components/planner/menus/lesson-menu/primary/title.hbs",
    "isStrictMode": false
  });

  let LessonMenuPrimaryTitle = (_dec = (0, _component.tagName)(""), _dec(_class = class LessonMenuPrimaryTitle extends Ember.Component {}) || _class);
  _exports.default = LessonMenuPrimaryTitle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LessonMenuPrimaryTitle);
});