define("cc-frontend/components/planner/planbook/collaborations/link-sharing", ["exports", "@ember-decorators/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LinkSharing = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{#if (includes planbook.meta.permissions "can-change-collaborators")}}
      <div class="planbook-collaborators__share-link-question">
        <XToggle @value={{planbookSharedWithWorld}} @onToggle={{action "changePublicPermission"}} @theme="ios" @size="large" @onLabel="::true" @offLabel="::false" />
        <div class="planbook-collaborators__share-link-question-text">
          Allow anyone with a link to <strong>view</strong> (but not comment or edit) your planbook
        </div>
      </div>
    {{/if}}
    {{#if planbookSharedWithWorld}}
      <div class="planbook-collaborators__share-link-link">
        <div class="planbook-collaborators__share-link-link-title">Public link:</div>
        <a href={{concat "https://" hostName "/planbooks/" planbook.id "/planner/week"}} target="_blank">
          {{concat "https://" hostName "/planbooks/" planbook.id "/planner/week"}}
        </a>
      </div>
    {{/if}}
  
  */
  {
    "id": "yxSNtFsI",
    "block": "[[[1,\"\\n\"],[41,[28,[37,1],[[33,2,[\"meta\",\"permissions\"]],\"can-change-collaborators\"],null],[[[1,\"    \"],[10,0],[14,0,\"planbook-collaborators__share-link-question\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@value\",\"@onToggle\",\"@theme\",\"@size\",\"@onLabel\",\"@offLabel\"],[[36,4],[28,[37,5],[[30,0],\"changePublicPermission\"],null],\"ios\",\"large\",\"::true\",\"::false\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"planbook-collaborators__share-link-question-text\"],[12],[1,\"\\n        Allow anyone with a link to \"],[10,\"strong\"],[12],[1,\"view\"],[13],[1,\" (but not comment or edit) your planbook\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[33,4],[[[1,\"    \"],[10,0],[14,0,\"planbook-collaborators__share-link-link\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"planbook-collaborators__share-link-link-title\"],[12],[1,\"Public link:\"],[13],[1,\"\\n      \"],[10,3],[15,6,[28,[37,6],[\"https://\",[33,7],\"/planbooks/\",[33,2,[\"id\"]],\"/planner/week\"],null]],[14,\"target\",\"_blank\"],[12],[1,\"\\n        \"],[1,[28,[35,6],[\"https://\",[33,7],\"/planbooks/\",[33,2,[\"id\"]],\"/planner/week\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"includes\",\"planbook\",\"x-toggle\",\"planbookSharedWithWorld\",\"action\",\"concat\",\"hostName\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec3 = Ember.computed("planbook.attributes.publicAccess.roles.[]"), _dec4 = Ember.computed, _dec5 = Ember._action, _dec(_class = (_class2 = class LinkSharing extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "rpc", _descriptor, this);
    }

    get planbookSharedWithWorld() {
      return _lodash.default.first(Ember.get(this, "planbook.attributes.publicAccess.roles")) === "planbook:is-viewer";
    }

    get hostName() {
      return typeof window === "undefined" && typeof window.location === "undefined" ? "https://www.commoncurriculum.com" : window.location.host;
    }

    changePublicPermission() {
      let role = Ember.get(this, "planbookSharedWithWorld") ? "NONE" : "VIEWER";
      this.rpc.planbook_collaboration_change_public_permission({
        planbookId: this.planbook.id,
        role: role
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "planbookSharedWithWorld", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "planbookSharedWithWorld"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hostName", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hostName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changePublicPermission", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "changePublicPermission"), _class2.prototype)), _class2)) || _class);
  _exports.default = LinkSharing;
});