define("cc-frontend/templates/user/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vwaiSCw9",
    "block": "[[[1,[28,[35,0],[[33,1,[\"attributes\",\"firstName\"]],\" \",[33,1,[\"attributes\",\"lastName\"]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@title\"],[\"user.show\"]],null],[1,\"\\n\"],[8,[39,3],null,null,null],[1,\"\\n\"],[8,[39,4],null,[[\"@user\"],[[36,1]]],null],[1,\"\\n\"],[46,[28,[37,6],null,null],null,null,null]],[],false,[\"page-title\",\"model\",\"record-page-visit\",\"default-navbar\",\"user/home\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/user/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});