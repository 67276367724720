define("cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(planbookId, userId, verb = "edited") {
    return {
      actor: `user-${userId}`,
      verb: verb,
      object: `planbook-${planbookId}`,
      to: [`planbook-edits:planbook-${planbookId}`]
    };
  }
});