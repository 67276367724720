define("cc-frontend/components/planner/menus/day-menu/toggle-courses/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="class-list">
    {{#each @courses as |course|}}
      {{#unless (contains course.id @hiddenCourseIds)}}
        <Planner::Course::Date::CourseDateCombined
          @dateString={{@dateString}}
          @planbook={{@planbook}}
          @rotationCalendar={{@rotationCalendar}}
          @course={{course}}
          as |courseDate|
        >
          <div
            local-class="toggle-course-is-on {{if courseDate.isOff "is-off" "is-on"}}"
            class="{{if (not courseDate.isOff) "take-course-background-color"}}
              take-course-border-color
              {{course.attributes.color}}"
          >
            <div local-class="toggle-course-is-on__course-title">
              <span>
                {{course.attributes.title}}
              </span>
            </div>
            <Planner::Course::Date::CourseToggle
              @course={{course}}
              @courseDate={{courseDate}}
              @dateString={{@dateString}}
            />
  
          </div>
        </Planner::Course::Date::CourseDateCombined>
      {{/unless}}
    {{/each}}
  </div>
  */
  {
    "id": "GqN4OTuO",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"class-list\"],[[\"from\"],[\"cc-frontend/components/planner/menus/day-menu/toggle-courses/body.css\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[41,[51,[28,[37,4],[[30,2,[\"id\"]],[30,3]],null]],[[[1,\"      \"],[8,[39,5],null,[[\"@dateString\",\"@planbook\",\"@rotationCalendar\",\"@course\"],[[30,4],[30,5],[30,6],[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[29,[[28,[37,6],[[52,[28,[37,8],[[30,7,[\"isOff\"]]],null],\"take-course-background-color\"],\"\\n            take-course-border-color\\n            \",[30,2,[\"attributes\",\"color\"]]],null],\" \",[28,[37,0],[[28,[37,6],[\"toggle-course-is-on \",[52,[30,7,[\"isOff\"]],\"is-off\",\"is-on\"]],null]],[[\"from\"],[\"cc-frontend/components/planner/menus/day-menu/toggle-courses/body.css\"]]]]]],[12],[1,\"\\n          \"],[10,0],[15,0,[29,[[28,[37,0],[\"toggle-course-is-on__course-title\"],[[\"from\"],[\"cc-frontend/components/planner/menus/day-menu/toggle-courses/body.css\"]]]]]],[12],[1,\"\\n            \"],[10,1],[12],[1,\"\\n              \"],[1,[30,2,[\"attributes\",\"title\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[39,9],null,[[\"@course\",\"@courseDate\",\"@dateString\"],[[30,2],[30,7],[30,4]]],null],[1,\"\\n\\n        \"],[13],[1,\"\\n      \"]],[7]]]]],[1,\"\\n\"]],[]],null]],[2]],null],[13]],[\"@courses\",\"course\",\"@hiddenCourseIds\",\"@dateString\",\"@planbook\",\"@rotationCalendar\",\"courseDate\"],false,[\"local-class\",\"each\",\"-track-array\",\"unless\",\"contains\",\"planner/course/date/course-date-combined\",\"concat\",\"if\",\"not\",\"planner/course/date/course-toggle\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/toggle-courses/body.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});