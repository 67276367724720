define("cc-frontend/components/group/members-table/headers/upgraded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-flex tw-flex-col">
    <div class="group-members-table__header__header">
      Upgraded?
    </div>
    <div class="group-members-table__header__subheader">
  
      {{@teacherMemberships.length}}
      {{pluralize @teacherMemberships.length "Teacher" "Teachers"}}
  
    </div>
  </div>
  {{yield}}
  */
  {
    "id": "Bn+Dd1kZ",
    "block": "[[[10,0],[14,0,\"tw-flex tw-flex-col\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"group-members-table__header__header\"],[12],[1,\"\\n    Upgraded?\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"group-members-table__header__subheader\"],[12],[1,\"\\n\\n    \"],[1,[30,1,[\"length\"]]],[1,\"\\n    \"],[1,[28,[35,0],[[30,1,[\"length\"]],\"Teacher\",\"Teachers\"],null]],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,2,null]],[\"@teacherMemberships\",\"&default\"],false,[\"pluralize\",\"yield\"]]",
    "moduleName": "cc-frontend/components/group/members-table/headers/upgraded.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});