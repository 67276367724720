define("cc-frontend/components/planner/course/date/course-date-combined", ["exports", "@ember-decorators/component", "@marcj/marshal", "cc-frontend/models/course-date-combined", "lodash-es"], function (_exports, _component, _marshal, _courseDateCombined, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateCourseDateCombined = calculateCourseDateCombined;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.course}}
    {{yield this.courseDateCombined}}
  {{else}}
    <DS::ThreeDotSpinner />
  {{/if}}
  */
  {
    "id": "a6Beqna+",
    "block": "[[[41,[30,0,[\"course\"]],[[[1,\"  \"],[18,1,[[30,0,[\"courseDateCombined\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"yield\",\"d-s/three-dot-spinner\"]]",
    "moduleName": "cc-frontend/components/planner/course/date/course-date-combined.hbs",
    "isStrictMode": false
  });

  /**
   * Use this to find if a given date is on/off, in/out of the school year, has
   * date annotations, etc
   */
  function calculateCourseDateCombined(dateString, course, planbook, rotationCalendar, phantomCardStackIdFinder) {
    let rotationDate = (0, _lodashEs.find)(rotationCalendar.attributes.dates, date => date.attributes.dateString === dateString) || {
      attributes: {
        semesterId: null,
        rotationId: null
      }
    };
    let courseDateCustom = (0, _lodashEs.find)((0, _lodashEs.filter)(course.attributes.calendar.dates, date => date.type === "course-date-custom"), date => date.attributes.dateString === dateString) || null;
    let courseDateDefault = (0, _lodashEs.find)((0, _lodashEs.filter)(course.attributes.calendar.dates, date => date.type === "course-date-default"), date => {
      return (// @ts-ignore
        date.attributes.semesterId === rotationDate.attributes.semesterId && // @ts-ignore
        date.attributes.rotationId === rotationDate.attributes.rotationId
      );
    });
    let firstDay = (0, _lodashEs.min)((0, _lodashEs.map)(rotationCalendar.attributes.semesters, "startDate"));
    let lastDay = (0, _lodashEs.max)((0, _lodashEs.map)(rotationCalendar.attributes.semesters, "endDate"));
    if (firstDay === undefined || lastDay === undefined) throw Error("No start or end days");
    let planbookDateDefaultIsOff = (0, _lodashEs.includes)(planbook.attributes.calendar.datesOff, `${rotationDate.attributes.semesterId}:${rotationDate.attributes.rotationId}`);
    let planbookDateCustomIsOff = (0, _lodashEs.includes)(planbook.attributes.calendar.datesOff, dateString);
    let courseDateDefaultIsOff = (0, _lodashEs.includes)(course.attributes.calendar.datesOff, `${rotationDate.attributes.semesterId}:${rotationDate.attributes.rotationId}`);
    let courseDateCustomIsOff = (0, _lodashEs.includes)(course.attributes.calendar.datesOff, dateString);
    let planbookIsOff = planbookDateCustomIsOff ? planbookDateCustomIsOff : planbookDateDefaultIsOff;
    let courseIsOff = courseDateCustomIsOff ? courseDateCustomIsOff : courseDateDefaultIsOff; // @ts-ignore TS is not picking up the output of the filter above

    let courseDateForcedOn = courseDateCustom ? courseDateCustom.attributes.isForcedOn : false;
    let isOff = !courseDateForcedOn && (planbookIsOff ? planbookIsOff : courseIsOff);
    let visibleCourseDateAnnotations = (0, _lodashEs.filter)(course.attributes.calendar.dateAnnotations, annotation => {
      return annotation.type === "date-annotation-course-custom-single" && // @ts-ignore TS isn't picking up first check here
      annotation.attributes.dateString === dateString && // @ts-ignore
      annotation.attributes.showAnnotation === true;
    });
    let cardStackId = courseDateCustom ? courseDateCustom.attributes.cardStackId : null;
    let hasCardStackId = cardStackId !== null;
    let cardStackTemplateId = courseDateDefault ? courseDateDefault.attributes.cardStackId : null;
    let phantomCardStackId = cardStackId === null ? phantomCardStackIdFinder(course.id, dateString) : null;
    let hasLesson = (0, _lodashEs.isNil)(courseDateCustom === null || courseDateCustom === void 0 ? void 0 : courseDateCustom.attributes.cardStackId) !== true;
    return (0, _marshal.plainToClass)(_courseDateCombined.default, {
      cardStackId: cardStackId,
      cardStackTemplateId: cardStackTemplateId,
      dateString: dateString,
      hasLesson: hasLesson,
      hasNoTemplate: cardStackTemplateId === null,
      hasVisibleAnnotations: visibleCourseDateAnnotations.length > 0,
      hideFromCalendar: isOff && hasCardStackId !== true && visibleCourseDateAnnotations.length === 0,
      isAfterSchoolYear: dateString > lastDay,
      isBeforeSchoolYear: dateString < firstDay,
      isForcedOn: courseDateForcedOn,
      isInsideSchoolYear: dateString >= firstDay && dateString <= lastDay,
      isNewLesson: cardStackId === null,
      isOff: isOff,
      isOffByDefault: courseDateDefaultIsOff,
      phantomCardStackId: phantomCardStackId,
      showLesson: isOff === false || isOff === true && hasLesson,
      showNoticeOfDanglingLesson: isOff && hasLesson,
      // @ts-ignore Not picking put that it's a course date custom
      unitEnd: courseDateCustom ? courseDateCustom.attributes.unitEnd : [],
      // @ts-ignore Not picking put that it's a course date custom
      unitIds: courseDateCustom ? courseDateCustom.attributes.unitIds : [],
      // @ts-ignore Not picking put that it's a course date custom
      unitStart: courseDateCustom ? courseDateCustom.attributes.unitStart : [],
      visibleAnnotations: visibleCourseDateAnnotations
    });
  }

  let CourseDateCombinedComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.computed("dateString", "course.attributes.calendar.version", "rotationCalendar.attributes.dates", "planbook.attributes.calendar.version"), _dec(_class = (_class2 = class CourseDateCombinedComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "rotationCalendar", void 0);

      _defineProperty(this, "course", void 0);

      _defineProperty(this, "planbook", void 0);

      _initializerDefineProperty(this, "phantomCardStacks", _descriptor, this);
    }

    get courseDateCombined() {
      return calculateCourseDateCombined(this.dateString, this.course, this.planbook, this.rotationCalendar, (courseId, dateString) => this.phantomCardStacks.findCardStackIdForDate(courseId, dateString));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "phantomCardStacks", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "courseDateCombined", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "courseDateCombined"), _class2.prototype)), _class2)) || _class);
  _exports.default = CourseDateCombinedComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CourseDateCombinedComponent);
});