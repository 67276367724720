define("cc-frontend/components/dialogs/ask-to-leave-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-question" style="display: block;">
    ?
  </div>
  {{! <h1 class="create-annotation__title">Would you like to show a note or hide the class for the day?</h1> }}
  {{! <h1 class="create-annotation__title">Do you want to see a note in place of the lesson or would you like to completely hide the course for the day?</h1> }}
  <h1 class="create-annotation__title">
    Do you want to leave a note on this class?
  </h1>
  <h2 class="create-annotation__description">
    You can turn this class back on from the "Day Menu".
  </h2>
  <div class="create-annotation__submit-buttons">
    <div class="create-annotation__submit-button" {{action @accept true}}>
      Yes, let me add a note
    </div>
    <div class="create-annotation__submit-button" {{action @accept false}}>
      No, completely hide the class
    </div>
  </div>
  <div class="create-annotation__cancel-button-wrapper">
    <div class="btn btn-link create-annotation__cancel-button" {{action @decline}}>
      Cancel
    </div>
  </div>
  */
  {
    "id": "R2Zh6v0W",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-question\"],[14,5,\"display: block;\"],[12],[1,\"\\n  ?\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Do you want to leave a note on this class?\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description\"],[12],[1,\"\\n  You can turn this class back on from the \\\"Day Menu\\\".\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[[30,0],[30,1],true],null],[12],[1,\"\\n    Yes, let me add a note\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[[30,0],[30,1],false],null],[12],[1,\"\\n    No, completely hide the class\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__cancel-button-wrapper\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"btn btn-link create-annotation__cancel-button\"],[4,[38,0],[[30,0],[30,2]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\",\"@decline\"],false,[\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/ask-to-leave-note.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});