define("cc-frontend/templates/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0BKANfs2",
    "block": "[[[1,[28,[35,0],[\"Cc Support & Knowledge Base\"],null]],[1,\"\\n\"],[8,[39,1],null,[[\"@title\"],[\"help\"]],null],[1,\"\\n\"],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"page-title\",\"record-page-visit\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/help.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});