define("cc-frontend/templates/icons/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qG/4yFA7",
    "block": "[[[1,\"`\\n\"],[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"viewBox\",\"0 0 24 24\"],[14,5,\"enable-background:new 0 0 24 24;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"title\"],[12],[1,\"\\n      search\\n    \"],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M23.5,24c-0.134,0-0.259-0.052-0.354-0.146l-9.004-9.004C12.582,16.238,10.588,17,8.5,17C3.813,17,0,13.187,0,8.5\\n  \\t\\tC0,3.813,3.813,0,8.5,0C13.187,0,17,3.813,17,8.5c0,2.088-0.762,4.082-2.151,5.642l9.004,9.004C23.948,23.241,24,23.366,24,23.5\\n  \\t\\ts-0.052,0.259-0.146,0.354S23.634,24,23.5,24z M8.5,1C4.365,1,1,4.365,1,8.5S4.365,16,8.5,16S16,12.635,16,8.5S12.635,1,8.5,1z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n`\"]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/search.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});