define("cc-frontend/components/dialogs/confirm-delete-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-warning pulse-warning" style="display: block;">
    !
  </div>
  {{! <h1 class="create-annotation__title">How thorougly would you like to delete this lesson?</h1> }}
  <h1 class="create-annotation__title">
    Would you like to delete this unit?
  </h1>
  <div class="create-annotation__submit-buttons">
    <div class="create-annotation__submit-button" {{on "click" (fn @accept false)}}>
      Delete the Unit
    </div>
    <div class="create-annotation__submit-button" {{on "click" (fn @accept true)}}>
      Delete the Unit AND lessons
    </div>
  </div>
  <div class="create-annotation__cancel-button-wrapper">
    <div class="btn btn-link create-annotation__cancel-button" {{on "click" @decline}}>
      Cancel
    </div>
  </div>
  */
  {
    "id": "/kLAZrnN",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-warning pulse-warning\"],[14,5,\"display: block;\"],[12],[1,\"\\n  !\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Would you like to delete this unit?\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1],false],null]],null],[12],[1,\"\\n    Delete the Unit\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1],true],null]],null],[12],[1,\"\\n    Delete the Unit AND lessons\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__cancel-button-wrapper\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"btn btn-link create-annotation__cancel-button\"],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\",\"@decline\"],false,[\"on\",\"fn\"]]",
    "moduleName": "cc-frontend/components/dialogs/confirm-delete-unit.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});