define("cc-frontend/components/planner/planbook/create-edit/templates-by-course/item.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "item": "_item_pu9cfh",
    "course-title": "_course-title_pu9cfh",
    "template-info": "_template-info_pu9cfh",
    "template-actions": "_template-actions_pu9cfh",
    "template-title": "_template-title_pu9cfh",
    "change-icon": "_change-icon_pu9cfh",
    "choose-button": "_choose-button_pu9cfh",
    "change-button": "_change-button_pu9cfh",
    "edit-button": "_edit-button_pu9cfh",
    "change-text": "_change-text_pu9cfh"
  };
  _exports.default = _default;
});