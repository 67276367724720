define("cc-frontend/components/planner/menus/lesson-menu/primary/body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "two-line-row__sub-heading": "_two-line-row__sub-heading_id2h6g"
  };
  _exports.default = _default;
});