define("cc-frontend/components/user/create-planbook-form.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "user-sign__planbook-years": "_user-sign__planbook-years_b2b0a6",
    "user-sign__planbook-year": "_user-sign__planbook-year_b2b0a6",
    "is-active": "_is-active_b2b0a6",
    "rotation-section": "_rotation-section_b2b0a6",
    "rotation-list": "_rotation-list_b2b0a6",
    "rotation": "_rotation_b2b0a6",
    "rotation-input": "_rotation-input_b2b0a6",
    "rotation-delete": "_rotation-delete_b2b0a6",
    "rotation-add": "_rotation-add_b2b0a6"
  };
  _exports.default = _default;
});