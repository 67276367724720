define("cc-frontend/services/mouse-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MouseDown extends Ember.Service {}

  _exports.default = MouseDown;
});