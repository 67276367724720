define("cc-frontend/components/icons/ios-folder-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <path
      d="M457 96H215c-2.791 0-4.271-.585-6.094-2.408l-22.501-22.501-.168-.162C181.365 66.333 177.361 64 169 64H57c-13.785 0-25 10.317-25 23v336c0 13.317 11.683 25 25 25h400c12.683 0 23-11.215 23-25V119c0-13.327-9.673-23-23-23zM57 80h112c3.497 0 3.737.189 6.175 2.488l22.417 22.418c4.84 4.84 10.372 7.094 17.408 7.094h242c4.448 0 7 2.552 7 7v42.376c-2.504-.888-5.195-1.376-8-1.376H56c-2.805 0-5.496.488-8 1.376V87c0-4.323 4.672-7 9-7zm407 343c0 4.328-2.677 9-7 9H57c-4.458 0-9-4.542-9-9V184c0-4.411 3.589-8 8-8h400c4.411 0 8 3.589 8 8v239z"
    ></path>
  </svg>
  */
  {
    "id": "JwhL/5wG",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M457 96H215c-2.791 0-4.271-.585-6.094-2.408l-22.501-22.501-.168-.162C181.365 66.333 177.361 64 169 64H57c-13.785 0-25 10.317-25 23v336c0 13.317 11.683 25 25 25h400c12.683 0 23-11.215 23-25V119c0-13.327-9.673-23-23-23zM57 80h112c3.497 0 3.737.189 6.175 2.488l22.417 22.418c4.84 4.84 10.372 7.094 17.408 7.094h242c4.448 0 7 2.552 7 7v42.376c-2.504-.888-5.195-1.376-8-1.376H56c-2.805 0-5.496.488-8 1.376V87c0-4.323 4.672-7 9-7zm407 343c0 4.328-2.677 9-7 9H57c-4.458 0-9-4.542-9-9V184c0-4.411 3.589-8 8-8h400c4.411 0 8 3.589 8 8v239z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/ios-folder-outline.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});