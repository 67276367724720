define("cc-frontend/components/icons/bump-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M10,22.968c-0.266,0 -0.517,-0.104 -0.706,-0.292c-0.189,-0.188 -0.293,-0.439 -0.294,-0.707v-2.476c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v2.471c0.005,-0.001 0.007,-0.002 0.008,-0.002l12.683,-9.966l-12.692,-9.97l0.001,2.468c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.468c0,-0.222 0.075,-0.44 0.212,-0.615c0.165,-0.211 0.402,-0.345 0.667,-0.377c0.041,-0.005 0.082,-0.007 0.122,-0.007c0.225,0 0.438,0.073 0.615,0.212l12.693,9.972c0.062,0.049 0.119,0.106 0.168,0.169c0.165,0.209 0.238,0.47 0.206,0.736c-0.032,0.265 -0.165,0.502 -0.375,0.667l-12.692,9.972c-0.175,0.137 -0.394,0.213 -0.615,0.213c0.001,-3.55271e-15 -0.001,-3.55271e-15 -0.001,-3.55271e-15Z"
      ></path>
      <path
        d="M2,22.967c-0.266,0 -0.517,-0.104 -0.706,-0.292c-0.189,-0.188 -0.293,-0.439 -0.294,-0.707v-19.942c0,-0.222 0.075,-0.44 0.212,-0.615c0.165,-0.211 0.402,-0.345 0.667,-0.377c0.041,-0.005 0.082,-0.007 0.122,-0.007c0.225,0 0.438,0.073 0.615,0.212l12.693,9.972c0.062,0.049 0.119,0.106 0.169,0.169c0.164,0.209 0.238,0.47 0.206,0.736c-0.032,0.265 -0.165,0.502 -0.375,0.667l-12.692,9.971c-0.175,0.137 -0.394,0.213 -0.615,0.213c0,-3.55271e-15 -0.002,-3.55271e-15 -0.002,-3.55271e-15Zm0,-20.94v-8.88178e-16v19.941l12.691,-9.971l-12.691,-9.97v-8.88178e-16Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "pVWQlD5o",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M10,22.968c-0.266,0 -0.517,-0.104 -0.706,-0.292c-0.189,-0.188 -0.293,-0.439 -0.294,-0.707v-2.476c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v2.471c0.005,-0.001 0.007,-0.002 0.008,-0.002l12.683,-9.966l-12.692,-9.97l0.001,2.468c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.468c0,-0.222 0.075,-0.44 0.212,-0.615c0.165,-0.211 0.402,-0.345 0.667,-0.377c0.041,-0.005 0.082,-0.007 0.122,-0.007c0.225,0 0.438,0.073 0.615,0.212l12.693,9.972c0.062,0.049 0.119,0.106 0.168,0.169c0.165,0.209 0.238,0.47 0.206,0.736c-0.032,0.265 -0.165,0.502 -0.375,0.667l-12.692,9.972c-0.175,0.137 -0.394,0.213 -0.615,0.213c0.001,-3.55271e-15 -0.001,-3.55271e-15 -0.001,-3.55271e-15Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M2,22.967c-0.266,0 -0.517,-0.104 -0.706,-0.292c-0.189,-0.188 -0.293,-0.439 -0.294,-0.707v-19.942c0,-0.222 0.075,-0.44 0.212,-0.615c0.165,-0.211 0.402,-0.345 0.667,-0.377c0.041,-0.005 0.082,-0.007 0.122,-0.007c0.225,0 0.438,0.073 0.615,0.212l12.693,9.972c0.062,0.049 0.119,0.106 0.169,0.169c0.164,0.209 0.238,0.47 0.206,0.736c-0.032,0.265 -0.165,0.502 -0.375,0.667l-12.692,9.971c-0.175,0.137 -0.394,0.213 -0.615,0.213c0,-3.55271e-15 -0.002,-3.55271e-15 -0.002,-3.55271e-15Zm0,-20.94v-8.88178e-16v19.941l12.691,-9.971l-12.691,-9.97v-8.88178e-16Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/bump-right.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});