define("cc-frontend/models/course-calendar-date", ["exports", "cc-frontend/lib/structor_2", "cc-frontend/models/course-calendar-date-custom", "cc-frontend/models/course-calendar-date-default"], function (_exports, _structor_, _courseCalendarDateCustom, _courseCalendarDateDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CourseCalendarDate extends _structor_.default {
    defineStruct() {
      return {
        type: null,
        id: null,
        attributes: model => {
          if (model.type === "course-date-custom") return new _courseCalendarDateCustom.default(model.attributes);
          if (model.type === "course-date-default") return new _courseCalendarDateDefault.default(model.attributes);
        }
      };
    }

  }

  _exports.default = CourseCalendarDate;
});