define("cc-frontend/components/d-s/imgix", ["exports", "@glimmer/component", "lodash-es"], function (_exports, _component, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <img
    ...attributes
    srcset={{this.srcSet}}
    src={{this._src}}
    width={{@width}}
    height={{@height}}
    loading={{this._loading}}
  />
  */
  {
    "id": "wW03BNe5",
    "block": "[[[11,\"img\"],[17,1],[16,\"srcset\",[30,0,[\"srcSet\"]]],[16,\"src\",[30,0,[\"_src\"]]],[16,\"width\",[30,2]],[16,\"height\",[30,3]],[16,\"loading\",[30,0,[\"_loading\"]]],[12],[13]],[\"&attrs\",\"@width\",\"@height\"],false,[]]",
    "moduleName": "cc-frontend/components/d-s/imgix.hbs",
    "isStrictMode": false
  });

  /**
   * Usage:
  
    <DS::Imgix
      @src="https://commoncurriculum.imgix.net/items/p9uddkq5/download"
      @imgixParams={{hash crop="faces" width="1200"}}
      class="feature-callout__svg"
    />
  
    or
  
    <DS::Imgix
      @src="https://commoncurriculum.imgix.net/items/p9uddkq5/download"
      @imgixParams={{hash crop="faces" width="1200"}}
      class="feature-callout__svg"
      @height="100"
      @width="100"
    />
  
  
    The imgixParams overwrite all other settings (e.g. width, height) so you
    can set the width/height of the image and send a different setting to imgix
   */
  class DSImgixComponent extends _component.default {
    get srcSet() {
      if ((0, _lodashEs.isNil)(this.args.src)) return "";
      return (0, _lodashEs.map)(DPR_QUALITY_VALUES, (q, index) => {
        let dpr = index + 1;
        let url = buildURL(this.args.src, this.buildImgixUrlParams({
          imgixParams: this.args.imgixParams,
          q,
          dpr
        }));
        return `${url} ${dpr}x`;
      }).join(", ");
    }
    /**
     * Will construct params based on the following guidelines:
     * - Will use @height unless the imgixParams include height
     * - Will use @width unless the imgixParams include width.
     * - Will use @q unless igmixParmas include q
     * - Will use @dpr unless imgixParams include DPR
     *
     */


    buildImgixUrlParams({
      imgixParams,
      q,
      dpr
    }) {
      let params = {}; // As we might want to pass in null, we're not checking if the value is nil
      // instead, we're checking if we're passing in the key. If not,  and we've
      // set an height/width on the image itself, we send that height/width to imigx.
      // The use case here is we're rendering an image in a thumbnail and we set
      // <img heigh=50 width=50> and we want imgix to return an image of the same size.
      // We don't want to have to also do @imigxParams={{hash h=50 w=50}}

      if (!(0, _lodashEs.has)(imgixParams, "w") && !(0, _lodashEs.has)(imgixParams, "width") && !(0, _lodashEs.isNil)(this.args.width)) {
        params.w = this.args.width;
      }

      if (!(0, _lodashEs.has)(imgixParams, "h") && !(0, _lodashEs.has)(imgixParams, "height") && !(0, _lodashEs.isNil)(this.args.height)) {
        params.h = this.args.height;
      }

      if (q) {
        params.q = q;
      }

      if (dpr) {
        params.dpr = dpr;
      } // Because we'll never remember to pass h/w to `imgixParams` instead of height/width,
      // here we "rename" the param to h or w.
      // Why rename? The src url coming from prismic has height/width set as h/w so
      // we use the same key so they'll properly clobber each other.


      let iParams = (0, _lodashEs.clone)(imgixParams);

      if ((0, _lodashEs.has)(iParams, "width")) {
        iParams.w = iParams.width;
        delete iParams.width;
      }

      if ((0, _lodashEs.has)(iParams, "height")) {
        iParams.h = iParams.height;
        delete iParams.height;
      }

      return Object.assign({
        auto: "format"
      }, params, iParams);
    }

    get _src() {
      if ((0, _lodashEs.isNil)(this.args.src)) return "";
      return buildURL(this.args.src, this.buildImgixUrlParams({
        imgixParams: this.args.imgixParams
      }));
    } // Default to "auto"


    get _loading() {
      return this.args.loading || "auto";
    }

  }

  _exports.default = DSImgixComponent;

  function extractQueryParams(src) {
    const splitSrc = src.split("?");
    const url = splitSrc[0];
    const query = splitSrc[1];

    if (!query) {
      return [url, {}];
    }

    const paramsPairs = query.split("&");
    const params = {};
    const len = paramsPairs.length;

    for (let i = 0; i < len; i++) {
      const param = paramsPairs[i];
      const splitParam = param.split("=");
      const key = splitParam[0];
      const val = splitParam[1];
      params[key] = decodeURIComponent(val);
    }

    return [url, params];
  }

  const DPR_QUALITY = {
    q_dpr1: 75,
    q_dpr2: 50,
    q_dpr3: 35,
    q_dpr4: 23,
    q_dpr5: 20
  };
  const DPR_QUALITY_VALUES = Object.values(DPR_QUALITY); // Types from https://github.com/DefinitelyTyped/DefinitelyTyped/pull/40289/files#diff-881ddc76d18998f7d8b00df0d8371f8cR201
  // TO UPDATE THESE
  // - Just copy and paste
  // - I only made changes to the LongForm interface to
  // incorporate all the long form of the abbreviations they have in the
  // PARAMS_EXPANSION object

  /*
  Copyright © 2015 by Coursera
  Licensed under the Apache License 2.0, seen https://github.com/coursera/react-imgix/blob/master/LICENSE
  Minor syntax modifications have been made
  */
  // var Base64 = require("js-base64").Base64
  // @see https://www.imgix.com/docs/reference
  var PARAM_EXPANSION = Object.freeze({
    // Adjustment
    brightness: "bri",
    contrast: "con",
    exposure: "exp",
    gamma: "gam",
    highlights: "high",
    hue: "hue",
    invert: "invert",
    saturation: "sat",
    shaddows: "shad",
    sharpness: "sharp",
    "unsharp-mask": "usm",
    "unsharp-radius": "usmrad",
    vibrance: "vib",
    // Automatic
    "auto-features": "auto",
    // Background
    "background-color": "bg",
    // Blend
    blend: "blend",
    "blend-mode": "bm",
    "blend-align": "ba",
    "blend-alpha": "balph",
    "blend-padding": "bp",
    "blend-width": "bw",
    "blend-height": "bh",
    "blend-fit": "bf",
    "blend-crop": "bc",
    "blend-size": "bs",
    "blend-x": "bx",
    "blend-y": "by",
    // Border & Padding
    border: "border",
    padding: "pad",
    // Face Detection
    "face-index": "faceindex",
    "face-padding": "facepad",
    faces: "faces",
    // Format
    "chroma-subsampling": "chromasub",
    "color-quantization": "colorquant",
    download: "dl",
    DPI: "dpi",
    format: "fm",
    "lossless-compression": "lossless",
    quality: "q",
    dpr: "dpr",
    // Mask
    "mask-image": "mask",
    // Mask
    "noise-blur": "nr",
    "noise-sharpen": "nrs",
    // Palette n/a
    // PDF n/a
    // Pixel Density n/a
    // Rotation
    "flip-direction": "flip",
    orientation: "or",
    "rotation-angle": "rot",
    // Size
    "crop-mode": "crop",
    "fit-mode": "fit",
    "image-height": "h",
    "image-width": "w",
    // Stylize
    blurring: "blur",
    halftone: "htn",
    monotone: "mono",
    pixelate: "px",
    "sepia-tone": "sepia",
    // Trim TODO
    // Watermark TODO
    // Extra
    height: "h",
    width: "w"
  });
  var DEFAULT_OPTIONS = Object.freeze({
    auto: "format" // http://www.imgix.com/docs/reference/automatic#param-auto

  });
  /**
   * Construct a URL for an image with an Imgix proxy, expanding image options
   * per the [API reference docs](https://www.imgix.com/docs/reference).
   * @param  {String} src         src of raw image
   * @param  {Object} longOptions map of image API options, in long or short form per expansion rules
   * @return {String}             URL of image src transformed by Imgix
   */

  function constructUrl(src, longOptions) {
    if (!src) {
      return "";
    }

    const keys = Object.keys(longOptions);
    const keysLength = keys.length;
    let url = src + "?";

    for (let i = 0; i < keysLength; i++) {
      let key = keys[i];
      let val = longOptions[key];

      if (PARAM_EXPANSION[key]) {
        key = PARAM_EXPANSION[key];
      } else {
        key = encodeURIComponent(key);
      } // if (key.substr(-2) === "64") {
      //   val = Base64.encodeURI(val)
      // }


      url += key + "=" + encodeURIComponent(val) + "&";
    }

    return url.slice(0, -1);
  }

  function buildURL(src, imgixParams = {}) {
    const [rawSrc, params] = extractQueryParams(src);
    let urlParams = (0, _lodashEs.omitBy)(Object.assign({}, params, imgixParams), (v, k) => (0, _lodashEs.isNil)(v));
    return constructUrl(rawSrc, urlParams);
  }

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DSImgixComponent);
});