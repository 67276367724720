define("cc-frontend/components/icons/paperclip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M10.5,24c-2.65,0 -4.5,-2.056 -4.5,-5v-12.5c0,-3.766 2.523,-6.5 6,-6.5c3.477,0 6,2.734 6,6.5v9c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-9c0,-3.187 -2.103,-5.5 -5,-5.5c-2.897,0 -5,2.313 -5,5.5v12.5c0,2.355 1.439,4 3.5,4c2.061,0 3.5,-1.645 3.5,-4v-9.5c0,-0.586 -0.144,-2.5 -2,-2.5c-1.856,0 -2,1.914 -2,2.5v7.5c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-7.5c0,-2.126 1.178,-3.5 3,-3.5c1.823,0 3,1.374 3,3.5v9.5c0,2.944 -1.851,5 -4.5,5Z"
    ></path>
  </svg>
  */
  {
    "id": "+EwQq1AN",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M10.5,24c-2.65,0 -4.5,-2.056 -4.5,-5v-12.5c0,-3.766 2.523,-6.5 6,-6.5c3.477,0 6,2.734 6,6.5v9c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-9c0,-3.187 -2.103,-5.5 -5,-5.5c-2.897,0 -5,2.313 -5,5.5v12.5c0,2.355 1.439,4 3.5,4c2.061,0 3.5,-1.645 3.5,-4v-9.5c0,-0.586 -0.144,-2.5 -2,-2.5c-1.856,0 -2,1.914 -2,2.5v7.5c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-7.5c0,-2.126 1.178,-3.5 3,-3.5c1.823,0 3,1.374 3,3.5v9.5c0,2.944 -1.851,5 -4.5,5Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/paperclip.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});