define("cc-frontend/components/planner/card-stacks/add-to-routine-button", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="add-button">
    <Icons::AddNoCircleHeavier />
  </div>
  */
  {
    "id": "X9z3zUTb",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"add-button\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/add-to-routine-button.css\"]]]]]],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"],[13]],[],false,[\"local-class\",\"icons/add-no-circle-heavier\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/add-to-routine-button.hbs",
    "isStrictMode": false
  });

  let AddToRoutineButtonTs = (_dec = (0, _component.tagName)(""), _dec(_class = class AddToRoutineButtonTs extends Ember.Component {}) || _class);
  _exports.default = AddToRoutineButtonTs;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AddToRoutineButtonTs);
});