define("cc-frontend/templates/icons/-streamline-person-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r+EkLvSV",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.467-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.467 5.5-5.5-2.467-5.5-5.5-5.5z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M17.5 21c-.276 0-.5-.224-.5-.5V18h-2.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5H17v-2.5c0-.276.224-.5.5-.5s.5.224.5.5V17h2.5c.276 0 .5.224.5.5s-.224.5-.5.5H18v2.5c0 .276-.224.5-.5.5zM.5 17c-.132 0-.262-.054-.355-.148-.095-.096-.147-.223-.145-.357.014-1.215.263-2.396.74-3.513.55-1.103 2.317-1.753 4.764-2.652l.634-.234c.015-.01.081-.089.131-.295.13-.536.008-1.209-.063-1.323-1.068-1.152-1.604-2.724-1.464-4.304-.084-1.008.223-1.992.867-2.773C6.264.607 7.189.115 8.214.016c.091-.009.182-.014.274-.016 1.045.025 2.006.45 2.718 1.196.712.746 1.09 1.723 1.064 2.751-.002.075-.006.15-.012.225.141 1.58-.394 3.152-1.471 4.318-.061.101-.182.775-.052 1.309.05.207.116.286.135.297l1.302.479c.259.095.392.383.297.642-.072.196-.261.327-.469.327-.059 0-.117-.01-.172-.031l-1.307-.481c-.258-.096-.598-.346-.757-.997-.173-.709-.087-1.81.286-2.221.897-.971 1.337-2.281 1.208-3.593-.003-.033-.003-.065 0-.098.006-.067.01-.134.012-.202C11.309 2.35 10.061 1.039 8.488 1c-.043.002-.111.006-.178.012-.759.073-1.444.437-1.929 1.026-.485.588-.712 1.33-.639 2.089.003.033.003.065 0 .097-.127 1.313.313 2.621 1.209 3.59.376.414.462 1.515.29 2.225-.159.651-.498.901-.755.996l-.636.233c-2.062.758-3.842 1.412-4.203 2.134-.348.818-.56 1.695-.626 2.598H9c.276 0 .5.224.5.5s-.224.5-.5.5H.5z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-streamline-person-add.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});