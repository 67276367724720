define("cc-frontend/components/planner/modals/interior-print-modal", ["exports", "@ember-decorators/component", "cc-frontend/helpers/find-document", "cc-frontend/helpers/find-many"], function (_exports, _component, _findDocument, _findMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InteriorPrintModal = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
  <a {{on "click" @closeModal}} class="btn-exit-modal-string" data-test-close-print-modal>
    Close <span class="close-modal-times">&times;</span>
  </a>
  <div class="modal is-visible js-modal print-modal {{hasOpenedClasses}}">
    <div class="modal-dialog">
      <div class="modal-content">
    <div class="modal-header" data-test-print-modal>
      <h3> Print or Download <br>
        {{dateRangeString}}
      </h3>
    </div>
    <div class="modal-body print-modal-body">
      {{#if canPrint}}
        {{#if (or (and this.courses.isFulfilled planbook.isFulfilled) this.course.isFulfilled)}}
          <Planner::Modals::ConfigurePrintDownload
            @perspective={{@perspective}}
            @startDate={{@startDate}}
            @endDate={{@endDate}}
            @medium={{@medium}}
            @fullName={{this.fullName}}
            @courses={{this.courses.content}}
            @unitId={{@unitId}}
            @courseId={{@courseId}}
            @courseIds={{@courseIds}}
            @planbookId={{this.planbook.id}}
            @planbookTitle={{this.planbook.attributes.title}}
            @lastPrintOptions={{this.lastPrintOptions}}
            @isFilteringClasses={{@isFilteringClasses}}
            @classesLinedUp={{@classesLinedUp}}
          />
        {{/if}}
      {{else}}
        You need to upgrade to Cc Pro to print this layout.
      {{/if}}
    </div>
  </div>
  </div>
  </div>
  
  
  */
  {
    "id": "izIWwfHQ",
    "block": "[[[1,\"\\n\"],[11,3],[24,0,\"btn-exit-modal-string\"],[24,\"data-test-close-print-modal\",\"\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n  Close \"],[10,1],[14,0,\"close-modal-times\"],[12],[1,\"×\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"modal is-visible js-modal print-modal \",[36,1]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-dialog\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-header\"],[14,\"data-test-print-modal\",\"\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,\" Print or Download \"],[10,\"br\"],[12],[13],[1,\"\\n      \"],[1,[34,2]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"modal-body print-modal-body\"],[12],[1,\"\\n\"],[41,[33,4],[[[41,[28,[37,5],[[28,[37,6],[[30,0,[\"courses\",\"isFulfilled\"]],[33,7,[\"isFulfilled\"]]],null],[30,0,[\"course\",\"isFulfilled\"]]],null],[[[1,\"        \"],[8,[39,8],null,[[\"@perspective\",\"@startDate\",\"@endDate\",\"@medium\",\"@fullName\",\"@courses\",\"@unitId\",\"@courseId\",\"@courseIds\",\"@planbookId\",\"@planbookTitle\",\"@lastPrintOptions\",\"@isFilteringClasses\",\"@classesLinedUp\"],[[30,2],[30,3],[30,4],[30,5],[30,0,[\"fullName\"]],[30,0,[\"courses\",\"content\"]],[30,6],[30,7],[30,8],[30,0,[\"planbook\",\"id\"]],[30,0,[\"planbook\",\"attributes\",\"title\"]],[30,0,[\"lastPrintOptions\"]],[30,9],[30,10]]],null],[1,\"\\n\"]],[]],null]],[]],[[[1,\"      You need to upgrade to Cc Pro to print this layout.\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@closeModal\",\"@perspective\",\"@startDate\",\"@endDate\",\"@medium\",\"@unitId\",\"@courseId\",\"@courseIds\",\"@isFilteringClasses\",\"@classesLinedUp\"],false,[\"on\",\"hasOpenedClasses\",\"dateRangeString\",\"if\",\"canPrint\",\"or\",\"and\",\"planbook\",\"planner/modals/configure-print-download\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed("session.user.attributes.printOptions"), _dec7 = Ember.computed("session.user.attributes.firstName", "session.user.attributes.lastName"), _dec8 = Ember.computed.alias("session.user.attributes.isPro"), _dec9 = (0, _findDocument.default)("courseId", "course"), _dec10 = (0, _findMany.default)("courseIds", "course"), _dec11 = (0, _findDocument.default)("planbookId", "planbook"), _dec(_class = (_class2 = class InteriorPrintModal extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "modalManager", _descriptor4, this);

      _initializerDefineProperty(this, "needsToUpgradeToPrint", _descriptor5, this);

      _initializerDefineProperty(this, "course", _descriptor6, this);

      _initializerDefineProperty(this, "courses", _descriptor7, this);

      _initializerDefineProperty(this, "planbook", _descriptor8, this);
    }

    get lastPrintOptions() {
      return Ember.get(this, "session.user.attributes.printOptions");
    }

    get fullName() {
      return Ember.get(this, "session.user.attributes.firstName") + " " + Ember.get(this, "session.user.attributes.lastName");
    } // I think we should change this to a subscription service


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appState", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "modalManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "lastPrintOptions", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "lastPrintOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fullName", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "fullName"), _class2.prototype), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "needsToUpgradeToPrint", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "course", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "courses", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "planbook", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = InteriorPrintModal;
});