define("cc-frontend/transitions", ["exports", "liquid-fire", "lodash"], function (_exports, _liquidFire, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // import wait from "liquid-fire/transitions/wait";
  function _default() {
    /**
     * Within the setup screen
     */
    // this.transition(
    //   this.fromRoute('planbook.setup.classes'),
    //   this.toRoute('planbook.setup.schedule'),
    //   this.use('toLeft'),
    //   this.reverse('toRight')
    // );
    this.transition(this.fromRoute("planbook.setup.background"), this.toRoute("planbook.setup.classes"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("planbook.setup.classes"), this.toRoute("planbook.setup.class-schedule"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("planbook.setup.class-schedule"), this.toRoute("planbook.setup.templates"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("planbook.setup.templates"), this.toRoute("planbook.setup.template-schedule"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("planbook.setup.template-schedule"), this.toRoute("planbook.setup.holidays"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.hasClass("undo-menu"), this.use("fade-down", {
      duration: 200
    }) //, { duration: 400, easing: [600, 22] })
    );
    this.transition(this.hasClass("lf-fade-down"), this.use("fade-down", {
      duration: 300
    }) //, { duration: 400, easing: [600, 22] })
    );
    this.transition(this.hasClass("in-app-notice"), this.toValue(true), this.use("fade-down", {
      duration: 200
    }) //, { duration: 400, easing: [600, 22] })
    );
    this.transition(this.hasClass("cc-tooltip-wrapper"), this.toValue((toValue, fromValue) => toValue === null || fromValue === null), this.use("fade-down", {
      duration: 200
    }));
    this.transition(this.hasClass("popping-explainer-wrapper"), this.toValue((toValue, fromValue) => toValue === null || fromValue === null), this.use("fade-down", {
      duration: 200
    })); // this.transition(
    //   this.hasClass("modal-backdrop"),
    //   this.use('fade', {duration: 125, easing: [500, 20]})
    // )

    /**
     * To the setup screen
     */

    this.transition( // this.fromRoute(['day', 'week', 'month', 'class-website-settings']),
    // this.toRoute('planbook.setup'),
    this.fromRoute(routeName => Ember.isNone(routeName) || _lodash.default.includes(routeName, "planbook.show")), this.toRoute(routeName => _lodash.default.includes(routeName, "planbook.setup")), this.use(function (opts) {
      (0, _liquidFire.stop)(this.oldElement);
      (0, _liquidFire.animate)(this.oldElement, {
        opacity: 0
      }, {
        duration: 0
      }); // return wait(750).then(() => {
      //   animate(this.newElement, {opacity: [1, 0]}, {duration: 750}, 'fade-in')
      // })

      (0, _liquidFire.animate)(this.newElement, {
        opacity: 0
      }, {
        duration: 0
      });
      return new Ember.RSVP.Promise(resolve => {
        $(".liquid-outlet--planbook").css("overflow", "visible");
        $(".liquid-outlet--planbook > .liquid-child").css("overflow", "visible");
        setTimeout(() => {
          resolve((0, _liquidFire.animate)(this.newElement, {
            opacity: [1, 0]
          }, {
            duration: 750
          }, "fade-in"));
        }, 1000);
      }); // let oldView = this.oldView
      // let newView = this.newView
      // console.log(animate)
      // console.log("stop", stop)
      // console.log('insertNewView', oldView, insertNewView, opts)
      // stop(oldView);
      // return animate(oldView, {opacity: 0}, opts)
      //   .then(insertNewView)
      //   .then(function(newView){
      //     console.log('newView', newView)
      //     return animate(newView, {opacity: [0, 1]}, opts);
      //   });
    }), this.reverse("crossFade"));
    this.transition(this.fromRoute(routeName => _lodash.default.includes(routeName, "planbook.show")), this.toRoute(routeName => _lodash.default.includes(routeName, "planbook.show")), this.use("crossFade"), this.reverse("crossFade"));
    /**
     * Plnabook setup
     */

    this.transition(this.hasClass("planbook-setup-header--is-editing-templates"), this.toValue(true), this.use("crossFade"), this.reverse("crossFade"));
    this.transition(this.hasClass("planbook-date-template__add-part-wrapper"), this.toValue(true), this.use("toDown"), this.reverse("toUp"));
    /**
     * Sign Page
     */

    this.transition(this.hasClass("lf-sign-headline"), this.toValue(true), this.use("toUp"), this.reverse("toDown"));
    this.transition(this.hasClass("lf-sign-in-headline"), this.toValue(true), this.use("toUp"), this.reverse("toDown"));
    this.transition(this.hasClass("lf-sign-up-headline"), this.toValue(true), this.use("toUp"), this.reverse("toDown"));
    this.transition(this.hasClass("lf-sign-up-headline-steps"), this.use("crossFade"), this.reverse("crossFade"));
    this.transition(this.hasClass("lf-form-area"), this.use("crossFade"));
    this.transition(this.hasClass("lf-to-down"), this.toValue(true), this.use("toDown"), this.reverse("toUp"));
    this.transition(this.hasClass("lf-to-right"), this.toValue(true), this.use("toRight"), this.reverse("toLeft"));
    this.transition(this.hasClass("lf-crossfade"), this.use("crossFade"));
    this.transition(this.hasClass("lf-steps"), this.fromValue(2), this.toValue(3), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.hasClass("lf-steps"), this.fromValue(1), this.toValue(2), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.hasClass("animated-dropdown"), this.use("fade-down-slight", {
      duration: 300,
      easing: [500, 30]
    }));
    this.transition(this.hasClass("animated-dropdown-center"), this.use("fade-down-slight-center", {
      duration: 300,
      easing: [500, 30]
    }));
  }
});