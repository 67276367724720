define("cc-frontend/templates/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GWZzBMOo",
    "block": "[[[10,\"style\"],[12],[1,\"\\n  html, body{ font-size:\\n  \"],[1,[30,0,[\"fontSize\"]]],[1,\"px; }\\n\"],[13],[1,\"\\n\"],[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/print.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});