define("cc-frontend/lib/actions/lesson/LESSON_EDIT_TITLE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_EDIT_TITLE = void 0;
  const LESSON_EDIT_TITLE = {
    name: "LESSON_EDIT_TITLE",
    params: {
      value: "string",
      originalValue: "string",
      cardStackId: "string",
      planbookId: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", payload.value).patch;

      let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.title", payload.value).patch;

      return [patchForCardStackSummary, patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", payload.originalValue).patch;

      let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.title", payload.originalValue).patch;

      return [patchForCardStackSummary, patch];
    },

    narrative(payload, findFn, userId) {
      // debugger;
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        oldTitle: payload.originalValue,
        newTitle: payload.value
      });

      return {
        title: "Renamed Lesson",
        titleForAnalytics: "Lesson - Edit Title",
        description: `Changed the title from "${payload.originalValue}" to "${payload.value}"`,
        activity: (0, _defaultLessonActivity.default)(payload, models),
        context: newContext
      };
    }

  };
  _exports.LESSON_EDIT_TITLE = LESSON_EDIT_TITLE;
  var _default = LESSON_EDIT_TITLE;
  _exports.default = _default;
});