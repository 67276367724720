define("cc-frontend/templates/icons/-trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T48VHmLg",
    "block": "[[[10,\"svg\"],[14,\"baseProfile\",\"tiny\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 128 128\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M107.5 41.5l-4-5.9c-.7-1-2.3-1.9-3.5-1.9h-9.2v-8c0-1.2-.7-3-1.6-3.8l-6.7-6.7c-.9-.9-2.6-1.6-3.8-1.6h-27.7c-1.2 0-3 .7-3.8 1.6l-6.2 6.2c-.9.9-1.6 2.6-1.6 3.8v8.5h-9.6c-1.2 0-2.8.8-3.5 1.9l-3.9 5.8c-.7 1-.2 1.9 1 1.9h6.6v72.8c0 1.2 1 2.3 2.3 2.3h65.8c1.2 0 2.3-1 2.3-2.3v-72.8h6.2c1.2.1 1.6-.8.9-1.8zm-58.7-8.3v-4.1c0-1.2.7-3 1.6-3.8l.7-.7c.9-.9 2.6-1.6 3.8-1.6h19.9c1.2 0 3 .7 3.8 1.6l1.2 1.2c.9.9 1.6 2.6 1.6 3.8v3.6c0 .2 0 .3-.1.5h-32.5v-.5zm42.2 12.4v61.2c0 1.2-1 2.3-2.3 2.3h-47.1c-1.2 0-2.3-1-2.3-2.3v-61.2c0-1.2 1-2.3 2.3-2.3h47.2c1.3.1 2.2 1.1 2.2 2.3zm-40.2 4.1h-2.8c-1.2 0-2.3 1-2.3 2.3v48.7c0 1.2 1 2.3 2.3 2.3h2.7c1.2 0 2.3-1 2.3-2.3v-48.8c0-1.2-1-2.2-2.2-2.2zm12.9 53.1h2.7c1.2 0 2.3-1 2.3-2.3v-48.6c0-1.2-1-2.3-2.3-2.3h-2.7c-1.2 0-2.3 1-2.3 2.3v48.7c0 1.2 1 2.2 2.3 2.2zm15.6 0h2.7c1.2 0 2.3-1 2.3-2.3v-48.6c0-1.2-1-2.3-2.3-2.3h-2.7c-1.2 0-2.3 1-2.3 2.3v48.7c0 1.2 1.1 2.2 2.3 2.2z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-trash.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});