define("cc-frontend/components/planner/template-chooser/template-chooser.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "main-screen": "_main-screen_mgy7w2",
    "screen-2": "_screen-2_mgy7w2"
  };
  _exports.default = _default;
});