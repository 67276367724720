define("cc-frontend/components/planner/template-chooser/template-chooser-modal.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_usfl04",
    "--is-small": "_--is-small_usfl04",
    "modal-inner__close": "_modal-inner__close_usfl04"
  };
  _exports.default = _default;
});