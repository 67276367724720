define("cc-frontend/lib/actions/lesson/LESSON_CARD_ADD_ATTACHMENTS", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_ADD_ATTACHMENTS = void 0;
  const LESSON_CARD_ADD_ATTACHMENTS = {
    name: "LESSON_CARD_ADD_ATTACHMENTS",
    params: {
      cardStackId: "string",
      sectionId: "string",
      source: "string",
      originalAttachments: "array",
      files: {
        type: "array",
        items: {
          type: "object",
          strict: false,
          properties: {
            id: {
              type: "string"
            },
            url: {
              type: "string"
            },
            type: {
              type: "string"
            },
            title: {
              type: "string"
            },
            icon: {
              type: "string",
              optional: true
            },
            size: {
              type: "number",
              optional: true
            },
            mimetype: {
              type: "string",
              optional: true
            },
            isWriteable: {
              type: "boolean",
              optional: true
            }
          }
        }
      }
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).push("embedded", "attributes.attachments", ["$each", payload.files]).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      });

      patch.set("embedded", "attributes.attachments", payload.originalAttachments);
      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let title = "Attachment - Other";
      if (payload.source === "upload") title = "Attachment - Upload File";
      if (payload.source === "dropbox") title = "Attachment - Add from Dropbox";
      if (payload.source === "google") title = "Attachment - Add from Google Drive"; // This is what we use for dragging or clicking the upload button

      if (payload.source === "drag") title = "Attachment - Upload File";
      if (payload.source === "onedrive") title = "Attachment - Add from OneDrive";
      let fileCount = Ember.get(payload, "files.length");
      let fileCountInWords = fileCount === 1 ? "1 File" : `${fileCount} files`;

      let card = _lodash.default.find(models.cardStack.attributes.cards, card => {
        return card.id === payload.sectionId;
      });

      let cardTitle = card.attributes.title;

      let attachmentTitles = _lodash.default.map(payload.files, file => {
        return file.title;
      });

      let context = (0, _defaultLessonContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        cardTitle: cardTitle,
        fileCount: fileCount,
        attachmentTitles
      });

      return {
        title: "Attached a File",
        titleForAnalytics: title,
        description: `Added ${fileCountInWords} to "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "created")
      };
    }

  };
  _exports.LESSON_CARD_ADD_ATTACHMENTS = LESSON_CARD_ADD_ATTACHMENTS;
  var _default = LESSON_CARD_ADD_ATTACHMENTS;
  _exports.default = _default;
});