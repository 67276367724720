define("cc-frontend/components/planner/menus/share-link/show-link-body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "show-link": "_show-link_1g55em",
    "link-box": "_link-box_1g55em",
    "copy-box-wrapper": "_copy-box-wrapper_1g55em"
  };
  _exports.default = _default;
});