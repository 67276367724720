define("cc-frontend/templates/new-group/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aFk8BWN9",
    "block": "[[[1,[28,[35,0],[[33,1,[\"attributes\",\"title\"]]],null]],[1,\"\\n\"],[41,[28,[37,3],[[33,1,[\"attributes\",\"usedAs\"]],\"school\"],null],[[[1,\"  \"],[8,[39,4],null,[[\"@group\"],[[36,1]]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,3],[[33,1,[\"attributes\",\"usedAs\"]],\"school-network\"],null],[[[1,\"  \"],[8,[39,5],null,[[\"@group\"],[[36,1]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"page-title\",\"model\",\"if\",\"eq\",\"school/show\",\"school-network/show\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});