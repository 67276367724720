define("cc-frontend/templates/new-group/show/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N3xLhbAG",
    "block": "[[[1,[28,[35,0],[\"Reviews\"],null]],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"school-backdrop\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"school-backdrop__fade\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"school-backdrop__pattern\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"school-backdrop__gradient\"],[15,5,[28,[37,2],[[28,[37,3],[\"background-image: linear-gradient(90deg, \",[33,4,[\"attributes\",\"branding\",\"primaryColor\"]],\", \",[33,4,[\"attributes\",\"branding\",\"secondaryColor\"]]],null]],null]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,5],null,[[\"@group\",\"@filter\",\"@selectedCardStackId\",\"@setNewCardStackId\"],[[30,1],[30,0,[\"filter\"]],[30,0,[\"cardStackId\"]],[30,0,[\"setNewCardStackId\"]]]],null]],[\"@model\"],false,[\"page-title\",\"default-navbar\",\"html-safe\",\"concat\",\"model\",\"school/reviews/manager\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/reviews.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});