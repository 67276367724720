define("cc-frontend/components/icons/copy-doc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    {{! Common File Double }}
    <path
      d="M3.5,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-18c0,-0.827 0.673,-1.5 1.5,-1.5h1.5v-1.5c0,-0.827 0.673,-1.5 1.5,-1.5h10.586c0.401,0 0.777,0.156 1.061,0.439l3.414,3.414c0.283,0.284 0.439,0.66 0.439,1.061v14.586c0,0.827 -0.673,1.5 -1.5,1.5h-1.5v1.5c0,0.827 -0.673,1.5 -1.5,1.5h-14Zm0,-20c-0.276,0 -0.5,0.224 -0.5,0.5v18c0,0.276 0.224,0.5 0.5,0.5h14c0.276,0 0.5,-0.224 0.5,-0.5v-1.5h-11.5c-0.827,0 -1.5,-0.673 -1.5,-1.5v-15.5h-1.5Zm3,-3c-0.276,0 -0.5,0.224 -0.5,0.5v18c0,0.276 0.224,0.5 0.5,0.5h14c0.276,0 0.5,-0.224 0.5,-0.5v-14.586c0,-0.133 -0.052,-0.259 -0.146,-0.353l-3.414,-3.414c-0.094,-0.094 -0.222,-0.147 -0.354,-0.147h-10.586Z"
    ></path>
  </svg>
  */
  {
    "id": "yNj+MgG8",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n\"],[1,\"  \"],[10,\"path\"],[14,\"d\",\"M3.5,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-18c0,-0.827 0.673,-1.5 1.5,-1.5h1.5v-1.5c0,-0.827 0.673,-1.5 1.5,-1.5h10.586c0.401,0 0.777,0.156 1.061,0.439l3.414,3.414c0.283,0.284 0.439,0.66 0.439,1.061v14.586c0,0.827 -0.673,1.5 -1.5,1.5h-1.5v1.5c0,0.827 -0.673,1.5 -1.5,1.5h-14Zm0,-20c-0.276,0 -0.5,0.224 -0.5,0.5v18c0,0.276 0.224,0.5 0.5,0.5h14c0.276,0 0.5,-0.224 0.5,-0.5v-1.5h-11.5c-0.827,0 -1.5,-0.673 -1.5,-1.5v-15.5h-1.5Zm3,-3c-0.276,0 -0.5,0.224 -0.5,0.5v18c0,0.276 0.224,0.5 0.5,0.5h14c0.276,0 0.5,-0.224 0.5,-0.5v-14.586c0,-0.133 -0.052,-0.259 -0.146,-0.353l-3.414,-3.414c-0.094,-0.094 -0.222,-0.147 -0.354,-0.147h-10.586Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/copy-doc.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});