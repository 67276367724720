define("cc-frontend/components/planner/menus/google-classroom/attachment-menus/create-attachment", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  */
  {
    "id": "lWvqWvS4",
    "block": "[[],[],false,[]]",
    "moduleName": "cc-frontend/components/planner/menus/google-classroom/attachment-menus/create-attachment.hbs",
    "isStrictMode": false
  });

  class GoogleClassroomCreateAttachmentMenuComponent extends _component.default {}

  _exports.default = GoogleClassroomCreateAttachmentMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GoogleClassroomCreateAttachmentMenuComponent);
});