define("cc-frontend/components/bopit/anchor", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BopitPopoverAnchor = (_dec = (0, _component.classNameBindings)("_isOpenClass"), _dec2 = (0, _component.attributeBindings)("aria-label"), _dec3 = (0, _component.tagName)("div"), _dec4 = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{yield}}
  
  */
  {
    "id": "WGRYy85D",
    "block": "[[[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec5 = Ember.computed("isOpen"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class BopitPopoverAnchor extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "onClick", () => {});

      _defineProperty(this, "mouseHasEntered", () => {});

      _defineProperty(this, "mouseHasLeft", () => {});
    }

    init() {
      super.init(...arguments);
      var parentElementId = this.parentElementId;
      this.set("elementId", `${parentElementId}-anchor`);
    }

    get _isOpenClass() {
      return Ember.get(this, "isOpen") ? Ember.get(this, "isOpenClass") || "is-open" : Ember.get(this, "isClosedClass") || "is-closed";
    }

    click(e) {
      this.onClick(e); // e.preventDefault();
      // e.stopPropagation();
    }

    mouseEnter(e) {
      this.mouseHasEntered(e);
    }

    mouseLeave(e) {
      this.mouseHasLeft(e);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "_isOpenClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_isOpenClass"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = BopitPopoverAnchor;
});