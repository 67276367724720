define("cc-frontend/lib/actions/planbook/PLANBOOK_DATE_RESET_TO_TEMPLATE", ["exports", "cc-frontend/lib/patch-updater", "moment"], function (_exports, _patchUpdater, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "PLANBOOK_DATE_RESET_TO_TEMPLATE",
    params: {
      courseId: "string",
      dateId: "string",
      dateString: "string",
      currentCardStackId: "string"
    },

    patches(payload) {
      return [_patchUpdater.default.create("planbook", payload.planbookId, "attributes.calendar.dates", {
        id: payload.dateId
      }).set("embedded", "attributes.cardStackId", null).inc("attributes.calendar.version", 1).patch];
    },

    undoPatches(payload) {
      return [_patchUpdater.default.create("planbook", payload.planbookId, "attributes.calendar.dates", {
        id: payload.dateId
      }).set("embedded", "attributes.cardStackId", payload.currentCardStackId).inc("attributes.calendar.version", 1).patch];
    },

    narrative(payload, findFn, userId) {
      let planbook = findFn("planbook", payload.planbookId);
      let formattedDate = (0, _moment.default)(payload.dateString).format("ddd. MMM. D, YYYY");
      let shortFormattedDate = (0, _moment.default)(payload.dateString).format("MMM. D YYYY");
      return {
        title: `Reset ${shortFormattedDate} to the normal schedule`,
        titleForAnalytics: "Planbook - Reset Date to Template",
        description: `Reset ${formattedDate} so it will use the schedule for a normal ${payload.rotationDayTitle}`,
        context: {
          planbookTitle: planbook.attributes.title,
          dateString: payload.dateString,
          planbookId: planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `planbook-${planbook.id}`,
          to: [`planbook-edits:planbook-${planbook.id}`]
        }
      };
    }

  };
  _exports.default = _default;
});