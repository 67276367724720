define("cc-frontend/components/planner/course/create-edit/edit-template-menu.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ds-dropdown": "_ds-dropdown_1ao7t6",
    "--arrow": "_--arrow_1ao7t6",
    "--arrow-bottom-right": "_--arrow-bottom-right_1ao7t6",
    "header-spacer": "_header-spacer_1ao7t6",
    "used-templates-header": "_used-templates-header_1ao7t6",
    "template-suggestion": "_template-suggestion_1ao7t6"
  };
  _exports.default = _default;
});