define("cc-frontend/components/planner/menus/google-classroom/doc-sharing-menu/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  */
  {
    "id": "2h1xMvks",
    "block": "[[],[],false,[]]",
    "moduleName": "cc-frontend/components/planner/menus/google-classroom/doc-sharing-menu/index.hbs",
    "isStrictMode": false
  });

  class GoogleClassroomDocumentSharingMenuComponent extends _component.default {}

  _exports.default = GoogleClassroomDocumentSharingMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GoogleClassroomDocumentSharingMenuComponent);
});