define("cc-frontend/controllers/print/planbook-month", ["exports", "cc-frontend/helpers/belongs-to", "lodash", "moment", "cc-frontend/helpers/find-document", "cc-frontend/lib/date-formatter"], function (_exports, _belongsTo, _lodash, _moment, _findDocument, _dateFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlanbookMonthController = (_dec = Ember.inject.service, _dec2 = (0, _findDocument.default)("model.planbookId", "planbook"), _dec3 = Ember.computed.alias("model.courses"), _dec4 = (0, _belongsTo.default)("planbook", "rotationCalendar"), _dec5 = Ember.computed("courses"), _dec6 = Ember.computed("startDate", "endDate", "rotationCalendar.content.attributes.schoolDays"), _dec7 = Ember.computed("weeks.length"), (_class = class PlanbookMonthController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "queryParams", ["startDate", "endDate", "classesLinedUp", "isFilteringClasses", "courseIds", "headerText"]);

      _defineProperty(this, "classesLinedUp", false);

      _defineProperty(this, "isFilteringClasses", false);

      _defineProperty(this, "courseIds", []);

      _initializerDefineProperty(this, "planbook", _descriptor2, this);

      _initializerDefineProperty(this, "courses", _descriptor3, this);

      _initializerDefineProperty(this, "rotationCalendar", _descriptor4, this);
    }

    get visibleCourses() {
      if (Ember.get(this, "isFilteringClasses") === true) {
        return _lodash.default.compact(_lodash.default.map(Ember.get(this, "courseIds"), id => _lodash.default.find(Ember.get(this, "model.courses"), course => Ember.get(course, "id") === id)));
      } else {
        return Ember.get(this, "courses");
      }
    } // If it's the week view, we replace. Otherwise, we append


    get weeks() {
      let validSchoolDays = Ember.get(this, "rotationCalendar.content.attributes.schoolDays");
      let startDate = Ember.get(this, "startDate");
      let endDate = Ember.get(this, "endDate");

      let range = _moment.default.range(startDate, endDate);

      let acc = [];
      Array.from(range.by("days")).map(moment => {
        if (_lodash.default.includes(validSchoolDays, moment.weekday())) acc.push(moment);
      });

      let newDates = _lodash.default.chain(acc).map(m => m.format("YYYY-MM-DD")).thru(array => (0, _dateFormatter.decorateDates)(array, null)).chunk(5).value();

      return newDates;
    }

    get dateRangeString() {
      if (Ember.get(this, "weeks.length") === 0) return;
      let middle = Math.floor(Ember.get(this, "weeks.length") / 2);
      let middleDate = Ember.get(this, "weeks")[middle][3];
      return (0, _moment.default)(middleDate.dateString).format("MMMM YYYY");
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "planbook", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "courses", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rotationCalendar", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "visibleCourses", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "visibleCourses"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "weeks", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "weeks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateRangeString", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "dateRangeString"), _class.prototype)), _class));
  _exports.default = PlanbookMonthController;
});