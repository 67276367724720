define("cc-frontend/components/school/edit/payment/buttons/purchase-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    ...attributes
    type="button"
    class="school-edit-payment__status-members__members__pay-buttons__button test-create-quote-btn"
  >
    <Icons::StreamlineTaskChecklistEdit />
    <div class="school-edit-payment__status-members__members__pay-buttons__button__text">
      <div class="school-edit-payment__status-members__members__pay-buttons__button__text__bottom">
        Pay with Purchase Order
      </div>
      <div class="school-edit-payment__status-members__members__pay-buttons__button__text__top">
        Create a quote
      </div>
    </div>
  </button>
  */
  {
    "id": "a2iG6DWJ",
    "block": "[[[11,\"button\"],[17,1],[24,4,\"button\"],[24,0,\"school-edit-payment__status-members__members__pay-buttons__button test-create-quote-btn\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n  \"],[10,0],[14,0,\"school-edit-payment__status-members__members__pay-buttons__button__text\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"school-edit-payment__status-members__members__pay-buttons__button__text__bottom\"],[12],[1,\"\\n      Pay with Purchase Order\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"school-edit-payment__status-members__members__pay-buttons__button__text__top\"],[12],[1,\"\\n      Create a quote\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"icons/streamline-task-checklist-edit\"]]",
    "moduleName": "cc-frontend/components/school/edit/payment/buttons/purchase-order.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});