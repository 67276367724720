define("cc-frontend/templates/icons/-comment-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nElpEc8Y",
    "block": "[[[10,\"svg\"],[14,\"data-name\",\"Layer 1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M127.64 425.63a54.14 54.14 0 0 1-15.59-2c-10.05-3.09-12.63-9.43-13.25-12.94-1-5.42 1.35-10.82 6.16-14.44 19.75-14.89 30-30.5 30.56-46.47-40.6-25.07-66.52-63.1-71.44-105.23-4.17-35.64 7-72.17 31.34-102.86 32.5-40.9 84.76-68.13 143.38-74.69 51-5.71 101 4.81 140.63 29.61C419.95 122 445 159.13 449.92 201.28c4.17 35.64-7 72.17-31.33 102.85-32.51 40.87-84.77 68.13-143.39 74.69a238.59 238.59 0 0 1-49.39.41c-35.81 34.98-73.81 46.4-98.17 46.4zM265.71 89.5a217.93 217.93 0 0 0-24.24 1.36c-52.32 5.85-98.7 29.82-127.26 65.77-20.67 26-29.76 55.46-26.29 85.13 4.27 36.43 28 69.44 65.25 90.58l5.34 3 .67 6.1c2.68 24.56-9 44.61-23.43 59.57a95.53 95.53 0 0 0 15.43-3.35c15.1-4.53 38.05-15 61.59-39.36l4.15-4.29 5.92.72a213.92 213.92 0 0 0 49.7.21c52.32-5.85 98.71-29.83 127.27-65.77 20.67-26 29.76-55.45 26.29-85.13C418.3 137.6 348.67 89.5 265.71 89.5z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-comment-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});