define("cc-frontend/components/planner/template-chooser/choose-from-gallery/lesson", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="template-box {{if @isGroup "--is-group"}}" class="js-template-box">
    <div local-class="template-box__header">
      <div
        class="test-choose-template"
        local-class="template-box-button"
        {{on "click" (fn @selectTemplate @lesson)}}
      >
        <Icons::Pointer />
        Choose
      </div>
    </div>
    <div local-class="template-box__top">
      {{#if @isGroup}}
        <div local-class="template-box__top__group-label">
          {{@groupTitle}}
        </div>
      {{/if}}
      <Planner::CardStacks::LessonSimpleReadOnly @lesson={{@lesson}} />
    </div>
  </div>
  */
  {
    "id": "J9C2fbNC",
    "block": "[[[10,0],[15,0,[29,[\"js-template-box \",[28,[37,0],[[28,[37,1],[\"template-box \",[52,[30,1],\"--is-group\"]],null]],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/choose-from-gallery/lesson.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"template-box__header\"],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/choose-from-gallery/lesson.css\"]]]]]],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[\"test-choose-template \",[28,[37,0],[\"template-box-button\"],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/choose-from-gallery/lesson.css\"]]]]]],[4,[38,3],[\"click\",[28,[37,4],[[30,2],[30,3]],null]],null],[12],[1,\"\\n      \"],[8,[39,5],null,null,null],[1,\"\\n      Choose\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"template-box__top\"],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/choose-from-gallery/lesson.css\"]]]]]],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,0],[\"template-box__top__group-label\"],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/choose-from-gallery/lesson.css\"]]]]]],[12],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,6],null,[[\"@lesson\"],[[30,3]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isGroup\",\"@selectTemplate\",\"@lesson\",\"@groupTitle\"],false,[\"local-class\",\"concat\",\"if\",\"on\",\"fn\",\"icons/pointer\",\"planner/card-stacks/lesson-simple-read-only\"]]",
    "moduleName": "cc-frontend/components/planner/template-chooser/choose-from-gallery/lesson.hbs",
    "isStrictMode": false
  });

  let ChooseFromGalleryLesson = (_dec = (0, _component.tagName)(""), _dec(_class = class ChooseFromGalleryLesson extends Ember.Component {}) || _class);
  _exports.default = ChooseFromGalleryLesson;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ChooseFromGalleryLesson);
});