define("cc-frontend/lib/actions/course/COURSE_SET_RECENTLY_IMPORTED_COURSES", ["exports", "cc-frontend/lib/patch-updater"], function (_exports, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_SET_RECENTLY_IMPORTED_COURSES = void 0;
  const COURSE_SET_RECENTLY_IMPORTED_COURSES = {
    name: "COURSE_SET_RECENTLY_IMPORTED_COURSES",
    params: {
      courseId: "string",
      recentlyImportedCourses: "array"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("course", payload.courseId).set("attributes.recentlyImportedCourses", payload.recentlyImportedCourses).patch;

      return [patch];
    },

    undoPatches: null,
    narrative: null
  };
  _exports.COURSE_SET_RECENTLY_IMPORTED_COURSES = COURSE_SET_RECENTLY_IMPORTED_COURSES;
  var _default = COURSE_SET_RECENTLY_IMPORTED_COURSES;
  _exports.default = _default;
});