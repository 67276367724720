define("cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/dates-off-calculator"], function (_exports, _patchUpdater, _lodash, _datesOffCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePlanbookAddSpecialEvent = preparePlanbookAddSpecialEvent;
  _exports.default = _exports.PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT = void 0;

  function preparePlanbookAddSpecialEvent(payload) {
    return {
      name: PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT.name,
      payload: payload,
      patches: PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT.patches(payload),
      undoPatches: PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT.undoPatches(payload),
      narrative: (0, _lodash.partial)(PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT.narrative, payload)
    };
  }

  const PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT = {
    name: "PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT",
    params: {
      annotation: "object",
      planbook: "object"
    },

    patches(payload) {
      let newAnnotations = payload.planbook.attributes.calendar.dateAnnotations.concat([payload.dateAnnotation]);
      let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(newAnnotations);

      let patch = _patchUpdater.default.create("planbook", payload.planbook.id).push("attributes.calendar.dateAnnotations", payload.dateAnnotation).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatches(payload) {
      let oldAnnotations = JSON.parse(JSON.stringify(payload.planbook.attributes.calendar.dateAnnotations));
      let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(oldAnnotations);

      let patch = _patchUpdater.default.create("planbook", payload.planbook.id).pull("attributes.calendar.dateAnnotations", {
        id: payload.dateAnnotation.id
      }).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      // @ts-ignore
      let formattedDateString = moment(payload.dateAnnotation.attributes.dateString).format("ddd, MMM. D, YYYY"); // @ts-ignore

      let formattedStartDate = moment(payload.dateAnnotation.attributes.startDate).format("ddd, MMM. D, YYYY"); // @ts-ignore

      let formattedEndDate = moment(payload.dateAnnotation.attributes.endDate).format("ddd, MMM. D, YYYY");
      let descriptionStarter = "Added Special Event ";
      let descriptionPhrase = payload.dateAnnotation.type === "date-annotation-planbook-custom-single" ? `on ${formattedDateString}` : `from ${formattedStartDate} to ${formattedEndDate}`;
      let analyticsModifier = payload.dateAnnotation.type === `date-annotation-planbook-custom-single` ? "Single" : "Range";
      return {
        title: `Added Special Event`,
        titleForAnalytics: `Planbook Date - Create Annotation - ${analyticsModifier}`,
        description: `${descriptionStarter} ${descriptionPhrase}`,
        activity: {},
        context: {}
      };
    }

  };
  _exports.PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT = PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT;
  var _default = PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT;
  _exports.default = _default;
});