define("cc-frontend/lib/actions/create-narrative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * @typedef ActionNarrative
   * @property {string} title
   * @property {string} titleForAnalytics
   * @property {string} description
   * @property {object} activity
   * @property {object} context
   *
   *
   * @param {ActionNarrative} attrs
   * @return ActionNarrative
   */
  function _default(attrs) {
    return {
      title: attrs.title || null,
      titleForAnalytics: attrs.titleForAnalytics || null,
      activity: attrs.activity || null,
      description: attrs.description || null,
      context: attrs.context || null
    };
  }
});