define("cc-frontend/components/icons/google-logo-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    id="e2e54239-d369-4682-88f5-a8d243a1e6e8"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46 46"
  >
    <g id="b83b0052-137e-4bc4-9d82-4099ca260b2b" data-name="Google-Button"><g
        id="a4c97bf5-1fea-4d4d-95f0-a89bf6400e48"
        data-name="btn google light normal"
      >
        <g id="e63e90b8-b6f6-42b1-8ccf-0f44d4713ce1" data-name="logo googleg 48dp">
          <path
            id="b991b1a5-057b-4459-b838-ef00fbdc8cb8"
            data-name="Shape"
            d="M45.08,23.52a26.51,26.51,0,0,0-.42-4.7H23v8.9H35.38a10.6,10.6,0,0,1-4.59,6.94v5.77h7.43c4.35-4,6.86-9.9,6.86-16.91Z"
            style="fill:#4285f4;fill-rule:evenodd"
          >
          </path>
          <path
            id="a35e0acd-7e62-4001-8819-643569b02f20"
            data-name="Shape"
            d="M23,46a22,22,0,0,0,15.22-5.57l-7.43-5.77a13.89,13.89,0,0,1-20.66-7.29H2.45v6A23,23,0,0,0,23,46Z"
            style="fill:#34a853;fill-rule:evenodd"
          ></path>
          <path
            id="aa22e588-1301-4b3d-b898-1f0c2f684190"
            data-name="Shape"
            d="M10.13,27.37a13.62,13.62,0,0,1,0-8.74v-6H2.45a23,23,0,0,0,0,20.66l7.68-6Z"
            style="fill:#fbbc05;fill-rule:evenodd"
          ></path>
          <path
            id="b0cbc053-4d74-4931-8539-10eb62b70668"
            data-name="Shape"
            d="M23,9.15a12.4,12.4,0,0,1,8.79,3.44L38.39,6A22.11,22.11,0,0,0,23,0,23,23,0,0,0,2.45,12.67l7.68,6A13.7,13.7,0,0,1,23,9.15Z"
            style="fill:#ea4335;fill-rule:evenodd"
          ></path>
          <path
            id="fec94a04-6f76-4df2-ac2f-ff5afb3af958"
            data-name="Shape"
            d="M0,0H46V46H0Z"
            style="fill:none"
          ></path>
        </g>
      </g>
    </g>
  </svg>
  */
  {
    "id": "wTo6G8Fc",
    "block": "[[[10,\"svg\"],[14,1,\"e2e54239-d369-4682-88f5-a8d243a1e6e8\"],[14,\"data-name\",\"Layer 1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 46 46\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,1,\"b83b0052-137e-4bc4-9d82-4099ca260b2b\"],[14,\"data-name\",\"Google-Button\"],[12],[10,\"g\"],[14,1,\"a4c97bf5-1fea-4d4d-95f0-a89bf6400e48\"],[14,\"data-name\",\"btn google light normal\"],[12],[1,\"\\n      \"],[10,\"g\"],[14,1,\"e63e90b8-b6f6-42b1-8ccf-0f44d4713ce1\"],[14,\"data-name\",\"logo googleg 48dp\"],[12],[1,\"\\n        \"],[10,\"path\"],[14,1,\"b991b1a5-057b-4459-b838-ef00fbdc8cb8\"],[14,\"data-name\",\"Shape\"],[14,\"d\",\"M45.08,23.52a26.51,26.51,0,0,0-.42-4.7H23v8.9H35.38a10.6,10.6,0,0,1-4.59,6.94v5.77h7.43c4.35-4,6.86-9.9,6.86-16.91Z\"],[14,5,\"fill:#4285f4;fill-rule:evenodd\"],[12],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"path\"],[14,1,\"a35e0acd-7e62-4001-8819-643569b02f20\"],[14,\"data-name\",\"Shape\"],[14,\"d\",\"M23,46a22,22,0,0,0,15.22-5.57l-7.43-5.77a13.89,13.89,0,0,1-20.66-7.29H2.45v6A23,23,0,0,0,23,46Z\"],[14,5,\"fill:#34a853;fill-rule:evenodd\"],[12],[13],[1,\"\\n        \"],[10,\"path\"],[14,1,\"aa22e588-1301-4b3d-b898-1f0c2f684190\"],[14,\"data-name\",\"Shape\"],[14,\"d\",\"M10.13,27.37a13.62,13.62,0,0,1,0-8.74v-6H2.45a23,23,0,0,0,0,20.66l7.68-6Z\"],[14,5,\"fill:#fbbc05;fill-rule:evenodd\"],[12],[13],[1,\"\\n        \"],[10,\"path\"],[14,1,\"b0cbc053-4d74-4931-8539-10eb62b70668\"],[14,\"data-name\",\"Shape\"],[14,\"d\",\"M23,9.15a12.4,12.4,0,0,1,8.79,3.44L38.39,6A22.11,22.11,0,0,0,23,0,23,23,0,0,0,2.45,12.67l7.68,6A13.7,13.7,0,0,1,23,9.15Z\"],[14,5,\"fill:#ea4335;fill-rule:evenodd\"],[12],[13],[1,\"\\n        \"],[10,\"path\"],[14,1,\"fec94a04-6f76-4df2-ac2f-ff5afb3af958\"],[14,\"data-name\",\"Shape\"],[14,\"d\",\"M0,0H46V46H0Z\"],[14,5,\"fill:none\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/google-logo-color.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});