define("cc-frontend/templates/user/edit-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f1uaDYVL",
    "block": "[[[8,[39,0],null,[[\"@isCompleted\",\"@encounteredServerError\"],[[36,1],[36,2]]],null]],[],false,[\"user/edit-password\",\"completed\",\"serverError\"]]",
    "moduleName": "cc-frontend/templates/user/edit-password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});