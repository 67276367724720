define("cc-frontend/components/planner/planbook/weekly-order/course-slot.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "course-slot-wrapper": "_course-slot-wrapper_14clzm",
    "course-slot": "_course-slot_14clzm",
    "course-slot__title": "_course-slot__title_14clzm",
    "course-slot__left": "_course-slot__left_14clzm",
    "course-slot__move-pad": "_course-slot__move-pad_14clzm"
  };
  _exports.default = _default;
});