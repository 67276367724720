define("cc-frontend/components/bopit/tethered-body", ["exports", "@ember-decorators/component", "ember-tether/components/ember-tether"], function (_exports, _component, _emberTether) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BopitTetheredBody = (_dec = (0, _component.classNameBindings)("_isOpenClass", "_afterOpenClass"), _dec2 = (0, _component.tagName)("div"), _dec3 = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{yield}}
  
  */
  {
    "id": "WGRYy85D",
    "block": "[[[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec4 = Ember.inject.service, _dec5 = Ember.computed("isOpen"), _dec6 = Ember.computed("hasOpened"), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class BopitTetheredBody extends _emberTether.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "mouseEvents", _descriptor, this);

      _defineProperty(this, "clickedOutside", () => {});

      _defineProperty(this, "mouseHasEntered", () => {});

      _defineProperty(this, "mouseHasLeft", () => {});
    }

    get _isOpenClass() {
      return Ember.get(this, "isOpen") ? Ember.get(this, "isOpenClass") || "is-open" : Ember.get(this, "isClosedClass") || "is-closed";
    }

    get _afterOpenClass() {
      if (Ember.isNone(this.$())) return;
      let afterHasOpenedClass = Ember.get(this, "afterOpenClass") || Ember.get(this, "classPrefix") + "-has-opened";

      if (Ember.get(this, "hasOpened") === true) {
        return this.$().attr("class") + " " + afterHasOpenedClass;
      } else {
        let classes = this.$().attr("class") || "";
        return classes.replace(afterHasOpenedClass, "");
      }
    }

    init() {
      super.init(...arguments);
      Ember.get(this, "mouseEvents").on("mouseDown", this, "onMouseDown");
    }

    didInsertElement() {
      this._originalParentNode = this.element.parentNode;
      super.didInsertElement(...arguments);
      Ember.run.scheduleOnce("afterRender", () => {
        this.$('[contenteditable="true"]').focus();
      }); // Ember.run.scheduleOnce('afterRender', () => set(this, 'hasOpened', true))
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);

      if (Ember.get(this, "isOpen") === true) {
        Ember.run.scheduleOnce("afterRender", () => Ember.set(this, "hasOpened", true));
      }

      if (Ember.get(this, "isOpen") === false) {
        Ember.set(this, "hasOpened", false);
      }
    }

    willDestroyElement() {
      // this._super(...arguments);
      const {
        _tether,
        element
      } = this;
      this.removeTether(this._tether);
      this.removeElement(element);
      Ember.get(this, "mouseEvents").off("mouseDown", this, "onMouseDown"); // this._super(...arguments)
    }

    removeElement(element) {
      if (element.parentNode) {
        element.parentNode.removeChild(element);
      }

      if (this._originalParentNode) {
        this._originalParentNode.appendChild(element);
      }
    }

    onMouseDown(e) {
      let isSelf = Ember.$(e.target).closest("#" + Ember.get(this, "elementId")).length >= 1;
      let isAnchor = Ember.$(e.target).closest(Ember.get(this, "target")).length >= 1;
      let allowClick = Ember.$(e.target).closest("." + "bopit-allow-click").length >= 1;
      let isInteractive = Ember.get(this, "isInteractive");
      if (allowClick) return;

      if (isSelf === false && isAnchor === false) {
        this.clickedOutside(e);
        return true;
      }

      if (isInteractive === true && isSelf === true) return;

      if (isInteractive === false && isSelf === true) {
        this.clickedOutside(e);
        return true;
      }
    }

    mouseEnter(e) {
      this.mouseHasEntered(e); // this.sendAction("mouseHasEntered")
    }

    mouseLeave(e) {
      this.mouseHasLeft(e); // this.sendAction("mouseHasLeft")
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "mouseEvents", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "_isOpenClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_isOpenClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_afterOpenClass", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "_afterOpenClass"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = BopitTetheredBody;
});