define("cc-frontend/components/planner/planbook/create-edit/footers/edit.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "nav-box--base": "_nav-box--base_w088hj",
    "nav-box": "_nav-box_w088hj _nav-box--base_w088hj",
    "nav-box__top": "_nav-box__top_w088hj",
    "nav-box__head": "_nav-box__head_w088hj",
    "line-break": "_line-break_w088hj",
    "nav-box__subhead": "_nav-box__subhead_w088hj",
    "--is-link": "_--is-link_w088hj",
    "nav-box__nav": "_nav-box__nav_w088hj",
    "nav-box__nav__item": "_nav-box__nav__item_w088hj",
    "is-selected": "_is-selected_w088hj",
    "big-icon": "_big-icon_w088hj",
    "template-sub-nav": "_template-sub-nav_w088hj",
    "template-sub-nav__header": "_template-sub-nav__header_w088hj",
    "template-sub-nav__option": "_template-sub-nav__option_w088hj"
  };
  _exports.default = _default;
});