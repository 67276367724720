define("cc-frontend/templates/user/edit-password-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ToQVQO6x",
    "block": "[[[10,0],[14,0,\"user-sign-wrapper user-sign-wrapper--reset-password-expired\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"user-sign__title\"],[12],[1,\"\\n    The reset password link expired. 😳 But another link is on the way! 😃\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"user-sign__text\"],[12],[1,\"\\n    The link you clicked on was only good for 4 hours. Don't worry -- We've already sent you an\\n    email with a new link! Click that link and you'll have your password reset in a jiffy.\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/user/edit-password-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});