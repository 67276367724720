define("cc-frontend/components/admin/group/membership-table/invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  {{#if (gt @model.length 0)}}
    <EmberTable class="group-admin-membership-table" as |t|>
      <t.head @columns={{this.columns}} />
      <t.body @rows={{@model}} />
    </EmberTable>
  {{else}}
    <div class="tw-text-base">
      No members invited.
    </div>
  {{/if}}
  */
  {
    "id": "fW+4/x/7",
    "block": "[[[18,3,null],[1,\"\\n\"],[41,[28,[37,2],[[30,1,[\"length\"]],0],null],[[[1,\"  \"],[8,[39,3],[[24,0,\"group-admin-membership-table\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"head\"]],null,[[\"@columns\"],[[30,0,[\"columns\"]]]],null],[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,[[\"@rows\"],[[30,1]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"tw-text-base\"],[12],[1,\"\\n    No members invited.\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@model\",\"t\",\"&default\"],false,[\"yield\",\"if\",\"gt\",\"ember-table\"]]",
    "moduleName": "cc-frontend/components/admin/group/membership-table/invite.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});