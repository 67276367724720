define("cc-frontend/components/planner/course/create-edit/edit-modal", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="modal">
    <div local-class="modal-inner {{if this.isSmall "--is-small"}}">
      <div local-class="modal-inner__close" {{on "click" @onClose}} data-test-close-edit-class-modal>
        <Icons::RemoveCircle />
      </div>
      <Planner::Course::CreateEdit::EditMain
        @rotationCalendar={{@rotationCalendar}}
        @planbook={{@planbook}}
        @course={{@course}}
        @close={{@onClose}}
        @setSmallWidth={{this.setSmallWidth}}
        @setNormalWidth={{this.setNormalWidth}}
      />
  
    </div>
  </div>
  */
  {
    "id": "LTKzV2k8",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"modal\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/edit-modal.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[[28,[37,1],[\"modal-inner \",[52,[30,0,[\"isSmall\"]],\"--is-small\"]],null]],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/edit-modal.css\"]]]]]],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[[28,[37,0],[\"modal-inner__close\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/edit-modal.css\"]]]]]],[24,\"data-test-close-edit-class-modal\",\"\"],[4,[38,3],[\"click\",[30,1]],null],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,5],null,[[\"@rotationCalendar\",\"@planbook\",\"@course\",\"@close\",\"@setSmallWidth\",\"@setNormalWidth\"],[[30,2],[30,3],[30,4],[30,1],[30,0,[\"setSmallWidth\"]],[30,0,[\"setNormalWidth\"]]]],null],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13]],[\"@onClose\",\"@rotationCalendar\",\"@planbook\",\"@course\"],false,[\"local-class\",\"concat\",\"if\",\"on\",\"icons/remove-circle\",\"planner/course/create-edit/edit-main\"]]",
    "moduleName": "cc-frontend/components/planner/course/create-edit/edit-modal.hbs",
    "isStrictMode": false
  });

  let EditClassMainModal = (_dec = (0, _component.tagName)(""), _dec2 = Ember._action, _dec3 = Ember._action, _dec(_class = (_class2 = class EditClassMainModal extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isSmall", false);
    }

    setSmallWidth() {
      Ember.set(this, "isSmall", true);
    }

    setNormalWidth() {
      Ember.set(this, "isSmall", false);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "setSmallWidth", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "setSmallWidth"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setNormalWidth", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "setNormalWidth"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditClassMainModal;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EditClassMainModal);
});