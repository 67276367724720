define("cc-frontend/components/planner/menus/share-link/show-link-title", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Menus::Titles::WithBackArrow @goBack={{@goBack}} @title={{@title}} />
  */
  {
    "id": "pOQATNWC",
    "block": "[[[8,[39,0],null,[[\"@goBack\",\"@title\"],[[30,1],[30,2]]],null]],[\"@goBack\",\"@title\"],false,[\"planner/menus/titles/with-back-arrow\"]]",
    "moduleName": "cc-frontend/components/planner/menus/share-link/show-link-title.hbs",
    "isStrictMode": false
  });

  let ShowLinkTitleComponent = (_dec = (0, _component.tagName)(""), _dec(_class = class ShowLinkTitleComponent extends Ember.Component {}) || _class);
  _exports.default = ShowLinkTitleComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ShowLinkTitleComponent);
});