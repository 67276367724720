define("cc-frontend/lib/actions/lesson/LESSON_CARD_MARK_PUBLIC", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_MARK_PUBLIC = void 0;
  const LESSON_CARD_MARK_PUBLIC = {
    name: "LESSON_CARD_MARK_PUBLIC",
    params: {
      value: "boolean",
      cardStackId: "string",
      sectionId: "string",
      originalValue: "boolean"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).set("embedded", "attributes.isPublic", payload.value) // This needs to be a document set
      .set("attributes.sectionsArePristine", false).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).set("attributes.isPublic", payload.originalValue) // This needs to be a document set
      .set("attributes.sectionsArePristine", payload.originalValue).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);

      let card = _lodash.default.find(_lodash.default.get(models, "cardStack.attributes.cards"), {
        id: payload.sectionId
      });

      let cardTitle = _lodash.default.get(card, "title");

      let showHidden = payload.value === true ? "Showed" : "Hide";
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = Object.assign({}, context, {
        cardTitle: cardTitle,
        showOrHide: showHidden
      });
      return {
        title: `${showHidden} Lesson Card on Class Website`,
        titleForAnalytics: "Lesson Card - Toggle Public",
        description: `${showHidden} ${cardTitle}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models)
      };
    }

  };
  _exports.LESSON_CARD_MARK_PUBLIC = LESSON_CARD_MARK_PUBLIC;
  var _default = LESSON_CARD_MARK_PUBLIC;
  _exports.default = _default;
});