define("cc-frontend/lib/actions/lesson/LESSON_CARD_SET_GC_TOPIC", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareSetGoogleClassroomTopic;

  function prepareSetGoogleClassroomTopic(payload) {
    return {
      name: "LESSON_CARD_SET_GC_TOPIC",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  const patches = function (payload) {
    let setTopic = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.cardId
    }).set("embedded", "attributes.extraPostProperties.topicName", payload.topic).inc("embedded", "attributes._revision", 1).patch;

    return [setTopic];
  };

  const narrative = function (payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    return {
      title: "Set Google Classroom Topic",
      titleForAnalytics: "Lesson Card - GC Post - Set Topic",
      description: `Set Google Class topic to ${payload.topic}`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models, "updated")
    };
  };
});