define("cc-frontend/models/transferrable-date", ["exports", "cc-frontend/lib/structor"], function (_exports, _structor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransferrableDate extends _structor.default {
    defineStruct() {
      this.field("cardStackId");
      this.field("unitStart", []);
      this.field("unitEnd", []);
    }

  }

  _exports.default = TransferrableDate;
});