define("cc-frontend/components/print/lesson-card-standards", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PrintLessonCardStandards = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{#each card.attributes.standards as |standard|}}
      <div class="ccp-standard {{if (not showStandardDescriptions) 'ccp-standard--only-codes'}}">
        <span class="ccp-standard__code">{{standard.code}}</span>
        {{#if showStandardDescriptions}}
          <span class="ccp-standard__statement">{{{standard.statement}}}</span>
          <span class="ccp-standard__jurisdiction">{{standard.jurisdiction}}</span>
          <span class="ccp-standard__jurisdiction">{{standard.subject}}</span>
        {{/if}}
      </div>
    {{/each}}
    {{yield}}
  
  */
  {
    "id": "a/439Ub2",
    "block": "[[[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2,[\"attributes\",\"standards\"]]],null]],null],null,[[[1,\"    \"],[10,0],[15,0,[29,[\"ccp-standard \",[52,[28,[37,4],[[33,5]],null],\"ccp-standard--only-codes\"]]]],[12],[1,\"\\n      \"],[10,1],[14,0,\"ccp-standard__code\"],[12],[1,[30,1,[\"code\"]]],[13],[1,\"\\n\"],[41,[33,5],[[[1,\"        \"],[10,1],[14,0,\"ccp-standard__statement\"],[12],[2,[30,1,[\"statement\"]]],[13],[1,\"\\n        \"],[10,1],[14,0,\"ccp-standard__jurisdiction\"],[12],[1,[30,1,[\"jurisdiction\"]]],[13],[1,\"\\n        \"],[10,1],[14,0,\"ccp-standard__jurisdiction\"],[12],[1,[30,1,[\"subject\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[18,2,null],[1,\"\\n\"]],[\"standard\",\"&default\"],false,[\"each\",\"-track-array\",\"card\",\"if\",\"not\",\"showStandardDescriptions\",\"yield\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec(_class = (_class2 = class PrintLessonCardStandards extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = PrintLessonCardStandards;
});