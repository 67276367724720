define("cc-frontend/lib/actions/planbook/lessons/PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY", ["exports", "cc-frontend/lib/find-course-date", "lodash", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-activity", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-context"], function (_exports, _findCourseDate, _lodash, _lodashEs, _patchUpdater, _defaultPlanbookLessonsActivity, _defaultPlanbookLessonsContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareEraseAllLessonsForDay = prepareEraseAllLessonsForDay;
  _exports.default = _exports.PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY = void 0;

  function prepareEraseAllLessonsForDay(payload) {
    return {
      name: PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.name,
      payload: payload,
      patches: PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.patches(payload),
      undoPatches: PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.narrative, payload)
    };
  }

  const PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY = {
    name: "PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY",

    patches(payload) {
      return _lodash.default.compact(_lodash.default.map(payload.courses, course => {
        let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);

        if (courseDate) {
          return _patchUpdater.default.create("course", course.id, "attributes.calendar.dates", {
            id: courseDate.id
          }).set("embedded", "attributes.cardStackId", null).inc("attributes.calendar.version", 1).patch;
        } else {
          return null;
        }
      }));
    },

    undoPatches(payload) {
      return _lodash.default.compact(_lodash.default.map(payload.courses, course => {
        let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);

        if (courseDate) {
          return _patchUpdater.default.create("course", course.id, "attributes.calendar.dates", {
            id: courseDate.id
          }).set("embedded", "attributes.cardStackId", courseDate.attributes.cardStackId).inc("attributes.calendar.version", 1).patch;
        } else {
          return null;
        }
      }));
    },

    narrative(payload, findFn, userId) {
      let planbook = findFn("planbook", payload.planbookId); // @ts-ignore no types for moment

      let formattedDateString = moment(payload.dateString).format("ddd, MMM. D, YYYY");
      return {
        title: "Erased An Entire Day Of Lessons",
        titleForAnalytics: "Lesson - Erase Entire Day",
        description: `Erased all the lessons on ${formattedDateString} and removed their lesson histories.`,
        context: (0, _defaultPlanbookLessonsContext.default)(planbook, payload),
        activity: (0, _defaultPlanbookLessonsActivity.default)(payload.planbookId, userId)
      };
    }

  };
  _exports.PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY = PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY;
  var _default = PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY;
  _exports.default = _default;
});