define("cc-frontend/services/windowscroll", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Windowscroll extends Ember.Service.extend(Ember.Evented) {
    init() {
      super.init(...arguments);
      this.scrollTop = null;
      this.isScrollingUp = false;

      if (typeof Ember.$ !== "undefined") {
        let debounedScroll = _lodash.default.debounce(e => {
          this._onScroll(e, Ember.$(window).scrollTop());
        }, 30);

        Ember.$(window).on("wheel", debounedScroll);
        this.set("scrollTop", Ember.$(window).scrollTop());
      }
    }

    destroy() {
      super.destroy(...arguments);

      if (typeof Ember.$ !== "undefined") {
        Ember.$(window).off("scroll");
      }
    }

    _onScroll(e, top) {
      if (this.isDestroyed || this.isDestroying) return;
      this.set("isScrollingUp", this.scrollTop > top);
      this.set("scrollTop", top);
      this.trigger("scroll", e, top);
    }

  }

  _exports.default = Windowscroll;
});