define("cc-frontend/lib/structor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Structor {
    /**
     *
     * @param {object} attributes
     * @param {*} map
     * @param {*} key
     * @param {*} val
     */
    _field(attributes, map, key, val) {
      if (typeof val === "function") {
        attributes[key] = val(map);
      } else {
        attributes[key] = map[key] === undefined ? val : map[key];
      }
    }

    constructor(map = {}) {
      let attributes = {}; // this.field = (key, val) => this._field(attributes, map, key, val)

      this.field = function (key, val) {
        this._field(attributes, map, key, val);
      };

      this.defineStruct();
      return attributes;
    }

  }

  _exports.default = Structor;
});