define("cc-frontend/lib/prismic-helpers/extract-prismic-item-ids", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = extractPrismicItemIds;

  /**
   * A macro that returns a computed property that returns an array of proxy objects
   * that resolve to prismic-doc ids
   * @param  {String}  modelName -path to the prismicDoc `e.g. `prismicDoc` or `prismicSlice`
   * @return {Array<String>} - An array of ids to reference the prismic-doc
   */
  function extractPrismicItemIds(modelName) {
    /**
     * Example:
       ``` javascript
        class exampleComponent extends Component {
          prismicSlice = { items: [
                                    { "slice_type": {id: 3} },
                                    { "slice_type": {id: 6} },
                                    { "slice_type": {id: 4} }
                                  ]
                          }
          @extractPrismicItemIds("prismicSlice") prismicIds
        }
      ```
     */
    return Ember.computed(`${modelName}.items.[]`, function () {
      return (0, _lodashEs.flatMap)(Ember.get(this[modelName], "items"), item => {
        return (0, _lodashEs.map)((0, _lodashEs.values)(item), obj => Ember.get(obj, "id"));
      });
    });
  }
});