define("cc-frontend/lib/actions/lesson/LESSON_CARD_INTERMEDIATE_UPDATE", ["exports", "cc-frontend/lib/patch-updater"], function (_exports, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LESSON_CARD_INTERMEDIATE_UPDATE = void 0;
  const LESSON_CARD_INTERMEDIATE_UPDATE = {
    name: "LESSON_CARD_INTERMEDIATE_UPDATE",
    params: {
      value: {
        type: ["string", "boolean", "number"]
      },
      cardStackId: "string",
      prop: "string",
      sectionId: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).set("embedded", `attributes.${payload.prop}`, payload.value).patch;

      return [patch];
    },

    undoPatches: null,
    narrative: null
  };
  _exports.LESSON_CARD_INTERMEDIATE_UPDATE = LESSON_CARD_INTERMEDIATE_UPDATE;
});