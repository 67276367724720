define("cc-frontend/lib/actions/lesson/LESSON_ROLLBACK_VERSION", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_ROLLBACK_VERSION = void 0;
  const LESSON_ROLLBACK_VERSION = {
    name: "LESSON_ROLLBACK_VERSION",
    params: {
      lessonAttributes: "object",
      version: "number",
      cardStackId: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("lesson", payload.cardStackId); // patch.document.ops.set.push(['attributes', _.cloneDeep(payload.lessonAttributes)])


      _lodash.default.each(payload.lessonAttributes, (v, k) => {
        patch.set(`attributes.${k}`, _lodash.default.cloneDeep(v));
      });

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let lessonVersion = Ember.get(payload, "version");
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let lessonTitle = models.lesson.attributes.title;
      return {
        title: "Rolled lesson back to previous version",
        titleForAnalytics: "Lesson - Rollback to version",
        description: `Rolled ${lessonTitle} back to version "${payload.version}"`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models)
      };
    }

  };
  _exports.LESSON_ROLLBACK_VERSION = LESSON_ROLLBACK_VERSION;
  var _default = LESSON_ROLLBACK_VERSION;
  _exports.default = _default;
});