define("cc-frontend/lib/actions/group/GROUP_CHANGE_TITLE", ["exports", "cc-frontend/lib/patch-updater"], function (_exports, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GROUP_CHANGE_TITLE = void 0;
  const GROUP_CHANGE_TITLE = {
    name: "GROUP_CHANGE_TITLE",
    params: {
      groupId: "string",
      value: "string",
      originalValue: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("group", payload.groupId).set("attributes.title", payload.value).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("group", payload.groupId).set("attributes.title", payload.originalValue).patch;

      return [patch];
    },

    narrative(payload, _findFn, _userId) {
      return {
        title: "Changed Group Title",
        titleForAnalytics: "Group - Changed Title",
        description: `Changed the title from "${payload.originalValue}" to ${payload.value}`,
        context: {
          groupId: payload.groupId
        },
        activity: null
      };
    }

  };
  _exports.GROUP_CHANGE_TITLE = GROUP_CHANGE_TITLE;
  var _default = GROUP_CHANGE_TITLE;
  _exports.default = _default;
});