define("cc-frontend/components/planner/planbook/weekly-order.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "week-grid": "_week-grid_138y2g",
    "order-wrapper": "_order-wrapper_138y2g",
    "order": "_order_138y2g",
    "week-grid__template-date": "_week-grid__template-date_138y2g",
    "week-grid__day": "_week-grid__day_138y2g",
    "week-grid__routine-row": "_week-grid__routine-row_138y2g"
  };
  _exports.default = _default;
});