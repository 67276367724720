define("cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-context", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultPlanbookLessonContext;

  function defaultPlanbookLessonContext(planbook, payload) {
    let formattedDate = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
    return {
      planbookId: planbook.id,
      planbookTitle: planbook.attributes.title,
      dateString: payload.dateString,
      formattedDateString: formattedDate
    };
  }
});