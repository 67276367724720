define("cc-frontend/helpers/match-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MatchMedia extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "_mediaQueryList", null);

      _defineProperty(this, "_mediaQueryListener", null);
    }

    compute([mediaQueryString]) {
      return this._attachMatchMedia(mediaQueryString);
    }

    willDestroy() {
      this._detachMatchMedia();
    }

    _attachMatchMedia(mediaQueryString) {
      this._detachMatchMedia();

      this._mediaQueryListener = event => {
        // @ts-ignore
        this.recompute(event.matches);
      };

      this._mediaQueryList = window.matchMedia(mediaQueryString);

      this._mediaQueryList.addListener(this._mediaQueryListener);

      return this._mediaQueryList.matches;
    }

    _detachMatchMedia() {
      if (this._mediaQueryListener && this._mediaQueryList) {
        this._mediaQueryList.removeListener(this._mediaQueryListener);

        this._mediaQueryList = null;
        this._mediaQueryListener = null;
      }
    }

  }

  _exports.default = MatchMedia;
});