define("cc-frontend/components/school/edit/templates", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/find-doc", "cc-frontend/helpers/has-many-altogether", "cc-frontend/lib/actions/planbook/PLANBOOK_CREATE_LESSON_TEMPLATE", "cc-frontend/lib/object-id-gen", "ember-could-get-used-to-this"], function (_exports, _glimmerComponent, _findDoc, _hasManyAltogether, _PLANBOOK_CREATE_LESSON_TEMPLATE, _objectIdGen, _emberCouldGetUsedToThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="slideout__body__heading">
    <h2 class="slideout__body__heading__title tw-text-link-blue">
      Shared Templates
    </h2>
    <button
      class="slideout__body__heading__add tw-ml-4 slideout__body__heading__add--subtler test-add-template-btn"
      type="button"
      {{on "click" this.addTemplate}}
    >
      <Icons::IosPlus />
      Add Template
    </button>
  </div>
  <h3 class="school-setup__h3">
    Your teachers will be able to copy any of these templates into their own planbooks
  </h3>
  
  {{#if this.planbook.isPending}}
    <div class="tw-m-10 tw-flex tw-flex-row tw-justify-center tw-items-center">
      <DS::ThreeDotSpinner />
    </div>
  {{else}}
    <div class="school-setup-edit-templates">
      {{#each this.cardStackTemplates.content as |cardStack|}}
        <div class="school-setup-edit-templates__template">
          <School::Edit::LessonTemplate
            @cardStack={{cardStack}}
            @planbook={{this.planbook.content}}
            @hideTemplateMagic={{true}}
            @hideDeleteButton={{false}}
            @componentId={{concat "lesson-template-" cardStack.id}}
          />
        </div>
      {{/each}}
    </div>
  {{/if}}
  */
  {
    "id": "WtcU9f/4",
    "block": "[[[10,0],[14,0,\"slideout__body__heading\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"slideout__body__heading__title tw-text-link-blue\"],[12],[1,\"\\n    Shared Templates\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"slideout__body__heading__add tw-ml-4 slideout__body__heading__add--subtler test-add-template-btn\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"addTemplate\"]]],null],[12],[1,\"\\n    \"],[8,[39,1],null,null,null],[1,\"\\n    Add Template\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h3\"],[14,0,\"school-setup__h3\"],[12],[1,\"\\n  Your teachers will be able to copy any of these templates into their own planbooks\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"planbook\",\"isPending\"]],[[[1,\"  \"],[10,0],[14,0,\"tw-m-10 tw-flex tw-flex-row tw-justify-center tw-items-center\"],[12],[1,\"\\n    \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"school-setup-edit-templates\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"cardStackTemplates\",\"content\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"school-setup-edit-templates__template\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@cardStack\",\"@planbook\",\"@hideTemplateMagic\",\"@hideDeleteButton\",\"@componentId\"],[[30,1],[30,0,[\"planbook\",\"content\"]],true,false,[28,[37,7],[\"lesson-template-\",[30,1,[\"id\"]]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"cardStack\"],false,[\"on\",\"icons/ios-plus\",\"if\",\"d-s/three-dot-spinner\",\"each\",\"-track-array\",\"school/edit/lesson-template\",\"concat\"]]",
    "moduleName": "cc-frontend/components/school/edit/templates.hbs",
    "isStrictMode": false
  });

  let SchoolEditTemplates = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class SchoolEditTemplates extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "planbook", _descriptor2, this);

      _initializerDefineProperty(this, "cardStackTemplates", _descriptor3, this);
    }

    addTemplate() {
      if (this.planbook.content === null) return;

      let newId = _objectIdGen.default.create();

      let newLessonAttributes = {
        cards: [{
          id: _objectIdGen.default.create(),
          type: "card-standards",
          attributes: {
            position: 0,
            standards: [],
            title: "Standards",
            isPublic: false
          }
        }]
      };
      this.store.dispatchAction(_PLANBOOK_CREATE_LESSON_TEMPLATE.PLANBOOK_CREATE_LESSON_TEMPLATE, {
        planbookId: this.planbook.content.id,
        cardStackAttributes: newLessonAttributes,
        newId: newId,
        assignTemplatePatches: [],
        assignTemplateUndoPatches: []
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "planbook", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _findDoc.default(() => ["planbook", this.args.group.attributes.cardStackTemplatesPlanbookId]);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cardStackTemplates", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _hasManyAltogether.default(() => {
        let model = this.planbook.content ? this.planbook.content : null;
        return [model, "cardStackTemplates"];
      });
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addTemplate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addTemplate"), _class.prototype)), _class));
  _exports.default = SchoolEditTemplates;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SchoolEditTemplates);
});