define("cc-frontend/components/icons/paste-doc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    {{! Copy Paste 1 }}
    <g>
      <path
        d="M1.5,23c-0.827,0 -1.5,-0.673 -1.5,-1.5v-17c0,-0.827 0.673,-1.5 1.5,-1.5h3.5v-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h1.55c0.234,-1.155 1.25,-2 2.45,-2c1.2,0 2.216,0.845 2.45,2h1.55c0.276,0 0.5,0.224 0.5,0.5v0.5h3.5c0.827,0 1.5,0.673 1.5,1.5v3.5c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3.5c0,-0.276 -0.224,-0.5 -0.5,-0.5h-3.5v1.5c0,0.276 -0.224,0.5 -0.5,0.5h-8c-0.276,0 -0.5,-0.224 -0.5,-0.5v-1.5h-3.5c-0.276,0 -0.5,0.224 -0.5,0.5v17c0,0.276 0.224,0.5 0.5,0.5h8c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-8Zm11.5,-18v-2h-1.5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.827 -0.673,-1.5 -1.5,-1.5c-0.827,0 -1.5,0.673 -1.5,1.5c0,0.276 -0.224,0.5 -0.5,0.5h-1.5v2h7Z"
      ></path>
      <path
        d="M12.5,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-11.5c0,-0.827 0.673,-1.5 1.5,-1.5h7.086c0.401,0 0.777,0.156 1.061,0.439l1.914,1.914c0.283,0.284 0.439,0.66 0.439,1.061v9.586c0,0.827 -0.673,1.5 -1.5,1.5h-9Zm0,-13.5c-0.276,0 -0.5,0.224 -0.5,0.5v11.5c0,0.276 0.224,0.5 0.5,0.5h9c0.276,0 0.5,-0.224 0.5,-0.5v-9.586c0,-0.132 -0.053,-0.26 -0.146,-0.354l-1.914,-1.914c-0.093,-0.093 -0.222,-0.146 -0.354,-0.146h-7.086Z"
      ></path>
      <path
        d="M14.5,14.999c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-5Z"
      ></path>
      <path
        d="M14.5,17.999c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-5Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "nz4kd+9u",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n\"],[1,\"  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M1.5,23c-0.827,0 -1.5,-0.673 -1.5,-1.5v-17c0,-0.827 0.673,-1.5 1.5,-1.5h3.5v-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h1.55c0.234,-1.155 1.25,-2 2.45,-2c1.2,0 2.216,0.845 2.45,2h1.55c0.276,0 0.5,0.224 0.5,0.5v0.5h3.5c0.827,0 1.5,0.673 1.5,1.5v3.5c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3.5c0,-0.276 -0.224,-0.5 -0.5,-0.5h-3.5v1.5c0,0.276 -0.224,0.5 -0.5,0.5h-8c-0.276,0 -0.5,-0.224 -0.5,-0.5v-1.5h-3.5c-0.276,0 -0.5,0.224 -0.5,0.5v17c0,0.276 0.224,0.5 0.5,0.5h8c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-8Zm11.5,-18v-2h-1.5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.827 -0.673,-1.5 -1.5,-1.5c-0.827,0 -1.5,0.673 -1.5,1.5c0,0.276 -0.224,0.5 -0.5,0.5h-1.5v2h7Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12.5,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-11.5c0,-0.827 0.673,-1.5 1.5,-1.5h7.086c0.401,0 0.777,0.156 1.061,0.439l1.914,1.914c0.283,0.284 0.439,0.66 0.439,1.061v9.586c0,0.827 -0.673,1.5 -1.5,1.5h-9Zm0,-13.5c-0.276,0 -0.5,0.224 -0.5,0.5v11.5c0,0.276 0.224,0.5 0.5,0.5h9c0.276,0 0.5,-0.224 0.5,-0.5v-9.586c0,-0.132 -0.053,-0.26 -0.146,-0.354l-1.914,-1.914c-0.093,-0.093 -0.222,-0.146 -0.354,-0.146h-7.086Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M14.5,14.999c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M14.5,17.999c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-5Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/paste-doc.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});