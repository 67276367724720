define("cc-frontend/lib/actions/lesson/LESSON_ERASE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "moment", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _moment, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareLessonErase = prepareLessonErase;
  _exports.default = _exports.LESSON_ERASE = void 0;

  function prepareLessonErase(payload) {
    return {
      name: LESSON_ERASE.name,
      payload: payload,
      patches: LESSON_ERASE.patches(payload),
      undoPatches: LESSON_ERASE.undoPatches(payload),
      narrative: (0, _lodash.partial)(LESSON_ERASE.narrative, payload)
    };
  }

  const LESSON_ERASE = {
    name: "LESSON_ERASE",

    patches(payload) {
      let courseDate = _lodash.default.chain(payload.course.attributes.calendar.dates).filter(date => date.type === "course-date-custom").find(date => date.attributes.dateString === payload.dateString).value();

      if (courseDate === undefined) throw Error("CourseDate is Undefined");
      return [_patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
        id: courseDate.id
      }).set("embedded", "attributes.cardStackId", null).inc("attributes.calendar.version", 1).patch];
    },

    undoPatches(payload) {
      let courseDate = _lodash.default.chain(payload.course.attributes.calendar.dates).filter(date => date.type === "course-date-custom").find(date => date.attributes.dateString === payload.dateString).value();

      if (courseDate === undefined) throw Error("CourseDate is Undefined");
      return [_patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
        id: courseDate.id
      }).set("embedded", "attributes.cardStackId", payload.cardStackId).inc("attributes.calendar.version", 1).patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let courseTitle = payload.course.attributes.title;
      let lessonTitle = models.cardStack ? models.cardStack.attributes.title : null;
      let formattedDateString = (0, _moment.default)(payload.dateString).format("ddd. MMM. D, YYYY");
      return {
        title: "Erased A Lesson",
        titleForAnalytics: "Lesson - Erase",
        description: `Completely erased and deleted the history for "${lessonTitle}", your ${courseTitle} lesson on ${formattedDateString}.`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models, "deleted")
      };
    }

  };
  _exports.LESSON_ERASE = LESSON_ERASE;
  var _default = LESSON_ERASE;
  _exports.default = _default;
});