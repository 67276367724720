define("cc-frontend/components/kb/prismic-article/explainer.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "explainer": "_explainer_1ba7mc",
    "text-content": "_text-content_1ba7mc",
    "solo-text": "_solo-text_1ba7mc",
    "explainer-media": "_explainer-media_1ba7mc",
    "explainer__media-only": "_explainer__media-only_1ba7mc"
  };
  _exports.default = _default;
});