define("cc-frontend/components/admin/actions-for-session-list", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/belongs-to", "cc-frontend/helpers/query", "ember-could-get-used-to-this"], function (_exports, _glimmerComponent, _belongsTo, _query, _emberCouldGetUsedToThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#vertical-collection
    this.sessionQuery.results
    lastReached="loadMoreBelow"
    estimateHeight=48
    as |item _index|
  }}
    {{#if item.dateString}}
      <div class="admin-version-box__item admin-version-box__item--date-string tw-sticky">
        <div class="admin-version-box__item__date-string">
          {{moment-format item.dateString "dddd MMM Do, YYYY"}}
        </div>
      </div>
    {{else}}
      <ActionSummaryProvider @actionId={{item.id}} as |actionSummary|>
        <div
          class="admin-version-box__item
            {{if (eq this.selectedActionId actionSummary.content.id) "--is-selected"}}"
          {{on "click" (fn @selectAction actionSummary.id)}}
        >
          <div class="admin-version-box__item__version">
            {{moment-format actionSummary.content.attributes.serverReceivedAt "h:mma"}}
          </div>
          <div class="admin-version-box__item__text">
            <div class="admin-version-box__item__action-name">
              {{#if actionSummary.content.attributes.titleForAnalytics}}
                {{actionSummary.content.attributes.titleForAnalytics}}
              {{else}}
                {{actionSummary.content.attributes.name}}
              {{/if}}
            </div>
            <div class="admin-version-box__item__description">
              {{actionSummary.content.attributes.description}}
            </div>
          </div>
        </div>
      </ActionSummaryProvider>
    {{/if}}
  {{/vertical-collection}}
  */
  {
    "id": "LsQdGpK6",
    "block": "[[[6,[39,0],[[30,0,[\"sessionQuery\",\"results\"]]],[[\"lastReached\",\"estimateHeight\"],[\"loadMoreBelow\",48]],[[\"default\"],[[[[41,[30,1,[\"dateString\"]],[[[1,\"    \"],[10,0],[14,0,\"admin-version-box__item admin-version-box__item--date-string tw-sticky\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"admin-version-box__item__date-string\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[[30,1,[\"dateString\"]],\"dddd MMM Do, YYYY\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@actionId\"],[[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[11,0],[16,0,[29,[\"admin-version-box__item\\n          \",[52,[28,[37,4],[[30,0,[\"selectedActionId\"]],[30,3,[\"content\",\"id\"]]],null],\"--is-selected\"]]]],[4,[38,5],[\"click\",[28,[37,6],[[30,4],[30,3,[\"id\"]]],null]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"admin-version-box__item__version\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[[30,3,[\"content\",\"attributes\",\"serverReceivedAt\"]],\"h:mma\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"admin-version-box__item__text\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"admin-version-box__item__action-name\"],[12],[1,\"\\n\"],[41,[30,3,[\"content\",\"attributes\",\"titleForAnalytics\"]],[[[1,\"              \"],[1,[30,3,[\"content\",\"attributes\",\"titleForAnalytics\"]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,3,[\"content\",\"attributes\",\"name\"]]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"admin-version-box__item__description\"],[12],[1,\"\\n            \"],[1,[30,3,[\"content\",\"attributes\",\"description\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[]]]],[1,2]]]]]],[\"item\",\"_index\",\"actionSummary\",\"@selectAction\"],false,[\"vertical-collection\",\"if\",\"moment-format\",\"action-summary-provider\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "cc-frontend/components/admin/actions-for-session-list.hbs",
    "isStrictMode": false
  });

  let AdminActionsForSessionList = (_dec = Ember.inject.service, _dec2 = (0, _belongsTo.default)("model", "user"), _dec3 = Ember._action, (_class = class AdminActionsForSessionList extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);

      _defineProperty(this, "selectedActionId", null);

      _defineProperty(this, "nextOffset", 0);

      _initializerDefineProperty(this, "sessionQuery", _descriptor3, this);
    }

    loadMoreBelow() {
      this.nextOffset = this.nextOffset + 100;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionQuery", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _query.default(() => ["actions_for_session", this.args.model.id, this.nextOffset]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadMoreBelow", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadMoreBelow"), _class.prototype)), _class));
  _exports.default = AdminActionsForSessionList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AdminActionsForSessionList);
});