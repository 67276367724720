define("cc-frontend/components/group/membership-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @groupMembership.type "group-membership-accepted")}}
    {{#if @groupMembership.attributes.userId}}
      <LinkTo @route="user.show" @model={{@groupMembership.attributes.userId}}>
        <ContainerUserSummary
          @userId={{@groupMembership.attributes.userId}}
          @hideSpinner={{true}}
          as |userSummary|
        >
          <User::Avatar
            @userSummary={{userSummary.content}}
            @avatarSizeClass="user-avatar--sm"
            @enableTooltip={{true}}
          />
  
        </ContainerUserSummary>
      </LinkTo>
    {{/if}}
  {{else}}
    <User::Avatar
      @invitedUser={{true}}
      @email={{@groupMembership.attributes.email}}
      @avatarSizeClass="user-avatar--sm"
      @enableTooltip={{true}}
    />
  
  {{/if}}
  {{yield}}
  */
  {
    "id": "ydLFo2r+",
    "block": "[[[41,[28,[37,1],[[30,1,[\"type\"]],\"group-membership-accepted\"],null],[[[41,[30,1,[\"attributes\",\"userId\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"user.show\",[30,1,[\"attributes\",\"userId\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@userId\",\"@hideSpinner\"],[[30,1,[\"attributes\",\"userId\"]],true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@userSummary\",\"@avatarSizeClass\",\"@enableTooltip\"],[[30,2,[\"content\"]],\"user-avatar--sm\",true]],null],[1,\"\\n\\n      \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@invitedUser\",\"@email\",\"@avatarSizeClass\",\"@enableTooltip\"],[true,[30,1,[\"attributes\",\"email\"]],\"user-avatar--sm\",true]],null],[1,\"\\n\\n\"]],[]]],[18,3,null]],[\"@groupMembership\",\"userSummary\",\"&default\"],false,[\"if\",\"eq\",\"link-to\",\"container-user-summary\",\"user/avatar\",\"yield\"]]",
    "moduleName": "cc-frontend/components/group/membership-avatar.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});