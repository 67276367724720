define("cc-frontend/components/dialogs/ask-for-team-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h1 class="bold-dialog__title">
    What is the name of the team?
  </h1>
  {{#if this.options.isCreatingTeam}}
    <div class="tw-m-4">
      <DS::ThreeDotSpinner />
    </div>
  {{else}}
    <div class="bold-dialog__form">
      <Input
        @value={{@options.nameOfNewTeam}}
        placeholder="Enter your team's name"
        class="bold-dialog__form__input test-enter-teams-name"
      />
    </div>
    <div class="bold-dialog__buttons">
      {{#liquid-if @options.nameOfNewTeam class="lf-fade-down"}}
        <div
          class="bold-dialog__buttons__submit test-create-team-btn"
          {{action @accept @options.nameOfNewTeam}}
        >
          Create my team!
        </div>
      {{/liquid-if}}
      <div class="bold-dialog__buttons__cancel test-cancel-create-team-btn" {{action @decline}}>
        Cancel
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "Z9c4jQHK",
    "block": "[[[10,\"h1\"],[14,0,\"bold-dialog__title\"],[12],[1,\"\\n  What is the name of the team?\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"options\",\"isCreatingTeam\"]],[[[1,\"  \"],[10,0],[14,0,\"tw-m-4\"],[12],[1,\"\\n    \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"bold-dialog__form\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,\"placeholder\",\"Enter your team's name\"],[24,0,\"bold-dialog__form__input test-enter-teams-name\"]],[[\"@value\"],[[30,1,[\"nameOfNewTeam\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"bold-dialog__buttons\"],[12],[1,\"\\n\"],[6,[39,3],[[30,1,[\"nameOfNewTeam\"]]],[[\"class\"],[\"lf-fade-down\"]],[[\"default\"],[[[[1,\"      \"],[11,0],[24,0,\"bold-dialog__buttons__submit test-create-team-btn\"],[4,[38,4],[[30,0],[30,2],[30,1,[\"nameOfNewTeam\"]]],null],[12],[1,\"\\n        Create my team!\\n      \"],[13],[1,\"\\n\"]],[]]]]],[1,\"    \"],[11,0],[24,0,\"bold-dialog__buttons__cancel test-cancel-create-team-btn\"],[4,[38,4],[[30,0],[30,3]],null],[12],[1,\"\\n      Cancel\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@options\",\"@accept\",\"@decline\"],false,[\"if\",\"d-s/three-dot-spinner\",\"input\",\"liquid-if\",\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/ask-for-team-title.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});