define("cc-frontend/app", ["exports", "@segment/analytics-next", "@sentry/browser", "ember-load-initializers", "ember-resolver", "fetch", "moment", "moment-range", "reflect-metadata", "cc-frontend/config/environment", "cc-frontend/lib/object-id-gen", "cc-frontend/reopenings/route", "lodash-es"], function (_exports, _analyticsNext, Sentry, _emberLoadInitializers, _emberResolver, _fetch, _moment, _momentRange, _reflectMetadata, _environment, _objectIdGen, _route, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateFullStorySessionUrl = generateFullStorySessionUrl;
  _exports.handleError = handleError;
  _exports.notifySentry = notifySentry;
  _exports.postError = postError;
  _exports.default = _exports.analytics = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const analytics = new _analyticsNext.AnalyticsBrowser(); // @ts-ignore

  _exports.analytics = analytics;
  window.analytics = analytics;

  if (_environment.default.environment === "production") {
    analytics.load({
      writeKey: "vll1uqsjn1",
      cdnURL: "https://segcdn.commoncurriculum.com"
    }, {
      integrations: {
        All: true,
        // We'll load fullstory ourselves
        FullStory: false
      },
      obfuscate: true
    });
  }

  console.log(`Client Version: ${_environment.default.APP.version}`);

  if (window.location) {
    let href = window.location.pathname;
    let newHref = href.replace(/[/]{2,}/, "/");

    if (href !== newHref) {
      console.log("Replacing URL as it has double slashes in it."); // @ts-ignore

      window.history.replaceState({
        path: newHref
      }, null, newHref);
    }
  }

  (0, _momentRange.extendMoment)(_moment.default); // Make sure the default title tag is removed once we build the app

  if (typeof document !== "undefined") {
    let titleTag = document.getElementsByClassName("TITLE_NOT_FASTBOOT");

    if (typeof titleTag !== "undefined" && titleTag[0]) {
      titleTag[0].remove();
    }
  }

  if (window === undefined) {
    // @ts-ignore
    // eslint-disable-next-line no-global-assign
    document = document || {};
  }

  if (_environment.default.environment === "production") {
    let version = _environment.default.CLIENT_VERSION.replace("Cc.", "");

    (0, Sentry.init)({
      dsn: _environment.default.SENTRY_DSN_PUBLIC,
      release: version,
      environment: `${_environment.default.environment}_ember`,
      integrations: [new Sentry.Integrations.Breadcrumbs({
        xhr: false
      })],

      beforeSend(event, hint) {
        if ((hint === null || hint === void 0 ? void 0 : hint.originalException) !== undefined && typeof hint.originalException !== "string" && (hint === null || hint === void 0 ? void 0 : hint.originalException) !== null) {
          const error = hint.originalException;

          if (error && error.stack && error.stack.match(/assets\/vendor\/ckeditor-4\.18\.0\/ckeditor/i)) {
            event.fingerprint = ["ckeditor"];
          }
        }

        return event;
      }

    });
    Sentry.setTag("clientVersion", _environment.default.CLIENT_VERSION);
  }

  if (_environment.default.environment !== "test") {
    // @ts-ignore
    Ember.onerror = function (error) {
      // I'm not sure why we're not able to catch this error other places.
      // Since this is a problem with what appears to be a stale connection
      // this should fix the issue
      if ((0, _lodashEs.has)(error, "reason") && error.reason === "unmatched topic") {
        window.location.reload();
      } else {
        handleError(error);
      }
    };
  }

  Ember.RSVP.on("error", function (reason) {
    console.log("RSVP ERROR", reason); // let id = ObjectId.create()
    // postError(reason, id)
    // displayError(id)
  });
  let shouldAutoboot = window && window.location && window.location.pathname.search(/^\/print/) > -1 && window.location.href.search(/CC_DEBUG/) === -1 ? false : true; // Don't autoboot in storybook

  let isInStoryBook = false;

  try {
    isInStoryBook = window && window.parent && window.parent.location.href.search("http://localhost:9001") > -1;
  } catch (e) {
    console.log("In iframe probably");
  }

  shouldAutoboot = isInStoryBook ? false : shouldAutoboot;

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "autoboot", shouldAutoboot);

      _defineProperty(this, "Resolver", _emberResolver.default);
    }

  }

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  if (window.CKEDITOR && window.document) {
    window.CKEDITOR.disableAutoInline = true;
    window.CKEDITOR.config.customConfig = "/ckeditor/config.js";
    if (window.CKEDITOR.loadFullCore) window.CKEDITOR.loadFullCore();
  }

  let hasHandledError = false;

  function generateFullStorySessionUrl(atCurrentTime = false) {
    let url = typeof FS !== "undefined" && FS.getCurrentSessionURL ? FS.getCurrentSessionURL(atCurrentTime) : null;
    return url !== null ? url.replace("fulls-api.commoncurriculum.net", "fullstory.com") : url;
  }

  function handleError(error) {
    if (hasHandledError === true) return;
    hasHandledError = true;

    let id = _objectIdGen.default.create();

    console.error(`Error Id: ${id}`, error.name, error.message, error.stack, error);
    if (_environment.default.environment === "test") return;
    notifySentry(error, id);
    postError(error, id);
    displayError(id);

    if (window && window.location && _environment.default.environment !== "development") {
      setTimeout(() => {
        window.location.reload();
      }, 30000);
    } // We send this without using the ajax service to be sure we're not getting/
    // caught up with app internals that might be broke.

  }

  function notifySentry(error, id) {
    let sessionUrl = generateFullStorySessionUrl();
    let userId = null;
    let sessionId = null;

    if (window.localStorage !== undefined) {
      let sessionStore = JSON.parse(localStorage.getItem("sessionStore") || "{}");
      userId = sessionStore["userId"];
      sessionId = sessionStore["sessionId"];
    }

    Sentry.withScope(scope => {
      // @ts-ignore
      scope.setLevel("fatal");
      scope.setExtra("errorId", id);
      scope.setExtra("fullStorySessionUrl", sessionUrl);
      scope.setTag("clientVersion", _environment.default.CLIENT_VERSION); // @ts-ignore

      scope.setTag("userId", userId); // @ts-ignore

      scope.setTag("sessionId", sessionId);
      scope.setTag("errorId", id);
      Sentry.captureException(error);
    }); // @ts-ignore

    if (typeof window.gtag !== "undefined") {
      // @ts-ignore
      window.gtag("event", "exception", {
        description: error.name,
        fatal: true // set to true if the error is fatal

      });
    }
  }

  function postError(error, id) {
    let fullStorySessionUrl = generateFullStorySessionUrl(true);
    let token = null;
    let userId = null;
    let sessionId = null;

    if (window.localStorage !== undefined) {
      let sessionStore = JSON.parse(localStorage.getItem("sessionStore") || "{}");
      token = sessionStore["token"];
      userId = sessionStore["userId"];
      sessionId = sessionStore["sessionId"];
    }

    if (window && window.analytics) {
      window.analytics.track("Client Error", {
        errorId: id,
        title: error.name,
        description: error.message,
        sessionLink: `https://${window.location.host}/admin/sessions/${sessionId}`,
        fullStorySessionUrl: fullStorySessionUrl,
        context: {
          sessionId: sessionId,
          userId: userId
        }
      });
    }

    if (typeof FastBoot === "undefined") {
      let headers = {
        "Content-Type": "application/json; charset=utf-8"
      };
      if (token) headers["Authroization"] = `Bearer ${token}`;
      if (sessionId) headers["X-SESSION-ID"] = sessionId;
      (0, _fetch.default)("/api/v4/actions", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          action: {
            id: id,
            name: "CLIENT_ERROR",
            narrativeTitle: error.name,
            narrativeDescription: error.message,
            narrativeContext: {
              fullStorySessionUrl: fullStorySessionUrl,
              stackTrace: error.stack,
              sessionId: sessionId,
              userId: userId,
              url: window.location.href,
              sessionLink: `https://${window.location.host}/admin/sessions/${sessionId}`
            }
          }
        })
      });
    }
  }

  function displayError(id) {
    if (window && window.swal) {
      window.swal({
        title: "There's been an error. We need to refresh your browser",
        text: `We've been notified. Error code: ${id}`,
        type: "warning",
        showCancelButton: false
      }).then(() => {
        window.location.reload();
      }).catch(() => {
        window.location.reload();
      });
    }
  }

  var _default = App;
  _exports.default = _default;
});