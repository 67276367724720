define("cc-frontend/components/dialogs/link-as-attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h1 class="create-annotation__title">
    Enter your link information below:
  </h1>
  
  <div class="bold-dialog__form" style="flex-direction:column;">
    <Input
      @value={{@options.url}}
      placeholder="URL"
      class="bold-dialog__form__input test-enter-teams-name"
      {{autofocus}}
    />
    <Input
      @value={{@options.title}}
      placeholder="Title (optional)"
      class="bold-dialog__form__input test-enter-teams-name"
    />
  </div>
  <div class="bold-dialog__buttons">
    {{#liquid-if @options.url class="lf-fade-down"}}
      <div
        class="bold-dialog__buttons__submit test-create-team-btn"
        {{on "click" (fn @accept @options)}}
      >
        Add link
      </div>
    {{/liquid-if}}
    <div
      class="bold-dialog__buttons__cancel test-cancel-create-team-btn"
      {{on "click" (fn @decline @options)}}
    >
      Cancel
    </div>
  </div>
  */
  {
    "id": "0Ma+m65d",
    "block": "[[[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Enter your link information below:\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"bold-dialog__form\"],[14,5,\"flex-direction:column;\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"placeholder\",\"URL\"],[24,0,\"bold-dialog__form__input test-enter-teams-name\"],[4,[38,1],null,null]],[[\"@value\"],[[30,1,[\"url\"]]]],null],[1,\"\\n  \"],[8,[39,0],[[24,\"placeholder\",\"Title (optional)\"],[24,0,\"bold-dialog__form__input test-enter-teams-name\"]],[[\"@value\"],[[30,1,[\"title\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"bold-dialog__buttons\"],[12],[1,\"\\n\"],[6,[39,2],[[30,1,[\"url\"]]],[[\"class\"],[\"lf-fade-down\"]],[[\"default\"],[[[[1,\"    \"],[11,0],[24,0,\"bold-dialog__buttons__submit test-create-team-btn\"],[4,[38,3],[\"click\",[28,[37,4],[[30,2],[30,1]],null]],null],[12],[1,\"\\n      Add link\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"  \"],[11,0],[24,0,\"bold-dialog__buttons__cancel test-cancel-create-team-btn\"],[4,[38,3],[\"click\",[28,[37,4],[[30,3],[30,1]],null]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@options\",\"@accept\",\"@decline\"],false,[\"input\",\"autofocus\",\"liquid-if\",\"on\",\"fn\"]]",
    "moduleName": "cc-frontend/components/dialogs/link-as-attachment.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});