define("cc-frontend/lib/section-moving", ["exports", "cc-frontend/app", "ember-sortable/utils/manager"], function (_exports, _app, _manager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hideSectionValues = hideSectionValues;
  _exports.showSectionValues = showSectionValues;
  _exports.startSorting = startSorting;
  _exports.completeSorting = completeSorting;

  function hideSectionValues() {
    $(".week-grid").css({
      visibility: "hidden"
    }); // Setting the max-height instead of display: none seems to reduce time spend in layout

    $("body").append(`
    <style type="text/css" class="-moving-section-style" id="section-style-${Ember.get(this, "model.id")}">
      .lesson-section__body {
        // visibility: hidden;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
      }
      .lesson-sections__btn-add-lesson-card-to-bottom-wrapper,
      .lesson-sections__btn-add-lesson-card-to-bottom{
        padding-top: 0;
        padding-bottom: 0;
        // visibility: hidden;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
      }
    </style>
  `);
    $(".week-grid").css("visibility", "visible");
    $("body").addClass("is-dragging");
  }

  function showSectionValues() {// $('.week-grid').css({opacity: 0})
    // $('.week-grid').css('visibility', 'hidden')
    // $(`#section-style-${get(this, 'model.id')}`).html("")
    // $('.week-grid').css('visibility', 'visible')
    // Ember.run.scheduleOnce('afterRender', () => {
    //   $('.week-grid').css({opacity: 1})
    // })
  }

  function startSorting(originalEvent) {
    this.dragging.set("isDragging", true);
    this.beginMove(); // this._sortableManager = null

    let clientY = originalEvent.clientY; // let margin = this.$().offset().top - $(window).scrollTop()

    this.hideSectionValues();
    let {
      top,
      left
    } = this.$().offset();
    originalEvent.pageX = left;
    originalEvent.pageY = top;
    $(window).scrollTop(top - clientY + 20); // $("body").animate({scrollTop: (top - clientY + 20)}, 500)
    // Give it a frame to catch up

    Ember.run.later(() => {
      if (this.isDestroyed || this.isDestroying) return;
      this._sortableManager = new _manager.default({
        node: this,
        onComplete: (...args) => {
          try {
            this.completeSorting(...args);
          } catch (e) {
            (0, _app.handleError)(e);
          }
        },
        onCancel: (...args) => {
          try {
            this.completeSorting(...args);
          } catch (e) {
            (0, _app.handleError)(e);
          }
        }
      });

      this._sortableManager.start(originalEvent);
    }, 30);
  }

  function completeSorting() {
    if (this === undefined) return;
    this.dragging.set("isDragging", false);
    this.showSectionValues();
    let offset = this._sortableManager === undefined ? 0 : this._sortableManager.gesture.clientY;
    let id = Ember.get(this, "model.id"); // If we call _super earlier, we lose access to _sortableManager

    this._super(...arguments);

    Ember.run.scheduleOnce("afterRender", () => {
      Ember.run.later(() => {
        $(`.-moving-section-style`).remove();
        $("body").removeClass("is-dragging");
        let $lessonSectionEl = $(`#lesson-section-${id}`);

        if ($lessonSectionEl) {
          let lessonSectionOffset = $lessonSectionEl.offset();

          if (lessonSectionOffset) {
            $(window).scrollTop(lessonSectionOffset.top - offset + 20); // 25 is so the cursor is at the card title
          } else {// Happens if we're dragging to a new lesson that triggers LESSON_FIRST_EDIT
            }
        }

        requestAnimationFrame(() => {
          $(".week-grid").css({
            visibility: "visible",
            opacity: 1
          });
        });
      }, 0);
    });
  }
});