define("cc-frontend/lib/actions/analytics-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = analyticsOnly;

  function analyticsOnly(payload) {
    return {
      name: "ANALYTICS",
      payload: payload,
      patches: null,
      undoPatches: null,

      narrative(findFn, _userId) {
        return {
          titleForAnalytics: payload.titleForAnalytics,
          context: payload.context || {}
        };
      }

    };
  }
});