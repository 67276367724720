define("cc-frontend/helpers/find-many-individually", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = findMany;
  const ArrayPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  const ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  /**
   * A macro that returns a computed property that returns an array of proxy objects
   *
   * @param  {String} keyName
   * @param  {String} relationshipType
   * @return {Array<ObjectPromiseProxy>} - an array of proxy objects whose values can be accessed via
   * the content property on each item
   */

  function findMany(keyName, relationshipType) {
    /**
     * Example
       ``` javascript
        class exampleComponent extends Component {
          courseIds = [ 1, 2, 3, 4 ]
          @findMany("courseIds", "course") selectedCourses
        }
      ```
      */
    return Ember.computed(`${keyName}.[]`, function () {
      let ids = Ember.get(this, keyName);
      if (Ember.get(this, "store") === undefined) throw Error("You need to inject the store to use the findMany function");
      return _lodash.default.map(ids, id => {
        return ObjectPromiseProxy.create({
          promise: Ember.get(this, "store").find(relationshipType, id)
        });
      });
    });
  }
});