define("cc-frontend/templates/icons/-ios7-printer-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQeWaRR+",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"512px\"],[14,\"height\",\"512px\"],[14,\"viewBox\",\"0 0 512 512\"],[14,5,\"enable-background:new 0 0 512 512;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M432.5,112H400V64H112v48H80.5C62.9,112,48,125.8,48,143.3v158.4c0,17.5,14.9,32.3,32.5,32.3H128v114h256V334h48.5\\n\\t\\tc17.6,0,31.5-14.8,31.5-32.3V143.3C464,125.8,450.1,112,432.5,112z M128,80h256v32H128V80z M368,432H144V240h224V432z M448,301.7\\n\\t\\tc0,8.7-6.7,16.3-15.5,16.3H384v-94H128v94H80.5c-8.8,0-16.5-7.6-16.5-16.3V143.3c0-8.7,7.7-15.3,16.5-15.3H432h0.5\\n\\t\\tc8.8,0,15.5,6.6,15.5,15.3V301.7z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ios7-printer-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});