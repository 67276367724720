define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_REPLACE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_STANDARDS_REPLACE = void 0;
  const LESSON_STANDARDS_REPLACE = {
    name: "LESSON_STANDARDS_REPLACE",
    params: {
      cardStackId: "string",
      cardId: "string",
      standards: {
        type: "array",
        items: {
          type: "object"
        }
      },
      previousStandards: "array"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).set("embedded", "attributes.standards", payload.standards).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).set("embedded", "attributes.standards", JSON.parse(JSON.stringify(payload.previousStandards))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let diff = Ember.get(payload, "standards.length") - payload.previousStandards.length;
      let verb = diff > 0 ? "Added" : "Removed";
      let noun = diff === 1 ? "standard" : "standards";
      let absoluteDiff = Math.abs(diff);
      return {
        title: "Changed Lesson Standards",
        titleForAnalytics: "Lesson - Update Standards",
        description: `${verb} ${absoluteDiff} ${noun}`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models)
      };
    }

  };
  _exports.LESSON_STANDARDS_REPLACE = LESSON_STANDARDS_REPLACE;
  var _default = LESSON_STANDARDS_REPLACE;
  _exports.default = _default;
});