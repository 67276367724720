define("cc-frontend/components/planner/menus/lesson-menu/share-link", ["exports", "@ember-decorators/component", "lodash-es"], function (_exports, _component, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.menuName "CANNOT_SHARE")}}
    <ToElsewhere
      @named="lesson-menu-title"
      @send={{component "planner/menus/titles/blank"}}
      @outsideParams={{hash isBlank=true}}
    />
  
    <ToElsewhere
      @named="lesson-menu-body"
      @send={{component "planner/menus/share-link/cannot-share-body" confirmButton=@goBack}}
    />
  
  {{/if}}
  {{#if (eq this.menuName "CONFIRM_LINK_SHARING")}}
    <ToElsewhere
      @named="lesson-menu-title"
      @send={{component
        "planner/menus/share-link/confirm-link-sharing-title"
        goBack=@goBack
        title="Turn on Link Sharing"
      }}
    />
  
    <ToElsewhere
      @named="lesson-menu-body"
      @send={{component
        "planner/menus/share-link/confirm-link-sharing-body"
        planbookId=@planbook.id
        goToLink=this.goToLink
        goBack=@goBack
      }}
    />
  
  {{/if}}
  {{#if (eq this.menuName "SHOW_LINK")}}
    <ToElsewhere
      @named="lesson-menu-title"
      @send={{component
        "planner/menus/share-link/show-link-title"
        goBack=this.goBackFromShowLink
        title="Here's the link"
      }}
    />
  
    <ToElsewhere
      @named="lesson-menu-body"
      @send={{component
        "planner/menus/share-link/show-link-body"
        linkUrl=this.linkUrl
        closeMenu=@closeMenu
      }}
    />
  
  {{/if}}
  */
  {
    "id": "gTdh8L0x",
    "block": "[[[41,[28,[37,1],[[30,0,[\"menuName\"]],\"CANNOT_SHARE\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@named\",\"@send\",\"@outsideParams\"],[\"lesson-menu-title\",[50,\"planner/menus/titles/blank\",0,null,null],[28,[37,4],null,[[\"isBlank\"],[true]]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"lesson-menu-body\",[50,\"planner/menus/share-link/cannot-share-body\",0,null,[[\"confirmButton\"],[[30,1]]]]]],null],[1,\"\\n\\n\"]],[]],null],[41,[28,[37,1],[[30,0,[\"menuName\"]],\"CONFIRM_LINK_SHARING\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"lesson-menu-title\",[50,\"planner/menus/share-link/confirm-link-sharing-title\",0,null,[[\"goBack\",\"title\"],[[30,1],\"Turn on Link Sharing\"]]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"lesson-menu-body\",[50,\"planner/menus/share-link/confirm-link-sharing-body\",0,null,[[\"planbookId\",\"goToLink\",\"goBack\"],[[30,2,[\"id\"]],[30,0,[\"goToLink\"]],[30,1]]]]]],null],[1,\"\\n\\n\"]],[]],null],[41,[28,[37,1],[[30,0,[\"menuName\"]],\"SHOW_LINK\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"lesson-menu-title\",[50,\"planner/menus/share-link/show-link-title\",0,null,[[\"goBack\",\"title\"],[[30,0,[\"goBackFromShowLink\"]],\"Here's the link\"]]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"lesson-menu-body\",[50,\"planner/menus/share-link/show-link-body\",0,null,[[\"linkUrl\",\"closeMenu\"],[[30,0,[\"linkUrl\"]],[30,3]]]]]],null],[1,\"\\n\\n\"]],[]],null]],[\"@goBack\",\"@planbook\",\"@closeMenu\"],false,[\"if\",\"eq\",\"to-elsewhere\",\"component\",\"hash\"]]",
    "moduleName": "cc-frontend/components/planner/menus/lesson-menu/share-link.hbs",
    "isStrictMode": false
  });

  let LessonMenuShareLink = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = (_class2 = class LessonMenuShareLink extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "cardStackId", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "goBack", void 0);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "linkUrl", "");

      _defineProperty(this, "hasReceivedAttrs", false);

      _defineProperty(this, "needsToConfirmLinkSharing", false);

      _defineProperty(this, "menuName", "SHOW_LINK");
    }

    // callbacks
    didReceiveAttrs() {
      if (this.hasReceivedAttrs || (0, _lodashEs.isNil)(this.planbook)) return;
      this.hasReceivedAttrs = true;

      if ((0, _lodashEs.isNil)(this.cardStackId)) {
        this.menuName = "CANNOT_SHARE";
        return;
      } // this.linkUrl =
      // let path = getOwner(this)
      //   .lookup("router:main")
      //   .generate(`lesson`, {
      //     lessonId: this.cardStackId,
      //   })


      let path = this.router.urlFor("lesson", this.cardStackId);
      this.linkUrl = `${window.location.protocol}//${window.location.host}${path}`;
      console.log("this.planbook", this.planbook.attributes.publicAccess);

      if (!(0, _lodashEs.includes)(this.planbook.attributes.publicAccess.roles, "planbook:is-viewer")) {
        this.needsToConfirmLinkSharing = true;
        this.menuName = "CONFIRM_LINK_SHARING";
      }
    }

    goBackFromShowLink() {
      if (this.needsToConfirmLinkSharing) {
        this.set("menuName", "CONFIRM_LINK_SHARING");
      } else {
        this.goBack();
      }
    }

    goToLink() {
      this.set("menuName", "SHOW_LINK");
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "goBackFromShowLink", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "goBackFromShowLink"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToLink", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "goToLink"), _class2.prototype)), _class2)) || _class);
  _exports.default = LessonMenuShareLink;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LessonMenuShareLink);
});