define("cc-frontend/components/kb/prismic-article/explainer-buttons.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "nav-buttons": "_nav-buttons_1x3mez",
    "back-button": "_back-button_1x3mez",
    "next-button": "_next-button_1x3mez",
    "button-text": "_button-text_1x3mez",
    "button-icon": "_button-icon_1x3mez",
    "center": "_center_1x3mez",
    "step-number": "_step-number_1x3mez",
    "step-title": "_step-title_1x3mez",
    "--text-only": "_--text-only_1x3mez"
  };
  _exports.default = _default;
});