define("cc-frontend/components/planner/template-chooser/group-templates", ["exports", "@ember-decorators/component", "cc-frontend/helpers/belongs-to", "cc-frontend/helpers/has-many-collectively"], function (_exports, _component, _belongsTo, _hasManyCollectively) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (and this.group.relationships.cardStackTemplatesPlanbook.data this.planbookTemplates)}}
    {{yield this.planbookTemplates}}
  {{/if}}
  */
  {
    "id": "O6Nz1IBb",
    "block": "[[[41,[28,[37,1],[[30,0,[\"group\",\"relationships\",\"cardStackTemplatesPlanbook\",\"data\"]],[30,0,[\"planbookTemplates\"]]],null],[[[1,\"  \"],[18,1,[[30,0,[\"planbookTemplates\"]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"and\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/template-chooser/group-templates.hbs",
    "isStrictMode": false
  });

  let GroupTemplatesProvider = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = (0, _belongsTo.default)("group", "cardStackTemplatesPlanbook"), _dec4 = (0, _hasManyCollectively.default)("groupTemplatePlanbook", "card-stack", "cardStackTemplates"), _dec(_class = (_class2 = class GroupTemplatesProvider extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "groupTemplatePlanbook", _descriptor2, this);

      _initializerDefineProperty(this, "planbookTemplates", _descriptor3, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "groupTemplatePlanbook", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "planbookTemplates", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = GroupTemplatesProvider;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupTemplatesProvider);
});