define("cc-frontend/components/icons/grid-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M17.53,23.997c-3.584,0 -6.5,-2.916 -6.5,-6.5c0,-3.584 2.916,-6.5 6.5,-6.5c3.584,0 6.5,2.916 6.5,6.5c0,3.584 -2.916,6.5 -6.5,6.5Zm0,-12c-3.033,0 -5.5,2.467 -5.5,5.5c0,3.033 2.467,5.5 5.5,5.5c3.033,0 5.5,-2.467 5.5,-5.5c0,-3.033 -2.467,-5.5 -5.5,-5.5Z"
      ></path>
      <path
        d="M16.699,20.426c-0.272,0 -0.531,-0.086 -0.749,-0.25c-0.049,-0.037 -0.093,-0.075 -0.134,-0.116l-1.499,-1.499c-0.195,-0.195 -0.195,-0.512 0,-0.707c0.094,-0.094 0.22,-0.146 0.354,-0.146c0.134,0 0.259,0.052 0.354,0.146l1.5,1.5c0.01,0.01 0.018,0.017 0.027,0.023c0.044,0.033 0.095,0.05 0.149,0.05c0.08,0 0.153,-0.037 0.201,-0.1l2.899,-3.874c0.094,-0.125 0.244,-0.2 0.401,-0.2c0.109,0 0.212,0.034 0.299,0.1c0.22,0.166 0.266,0.479 0.101,0.7l-2.9,3.874c-0.237,0.313 -0.611,0.499 -1.003,0.499Z"
      ></path>
      <path
        d="M2.53,19c-1.379,0 -2.5,-1.122 -2.5,-2.5v-13.994c0,-1.379 1.121,-2.5 2.5,-2.5h14c1.378,0 2.5,1.121 2.5,2.5v7c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.5h-5v3.5c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3.5h-5v4.991h3.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-3.5v5.003h2.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-7Zm-1.5,-2.5c0,0.827 0.673,1.5 1.5,1.5h3.5v-5.003h-5v3.503Zm5,-4.503v-4.991h-5v4.991h5Zm12,-5.991v-3.5c0,-0.827 -0.673,-1.5 -1.5,-1.5h-3.5v5h5Zm-6,0v-5h-5v5h5Zm-6,0v-5h-3.5c-0.827,0 -1.5,0.673 -1.5,1.5v3.5h5Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "TO5HQo9Q",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M17.53,23.997c-3.584,0 -6.5,-2.916 -6.5,-6.5c0,-3.584 2.916,-6.5 6.5,-6.5c3.584,0 6.5,2.916 6.5,6.5c0,3.584 -2.916,6.5 -6.5,6.5Zm0,-12c-3.033,0 -5.5,2.467 -5.5,5.5c0,3.033 2.467,5.5 5.5,5.5c3.033,0 5.5,-2.467 5.5,-5.5c0,-3.033 -2.467,-5.5 -5.5,-5.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M16.699,20.426c-0.272,0 -0.531,-0.086 -0.749,-0.25c-0.049,-0.037 -0.093,-0.075 -0.134,-0.116l-1.499,-1.499c-0.195,-0.195 -0.195,-0.512 0,-0.707c0.094,-0.094 0.22,-0.146 0.354,-0.146c0.134,0 0.259,0.052 0.354,0.146l1.5,1.5c0.01,0.01 0.018,0.017 0.027,0.023c0.044,0.033 0.095,0.05 0.149,0.05c0.08,0 0.153,-0.037 0.201,-0.1l2.899,-3.874c0.094,-0.125 0.244,-0.2 0.401,-0.2c0.109,0 0.212,0.034 0.299,0.1c0.22,0.166 0.266,0.479 0.101,0.7l-2.9,3.874c-0.237,0.313 -0.611,0.499 -1.003,0.499Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M2.53,19c-1.379,0 -2.5,-1.122 -2.5,-2.5v-13.994c0,-1.379 1.121,-2.5 2.5,-2.5h14c1.378,0 2.5,1.121 2.5,2.5v7c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-2.5h-5v3.5c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3.5h-5v4.991h3.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-3.5v5.003h2.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-7Zm-1.5,-2.5c0,0.827 0.673,1.5 1.5,1.5h3.5v-5.003h-5v3.503Zm5,-4.503v-4.991h-5v4.991h5Zm12,-5.991v-3.5c0,-0.827 -0.673,-1.5 -1.5,-1.5h-3.5v5h5Zm-6,0v-5h-5v5h5Zm-6,0v-5h-3.5c-0.827,0 -1.5,0.673 -1.5,1.5v3.5h5Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/grid-check.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});