define("cc-frontend/components/planner/menus/lesson-without-template", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DS::DropdownMenu>
    <div class="g-dropdown-menu__top-spacer-if-no-title"></div>
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/lightning-bolt"
      @clickAction={{this.createTemplate}}
    >
      Start from a Template
    </DS::DropdownMenu::SingleButtonRow>
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/calendar-edit"
      @clickAction={{this.createBlankLesson}}
    >
      Create a Blank Lesson
    </DS::DropdownMenu::SingleButtonRow>
    <div class="g-dropdown-menu__single-button-row-divider"></div>
    <DS::DropdownMenu::SingleButtonRow @isSecondary="true" @clickAction={{this.editCourse}}>
      Edit Class Title, Color, & Order
    </DS::DropdownMenu::SingleButtonRow>
    <DS::DropdownMenu::SingleButtonRow @isSecondary="true">
      Adjust templates for all classes
    </DS::DropdownMenu::SingleButtonRow>
  </DS::DropdownMenu>
  */
  {
    "id": "pN4irNt8",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"g-dropdown-menu__top-spacer-if-no-title\"],[12],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@iconName\",\"@clickAction\"],[\"icons/lightning-bolt\",[30,0,[\"createTemplate\"]]]],[[\"default\"],[[[[1,\"\\n    Start from a Template\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@iconName\",\"@clickAction\"],[\"icons/calendar-edit\",[30,0,[\"createBlankLesson\"]]]],[[\"default\"],[[[[1,\"\\n    Create a Blank Lesson\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"g-dropdown-menu__single-button-row-divider\"],[12],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@isSecondary\",\"@clickAction\"],[\"true\",[30,0,[\"editCourse\"]]]],[[\"default\"],[[[[1,\"\\n    Edit Class Title, Color, & Order\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@isSecondary\"],[\"true\"]],[[\"default\"],[[[[1,\"\\n    Adjust templates for all classes\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"d-s/dropdown-menu\",\"d-s/dropdown-menu/single-button-row\"]]",
    "moduleName": "cc-frontend/components/planner/menus/lesson-without-template.hbs",
    "isStrictMode": false
  });

  let FilterClasses = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec(_class = (_class2 = class FilterClasses extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "toggleClass", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _defineProperty(this, "editCourseTitleAndOrder", void 0);

      _defineProperty(this, "showTemplateChooser", void 0);

      _defineProperty(this, "courseId", void 0);

      _defineProperty(this, "modelsNeededForFirstEdit", void 0);

      _initializerDefineProperty(this, "lessonEditor", _descriptor, this);
    }

    editCourse() {
      this.editCourseTitleAndOrder(this.courseId);
      this.closeMenu();
    }

    createTemplate() {
      this.showTemplateChooser();
      this.closeMenu();
    }

    createBlankLesson() {
      this.lessonEditor.createBlankLesson(this.modelsNeededForFirstEdit);
      this.closeMenu();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "lessonEditor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "editCourse", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "editCourse"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createTemplate", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "createTemplate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createBlankLesson", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "createBlankLesson"), _class2.prototype)), _class2)) || _class);
  _exports.default = FilterClasses;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FilterClasses);
});