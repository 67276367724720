define("cc-frontend/lib/actions/lesson/LESSON_CARD_UPDATE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareLessonCardUpdate = prepareLessonCardUpdate;
  _exports.default = _exports.LESSON_CARD_UPDATE = void 0;

  function prepareLessonCardUpdate(payload) {
    return {
      name: LESSON_CARD_UPDATE.name,
      payload: payload,
      patches: LESSON_CARD_UPDATE.patches(payload),
      undoPatches: LESSON_CARD_UPDATE.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(LESSON_CARD_UPDATE.narrative, payload)
    };
  }

  const LESSON_CARD_UPDATE = {
    name: "LESSON_CARD_UPDATE",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).set("embedded", `attributes.${payload.prop}`, payload.value).inc("embedded", `attributes._revision`, 1).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).set("embedded", `attributes.${payload.prop}`, payload.originalValue).inc("embedded", `attributes._revision`, -1).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);

      let card = _lodash.default.find(_lodash.default.get(models, "cardStack.attributes.cards"), {
        id: payload.sectionId
      });

      let cardTitle = _lodash.default.get(card, ["attributes", "title"]);

      let context = (0, _defaultLessonContext.default)(payload, models);

      if (payload.prop === "title") {
        return {
          title: "Renamed Lesson Card",
          titleForAnalytics: "Lesson Card - Edit Title",
          description: `Changed card title from "${payload.originalValue}" to "${payload.value}"`,
          context: context,
          activity: (0, _defaultLessonActivity.default)(payload, models)
        };
      }

      if (payload.prop === "value") {
        let deltaInWords;

        if (payload.wordCountChange > 0) {
          deltaInWords = payload.wordCountChange === 1 ? `and added 1 word` : `and added ${payload.wordCountChange} words`;
        } else if (payload.wordCountChange < 0) {
          deltaInWords = payload.wordCountChange === 1 ? `and deleted 1 word` : `and deleted ${payload.wordCountChange} words`;
        } else {
          deltaInWords = "";
        }

        let newContext = _lodash.default.assign({}, context, {
          wordCountChange: payload.wordCountChange
        });

        return {
          title: "Edited Lesson Card",
          titleForAnalytics: "Lesson Card - Edit Value",
          description: `Edited ${cardTitle} ${deltaInWords}.`,
          context: newContext,
          activity: (0, _defaultLessonActivity.default)(payload, models)
        };
      }

      if (payload.prop === "color") {
        return {
          title: "Changed Lesson Card Color",
          titleForAnalytics: "Lesson Card - Changed Color",
          description: `Changed the color of ${cardTitle}`,
          context: context,
          activity: (0, _defaultLessonActivity.default)(payload, models)
        };
      }

      return null;
    }

  };
  _exports.LESSON_CARD_UPDATE = LESSON_CARD_UPDATE;
  var _default = LESSON_CARD_UPDATE;
  _exports.default = _default;
});