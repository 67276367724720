define("cc-frontend/components/kb/prismic-article", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="content">
    {{#each @prismicDoc.attributes.data.body as |item|}}
      {{#if (eq item.slice_type "text_content")}}
        <div local-class="text-block">
          {{{prismic-as-html item.primary.text_content}}}
        </div>
      {{/if}}
      {{#if (eq item.slice_type "wistia_video")}}
        <div local-class="video">
          <Kb::PrismicArticle::WistiaVideo @slice={{item}} />
        </div>
      {{/if}}
      {{#if (eq item.slice_type "screenshot")}}
        <DS::Imgix
          @src={{item.primary.screenshot.url}}
          @imgixParams={{hash
            width=item.primary.screenshot.dimensions.width
            height=item.primary.screenshot.dimensions.height
            fit="crop"
          }}
          @height={{item.primary.screenshot.dimensions.height}}
          @width={{item.primary.screenshot.dimensions.width}}
          alt={{item.primary.screenshot.alt}}
        />
      {{/if}}
      {{#if (eq item.slice_type "link_to_explainer")}}
        <Kb::PrismicArticle::Explainer @slice={{item}} />
      {{/if}}
    {{/each}}
  </div>
  */
  {
    "id": "6L7mEPoW",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"content\"],[[\"from\"],[\"cc-frontend/components/kb/prismic-article.css\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"attributes\",\"data\",\"body\"]]],null]],null],null,[[[41,[28,[37,4],[[30,2,[\"slice_type\"]],\"text_content\"],null],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,0],[\"text-block\"],[[\"from\"],[\"cc-frontend/components/kb/prismic-article.css\"]]]]]],[12],[1,\"\\n        \"],[2,[28,[37,5],[[30,2,[\"primary\",\"text_content\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,4],[[30,2,[\"slice_type\"]],\"wistia_video\"],null],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,0],[\"video\"],[[\"from\"],[\"cc-frontend/components/kb/prismic-article.css\"]]]]]],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@slice\"],[[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,4],[[30,2,[\"slice_type\"]],\"screenshot\"],null],[[[1,\"      \"],[8,[39,7],[[16,\"alt\",[30,2,[\"primary\",\"screenshot\",\"alt\"]]]],[[\"@src\",\"@imgixParams\",\"@height\",\"@width\"],[[30,2,[\"primary\",\"screenshot\",\"url\"]],[28,[37,8],null,[[\"width\",\"height\",\"fit\"],[[30,2,[\"primary\",\"screenshot\",\"dimensions\",\"width\"]],[30,2,[\"primary\",\"screenshot\",\"dimensions\",\"height\"]],\"crop\"]]],[30,2,[\"primary\",\"screenshot\",\"dimensions\",\"height\"]],[30,2,[\"primary\",\"screenshot\",\"dimensions\",\"width\"]]]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,4],[[30,2,[\"slice_type\"]],\"link_to_explainer\"],null],[[[1,\"      \"],[8,[39,9],null,[[\"@slice\"],[[30,2]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[13]],[\"@prismicDoc\",\"item\"],false,[\"local-class\",\"each\",\"-track-array\",\"if\",\"eq\",\"prismic-as-html\",\"kb/prismic-article/wistia-video\",\"d-s/imgix\",\"hash\",\"kb/prismic-article/explainer\"]]",
    "moduleName": "cc-frontend/components/kb/prismic-article.hbs",
    "isStrictMode": false
  });

  class KbPrismicComponent extends _component.default {}

  _exports.default = KbPrismicComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KbPrismicComponent);
});