define("cc-frontend/templates/admin/school-years", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HZ0gnl5P",
    "block": "[[[8,[39,0],null,null,null],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"admin/school-year/index\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/admin/school-years.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});