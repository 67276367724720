define("cc-frontend/components/planner/card-stacks/cards.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "add-card-wrapper": "_add-card-wrapper_179sn7",
    "add-card": "_add-card_179sn7",
    "--fullScreen": "_--fullScreen_179sn7"
  };
  _exports.default = _default;
});