define("cc-frontend/lib/actions/course-calendar", ["exports", "cc-frontend/lib/actions/course/calendar/ADD_UNIT", "cc-frontend/lib/actions/course/calendar/COURSE_DATE_BUMP_LESSON", "cc-frontend/lib/actions/course/calendar/COURSE_DATE_SAVE_AS_TEMPLATE", "cc-frontend/lib/actions/course/calendar/COURSE_DATE_TEMPLATE_TOGGLE", "cc-frontend/lib/actions/course/calendar/COURSE_ROTATION_SET_TEMPLATE_ID", "cc-frontend/lib/actions/course/calendar/DELETE_UNIT", "cc-frontend/lib/actions/course/calendar/MOVE_LESSON_BY_AMOUNT", "cc-frontend/lib/actions/course/calendar/MOVE_LESSON_TO_DATE", "cc-frontend/lib/actions/course/calendar/MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE", "cc-frontend/lib/actions/course/calendar/MOVE_MULTIPLE_LESSONS", "cc-frontend/lib/actions/course/calendar/MOVE_UNIT", "cc-frontend/lib/actions/course/calendar/PULL_MULTIPLE_LESSONS_BACKWARDS", "cc-frontend/lib/actions/course/calendar/RESIZE_UNIT"], function (_exports, _ADD_UNIT, _COURSE_DATE_BUMP_LESSON, _COURSE_DATE_SAVE_AS_TEMPLATE, _COURSE_DATE_TEMPLATE_TOGGLE, _COURSE_ROTATION_SET_TEMPLATE_ID, _DELETE_UNIT, _MOVE_LESSON_BY_AMOUNT, _MOVE_LESSON_TO_DATE, _MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE, _MOVE_MULTIPLE_LESSONS, _MOVE_UNIT, _PULL_MULTIPLE_LESSONS_BACKWARDS, _RESIZE_UNIT) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let patches = {
    MOVE_UNIT: _MOVE_UNIT.default,
    MOVE_LESSON_BY_AMOUNT: _MOVE_LESSON_BY_AMOUNT.default,
    MOVE_LESSON_TO_DATE: _MOVE_LESSON_TO_DATE.default,
    MOVE_MULTIPLE_LESSONS: _MOVE_MULTIPLE_LESSONS.default,
    MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE: _MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE.default,
    PULL_MULTIPLE_LESSONS_BACKWARDS: _PULL_MULTIPLE_LESSONS_BACKWARDS.default,
    RESIZE_UNIT: _RESIZE_UNIT.default,
    DELETE_UNIT: _DELETE_UNIT.default,
    ADD_UNIT: _ADD_UNIT.default,
    COURSE_DATE_SAVE_AS_TEMPLATE: _COURSE_DATE_SAVE_AS_TEMPLATE.default,
    COURSE_ROTATION_SET_TEMPLATE_ID: _COURSE_ROTATION_SET_TEMPLATE_ID.default,
    COURSE_DATE_TEMPLATE_TOGGLE: _COURSE_DATE_TEMPLATE_TOGGLE.default,
    COURSE_DATE_BUMP_LESSON: _COURSE_DATE_BUMP_LESSON.default
  };
  var _default = patches;
  _exports.default = _default;
});