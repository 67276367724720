define("cc-frontend/components/planner/card-stacks/cards/card-google-classroom.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gc-banner": "_gc-banner_1f77n0",
    "gc-banner__text": "_gc-banner__text_1f77n0",
    "gc-info": "_gc-info_1f77n0",
    "gc-info__title": "_gc-info__title_1f77n0",
    "gc-unpublished-changes": "_gc-unpublished-changes_1f77n0",
    "gc-unpublished-changes__heading": "_gc-unpublished-changes__heading_1f77n0",
    "gc-unpublished-changes__description": "_gc-unpublished-changes__description_1f77n0"
  };
  _exports.default = _default;
});