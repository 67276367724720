define("cc-frontend/components/icons/arrow-minimal-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M18.401,24c-0.122,0 -0.24,-0.044 -0.331,-0.125l-12.575,-11.127c-0.03,-0.027 -0.058,-0.055 -0.084,-0.084c-0.366,-0.413 -0.329,-1.047 0.083,-1.412l12.575,-11.127c0.092,-0.081 0.21,-0.125 0.332,-0.125c0.143,0 0.28,0.062 0.375,0.169c0.182,0.206 0.163,0.523 -0.043,0.705l-12.576,11.126l12.575,11.125c0.206,0.183 0.226,0.5 0.043,0.706c-0.095,0.108 -0.231,0.169 -0.374,0.169Z"
    ></path>
  </svg>
  */
  {
    "id": "wQ0QrmGd",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M18.401,24c-0.122,0 -0.24,-0.044 -0.331,-0.125l-12.575,-11.127c-0.03,-0.027 -0.058,-0.055 -0.084,-0.084c-0.366,-0.413 -0.329,-1.047 0.083,-1.412l12.575,-11.127c0.092,-0.081 0.21,-0.125 0.332,-0.125c0.143,0 0.28,0.062 0.375,0.169c0.182,0.206 0.163,0.523 -0.043,0.705l-12.576,11.126l12.575,11.125c0.206,0.183 0.226,0.5 0.043,0.706c-0.095,0.108 -0.231,0.169 -0.374,0.169Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/arrow-minimal-left.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});