define("cc-frontend/components/planner/menus/date-annotation/ask-if-note-body", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-flex tw-flex-col" data-test-ask-if-leave-note>
    <Planner::Menus::Parts::OutlineButton @onClick={{@leaveNote}} data-test-leave-note>
      Yes, I'll leave a note
    </Planner::Menus::Parts::OutlineButton>
    <Planner::Menus::Parts::OutlineButton @onClick={{@noNote}} data-test-no-note>
      No, just hide the class
    </Planner::Menus::Parts::OutlineButton>
  </div>
  */
  {
    "id": "usloCh5K",
    "block": "[[[10,0],[14,0,\"tw-flex tw-flex-col\"],[14,\"data-test-ask-if-leave-note\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-leave-note\",\"\"]],[[\"@onClick\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    Yes, I'll leave a note\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-no-note\",\"\"]],[[\"@onClick\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n    No, just hide the class\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@leaveNote\",\"@noNote\"],false,[\"planner/menus/parts/outline-button\"]]",
    "moduleName": "cc-frontend/components/planner/menus/date-annotation/ask-if-note-body.hbs",
    "isStrictMode": false
  });

  let DateAnnotationAskIfNoteBody = (_dec = (0, _component.tagName)(""), _dec(_class = class DateAnnotationAskIfNoteBody extends Ember.Component {}) || _class);
  _exports.default = DateAnnotationAskIfNoteBody;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DateAnnotationAskIfNoteBody);
});