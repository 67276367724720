define("cc-frontend/lib/patch-updater", ["exports", "lodash-es", "cc-frontend/lib/actions/create-patch"], function (_exports, _lodashEs, _createPatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let getArgs = function (isEmbeddedOrKey, keyOrValue, value) {
    let _key = value === undefined ? isEmbeddedOrKey : keyOrValue;

    let _value = value === undefined ? keyOrValue : value;

    let _isEmbedded = value === undefined ? false : true;

    return [_isEmbedded, _key, _value];
  };

  /**
     Usage:
     =====
  
     Updater.create("planbook", payload.planbookId, "specialDays", {startDate: payload.dateString, endDate: payload.dateString})
       .set("embedded", "startDate", payload.dateString)
       .set("embedded", "endDate", payload.dateString)
       .set("embedded", "isOn", false)
       .set(`attributes.dates.${payload.dateString}.isOn`, payload.value)
       .inc(`attributes.version`, 1)
       .patch
   */
  class Updater {
    static create( // @ts-ignore
    type, // @ts-ignore
    id, // @ts-ignore
    embeddedPath, // @ts-ignore
    embeddedElementMatch) {
      let patch = (0, _createPatch.default)(...arguments);
      return new this(patch);
    } // _patch = Patch


    constructor(patch) {
      _defineProperty(this, "_patch", void 0);

      this._patch = patch;
      return this;
    }

    get patch() {
      return {
        id: null,
        document: {
          id: this._patch.document.id,
          modelType: this._patch.document.modelType,
          ops: (0, _lodashEs.reduce)(this._patch.document.ops, (acc, val, key) => {
            // @ts-ignore:
            val.length > 0 ? acc[key] = val : acc;
            return acc;
          }, {})
        },
        embeddedDocument: this._patch.embeddedDocument === null ? null : {
          path: this._patch.embeddedDocument.path,
          elemMatch: (0, _lodashEs.cloneDeep)(this._patch.embeddedDocument.elemMatch),
          ops: (0, _lodashEs.reduce)(this._patch.embeddedDocument.ops, (acc, val, key) => {
            // @ts-ignore:
            val.length > 0 ? acc[key] = val : acc;
            return acc;
          }, {})
        },
        actionId: null,
        timeAtCreation: null,
        revisionAtCreation: null,
        isCreatePatch: this._patch.isCreatePatch
      };
    }

    get wrapInArray() {
      return [this.patch];
    }

    isCreatePatch() {
      this._patch.isCreatePatch = true;
      return this;
    }

    set() {
      let [isEmbedded, key, value] = getArgs(...arguments);

      this._addOp(isEmbedded, "set", key, value);

      return this;
    }

    unset() {
      let [isEmbedded, key, value] = getArgs(...arguments);

      this._addOp(isEmbedded, "unset", key, value);

      return this;
    }

    inc() {
      let [isEmbedded, key, value] = getArgs(...arguments);

      this._addOp(isEmbedded, "inc", key, value);

      return this;
    }

    push() {
      let [isEmbedded, key, value] = getArgs(...arguments);

      this._addOp(isEmbedded, "push", key, value);

      return this;
    }

    pull() {
      let [isEmbedded, key, value] = getArgs(...arguments);

      this._addOp(isEmbedded, "pull", key, value);

      return this;
    }

    pullAll() {
      let [isEmbedded, key, value] = getArgs(...arguments);

      this._addOp(isEmbedded, "pullAll", key, value);

      return this;
    }

    addToSet() {
      let [isEmbedded, key, value] = getArgs(...arguments);

      this._addOp(isEmbedded, "addToSet", key, value);

      return this;
    }

    _addOp(isEmbedded, op, key, value) {
      if (isEmbedded === true) {
        // @ts-ignore:
        this._patch.embeddedDocument.ops[op].push([key, value]);
      } else {
        this._patch.document.ops[op].push([key, value]);
      }

      return this;
    }

  }

  _exports.default = Updater;
});