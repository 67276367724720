define("cc-frontend/components/planner/menus/filter-classes.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header-bar__text": "_header-bar__text_1tt00w",
    "toggle-line-up-classes-divider": "_toggle-line-up-classes-divider_1tt00w",
    "toggle-line-up-classes-row": "_toggle-line-up-classes-row_1tt00w",
    "toggle-line-up-classes-row__text": "_toggle-line-up-classes-row__text_1tt00w",
    "toggle-classes-instructions": "_toggle-classes-instructions_1tt00w",
    "toggle-classes-instructions__text": "_toggle-classes-instructions__text_1tt00w",
    "toggle-classes-instructions__header": "_toggle-classes-instructions__header_1tt00w",
    "toggle-classes-instructions__subheader": "_toggle-classes-instructions__subheader_1tt00w",
    "class-list": "_class-list_1tt00w",
    "class-list__class": "_class-list__class_1tt00w",
    "class-list__class__move-button": "_class-list__class__move-button_1tt00w",
    "class-list__class__title": "_class-list__class__title_1tt00w",
    "is-selected": "_is-selected_1tt00w",
    "is-not-selected": "_is-not-selected_1tt00w",
    "class-list__class__checkbox": "_class-list__class__checkbox_1tt00w",
    "class-list__class__left-side": "_class-list__class__left-side_1tt00w",
    "controls": "_controls_1tt00w",
    "controls__inner": "_controls__inner_1tt00w",
    "controls__control": "_controls__control_1tt00w"
  };
  _exports.default = _default;
});