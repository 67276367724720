define("cc-frontend/components/planner/menus/add-menu-but-nope", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="day-card-add-btn-wrapper"
    id={{concat "add-menu-" @dateString}}
    {{action "toggleAddMenu"}}
  >
    <Planner::CardStacks::AddToRoutineButton />
    {{#if this.canShowAddMenu}}
      <LiquidWormhole @class="dialog-backdrop" @click={{action "toggleAddMenu"}} />
      <LiquidTether
        @target={{concat "#add-menu-" @dateString}}
        @offset="-8px 0"
        @targetAttachment="bottom center"
        @attachment="top center"
        @constraints={{this.addToRoutineMenuConstraints}}
      >
        <DS::DropdownMenu @iconColumns="3" @arrowPlacement="TOP_CENTER">
          <div class="g-dropdown-menu__top-spacer-if-no-title"></div>
          <div local-class="notice">
            To add classes or notes, untoggle the "Classes Lined Up" option at the top of the screen.
          </div>
          <div local-class="button-row">
            <Planner::Menus::Parts::OutlineButton
              @onClick={{this.toggleAddMenu}}
              @noHorizontalMargin={{true}}
            >
              Okay
            </Planner::Menus::Parts::OutlineButton>
          </div>
        </DS::DropdownMenu>
      </LiquidTether>
    {{/if}}
  </div>
  */
  {
    "id": "Spr+ZH5k",
    "block": "[[[11,0],[24,0,\"day-card-add-btn-wrapper\"],[16,1,[28,[37,0],[\"add-menu-\",[30,1]],null]],[4,[38,1],[[30,0],\"toggleAddMenu\"],null],[12],[1,\"\\n  \"],[8,[39,2],null,null,null],[1,\"\\n\"],[41,[30,0,[\"canShowAddMenu\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@class\",\"@click\"],[\"dialog-backdrop\",[28,[37,1],[[30,0],\"toggleAddMenu\"],null]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@target\",\"@offset\",\"@targetAttachment\",\"@attachment\",\"@constraints\"],[[28,[37,0],[\"#add-menu-\",[30,1]],null],\"-8px 0\",\"bottom center\",\"top center\",[30,0,[\"addToRoutineMenuConstraints\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,6],null,[[\"@iconColumns\",\"@arrowPlacement\"],[\"3\",\"TOP_CENTER\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"g-dropdown-menu__top-spacer-if-no-title\"],[12],[13],[1,\"\\n        \"],[10,0],[15,0,[29,[[28,[37,7],[\"notice\"],[[\"from\"],[\"cc-frontend/components/planner/menus/add-menu-but-nope.css\"]]]]]],[12],[1,\"\\n          To add classes or notes, untoggle the \\\"Classes Lined Up\\\" option at the top of the screen.\\n        \"],[13],[1,\"\\n        \"],[10,0],[15,0,[29,[[28,[37,7],[\"button-row\"],[[\"from\"],[\"cc-frontend/components/planner/menus/add-menu-but-nope.css\"]]]]]],[12],[1,\"\\n          \"],[8,[39,8],null,[[\"@onClick\",\"@noHorizontalMargin\"],[[30,0,[\"toggleAddMenu\"]],true]],[[\"default\"],[[[[1,\"\\n            Okay\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[\"@dateString\"],false,[\"concat\",\"action\",\"planner/card-stacks/add-to-routine-button\",\"if\",\"liquid-wormhole\",\"liquid-tether\",\"d-s/dropdown-menu\",\"local-class\",\"planner/menus/parts/outline-button\"]]",
    "moduleName": "cc-frontend/components/planner/menus/add-menu-but-nope.hbs",
    "isStrictMode": false
  });

  let AddMenuButNope = (_dec = (0, _component.tagName)(""), _dec2 = Ember._action, _dec(_class = (_class2 = class AddMenuButNope extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "canShowAddMenu", false);

      _defineProperty(this, "addToRoutineMenuConstraints", [{
        to: "window",
        pin: ["left"]
      }]);
    }

    toggleAddMenu() {
      Ember.set(this, "canShowAddMenu", !Ember.get(this, "canShowAddMenu"));
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "toggleAddMenu", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleAddMenu"), _class2.prototype)), _class2)) || _class);
  _exports.default = AddMenuButNope;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AddMenuButNope);
});