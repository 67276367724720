define("cc-frontend/routes/planbookv5", ["exports", "cohere-js", "lodash-es"], function (_exports, _cohereJs, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Planbookv5Route = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, (_class = class Planbookv5Route extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionStore", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "finder", _descriptor4, this);

      _initializerDefineProperty(this, "fastboot", _descriptor5, this);

      _initializerDefineProperty(this, "headData", _descriptor6, this);

      _initializerDefineProperty(this, "session", _descriptor7, this);

      this.router.on("routeDidChange", transition => {
        var _transition$to$attrib, _transition$to$attrib2;

        if ((0, _lodashEs.includes)(transition.to.name, "planbookv5.planner") && ((_transition$to$attrib = transition.to.attributes) === null || _transition$to$attrib === void 0 ? void 0 : (_transition$to$attrib2 = _transition$to$attrib.planbook) === null || _transition$to$attrib2 === void 0 ? void 0 : _transition$to$attrib2.id) !== undefined) {
          // save last used view settings in local storage
          this.sessionStore.persistObject(`planbook-${transition.to.attributes.planbook.id}-lastView`, {
            url: `${this.router.currentURL}`,
            route: `${transition.to.name}`,
            queryParams: transition.to.queryParams
          });
        }
      });
    }

    model(params) {
      // We need to move this to the application model hook
      // also, check if there's any queries being fetched
      return new Ember.RSVP.Promise((resolve, reject) => {
        Ember.get(this, "store").find("planbook", params.planbook_id).then(planbook => {
          // Eventually, we'll do this:
          // if (
          //   planbook.attributes.publicAccess &&
          //   planbook.attributes.publicAccess.requiresLink === false &&
          //   planbook.attributes.publicAccess.roles.length > 0
          // ) {
          //   set(this, "headData.robots", "all")
          // } else {
          //   set(this, "headData.robots", "noindex")
          // }
          // Until then:
          // @ts-ignore
          Ember.set(this, "headData.robots", "none");

          if (planbook === undefined) {
            return reject({
              status: "404",
              planbookId: params.planbook_id
            });
          }

          return Ember.get(this, "store").find("rotation-calendar", planbook.relationships.rotationCalendar.data.id).then(rotationCalendar => {
            resolve({
              planbook,
              rotationCalendar
            });
          });
        });
      });
    }

    afterModel() {
      if (this.session.user.content) {
        var _this$session$user, _this$session$user$co, _this$session$user$co2;

        if (((_this$session$user = this.session.user) === null || _this$session$user === void 0 ? void 0 : (_this$session$user$co = _this$session$user.content) === null || _this$session$user$co === void 0 ? void 0 : (_this$session$user$co2 = _this$session$user$co.attributes) === null || _this$session$user$co2 === void 0 ? void 0 : _this$session$user$co2.featureFlags["COHERE"]) === true) {
          let user = this.session.user.content;

          _cohereJs.default.init("vsl_qZYfYM9GwgZc6asFq-Ip");

          _cohereJs.default.identify(this.session.user.content.id, {
            displayName: `${user.attributes.firstName} ${user.attributes.lastName}`,
            // Optional
            email: user.attributes.email // Optional

          });
        }
      }
    }

    error(error, _transition) {
      if (error.status === "404") {
        console.log(`Planbook was not found with ID "${error.planbookId}"`);

        if (Ember.get(this, "fastboot") && this.fastboot.isFastBoot) {
          // @ts-ignore
          this.set("fastboot.response.statusCode", 404);
        }

        return this.replaceWith("not-found", "planbook-not-found");
      } else {
        // Let the route above this handle the error.
        return true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionStore", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "finder", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = Planbookv5Route;
});