define("cc-frontend/components/planner/card-stacks/unit-provider", ["exports", "cc-frontend/helpers/find-many", "lodash-es"], function (_exports, _findMany, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.unit.isFulfilled this.unit.content this.unitInfo this.units}}
  */
  {
    "id": "TWsGnzXZ",
    "block": "[[[18,1,[[30,0,[\"unit\",\"isFulfilled\"]],[30,0,[\"unit\",\"content\"]],[30,0,[\"unitInfo\"]],[30,0,[\"units\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/unit-provider.hbs",
    "isStrictMode": false
  });

  let UnitProvider = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _findMany.default)("unitInfo.ancestorIds", "card-stack-summary"), _dec4 = Ember.computed("unitId"), _dec5 = Ember.computed("course.attributes.calendar.version", "unitId"), (_class = class UnitProvider extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "course", void 0);

      _defineProperty(this, "unitId", void 0);

      _defineProperty(this, "showStandardsModal", false);

      _defineProperty(this, "sectionState", {});

      _defineProperty(this, "menuIsOpen", false);

      _defineProperty(this, "isExpanded", true);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);

      _initializerDefineProperty(this, "units", _descriptor3, this);
    }

    // CPs
    get unit() {
      class ObjectPromiseProxy extends Ember.ObjectProxy.extend(Ember.PromiseProxyMixin) {}

      let promise = this.store.find("card-stack", this.unitId);
      return ObjectPromiseProxy.create({
        promise: promise
      });
    }

    get unitInfo() {
      return (0, _lodashEs.find)(this.course.attributes.calendar.units, {
        id: this.unitId
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appState", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "units", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "unit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "unit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unitInfo", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "unitInfo"), _class.prototype)), _class));
  _exports.default = UnitProvider;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UnitProvider);
});