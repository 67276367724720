define("cc-frontend/components/planner/planbook/copy-planbook/modal.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "import-planbook": "_import-planbook_v5ebr2",
    "header-box-wrapper": "_header-box-wrapper_v5ebr2",
    "header-close": "_header-close_v5ebr2",
    "header-box": "_header-box_v5ebr2",
    "header-box__back-arrow": "_header-box__back-arrow_v5ebr2",
    "header-box__words": "_header-box__words_v5ebr2",
    "step": "_step_v5ebr2",
    "header": "_header_v5ebr2",
    "course-pill-list": "_course-pill-list_v5ebr2",
    "course-pill": "_course-pill_v5ebr2",
    "course-pill__title": "_course-pill__title_v5ebr2",
    "course-pill__checkbox": "_course-pill__checkbox_v5ebr2",
    "submit": "_submit_v5ebr2",
    "--create-planbook": "_--create-planbook_v5ebr2",
    "title-input": "_title-input_v5ebr2",
    "confirmation-label": "_confirmation-label_v5ebr2",
    "confirmation-value": "_confirmation-value_v5ebr2"
  };
  _exports.default = _default;
});