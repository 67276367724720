define("cc-frontend/templates/help/article", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Vyj19w9",
    "block": "[[[1,[28,[35,0],[[30,1,[\"attributes\",\"title\"]]],null]],[1,\"\\n\"],[3,\"\\n  For spacing. Formatter is whack.\\n\"],[1,\"\\n\"],[8,[39,1],null,[[\"@entry\"],[[30,1]]],null]],[\"@model\"],false,[\"page-title\",\"kb/entry/main\"]]",
    "moduleName": "cc-frontend/templates/help/article.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});