define("cc-frontend/lib/actions/course/calendar/COURSE_DATE_TEMPLATE_TOGGLE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/date-annotation", "lodash"], function (_exports, _patchUpdater, _dateAnnotation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "COURSE_DATE_TEMPLATE_TOGGLE",
    params: {
      isOn: "string",
      course: "object",
      rotationId: "string",
      semesterId: "string"
    },

    patches(payload) {
      let hasDateAnnotation = _lodash.default.find(payload.course.attributes.calendar.dateAnnotations, annotation => {
        return annotation.type === "date-annotation-course-default" && annotation.attributes.rotationId === payload.rotationId && annotation.attributes.semesterId === payload.semesterId;
      });

      let annotation;

      if (hasDateAnnotation === undefined) {
        annotation = new _dateAnnotation.default({
          id: `${payload.semesterId}:${payload.rotationId}`,
          type: "date-annotation-course-default",
          attributes: {
            rotationId: payload.rotationId,
            semesterId: payload.semesterId,
            isOff: !payload.isOn
          }
        });
      } else {
        annotation = hasDateAnnotation;
      }

      let patch;

      if (hasDateAnnotation) {
        patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dateAnnotations", {
          id: `${payload.semesterId}:${payload.rotationId}`
        }).set("embedded", "attributes.isOff", !payload.isOn);
      } else {
        patch = _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dateAnnotations", annotation);
      }

      if (payload.isOn) {
        patch.pull("attributes.calendar.datesOff", `${payload.semesterId}:${payload.rotationId}`);
      } else {
        patch.addToSet("attributes.calendar.datesOff", `${payload.semesterId}:${payload.rotationId}`);
      }

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let course = findFn("course", _lodash.default.get(payload, "courseId"));
      let rotationCalendar = findFn("rotation-calendar", _lodash.default.get(course, "relationships.rotationCalendar.data.id"));
      let planbook = findFn("planbook", _lodash.default.get(course, "relationships.planbook.data.id")); // Find the rotationCalendar
      // Find the title of the rotation

      let onOff = payload.isOff === true ? "Off" : "On";

      let rotation = _lodash.default.find(rotationCalendar, {
        id: payload.rotationId
      });

      let title = _lodash.default.get(rotation, "title");

      return {
        title: `Toggle Class ${onOff}`,
        titleForAnalytics: "Course - Toggle Template Date",
        description: `Toggle all courses on that happen on ${title} to be ${onOff}`,
        context: {
          courseTitle: course.attributes.title,
          planbookTitle: planbook.attributes.title,
          courseId: course.id,
          planbookId: planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${course.id}`,
          to: [`course-edits:course-${course.id}`, `planbook-edits:planbook-${planbook.id}`]
        }
      };
    }

  };
  _exports.default = _default;
});