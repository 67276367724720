define("cc-frontend/templates/icons/-nucleo-power-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kuMJPyhm",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"64\"],[14,\"height\",\"64\"],[14,\"viewBox\",\"0 0 64 64\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,0,\"a\"],[14,\"d\",\"M32 56.41a23.67 23.67 0 0 1-15.51-41.54 2 2 0 1 1 2.62 3 19.67 19.67 0 1 0 25.77 0 2 2 0 1 1 2.62-3A23.67 23.67 0 0 1 32 56.41z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,0,\"a\"],[14,\"d\",\"M32 32.51a2 2 0 0 1-2-2V9.59a2 2 0 0 1 4 0v20.92a2 2 0 0 1-2 2z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-nucleo-power-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});