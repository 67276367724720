define("cc-frontend/components/planner/google-classroom/parts/attachment.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "attachment-line": "_attachment-line_1yp16e",
    "attachment-line__right": "_attachment-line__right_1yp16e",
    "file-name-section": "_file-name-section_1yp16e",
    "attachment-line__left": "_attachment-line__left_1yp16e",
    "share-mode-select__change-mode": "_share-mode-select__change-mode_1yp16e",
    "share-mode-option": "_share-mode-option_1yp16e",
    "--is-selected": "_--is-selected_1yp16e",
    "remove-attachment-btn": "_remove-attachment-btn_1yp16e",
    "status-box-icon": "_status-box-icon_1yp16e",
    "--success": "_--success_1yp16e",
    "--error": "_--error_1yp16e"
  };
  _exports.default = _default;
});