define("cc-frontend/templates/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s+Ud7Vnn",
    "block": "[[[1,[28,[35,0],[\"Features | Planbook & Lesson Planner. Free for Teachers. Common Curriculum\"],[[\"replace\"],[true]]]],[1,\"\\n\"],[8,[39,1],null,[[\"@title\"],[\"marketing.features\"]],null],[1,\"\\n\"],[8,[39,2],null,null,null]],[],false,[\"page-title\",\"record-page-visit\",\"home-page/features-page\"]]",
    "moduleName": "cc-frontend/templates/features.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});