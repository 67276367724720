define("cc-frontend/modifiers/autofocus", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function autofocus(element, [selector = "input:not([disabled])"]) {
    const childElement = element.querySelector(selector); // We do a later so ember animated doesn't interfere
    // https://github.com/qonto/ember-autofocus-modifier/issues/88

    Ember.run.later(() => {
      if (childElement) {
        childElement.focus();
      } else {
        element.focus();
      }
    }, 200);
  });

  _exports.default = _default;
});