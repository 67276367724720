define("cc-frontend/components/planner/course/outline-and-detail/outline", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @isPrinting}}
    <Planner::Course::OutlineAndDetail::Search
      @isSearching={{this.isSearching}}
      @hasQuery={{this.hasQuery}}
      @onClearSearchQuery={{this.onClearSearchQuery}}
      @onSearchFocus={{this.onSearchFocus}}
      @startedSearch={{this.startedSearch}}
      @finishedSearch={{this.finishedSearch}}
      @course={{@course}}
    />
  
  {{/unless}}
  <Planner::Course::OutlineAndDetail::DatesForList
    @course={{@course}}
    @planbook={{@planbook}}
    @startDate={{@startDate}}
    @endDate={{@endDate}}
    @rotationCalendar={{@rotationCalendar}}
    as |dates|
  >
    {{#if @isPrinting}}
      <Planner::Course::OutlineAndDetail::ListForPrinting @dates={{dates}} />
    {{else}}
      <Planner::Course::OutlineAndDetail::List
        @hasQuery={{this.hasQuery}}
        @dates={{dates}}
        @queryResults={{this.queryResults}}
        @focusedItemId={{@focusedItemId}}
        @planbook={{@planbook}}
        @course={{@course}}
        @rotationCalendar={{@rotationCalendar}}
        @onNewFocusedItemId={{@onNewFocusedItemId}}
        @selectedUnitIds={{@selectedUnitIds}}
        @selectedDateStrings={{@selectedDateStrings}}
        @onLessonSelect={{@onLessonSelect}}
        @onUnitSelect={{@onUnitSelect}}
        @isSelectable={{@isSelectable}}
      />
  
    {{/if}}
  </Planner::Course::OutlineAndDetail::DatesForList>
  */
  {
    "id": "APX35vDD",
    "block": "[[[41,[51,[30,1]],[[[1,\"  \"],[8,[39,1],null,[[\"@isSearching\",\"@hasQuery\",\"@onClearSearchQuery\",\"@onSearchFocus\",\"@startedSearch\",\"@finishedSearch\",\"@course\"],[[30,0,[\"isSearching\"]],[30,0,[\"hasQuery\"]],[30,0,[\"onClearSearchQuery\"]],[30,0,[\"onSearchFocus\"]],[30,0,[\"startedSearch\"]],[30,0,[\"finishedSearch\"]],[30,2]]],null],[1,\"\\n\\n\"]],[]],null],[8,[39,2],null,[[\"@course\",\"@planbook\",\"@startDate\",\"@endDate\",\"@rotationCalendar\"],[[30,2],[30,3],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,4],null,[[\"@dates\"],[[30,7]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,[[\"@hasQuery\",\"@dates\",\"@queryResults\",\"@focusedItemId\",\"@planbook\",\"@course\",\"@rotationCalendar\",\"@onNewFocusedItemId\",\"@selectedUnitIds\",\"@selectedDateStrings\",\"@onLessonSelect\",\"@onUnitSelect\",\"@isSelectable\"],[[30,0,[\"hasQuery\"]],[30,7],[30,0,[\"queryResults\"]],[30,8],[30,3],[30,2],[30,6],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14]]],null],[1,\"\\n\\n\"]],[]]]],[7]]]]]],[\"@isPrinting\",\"@course\",\"@planbook\",\"@startDate\",\"@endDate\",\"@rotationCalendar\",\"dates\",\"@focusedItemId\",\"@onNewFocusedItemId\",\"@selectedUnitIds\",\"@selectedDateStrings\",\"@onLessonSelect\",\"@onUnitSelect\",\"@isSelectable\"],false,[\"unless\",\"planner/course/outline-and-detail/search\",\"planner/course/outline-and-detail/dates-for-list\",\"if\",\"planner/course/outline-and-detail/list-for-printing\",\"planner/course/outline-and-detail/list\"]]",
    "moduleName": "cc-frontend/components/planner/course/outline-and-detail/outline.hbs",
    "isStrictMode": false
  });

  let Outline = (_dec = (0, _component.classNames)("course-outline-with-detail__outline"), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec(_class = (_class2 = class Outline extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);

      _initializerDefineProperty(this, "dragging", _descriptor3, this);

      _initializerDefineProperty(this, "rpc", _descriptor4, this);

      _defineProperty(this, "onNewFocusedItemId", void 0);

      _defineProperty(this, "focusedItemId", void 0);

      _defineProperty(this, "course", void 0);

      _defineProperty(this, "rotationCalendar", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "isSelectable", void 0);

      _defineProperty(this, "onUnitSelect", void 0);

      _defineProperty(this, "onLessonSelect", void 0);

      _defineProperty(this, "selectedDateStrings", void 0);

      _defineProperty(this, "selectedUnitIds", void 0);

      _defineProperty(this, "hasNotYetScrolled", true);

      _defineProperty(this, "hasQuery", false);

      _defineProperty(this, "isSearching", false);

      _defineProperty(this, "_previousFocusedItemId", void 0);

      _defineProperty(this, "queryResults", null);
    }

    startedSearch(query) {
      this.set("hasQuery", query !== "" && query !== null);
      this.set("isSearching", true);
    }

    finishedSearch(data) {
      this.set("queryResults", data);
      this.set("isSearching", false);
    }

    onSearchFocus() {
      Ember.set(this, "_previousFocusedItemId", this.focusedItemId);
      this.onNewFocusedItemId(null);
    }

    onClearSearchQuery() {
      this.set("queryResults", null);
      this.onNewFocusedItemId(this._previousFocusedItemId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appState", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "dragging", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "rpc", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "startedSearch", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "startedSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "finishedSearch", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "finishedSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onSearchFocus", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "onSearchFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onClearSearchQuery", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "onClearSearchQuery"), _class2.prototype)), _class2)) || _class);
  _exports.default = Outline;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Outline);
});