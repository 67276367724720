define("cc-frontend/instance-initializers/store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let store = appInstance.lookup("service:store");
    appInstance.lookup("service:persister");
    appInstance.lookup("service:action-callbacks");
    appInstance.lookup("service:patch-callbacks");
  }

  var _default = {
    name: "store",
    initialize: initialize,
    after: ["socket"]
  };
  _exports.default = _default;
});