define("cc-frontend/templates/icons/-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IyYrc5Y2",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M397.8 55.6H228.5v.2l-68.8 68.6v22.9l-45.6 45.5v259.7h238.1V384h45.6V55.6zm-72.9 369.6H141.5V220.3h18.2v163.9h165.1v41zm45.6-68.4H187.1v-205h68.8V83h114.6v273.8z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-copy.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});