define("cc-frontend/components/planner/menus/day-menu/change-start-of-school-year/confirm-body", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="g-dropdown-menu__header">
    Do you want to change the start of the school year to
    {{moment-format @dateString "MMMM D, YYYY"}}
    ?
  </div>
  <div class="tw-flex tw-flex-col">
    <Planner::Menus::Parts::OutlineButton @onClick={{fn @confirmButton}}>
      Yes, I want to change it
    </Planner::Menus::Parts::OutlineButton>
    <Planner::Menus::Parts::OutlineButton @isLinkStyle={{true}} @onClick={{fn @cancelButton}}>
      No, leave it where it was
    </Planner::Menus::Parts::OutlineButton>
  </div>
  */
  {
    "id": "5vBbIsqi",
    "block": "[[[10,0],[14,0,\"g-dropdown-menu__header\"],[12],[1,\"\\n  Do you want to change the start of the school year to\\n  \"],[1,[28,[35,0],[[30,1],\"MMMM D, YYYY\"],null]],[1,\"\\n  ?\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"tw-flex tw-flex-col\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@onClick\"],[[28,[37,2],[[30,2]],null]]],[[\"default\"],[[[[1,\"\\n    Yes, I want to change it\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@isLinkStyle\",\"@onClick\"],[true,[28,[37,2],[[30,3]],null]]],[[\"default\"],[[[[1,\"\\n    No, leave it where it was\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@dateString\",\"@confirmButton\",\"@cancelButton\"],false,[\"moment-format\",\"planner/menus/parts/outline-button\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/change-start-of-school-year/confirm-body.hbs",
    "isStrictMode": false
  });

  let DayMenuTitleConfirmChangeStartOfSchoolYear = (_dec = (0, _component.tagName)(""), _dec(_class = class DayMenuTitleConfirmChangeStartOfSchoolYear extends Ember.Component {}) || _class);
  _exports.default = DayMenuTitleConfirmChangeStartOfSchoolYear;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DayMenuTitleConfirmChangeStartOfSchoolYear);
});