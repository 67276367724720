define("cc-frontend/components/user/create-planbook.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "onboarding-body": "_onboarding-body_omrdhx",
    "form-section": "_form-section_omrdhx",
    "onboarding-example": "_onboarding-example_omrdhx",
    "onboarding-example__header": "_onboarding-example__header_omrdhx",
    "onboarding-example__person": "_onboarding-example__person_omrdhx",
    "onboarding-example__person__image": "_onboarding-example__person__image_omrdhx",
    "onboarding-example__person__text": "_onboarding-example__person__text_omrdhx",
    "onboarding-example__person__text__header": "_onboarding-example__person__text__header_omrdhx"
  };
  _exports.default = _default;
});