define("cc-frontend/lib/actions/app-state", ["exports", "lodash", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/create-narrative", "cc-frontend/lib/actions/create-patch"], function (_exports, _lodash, _patchUpdater, _createNarrative, _createPatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_STANDARDS_MODAL_OPEN = _exports.PAGE_VISIT = _exports.MARKETING_CLICK_FEATURE = void 0;

  let defaultRelatedModels = function (findFn, payload, actionId, session) {
    return {
      session,
      actionId
    };
  };

  let defaultContext = function (payload, models) {
    // We use get as some of these values might be null/undefined and we want to not break
    return {
      sessionId: Ember.get(models, "session.id"),
      userId: Ember.get(models, "session.userId"),
      sequenceInSession: Ember.get(models, "session.sequenceNumber"),
      secondsSinceLastAction: Ember.get(models, "session.secondsSinceLastAction")
    };
  };

  let genericParams = {
    componentId: "string",
    sessionId: "string",
    attrs: "object"
  };

  let genericPatches = function patches(payload) {
    let updater = _patchUpdater.default.create("app-state", payload.sessionId);

    _lodash.default.each(payload.attrs, (val, key) => {
      updater.set(`attributes.${payload.componentId}.${key}`, val);
    });

    return [updater.patch];
  };

  let genericNarrative = function (titleForAnalytics) {
    return function (_payload, _findFn, _userId) {
      let payload = _payload || {};
      return {
        title: payload.title || null,
        titleForAnalytics: titleForAnalytics,
        description: payload.description || null,
        context: payload.context || {},
        activity: null
      };
    };
  };

  let actions = {
    REGISTER_COMPONENT: {
      params: {
        sessionId: "string",
        componentId: "string",
        defaultState: "object"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId, payload.defaultState]);
        return [patch];
      },

      undoPatches: null,
      narrative: null
    },
    DEREGISTER_COMPONENT: {
      params: {
        sessionId: "string",
        componentId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.unset.push(["attributes." + payload.componentId, 1]);
        return [patch];
      },

      undoPatches: null,
      narrative: null
    },
    LESSON_STANDARDS_MODAL_CLOSE: {
      params: {
        sessionId: "string",
        componentId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".isShowingModal", false]);
        return [patch];
      },

      undoPatches: null,

      narrative(payload) {
        let models = defaultRelatedModels;
        return (0, _createNarrative.default)({
          titleForAnalytics: "Standards - Close modal",
          context: defaultContext
        });
      }

    },
    CLOSE_LESSON_TEMPLATE_MODAL: {
      params: {
        componentId: "string",
        sessionId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".isOpen", false]);
        return [patch];
      }

    },
    CLOSE_UNIT_COMPARISON_MODAL: {
      params: {
        componentId: "string",
        sessionId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".isOpen", false]);
        return [patch];
      },

      narrative(payload) {
        let models = defaultRelatedModels;
        return (0, _createNarrative.default)({
          titleForAnalytics: "Unit - Comparison Modal - Close",
          context: defaultContext
        });
      }

    },
    SET_CLASS_WEBSITE_SLUG_VALIDATION: {
      params: {
        componentId: "string",
        sessionId: "string",
        slug: {
          type: "string",
          optional: true
        },
        isValid: "boolean",
        message: {
          type: "string",
          optional: true
        }
      },

      patches(payload) {
        let patch = _patchUpdater.default.create("app-state", payload.sessionId).set(`attributes.${payload.componentId}.slugIsValid`, payload.isValid).set(`attributes.${payload.componentId}.slugErrorMessage`, payload.message).patch;

        return [patch];
      }

    },
    OPEN_PRINT_MODAL: {
      params: {
        componentId: "string",
        sessionId: "string",
        perspective: "string",
        startDate: "string",
        endDate: "string",
        medium: "string",
        currentUserId: "string",
        courseIds: "array",
        courseId: "string",
        planbookId: "string",
        dateRangeString: "string",
        canPrint: "boolean"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);

        _lodash.default.chain(payload).map((val, key) => [`attributes.${payload.componentId}.${key}`, val]).forEach(tuple => patch.document.ops.set.push(tuple)).value();

        patch.document.ops.set.push([`attributes.${payload.componentId}.isOpen`, true]);
        return [patch];
      },

      narrative(payload) {
        let models = defaultRelatedModels;

        let title = _lodash.default.capitalize(`${payload.medium} ${payload.perspective}`);

        return (0, _createNarrative.default)({
          titleForAnalytics: title,
          context: defaultContext
        });
      }

    },
    CLOSE_PRINT_MODAL: {
      params: {
        componentId: "string",
        sessionId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".isOpen", false]);
        return [patch];
      }

    },
    CLOSE_PAYWALL_MODAL: {
      params: {
        componentId: "string",
        sessionId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".isOpen", false]);
        return [patch];
      }

    },
    OPEN_COURSE_OUTLINE_MODAL: {
      params: {
        componentId: "string",
        sessionId: "string",
        courseId: "string",
        focusedItemId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".isOpen", true]);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".courseId", payload.courseId]);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".focusedItemId", payload.focusedItemId]);
        return [patch];
      },

      narrative(payload) {
        let models = defaultRelatedModels;
        return (0, _createNarrative.default)({
          titleForAnalytics: "Unit Planner - Show Outline",
          context: defaultContext
        });
      }

    },
    SIDEBAR_SET_UNIT_ID: {
      params: {
        componentId: "string",
        sessionId: "string",
        cardStackId: "string",
        unitInfo: "object",
        courseId: "string"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".cardStackId", payload.cardStackId]);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".unitInfo", payload.unitInfo]);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".courseId", payload.courseId]);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".isUnit", true]);
        return [patch];
      }

    },
    SIDEBAR_OPEN: {
      params: {
        componentId: "string",
        sessionId: "string",
        cardStackId: "string",
        unitInfo: "object"
      },

      patches(payload) {
        let patch = (0, _createPatch.default)("app-state", payload.sessionId);
        patch.document.ops.set.push(["attributes." + payload.componentId + ".showSidebar", true]);
        return [patch];
      },

      narrative(payload) {
        let models = defaultRelatedModels;
        return (0, _createNarrative.default)({
          titleForAnalytics: "Lesson Sidebar - Open",
          context: defaultContext
        });
      }

    },
    KB_HAPPY_FACE_CLICKED: {
      params: {
        article: "object"
      },
      patches: null,
      undoPatches: null,

      narrative(payload) {
        return (0, _createNarrative.default)({
          titleForAnalytics: "KB Article - Happy Face Clicked",
          context: {
            articleId: payload.article.id,
            articleTitle: payload.article.attributes.title
          }
        });
      }

    },
    KB_SAD_FACE_CLICKED: {
      params: {
        article: "object"
      },
      patches: null,
      undoPatches: null,

      narrative(payload) {
        return (0, _createNarrative.default)({
          titleForAnalytics: "KB Article - Sad Face Clicked",
          context: {
            articleId: payload.article.id,
            articleTitle: payload.article.attributes.title
          }
        });
      }

    },
    KB_SEND_US_A_MESSAGE: {
      params: {},
      patches: null,
      undoPatches: null,

      narrative(payload) {
        return (0, _createNarrative.default)({
          titleForAnalytics: "KB Send us a Message",
          context: {
            searchQuery: payload.searchQuery
          }
        });
      }

    },
    KB_SELECT_ARTICLE_FROM_SEARCH: {
      params: {},
      patches: null,
      undoPatches: null,

      narrative(payload) {
        return (0, _createNarrative.default)({
          titleForAnalytics: "KB Search - Select Article",
          context: {
            searchQuery: payload.searchQuery,
            articleTitle: payload.articleTitle,
            articleId: payload.articleId,
            parentId: payload.parentId,
            ancestorIds: payload.ancestorIds
          }
        });
      }

    },
    KB_VISIT_ARTICLE: {
      params: {
        article: "object"
      },
      patches: null,
      undoPatches: null,

      narrative(payload) {
        return (0, _createNarrative.default)({
          titleForAnalytics: "KB Article - Visit",
          context: {
            articleId: payload.articleId,
            articleTitle: payload.articleTitle
          }
        });
      }

    },
    PLANBOOK_SEARCH_BOX_UNFOCUS: {
      params: {
        query: "string"
      },
      patches: null,
      undoPatches: null,

      narrative(payload) {
        return (0, _createNarrative.default)({
          titleForAnalytics: "Planbook - Search - Blur Search Box",
          context: {
            searchTerm: payload.query
          }
        });
      }

    },
    PLANBOOK_SEARCH_SELECT_RESULT: {
      params: {},
      patches: null,
      undoPatches: null,

      narrative(_payload) {
        return (0, _createNarrative.default)({
          titleForAnalytics: "Planbook - Search - Select Result"
        });
      }

    },
    OPEN_ATTACHMENT_PREVIEW_MODAL: {
      params: genericParams,
      patches: genericPatches,
      narrative: genericNarrative("Attachment - Preview")
    },
    CLOSE_ATTACHMENT_PREVIEW_MODAL: {
      params: genericParams,
      patches: genericPatches,
      narrative: genericNarrative("Attachment - Preview")
    }
  };
  const MARKETING_CLICK_FEATURE = {
    name: "MARKETING_CLICK_FEATURE",
    patches: null,
    narrative: genericNarrative("Marketing - Click Feature")
  };
  _exports.MARKETING_CLICK_FEATURE = MARKETING_CLICK_FEATURE;
  const PAGE_VISIT = {
    name: "PAGE_VISIT",
    patches: null,
    undoPatches: null,
    narrative: genericNarrative("Page - Visit")
  };
  _exports.PAGE_VISIT = PAGE_VISIT;
  const LESSON_STANDARDS_MODAL_OPEN = {
    name: "LESSON_STANDARDS_MODAL_OPEN",
    params: {
      sessionId: "string",
      componentId: "string"
    },

    patches(payload) {
      let patch = (0, _createPatch.default)("app-state", payload.sessionId);
      patch.document.ops.set.push(["attributes." + payload.componentId + ".isShowingModal", true]);
      return [patch];
    },

    narrative(payload) {
      let models = defaultRelatedModels;
      return (0, _createNarrative.default)({
        titleForAnalytics: "Standards - Open modal",
        context: defaultContext
      });
    }

  };
  _exports.LESSON_STANDARDS_MODAL_OPEN = LESSON_STANDARDS_MODAL_OPEN;
  var _default = actions;
  _exports.default = _default;
});