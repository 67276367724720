define("cc-frontend/components/icons/party-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g>
      <path
        d="M37.648,32.979,15.021,10.352a1,1,0,0,0-1.656.39L2.052,44.684A1,1,0,0,0,3,46a.987.987,0,0,0,.316-.052L37.258,34.635a1,1,0,0,0,.39-1.656Z"
        fill="#efd358"
      ></path>
      <ellipse
        cx="25.627"
        cy="22.373"
        rx="5"
        ry="17"
        transform="translate(-8.314 24.674) rotate(-45)"
        fill="#e2ac4b"
      ></ellipse>
      <path
        d="M31,25a1,1,0,0,1-.9-1.426c2.516-5.349,8.805-7.2,15.29-4.5a1,1,0,1,1-.77,1.846c-5.451-2.27-10.674-.828-12.71,3.5A1,1,0,0,1,31,25Z"
        fill="#43a6dd"
      ></path>
      <circle cx="37" cy="9" r="3" fill="#e86c60"></circle>
      <circle cx="41" cy="39" r="2" fill="#e2ac4b"></circle>
      <circle cx="28" cy="18" r="2" fill="#5a7a84"></circle>
      <circle cx="42.5" cy="26.5" r="1.5" fill="#ae453e"></circle>
      <circle cx="17.5" cy="4.5" r="1.5" fill="#e2ac4b"></circle>
      <path
        d="M21,19a1,1,0,0,1-.6-1.8A12.026,12.026,0,0,0,24.081,2.394a1,1,0,0,1,1.838-.788A13.975,13.975,0,0,1,21.6,18.8.994.994,0,0,1,21,19Z"
        fill="#e86c60"
      ></path>
      <path
        d="M4.172,13.828a1,1,0,0,1-.707-.293,5,5,0,1,1,7.07-7.07A1,1,0,0,1,9.121,7.879a3.072,3.072,0,0,0-4.242,0,3,3,0,0,0,0,4.242,1,1,0,0,1-.707,1.707Z"
        fill="#43a6dd"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "qqRMAlX9",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"48\"],[14,\"height\",\"48\"],[14,\"viewBox\",\"0 0 48 48\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M37.648,32.979,15.021,10.352a1,1,0,0,0-1.656.39L2.052,44.684A1,1,0,0,0,3,46a.987.987,0,0,0,.316-.052L37.258,34.635a1,1,0,0,0,.39-1.656Z\"],[14,\"fill\",\"#efd358\"],[12],[13],[1,\"\\n    \"],[10,\"ellipse\"],[14,\"cx\",\"25.627\"],[14,\"cy\",\"22.373\"],[14,\"rx\",\"5\"],[14,\"ry\",\"17\"],[14,\"transform\",\"translate(-8.314 24.674) rotate(-45)\"],[14,\"fill\",\"#e2ac4b\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M31,25a1,1,0,0,1-.9-1.426c2.516-5.349,8.805-7.2,15.29-4.5a1,1,0,1,1-.77,1.846c-5.451-2.27-10.674-.828-12.71,3.5A1,1,0,0,1,31,25Z\"],[14,\"fill\",\"#43a6dd\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"37\"],[14,\"cy\",\"9\"],[14,\"r\",\"3\"],[14,\"fill\",\"#e86c60\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"41\"],[14,\"cy\",\"39\"],[14,\"r\",\"2\"],[14,\"fill\",\"#e2ac4b\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"28\"],[14,\"cy\",\"18\"],[14,\"r\",\"2\"],[14,\"fill\",\"#5a7a84\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"42.5\"],[14,\"cy\",\"26.5\"],[14,\"r\",\"1.5\"],[14,\"fill\",\"#ae453e\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"17.5\"],[14,\"cy\",\"4.5\"],[14,\"r\",\"1.5\"],[14,\"fill\",\"#e2ac4b\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M21,19a1,1,0,0,1-.6-1.8A12.026,12.026,0,0,0,24.081,2.394a1,1,0,0,1,1.838-.788A13.975,13.975,0,0,1,21.6,18.8.994.994,0,0,1,21,19Z\"],[14,\"fill\",\"#e86c60\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M4.172,13.828a1,1,0,0,1-.707-.293,5,5,0,1,1,7.07-7.07A1,1,0,0,1,9.121,7.879a3.072,3.072,0,0,0-4.242,0,3,3,0,0,0,0,4.242,1,1,0,0,1-.707,1.707Z\"],[14,\"fill\",\"#43a6dd\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/party-hat.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});