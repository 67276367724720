define("cc-frontend/components/icons/arrow-up-with-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! Arrow Thick Circle UP 2 }}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M12,24.087c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,2.22045e-16 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z"
      ></path>
      <path
        d="M12,19.087c-1.103,0 -2,-0.897 -2,-2v-5.548l-1.46,1.463c-0.377,0.378 -0.88,0.585 -1.414,0.585c-0.535,0 -1.037,-0.208 -1.415,-0.585c-0.78,-0.78 -0.779,-2.049 0,-2.829l5.585,-5.585c0.181,-0.182 0.439,-0.286 0.707,-0.286c0.268,0 0.526,0.104 0.707,0.286l5.582,5.585c0.78,0.78 0.78,2.049 0,2.829c-0.377,0.377 -0.879,0.585 -1.414,0.585v0c-0.535,0 -1.037,-0.208 -1.414,-0.585l-1.464,-1.465v5.55c0,1.103 -0.897,2 -2,2Zm-1.5,-9.257c0.066,0 0.13,0.013 0.191,0.038c0.188,0.077 0.309,0.259 0.309,0.462v6.757c0,0.552 0.449,1 1,1c0.552,0 1,-0.448 1,-1v-6.757c0,-0.203 0.121,-0.384 0.309,-0.462c0.061,-0.025 0.125,-0.038 0.191,-0.038c0.134,0 0.259,0.052 0.354,0.146l2.318,2.318c0.189,0.189 0.44,0.293 0.707,0.293c0.267,0 0.519,-0.104 0.708,-0.293c0.39,-0.39 0.389,-1.025 0,-1.415l-5.582,-5.585l-5.585,5.585c-0.39,0.39 -0.39,1.025 0,1.415c0.188,0.189 0.44,0.292 0.707,0.292c0.267,0 0.519,-0.104 0.707,-0.292l2.314,-2.318c0.091,-0.093 0.22,-0.146 0.352,-0.146Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "p+DKGXrb",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,24.087c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,2.22045e-16 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,19.087c-1.103,0 -2,-0.897 -2,-2v-5.548l-1.46,1.463c-0.377,0.378 -0.88,0.585 -1.414,0.585c-0.535,0 -1.037,-0.208 -1.415,-0.585c-0.78,-0.78 -0.779,-2.049 0,-2.829l5.585,-5.585c0.181,-0.182 0.439,-0.286 0.707,-0.286c0.268,0 0.526,0.104 0.707,0.286l5.582,5.585c0.78,0.78 0.78,2.049 0,2.829c-0.377,0.377 -0.879,0.585 -1.414,0.585v0c-0.535,0 -1.037,-0.208 -1.414,-0.585l-1.464,-1.465v5.55c0,1.103 -0.897,2 -2,2Zm-1.5,-9.257c0.066,0 0.13,0.013 0.191,0.038c0.188,0.077 0.309,0.259 0.309,0.462v6.757c0,0.552 0.449,1 1,1c0.552,0 1,-0.448 1,-1v-6.757c0,-0.203 0.121,-0.384 0.309,-0.462c0.061,-0.025 0.125,-0.038 0.191,-0.038c0.134,0 0.259,0.052 0.354,0.146l2.318,2.318c0.189,0.189 0.44,0.293 0.707,0.293c0.267,0 0.519,-0.104 0.708,-0.293c0.39,-0.39 0.389,-1.025 0,-1.415l-5.582,-5.585l-5.585,5.585c-0.39,0.39 -0.39,1.025 0,1.415c0.188,0.189 0.44,0.292 0.707,0.292c0.267,0 0.519,-0.104 0.707,-0.292l2.314,-2.318c0.091,-0.093 0.22,-0.146 0.352,-0.146Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/arrow-up-with-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});