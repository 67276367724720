define("cc-frontend/components/icons/streamline-task-checklist-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 24C.673 24 0 23.327 0 22.5v-19C0 2.673.673 2 1.5 2h2c.276 0 .5.224.5.5s-.224.5-.5.5h-2c-.276 0-.5.224-.5.5v19c0 .276.224.5.5.5H10c.276 0 .5.224.5.5s-.224.5-.5.5H1.5zM18.5 10c-.276 0-.5-.224-.5-.5v-6c0-.276-.224-.5-.5-.5h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.827 0 1.5.673 1.5 1.5v6c0 .276-.224.5-.5.5z"
    ></path>
    <path
      d="M3 22c-.551 0-1-.448-1-1V5c0-.551.449-1 1-1h2V2.5c0-.276.224-.5.5-.5h1.55C7.284.845 8.3 0 9.5 0c1.2 0 2.216.845 2.45 2h1.55c.276 0 .5.224.5.5V4h2c.552 0 1 .449 1 1v4.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5V5h-2v.5c0 .276-.224.5-.5.5h-8c-.276 0-.5-.224-.5-.5V5H3v16h7c.276 0 .5.224.5.5s-.224.5-.5.5H3zM13 5V3h-1.5c-.276 0-.5-.224-.5-.5 0-.827-.673-1.5-1.5-1.5S8 1.673 8 2.5c0 .276-.224.5-.5.5H6v2h7z"
    ></path>
    <path
      d="M5.5 8.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5h-8zM5.5 11.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5h-6zM5.5 14.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h4c.276 0 .5.224.5.5s-.224.5-.5.5h-4zM17.5 23.999c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.467-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.467 5.5-5.5-2.467-5.5-5.5-5.5z"
    ></path>
    <path
      d="M17.5 20.999c-.276 0-.5-.224-.5-.5v-2.5h-2.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5H17v-2.5c0-.276.224-.5.5-.5s.5.224.5.5v2.5h2.5c.276 0 .5.224.5.5s-.224.5-.5.5H18v2.5c0 .276-.224.5-.5.5z"
    ></path>
  </svg>
  */
  {
    "id": "YMlddJfT",
    "block": "[[[10,\"svg\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M1.5 24C.673 24 0 23.327 0 22.5v-19C0 2.673.673 2 1.5 2h2c.276 0 .5.224.5.5s-.224.5-.5.5h-2c-.276 0-.5.224-.5.5v19c0 .276.224.5.5.5H10c.276 0 .5.224.5.5s-.224.5-.5.5H1.5zM18.5 10c-.276 0-.5-.224-.5-.5v-6c0-.276-.224-.5-.5-.5h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.827 0 1.5.673 1.5 1.5v6c0 .276-.224.5-.5.5z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M3 22c-.551 0-1-.448-1-1V5c0-.551.449-1 1-1h2V2.5c0-.276.224-.5.5-.5h1.55C7.284.845 8.3 0 9.5 0c1.2 0 2.216.845 2.45 2h1.55c.276 0 .5.224.5.5V4h2c.552 0 1 .449 1 1v4.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5V5h-2v.5c0 .276-.224.5-.5.5h-8c-.276 0-.5-.224-.5-.5V5H3v16h7c.276 0 .5.224.5.5s-.224.5-.5.5H3zM13 5V3h-1.5c-.276 0-.5-.224-.5-.5 0-.827-.673-1.5-1.5-1.5S8 1.673 8 2.5c0 .276-.224.5-.5.5H6v2h7z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M5.5 8.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5h-8zM5.5 11.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5h-6zM5.5 14.999c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h4c.276 0 .5.224.5.5s-.224.5-.5.5h-4zM17.5 23.999c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.467-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.467 5.5-5.5-2.467-5.5-5.5-5.5z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M17.5 20.999c-.276 0-.5-.224-.5-.5v-2.5h-2.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5H17v-2.5c0-.276.224-.5.5-.5s.5.224.5.5v2.5h2.5c.276 0 .5.224.5.5s-.224.5-.5.5H18v2.5c0 .276-.224.5-.5.5z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/streamline-task-checklist-add.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});