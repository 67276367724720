define("cc-frontend/helpers/statechart", ["exports", "ember-could-get-used-to-this", "lodash-es", "xstate"], function (_exports, _emberCouldGetUsedToThis, _lodashEs, _xstate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Statechart = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const INTERPRETER = Symbol("interpreter");
  const CONFIG = Symbol("config");
  const MACHINE = Symbol("machine");
  const ERROR_CANT_RECONFIGURE = `Cannot re-invoke withContext after the interpreter has been initialized`;
  const ERROR_CHART_MISSING = `A statechart was not passed`;

  const gatherActions = function (object, actionsArray) {
    for (let k in object) {
      if (["onEntry", "onExit", "entry", "exit", "actions"].includes(k)) {
        if (Array.isArray(object[k])) {
          actionsArray = actionsArray.concat(object[k]);
        } else if (typeof object[k] === "string") {
          actionsArray.push(object[k]);
        }
      } else if (typeof object[k] === "object" && object[k] !== null) {
        actionsArray = gatherActions(object[k], actionsArray);
      }
    }

    return actionsArray;
  };

  const gatherGuards = function (object, acc) {
    for (let k in object) {
      if (k === "cond") {
        if (typeof object[k] === "object" && object[k] !== null && (0, _lodashEs.has)(object[k], "type")) {
          acc.push(object[k].type);
        } else {
          acc.push(object[k]);
        }

        acc.push(object[k]);
      } else if (typeof object[k] === "object" && object[k] !== null) {
        acc = gatherGuards(object[k], acc);
      }
    }

    return acc;
  };

  const gatherActivities = function (object, acc) {
    for (let k in object) {
      if (k === "activities") {
        acc = acc.concat(object[k]);
      } else if (typeof object[k] === "object" && object[k] !== null) {
        acc = gatherActivities(object[k], acc);
      }
    }

    return acc;
  };

  const gatherDelays = function (object, acc) {
    for (let k in object) {
      if (k === "after") {
        if (typeof object[k] === "object" && object[k] !== null) {
          acc = acc.concat(Object.keys(object[k]));
        } else if (Array.isArray(object[k])) {
          object[k].forEach(el => {
            for (let key in el) {
              if (key === "delay") {
                acc.push(el[key]);
              }
            }
          });
        }
      } else if (typeof object[k] === "object" && object[k] !== null) {
        acc = gatherDelays(object[k], acc);
      }
    }

    return acc;
  };

  /**
   *
    @use statechart = new Statechart(() => [{
      owner: this,
      chart,
      // below are optional:
      context,
      activities,
      guards,
      delays,
      onTransition,
      update
    }])
   */
  let Statechart = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class Statechart extends _emberCouldGetUsedToThis.Resource {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "state", _descriptor, this);
    }

    send(...args) {
      return this[INTERPRETER].send(...args);
    }
    /**
     * Private
     */


    update() {
      // @ts-ignore
      let {
        update
      } = this.args.positional[0];

      if (update) {
        // @ts-ignore
        update(this.send, this.state.context, this.state.value);
      }
    }
    /**
     * Lifecycle methods on Resource
     *
     */


    setup() {
      var _this$args$positional, _this$args$positional2;

      if (((_this$args$positional = this.args.positional) === null || _this$args$positional === void 0 ? void 0 : _this$args$positional[0]) === undefined) return;
      let {
        context,
        activities,
        actions,
        guards,
        delays,
        onTransition,
        owner,
        chart
      } = (_this$args$positional2 = this.args.positional) === null || _this$args$positional2 === void 0 ? void 0 : _this$args$positional2[0];
      (false && !(chart) && Ember.assert(ERROR_CHART_MISSING, chart)); // This all could likely be refactored into one function but it could get a dice

      let allActions = gatherActions(chart, []);
      let actionsConfig = allActions.reduce((acc, actionName) => {
        (false && !(owner[actionName]) && Ember.assert(`Must implement function for the action: ${actionName}`, owner[actionName]));
        acc[actionName] = owner[actionName];
        return acc;
      }, {});
      let allGuards = gatherGuards(chart, []);
      let guardsConfig = allGuards.reduce((acc, guardName) => {
        (false && !(owner[guardName]) && Ember.assert(`Must implement function for the guard: ${guardName}`, owner[guardName]));
        acc[guardName] = owner[guardName];
        return acc;
      }, {});
      let allActivities = gatherActivities(chart, []);
      let activitiesConfig = allActivities.reduce((acc, activityName) => {
        (false && !(owner[activityName]) && Ember.assert(`Must implement function for the activity: ${activityName}`, owner[activityName]));
        acc[activityName] = owner[activityName];
        return acc;
      }, {});
      let allDelays = gatherDelays(chart, []);
      let delayConfig = allDelays.reduce((acc, delayName) => {
        (false && !(owner[delayName]) && Ember.assert(`Must implement function for the delay: ${delayName}`, owner[delayName]));
        acc[delayName] = owner[delayName];
        return acc;
      }, {}); // Combine into one config object -- this also could be done better

      actions = actions || {};
      actions = { ...actionsConfig,
        ...actions
      };
      guards = guards || {};
      guards = { ...guardsConfig,
        ...guards
      };
      activities = activities || {};
      activities = { ...activitiesConfig,
        ...activities
      };
      delays = delays || {};
      delays = { ...delayConfig,
        ...delays
      }; // @ts-ignore

      this[MACHINE] = (0, _xstate.Machine)(chart).withContext(context).withConfig({
        guards,
        actions,
        activities,
        delays
      });
      this[INTERPRETER] = (0, _xstate.interpret)(this[MACHINE], {
        devTools: false
        /* DEBUG */
        ,
        clock: {
          setTimeout(fn, ms) {
            return Ember.run.later.call(null, fn, ms);
          },

          clearTimeout(timer) {
            return Ember.run.cancel.call(null, timer);
          }

        }
      });
      this[INTERPRETER].onTransition(state => {
        this.state = state;
      });

      if (onTransition) {
        this[INTERPRETER].onTransition(onTransition);
      }

      this[INTERPRETER].start();
    }

    teardown() {
      if (!this[INTERPRETER]) return;
      this[INTERPRETER].stop();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "send", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class));
  _exports.Statechart = Statechart;
});