define("cc-frontend/models/date-annotation-planbook-custom-range-attributes", ["exports", "cc-frontend/lib/structor"], function (_exports, _structor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateAnnotationPlanbookCustomRangeAttributes extends _structor.default {
    defineStruct() {
      this.field("title");
      this.field("description", "<p></p>");
      this.field("startDate");
      this.field("endDate");
      this.field("isOff");
    }

  }

  _exports.default = DateAnnotationPlanbookCustomRangeAttributes;
});