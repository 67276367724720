define("cc-frontend/lib/actions/lesson/LESSON_CLEAR", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareLessonClear = prepareLessonClear;
  _exports.default = _exports.LESSON_CLEAR = void 0;

  function prepareLessonClear(payload) {
    return {
      name: LESSON_CLEAR.name,
      payload: payload,
      patches: LESSON_CLEAR.patches(payload),
      undoPatches: LESSON_CLEAR.undoPatches(payload),
      narrative: (0, _lodash.partial)(LESSON_CLEAR.narrative, payload)
    };
  }

  const LESSON_CLEAR = {
    name: "LESSON_CLEAR",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", "").set("attributes.cards", []).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", payload.originalLessonTitle).set("attributes.cards", JSON.parse(JSON.stringify(payload.originalCards))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let lessonTitle = payload.originalLessonTitle;
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      return {
        title: "Deleted a lesson",
        titleForAnalytics: "Lesson - Delete",
        description: `Deleted "${lessonTitle}"`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models, "deleted")
      };
    }

  };
  _exports.LESSON_CLEAR = LESSON_CLEAR;
  var _default = LESSON_CLEAR;
  _exports.default = _default;
});