define("cc-frontend/components/planner/card-stacks/lesson/provider", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Course::Date::CourseDateCombined
    @course={{@course}}
    @planbook={{@planbook}}
    @rotationCalendar={{@rotationCalendar}}
    @dateString={{@dateString}}
    as |courseDate|
  >
    <Planner::CardStacks::Provider
      @ownerId={{@course.id}}
      @cardStackId={{courseDate.cardStackId}}
      @phantomCardStackId={{courseDate.phantomCardStackId}}
      @cardStackTemplateId={{courseDate.cardStackTemplateId}}
      @usedAs="lesson"
      @useStudentVersion={{@useStudentVersion}}
      @useCardStackSummary={{@useCardStackSummary}}
      @unitIds={{courseDate.unitIds}}
      @dateString={{@dateString}}
      @defaultPermissions={{@course.meta.permissions}}
      @relationships={{array (array "course" @course.id) (array "planbook" @planbook.id)}}
      as |cardStackIsFulfilled cardStack units|
    >
      {{yield courseDate cardStack units cardStackIsFulfilled}}
    </Planner::CardStacks::Provider>
  </Planner::Course::Date::CourseDateCombined>
  */
  {
    "id": "ebQKWnR1",
    "block": "[[[8,[39,0],null,[[\"@course\",\"@planbook\",\"@rotationCalendar\",\"@dateString\"],[[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@ownerId\",\"@cardStackId\",\"@phantomCardStackId\",\"@cardStackTemplateId\",\"@usedAs\",\"@useStudentVersion\",\"@useCardStackSummary\",\"@unitIds\",\"@dateString\",\"@defaultPermissions\",\"@relationships\"],[[30,1,[\"id\"]],[30,5,[\"cardStackId\"]],[30,5,[\"phantomCardStackId\"]],[30,5,[\"cardStackTemplateId\"]],\"lesson\",[30,6],[30,7],[30,5,[\"unitIds\"]],[30,4],[30,1,[\"meta\",\"permissions\"]],[28,[37,2],[[28,[37,2],[\"course\",[30,1,[\"id\"]]],null],[28,[37,2],[\"planbook\",[30,2,[\"id\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[18,11,[[30,5],[30,9],[30,10],[30,8]]],[1,\"\\n  \"]],[8,9,10]]]]],[1,\"\\n\"]],[5]]]]]],[\"@course\",\"@planbook\",\"@rotationCalendar\",\"@dateString\",\"courseDate\",\"@useStudentVersion\",\"@useCardStackSummary\",\"cardStackIsFulfilled\",\"cardStack\",\"units\",\"&default\"],false,[\"planner/course/date/course-date-combined\",\"planner/card-stacks/provider\",\"array\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/lesson/provider.hbs",
    "isStrictMode": false
  });

  class LessonProviderComponent extends _component.default {}

  _exports.default = LessonProviderComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LessonProviderComponent);
});