define("cc-frontend/routes/planbook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlanbookRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, (_class = class PlanbookRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "finder", _descriptor2, this);

      _initializerDefineProperty(this, "fastboot", _descriptor3, this);

      _initializerDefineProperty(this, "headData", _descriptor4, this);
    }

    model(params) {
      // We need to move this to the application model hook
      // also, check if there's any queries being fetched
      return new Ember.RSVP.Promise((resolve, reject) => {
        Ember.get(this, "store").find("planbook", params.planbook_id).then(planbook => {
          // Eventually, we'll do this:
          // if (
          //   planbook.attributes.publicAccess &&
          //   planbook.attributes.publicAccess.requiresLink === false &&
          //   planbook.attributes.publicAccess.roles.length > 0
          // ) {
          //   set(this, "headData.robots", "all")
          // } else {
          //   set(this, "headData.robots", "noindex")
          // }
          // Until then:
          Ember.set(this, "headData.robots", "none");

          if (planbook === undefined) {
            return reject({
              status: "404",
              planbookId: params.planbook_id
            });
          }

          return Ember.get(this, "store").find("rotation-calendar", planbook.relationships.rotationCalendar.data.id).then(rotationCalendar => {
            resolve({
              planbook,
              rotationCalendar
            });
          });
        });
      });
    }

    error(error, _transition) {
      if (error.status === "404") {
        console.log(`Planbook was not found with ID "${error.planbookId}"`);

        if (Ember.get(this, "fastboot") && Ember.get(this, "fastboot.isFastBoot")) {
          this.set("fastboot.response.statusCode", 404);
        }

        this.replaceWith("not-found", "planbook-not-found");
      } else {
        // Let the route above this handle the error.
        return true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "finder", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = PlanbookRoute;
});