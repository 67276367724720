define("cc-frontend/components/planner/menus/add-menu-but-nope.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "notice": "_notice_1lfdb7",
    "button-row": "_button-row_1lfdb7"
  };
  _exports.default = _default;
});