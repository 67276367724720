define("cc-frontend/components/planner/template-chooser/choose-from-gallery/lesson.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "template-box": "_template-box_masbxg",
    "template-box__header": "_template-box__header_masbxg",
    "template-box-button": "_template-box-button_masbxg d-s-shadowed-button",
    "template-box__top__group-label": "_template-box__top__group-label_masbxg"
  };
  _exports.default = _default;
});