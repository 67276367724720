define("cc-frontend/templates/why-cc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TgJwbcpb",
    "block": "[[[1,[28,[35,0],[\"Why Cc? | Planbook & Lesson Planner. Free for Teachers. Common Curriculum\"],[[\"replace\"],[true]]]],[1,\"\\n\"],[8,[39,1],null,[[\"@title\"],[\"marketing.why-cc\"]],null],[1,\"\\n\"],[8,[39,2],null,null,null],[1,\"\\n\"],[8,[39,3],null,[[\"@prismicId\"],[[30,0,[\"prismicId\"]]]],null]],[],false,[\"page-title\",\"record-page-visit\",\"home-page/navbar\",\"home-page/marketing/why-cc\"]]",
    "moduleName": "cc-frontend/templates/why-cc.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});