define("cc-frontend/components/d-s/dropdown-menu", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    local-class="dropdown-menu {{this.arrowClass}} dropdown-menu--columns-{{@iconColumns}}"
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "DT9zeO3M",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[[28,[37,1],[\"dropdown-menu \",[30,0,[\"arrowClass\"]],\" dropdown-menu--columns-\",[30,1]],null]],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu.css\"]]]]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"@iconColumns\",\"&attrs\",\"&default\"],false,[\"local-class\",\"concat\",\"yield\"]]",
    "moduleName": "cc-frontend/components/d-s/dropdown-menu.hbs",
    "isStrictMode": false
  });

  let DropdownMenu = (_dec = Ember.computed("arrowPlacement"), (_class = class DropdownMenu extends _glimmerComponent.default {
    get arrowClass() {
      // if (this.arrowPlacement === "NONE") {
      //   return ""
      // } else if (this.arrowPlacement === "TOP_CENTER") {
      //   return "--arrow --arrow-center"
      // } else if (this.arrowPlacement === "TOP_RIGHT") {
      //   return "--arrow --arrow-right"
      // } else {
      //   return "--arrow --arrow-right"
      // }
      if (this.args.arrowPlacement === "NONE") {
        return "";
      } else if (this.args.arrowPlacement === "TOP_CENTER") {
        return "--arrow --arrow-center";
      } else if (this.args.arrowPlacement === "BOTTOM_RIGHT") {
        return "--arrow --arrow-bottom-right";
      } else if (this.args.arrowPlacement === "MIDDLE_RIGHT") {
        return "--arrow --arrow-middle-right";
      } else {
        return "--arrow --arrow-else-right";
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "arrowClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "arrowClass"), _class.prototype)), _class));
  _exports.default = DropdownMenu;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DropdownMenu);
});