define("cc-frontend/components/d-s/toggle.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "toggle": "_toggle_ccoqgv",
    "--white-circle": "_--white-circle_ccoqgv"
  };
  _exports.default = _default;
});