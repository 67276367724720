define("cc-frontend/components/planner/menus/day-menu/change-rotation", ["exports", "@glimmer/component", "lodash-es"], function (_exports, _component, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToElsewhere
    @named="day-menu-title"
    @send={{component
      "planner/menus/day-menu/change-rotation/title"
      goBack=@goBack
      title="Change the rotation"
    }}
  />
  
  <ToElsewhere
    @named="day-menu-body"
    @send={{component
      "planner/menus/day-menu/change-rotation/body"
      rotationCalendar=@rotationCalendar
      rotationCycleResetsOnDay=@rotationCycleResetsOnDay
      planbook=@planbook
      courses=@courses
      dateString=@dateString
      changeRotation=this.changeRotation
    }}
  />
  */
  {
    "id": "z7bYDR7/",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"day-menu-title\",[50,\"planner/menus/day-menu/change-rotation/title\",0,null,[[\"goBack\",\"title\"],[[30,1],\"Change the rotation\"]]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@named\",\"@send\"],[\"day-menu-body\",[50,\"planner/menus/day-menu/change-rotation/body\",0,null,[[\"rotationCalendar\",\"rotationCycleResetsOnDay\",\"planbook\",\"courses\",\"dateString\",\"changeRotation\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,0,[\"changeRotation\"]]]]]]],null]],[\"@goBack\",\"@rotationCalendar\",\"@rotationCycleResetsOnDay\",\"@planbook\",\"@courses\",\"@dateString\"],false,[\"to-elsewhere\",\"component\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/change-rotation.hbs",
    "isStrictMode": false
  });

  let ChangeRotationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class ChangeRotationComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "rotationEditor", _descriptor, this);

      _initializerDefineProperty(this, "notifier", _descriptor2, this);
    }

    changeRotation(newRotationId) {
      this.rotationEditor.changeRotationIdOnDay({
        dateString: this.args.dateString,
        rotationCalendar: this.args.rotationCalendar,
        courses: this.args.courses,
        planbook: this.args.planbook,
        newRotationId: newRotationId
      });
      this.args.closeMenu();
      let formattedDate = dateFns.format(dateFns.parse(this.args.dateString), "MMM Do, YYYY");
      let rotation = (0, _lodashEs.find)(this.args.rotationCalendar.attributes.rotations, r => r.id === newRotationId);
      this.notifier.success("", {
        title: `${formattedDate} was changed to a ${rotation === null || rotation === void 0 ? void 0 : rotation.title}`,
        message: "Click the Undo Button to undo this.",
        duration: 5000
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rotationEditor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifier", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeRotation", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeRotation"), _class.prototype)), _class));
  _exports.default = ChangeRotationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ChangeRotationComponent);
});