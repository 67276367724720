define("cc-frontend/templates/admin/groups-old-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sFXpTOcs",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"tw-container tw-mx-auto tw-flex tw-flex-col tw-h-screen tw-pt-8\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"tw-text-4xl tw-font-extrabold tw-pl-4 tw-pb-8 tw-m-0\"],[12],[1,\"\\n      Groups\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,2],null,[[\"@class\"],[\"tw-flex tw-flex-row tw-h-full tw-w-full\"]],null],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"admin/group/index-table\"]]",
    "moduleName": "cc-frontend/templates/admin/groups-old-admin.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});