define("cc-frontend/components/dialog-manager", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.dialog.isOpen}}
    <Portal @target="dialog-portal">
      {{! <div class="tw-absolute tw-pin"> }}
      <div
        class="ember-dialog-dialog
          {{this.dialog.options.className}}
          {{if this.dialog.options.substrate "substrate"}}
          js-opened-from-menu"
        style="z-index: 999999999999"
      >
        <span class="btn-exit-modal-string" {{on "click" this.decline}}>
          Close
          <span class="close-modal-times">
            &times;
          </span>
        </span>
        <div class="dialog-content" tabindex="-1">
          <div class="dialog-body">
            {{! I have no idea what this error means. I think it's because it's not typing component correctly}}
            {{component
              this.dialog.templateName
              options=this.dialog.options
              accept=this.accept
              decline=this.decline
            }}
          </div>
        </div>
      </div>
      {{! </div> }}
    </Portal>
  {{/if}}
  */
  {
    "id": "Qqwfro4p",
    "block": "[[[41,[30,0,[\"dialog\",\"isOpen\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@target\"],[\"dialog-portal\"]],[[\"default\"],[[[[1,\"\\n\"],[1,\"    \"],[10,0],[15,0,[29,[\"ember-dialog-dialog\\n        \",[30,0,[\"dialog\",\"options\",\"className\"]],\"\\n        \",[52,[30,0,[\"dialog\",\"options\",\"substrate\"]],\"substrate\"],\"\\n        js-opened-from-menu\"]]],[14,5,\"z-index: 999999999999\"],[12],[1,\"\\n      \"],[11,1],[24,0,\"btn-exit-modal-string\"],[4,[38,2],[\"click\",[30,0,[\"decline\"]]],null],[12],[1,\"\\n        Close\\n        \"],[10,1],[14,0,\"close-modal-times\"],[12],[1,\"\\n          ×\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"dialog-content\"],[14,\"tabindex\",\"-1\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"dialog-body\"],[12],[1,\"\\n\"],[1,\"          \"],[46,[30,0,[\"dialog\",\"templateName\"]],null,[[\"options\",\"accept\",\"decline\"],[[30,0,[\"dialog\",\"options\"]],[30,0,[\"accept\"]],[30,0,[\"decline\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"portal\",\"on\",\"component\"]]",
    "moduleName": "cc-frontend/components/dialog-manager.hbs",
    "isStrictMode": false
  });

  let DialogManager = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class DialogManager extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "dialog", _descriptor2, this);
    }

    accept() {
      Ember.get(this, "dialog").accept(...arguments);
    }

    decline() {
      Ember.get(this, "dialog").decline(...arguments);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "accept", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "accept"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decline", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "decline"), _class.prototype)), _class));
  _exports.default = DialogManager;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DialogManager);
});