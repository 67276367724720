define("cc-frontend/components/icons/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    {{! Pencil 2 }}
    <path
      d="M0.498,24.004c-0.134,0 -0.259,-0.052 -0.354,-0.146c-0.131,-0.131 -0.179,-0.323 -0.124,-0.501l2,-6.5c0.004,-0.013 0.009,-0.026 0.016,-0.038c0.024,-0.062 0.061,-0.12 0.108,-0.168l15.917,-15.918c0.472,-0.469 1.1,-0.727 1.768,-0.727c0.668,0 1.295,0.258 1.766,0.726l1.673,1.673c0.974,0.975 0.974,2.56 0,3.535l-15.917,15.917c-0.041,0.041 -0.089,0.073 -0.147,0.1c-0.004,0.002 -0.023,0.01 -0.027,0.012c-0.001,0.001 -0.016,0.007 -0.031,0.012l-6.502,2.001c-0.048,0.015 -0.097,0.022 -0.146,0.022Zm0.756,-1.256l1.362,-0.419l-0.943,-0.943l-0.419,1.362Zm2.443,-0.752l2.36,-0.726l-0.413,-0.413c-0.094,-0.094 -0.146,-0.22 -0.146,-0.354v-2h-2c-0.134,0 -0.259,-0.052 -0.354,-0.146l-0.413,-0.413l-0.726,2.36l1.692,1.692Zm3.301,-1.199l12.123,-12.124l-1.543,-1.543l-11.08,11.081v2.086l0.5,0.5Zm-1.207,-3.293l11.08,-11.081l-1.543,-1.543l-12.123,12.124l0.5,0.5h2.086Zm14.037,-9.538l0.793,-0.793l-3.793,-3.793l-0.793,0.793l3.793,3.793Zm1.5,-1.5l1.233,-1.233c0.584,-0.585 0.584,-1.536 0,-2.121l-1.673,-1.672c-0.282,-0.28 -0.659,-0.434 -1.06,-0.434c-0.402,0 -0.779,0.155 -1.062,0.436l-1.231,1.231l3.793,3.793Z"
    ></path>
  </svg>
  */
  {
    "id": "cEBVI1bI",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n\"],[1,\"  \"],[10,\"path\"],[14,\"d\",\"M0.498,24.004c-0.134,0 -0.259,-0.052 -0.354,-0.146c-0.131,-0.131 -0.179,-0.323 -0.124,-0.501l2,-6.5c0.004,-0.013 0.009,-0.026 0.016,-0.038c0.024,-0.062 0.061,-0.12 0.108,-0.168l15.917,-15.918c0.472,-0.469 1.1,-0.727 1.768,-0.727c0.668,0 1.295,0.258 1.766,0.726l1.673,1.673c0.974,0.975 0.974,2.56 0,3.535l-15.917,15.917c-0.041,0.041 -0.089,0.073 -0.147,0.1c-0.004,0.002 -0.023,0.01 -0.027,0.012c-0.001,0.001 -0.016,0.007 -0.031,0.012l-6.502,2.001c-0.048,0.015 -0.097,0.022 -0.146,0.022Zm0.756,-1.256l1.362,-0.419l-0.943,-0.943l-0.419,1.362Zm2.443,-0.752l2.36,-0.726l-0.413,-0.413c-0.094,-0.094 -0.146,-0.22 -0.146,-0.354v-2h-2c-0.134,0 -0.259,-0.052 -0.354,-0.146l-0.413,-0.413l-0.726,2.36l1.692,1.692Zm3.301,-1.199l12.123,-12.124l-1.543,-1.543l-11.08,11.081v2.086l0.5,0.5Zm-1.207,-3.293l11.08,-11.081l-1.543,-1.543l-12.123,12.124l0.5,0.5h2.086Zm14.037,-9.538l0.793,-0.793l-3.793,-3.793l-0.793,0.793l3.793,3.793Zm1.5,-1.5l1.233,-1.233c0.584,-0.585 0.584,-1.536 0,-2.121l-1.673,-1.672c-0.282,-0.28 -0.659,-0.434 -1.06,-0.434c-0.402,0 -0.779,0.155 -1.062,0.436l-1.231,1.231l3.793,3.793Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/pencil.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});