define("cc-frontend/lib/actions/course/date/default-course-date-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(payload, models) {
    return {
      courseTitle: Ember.get(models, "course.attributes.title"),
      planbookTitle: Ember.get(models, "planbook.attributes.title"),
      courseId: Ember.get(models, "course.id"),
      planbookId: Ember.get(models, "planbook.id"),
      userId: Ember.get(models, "user.id"),
      calledFrom: payload.calledFrom ? payload.calledFrom : null,
      date: payload.annotation ? payload.annotation.attributes.dateString : payload.dateString ? payload.dateString : null,
      annotationId: Ember.get(payload, "annotation.id")
    };
  }
});