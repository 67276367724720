define("cc-frontend/components/planner/card-stacks/unit.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-outer": "_lesson-outer_jvyttc",
    "lesson": "_lesson_jvyttc"
  };
  _exports.default = _default;
});