define("cc-frontend/services/google-picker-auth", ["exports", "cc-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GooglePickerAuth = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class GooglePickerAuth extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "pickerLoaded", false);

      _defineProperty(this, "gisInited", false);

      _defineProperty(this, "accessToken", null);

      _defineProperty(this, "tokenClient", null);

      _defineProperty(this, "promiseResolve", null);

      _defineProperty(this, "promiseReject", null);

      _defineProperty(this, "tokenExpiresAt", null);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "fastboot", _descriptor2, this);

      if (this.fastboot.isFastBoot) return; // load gapi

      let gapiScript = document.createElement("script");
      gapiScript.setAttribute("src", "https://apis.google.com/js/api.js");
      gapiScript.addEventListener("load", () => {
        // load the picker api
        window.gapi.load("picker", () => {
          this.pickerLoaded = true;
        });
      });
      document.body.appendChild(gapiScript); // load gsi

      let gsiScript = document.createElement("script");
      gsiScript.setAttribute("src", "https://accounts.google.com/gsi/client");
      gsiScript.addEventListener("load", () => {
        var _google, _google$accounts, _google$accounts$oaut;

        this.tokenClient = (_google = google) === null || _google === void 0 ? void 0 : (_google$accounts = _google.accounts) === null || _google$accounts === void 0 ? void 0 : (_google$accounts$oaut = _google$accounts.oauth2) === null || _google$accounts$oaut === void 0 ? void 0 : _google$accounts$oaut.initTokenClient({
          client_id: _environment.default.GOOGLE_DEVELOPERS_CLIENT_ID,
          scope: "https://www.googleapis.com/auth/drive.file",
          prompt: "",
          callback: async response => {
            var _this$promiseResolve;

            if (response.error !== undefined) {
              var _this$promiseReject;

              (_this$promiseReject = this.promiseReject) === null || _this$promiseReject === void 0 ? void 0 : _this$promiseReject.call(this, response.error);
            }

            this.accessToken = response.access_token;
            this.tokenExpiresAt = new Date();
            this.tokenExpiresAt.setSeconds(this.tokenExpiresAt.getSeconds() + Number.parseInt(response.expires_in));
            (_this$promiseResolve = this.promiseResolve) === null || _this$promiseResolve === void 0 ? void 0 : _this$promiseResolve.call(this, response.access_token);
          }
        });
        this.gisInited = true;
      }); // append gsi script to body

      document.body.appendChild(gsiScript);
    }

    requestAccessToken() {
      let isExpired = this.tokenExpiresAt !== null && this.tokenExpiresAt < new Date();

      if (this.tokenClient === null) {
        window.alert("Google Picker wasn't able to load correctly. Try refreshing the page.");
        return new Promise(resolve => {
          // will be null
          resolve(this.accessToken);
        });
      } // check if no acess token or is expired


      if (this.accessToken === null || isExpired) {
        var _this$tokenClient;

        (_this$tokenClient = this.tokenClient) === null || _this$tokenClient === void 0 ? void 0 : _this$tokenClient.requestAccessToken({
          prompt: ""
        });
        return new Promise((resolve, reject) => {
          this.promiseResolve = resolve;
          this.promiseReject = reject;
        });
      } else {
        return new Promise((resolve, reject) => {
          resolve(this.accessToken);
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GooglePickerAuth;
});