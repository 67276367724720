define("cc-frontend/templates/icons/-ios7-cloud-download-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cy8bgEqh",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"stroke-width\",\"16\"],[14,\"stroke-miterlimit\",\"10\"],[14,\"d\",\"M186.4 408.3l-12.7 12.5 81.5 80.2 81.5-80.2-12.7-12.5-59.8 58.8v-264.6h-17.9v264.7l-59.9-58.9zm225.3-237l.2-3.8c0-69.4-57.8-125.6-127.7-125.6-50.4 0-93.8 29.3-114.1 71.5-8.9-4.4-18.7-6.9-29.3-6.9-32.4 0-59.1 25.5-64.4 56.1-38.5 13.1-66.3 47.4-66.3 90.1 0 53.7 43.8 97.5 98 97.5h100v-17.5h-99.2c-44.7 0-81.1-36.3-81.1-80.6 0-34.3 22.1-62.9 54.9-74l9.2-3.1 1.6-9.5c3.9-23.3 24.2-42.4 48-42.4 7.5 0 15 1.7 21.8 5.1l14.8 7.3 7.1-14.9c18.1-37.6 57-61.9 98.8-61.9 60.5 0 110.3 46.8 110.3 106.7 0 14.3-.2 22-.2 22l16.6.1c40.1.5 71.7 32.8 71.7 72.6 0 39.7-32.6 72.3-72.7 72.5l-3.5.1h-106.3v17.6h110.4c49.2 0 89.5-40.7 89.5-89.8.1-49.2-38.6-89.1-88.1-89.2z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-ios7-cloud-download-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});