define("cc-frontend/components/planner/perspectives/timeline/wrapper", ["exports", "@ember-decorators/component", "cc-frontend/helpers/has-many"], function (_exports, _component, _hasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimelineWrapperComponent = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{!-- <div class='btn-group'>
      <div class="btn btn-default" {{action "decWidth"}}>-</div>
      <input value={{dateWidth}} onkeyup={{action "updateDateWidth" value="target.value"}}>
      <div class="btn btn-default" {{action "incWidth"}}>+</div>
      <div class="btn btn-default" {{action "updateDateWidth" 8}}>Year</div>
      <div class="btn btn-default" {{action "updateDateWidth" 18}}>Quarter</div>
      <div class="btn btn-default" {{action "updateDateWidth" 36}}>Month</div>
      <div class="btn btn-default" {{action "updateDateWidth" 60}}>2 Weeks</div>
    </div> --}}
  
    {{#if showUnits}}
      <Planner::Perspectives::Timeline::Timelines
        @selectedDate={{date}}
        @rotationCalendar={{rotationCalendar}}
        @planbook={{planbook}}
        @courses={{courses}}
        @dateWidth={{dateWidth}}
        @barHeight={{barHeight}}
        @timelineOffset={{index}}
        @additionalOffset={{dateBarHeight}}
        @isAddingUnits={{eq isAddingUnitsFor course.id}}
        @clickedDate={{action "clickedDate"}}
        @useModalManager={{useModalManager}}
        @useOutlineLink={{useOutlineLink}}
        @editCourseTitleAndOrder={{editCourseTitleAndOrder}}
      />
    {{/if}}
  
  */
  {
    "id": "hK5dGRLn",
    "block": "[[[1,\"\\n\"],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[8,[39,2],null,[[\"@selectedDate\",\"@rotationCalendar\",\"@planbook\",\"@courses\",\"@dateWidth\",\"@barHeight\",\"@timelineOffset\",\"@additionalOffset\",\"@isAddingUnits\",\"@clickedDate\",\"@useModalManager\",\"@useOutlineLink\",\"@editCourseTitleAndOrder\"],[[36,3],[36,4],[36,5],[36,6],[36,7],[36,8],[36,9],[36,10],[28,[37,11],[[33,12],[33,13,[\"id\"]]],null],[28,[37,14],[[30,0],\"clickedDate\"],null],[36,15],[36,16],[36,17]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showUnits\",\"planner/perspectives/timeline/timelines\",\"date\",\"rotationCalendar\",\"planbook\",\"courses\",\"dateWidth\",\"barHeight\",\"index\",\"dateBarHeight\",\"eq\",\"isAddingUnitsFor\",\"course\",\"action\",\"useModalManager\",\"useOutlineLink\",\"editCourseTitleAndOrder\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _hasMany.default)("planbook", "course", "courses"), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec(_class = (_class2 = class TimelineWrapperComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);

      _defineProperty(this, "barHeight", 40);

      _defineProperty(this, "dateWidth", 20);

      _defineProperty(this, "dateBarHeight", 55);

      _initializerDefineProperty(this, "courses", _descriptor3, this);

      _defineProperty(this, "showUnits", false);
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      Ember.run.later(() => {
        if (this.isDestroyed || this.isDestroying) return;
        Ember.set(this, "showUnits", true);
      }, 15);
    }

    clickedDate(dateString) {
      Ember.set(this, "date", dateString);
    }

    updateDateWidth(width) {
      Ember.run.debounce(this, () => Ember.set(this, "dateWidth", width), 15);
    }

    incWidth() {
      Ember.set(this, "dateWidth", Math.floor(Ember.get(this, "dateWidth")) + 1);
    }

    decWidth() {
      Ember.set(this, "dateWidth", Math.floor(Ember.get(this, "dateWidth")) - 1);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appState", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "courses", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "clickedDate", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "clickedDate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateDateWidth", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "updateDateWidth"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "incWidth", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "incWidth"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "decWidth", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "decWidth"), _class2.prototype)), _class2)) || _class);
  _exports.default = TimelineWrapperComponent;
});