define("cc-frontend/components/kb/entry/section", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/has-many-altogether", "ember-could-get-used-to-this"], function (_exports, _glimmerComponent, _hasManyAltogether, _emberCouldGetUsedToThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="kb-section take-course-background-color {{@entry.attributes.color}}" ...attributes>
    <h2 class="kb-section__title" id={{@entry.id}}>{{@entry.attributes.title}}</h2>
    <div class="kb-section__article-list take-course-background-color {{@entry.attributes.color}}">
      {{#each this.articleWrappers.content as |child|}}
        <LinkTo
          @route="help.article"
          @model={{kb-link-id child.attributes.title child.id}}
          class={{concat
            "kb-section__article-list-article take-course-background-color-on-hover take-course-color take-course-fill-color"
            @entry.attributes.color
          }}
        >
          <div class="kb-section__article-list-article-title">
            {{child.attributes.title}}
          </div>
          <div class="kb-section__article-list-article-arrow">
  
            <Icons::IosArrowRight />
          </div>
  
          <div class="kb-section__article-list-article-subtitle">
            {{child.attributes.subtitle}}
          </div>
        </LinkTo>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "1R1A5M8R",
    "block": "[[[11,0],[16,0,[29,[\"kb-section take-course-background-color \",[30,1,[\"attributes\",\"color\"]]]]],[17,2],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"kb-section__title\"],[15,1,[30,1,[\"id\"]]],[12],[1,[30,1,[\"attributes\",\"title\"]]],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"kb-section__article-list take-course-background-color \",[30,1,[\"attributes\",\"color\"]]]]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"articleWrappers\",\"content\"]]],null]],null],null,[[[1,\"      \"],[8,[39,2],[[16,0,[28,[37,3],[\"kb-section__article-list-article take-course-background-color-on-hover take-course-color take-course-fill-color\",[30,1,[\"attributes\",\"color\"]]],null]]],[[\"@route\",\"@model\"],[\"help.article\",[28,[37,4],[[30,3,[\"attributes\",\"title\"]],[30,3,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"kb-section__article-list-article-title\"],[12],[1,\"\\n          \"],[1,[30,3,[\"attributes\",\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"kb-section__article-list-article-arrow\"],[12],[1,\"\\n\\n          \"],[8,[39,5],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"kb-section__article-list-article-subtitle\"],[12],[1,\"\\n          \"],[1,[30,3,[\"attributes\",\"subtitle\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@entry\",\"&attrs\",\"child\"],false,[\"each\",\"-track-array\",\"link-to\",\"concat\",\"kb-link-id\",\"icons/ios-arrow-right\"]]",
    "moduleName": "cc-frontend/components/kb/entry/section.hbs",
    "isStrictMode": false
  });

  let KbEntrySection = (_dec = Ember.inject.service, (_class = class KbEntrySection extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "articleWrappers", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "articleWrappers", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _hasManyAltogether.default(() => [this.args.entry, "children", "kb-entry"]);
    }
  })), _class));
  _exports.default = KbEntrySection;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KbEntrySection);
});