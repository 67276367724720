define("cc-frontend/components/user/select-create-or-copy-planbook", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Icons::IosPlus />
  <div class="tw-hidden sm:tw-block">
    Create a Planbook
  </div>
  */
  {
    "id": "DptMGqWM",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"tw-hidden sm:tw-block\"],[12],[1,\"\\n  Create a Planbook\\n\"],[13]],[],false,[\"icons/ios-plus\"]]",
    "moduleName": "cc-frontend/components/user/select-create-or-copy-planbook.hbs",
    "isStrictMode": false
  });

  let SelectCreateOrCopyPlanbookComponent = (_dec = (0, _component.tagName)(""), _dec(_class = class SelectCreateOrCopyPlanbookComponent extends Ember.Component {}) || _class);
  _exports.default = SelectCreateOrCopyPlanbookComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SelectCreateOrCopyPlanbookComponent);
});