define("cc-frontend/components/icons/happy-face-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <circle cx="32" cy="32" r="30" fill="#ffdd67"></circle>
    <path
      fill="#664e27"
      d="M49.7 34.4c-.4-.5-1.1-.4-1.9-.4H16.2c-.8 0-1.5-.1-1.9.4C10.4 39.4 15 54 32 54s21.6-14.6 17.7-19.6"
    ></path>
    <path
      fill="#4c3526"
      d="M33.8 41.7c-.6 0-1.5.5-1.1 2 .2.7 1.2 1.6 1.2 2.8 0 2.4-3.8 2.4-3.8 0 0-1.2 1-2 1.2-2.8.3-1.4-.6-2-1.1-2-1.6 0-4.1 1.7-4.1 4.6 0 3.2 2.7 5.8 6 5.8s6-2.6 6-5.8c-.1-2.8-2.7-4.5-4.3-4.6"
    ></path>
    <path
      fill="#ff717f"
      d="M24.3 50.7c2.2 1 4.8 1.5 7.7 1.5 2.9 0 5.5-.6 7.7-1.5C37.6 49.6 35 49 32 49s-5.6.6-7.7 1.7"
    ></path>
    <path fill="#fff" d="M47 36H17.1c-2.1 0-2.1 4-.1 4h30c2 0 2-4 0-4"></path>
    <g fill="#664e27">
      <circle cx="20.5" cy="23" r="5"></circle>
      <circle cx="43.5" cy="23" r="5"></circle>
    </g>
  </svg>
  */
  {
    "id": "D/r0KD0y",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 64 64\"],[12],[1,\"\\n  \"],[10,\"circle\"],[14,\"cx\",\"32\"],[14,\"cy\",\"32\"],[14,\"r\",\"30\"],[14,\"fill\",\"#ffdd67\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#664e27\"],[14,\"d\",\"M49.7 34.4c-.4-.5-1.1-.4-1.9-.4H16.2c-.8 0-1.5-.1-1.9.4C10.4 39.4 15 54 32 54s21.6-14.6 17.7-19.6\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#4c3526\"],[14,\"d\",\"M33.8 41.7c-.6 0-1.5.5-1.1 2 .2.7 1.2 1.6 1.2 2.8 0 2.4-3.8 2.4-3.8 0 0-1.2 1-2 1.2-2.8.3-1.4-.6-2-1.1-2-1.6 0-4.1 1.7-4.1 4.6 0 3.2 2.7 5.8 6 5.8s6-2.6 6-5.8c-.1-2.8-2.7-4.5-4.3-4.6\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#ff717f\"],[14,\"d\",\"M24.3 50.7c2.2 1 4.8 1.5 7.7 1.5 2.9 0 5.5-.6 7.7-1.5C37.6 49.6 35 49 32 49s-5.6.6-7.7 1.7\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#fff\"],[14,\"d\",\"M47 36H17.1c-2.1 0-2.1 4-.1 4h30c2 0 2-4 0-4\"],[12],[13],[1,\"\\n  \"],[10,\"g\"],[14,\"fill\",\"#664e27\"],[12],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"20.5\"],[14,\"cy\",\"23\"],[14,\"r\",\"5\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"43.5\"],[14,\"cy\",\"23\"],[14,\"r\",\"5\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/happy-face-color.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});