define("cc-frontend/components/group/select-search", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/utils/parse-emails", "js-search", "lodash-es", "tracked-toolbox"], function (_exports, _glimmerComponent, _parseEmails, JsSearch, _lodashEs, _trackedToolbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.search}}
    <PowerSelectMultipleWithCreate
      @options={{@users}}
      @animationEnabled={{false}}
      @matcher={{this.userMatcher}}
      @searchIndex={{this.search}}
      @dropdownClass={{@dropdownClass}}
      @triggerClass={{@triggerClass}}
      @onChange={{@onchange}}
      @onCreate={{@oncreate}}
      @showCreateWhen={{@hideCreateOption}}
      @placeholder="Type a name or email. Separate multiple emails with commas"
      @searchMessage="Type an email. Separate multiple emails with commas"
      @selected={{this.selected}}
      as |userSummary|
    >
      <User::AvatarWithName @userSummary={{userSummary}} />
    </PowerSelectMultipleWithCreate>
  {{else}}
    <DS::ThreeDotSpinner />
  {{/if}}
  */
  {
    "id": "IByr9t34",
    "block": "[[[41,[30,0,[\"search\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@options\",\"@animationEnabled\",\"@matcher\",\"@searchIndex\",\"@dropdownClass\",\"@triggerClass\",\"@onChange\",\"@onCreate\",\"@showCreateWhen\",\"@placeholder\",\"@searchMessage\",\"@selected\"],[[30,1],false,[30,0,[\"userMatcher\"]],[30,0,[\"search\"]],[30,2],[30,3],[30,4],[30,5],[30,6],\"Type a name or email. Separate multiple emails with commas\",\"Type an email. Separate multiple emails with commas\",[30,0,[\"selected\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@userSummary\"],[[30,7]]],null],[1,\"\\n  \"]],[7]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]]]],[\"@users\",\"@dropdownClass\",\"@triggerClass\",\"@onchange\",\"@oncreate\",\"@hideCreateOption\",\"userSummary\"],false,[\"if\",\"power-select-multiple-with-create\",\"user/avatar-with-name\",\"d-s/three-dot-spinner\"]]",
    "moduleName": "cc-frontend/components/group/select-search.hbs",
    "isStrictMode": false
  });

  let UserSelectSearch = (_dec = Ember._action, (_class = class UserSelectSearch extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "selected", []);

      _defineProperty(this, "isOpen", false);
    }

    // lastSearchResult: { term: string | null; results: Array<string> | null } = {
    //   term: null,
    //   results: null,
    // }
    get search() {
      if (this.args.users === null) return null;
      let search = new JsSearch.Search("id");
      search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
      search.addIndex(["attributes", "email"]);
      search.addIndex(["attributes", "firstName"]);
      search.addIndex(["attributes", "lastName"]);
      search.addDocuments(this.args.users);
      return search;
    }

    userMatcher(user, term) {
      var _this$lastSearchResul;

      // `this` refers to the power-select component
      // We cache the results as we're fed each user -- so, we do a query on the first time the
      // query changes. Then, we just see if the doc is in the list of results
      if ((0, _lodashEs.isNil)(this.lastSearchResult) || this.lastSearchResult.term !== term) {
        this.lastSearchResult = {
          term: term,
          results: (0, _lodashEs.map)(this.searchIndex.search(term), user => user.id)
        };
      }

      return (_this$lastSearchResul = this.lastSearchResult.results) === null || _this$lastSearchResul === void 0 ? void 0 : _this$lastSearchResul.indexOf(user.id);
    }

    hideCreateOption(term) {
      let terms = (0, _parseEmails.default)(term);

      if (terms.length > 1) {
        return true;
      } else {
        return this.search.search(term).length === 0;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "search", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideCreateOption", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "hideCreateOption"), _class.prototype)), _class));
  _exports.default = UserSelectSearch;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UserSelectSearch);
});