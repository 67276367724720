define("cc-frontend/components/planner/template-chooser/select-courses-and-days", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="wrapper">
    <div local-class="heading">
      Select when it's used
    </div>
    <ul class="template-course-listing">
      {{#each @courses as |course|}}
        <li class="template-course-listing-item">
          <span class="template-course-listing-title">
            {{course.attributes.title}}
          </span>
          <ul class="template-rotation-toggles-row">
            {{#each (sort-by "position" @rotationCalendar.attributes.rotations) as |rotation|}}
              <TemplateMagicDate
                @cardStackId={{this.cardStackId}}
                @tagName="li"
                @rotation={{rotation}}
                @semester={{this.semester}}
                @course={{course}}
                @toggleTemplate={{action "toggleTemplate" course.id rotation.id}}
              />
  
            {{/each}}
          </ul>
        </li>
      {{/each}}
    </ul>
    <div
      local-class="order-footer__submit"
      class="sierra-button sierra-button--with-states sierra-button--blue-text sierra-button--xl sierra-button--gradient"
      {{on "click" @onNext}}
    >
      Next!
    </div>
  </div>
  */
  {
    "id": "LcUiWeH0",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"wrapper\"],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/select-courses-and-days.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"heading\"],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/select-courses-and-days.css\"]]]]]],[12],[1,\"\\n    Select when it's used\\n  \"],[13],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"template-course-listing\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"template-course-listing-item\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"template-course-listing-title\"],[12],[1,\"\\n          \"],[1,[30,2,[\"attributes\",\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"template-rotation-toggles-row\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[\"position\",[30,3,[\"attributes\",\"rotations\"]]],null]],null]],null],null,[[[1,\"            \"],[8,[39,4],null,[[\"@cardStackId\",\"@tagName\",\"@rotation\",\"@semester\",\"@course\",\"@toggleTemplate\"],[[30,0,[\"cardStackId\"]],\"li\",[30,4],[30,0,[\"semester\"]],[30,2],[28,[37,5],[[30,0],\"toggleTemplate\",[30,2,[\"id\"]],[30,4,[\"id\"]]],null]]],null],[1,\"\\n\\n\"]],[4]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n  \"],[11,0],[16,0,[29,[\"sierra-button sierra-button--with-states sierra-button--blue-text sierra-button--xl sierra-button--gradient \",[28,[37,0],[\"order-footer__submit\"],[[\"from\"],[\"cc-frontend/components/planner/template-chooser/select-courses-and-days.css\"]]]]]],[4,[38,6],[\"click\",[30,5]],null],[12],[1,\"\\n    Next!\\n  \"],[13],[1,\"\\n\"],[13]],[\"@courses\",\"course\",\"@rotationCalendar\",\"rotation\",\"@onNext\"],false,[\"local-class\",\"each\",\"-track-array\",\"sort-by\",\"template-magic-date\",\"action\",\"on\"]]",
    "moduleName": "cc-frontend/components/planner/template-chooser/select-courses-and-days.hbs",
    "isStrictMode": false
  });

  let TemplateChooser = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec(_class = (_class2 = class TemplateChooser extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "cardStackId", void 0);

      _defineProperty(this, "courses", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "semester", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    toggleTemplate(courseId, rotationId, dateId, currentTemplateId) {
      let cardStackId = Ember.get(this, "cardStackId");
      Ember.get(this, "store").dispatch("COURSE_ROTATION_SET_TEMPLATE_ID", {
        courseId: courseId,
        rotationId: rotationId,
        semesterId: this.semester.id,
        cardStackId: cardStackId === currentTemplateId ? null : cardStackId,
        currentTemplateId: currentTemplateId,
        dateId: dateId
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleTemplate", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleTemplate"), _class2.prototype)), _class2)) || _class);
  _exports.default = TemplateChooser;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TemplateChooser);
});