define("cc-frontend/models/hydrated-unit", ["exports", "cc-frontend/lib/structor_2"], function (_exports, _structor_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HydratedUnit extends _structor_.default {
    defineStruct() {
      return {
        id: null,
        startIndex: null,
        endIndex: null,
        isMoving: false,
        depth: null,
        startDate: null,
        endDate: null,
        ancestorIds: [],
        descendantIds: [],
        unitLength: null
      };
    }

  }

  _exports.default = HydratedUnit;
});