define("cc-frontend/components/icons/add-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!add circle }}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M12,23.5c-6.341,0 -11.5,-5.159 -11.5,-11.5c0,-6.341 5.159,-11.5 11.5,-11.5c6.341,0 11.5,5.159 11.5,11.5c0,6.341 -5.159,11.5 -11.5,11.5Zm0,-22c-5.79,0 -10.5,4.71 -10.5,10.5c0,5.79 4.71,10.5 10.5,10.5c5.79,0 10.5,-4.71 10.5,-10.5c0,-5.79 -4.71,-10.5 -10.5,-10.5Z"
      ></path>
      <path
        d="M12,18.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-5.5h-5.5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h5.5v-5.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v5.5h5.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-5.5v5.5c0,0.276 -0.224,0.5 -0.5,0.5Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "1LJl6fth",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,23.5c-6.341,0 -11.5,-5.159 -11.5,-11.5c0,-6.341 5.159,-11.5 11.5,-11.5c6.341,0 11.5,5.159 11.5,11.5c0,6.341 -5.159,11.5 -11.5,11.5Zm0,-22c-5.79,0 -10.5,4.71 -10.5,10.5c0,5.79 4.71,10.5 10.5,10.5c5.79,0 10.5,-4.71 10.5,-10.5c0,-5.79 -4.71,-10.5 -10.5,-10.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,18.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-5.5h-5.5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h5.5v-5.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v5.5h5.5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-5.5v5.5c0,0.276 -0.224,0.5 -0.5,0.5Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/add-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});