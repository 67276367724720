define("cc-frontend/components/planner/menus/google-classroom/topic-menu/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  */
  {
    "id": "4O8eaCtM",
    "block": "[[],[],false,[]]",
    "moduleName": "cc-frontend/components/planner/menus/google-classroom/topic-menu/index.hbs",
    "isStrictMode": false
  });

  class GoogleClassroomTopicMenuComponent extends _component.default {}

  _exports.default = GoogleClassroomTopicMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GoogleClassroomTopicMenuComponent);
});