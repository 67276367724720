define("cc-frontend/lib/actions/UNDO_ACTION", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UNDO_ACTION = void 0;
  const UNDO_ACTION = {
    name: "UNDO_ACTION",
    params: {
      patches: "array",
      originalActionId: "string",
      originalActionNarrative: "object"
    },

    patches(payload) {
      return payload.patches;
    },

    undoPatches: null,

    narrative(payload, findFn, userId) {
      let context = _lodash.default.assign({}, payload.originalActionNarrative.context, {
        originalAction: payload.originalActionNarrative.titleForAnalytics
      });

      return {
        title: `Undo ${payload.originalActionNarrative.title}`,
        titleForAnalytics: `Undo Action`,
        description: `Undo ${payload.originalActionNarrative.description}`,
        context,
        activity: payload.originalActionNarrative.activity
      };
    }

  };
  _exports.UNDO_ACTION = UNDO_ACTION;
  var _default = UNDO_ACTION;
  _exports.default = _default;
});