define("cc-frontend/components/planner/card-stacks/lesson-template", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    local-class="lesson-outer"
    class="take-course-background-color {{@models.course.attributes.color}}"
  >
    <div
      local-class="lesson"
      data-course-id={{@models.course.id}}
      data-card-stack-id={{@models.cardStack.id}}
    >
      {{! Unit Header }}
      <Planner::CardStacks::Unit::Header
        @permissions={{@models.cardStack.meta.permissions}}
        @unitTitle={{@models.cardStack.attributes.title}}
        @courseTitle={{@models.course.attributes.title}}
        @courseId={{@models.course.id}}
        @cardStackId={{@models.cardStackId}}
        @editCourseTitleAndOrder={{@editCourseTitleAndOrder}}
      />
  
      {{! Lesson Body }}
      <div class="lesson-body">
        <Planner::CardStacks::Cards
          @usedAs={{@models.cardStack.attributes.usedAs}}
          @cardStackId={{@models.cardStack.id}}
          @permissions={{@models.cardStack.meta.permissions}}
          @planbookId={{@models.planbook.id}}
          @courseId={{@models.course.id}}
          @courseColor={{@models.course.attributes.color}}
          @courseStandardSetIds={{@models.course.attributes.standardSetIds}}
          @planbookRecentStandardSetIds={{@models.planbook.attributes.recentStandardSetIds}}
          @sortedCards={{this.sortedCards}}
          @sortableModel={{this.sortedCards}}
        />
  
      </div>
    </div>
  </div>
  */
  {
    "id": "TvHROI6/",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"take-course-background-color \",[30,1,[\"course\",\"attributes\",\"color\"]]],null],\" \",[28,[37,1],[\"lesson-outer\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson-styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,1],[\"lesson\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson-styles\"]]]]]],[15,\"data-course-id\",[30,1,[\"course\",\"id\"]]],[15,\"data-card-stack-id\",[30,1,[\"cardStack\",\"id\"]]],[12],[1,\"\\n\"],[1,\"    \"],[8,[39,2],null,[[\"@permissions\",\"@unitTitle\",\"@courseTitle\",\"@courseId\",\"@cardStackId\",\"@editCourseTitleAndOrder\"],[[30,1,[\"cardStack\",\"meta\",\"permissions\"]],[30,1,[\"cardStack\",\"attributes\",\"title\"]],[30,1,[\"course\",\"attributes\",\"title\"]],[30,1,[\"course\",\"id\"]],[30,1,[\"cardStackId\"]],[30,2]]],null],[1,\"\\n\\n\"],[1,\"    \"],[10,0],[14,0,\"lesson-body\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@usedAs\",\"@cardStackId\",\"@permissions\",\"@planbookId\",\"@courseId\",\"@courseColor\",\"@courseStandardSetIds\",\"@planbookRecentStandardSetIds\",\"@sortedCards\",\"@sortableModel\"],[[30,1,[\"cardStack\",\"attributes\",\"usedAs\"]],[30,1,[\"cardStack\",\"id\"]],[30,1,[\"cardStack\",\"meta\",\"permissions\"]],[30,1,[\"planbook\",\"id\"]],[30,1,[\"course\",\"id\"]],[30,1,[\"course\",\"attributes\",\"color\"]],[30,1,[\"course\",\"attributes\",\"standardSetIds\"]],[30,1,[\"planbook\",\"attributes\",\"recentStandardSetIds\"]],[30,0,[\"sortedCards\"]],[30,0,[\"sortedCards\"]]]],null],[1,\"\\n\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@models\",\"@editCourseTitleAndOrder\"],false,[\"concat\",\"local-class\",\"planner/card-stacks/unit/header\",\"planner/card-stacks/cards\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/lesson-template.hbs",
    "isStrictMode": false
  });

  let LessonTemplate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed("models.lesson.attributes.cards.[]"), _dec6 = Ember.computed("cardAttributes.@each.position"), (_class = class LessonTemplate extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "models", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "dragging", _descriptor4, this);
    }

    get cardAttributes() {
      if (this.models === undefined) return undefined;
      let cards = this.models.cardStack.attributes.cards;
      return (0, _lodashEs.map)(cards, "attributes");
    }

    get sortedCards() {
      let cardAttributes = this.cardAttributes; // have to trigger the computed property above

      if ((0, _lodashEs.isArray)(cardAttributes) !== true || (0, _lodashEs.isNil)(this.models.cardStack) || (0, _lodashEs.isNil)(this.models.cardStack.attributes)) return undefined;
      let cards = this.models.cardStack.attributes.cards;
      return (0, _lodashEs.sortBy)(cards, "attributes.position");
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appState", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dragging", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cardAttributes", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cardAttributes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortedCards", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sortedCards"), _class.prototype)), _class));
  _exports.default = LessonTemplate;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LessonTemplate);
});