define("cc-frontend/components/group/planbook-collaborator-select", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/models/planbook", "tracked-built-ins"], function (_exports, _glimmerComponent, _planbook, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isChanging}}
    <div class="tw-h-8">
      <DS::ThreeDotSpinner />
    </div>
  {{else}}
    <PowerSelect
      @options={{this.availableRoles}}
      @selected={{find-by
        "officialId"
        @collaborator.attributes.roles.firstObject
        this.availableRoles
      }}
      @searchEnabled={{false}}
      @triggerClass="sierra-subtle-dropdown-trigger"
      @dropdownClass="sierra-subtle-dropdown-list"
      @placeholder="Select a permission"
      @onChange={{fn this.updateCollaborator @collaborator.id}}
      as |role|
    >
      {{role.title}}
    </PowerSelect>
  {{/if}}
  */
  {
    "id": "knMFbIyC",
    "block": "[[[41,[30,0,[\"isChanging\"]],[[[1,\"  \"],[10,0],[14,0,\"tw-h-8\"],[12],[1,\"\\n    \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@options\",\"@selected\",\"@searchEnabled\",\"@triggerClass\",\"@dropdownClass\",\"@placeholder\",\"@onChange\"],[[30,0,[\"availableRoles\"]],[28,[37,3],[\"officialId\",[30,1,[\"attributes\",\"roles\",\"firstObject\"]],[30,0,[\"availableRoles\"]]],null],false,\"sierra-subtle-dropdown-trigger\",\"sierra-subtle-dropdown-list\",\"Select a permission\",[28,[37,4],[[30,0,[\"updateCollaborator\"]],[30,1,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"title\"]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]],[\"@collaborator\",\"role\"],false,[\"if\",\"d-s/three-dot-spinner\",\"power-select\",\"find-by\",\"fn\"]]",
    "moduleName": "cc-frontend/components/group/planbook-collaborator-select.hbs",
    "isStrictMode": false
  });

  let PlanbookCollaboratorSelect = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class PlanbookCollaboratorSelect extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _defineProperty(this, "availableRoles", _planbook.PLANBOOK_ROLES);

      _initializerDefineProperty(this, "isChanging", _descriptor3, this);
    }

    updateCollaborator(collaboratorId, newRole) {
      this.isChanging = true;
      this.rpc.planbook_collaboration_change_collaborator_role({
        planbookId: this.args.planbookId,
        collaboratorId: collaboratorId,
        role: newRole.id
      }).then(() => {
        Ember.run.later(() => {
          if (this.isDestroyed || this.isDestroying) return;
          this.isChanging = false;
        }, 1000);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isChanging", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateCollaborator", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateCollaborator"), _class.prototype)), _class));
  _exports.default = PlanbookCollaboratorSelect;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookCollaboratorSelect);
});