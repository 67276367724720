define("cc-frontend/components/planner/course/outline-and-detail/dates-for-list", ["exports", "lodash", "lodash-es"], function (_exports, _lodash, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.dates}}
  */
  {
    "id": "i+L3fW+Z",
    "block": "[[[18,1,[[30,0,[\"dates\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/course/outline-and-detail/dates-for-list.hbs",
    "isStrictMode": false
  });

  let Outline = (_dec = Ember.inject.service, _dec2 = Ember.computed("planbook.attributes.calendar.version", "course.attributes.calendar.version", "rotationCalendar.attributes.version", "startDate", "endDate"), (_class = class Outline extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "course", void 0);

      _defineProperty(this, "rotationCalendar", void 0);

      _defineProperty(this, "startDate", void 0);

      _defineProperty(this, "endDate", void 0);

      _defineProperty(this, "_cachedDatesMap", {});

      _defineProperty(this, "dateIdMap", {});

      _initializerDefineProperty(this, "phantomCardStacks", _descriptor, this);
    }

    get dates() {
      let units = this.course.attributes.calendar.units;
      let rotationCalendarDateMap = (0, _lodashEs.keyBy)(this.rotationCalendar.attributes.dates, date => date.attributes.dateString);
      let courseDateMap = (0, _lodashEs.reduce)(this.course.attributes.calendar.dates, (acc, date) => {
        if (date.type === "course-date-custom") acc[date.attributes.dateString] = date.attributes;
        return acc;
      }, {});
      let schoolDays = this.rotationCalendar.attributes.schoolDays;
      let firstDateOfSchoolYear = (0, _lodashEs.min)((0, _lodashEs.map)(this.rotationCalendar.attributes.semesters, semester => semester.startDate));
      let lastDateOfSchoolYear = (0, _lodashEs.min)((0, _lodashEs.map)(this.rotationCalendar.attributes.semesters, semester => semester.endDate));
      let courseDatesOff = this.course.attributes.calendar.datesOff;
      let planbookDatesOff = this.planbook.attributes.calendar.datesOff;
      let datesOffDictionary = (0, _lodashEs.reduce)((0, _lodashEs.concat)(courseDatesOff, planbookDatesOff), (acc, dateOff) => {
        acc[dateOff] = true;
        return acc;
      }, {});
      let minDate;
      let maxDate;

      if (this.startDate && this.endDate) {
        minDate = this.startDate;
        maxDate = this.endDate;
      } else {
        minDate = firstDateOfSchoolYear;
        maxDate = lastDateOfSchoolYear;
      }

      let list = _lodash.default.chain(dateFns.eachDay(minDate, maxDate)).filter(date => _lodash.default.includes(schoolDays, dateFns.getDay(date))).flatMap(date => {
        let dateString = dateFns.format(date, "YYYY-MM-DD");
        let rotationDate = rotationCalendarDateMap[dateString];
        let courseDate = courseDateMap[dateString] || {};
        let foundUnits = (0, _lodashEs.map)(courseDate.unitStart, id => {
          return Object.assign({}, _lodash.default.find(units, {
            id: id
          }), {
            isUnit: true
          });
        });
        let isOff = rotationDate === undefined ? false : datesOffDictionary[`${rotationDate.attributes.semesterId}:${rotationDate.attributes.rotationId}`] || datesOffDictionary[dateString]; // We cache the ids so that we don't rerender all the dates as soon as you edit a date

        let id;

        if (Ember.isNone(courseDate.cardStackId)) {
          this.dateIdMap[dateString] = this.phantomCardStacks.findCardStackIdForDate(this.course.id, dateString);
          id = this.dateIdMap[dateString];
        } else {
          id = courseDate.cardStackId;
        }

        if (isOff !== true || courseDate.isForcedOn === true) {
          foundUnits.push({
            id: id,
            // @ts-ignore
            dateString: dateString,
            // @ts-ignore
            isBeforeSchoolYear: dateString <= firstDateOfSchoolYear,
            // @ts-ignore
            isAfterSchoolYear: dateString >= lastDateOfSchoolYear,
            semesterId: rotationDate ? rotationDate.attributes.semesterId : null,
            rotationId: rotationDate ? rotationDate.attributes.rotationId : null,
            isNewLesson: Ember.isNone(courseDate.cardStackId),
            cardStackId: id,
            unitStart: courseDate.unitStart || [],
            unitEnd: courseDate.unitEnd || [],
            unitIds: courseDate.unitIds || []
          });
        }

        return foundUnits;
      }).value();

      return (0, _lodashEs.map)(list, el => {
        if (this._cachedDatesMap[el.id]) {
          this._cachedDatesMap[el.id].setProperties(el);
        } else {
          this._cachedDatesMap[el.id] = Ember.Object.create(el);
        }

        return this._cachedDatesMap[el.id];
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "phantomCardStacks", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dates", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dates"), _class.prototype)), _class));
  _exports.default = Outline;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Outline);
});