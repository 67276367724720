define("cc-frontend/components/planner/planbook/import-lessons/modal.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_nwwdaf",
    "modal-inner": "_modal-inner_nwwdaf",
    "main-screen": "_main-screen_nwwdaf _main-screen_1o1brh",
    "nav-box-wrapper": "_nav-box-wrapper_nwwdaf _nav-box--base_1o1brh",
    "INTRO": "_INTRO_nwwdaf",
    "CONFIRM_DATE": "_CONFIRM_DATE_nwwdaf",
    "PICK_DATE": "_PICK_DATE_nwwdaf",
    "ALL_DONE": "_ALL_DONE_nwwdaf",
    "IS_COPYING_LESSONS": "_IS_COPYING_LESSONS_nwwdaf",
    "CONFIRMATION": "_CONFIRMATION_nwwdaf",
    "nav-box__head": "_nav-box__head_nwwdaf _nav-box__head_1o1brh",
    "--center": "_--center_nwwdaf",
    "nav-box__subhead": "_nav-box__subhead_nwwdaf _nav-box__subhead_1o1brh",
    "nav-box--grid": "_nav-box--grid_nwwdaf",
    "nav-box__main": "_nav-box__main_nwwdaf",
    "nav-box__back-arrow": "_nav-box__back-arrow_nwwdaf _nav-box__back-arrow_13zyu5",
    "nav-box__next-arrow": "_nav-box__next-arrow_nwwdaf _nav-box__next-arrow_13zyu5 d-s-shadowed-button",
    "big-button": "_big-button_nwwdaf _evaluate-button_13zyu5",
    "big-button__text": "_big-button__text_nwwdaf",
    "confirm-date-button": "_confirm-date-button_nwwdaf",
    "confirm-date-button__header": "_confirm-date-button__header_nwwdaf",
    "confirm-date-button__subheader": "_confirm-date-button__subheader_nwwdaf",
    "choose-own-date-button": "_choose-own-date-button_nwwdaf",
    "date-picker-wrapper": "_date-picker-wrapper_nwwdaf"
  };
  _exports.default = _default;
});