define("cc-frontend/components/planner/menus/day-menu/primary/title", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Menus::Titles::Primary @title="Day Menu">
    <span local-class="day-label">
      {{#if @planbookDateCombined.isInsideSchoolYear}}
        Day
        {{@dayCount}}
      {{/if}}
    </span>
  </Planner::Menus::Titles::Primary>
  */
  {
    "id": "SMlOdTon",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Day Menu\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[15,0,[29,[[28,[37,1],[\"day-label\"],[[\"from\"],[\"cc-frontend/components/planner/menus/day-menu/primary/title.css\"]]]]]],[12],[1,\"\\n\"],[41,[30,1,[\"isInsideSchoolYear\"]],[[[1,\"      Day\\n      \"],[1,[30,2]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@planbookDateCombined\",\"@dayCount\"],false,[\"planner/menus/titles/primary\",\"local-class\",\"if\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/primary/title.hbs",
    "isStrictMode": false
  });

  let DayMenuSecondaryTitle = (_dec = (0, _component.tagName)(""), _dec(_class = class DayMenuSecondaryTitle extends Ember.Component {}) || _class);
  _exports.default = DayMenuSecondaryTitle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DayMenuSecondaryTitle);
});