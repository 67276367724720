define("cc-frontend/components/planner/modals/weekly-order", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="modal">
    <div local-class="modal-inner {{if this.isSmall "--is-small"}}">
      <div local-class="modal-inner__close" {{on "click" @onClose}}>
        <Icons::RemoveCircle />
      </div>
      <Planner::Planbook::WeeklyOrder
        @rotationCalendar={{@model.rotationCalendar}}
        @planbook={{@model.planbook}}
      />
    </div>
  </div>
  */
  {
    "id": "yiMyntff",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"modal\"],[[\"from\"],[\"cc-frontend/components/planner/modals/weekly-order.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[[28,[37,1],[\"modal-inner \",[52,[30,0,[\"isSmall\"]],\"--is-small\"]],null]],[[\"from\"],[\"cc-frontend/components/planner/modals/weekly-order.css\"]]]]]],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[[28,[37,0],[\"modal-inner__close\"],[[\"from\"],[\"cc-frontend/components/planner/modals/weekly-order.css\"]]]]]],[4,[38,3],[\"click\",[30,1]],null],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,5],null,[[\"@rotationCalendar\",\"@planbook\"],[[30,2,[\"rotationCalendar\"]],[30,2,[\"planbook\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@onClose\",\"@model\"],false,[\"local-class\",\"concat\",\"if\",\"on\",\"icons/remove-circle\",\"planner/planbook/weekly-order\"]]",
    "moduleName": "cc-frontend/components/planner/modals/weekly-order.hbs",
    "isStrictMode": false
  });

  let WeeklyOrderModalComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec(_class = (_class2 = class WeeklyOrderModalComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isSmall", false);

      _initializerDefineProperty(this, "modalManager", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "modalManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = WeeklyOrderModalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, WeeklyOrderModalComponent);
});