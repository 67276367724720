define("cc-frontend/components/school/edit/payment", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/belong-to", "ember-could-get-used-to-this"], function (_exports, _glimmerComponent, _belongTo, _emberCouldGetUsedToThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.fiscalGroup.content.attributes.managingGroupId @group.id)}}
    <div class="school-edit-payment">
      {{#if this.fiscalGroup.content}}
        <div class="school-edit-payment__notice-action-section">
          <School::Edit::Payment::NoticeBar
            @fiscalGroup={{this.fiscalGroup.content}}
            @stateName={{this.stateName}}
            @daysUntilEndsAtString={{this.daysUntilEndsAtString}}
          />
          <School::Edit::Payment::MemberBox
            @group={{@group}}
            @fiscalGroup={{this.fiscalGroup.content}}
          />
        </div>
        <div class="school-edit-payment__invoices-section">
          <School::Edit::Payment::Invoices @fiscalGroup={{this.fiscalGroup.content}} />
        </div>
      {{/if}}
    </div>
  {{else}}
    <div class="school-edit-payment">
      <div class="school-edit-payment__notice-bar school-edit-payment__notice-bar--blank">
        <h1 class="school-edit-payment__notice-bar__heading">
          Visit
          <LinkTo @route="new-group.show.edit.payment" @model={{this.managingGroup.content.id}}>
            {{this.managingGroup.content.attributes.title}}
          </LinkTo>
          to handle payment for this school.
        </h1>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "/BR0NDgw",
    "block": "[[[41,[28,[37,1],[[30,0,[\"fiscalGroup\",\"content\",\"attributes\",\"managingGroupId\"]],[30,1,[\"id\"]]],null],[[[1,\"  \"],[10,0],[14,0,\"school-edit-payment\"],[12],[1,\"\\n\"],[41,[30,0,[\"fiscalGroup\",\"content\"]],[[[1,\"      \"],[10,0],[14,0,\"school-edit-payment__notice-action-section\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@fiscalGroup\",\"@stateName\",\"@daysUntilEndsAtString\"],[[30,0,[\"fiscalGroup\",\"content\"]],[30,0,[\"stateName\"]],[30,0,[\"daysUntilEndsAtString\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@group\",\"@fiscalGroup\"],[[30,1],[30,0,[\"fiscalGroup\",\"content\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"school-edit-payment__invoices-section\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@fiscalGroup\"],[[30,0,[\"fiscalGroup\",\"content\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"school-edit-payment\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"school-edit-payment__notice-bar school-edit-payment__notice-bar--blank\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"school-edit-payment__notice-bar__heading\"],[12],[1,\"\\n        Visit\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"new-group.show.edit.payment\",[30,0,[\"managingGroup\",\"content\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"managingGroup\",\"content\",\"attributes\",\"title\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n        to handle payment for this school.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@group\"],false,[\"if\",\"eq\",\"school/edit/payment/notice-bar\",\"school/edit/payment/member-box\",\"school/edit/payment/invoices\",\"link-to\"]]",
    "moduleName": "cc-frontend/components/school/edit/payment.hbs",
    "isStrictMode": false
  });

  let SchoolEditPayment = (_class = class SchoolEditPayment extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fiscalGroup", _descriptor, this);

      _initializerDefineProperty(this, "managingGroup", _descriptor2, this);
    }

    get stateName() {
      let fg = Ember.get(this.fiscalGroup, "content");
      if (fg === null) return "";
      let attrs = Ember.get(fg, "attributes");
      let state = Ember.get(attrs, "state");
      let warningStartsAt = Ember.get(state, "warningStartsAt");
      let isAfterWarningDate = dateFns.isBefore(Ember.get(state, "warningStartsAt"), new Date());
      let currentValue = Ember.get(state, "currentValue");
      let postfix = warningStartsAt && isAfterWarningDate ? "-warning" : "";
      return `${currentValue}${postfix}`;
    }

    get daysUntilEndsAt() {
      let fg = Ember.get(this.fiscalGroup, "content");
      if (fg === null) return "";
      let attrs = Ember.get(fg, "attributes");
      let state = Ember.get(attrs, "state");
      if (this.fiscalGroup.content === null) return 0;
      return dateFns.differenceInDays(Ember.get(state, "endsAt"), new Date());
    }

    get daysUntilEndsAtString() {
      let days = this.daysUntilEndsAt + 1;
      return days === 1 ? `${days} day` : `${days} days`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fiscalGroup", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _belongTo.default(() => [this.args.group, "fiscalGroup"]);
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "managingGroup", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _belongTo.default(() => [this.fiscalGroup.content, "managingGroup"]);
    }
  })), _class);
  _exports.default = SchoolEditPayment;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SchoolEditPayment);
});