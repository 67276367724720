define("cc-frontend/templates/new-group/show/edit/teams", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y3sXfFp4",
    "block": "[[[41,[33,1,[\"attributes\",\"isEnabled\"]],[[[41,[28,[37,2],[[33,1,[\"meta\",\"permissions\"]],\"can-manage-group\"],null],[[[1,\"    \"],[8,[39,3],null,[[\"@group\"],[[36,1]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"tw-text-3xl tw-text-center tw-mt-8 tw-px-12\"],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[8,[39,5],null,[[\"@stateName\"],[\"group-closed\"]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"model\",\"includes\",\"school/edit/teams\",\"school/setup-permissions-notice\",\"school/edit/payment/notice-bar\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/edit/teams.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});