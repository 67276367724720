define("cc-frontend/components/icons/content-file-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M3.5,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-21c0,-0.827 0.673,-1.5 1.5,-1.5h13.293c0.401,0 0.777,0.156 1.061,0.439l3.707,3.707c0.283,0.284 0.439,0.661 0.439,1.061v17.293c0,0.827 -0.673,1.5 -1.5,1.5h-17Zm0,-23c-0.276,0 -0.5,0.224 -0.5,0.5v21c0,0.276 0.224,0.5 0.5,0.5h17c0.276,0 0.5,-0.224 0.5,-0.5v-17.293c0,-0.133 -0.052,-0.259 -0.146,-0.354l-3.707,-3.707c-0.094,-0.093 -0.222,-0.146 -0.354,-0.146h-13.293Z"
    ></path>
  </svg>
  */
  {
    "id": "Az0cVpsw",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M3.5,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-21c0,-0.827 0.673,-1.5 1.5,-1.5h13.293c0.401,0 0.777,0.156 1.061,0.439l3.707,3.707c0.283,0.284 0.439,0.661 0.439,1.061v17.293c0,0.827 -0.673,1.5 -1.5,1.5h-17Zm0,-23c-0.276,0 -0.5,0.224 -0.5,0.5v21c0,0.276 0.224,0.5 0.5,0.5h17c0.276,0 0.5,-0.224 0.5,-0.5v-17.293c0,-0.133 -0.052,-0.259 -0.146,-0.354l-3.707,-3.707c-0.094,-0.093 -0.222,-0.146 -0.354,-0.146h-13.293Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/content-file-empty.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});