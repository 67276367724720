define("cc-frontend/components/planner/menus/day-menu/change-rotation/body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "body": "_body_1jaupx",
    "day-list": "_day-list_1jaupx",
    "day-list__day": "_day-list__day_1jaupx",
    "--is-selected": "_--is-selected_1jaupx",
    "day-list__day__checkbox": "_day-list__day__checkbox_1jaupx",
    "day-list__day__name": "_day-list__day__name_1jaupx",
    "must-remove-reset": "_must-remove-reset_1jaupx"
  };
  _exports.default = _default;
});