define("cc-frontend/templates/-user-before-upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "79M2T5oT",
    "block": "[[],[],false,[]]",
    "moduleName": "cc-frontend/templates/-user-before-upgrade.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});