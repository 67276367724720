define("cc-frontend/components/icons/happy-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <path
      d="M387.469 289.593c-2.248-1.175-4.775-1.796-7.308-1.796-5.956 0-11.358 3.287-14.104 8.588C347.804 331.731 304.605 352 256.004 352c-49.734 0-93.84-20.562-109.757-54.997-2.589-5.589-8.244-9.199-14.407-9.199-2.294 0-4.521.485-6.658 1.463-7.926 3.674-11.397 13.117-7.734 21.057C138.454 355.765 194.135 384 256.004 384c60.34 0 114.604-27.281 138.241-73.055 4.009-7.761.956-17.347-6.776-21.352zM412.793 231.174C402.312 217.593 389.024 208 368.137 208s-34.062 9.573-44.611 23.117c-5.502 7.062-4.525 16.633 3.746 22.207 2.914 1.963 6.347 2.676 9.928 2.676 5.395-.001 10.507-2.109 13.67-6.179 3.45-4.435 7.707-9.821 17.268-9.821 9.61 0 13.832 5.361 17.23 9.777 3.178 4.104 8.305 6.223 13.717 6.223 3.581 0 7.007-.708 9.922-2.683 7.551-5.132 9.25-15.067 3.786-22.143zM188.792 231.174C178.312 217.593 165.024 208 144.137 208s-34.063 9.573-44.612 23.117c-5.501 7.062-4.525 16.633 3.747 22.207 2.914 1.963 6.346 2.676 9.927 2.676 5.395-.001 10.507-2.109 13.669-6.179 3.451-4.435 7.708-9.821 17.269-9.821 9.61 0 13.832 5.361 17.23 9.777 3.177 4.104 8.304 6.223 13.716 6.223 3.582 0 7.007-.708 9.922-2.683 7.553-5.132 9.252-15.067 3.787-22.143z"
    ></path>
    <path
      d="M256 32C132.288 32 32 132.288 32 256s100.288 224 224 224 224-100.288 224-224S379.712 32 256 32zm135.765 359.765C355.5 428.028 307.285 448 256 448s-99.5-19.972-135.765-56.235C83.972 355.5 64 307.285 64 256s19.972-99.5 56.235-135.765C156.5 83.972 204.715 64 256 64s99.5 19.972 135.765 56.235C428.028 156.5 448 204.715 448 256s-19.972 99.5-56.235 135.765z"
    ></path>
  </svg>
  */
  {
    "id": "q/QQ6Bx2",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M387.469 289.593c-2.248-1.175-4.775-1.796-7.308-1.796-5.956 0-11.358 3.287-14.104 8.588C347.804 331.731 304.605 352 256.004 352c-49.734 0-93.84-20.562-109.757-54.997-2.589-5.589-8.244-9.199-14.407-9.199-2.294 0-4.521.485-6.658 1.463-7.926 3.674-11.397 13.117-7.734 21.057C138.454 355.765 194.135 384 256.004 384c60.34 0 114.604-27.281 138.241-73.055 4.009-7.761.956-17.347-6.776-21.352zM412.793 231.174C402.312 217.593 389.024 208 368.137 208s-34.062 9.573-44.611 23.117c-5.502 7.062-4.525 16.633 3.746 22.207 2.914 1.963 6.347 2.676 9.928 2.676 5.395-.001 10.507-2.109 13.67-6.179 3.45-4.435 7.707-9.821 17.268-9.821 9.61 0 13.832 5.361 17.23 9.777 3.178 4.104 8.305 6.223 13.717 6.223 3.581 0 7.007-.708 9.922-2.683 7.551-5.132 9.25-15.067 3.786-22.143zM188.792 231.174C178.312 217.593 165.024 208 144.137 208s-34.063 9.573-44.612 23.117c-5.501 7.062-4.525 16.633 3.747 22.207 2.914 1.963 6.346 2.676 9.927 2.676 5.395-.001 10.507-2.109 13.669-6.179 3.451-4.435 7.708-9.821 17.269-9.821 9.61 0 13.832 5.361 17.23 9.777 3.177 4.104 8.304 6.223 13.716 6.223 3.582 0 7.007-.708 9.922-2.683 7.553-5.132 9.252-15.067 3.787-22.143z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M256 32C132.288 32 32 132.288 32 256s100.288 224 224 224 224-100.288 224-224S379.712 32 256 32zm135.765 359.765C355.5 428.028 307.285 448 256 448s-99.5-19.972-135.765-56.235C83.972 355.5 64 307.285 64 256s19.972-99.5 56.235-135.765C156.5 83.972 204.715 64 256 64s99.5 19.972 135.765 56.235C428.028 156.5 448 204.715 448 256s-19.972 99.5-56.235 135.765z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/happy-outline.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});