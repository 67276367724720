define("cc-frontend/lib/structor_2", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Structor {
    /**
     *
     * @param {object} attributes
     * @param {*} map
     * @param {*} key
     * @param {*} val
     */
    constructor(map) {
      let attributes = this.defineStruct();
      map = map || {};

      _lodash.default.forEach(attributes, (val, key) => {
        if (typeof attributes[key] === "function") {
          attributes[key] = val(map);
        } else {
          attributes[key] = map[key] === undefined ? attributes[key] : map[key];
        }
      });

      return attributes;
    }

  }

  _exports.default = Structor;
});