define("cc-frontend/lib/planbook-calendar-creator", ["exports", "@marcj/marshal", "cc-frontend/models/planbook-calendar-date-combined", "lodash"], function (_exports, _marshal, _planbookCalendarDateCombined, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.courseDateComputedProperty = _exports.planbookDateComputedProperty = void 0;
  const planbookDateComputedProperty = Ember.computed("dateString", "rotationCalendar.attributes.version", "planbook.attributes.calendar.version", function () {
    let dateString = Ember.get(this, "dateString");
    let rotationDate = _lodash.default.find(Ember.get(this, "rotationCalendar.attributes.dates"), date => date.attributes.dateString === dateString) || {
      attributes: {}
    };

    let planbookDateCustom = _lodash.default.find(Ember.get(this, "planbook.attributes.calendar.dates"), date => date.attributes.dateString === dateString);

    let planbookDateDefault = _lodash.default.find(Ember.get(this, "planbook.attributes.calendar.dates"), date => {
      return date.attributes.semesterId === rotationDate.attributes.semesterId && date.attributes.rotationId === rotationDate.attributes.rotationId;
    });

    let firstDay = _lodash.default.min(_lodash.default.map(Ember.get(this, "rotationCalendar.attributes.semesters"), "startDate"));

    let lastDay = _lodash.default.max(_lodash.default.map(Ember.get(this, "rotationCalendar.attributes.semesters"), "endDate"));

    let planbookDateDefaultIsOff = _lodash.default.includes(Ember.get(this, "planbook.attributes.calendar.datesOff"), `${rotationDate.attributes.semesterId}:${rotationDate.attributes.rotationId}`);

    let planbookDateCustomIsOff = _lodash.default.includes(Ember.get(this, "planbook.attributes.calendar.datesOff"), dateString);

    let isOff = planbookDateCustomIsOff ? planbookDateCustomIsOff : planbookDateDefaultIsOff;

    let dateAnnotations = _lodash.default.filter(Ember.get(this, "planbook.attributes.calendar.dateAnnotations"), annotation => {
      if (annotation.type === "date-annotation-planbook-custom-single") {
        return annotation.attributes.dateString === dateString;
      }

      if (annotation.type === "date-annotation-planbook-custom-range") {
        return annotation.attributes.startDate <= dateString && annotation.attributes.endDate >= dateString;
      }
    });

    return (0, _marshal.plainToClass)(_planbookCalendarDateCombined.default, {
      dateString: dateString,
      cardStackId: planbookDateCustom ? planbookDateCustom.attributes.cardStackId : null,
      cardStackTemplateId: planbookDateDefault ? planbookDateDefault.attributes.cardStackId : null,
      isBeforeSchoolYear: dateString < firstDay,
      isAfterSchoolYear: dateString > lastDay,
      isInsideSchoolYear: dateString >= firstDay && dateString <= lastDay,
      isOff: isOff,
      dateAnnotations: dateAnnotations
    });
  });
  _exports.planbookDateComputedProperty = planbookDateComputedProperty;
  const courseDateComputedProperty = Ember.computed("dateString", "rotationCalendar.version", "planbook.attributes.calendar.version", "course.attributes.calendar.version", function () {
    let dateString = Ember.get(this, "dateString");
    let rotationDate = _lodash.default.find(Ember.get(this, "rotationCalendar.attributes.dates"), date => date.attributes.dateString === dateString) || {
      attributes: {}
    };

    let courseDateCustom = _lodash.default.find(Ember.get(this, "course.attributes.calendar.dates"), date => date.attributes.dateString === dateString);

    let courseDateDefault = _lodash.default.find(Ember.get(this, "course.attributes.calendar.dates"), date => {
      return date.attributes.semesterId === rotationDate.attributes.semesterId && date.attributes.rotationId === rotationDate.attributes.rotationId;
    });

    let firstDay = _lodash.default.min(_lodash.default.map(Ember.get(this, "rotationCalendar.attributes.semesters"), "startDate"));

    let lastDay = _lodash.default.max(_lodash.default.map(Ember.get(this, "rotationCalendar.attributes.semesters"), "endDate"));

    let planbookDateDefaultIsOff = _lodash.default.includes(Ember.get(this, "planbook.attributes.calendar.datesOff"), `${rotationDate.attributes.semesterId}:${rotationDate.attributes.rotationId}`);

    let planbookDateCustomIsOff = _lodash.default.includes(Ember.get(this, "planbook.attributes.calendar.datesOff"), dateString);

    let courseDateDefaultIsOff = _lodash.default.includes(Ember.get(this, "course.attributes.calendar.datesOff"), `${rotationDate.attributes.semesterId}:${rotationDate.attributes.rotationId}`);

    let courseDateCustomIsOff = _lodash.default.includes(Ember.get(this, "course.attributes.calendar.datesOff"), dateString);

    let planbookIsOff = planbookDateCustomIsOff ? planbookDateCustomIsOff : planbookDateDefaultIsOff;
    let courseIsOff = courseDateCustomIsOff ? courseDateCustomIsOff : courseDateDefaultIsOff;
    let courseDateForcedOn = courseDateCustom ? courseDateCustom.attributes.isForcedOn : false;
    let hasCardStackId = courseDateCustom ? courseDateCustom.attributes.cardStackId !== null : false;
    let isOff = !courseDateForcedOn && (planbookIsOff ? planbookIsOff : courseIsOff);

    let visibleCourseDateAnnotations = _lodash.default.filter(Ember.get(this, "course.attributes.calendar.dateAnnotations"), annotation => {
      return annotation.attributes.dateString === dateString && annotation.attributes.showAnnotation === true;
    });

    let cardStackId = courseDateCustom ? courseDateCustom.attributes.cardStackId : null;
    let cardStackTemplateId = courseDateDefault ? courseDateDefault.attributes.cardStackId : null;
    return (0, _marshal.plainToClass)(CourseCalendarDateCombined, {
      dateString: dateString,
      cardStackTemplateId: cardStackTemplateId,
      cardStackId: cardStackId,
      isNewLesson: cardStackId === null,
      hasNoTemplate: cardStackTemplateId === null,
      unitStart: courseDateCustom ? courseDateCustom.attributes.unitStart : [],
      unitEnd: courseDateCustom ? courseDateCustom.attributes.unitEnd : [],
      unitIds: courseDateCustom ? courseDateCustom.attributes.unitIds : [],
      // hideFromCalendar: isOff && visibleCourseDateAnnotations.length === 0,
      hideFromCalendar: isOff && hasCardStackId !== true && visibleCourseDateAnnotations.length === 0,
      visibleAnnotations: visibleCourseDateAnnotations,
      hasVisibleAnnotations: visibleCourseDateAnnotations.length > 0,
      hasLesson: courseDateCustom && courseDateCustom.attributes.cardStackId,
      isForcedOn: courseDateForcedOn,
      isOff: isOff,
      isOffByDefault: courseDateDefaultIsOff,
      isBeforeSchoolYear: dateString < firstDay,
      isAfterSchoolYear: dateString > lastDay,
      isInsideSchoolYear: dateString >= firstDay && dateString <= lastDay
    });
  });
  _exports.courseDateComputedProperty = courseDateComputedProperty;
});