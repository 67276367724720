define("cc-frontend/helpers/safe-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.safeString = safeString;
  _exports.default = void 0;

  function safeString(params
  /*, hash*/
  ) {
    return params ? Ember.String.htmlSafe(params.join("")) : "";
  }

  var _default = Ember.Helper.helper(safeString);

  _exports.default = _default;
});