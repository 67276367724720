define("cc-frontend/components/icons/book-flip-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M12,23.919c-0.14,0 -0.275,-0.059 -0.369,-0.163c-0.021,-0.022 -2.46,-2.471 -10.144,-2.568c-0.544,-0.008 -0.987,-0.456 -0.987,-1v-15.766c0.004,-0.277 0.111,-0.531 0.303,-0.717c0.186,-0.181 0.437,-0.282 0.706,-0.283c5.891,0.077 8.86,1.473 10.085,2.276c0.272,-1.296 1.348,-4.118 5.582,-5.563c0.104,-0.036 0.213,-0.054 0.324,-0.054c0.552,0 1,0.448 1,1v2.637c1.336,-0.187 2.676,-0.286 3.99,-0.296c0.268,0.002 0.524,0.105 0.708,0.283c0.191,0.186 0.299,0.436 0.303,0.703v15.78c0,0.543 -0.442,0.992 -0.986,1c-4.077,0.052 -6.635,0.761 -8.063,1.347c-1.486,0.61 -2.062,1.2 -2.086,1.225c-0.004,0.004 -0.024,0.024 -0.028,0.028c-0.093,0.085 -0.213,0.131 -0.338,0.131Zm-10.5,-3.731c5.76,0.073 8.731,1.412 10,2.215v-15.53c-0.632,-0.529 -3.305,-2.364 -10,-2.451v-0.25v16.016Zm17,-3.269c0,0.25 -0.187,0.464 -0.436,0.496c-2.666,0.348 -4.831,2.333 -5.445,4.915c1.326,-0.805 4.29,-2.071 9.882,-2.142l-0.001,-15.766l-0.004,-0.25l0.002,0.25c-1.319,0.009 -2.663,0.112 -3.998,0.305v12.192Zm-6.015,-10.382c0.01,0.039 0.015,0.077 0.015,0.116v13.349c1.086,-1.81 2.916,-3.109 5,-3.509v-15.412c-2.021,0.69 -3.525,1.827 -4.35,3.289c-0.632,1.119 -0.65,2.042 -0.65,2.051c0,0.038 -0.005,0.077 -0.015,0.116Z"
    ></path>
  </svg>
  */
  {
    "id": "vLyrQCm2",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M12,23.919c-0.14,0 -0.275,-0.059 -0.369,-0.163c-0.021,-0.022 -2.46,-2.471 -10.144,-2.568c-0.544,-0.008 -0.987,-0.456 -0.987,-1v-15.766c0.004,-0.277 0.111,-0.531 0.303,-0.717c0.186,-0.181 0.437,-0.282 0.706,-0.283c5.891,0.077 8.86,1.473 10.085,2.276c0.272,-1.296 1.348,-4.118 5.582,-5.563c0.104,-0.036 0.213,-0.054 0.324,-0.054c0.552,0 1,0.448 1,1v2.637c1.336,-0.187 2.676,-0.286 3.99,-0.296c0.268,0.002 0.524,0.105 0.708,0.283c0.191,0.186 0.299,0.436 0.303,0.703v15.78c0,0.543 -0.442,0.992 -0.986,1c-4.077,0.052 -6.635,0.761 -8.063,1.347c-1.486,0.61 -2.062,1.2 -2.086,1.225c-0.004,0.004 -0.024,0.024 -0.028,0.028c-0.093,0.085 -0.213,0.131 -0.338,0.131Zm-10.5,-3.731c5.76,0.073 8.731,1.412 10,2.215v-15.53c-0.632,-0.529 -3.305,-2.364 -10,-2.451v-0.25v16.016Zm17,-3.269c0,0.25 -0.187,0.464 -0.436,0.496c-2.666,0.348 -4.831,2.333 -5.445,4.915c1.326,-0.805 4.29,-2.071 9.882,-2.142l-0.001,-15.766l-0.004,-0.25l0.002,0.25c-1.319,0.009 -2.663,0.112 -3.998,0.305v12.192Zm-6.015,-10.382c0.01,0.039 0.015,0.077 0.015,0.116v13.349c1.086,-1.81 2.916,-3.109 5,-3.509v-15.412c-2.021,0.69 -3.525,1.827 -4.35,3.289c-0.632,1.119 -0.65,2.042 -0.65,2.051c0,0.038 -0.005,0.077 -0.015,0.116Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/book-flip-page.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});