define("cc-frontend/lib/actions/lesson/LESSON_CARD_GC_CHANGE_SHARE_MODE", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareUpsertShareMode = prepareUpsertShareMode;

  function prepareUpsertShareMode(payload) {
    return {
      name: "LESSON_CARD_GC_CHANGE_SHARE_MODE",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  const patches = function (payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.cardId
    }).set("embedded", `attributes.attachmentSettings.${payload.attachmentId}`, {
      shareMode: payload.shareMode
    }).patch;

    return [patch];
  };

  const narrative = function (payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    return {
      title: "Changed GC attachment sharing setting",
      titleForAnalytics: "Lesson Card - GC Change Share Mode - Schedule",
      description: `Changed GC attachment id ${payload.attachmentId} sharing setting to ${payload.shareMode}".`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models, "created")
    };
  };
});