define("cc-frontend/helpers/gt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gt = gt;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function gt(params) {
    let first = params[0];
    let second = params[1];
    return first > second;
  }

  class Gt extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "compute", gt);
    }

  }

  _exports.default = Gt;
});