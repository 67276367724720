define("cc-frontend/helpers/has-perm", ["exports", "@glint/environment-ember-loose/ember-component/helper", "lodash-es"], function (_exports, _helper, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasPerm = hasPerm;
  _exports.default = void 0;

  function hasPerm(args) {
    var _model$meta;

    let [model, perm] = args;
    if ((0, _lodashEs.isNil)(model)) return false;
    return (0, _lodashEs.includes)(model === null || model === void 0 ? void 0 : (_model$meta = model.meta) === null || _model$meta === void 0 ? void 0 : _model$meta.permissions, perm);
  }

  const hasPermHelper = (0, _helper.helper)(hasPerm);
  var _default = hasPermHelper;
  _exports.default = _default;
});