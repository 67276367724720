define("cc-frontend/lib/date-formatter", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormatMap = _exports.weekdaysInWords = _exports.monthsInWords = _exports.decorateDates = _exports.decorateDate = _exports.default = void 0;
  let weekdaysInWords = [{
    full: "Sunday",
    abbreviation: "Su"
  }, {
    full: "Monday",
    abbreviation: "M"
  }, {
    full: "Tuesday",
    abbreviation: "T"
  }, {
    full: "Wednesday",
    abbreviation: "W"
  }, {
    full: "Thursday",
    abbreviation: "R"
  }, {
    full: "Friday",
    abbreviation: "F"
  }, {
    full: "Saturday",
    abbreviation: "Sa"
  }];
  _exports.weekdaysInWords = weekdaysInWords;
  let monthsInWords = [{
    full: "January"
  }, {
    full: "February"
  }, {
    full: "March"
  }, {
    full: "April"
  }, {
    full: "May"
  }, {
    full: "June"
  }, {
    full: "July"
  }, {
    full: "August"
  }, {
    full: "September"
  }, {
    full: "October"
  }, {
    full: "November"
  }, {
    full: "December"
  }]; // We're not using moment because it's 11x slower which means we go from
  // 5ms to 55ms and that's on a fast macbook pro.
  // 55ms could easily be 250ms on a slower machine or mobile.
  // Reference: http://stackoverflow.com/questions/439630/how-do-you-create-a-javascript-date-object-with-a-set-timezone-without-using-a-s

  _exports.monthsInWords = monthsInWords;

  var dateFormatter = function (dateString) {
    let year = Math.floor(dateString.slice(0, 4));
    let month = Math.floor(dateString.slice(5, 7));
    let date = Math.floor(dateString.slice(8, 10));
    let dateObject = new Date(year, month - 1, date, 0, 0, 0); // months are 0 - 11. wat.

    let weekday = dateObject.getDay(); // We return the function and switch statement to reduce allocations. From some
    // simple testing, this appeared to help over returning a hash

    return function (attr) {
      switch (attr) {
        case "year":
          return year;

        case "month":
          return month;

        case "date":
          return date;

        case "weekdayIndex":
          return weekday;

        case "monthWord":
          return monthsInWords[month - 1].full;

        case "isFriday":
          return weekday === 5;

        case "isMonday":
          return weekday === 1;

        case "weekdayWord":
          return weekdaysInWords[weekday].full;

        case "weekdayWordAbbreviated":
          return weekdaysInWords[weekday].abbreviation;

        case "dayOfWeekAbbreviated":
          return weekdaysInWords[weekday].abbreviation;

        case "yearMonth":
          return dateString.slice(0, 7);

        case "dateOfMonth":
          return date;
      }
    };
  };

  var dateFormatMap = function (dateString) {
    let year = Math.floor(dateString.slice(0, 4));
    let month = Math.floor(dateString.slice(5, 7));
    let date = Math.floor(dateString.slice(8, 10));
    let dateObject = new Date(year, month - 1, date, 0, 0, 0); // months are 0 - 11. wat.

    let weekday = dateObject.getDay(); // We return the function and switch statement to reduce allocations. From some
    // simple testing, this appeared to help over returning a hash

    return {
      year: year,
      month: month,
      date: date,
      weekdayIndex: weekday,
      monthWord: monthsInWords[month - 1].full,
      isFriday: weekday === 5,
      isMonday: weekday === 1,
      weekdayWord: weekdaysInWords[weekday].full,
      weekdayWordAbbreviated: weekdaysInWords[weekday].abbreviation,
      dayOfWeekAbbreviated: weekdaysInWords[weekday].abbreviation,
      yearMonth: dateString.slice(0, 7),
      dateOfMonth: date
    };
  };

  _exports.dateFormatMap = dateFormatMap;

  var decorateDate = function (dateString, todayDateString) {
    let dateObject = dateFormatter(dateString);
    return {
      dateString: dateString,
      month: dateObject("monthWord"),
      dayOfWeek: dateObject("weekdayWord"),
      dayOfWeekAbbreviated: dateObject("weekdayWordAbbreviated"),
      yearMonth: dateString.slice(0, 7),
      weekday: dateObject("weekdayIndex"),
      dateOfMonth: dateObject("date"),
      isToday: dateString === todayDateString,
      isMonday: dateObject("isMonday"),
      isFriday: dateObject("isFriday"),
      isMonthStart: false,
      isMonthEnd: false
    };
  };

  _exports.decorateDate = decorateDate;

  var setMonthStart = function (hash, usedMonths, index, array) {
    hash.isMonthStart = usedMonths[hash.yearMonth] !== true;
    let nextHash = array[index + 1];
    hash.isMonthEnd = nextHash && usedMonths[nextHash.yearMonth] !== true;
    usedMonths[hash.yearMonth] = true; // set first day of month for next iteration

    return hash;
  };

  var decorateDates = function (dateStringArray, todayDateString) {
    let usedMonths = {};
    return _lodash.default.chain(dateStringArray).map(dateString => decorateDate(dateString, todayDateString)).map((dateHash, index, array) => setMonthStart(dateHash, usedMonths, index, array)).value();
  };

  _exports.decorateDates = decorateDates;
  var _default = dateFormatter;
  _exports.default = _default;
});