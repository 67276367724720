define("cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_DELETE_MULTIPLE", ["exports", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models"], function (_exports, _datesOffCalculator, _patchUpdater, _lodash, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_DATE_ANNOTATION_DELETE_MULTIPLE = void 0;
  const COURSE_DATE_ANNOTATION_DELETE_MULTIPLE = {
    name: "COURSE_DATE_ANNOTATION_DELETE_MULTIPLE",
    params: {
      dateStrings: "array",
      course: "object"
    },

    patches(payload) {
      let newAnnotations = _lodash.default.reject(payload.course.attributes.calendar.dateAnnotations, annotation => {
        return annotation.type === "date-annotation-course-custom-single" && _lodash.default.includes(payload.dateStrings, annotation.attributes.dateString);
      });

      let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(newAnnotations);

      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dateAnnotations", newAnnotations).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatches(payload) {
      let oldAnnotations = JSON.parse(JSON.stringify(payload.course.attributes.calendar.dateAnnotations));
      let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(oldAnnotations);

      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dateAnnotations", oldAnnotations).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
      let courseTitle = payload.course.attributes.title;
      let context = (0, _defaultCourseDateContext.default)(payload, models);
      let numberOfDates = payload.dateStrings.length;

      let newContext = _lodash.default.omit(_lodash.default.assign({}, context, {
        dates: numberOfDates
      }), ["date"]);

      return {
        // this is less weird as a separate undo because the choice they made didn't maintain the status quo.
        title: "Turned Multiple Lessons Back On",
        titleForAnalytics: "Lesson - Toggle Multiple On - Schedule Modal",
        description: `Turned ${numberOfDates} lessons back on while editing the schedule for your ${courseTitle} class.`,
        context: newContext,
        activity: (0, _defaultCourseDateActivity.default)(payload, models, "removed")
      };
    }

  };
  _exports.COURSE_DATE_ANNOTATION_DELETE_MULTIPLE = COURSE_DATE_ANNOTATION_DELETE_MULTIPLE;
  var _default = COURSE_DATE_ANNOTATION_DELETE_MULTIPLE;
  _exports.default = _default;
});