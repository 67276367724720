define("cc-frontend/components/d-s/dropdown-menu/icon-row/item", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="icon-row__item" {{on "click" this.onClick}} ...attributes>
    <div local-class="icon-row__item__icon">
      {{component @iconName}}
    </div>
    <div local-class="icon-row__item__text">
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "DkhfZqZT",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[\"icon-row__item\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/icon-row/item.css\"]]]]]],[17,1],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"icon-row__item__icon\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/icon-row/item.css\"]]]]]],[12],[1,\"\\n    \"],[46,[30,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"icon-row__item__text\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/icon-row/item.css\"]]]]]],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@iconName\",\"&default\"],false,[\"local-class\",\"on\",\"component\",\"yield\"]]",
    "moduleName": "cc-frontend/components/d-s/dropdown-menu/icon-row/item.hbs",
    "isStrictMode": false
  });

  let Item = (_dec = Ember._action, (_class = class Item extends _component.default {
    onClick() {
      if (this.args.clickAction) {
        this.args.clickAction();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = Item;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Item);
});