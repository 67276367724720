define("cc-frontend/mixins/register-component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    appState: Ember.inject.service(),

    init(attrs) {
      this._super(...arguments);

      this._registerComponent();
    },

    _registerComponent() {
      // Only register if we give a name. I think this should change, actually
      if (Ember.isNone(Ember.get(this, "componentId"))) throw Error("No componentId given " + this.toString()); // Only register component once

      if (this.registeredComponent) return;else this.registeredComponent = true; // Construct the id we'll use to identify this
      // let componentStateId = constructComponentStateId(this, get(attrs,'newAttrs.componentId.value'))

      let componentStateId = Ember.get(this, "componentId");

      if (_lodash.default.includes(componentStateId, "undefined")) {
        console.log("It looks like a property you were looking for wasn't definied as you have the word 'undefined' is in your componentId: " + componentStateId);
      }

      let as = Ember.get(this, "store").findInMemory("app-state", Ember.get(this, "session.id"));

      if (as !== undefined) {
        let cs = Ember.get(as, "attributes." + componentStateId); // if (!isNone(cs)) throw Error("Duplicate component state: " + componentStateId);
        // Might be an error?
        // if (!isNone(cs)) console.log("Duplicate component state: " + componentStateId);
      } // Register the component
      // let state = JSON.parse(JSON.stringify(get(this, 'defaultState') || {}))


      let state = Object.assign({}, Ember.get(this, "defaultState"), {
        componentId: Ember.get(this, "componentId")
      });
      Ember.guidFor(state); // Set the state of the component - should be the default state initially

      let appState = Ember.get(this, "store").findInMemory("app-state", Ember.get(this, "appState.id"));
      let componentState = Ember.get(appState, `attributes.${componentStateId}`);

      if (Ember.isNone(componentState)) {
        Ember.set(appState, `attributes.${componentStateId}`, state);
      }

      Ember.set(this, "state", Ember.get(appState, `attributes.${componentStateId}`));
    },

    // We have to register a component if it's componentId changes
    didUpdateAttrs() {
      this._super(...arguments);

      if (Ember.get(this, "componentId") && this._oldComponentId !== Ember.get(this, "componentId")) {
        this.registeredComponent = false;

        this._registerComponent();

        this.set("_oldComponentId", Ember.get(this, "componentId"));
      }
    },

    willDestroyElement() {
      this._super(...arguments); // Get the component's state id


      let componentStateId = Ember.get(this, "componentId");
      let appState = Ember.get(this, "store").findInMemory("app-state", Ember.get(this, "appState.id"));
      Ember.set(appState, `attributes.${componentStateId}`, null); // Deregister the component
      // get(this, 'store').dispatch("DEREGISTER_COMPONENT", {
      //   sessionId:   get(this, 'appState.id'),
      //   componentId: componentStateId,
      // })
    }

  });

  _exports.default = _default;
});