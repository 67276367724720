define("cc-frontend/components/planner/planbook/create-edit/templates-by-name/list.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "templates-by-name": "_templates-by-name_1c4s0e",
    "list": "_list_1c4s0e",
    "create-button": "_create-button_1c4s0e d-s-shadowed-button"
  };
  _exports.default = _default;
});