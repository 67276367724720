define("cc-frontend/components/icons/ios-close-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    x="0px"
    y="0px"
    width="512px"
    height="512px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M403.1,108.9c-81.2-81.2-212.9-81.2-294.2,0s-81.2,212.9,0,294.2c81.2,81.2,212.9,81.2,294.2,0
  			S484.3,190.1,403.1,108.9z M390.8,390.8c-74.3,74.3-195.3,74.3-269.6,0c-74.3-74.3-74.3-195.3,0-269.6s195.3-74.3,269.6,0
  			C465.2,195.5,465.2,316.5,390.8,390.8z"
        ></path>
      </g>
      <polygon
        class="st0"
        points="340.2,160 255.8,244.2 171.8,160.4 160,172.2 244,256 160,339.8 171.8,351.6 255.8,267.8 340.2,352
  		352,340.2 267.6,256 352,171.8"
      ></polygon>
    </g>
  </svg>
  */
  {
    "id": "n94wPfHK",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"512px\"],[14,\"height\",\"512px\"],[14,\"viewBox\",\"0 0 512 512\"],[14,5,\"enable-background:new 0 0 512 512;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"g\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,0,\"st0\"],[14,\"d\",\"M403.1,108.9c-81.2-81.2-212.9-81.2-294.2,0s-81.2,212.9,0,294.2c81.2,81.2,212.9,81.2,294.2,0\\n\\t\\t\\tS484.3,190.1,403.1,108.9z M390.8,390.8c-74.3,74.3-195.3,74.3-269.6,0c-74.3-74.3-74.3-195.3,0-269.6s195.3-74.3,269.6,0\\n\\t\\t\\tC465.2,195.5,465.2,316.5,390.8,390.8z\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"polygon\"],[14,0,\"st0\"],[14,\"points\",\"340.2,160 255.8,244.2 171.8,160.4 160,172.2 244,256 160,339.8 171.8,351.6 255.8,267.8 340.2,352\\n\\t\\t352,340.2 267.6,256 352,171.8\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/ios-close-outline.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});