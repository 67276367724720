define("cc-frontend/lib/actions/course/calendar/IMPORT_TRANSFERRABLE_ARRAY", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "moment"], function (_exports, _patchUpdater, _unitUtils, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "IMPORT_TRANSFERRABLE_ARRAY",
    params: {
      transferrableArray: "array",
      newStartDate: "string",
      course: "object",
      planbook: "object",
      rotationCalendar: "object"
    },

    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let newCourseDates = (0, _unitUtils.insertRange)(payload.transferrableArray, payload.newStartDate, 1, {}, payload.course.attributes.calendar.dates, consolidatedCourseDatesOff, schoolDays);
      let ret;

      try {
        ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
      } catch (e) {
        console.error(e);
        return [];
      }

      let {
        courseDates,
        units
      } = ret;

      let patch = _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let course = findFn("course", payload.course.id);
      let planbook = findFn("planbook", course.relationships.planbook.data.id);
      let formattedDateString = (0, _moment.default)(payload.newStartDate).format("ddd, MMM. D, YYYY");
      return {
        title: `Imported ${payload.lessonCount} lessons and ${payload.unitCount} units`,
        titleForAnalytics: "Import Lessons Modal - Import Successful",
        description: `Imported onto ${formattedDateString} in "${payload.course.attributes.title}"`,
        context: {
          lessonCount: payload.lessonCount,
          unitCount: payload.unitCount,
          courseTitle: Ember.get(course, "attributes.title"),
          planbookTitle: Ember.get(planbook, "attributes.title"),
          courseId: Ember.get(course, "id"),
          planbookId: Ember.get(planbook, "id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${payload.course.id}`,
          to: [`course-edits:course-${course.id}`, `planbook-edits:planbook-${planbook.id}`]
        }
      };
    }

  };
  _exports.default = _default;
});