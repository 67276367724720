define("cc-frontend/helpers/commonmark-it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convert = convert;
  _exports.default = void 0;
  var reader = new commonmark.Parser();
  var writer = new commonmark.HtmlRenderer();

  function convert(params
  /*, hash*/
  ) {
    let regex = /\<link\s"([a-zA-Z\d]*)"\>(.*)\<\/link\>/g;
    var text = params[0] || "";
    text = text.replace(regex, (_match, linkId, linkText) => {
      return `<a href="/help/${linkId}">${linkText}</a>`;
    });
    var parsed = reader.parse(text);
    var rendered = writer.render(parsed);
    return Ember.String.htmlSafe(rendered);
  }

  var _default = Ember.Helper.helper(convert);

  _exports.default = _default;
});