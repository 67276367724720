define("cc-frontend/services/callbacks/action/log", ["exports", "cc-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(actionStream) {
    actionStream.onValue(([type, _payload, actionDoc]) => {
      if (type === "REGISTER_COMPONENT" || type === "DEREGISTER_COMPONENT") return;

      if (window._LOG_PAYLOAD_ACTION_) {
        console.log("🚀 " + type, `${Ember.get(actionDoc, "attributes.narrative.title")} - ${Ember.get(actionDoc, "attributes.narrative.description")}. (Analytics Title: ${Ember.get(actionDoc, "attributes.narrative.titleForAnalytics")})`, actionDoc); // Only log in development or if Raven isn't defined (which would be in dev and )
      } else if (_environment.default.environment === "development") {
        console.log("🚀 " + type, `${Ember.get(actionDoc, "attributes.narrative.title")} - ${Ember.get(actionDoc, "attributes.narrative.description")}. (Analytics Title: ${Ember.get(actionDoc, "attributes.narrative.titleForAnalytics")})`);
      }
    });
  }
});