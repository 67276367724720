define("cc-frontend/components/d-s/three-dot-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="three-dot-spinner" ...attributes>
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
  */
  {
    "id": "UNUOgeE6",
    "block": "[[[11,0],[24,0,\"three-dot-spinner\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"bounce1\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"bounce2\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"bounce3\"],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/d-s/three-dot-spinner.hbs",
    "isStrictMode": false
  });

  class ThreeDotSpinnerComponent extends Ember.Component {}

  _exports.default = ThreeDotSpinnerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ThreeDotSpinnerComponent);
});