define("cc-frontend/components/planner/perspectives/month.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "month-mega-wrapper": "_month-mega-wrapper_168wqi",
    "--sidebar-is-visible": "_--sidebar-is-visible_168wqi",
    "month-wrapper": "_month-wrapper_168wqi",
    "month-sidebar-wrapper": "_month-sidebar-wrapper_168wqi",
    "month-sidebar-sticky-noticer": "_month-sidebar-sticky-noticer_168wqi",
    "month-sidebar": "_month-sidebar_168wqi",
    "month-sidebar__close": "_month-sidebar__close_168wqi",
    "month-sidebar__header": "_month-sidebar__header_168wqi",
    "month-sidebar__header__left": "_month-sidebar__header__left_168wqi",
    "month-sidebar__header__right": "_month-sidebar__header__right_168wqi",
    "month-sidebar__header__day-of-the-week": "_month-sidebar__header__day-of-the-week_168wqi",
    "month-sidebar__header__day-of-the-week__rotation-day": "_month-sidebar__header__day-of-the-week__rotation-day_168wqi",
    "month-sidebar__header__day": "_month-sidebar__header__day_168wqi",
    "month-sidebar__lesson-list": "_month-sidebar__lesson-list_168wqi",
    "month": "_month_168wqi",
    "month__header": "_month__header_168wqi",
    "month__header__day": "_month__header__day_168wqi",
    "month__body": "_month__body_168wqi",
    "month__body__week": "_month__body__week_168wqi"
  };
  _exports.default = _default;
});