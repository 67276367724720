define("cc-frontend/components/group/members-table/headers/planbooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-flex tw-flex-row tw-justify-start tw-items-center">
    <div class="group-members-table__header__header tw-text-left">
      Shared Planbooks
    </div>
    <PowerSelect
      @triggerClass="group-members-table__school-year-selector"
      @dropdownClass="school-show__headline__buttons__dropdown__list"
      @selected={{@selectedSchoolYear}}
      @options={{@schoolYears}}
      @searchEnabled={{false}}
      @triggerComponent="school-year-select-trigger"
      @onChange={{@selectSchoolYear}}
      as |schoolYear|
    >
      {{schoolYear.attributes.longAbbreviation}}
    </PowerSelect>
  </div>
  */
  {
    "id": "6ksz0eLX",
    "block": "[[[10,0],[14,0,\"tw-flex tw-flex-row tw-justify-start tw-items-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"group-members-table__header__header tw-text-left\"],[12],[1,\"\\n    Shared Planbooks\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@triggerClass\",\"@dropdownClass\",\"@selected\",\"@options\",\"@searchEnabled\",\"@triggerComponent\",\"@onChange\"],[\"group-members-table__school-year-selector\",\"school-show__headline__buttons__dropdown__list\",[30,1],[30,2],false,\"school-year-select-trigger\",[30,3]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,4,[\"attributes\",\"longAbbreviation\"]]],[1,\"\\n  \"]],[4]]]]],[1,\"\\n\"],[13]],[\"@selectedSchoolYear\",\"@schoolYears\",\"@selectSchoolYear\",\"schoolYear\"],false,[\"power-select\"]]",
    "moduleName": "cc-frontend/components/group/members-table/headers/planbooks.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});