define("cc-frontend/templates/new-group/show/edit/members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FI8k28/8",
    "block": "[[[41,[30,1,[\"attributes\",\"isEnabled\"]],[[[41,[28,[37,1],[[30,1,[\"meta\",\"permissions\"]],\"can-manage-group\"],null],[[[1,\"    \"],[8,[39,2],null,[[\"@group\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@stateName\"],[\"group-closed\"]],null],[1,\"\\n\"],[1,\"  \"],[1,[54,[\"Permissions\",[30,1,[\"meta\",\"permissions\"]]]]],[1,\"\\n\"],[41,[28,[37,1],[[30,1,[\"meta\",\"permissions\"]],\"can-manage-group-as-support\"],null],[[[1,\"    \"],[8,[39,2],null,[[\"@group\"],[[30,1]]],null],[1,\"\\n\"]],[]],null]],[]]]],[\"@model\"],false,[\"if\",\"includes\",\"school/edit/members\",\"school/setup-permissions-notice\",\"school/edit/payment/notice-bar\",\"log\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/edit/members.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});