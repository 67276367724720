define("cc-frontend/components/icons/spinning-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    style="enable-background:new 0 0 50 50;"
    xml:space="preserve"
    ...attributes
  >
    <path
      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      transform="rotate(339.122 25 25)"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="0.6s"
        repeatCount="indefinite"
      ></animateTransform>
    </path>
  </svg>
  */
  {
    "id": "7qVU2mdy",
    "block": "[[[11,\"svg\"],[24,\"version\",\"1.1\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[24,\"x\",\"0px\"],[24,\"y\",\"0px\"],[24,\"viewBox\",\"0 0 50 50\"],[24,5,\"enable-background:new 0 0 50 50;\"],[24,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[17,1],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z\"],[14,\"transform\",\"rotate(339.122 25 25)\"],[12],[1,\"\\n    \"],[10,\"animateTransform\"],[14,\"attributeType\",\"xml\"],[14,\"attributeName\",\"transform\"],[14,\"from\",\"0 25 25\"],[14,\"to\",\"360 25 25\"],[14,\"dur\",\"0.6s\"],[14,\"repeatCount\",\"indefinite\"],[14,4,\"rotate\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/spinning-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});