define("cc-frontend/components/planner/course/create-edit/create-main.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "main-screen": "_main-screen_13zyu5 _main-screen_1o1brh",
    "nav-box-wrapper": "_nav-box-wrapper_13zyu5 _nav-box--base_1o1brh",
    "EVALUATE_TEMPLATES": "_EVALUATE_TEMPLATES_13zyu5",
    "nav-box__top--inherited": "_nav-box__top--inherited_13zyu5 _nav-box__top_1o1brh",
    "nav-box__top": "_nav-box__top_13zyu5",
    "nav-box__head": "_nav-box__head_13zyu5 _nav-box__head_1o1brh",
    "--evaluate-templates": "_--evaluate-templates_13zyu5",
    "nav-box__subhead": "_nav-box__subhead_13zyu5 _nav-box__subhead_1o1brh",
    "nav-box--grid": "_nav-box--grid_13zyu5",
    "nav-box--edit-template-grid": "_nav-box--edit-template-grid_13zyu5",
    "nav-box__back-arrow": "_nav-box__back-arrow_13zyu5",
    "evaluate-button": "_evaluate-button_13zyu5",
    "edit-template": "_edit-template_13zyu5",
    "nav-box__main": "_nav-box__main_13zyu5",
    "nav-box__next-arrow": "_nav-box__next-arrow_13zyu5 d-s-shadowed-button",
    "nav-box__evaluate-buttons": "_nav-box__evaluate-buttons_13zyu5",
    "default-focus": "_default-focus_13zyu5",
    "evaluate-button__icon": "_evaluate-button__icon_13zyu5",
    "evaluate-button__text": "_evaluate-button__text_13zyu5",
    "final-screen": "_final-screen_13zyu5",
    "final-screen__video": "_final-screen__video_13zyu5",
    "nav-box--final-screen": "_nav-box--final-screen_13zyu5"
  };
  _exports.default = _default;
});