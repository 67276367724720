define("cc-frontend/components/icons/question-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M12,23.501c-6.341,0 -11.5,-5.159 -11.5,-11.5c0,-6.341 5.159,-11.5 11.5,-11.5c6.341,0 11.5,5.159 11.5,11.5c0,6.341 -5.159,11.5 -11.5,11.5Zm0,-22c-5.79,-1.33227e-15 -10.5,4.71 -10.5,10.5c0,5.79 4.71,10.5 10.5,10.5c5.79,0 10.5,-4.71 10.5,-10.5c0,-5.79 -4.71,-10.5 -10.5,-10.5Z"
      ></path>
      <path
        d="M12.5303,16.7197c0.292893,0.292893 0.292893,0.767767 0,1.06066c-0.292893,0.292893 -0.767767,0.292893 -1.06066,0c-0.292893,-0.292893 -0.292893,-0.767767 0,-1.06066c0.292893,-0.292893 0.767767,-0.292893 1.06066,0"
      ></path>
      <path
        d="M12,15.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-0.412c0,-0.994 0.589,-1.894 1.5,-2.291c0.911,-0.397 1.5,-1.297 1.5,-2.291c0,-0.667 -0.26,-1.294 -0.733,-1.767c-0.473,-0.473 -1.1,-0.733 -1.767,-0.734c-0.668,0 -1.296,0.26 -1.768,0.732c-0.472,0.472 -0.732,1.1 -0.732,1.768c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.935 0.364,-1.814 1.026,-2.475c0.661,-0.661 1.54,-1.025 2.474,-1.025c0.934,0 1.813,0.365 2.474,1.026c0.662,0.662 1.026,1.54 1.026,2.474c0,1.392 -0.824,2.651 -2.1,3.208c-0.547,0.239 -0.9,0.778 -0.9,1.375v0.412c0,0.276 -0.224,0.5 -0.5,0.5Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "Y7jF/WjR",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,23.501c-6.341,0 -11.5,-5.159 -11.5,-11.5c0,-6.341 5.159,-11.5 11.5,-11.5c6.341,0 11.5,5.159 11.5,11.5c0,6.341 -5.159,11.5 -11.5,11.5Zm0,-22c-5.79,-1.33227e-15 -10.5,4.71 -10.5,10.5c0,5.79 4.71,10.5 10.5,10.5c5.79,0 10.5,-4.71 10.5,-10.5c0,-5.79 -4.71,-10.5 -10.5,-10.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12.5303,16.7197c0.292893,0.292893 0.292893,0.767767 0,1.06066c-0.292893,0.292893 -0.767767,0.292893 -1.06066,0c-0.292893,-0.292893 -0.292893,-0.767767 0,-1.06066c0.292893,-0.292893 0.767767,-0.292893 1.06066,0\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,15.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-0.412c0,-0.994 0.589,-1.894 1.5,-2.291c0.911,-0.397 1.5,-1.297 1.5,-2.291c0,-0.667 -0.26,-1.294 -0.733,-1.767c-0.473,-0.473 -1.1,-0.733 -1.767,-0.734c-0.668,0 -1.296,0.26 -1.768,0.732c-0.472,0.472 -0.732,1.1 -0.732,1.768c0,0.276 -0.224,0.5 -0.5,0.5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.935 0.364,-1.814 1.026,-2.475c0.661,-0.661 1.54,-1.025 2.474,-1.025c0.934,0 1.813,0.365 2.474,1.026c0.662,0.662 1.026,1.54 1.026,2.474c0,1.392 -0.824,2.651 -2.1,3.208c-0.547,0.239 -0.9,0.778 -0.9,1.375v0.412c0,0.276 -0.224,0.5 -0.5,0.5Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/question-simple.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});