define("cc-frontend/components/planner/menus/planbook-menu/edit-title/body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "edit-form": "_edit-form_gymkjb",
    "title": "_title_gymkjb"
  };
  _exports.default = _default;
});