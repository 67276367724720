define("cc-frontend/components/planner/course/create-edit/onboarding-videos/two", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (match-media "(max-width: 649px)")}}
    <Planner::Course::CreateEdit::OnboardingVideos::Base @videoId="4uf3eee5yt" @isMobile={{true}} />
  {{else}}
    <Planner::Course::CreateEdit::OnboardingVideos::Base @videoId="nnpx7mo8lv" />
  {{/if}}
  */
  {
    "id": "vV+YE8MI",
    "block": "[[[41,[28,[37,1],[\"(max-width: 649px)\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@videoId\",\"@isMobile\"],[\"4uf3eee5yt\",true]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@videoId\"],[\"nnpx7mo8lv\"]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"match-media\",\"planner/course/create-edit/onboarding-videos/base\"]]",
    "moduleName": "cc-frontend/components/planner/course/create-edit/onboarding-videos/two.hbs",
    "isStrictMode": false
  });

  let OnboardingVideoTwo = (_dec = (0, _component.tagName)(""), _dec(_class = class OnboardingVideoTwo extends Ember.Component {}) || _class);
  _exports.default = OnboardingVideoTwo;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OnboardingVideoTwo);
});