define("cc-frontend/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OJdrMFUI",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"marketing.home\"]],null],[1,\"\\n\"],[10,3],[14,6,\"users/sign\"],[12],[1,\"Sign In/Up\"],[13]],[],false,[\"record-page-visit\"]]",
    "moduleName": "cc-frontend/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});