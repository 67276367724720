define("cc-frontend/components/icons/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    <path
      d="M11.257,23.5c-0.144,0 -0.281,-0.062 -0.375,-0.169c-0.182,-0.207 -0.162,-0.524 0.045,-0.706l11.501,-10.125h-21.671c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h21.669l-11.5,-10.125c-0.1,-0.088 -0.16,-0.21 -0.169,-0.343c-0.008,-0.133 0.036,-0.262 0.124,-0.362c0.095,-0.108 0.232,-0.17 0.375,-0.17c0.122,0 0.239,0.044 0.33,0.125l11.644,10.251c0.047,0.042 0.092,0.086 0.134,0.134c0.218,0.247 0.344,0.555 0.365,0.893c0.007,0.026 0.013,0.058 0.013,0.097c0,0.026 -0.003,0.048 -0.007,0.065c0,0.009 0,0.018 0,0.028c-0.025,0.402 -0.205,0.768 -0.505,1.033l-11.643,10.25c-0.091,0.08 -0.209,0.124 -0.33,0.124Z"
    ></path>
  </svg>
  */
  {
    "id": "bjXgpEGt",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M11.257,23.5c-0.144,0 -0.281,-0.062 -0.375,-0.169c-0.182,-0.207 -0.162,-0.524 0.045,-0.706l11.501,-10.125h-21.671c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h21.669l-11.5,-10.125c-0.1,-0.088 -0.16,-0.21 -0.169,-0.343c-0.008,-0.133 0.036,-0.262 0.124,-0.362c0.095,-0.108 0.232,-0.17 0.375,-0.17c0.122,0 0.239,0.044 0.33,0.125l11.644,10.251c0.047,0.042 0.092,0.086 0.134,0.134c0.218,0.247 0.344,0.555 0.365,0.893c0.007,0.026 0.013,0.058 0.013,0.097c0,0.026 -0.003,0.048 -0.007,0.065c0,0.009 0,0.018 0,0.028c-0.025,0.402 -0.205,0.768 -0.505,1.033l-11.643,10.25c-0.091,0.08 -0.209,0.124 -0.33,0.124Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/arrow-right.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});