define("cc-frontend/lib/actions/lesson/LESSON_SELECT_PLANBOOK_TEMPLATE", ["exports", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/lib/actions/lesson/LESSON_PASTE"], function (_exports, _defaultLessonContext, _defaultLessonModels, _LESSON_PASTE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LESSON_SELECT_PLANBOOK_TEMPLATE = void 0;
  const LESSON_SELECT_PLANBOOK_TEMPLATE = {
    name: "LESSON_SELECT_PLANBOOK_TEMPLATE",
    params: _LESSON_PASTE.default.params,
    patches: _LESSON_PASTE.default.patches,

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let lessonTitle = Ember.get(payload, "lessonAttributes.title");
      return {
        title: null,
        titleForAnalytics: "Planbook - Select initial lesson template",
        description: `Selected "${lessonTitle}" template`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: null
      };
    }

  };
  _exports.LESSON_SELECT_PLANBOOK_TEMPLATE = LESSON_SELECT_PLANBOOK_TEMPLATE;
});