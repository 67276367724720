define("cc-frontend/lib/actions/course/calendar/ADD_UNIT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "cc-frontend/models/course-calendar-date-custom", "lodash", "moment"], function (_exports, _patchUpdater, _unitUtils, _courseCalendarDateCustom, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "ADD_UNIT",
    params: {
      newUnitId: "string",
      unitStart: "string",
      course: "object",
      planbook: "object",
      rotationCalendar: "object",
      unitCards: "object"
    },

    patches(payload) {
      let createUnitPatch = _patchUpdater.default.create("card-stack", payload.newUnitId).set("attributes.usedAs", "unit").set("attributes.cards", payload.unitCards).set("meta", {
        permissions: ["can-edit-plan", "can-view-plan"]
      }).set("relationships.course.data", {
        id: payload.course.id,
        type: "course"
      }).set("relationships.planbook.data", {
        id: payload.planbook.id,
        type: "planbook"
      }).patch;

      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);

      let courseDateExists = _lodash.default.chain(payload.course.attributes.calendar.dates).filter(date => date.type === "course-date-custom").find(date => date.attributes.dateString === payload.unitStart).value();

      let courseDatesWithUnit;

      if (courseDateExists === undefined || courseDateExists === null) {
        courseDatesWithUnit = payload.course.attributes.calendar.dates.concat([{
          id: payload.unitStart,
          type: "course-date-custom",
          attributes: new _courseCalendarDateCustom.default({
            dateString: payload.unitStart,
            unitStart: [payload.newUnitId],
            unitEnd: [payload.newUnitId],
            unitIds: [payload.newUnitId]
          })
        }]);
      } else {
        courseDatesWithUnit = _lodash.default.map(payload.course.attributes.calendar.dates, courseDate => {
          if (courseDate.attributes.dateString === payload.unitStart) {
            let cd = _lodash.default.cloneDeep(courseDate);

            cd.attributes.unitStart.push(payload.newUnitId);
            cd.attributes.unitEnd.push(payload.newUnitId);
            return cd;
          } else {
            return courseDate;
          }
        });
      }

      let {
        courseDates,
        units
      } = (0, _unitUtils.constructUnitsAndDates)(courseDatesWithUnit, consolidatedCourseDatesOff, payload.rotationCalendar.attributes.schoolDays);

      let addUnitToCalendarPatch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", courseDates).set("attributes.calendar.units", units).inc("attributes.calendar.version", 1).patch;

      return [createUnitPatch, addUnitToCalendarPatch];
    },

    undoPatches(payload) {
      return _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).inc("attributes.calendar.version", 1).wrapInArray;
    },

    narrative(payload, findFn, userId) {
      let course = payload.course;
      let models = {
        course: course,
        planbook: findFn("planbook", _lodash.default.get(course, "relationships.planbook.data.id")),
        unit: findFn("card-stack", payload.newUnitId) || findFn("card-stack-summary", payload.newUnitId)
      };
      let courseTitle = Ember.get(models, "course.attributes.title");
      let newDateFormatted = (0, _moment.default)(payload.unitStart).format("ddd, MMM. D, YYYY");
      return {
        title: "Added Unit",
        titleForAnalytics: "Unit - Added",
        description: `Added unit to your ${courseTitle} class on ${newDateFormatted}`,
        context: {
          courseTitle,
          planbookTitle: Ember.get(models, "planbook.attributes.title"),
          unitId: payload.newUnitId,
          courseId: Ember.get(models, "course.id"),
          planbookId: Ember.get(models, "planbook.id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${payload.course.id}`,
          to: [`course-edits:course-${payload.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        }
      };
    }

  };
  _exports.default = _default;
});