define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_TOGGLE_PUBLIC", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LESSON_STANDARDS_TOGGLE_PUBLIC = void 0;
  const LESSON_STANDARDS_TOGGLE_PUBLIC = {
    name: "LESSON_STANDARDS_TOGGLE_PUBLIC",
    params: {
      cardStackId: "string",
      cardId: "string",
      value: "boolean",
      calledFrom: "string"
    },

    // are we not going to make this an undoable action? we don't send over an originalValue so I'm assuming not right now.
    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).set("embedded", "attributes.isPublic", payload.value).patch;

      return [patch];
    },

    // TODO ADd undo patches
    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        isPublic: payload.value,
        calledFrom: payload.calledFrom
      });

      let count = Ember.get(payload, "standards.length");
      let noun = count === 1 ? "standard" : "standards";
      let description = `Pasted ${count} ${noun}`;
      return {
        title: "Lesson Standards Pasted",
        titleForAnalytics: "Standards - toggle public",
        description: description,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models)
      };
    }

  };
  _exports.LESSON_STANDARDS_TOGGLE_PUBLIC = LESSON_STANDARDS_TOGGLE_PUBLIC;
});