define("cc-frontend/components/admin/card-stack/sidebar", ["exports", "tracked-built-ins"], function (_exports, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="admin-doc__top-bar">
    <div class="admin-doc-label admin-doc-label--card-stack">
      <div class="admin-doc-label__icon">
        <Icons::Document />
      </div>
      <div class="admin-doc-label__text">
        Card Stack
      </div>
    </div>
    <div
      class="admin-doc-button"
      id="action-menu-trigger"
      {{action (mut canShowActionMenu) (not canShowActionMenu)}}
    >
      <div class="admin-doc-button__icon">
        <Icons::IosGear />
      </div>
      <div class="admin-doc-button__text">
        Actions
      </div>
    </div>
    {{#if canShowActionMenu}}
      <LiquidWormhole @class="dialog-backdrop" @click={{action (mut canShowActionMenu) false}} />
      <LiquidTether
        @target="#action-menu-trigger"
        @offset="0 -24px"
        @targetAttachment="bottom center"
        @attachment="top center"
        @class="sierra-subtle-dropdown"
      >
        <div class="sierra-subtle-dropdown__options">
          {{! <div class="sierra-subtle-dropdown__options__option">Transfer to New Owner</div>
            <div class="sierra-subtle-dropdown__options__option">Clean Up Course Ids</div> }}
        </div>
      </LiquidTether>
    {{/if}}
  </div>
  <div class="admin-doc-h1">
    {{model.attributes.title}}
  </div>
  <Admin::VersionBox
    @model={{model}}
    @selectVersion={{selectVersion}}
    @currentVersion={{currentVersion}}
  />
  */
  {
    "id": "6w/W0pwX",
    "block": "[[[10,0],[14,0,\"admin-doc__top-bar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-doc-label admin-doc-label--card-stack\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"admin-doc-label__icon\"],[12],[1,\"\\n      \"],[8,[39,0],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"admin-doc-label__text\"],[12],[1,\"\\n      Card Stack\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,0,\"admin-doc-button\"],[24,1,\"action-menu-trigger\"],[4,[38,1],[[30,0],[28,[37,2],[[33,3]],null],[28,[37,4],[[33,3]],null]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"admin-doc-button__icon\"],[12],[1,\"\\n      \"],[8,[39,5],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"admin-doc-button__text\"],[12],[1,\"\\n      Actions\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[8,[39,7],null,[[\"@class\",\"@click\"],[\"dialog-backdrop\",[28,[37,1],[[30,0],[28,[37,2],[[33,3]],null],false],null]]],null],[1,\"\\n    \"],[8,[39,8],null,[[\"@target\",\"@offset\",\"@targetAttachment\",\"@attachment\",\"@class\"],[\"#action-menu-trigger\",\"0 -24px\",\"bottom center\",\"top center\",\"sierra-subtle-dropdown\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"sierra-subtle-dropdown__options\"],[12],[1,\"\\n\"],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,0],[14,0,\"admin-doc-h1\"],[12],[1,\"\\n  \"],[1,[33,9,[\"attributes\",\"title\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,10],null,[[\"@model\",\"@selectVersion\",\"@currentVersion\"],[[36,9],[36,11],[36,12]]],null]],[],false,[\"icons/document\",\"action\",\"mut\",\"canShowActionMenu\",\"not\",\"icons/ios-gear\",\"if\",\"liquid-wormhole\",\"liquid-tether\",\"model\",\"admin/version-box\",\"selectVersion\",\"currentVersion\"]]",
    "moduleName": "cc-frontend/components/admin/card-stack/sidebar.hbs",
    "isStrictMode": false
  });

  let AdminSidebarCardStack = (_dec = Ember.inject.service, (_class = class AdminSidebarCardStack extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "canShowActionMenu", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "canShowActionMenu", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = AdminSidebarCardStack;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AdminSidebarCardStack);
});