define("cc-frontend/lib/actions/course/calendar/TURN_DATE_OFF_AND", ["exports", "lodash-es", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater", "moment"], function (_exports, _lodashEs, _datesOffCalculator, _patchUpdater, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTurnDateOffAnd = prepareTurnDateOffAnd;
  _exports.default = void 0;

  function prepareTurnDateOffAnd(payload) {
    return {
      name: "TURN_DATE_OFF_AND",
      payload: payload,
      patches: payload.bumpOrDeletePatches.concat(patches(payload)),
      undoPatches: payload.bumpOrDeleteUndoPatches.concat(undoPatches(payload)),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  var _default = prepareTurnDateOffAnd;
  _exports.default = _default;

  let patches = function (payload) {
    let newAnnotations = payload.course.attributes.calendar.dateAnnotations.concat([payload.dateAnnotation]);
    let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(newAnnotations);

    let patch = _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dateAnnotations", payload.dateAnnotation).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

    return [patch.patch];
  };

  let undoPatches = function (payload) {
    let patch = _patchUpdater.default.create("course", payload.course.id).pull("attributes.calendar.dateAnnotations", payload.dateAnnotation).set("attributes.calendar.datesOff", JSON.parse(JSON.stringify(payload.course.attributes.calendar.datesOff || []))).inc("attributes.calendar.version", 1);

    return [patch.patch];
  };

  let narrative = function (payload, findFn, userId) {
    let formattedDate = (0, _moment.default)(payload.dateString).format("MMMM D, YYYY");
    let bumpedOrErasedPhrase;

    switch (payload.bumpOrDelete) {
      case "BUMP":
        bumpedOrErasedPhrase = " and bumped the lesson.";
        break;

      case "DELETE":
        bumpedOrErasedPhrase = " and erased the lesson.";
        break;

      case "NOTHING":
        bumpedOrErasedPhrase = ".";
        break;

      default:
        "";
    }

    return {
      title: `Turned Class Off`,
      titleForAnalytics: `Lesson - Toggle Off`,
      description: `Turned ${payload.course.attributes.title} off on ${formattedDate}${bumpedOrErasedPhrase}`,
      activity: {},
      context: {
        dateString: payload.dateString,
        courseId: payload.course.id,
        courseTitle: payload.course.attributes.title
      }
    };
  };
});