define("cc-frontend/models/hydrated-date", ["exports", "cc-frontend/lib/structor_2"], function (_exports, _structor_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HydratedDate extends _structor_.default {
    defineStruct() {
      return {
        dateString: null,
        cardStackId: null,
        unitStart: [],
        unitEnd: [],
        unitIds: [],
        isMoving: false,
        index: 0
      };
    }

  }

  _exports.default = HydratedDate;
});