define("cc-frontend/components/admin/class-website/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="admin-overview-table">
    <tbody>
      <tr>
        <td>
          Title
        </td>
        <td>
          {{modelToStringify.attributes.title}}
        </td>
      </tr>
  
      <tr>
        <td>
          Planbook
        </td>
        <td>
          <PlanbookSummaryProvider
            @planbookId={{modelToStringify.relationships.planbook.data.id}}
            as |planbook|
          >
            <LinkTo @route="admin.planbook" @model={{planbook.id}}>
              {{planbook.content.attributes.title}}<br />
            </LinkTo>
          </PlanbookSummaryProvider>
        </td>
      </tr>
      <tr>
        <td>
          Courses
        </td>
        <td>
          {{#each modelToStringify.relationships.courses.data as |map|}}
            <CourseSummaryProvider @id={{map.id}} as |course|>
              <LinkTo @route="admin.course" @model={{course.id}}>
                {{course.attributes.title}}<br />
              </LinkTo>
            </CourseSummaryProvider>
          {{else}}
            None
          {{/each}}
        </td>
      </tr>
    </tbody>
  </table>
  {{yield}}
  */
  {
    "id": "y0yy5K+n",
    "block": "[[[10,\"table\"],[14,0,\"admin-overview-table\"],[12],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n        Title\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n        \"],[1,[33,0,[\"attributes\",\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n        Planbook\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@planbookId\"],[[33,0,[\"relationships\",\"planbook\",\"data\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"admin.planbook\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"content\",\"attributes\",\"title\"]]],[10,\"br\"],[12],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[1]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n        Courses\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,0,[\"relationships\",\"courses\",\"data\"]]],null]],null],null,[[[1,\"          \"],[8,[39,5],null,[[\"@id\"],[[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"admin.course\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,3,[\"attributes\",\"title\"]]],[10,\"br\"],[12],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"]],[3]]]]],[1,\"\\n\"]],[2]],[[[1,\"          None\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,4,null]],[\"planbook\",\"map\",\"course\",\"&default\"],false,[\"modelToStringify\",\"planbook-summary-provider\",\"link-to\",\"each\",\"-track-array\",\"course-summary-provider\",\"yield\"]]",
    "moduleName": "cc-frontend/components/admin/class-website/detail.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});