define("cc-frontend/components/planner/template-chooser/choose-courses-for-templ", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "course-pill-list": "_course-pill-list_si62w5",
    "course-pill": "_course-pill_si62w5",
    "course-pill__title": "_course-pill__title_si62w5",
    "course-pill__checkbox": "_course-pill__checkbox_si62w5"
  };
  _exports.default = _default;
});