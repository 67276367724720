define("cc-frontend/controllers/user/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SignInController extends Ember.Controller {}

  _exports.default = SignInController;
});