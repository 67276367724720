define("cc-frontend/components/planner/planbook/planbook-date-combined-glimmer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.planbookDateCombined}}
  */
  {
    "id": "/mYtcRiO",
    "block": "[[[18,1,[[30,0,[\"planbookDateCombined\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/planbook-date-combined-glimmer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});