define("cc-frontend/helpers/has-many", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasMany;

  /**
   * A macro that returns a computed property that returns an array of proxy objects
   * of the type specified in the relationshipType parameter
  
   * @param  {String}  modelName - string of model/object to look within in the JSON:API
   * @param  {String}  relationshipType - type of object to be returned from CP
   * @param  {String}  relationship - string of key to look for in relationship object of modelName object in JSON:API
   * @return {Array<PromiseProxyObject>} - an array of promiseProxyObjects that can be accessed
   * via the content property. Content will be null if no fetch request has been made.
   */
  function hasMany(modelName, relationshipType, relationship) {
    /**
     * Example
       ``` javascript
        class exampleComponent extends Component {
          @hasMany("model", "course", "deletedCourses") deletedCourses
      }
      ```
         @return {[Array<PromiseProxyObject>]} an array of promiseProxyObjects of courses that have been deleted.
        Each course can be accessed via the content property. Content will be null if no fetch request has been made.
     */
    return Ember.computed(`${modelName}.relationships.${relationship}.data.@each.id`, function () {
      const ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
      if (Ember.get(this, "store") === null) throw new Error("You need to inject the store before using hasMany: " + this.toString()); // line all ids up in one array

      let ids = _lodash.default.chain(Ember.get(this, `${modelName}.relationships.${relationship}.data`)).map(r => Ember.get(r, "id")).reject(id => id === "" || id === null).value();

      return _lodash.default.map(ids, id => {
        return ObjectPromiseProxy.create({
          promise: Ember.get(this, "store").find(relationshipType, id)
        });
      });
    });
  }
});