define("cc-frontend/lib/phoenix/socket", ["exports", "cc-frontend/lib/phoenix/constants", "cc-frontend/lib/phoenix/utils", "cc-frontend/lib/phoenix/ajax", "cc-frontend/lib/phoenix/channel", "cc-frontend/lib/phoenix/longpoll", "cc-frontend/lib/phoenix/serializer", "cc-frontend/lib/phoenix/timer"], function (_exports, _constants, _utils, _ajax, _channel, _longpoll, _serializer, _timer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Initializes the Socket *
   *
   * For IE8 support use an ES5-shim (https://github.com/es-shims/es5-shim)
   *
   * @param {string} endPoint - The string WebSocket endpoint, ie, `"ws://example.com/socket"`,
   *                                               `"wss://example.com"`
   *                                               `"/socket"` (inherited host & protocol)
   * @param {Object} [opts] - Optional configuration
   * @param {Function} [opts.transport] - The Websocket Transport, for example WebSocket or Phoenix.LongPoll.
   *
   * Defaults to WebSocket with automatic LongPoll fallback.
   * @param {Function} [opts.encode] - The function to encode outgoing messages.
   *
   * Defaults to JSON encoder.
   *
   * @param {Function} [opts.decode] - The function to decode incoming messages.
   *
   * Defaults to JSON:
   *
   * ```javascript
   * (payload, callback) => callback(JSON.parse(payload))
   * ```
   *
   * @param {number} [opts.timeout] - The default timeout in milliseconds to trigger push timeouts.
   *
   * Defaults `DEFAULT_TIMEOUT`
   * @param {number} [opts.heartbeatIntervalMs] - The millisec interval to send a heartbeat message
   * @param {number} [opts.reconnectAfterMs] - The optional function that returns the millisec
   * socket reconnect interval.
   *
   * Defaults to stepped backoff of:
   *
   * ```javascript
   * function(tries){
   *   return [10, 50, 100, 150, 200, 250, 500, 1000, 2000][tries - 1] || 5000
   * }
   * ````
   *
   * @param {number} [opts.rejoinAfterMs] - The optional function that returns the millisec
   * rejoin interval for individual channels.
   *
   * ```javascript
   * function(tries){
   *   return [1000, 2000, 5000][tries - 1] || 10000
   * }
   * ````
   *
   * @param {Function} [opts.logger] - The optional function for specialized logging, ie:
   *
   * ```javascript
   * function(kind, msg, data) {
   *   console.log(`${kind}: ${msg}`, data)
   * }
   * ```
   *
   * @param {number} [opts.longpollerTimeout] - The maximum timeout of a long poll AJAX request.
   *
   * Defaults to 20s (double the server long poll timer).
   *
   * @param {(Object|function)} [opts.params] - The optional params to pass when connecting
   * @param {string} [opts.binaryType] - The binary type to use for binary WebSocket frames.
   *
   * Defaults to "arraybuffer"
   *
   * @param {vsn} [opts.vsn] - The serializer's protocol version to send on connect.
   *
   * Defaults to DEFAULT_VSN.
   */
  class Socket {
    constructor(endPoint, opts = {}) {
      this.stateChangeCallbacks = {
        open: [],
        close: [],
        error: [],
        message: []
      };
      this.channels = [];
      this.sendBuffer = [];
      this.ref = 0;
      this.timeout = opts.timeout || _constants.DEFAULT_TIMEOUT;
      this.transport = opts.transport || _constants.global.WebSocket || _longpoll.default;
      this.establishedConnections = 0;
      this.defaultEncoder = _serializer.default.encode.bind(_serializer.default);
      this.defaultDecoder = _serializer.default.decode.bind(_serializer.default);
      this.closeWasClean = false;
      this.binaryType = opts.binaryType || "arraybuffer";
      this.connectClock = 1;

      if (this.transport !== _longpoll.default) {
        this.encode = opts.encode || this.defaultEncoder;
        this.decode = opts.decode || this.defaultDecoder;
      } else {
        this.encode = this.defaultEncoder;
        this.decode = this.defaultDecoder;
      }

      let awaitingConnectionOnPageShow = null;

      if (_constants.phxWindow && _constants.phxWindow.addEventListener) {// HACK BY CC
        // We don't want the connection to go away on a page hide. We want
        // the tab to stay up to date.
        // phxWindow.addEventListener("pagehide", (_e) => {
        //   if (this.conn) {
        //     this.disconnect()
        //     awaitingConnectionOnPageShow = this.connectClock
        //   }
        // })
        // phxWindow.addEventListener("pageshow", (_e) => {
        //   if (awaitingConnectionOnPageShow === this.connectClock) {
        //     awaitingConnectionOnPageShow = null
        //     this.connect()
        //   }
        // })
      }

      this.heartbeatIntervalMs = opts.heartbeatIntervalMs || 30000;

      this.rejoinAfterMs = tries => {
        if (opts.rejoinAfterMs) {
          return opts.rejoinAfterMs(tries);
        } else {
          return [1000, 2000, 5000][tries - 1] || 10000;
        }
      };

      this.reconnectAfterMs = tries => {
        if (opts.reconnectAfterMs) {
          return opts.reconnectAfterMs(tries);
        } else {
          return [10, 50, 100, 150, 200, 250, 500, 1000, 2000][tries - 1] || 5000;
        }
      };

      this.logger = opts.logger || null;
      this.longpollerTimeout = opts.longpollerTimeout || 20000;
      this.params = (0, _utils.closure)(opts.params || {});
      this.endPoint = `${endPoint}/${_constants.TRANSPORTS.websocket}`;
      this.vsn = opts.vsn || _constants.DEFAULT_VSN;
      this.heartbeatTimeoutTimer = null;
      this.heartbeatTimer = null;
      this.pendingHeartbeatRef = null;
      this.reconnectTimer = new _timer.default(() => {
        this.teardown(() => this.connect());
      }, this.reconnectAfterMs);
    }
    /**
     * Returns the LongPoll transport reference
     */


    getLongPollTransport() {
      return _longpoll.default;
    }
    /**
     * Disconnects and replaces the active transport
     *
     * @param {Function} newTransport - The new transport class to instantiate
     *
     */


    replaceTransport(newTransport) {
      this.connectClock++;
      this.closeWasClean = true;
      this.reconnectTimer.reset();
      this.sendBuffer = [];

      if (this.conn) {
        this.conn.close();
        this.conn = null;
      }

      this.transport = newTransport;
    }
    /**
     * Returns the socket protocol
     *
     * @returns {string}
     */


    protocol() {
      return location.protocol.match(/^https/) ? "wss" : "ws";
    }
    /**
     * The fully qualified socket url
     *
     * @returns {string}
     */


    endPointURL() {
      let uri = _ajax.default.appendParams(_ajax.default.appendParams(this.endPoint, this.params()), {
        vsn: this.vsn
      });

      if (uri.charAt(0) !== "/") {
        return uri;
      }

      if (uri.charAt(1) === "/") {
        return `${this.protocol()}:${uri}`;
      }

      return `${this.protocol()}://${location.host}${uri}`;
    }
    /**
     * Disconnects the socket
     *
     * See https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#Status_codes for valid status codes.
     *
     * @param {Function} callback - Optional callback which is called after socket is disconnected.
     * @param {integer} code - A status code for disconnection (Optional).
     * @param {string} reason - A textual description of the reason to disconnect. (Optional)
     */


    disconnect(callback, code, reason) {
      this.connectClock++;
      this.closeWasClean = true;
      this.reconnectTimer.reset();
      this.teardown(callback, code, reason);
    }
    /**
     *
     * @param {Object} params - The params to send when connecting, for example `{user_id: userToken}`
     *
     * Passing params to connect is deprecated; pass them in the Socket constructor instead:
     * `new Socket("/socket", {params: {user_id: userToken}})`.
     */


    connect(params) {
      if (params) {
        console && console.log("passing params to connect is deprecated. Instead pass :params to the Socket constructor");
        this.params = (0, _utils.closure)(params);
      }

      if (this.conn) {
        return;
      }

      this.connectClock++;
      this.closeWasClean = false;
      this.conn = new this.transport(this.endPointURL());
      this.conn.binaryType = this.binaryType;
      this.conn.timeout = this.longpollerTimeout;

      this.conn.onopen = () => this.onConnOpen();

      this.conn.onerror = error => this.onConnError(error);

      this.conn.onmessage = event => this.onConnMessage(event);

      this.conn.onclose = event => this.onConnClose(event);
    }
    /**
     * Logs the message. Override `this.logger` for specialized logging. noops by default
     * @param {string} kind
     * @param {string} msg
     * @param {Object} data
     */


    log(kind, msg, data) {
      this.logger(kind, msg, data);
    }
    /**
     * Returns true if a logger has been set on this socket.
     */


    hasLogger() {
      return this.logger !== null;
    }
    /**
     * Registers callbacks for connection open events
     *
     * @example socket.onOpen(function(){ console.info("the socket was opened") })
     *
     * @param {Function} callback
     */


    onOpen(callback) {
      let ref = this.makeRef();
      this.stateChangeCallbacks.open.push([ref, callback]);
      return ref;
    }
    /**
     * Registers callbacks for connection close events
     * @param {Function} callback
     */


    onClose(callback) {
      let ref = this.makeRef();
      this.stateChangeCallbacks.close.push([ref, callback]);
      return ref;
    }
    /**
     * Registers callbacks for connection error events
     *
     * @example socket.onError(function(error){ alert("An error occurred") })
     *
     * @param {Function} callback
     */


    onError(callback) {
      let ref = this.makeRef();
      this.stateChangeCallbacks.error.push([ref, callback]);
      return ref;
    }
    /**
     * Registers callbacks for connection message events
     * @param {Function} callback
     */


    onMessage(callback) {
      let ref = this.makeRef();
      this.stateChangeCallbacks.message.push([ref, callback]);
      return ref;
    }
    /**
     * Pings the server and invokes the callback with the RTT in milliseconds
     * @param {Function} callback
     *
     * Returns true if the ping was pushed or false if unable to be pushed.
     */


    ping(callback) {
      if (!this.isConnected()) {
        return false;
      }

      let ref = this.makeRef();
      let startTime = Date.now();
      this.push({
        topic: "phoenix",
        event: "heartbeat",
        payload: {},
        ref: ref
      });
      let onMsgRef = this.onMessage(msg => {
        if (msg.ref === ref) {
          this.off([onMsgRef]);
          callback(Date.now() - startTime);
        }
      });
      return true;
    }
    /**
     * @private
     */


    clearHeartbeats() {
      clearTimeout(this.heartbeatTimer);
      clearTimeout(this.heartbeatTimeoutTimer);
    }

    onConnOpen() {
      if (this.hasLogger()) this.log("transport", `connected to ${this.endPointURL()}`);
      this.closeWasClean = false;
      this.establishedConnections++;
      this.flushSendBuffer();
      this.reconnectTimer.reset();
      this.resetHeartbeat();
      this.stateChangeCallbacks.open.forEach(([, callback]) => callback());
    }
    /**
     * @private
     */


    heartbeatTimeout() {
      if (this.pendingHeartbeatRef) {
        this.pendingHeartbeatRef = null;

        if (this.hasLogger()) {
          this.log("transport", "heartbeat timeout. Attempting to re-establish connection");
        }

        this.triggerChanError();
        this.closeWasClean = false;
        this.teardown(() => this.reconnectTimer.scheduleTimeout(), _constants.WS_CLOSE_NORMAL, "heartbeat timeout");
      }
    }

    resetHeartbeat() {
      if (this.conn && this.conn.skipHeartbeat) {
        return;
      }

      this.pendingHeartbeatRef = null;
      this.clearHeartbeats();
      this.heartbeatTimer = setTimeout(() => this.sendHeartbeat(), this.heartbeatIntervalMs);
    }

    teardown(callback, code, reason) {
      if (!this.conn) {
        return callback && callback();
      }

      this.waitForBufferDone(() => {
        if (this.conn) {
          if (code) {
            this.conn.close(code, reason || "");
          } else {
            this.conn.close();
          }
        }

        this.waitForSocketClosed(() => {
          if (this.conn) {
            this.conn.onopen = function () {}; // noop


            this.conn.onerror = function () {}; // noop


            this.conn.onmessage = function () {}; // noop


            this.conn.onclose = function () {}; // noop


            this.conn = null;
          }

          callback && callback();
        });
      });
    }

    waitForBufferDone(callback, tries = 1) {
      if (tries === 5 || !this.conn || !this.conn.bufferedAmount) {
        callback();
        return;
      }

      setTimeout(() => {
        this.waitForBufferDone(callback, tries + 1);
      }, 150 * tries);
    }

    waitForSocketClosed(callback, tries = 1) {
      if (tries === 5 || !this.conn || this.conn.readyState === _constants.SOCKET_STATES.closed) {
        callback();
        return;
      }

      setTimeout(() => {
        this.waitForSocketClosed(callback, tries + 1);
      }, 150 * tries);
    }

    onConnClose(event) {
      let closeCode = event && event.code;
      if (this.hasLogger()) this.log("transport", "close", event);
      this.triggerChanError();
      this.clearHeartbeats();

      if (!this.closeWasClean && closeCode !== 1000) {
        this.reconnectTimer.scheduleTimeout();
      }

      this.stateChangeCallbacks.close.forEach(([, callback]) => callback(event));
    }
    /**
     * @private
     */


    onConnError(error) {
      if (this.hasLogger()) this.log("transport", error);
      let transportBefore = this.transport;
      let establishedBefore = this.establishedConnections;
      this.stateChangeCallbacks.error.forEach(([, callback]) => {
        callback(error, transportBefore, establishedBefore);
      });

      if (transportBefore === this.transport || establishedBefore > 0) {
        this.triggerChanError();
      }
    }
    /**
     * @private
     */


    triggerChanError() {
      this.channels.forEach(channel => {
        if (!(channel.isErrored() || channel.isLeaving() || channel.isClosed())) {
          channel.trigger(_constants.CHANNEL_EVENTS.error);
        }
      });
    }
    /**
     * @returns {string}
     */


    connectionState() {
      switch (this.conn && this.conn.readyState) {
        case _constants.SOCKET_STATES.connecting:
          return "connecting";

        case _constants.SOCKET_STATES.open:
          return "open";

        case _constants.SOCKET_STATES.closing:
          return "closing";

        default:
          return "closed";
      }
    }
    /**
     * @returns {boolean}
     */


    isConnected() {
      return this.connectionState() === "open";
    }
    /**
     * @private
     *
     * @param {Channel}
     */


    remove(channel) {
      this.off(channel.stateChangeRefs);
      this.channels = this.channels.filter(c => c.joinRef() !== channel.joinRef());
    }
    /**
     * Removes `onOpen`, `onClose`, `onError,` and `onMessage` registrations.
     *
     * @param {refs} - list of refs returned by calls to
     *                 `onOpen`, `onClose`, `onError,` and `onMessage`
     */


    off(refs) {
      for (let key in this.stateChangeCallbacks) {
        this.stateChangeCallbacks[key] = this.stateChangeCallbacks[key].filter(([ref]) => {
          return refs.indexOf(ref) === -1;
        });
      }
    }
    /**
     * Initiates a new channel for the given topic
     *
     * @param {string} topic
     * @param {Object} chanParams - Parameters for the channel
     * @returns {Channel}
     */


    channel(topic, chanParams = {}) {
      let chan = new _channel.default(topic, chanParams, this);
      this.channels.push(chan);
      return chan;
    }
    /**
     * @param {Object} data
     */


    push(data) {
      if (this.hasLogger()) {
        let {
          topic,
          event,
          payload,
          ref,
          join_ref
        } = data;
        this.log("push", `${topic} ${event} (${join_ref}, ${ref})`, payload);
      }

      if (this.isConnected()) {
        this.encode(data, result => this.conn.send(result));
      } else {
        this.sendBuffer.push(() => this.encode(data, result => this.conn.send(result)));
      }
    }
    /**
     * Return the next message ref, accounting for overflows
     * @returns {string}
     */


    makeRef() {
      let newRef = this.ref + 1;

      if (newRef === this.ref) {
        this.ref = 0;
      } else {
        this.ref = newRef;
      }

      return this.ref.toString();
    }

    sendHeartbeat() {
      if (this.pendingHeartbeatRef && !this.isConnected()) {
        return;
      }

      this.pendingHeartbeatRef = this.makeRef();
      this.push({
        topic: "phoenix",
        event: "heartbeat",
        payload: {},
        ref: this.pendingHeartbeatRef
      });
      this.heartbeatTimeoutTimer = setTimeout(() => this.heartbeatTimeout(), this.heartbeatIntervalMs);
    }

    flushSendBuffer() {
      if (this.isConnected() && this.sendBuffer.length > 0) {
        this.sendBuffer.forEach(callback => callback());
        this.sendBuffer = [];
      }
    }

    onConnMessage(rawMessage) {
      this.decode(rawMessage.data, msg => {
        let {
          topic,
          event,
          payload,
          ref,
          join_ref
        } = msg;

        if (ref && ref === this.pendingHeartbeatRef) {
          this.clearHeartbeats();
          this.pendingHeartbeatRef = null;
          this.heartbeatTimer = setTimeout(() => this.sendHeartbeat(), this.heartbeatIntervalMs);
        }

        if (this.hasLogger()) this.log("receive", `${payload.status || ""} ${topic} ${event} ${ref && "(" + ref + ")" || ""}`, payload);

        for (let i = 0; i < this.channels.length; i++) {
          const channel = this.channels[i];

          if (!channel.isMember(topic, event, payload, join_ref)) {
            continue;
          }

          channel.trigger(event, payload, ref, join_ref);
        }

        for (let i = 0; i < this.stateChangeCallbacks.message.length; i++) {
          let [, callback] = this.stateChangeCallbacks.message[i];
          callback(msg);
        }
      });
    }

    leaveOpenTopic(topic) {
      let dupChannel = this.channels.find(c => c.topic === topic && (c.isJoined() || c.isJoining()));

      if (dupChannel) {
        if (this.hasLogger()) this.log("transport", `leaving duplicate topic "${topic}"`);
        dupChannel.leave();
      }
    }

  }

  _exports.default = Socket;
});