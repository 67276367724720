define("cc-frontend/components/planner/template-chooser/choose-from-gallery.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "choose-from-library": "_choose-from-library_ahl0xv",
    "filter-well": "_filter-well_ahl0xv",
    "filter-well__item": "_filter-well__item_ahl0xv",
    "--is-selected": "_--is-selected_ahl0xv",
    "choose-from-library__header": "_choose-from-library__header_ahl0xv",
    "horizontal-scrolling-list-wrapper": "_horizontal-scrolling-list-wrapper_ahl0xv",
    "horizontal-scrolling-list": "_horizontal-scrolling-list_ahl0xv",
    "template-box": "_template-box_ahl0xv _template-box_masbxg",
    "template-box-button": "_template-box-button_ahl0xv _template-box-button_masbxg d-s-shadowed-button",
    "template-box__title": "_template-box__title_ahl0xv",
    "template-box__emoji": "_template-box__emoji_ahl0xv",
    "carousel": "_carousel_ahl0xv",
    "carousel__arrow": "_carousel__arrow_ahl0xv",
    "--left": "_--left_ahl0xv",
    "--right": "_--right_ahl0xv"
  };
  _exports.default = _default;
});