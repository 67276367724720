define("cc-frontend/components/icons/search-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! search circle }}
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M18,18.5c-0.134,0 -0.259,-0.052 -0.354,-0.146l-2.899,-2.899c-1.017,0.852 -2.29,1.319 -3.611,1.319c-3.108,0 -5.637,-2.529 -5.637,-5.637c0.001,-3.108 2.53,-5.637 5.638,-5.637c3.108,0 5.637,2.529 5.637,5.637c0,1.321 -0.467,2.594 -1.319,3.611l2.899,2.899c0.094,0.094 0.146,0.219 0.146,0.353c0,0.134 -0.052,0.259 -0.146,0.354c-0.094,0.095 -0.22,0.146 -0.354,0.146Zm-6.863,-12c-2.557,0 -4.637,2.08 -4.637,4.637c0,2.557 2.08,4.637 4.637,4.637c2.557,0 4.637,-2.08 4.637,-4.637c0,-2.557 -2.08,-4.637 -4.637,-4.637Z"
      ></path>
      <path
        d="M12,24c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,0 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "i5XKVWGy",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M18,18.5c-0.134,0 -0.259,-0.052 -0.354,-0.146l-2.899,-2.899c-1.017,0.852 -2.29,1.319 -3.611,1.319c-3.108,0 -5.637,-2.529 -5.637,-5.637c0.001,-3.108 2.53,-5.637 5.638,-5.637c3.108,0 5.637,2.529 5.637,5.637c0,1.321 -0.467,2.594 -1.319,3.611l2.899,2.899c0.094,0.094 0.146,0.219 0.146,0.353c0,0.134 -0.052,0.259 -0.146,0.354c-0.094,0.095 -0.22,0.146 -0.354,0.146Zm-6.863,-12c-2.557,0 -4.637,2.08 -4.637,4.637c0,2.557 2.08,4.637 4.637,4.637c2.557,0 4.637,-2.08 4.637,-4.637c0,-2.557 -2.08,-4.637 -4.637,-4.637Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,24c-6.617,0 -12,-5.383 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,0 12,5.383 12,12c0,6.617 -5.383,12 -12,12Zm0,-23c-6.065,0 -11,4.935 -11,11c0,6.065 4.935,11 11,11c6.065,0 11,-4.935 11,-11c0,-6.065 -4.935,-11 -11,-11Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/search-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});