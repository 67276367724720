define("cc-frontend/components/d-s/dropdown-menu/google-classroom/no-icon-row", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="menu-option">
    {{yield}}
  </div>
  */
  {
    "id": "nJyKngTa",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"menu-option\"],[[\"from\"],[\"cc-frontend/components/d-s/dropdown-menu/google-classroom/no-icon-row.css\"]]]]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"yield\"]]",
    "moduleName": "cc-frontend/components/d-s/dropdown-menu/google-classroom/no-icon-row.hbs",
    "isStrictMode": false
  });

  class GoogleClassroomNoIconRowComponent extends _component.default {}

  _exports.default = GoogleClassroomNoIconRowComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GoogleClassroomNoIconRowComponent);
});