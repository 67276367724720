define("cc-frontend/lib/unit-utils", ["exports", "@sentry/browser", "lodash", "cc-frontend/models/course-calendar-date", "cc-frontend/models/transferrable-date"], function (_exports, Sentry, _lodash, _courseCalendarDate, _transferrableDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.consolidateCourseDatesOff = consolidateCourseDatesOff;
  _exports.extractRange = extractRange;
  _exports.extractLesson = extractLesson;
  _exports.extractUnit = extractUnit;
  _exports.findNewDate = findNewDate;
  _exports.insertLesson = insertLesson;
  _exports.insertRange = insertRange;
  _exports.simpleInsertRange = simpleInsertRange;
  _exports.moveEndOfUnit = moveEndOfUnit;
  _exports.moveStartOfUnit = moveStartOfUnit;
  _exports.constructUnitsAndDates = constructUnitsAndDates;
  _exports.getCardStackIdForDate = getCardStackIdForDate;
  _exports.getCourseDateForCardStackId = getCourseDateForCardStackId;

  /**
   *
   * @param {Date} date
   */
  function formatDateAsISO(date) {
    return dateFns.format(date, "YYYY-MM-DD"); // return date.toISOString().slice(0, 10)
  }
  /**
   *
   * @param {Number} amount
   * @param {Number} unitLength
   * @param {String[]} unitAncestorIds
   * @param {String[]} dateUnitIds
   */


  function isMovingParentUnit(amount, unitLength, unitAncestorIds, dateUnitIds) {
    return amount > 0 && // if we're draggin forward
    amount < unitLength && // if we haven't dragged it past it's end
    (0, _lodash.default)(dateUnitIds).intersection(unitAncestorIds).size() > 0 // the date in question contains the ancestor
    ;
  }
  /**
   * Loops through all the rotation dates and toggles off any custom or default dates.
   * Then, we take out any dates being forced on.
   *
   * @param {Array<string>} planbookDatesOff
   * @param {Array<String>} planbookDatesForcedOn
   * @param {Array<String>} courseDatesOff
   * @param {Array<String>} courseDatesForcedOn
   * @param {Array<Object>} rotationDates
   */


  function consolidateCourseDatesOff(course, planbook, rotationCalendar) {
    let courseDatesOff = course.attributes.calendar.datesOff;
    let courseDatesForcedOn = course.attributes.calendar.datesForcedOn;
    let planbookDatesOff = planbook.attributes.calendar.datesOff;
    let _planbookDatesForcedOn = planbook.attributes.calendar.datesForcedOn;
    let rotationDates = rotationCalendar.attributes.dates;

    let unitStartDates = _lodash.default.map(course.attributes.calendar.units, unit => unit.startDate);

    let unitEndDates = _lodash.default.map(course.attributes.calendar.units, unit => unit.endDate); // loop through rotationDates
    // - convert all the semester/rotationIds to find out if it's is on.
    // then, we only have to look to see if it's the dateString is off.


    return _lodash.default.chain(rotationDates).filter(date => {
      let semesterRotationString = date.attributes.semesterId + ":" + date.attributes.rotationId;

      let planbookDefaultIsOff = _lodash.default.includes(planbookDatesOff, semesterRotationString);

      let courseDefaultIsOff = _lodash.default.includes(courseDatesOff, semesterRotationString);

      let planbookCustomIsOff = _lodash.default.includes(planbookDatesOff, date.attributes.dateString);

      let courseCustomIsOff = _lodash.default.includes(courseDatesOff, date.attributes.dateString);

      let dateHasStartOfUnit = _lodash.default.includes(unitStartDates, date.attributes.dateString);

      let dateHasEndOfUnit = _lodash.default.includes(unitEndDates, date.attributes.dateString);

      let planbookIsOff = planbookCustomIsOff ? planbookCustomIsOff : planbookDefaultIsOff;
      let courseIsOff = courseCustomIsOff ? courseCustomIsOff : courseDefaultIsOff;
      let isOff = planbookIsOff ? planbookIsOff : courseIsOff;

      let courseDateForcedOn = _lodash.default.includes(courseDatesForcedOn, date.attributes.dateString);

      return isOff && !courseDateForcedOn && !dateHasStartOfUnit && !dateHasEndOfUnit;
    }).map(rotationDate => rotationDate.attributes.dateString).value();
  }
  /**
   * This is used when pulling a range of lessons and moving them. For instance,
   * let's say you wnat to add a week for Spring Break and you want to mvoe all the lessons
   * that are there. This will extract those lessons and units
   */


  function extractRange(startDate, endDate, courseDates, datesOff, schoolDays) {
    let date = dateFns.parse(startDate);

    let courseDateMap = _lodash.default.keyBy(courseDates, "attributes.dateString");

    let iterations = 0;
    let isIterating = true;
    let transferrableArray = [];
    let modifiedDates = {};

    while (isIterating && iterations < 500) {
      if (date > endDate) {
        isIterating = false;
        continue;
      }

      iterations++;
      let dateString = formatDateAsISO(date);

      if (!_lodash.default.includes(schoolDays, dateFns.getDay(date))) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      if (_lodash.default.includes(datesOff, dateString)) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      let courseDate = courseDateMap[dateString] ? courseDateMap[dateString] : undefined;

      if (courseDate) {
        transferrableArray.push(new _transferrableDate.default({
          cardStackId: courseDate.attributes.cardStackId,
          unitStart: courseDate.attributes.unitStart,
          unitEnd: courseDate.attributes.unitEnd
        }));
      }

      if (courseDate !== undefined) {
        modifiedDates[dateString] = _lodash.default.cloneDeep(courseDate);
        modifiedDates[dateString].attributes.cardStackId = null;
        modifiedDates[dateString].attributes.unitStart = [];
        modifiedDates[dateString].attributes.unitEnd = [];
        modifiedDates[dateString].attributes.unitIds = [];
      }

      date = dateFns.addDays(date, 1);
    }

    let courseDatesWithoutRange = _lodash.default.map(courseDates, courseDate => modifiedDates[courseDate.attributes.dateString] || courseDate);

    return {
      transferrableArray,
      courseDatesWithoutRange
    };
  }
  /**
   *
   * @param {String} cardStackId
   * @param {Object[]} courseDates
   */


  function extractLesson(cardStackId, courseDates) {
    let transferrableArray = [new _transferrableDate.default({
      cardStackId: cardStackId,
      unitStart: [],
      unitEnd: []
    })];

    let courseDatesWithoutLesson = _lodash.default.map(courseDates, courseDate => {
      if (courseDate.type === "course-date-custom" && courseDate.attributes.cardStackId === cardStackId) {
        let cd = _lodash.default.cloneDeep(courseDate);

        cd.attributes.cardStackId = null;
        return cd;
      } else {
        return courseDate;
      }
    });

    return {
      transferrableArray,
      courseDatesWithoutLesson
    };
  }
  /**
   * Pull out the unit and return a transferrable array and the course dates sans that array
   *
   * @param {Number} amount
   * @param {Object} unitHash
   * @param {Array<Object>} courseDates
   * @param {Array<String>} datesOff
   * @param {Array<number>} schoolDays
   */


  function extractUnit(amount, unitHash, courseDates, datesOff, schoolDays) {
    let unitStartDate = unitHash.startDate;
    let unitEndDate = unitHash.endDate;

    let unitIdsOnTheMove = _lodash.default.flatten([unitHash.descendantIds, unitHash.id]);

    let {
      transferrableArray,
      modifiedDates
    } = createRange(unitStartDate, unitEndDate, courseDates, datesOff, schoolDays).reduce((acc, courseDate) => {
      // Phase 1: Unit End
      // -----------------
      let unitStart = _lodash.default.intersection(courseDate.attributes.unitStart, unitIdsOnTheMove); // Phase 2: Unit End
      // -----------------
      // Tricky test -- if we're dragging a unit forward that's at the end of the unit, we want to also move the parent unit


      let moveParentUnits = isMovingParentUnit(amount, unitHash.unitLength, unitHash.ancestorIds, courseDate.attributes.unitIds);
      let unitEnd = moveParentUnits ? courseDate.attributes.unitEnd.slice(0) // take entire array including parents
      : _lodash.default.intersection(courseDate.attributes.unitEnd, unitIdsOnTheMove); // take normal array of units on the move

      acc.transferrableArray.push(new _transferrableDate.default({
        cardStackId: courseDate.attributes.cardStackId,
        unitStart: unitStart,
        unitEnd: unitEnd
      }));
      acc.modifiedDates[courseDate.attributes.dateString] = new _courseCalendarDate.default({
        id: courseDate.id,
        type: courseDate.type,
        attributes: {
          cardStackId: null,
          dateString: courseDate.attributes.dateString,
          unitStart: _lodash.default.without(courseDate.attributes.unitStart, ...unitIdsOnTheMove),
          unitEnd: moveParentUnits ? [] : _lodash.default.without(courseDate.attributes.unitEnd, ...unitIdsOnTheMove)
        }
      });
      return acc;
    }, {
      transferrableArray: [],
      modifiedDates: {}
    }).value();

    let courseDatesWithoutUnit = _lodash.default.map(courseDates, courseDate => modifiedDates[courseDate.attributes.dateString] || courseDate);

    return {
      transferrableArray,
      courseDatesWithoutUnit
    };
  }
  /**
   *
   * @param {Number} amount
   * @param {String} oldStartDate
   * @param {String[]} datesOff
   * @param {Number[]} schoolDays
   * @return {String}
   */


  function findNewDate(amount, oldStartDate, datesOff, schoolDays) {
    if (amount === 0) return oldStartDate;
    let dateOperation = amount > 0 ? dateFns.addDays : dateFns.subDays;
    let date = dateFns.parse(oldStartDate);
    let dateRange = [];
    let iterations = 0;

    while (dateRange.length < Math.abs(amount) && iterations < 500) {
      iterations++;
      date = dateOperation(date, 1);
      if (!_lodash.default.includes(schoolDays, dateFns.getDay(date))) continue;
      let dateString = formatDateAsISO(date);
      if (_lodash.default.includes(datesOff, dateString)) continue;
      dateRange.push(dateString);
    }

    return _lodash.default.last(dateRange);
  }

  function insertLesson(lesson, newStartDate, courseDates, datesOff, schoolDays) {} // TODO If it's a lesson, we want to merge it if there's a unitStart -- we should pass that in.


  function insertRange(transferrableArray, newStartDate, amount, unitHash, courseDates, datesOff, schoolDays, isLesson) {
    let courseDateMap = _lodash.default.keyBy(courseDates, "attributes.dateString");

    let newCourseDates = [];
    let modifiedDates = {};
    let date = dateFns.parse(newStartDate);
    let datesLaidDown = 0;
    let iterations = 0;

    while (transferrableArray.length > 0 && iterations < 500) {
      iterations++;
      let dateString = formatDateAsISO(date);

      if (!_lodash.default.includes(schoolDays, dateFns.getDay(date))) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      if (_lodash.default.includes(datesOff, dateString)) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      let courseDate = courseDateMap[dateString] ? _lodash.default.cloneDeep(courseDateMap[dateString]) : undefined; //==================================================
      // Apply a date if a date doesn't exist
      //==================================================

      if (courseDate === undefined) {
        let dateToTransfer = transferrableArray.shift();
        newCourseDates.push(new _courseCalendarDate.default({
          id: dateString,
          type: "course-date-custom",
          attributes: {
            dateString: dateString,
            cardStackId: dateToTransfer.cardStackId,
            unitStart: dateToTransfer.unitStart.slice(0),
            unitEnd: dateToTransfer.unitEnd.slice(0)
          }
        }));
        date = dateFns.addDays(date, 1);
        datesLaidDown++;
        continue;
      }

      let isDraggingOntoStartOfParentUnit = _lodash.default.intersection(courseDate.attributes.unitStart, unitHash.ancestorIds).length > 0; //==================================================
      // Pluck and Reset Date (if needed)
      //==================================================

      let isApplyingFirstLesson = isLesson === true && datesLaidDown === 0;

      if (shouldPluckCourseDate(courseDate, amount, transferrableArray.length, isApplyingFirstLesson, _lodash.default.first(transferrableArray))) {
        // ------------------------
        // Step 1: Set unitStarts
        // ------------------------
        let dateToTransferUnitStart = [];
        let courseDateToModifyUnitStart = []; // Note: The first condition is to see if we're at the beginning and we're at the beginning of one the
        // unit's parent units. In that case, we don't want to push back the parent unit

        if (datesLaidDown === 0 && isDraggingOntoStartOfParentUnit) {
          let childUnitIdsToBump = _lodash.default.without(courseDate.attributes.unitStart, ...unitHash.ancestorIds);

          dateToTransferUnitStart = childUnitIdsToBump;
          courseDateToModifyUnitStart = _lodash.default.without(courseDate.attributes.unitStart, ...childUnitIdsToBump);
        } else if (isApplyingFirstLesson) {
          dateToTransferUnitStart = [];
          courseDateToModifyUnitStart = courseDate.attributes.unitStart.slice(0);
        } else {
          dateToTransferUnitStart = courseDate.attributes.unitStart.slice(0);
          courseDateToModifyUnitStart = [];
        } // ----------------------------------
        // Step 2: Push date into transferrable array
        // ----------------------------------


        transferrableArray.push({
          cardStackId: courseDate.attributes.cardStackId,
          unitStart: dateToTransferUnitStart,
          unitEnd: courseDate.attributes.unitEnd.slice(0)
        }); // ----------------------------------
        // Step 3: Reset date
        // ----------------------------------

        courseDate.attributes.cardStackId = null;
        courseDate.attributes.unitStart = courseDateToModifyUnitStart;
        courseDate.attributes.unitEnd = [];
      } //==================================================
      // Apply a date
      //==================================================
      //------------------------
      // Figure out new unit end
      //------------------------


      let dateToTransfer = transferrableArray.shift();
      let newUnitEnd = [];

      if (datesLaidDown === 0 && isDraggingOntoStartOfParentUnit) {
        newUnitEnd = _lodash.default.intersection(courseDate.attributes.unitEnd, unitHash.ancestorIds).concat(dateToTransfer.unitEnd);
      } else {
        newUnitEnd = courseDate.attributes.unitEnd.concat(dateToTransfer.unitEnd);
      } //------------------------
      // Set properties on course date
      //------------------------


      courseDate.attributes.cardStackId = dateToTransfer.cardStackId;
      courseDate.attributes.unitStart = courseDate.attributes.unitStart.concat(dateToTransfer.unitStart); // courseDate.attributes.unitEnd = courseDate.attributes.unitEnd.concat(dateToTransfer.unitEnd)

      courseDate.attributes.unitEnd = newUnitEnd;
      courseDate.attributes.unitIds = []; // reset it
      //----------------------
      // Overwrite old courseDate
      //----------------------

      modifiedDates[dateString] = courseDate; //-------------------
      // Increment counters
      //-------------------

      datesLaidDown++;
      date = dateFns.addDays(date, 1);
    }

    return _lodash.default.map(courseDates, cd => modifiedDates[cd.attributes.dateString] || cd).concat(newCourseDates);
  } //  We use this when we just want to throw a range in and we're not really merging. For instance,
  //  PULL_LESSON_BACKWARDS


  function simpleInsertRange(transferrableArray, newStartDate, courseDates, datesOff, schoolDays, isLesson) {
    let courseDateMap = _lodash.default.keyBy(courseDates, "attributes.dateString");

    let newCourseDates = [];
    let modifiedDates = {};
    let date = dateFns.parse(newStartDate);
    let datesLaidDown = 0;
    let iterations = 0;

    while (transferrableArray.length > 0 && iterations < 500) {
      iterations++;
      let dateString = formatDateAsISO(date);

      if (!_lodash.default.includes(schoolDays, dateFns.getDay(date))) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      if (_lodash.default.includes(datesOff, dateString)) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      let courseDate = courseDateMap[dateString] ? _lodash.default.cloneDeep(courseDateMap[dateString]) : undefined; //==================================================
      // Apply a date if a date doesn't exist
      //==================================================

      if (courseDate === undefined) {
        let dateToTransfer = transferrableArray.shift();
        newCourseDates.push(new _courseCalendarDate.default({
          id: dateString,
          type: "course-date-custom",
          attributes: {
            dateString: dateString,
            cardStackId: dateToTransfer.cardStackId,
            unitStart: dateToTransfer.unitStart.slice(0),
            unitEnd: dateToTransfer.unitEnd.slice(0)
          }
        }));
        date = dateFns.addDays(date, 1);
        datesLaidDown++;
        continue;
      } //------------------------
      // Set properties on course date
      //------------------------


      let dateToTransfer = transferrableArray.shift();
      courseDate.attributes.cardStackId = dateToTransfer.cardStackId;
      courseDate.attributes.unitStart = courseDate.attributes.unitStart.concat(dateToTransfer.unitStart); // courseDate.attributes.unitEnd = courseDate.attributes.unitEnd.concat(dateToTransfer.unitEnd)

      courseDate.attributes.unitEnd = dateToTransfer.unitEnd;
      courseDate.attributes.unitIds = []; // reset it
      //----------------------
      // Overwrite old courseDate
      //----------------------

      modifiedDates[dateString] = courseDate; //-------------------
      // Increment counters
      //-------------------

      datesLaidDown++;
      date = dateFns.addDays(date, 1);
    }

    return _lodash.default.map(courseDates, cd => modifiedDates[cd.attributes.dateString] || cd).concat(newCourseDates);
  }

  function moveEndOfUnit(amount, newEndDate, unitHash, courseDates, units, datesOff, schoolDays) {
    if (amount === 0) return courseDates; // -------------------------------------------------------
    // Take the unit id out of the unitEnd
    // -------------------------------------------------------

    let courseDatesWithoutUnitEnd = _lodash.default.map(courseDates, courseDate => {
      if (_lodash.default.includes(courseDate.attributes.unitEnd, unitHash.id)) {
        let newCourseDate = _lodash.default.cloneDeep(courseDate);

        _lodash.default.pull(newCourseDate.attributes.unitEnd, unitHash.id);

        return newCourseDate;
      } else {
        return courseDate;
      }
    });

    let courseDateMap = _lodash.default.keyBy(courseDatesWithoutUnitEnd, "attributes.dateString");

    let newCourseDates = [];
    let modifiedDates = {};
    let date = dateFns.parse(newEndDate);
    let iterations = 0;
    let isFinding = true;

    while (isFinding && iterations < 500) {
      iterations++;
      let dateString = formatDateAsISO(date); // -------------------------------------------------------
      // Move to the next date if the day isn't in session
      // -------------------------------------------------------

      if (!_lodash.default.includes(schoolDays, dateFns.getDay(date))) {
        date = amount > 0 ? dateFns.addDays(date, 1) : dateFns.subDays(date, 1);
        continue;
      }

      if (_lodash.default.includes(datesOff, dateString)) {
        date = amount > 0 ? dateFns.addDays(date, 1) : dateFns.subDays(date, 1);
        continue;
      } // -------------------------------------------------------
      // Find the course date (if it exists)
      // -------------------------------------------------------


      let courseDate = courseDateMap[dateString] ? courseDateMap[dateString] : undefined; // -------------------------------------------------------
      // CASE 2: We have a date bigger or less than the max or min
      // So we reset it
      // -------------------------------------------------------
      // Case 2a. See if it exists max date
      // -------------------------------------------------------

      let maxDate = _lodash.default.chain(units).filter(unit => _lodash.default.includes(unitHash.ancestorIds, unit.id)).map("endDate").min().value();

      if (unitHash.ancestorIds && dateString > maxDate) {
        date = dateFns.parse(maxDate);
        continue;
      } // Case 2b. See if it exists min date
      // -------------------------------------------------------


      let minDate = unitHash.startDate;

      if (dateString < minDate) {
        date = dateFns.parse(minDate);
        continue;
      } // CASE 2c: We have a date with the same bounds as it's parent
      // This is a bit confusing -- we look to see if it has the same start/endDate
      // as it's nearest parent. We tax the highest startDate of it's ancestors
      // and then check if we're at the max date and, if so, decrement the enddate
      // so it doesn't have the same start/end as it's parent
      // -------------------------------------------------------


      let parentUnitStartDate = _lodash.default.chain(units).filter(unit => _lodash.default.includes(unitHash.ancestorIds, unit.id)).map("startDate").max().value();

      if (unitHash.startDate === parentUnitStartDate && maxDate === dateString) {
        date = dateFns.subDays(date, 1);
        continue;
      } // CASE 2d: We have a date with the same bounds as it's child
      // -------------------------------------------------------


      let childUnitsWithSameStart = _lodash.default.filter(units, unit => {
        return _lodash.default.includes(unitHash.descendantIds, unit.id) && unit.startDate === unitHash.startDate;
      });

      let childUnitsWithSameStartIds = _lodash.default.map(childUnitsWithSameStart, "id");

      let childUnitEndDates = _lodash.default.map(childUnitsWithSameStart, "endDate");

      if (_lodash.default.includes(childUnitEndDates, dateString)) {
        date = dateFns.subDays(date, 1); // This is hacky -- we actually need to iterate to make sure it's not a day off or not a school day

        continue;
      } // -------------------------------------------------------
      // CASE 1: We don't have a course date
      // So we just apply it.
      // -------------------------------------------------------


      if (courseDate === undefined) {
        newCourseDates.push(new _courseCalendarDate.default({
          id: dateString,
          type: "course-date-custom",
          attributes: {
            dateString: dateString,
            unitEnd: [unitHash.id]
          }
        }));
        isFinding = false;
        continue;
      } // -------------------------------------------------------
      // CASE 3: We have a unit there
      // -------------------------------------------------------


      let currentUnits = _lodash.default.filter(courseDate.attributes.unitIds, id => {
        return _lodash.default.includes(unitHash.ancestorIds, id) === false && _lodash.default.includes(courseDate.attributes.unitEnd, id) === false && unitHash.id !== id && _lodash.default.includes(childUnitsWithSameStartIds, id) !== true;
      }); // CASE 3A: we're moving forward
      // So, we look for the end of the unit we dragged it into
      // -------------------------------------------------------


      if (currentUnits.length > 0 && amount > 0) {
        date = _lodash.default.chain(courseDate.attributes.unitIds).without(unitHash.id).map(id => _lodash.default.find(units, unit => unit.id === id && unit.ancestorIds.length === unitHash.ancestorIds.length)).map("endDate").max().thru(endDate => dateFns.parse(endDate)).value();
        continue;
      } // CASE 3B: we're moving backwards
      // So, we look for the start of the unit we dragged it into
      // ----------------------------


      if (currentUnits.length > 0 && amount < 0) {
        date = _lodash.default.chain(courseDate.attributes.unitIds).without(unitHash.id).map(id => _lodash.default.find(units, unit => unit.id === id && unit.ancestorIds.length >= unitHash.ancestorIds.length)).map("startDate").min().thru(date => dateFns.parse(date)).thru(date => dateFns.subDays(date, 1)) // This is hacky -- we actually need to iterate to make sure it's not a day off or not a school day
        .value();
        continue;
      } // -------------------------------------------------------
      // CASE 4: We have a course date AND we're not in a unit
      // So we just apply it
      // -------------------------------------------------------


      modifiedDates[dateString] = _lodash.default.cloneDeep(courseDate);
      modifiedDates[dateString].attributes.unitEnd.unshift(unitHash.id);
      isFinding = false;
      continue;
    }

    return _lodash.default.map(courseDatesWithoutUnitEnd, cd => modifiedDates[cd.attributes.dateString] || cd).concat(newCourseDates);
  }

  function moveStartOfUnit(amount, newStartDate, unitHash, courseDates, units, datesOff, schoolDays) {
    if (amount === 0) return courseDates; // -------------------------------------------------------
    // Take the unit id out of the unitStart
    // -------------------------------------------------------

    let courseDatesWithoutUnitStart = _lodash.default.map(courseDates, courseDate => {
      if (_lodash.default.includes(courseDate.attributes.unitStart, unitHash.id)) {
        let newCourseDate = _lodash.default.cloneDeep(courseDate);

        _lodash.default.pull(newCourseDate.attributes.unitStart, unitHash.id);

        return newCourseDate;
      } else {
        return courseDate;
      }
    });

    let courseDateMap = _lodash.default.keyBy(courseDatesWithoutUnitStart, "attributes.dateString");

    let newCourseDates = [];
    let modifiedDates = {};
    let date = dateFns.parse(newStartDate);
    let iterations = 0;
    let isFinding = true;

    while (isFinding && iterations < 500) {
      if (iterations === 499) {// console.log("TOO MANY ITERATIONS")
      }

      iterations++;
      let dateString = formatDateAsISO(date); // -------------------------------------------------------
      // Move to the next date if the day isn't in session
      // -------------------------------------------------------

      if (!_lodash.default.includes(schoolDays, dateFns.getDay(date))) {
        date = amount > 0 ? dateFns.addDays(date, 1) : dateFns.subDays(date, 1); // console.log("not a school day")

        continue;
      }

      if (_lodash.default.includes(datesOff, dateString)) {
        date = amount > 0 ? dateFns.addDays(date, 1) : dateFns.subDays(date, 1); // console.log("date off")

        continue;
      } // -------------------------------------------------------
      // Find the course date (if it exists)
      // -------------------------------------------------------


      let courseDate = courseDateMap[dateString] ? courseDateMap[dateString] : undefined; // -------------------------------------------------------
      // CASE 2: We have a date bigger or less than the max or min
      // of the parents So we reset it
      // -------------------------------------------------------
      // Case 2a. See if there's an ancestor with a shorter start date
      // -------------------------------------------------------

      let minDate = _lodash.default.chain(units).filter(unit => _lodash.default.includes(unitHash.ancestorIds, unit.id)).map("startDate").min().value();

      if (unitHash.ancestorIds && dateString < minDate) {
        date = dateFns.parse(minDate); // console.log("case 2a: see if there's an ancestor with a shorter start date")

        continue;
      } // Case 2b. See if we're dragging past the end of the unit
      // -------------------------------------------------------


      let maxDate = unitHash.endDate;

      if (dateString > maxDate) {
        date = dateFns.parse(maxDate); // console.log("Case 2b: We're dragging past the end of the unit")

        continue;
      } // CASE 2c: We have a date with the same bounds as it's parent
      // This is a bit confusing -- we look to see if it has the same start/endDate
      // as it's nearest parent. We tax the highest startDate of it's ancestors
      // and then check if we're at the max date and, if so, decrement the enddate
      // so it doesn't have the same start/end as it's parent
      // -------------------------------------------------------


      let parentUnitStartDate = _lodash.default.chain(units).filter(unit => _lodash.default.includes(unitHash.ancestorIds, unit.id)).map("startDate").max().value();

      if (unitHash.startDate === parentUnitStartDate && maxDate === dateString) {
        date = dateFns.addDays(date, 1); // console.log("Case 2c: We have a date with the same bounds as it's parent")

        continue;
      } // CASE 2d: We have a date with the same bounds as it's child
      // -------------------------------------------------------


      let childUnitsWithSameStart = _lodash.default.filter(units, unit => {
        return _lodash.default.includes(unitHash.descendantIds, unit.id) && unit.startDate === unitHash.startDate;
      });

      let childUnitsWithSameStartIds = _lodash.default.map(childUnitsWithSameStart, "id");

      let childUnitStartDates = _lodash.default.map(childUnitsWithSameStart, "startDate");

      if (_lodash.default.includes(childUnitStartDates, dateString)) {
        date = dateFns.addDays(date, 1); // This is hacky -- we actually need to iterate to make sure it's not a day off or not a school day
        // console.log("case 2d: We have a date with the same bounds as it's child")

        continue;
      } // -------------------------------------------------------
      // CASE 1: We don't have a course date
      // So we just apply it.
      // -------------------------------------------------------


      if (courseDate === undefined) {
        // console.log("CASE 1: We don't have a course date")
        newCourseDates.push(new _courseCalendarDate.default({
          id: dateString,
          type: "course-date-custom",
          attributes: {
            dateString: dateString,
            unitStart: [unitHash.id]
          }
        }));
        isFinding = false;
        continue;
      } // -------------------------------------------------------
      // CASE 3: We have a unit there
      // -------------------------------------------------------


      let currentUnits = _lodash.default.filter(courseDate.attributes.unitIds, id => {
        return _lodash.default.includes(unitHash.ancestorIds, id) === false && _lodash.default.includes(courseDate.attributes.unitStart, id) === false && unitHash.id !== id && _lodash.default.includes(childUnitsWithSameStartIds, id) !== true;
      }); // CASE 3A: we're moving backwards
      // So, we look for the start of the unit we dragged it into
      // -------------------------------------------------------


      if (currentUnits.length > 0 && amount < 0) {
        date = _lodash.default.chain(courseDate.attributes.unitIds).without(unitHash.id).map(id => _lodash.default.find(units, unit => unit.id === id && unit.ancestorIds.length === unitHash.ancestorIds.length)).map("startDate").min().thru(startDate => dateFns.parse(startDate)).value(); // console.log("case 3a: we're moving backwards")

        continue;
      } // CASE 3B: we're moving forwards
      // So, we look for the end of the unit we dragged it into
      // ----------------------------


      if (currentUnits.length > 0 && amount > 0) {
        // console.log("CASE 3b: we're moving forwards", currentUnits, date)
        date = _lodash.default.chain(courseDate.attributes.unitIds).without(unitHash.id).map(id => _lodash.default.find(units, unit => unit.id === id && unit.ancestorIds.length >= unitHash.ancestorIds.length)).map("endDate").min().thru(date => dateFns.parse(date)).thru(date => dateFns.addDays(date, 1)) // This is hacky -- we actually need to iterate to make sure it's not a day off or not a school day
        .value(); // console.log("advanced date to", date)
        // isFinding = false

        continue;
      } // -------------------------------------------------------
      // CASE 4: We have a course date AND we're not in a unit
      // So we just apply it
      // -------------------------------------------------------
      // console.log("CASE 4: We have a course date AND we're not in a unit")
      // console.log("dateString", dateString, courseDate)


      modifiedDates[dateString] = _lodash.default.cloneDeep(courseDate);
      modifiedDates[dateString].attributes.unitStart.unshift(unitHash.id);
      isFinding = false;
      continue;
    } // console.log("update modified dates", modifiedDates)
    // console.log(_.map(modifiedDates, (d) => d.attributes.dateString))


    return _lodash.default.map(courseDatesWithoutUnitStart, cd => modifiedDates[cd.attributes.dateString] || cd).concat(newCourseDates);
  }
  /**
   */


  function constructUnitsAndDates(allCourseDates, datesOff, schoolDays) {
    // loop through dates and find min/max
    // add through, jumping over dates that are off and don't have unitStart/unitEnd
    // reduce to create the unit map
    // annotate the units with the ancestor/descendant ids
    // annotate the dates with unit ids
    let defaultCourseDates = _lodash.default.filter(allCourseDates, {
      type: "course-date-default"
    });

    let customCourseDates = _lodash.default.sortBy(_lodash.default.filter(allCourseDates, {
      type: "course-date-custom"
    }), date => date.attributes.dateString);

    let courseDateStrings = _lodash.default.map(customCourseDates, "attributes.dateString");

    let {
      newCourseDatesMap: newCourseDatesMap,
      units
    } = createRange(_lodash.default.min(courseDateStrings), _lodash.default.max(courseDateStrings), customCourseDates, datesOff, schoolDays).reduce((acc, courseDate, index) => {
      _lodash.default.forEach(courseDate.attributes.unitStart, id => {
        acc.units[id] = {
          id: id,
          startDate: courseDate.attributes.dateString,
          startIndex: index,
          endDate: "",
          unitLength: 0,
          ancestorIds: [],
          descendantIds: []
        };
        acc.currentUnitIds.push(id);
      });

      Ember.set(courseDate.attributes, "unitIds", _lodash.default.clone(acc.currentUnitIds));

      _lodash.default.forEach(courseDate.attributes.unitEnd, id => {
        if (acc.units[id] === undefined) {
          console.log("this unit doesnt exist", id);
        } else {
          acc.units[id].endDate = courseDate.attributes.dateString;
          acc.units[id].unitLength = index + 1 - acc.units[id].startIndex;
        }

        _lodash.default.pull(acc.currentUnitIds, id);
      });

      acc.newCourseDatesMap[courseDate.attributes.dateString] = courseDate;
      return acc;
    }, {
      newCourseDatesMap: {},
      units: {},
      currentUnitIds: []
    }).tap(acc => {
      let sortedUnits = _lodash.default.sortBy(acc.units, ["startDate", "unitLength"]);

      _lodash.default.forEach(sortedUnits, (unit, _index) => {
        _lodash.default.forEach(sortedUnits, (unit2, _index2) => {
          // The happy path
          if (unit2.endDate < unit.startDate && unit2.startDate < unit.startDate) return; // if (unit2.startDate <=  unit.startDate && unit2.endDate >= unit.endDate && unit2.id !== unit.id && unit.startDate !== unit2.startDate && unit.endDate !== unit2.endDate){

          if (unit2.startDate <= unit.startDate && unit2.endDate >= unit.endDate && unit2.id !== unit.id && !_lodash.default.includes(unit2.ancestorIds, unit.id)) {
            unit.ancestorIds.push(unit2.id);
          } else if (unit2.startDate >= unit.startDate && unit2.endDate <= unit.endDate && unit2.id !== unit.id) {
            unit.descendantIds.push(unit2.id);
          } else if (unit2.id !== unit.id && unit2.startDate === unit.startDate && unit2.endDate === unit.endDate) {
            console.log("Units have the same dates");
          } else if (unit2.startDate <= unit.startDate && unit2.endDate >= unit.startDate && unit2.endDate < unit.endDate && unit2.id !== unit.id) {
            console.log(`unit1: ${unit.id}: ${unit.startDate} - ${unit.endDate}`);
            console.log(`unit2: ${unit2.id}: ${unit2.startDate} - ${unit2.endDate}`);
            throw Error("Units overlap: starts before and breaks in the middle");
          } else if (unit2.startDate >= unit.startDate && unit2.startDate <= unit.endDate && unit2.endDate > unit.endDate && unit2.id !== unit.id) {
            console.log(`unit1: ${unit.id}: ${unit.startDate} - ${unit.endDate}`);
            console.log(`unit2: ${unit2.id}: ${unit2.startDate} - ${unit2.endDate}`); // debugger

            throw Error("Units overlap: starts after and extends past");
          }
        });
      });

      return acc;
    }).value();

    let oldCourseDatesMap = _lodash.default.reduce(customCourseDates, (acc, cd) => _lodash.default.set(acc, cd.attributes.dateString, cd), {});

    let courseDatesUpdated = _lodash.default.map(customCourseDates, cd => newCourseDatesMap[cd.attributes.dateString] || cd);

    let addedCourseDates = _lodash.default.filter(newCourseDatesMap, courseDate => {
      return oldCourseDatesMap[courseDate.attributes.dateString] === undefined;
    });

    let finishedCourseDates = _lodash.default.chain(courseDatesUpdated).concat(addedCourseDates).filter(date => isCourseDateWorthSaving(date)).sortBy(cd => cd.attributes.dateString).value();

    verifyCourseDates(finishedCourseDates, units);
    return {
      courseDates: finishedCourseDates.concat(defaultCourseDates),
      units: _lodash.default.values(units)
    };
  }

  function createRange(startDate, endDate, courseDates, datesOff, schoolDays) {
    let courseDateMap = _lodash.default.chain(courseDates).keyBy("attributes.dateString").value();

    return _lodash.default.chain(dateFns.eachDay(dateFns.parse(startDate), dateFns.parse(endDate))).filter(date => _lodash.default.includes(schoolDays, dateFns.getDay(date))) // filter for school days
    .map(date => formatDateAsISO(date)).map(dateString => {
      return courseDateMap[dateString] || new _courseCalendarDate.default({
        id: dateString,
        type: "course-date-custom",
        attributes: {
          dateString: dateString
        }
      });
    }).reject(courseDate => {
      // If there's a unit, we can't skip it -- otherwise, our array of dates will be missing a unit bookend
      return _lodash.default.includes(datesOff, courseDate.attributes.dateString) && _lodash.default.size(courseDate.attributes.unitStart) === 0 && _lodash.default.size(courseDate.attributes.unitEnd) === 0 && _lodash.default.size(courseDate.attributes.unitIds) === 0;
    });
  } // the moving forward is more complex b/c we don't want to have a blank lesson
  // at the end of the unit. So, if it doesn't have a lesson, we don't bump it.
  // or, if we have a bunch more to bump, we want to push it out so we have space.
  // This last condition is definitely the most confusing and hard for me to think about


  function shouldPluckCourseDate(date, amount, bufferLength, isApplyingFirstLesson, dateToInsert) {
    if (_lodash.default.isString(date.attributes.cardStackId)) return true;
    let dateIsNotInAUnit = _lodash.default.size(date.attributes.unitIds) === 0;
    if (isApplyingFirstLesson && date.attributes.cardStackId === null && dateIsNotInAUnit) return false; // If we're moving the end of a unit, we need to bump the beginning of the next unit

    if (_lodash.default.size(dateToInsert.unitEnd) > 0 && _lodash.default.size(date.attributes.unitStart) > 0) return true; // If we only have one date to insert, we do it (given the conditions above haven't been met)

    if (bufferLength === 1) return false;

    if (amount < 0) {
      return _lodash.default.size(date.attributes.unitStart) > 0 || _lodash.default.size(date.attributes.unitEnd) > 0 || _lodash.default.size(date.attributes.unitIds) > 0;
    } else {
      return _lodash.default.size(date.attributes.unitIds) > 0 || // there's a unit
      _lodash.default.size(date.attributes.unitStart) > 0 || // it has a unit start
      _lodash.default.size(date.attributes.unitEnd) > 0 && date.attributes.cardStackId || // there's a unitEnd AND a lesson
      _lodash.default.size(date.attributes.unitEnd) > 0 && bufferLength > 1 // there's a unitEnd AND we still have more things to move
      ;
    }
  }

  function getCardStackIdForDate(dates, dateString) {
    return _lodash.default.chain(dates).filter(date => date.type === "course-date-custom").find(date => date.attributes.dateString === dateString).get("attributes.cardStackId").value();
  }

  function getCourseDateForCardStackId(dates, cardStackId) {
    return _lodash.default.chain(dates).filter(date => date.type === "course-date-custom").find(date => date.attributes.cardStackId === cardStackId).value();
  }

  function isCourseDateWorthSaving(courseDate) {
    return courseDate.attributes.cardStackId !== null || courseDate.attributes.unitIds.length > 0 || courseDate.attributes.isForcedOn === true;
  }

  function verifyCourseDates(courseDates, units) {
    // make sure course starts have course ends
    // make sure all course ends are after or at course starts
    let unitMap = _lodash.default.chain(courseDates).filter({
      type: "course-date-custom"
    }).sortBy(date => date.attributes.dateString).reduce((acc, date) => {
      _lodash.default.each(date.attributes.unitStart, id => {
        if (acc[id] !== undefined) {
          Sentry.configureScope(scope => {
            scope.setFingerprint(["unit-multiple-with-same-id"]);
          });
          throw Error(`Multiple units with the id: ${id}`);
        }

        acc[id] = {
          id: id,
          startDate: null,
          endDate: null
        };
        acc[id]["startDate"] = date.attributes.dateString;
      });

      _lodash.default.each(date.attributes.unitEnd, id => {
        if (acc[id] === undefined) {
          Sentry.configureScope(scope => {
            scope.setFingerprint(["unit-start-end-error"]);
          });
          throw Error(`Unit end comes before unit beginning, ${id}, ${date.attributes.dateString}`);
        }

        acc[id]["endDate"] = date.attributes.dateString;
      });

      return acc;
    }, {}).value();

    _lodash.default.forEach(unitMap, (map, _id) => {
      if (_lodash.default.isNil(map.endDate) || _lodash.default.isNil(map.startDate) || map.endDate < map.startDate) {
        console.log(`Problematic unit: ${_id}`);
        console.log(map);
        Sentry.configureScope(scope => {
          scope.setFingerprint(["unit-start-end-error"]);
        });
        throw Error("Unit starts don't match ends");
      }
    }); // let unitIdsFromCourseDates = _.chain(courseDates).filter(cd => cd.type === "course-date-custom").flatMap(cd => cd.attributes.unitIds).sortBy().uniq().value()
    // let unitIdsFromUnits = _.sortBy(_.map(units, "id"))
    // let unitIdsFromMap   = _.sortBy(_.map(unitMap, "id"))
    //
    // _.forEach(unitIdsFromUnits, (id, index) => {
    //   if (unitIdsFromMap[index] !== id) throw Error("Unit array doesn't match the units in the dates", unitIdsFromMap, unitIdsFromUnits)
    //   if (unitIdsFromCourseDates[index] !== id) throw Error("Unit array doesn't match the units in the dates", unitIdsFromMap, unitIdsFromCourseDates)
    // })

  }
});