define("cc-frontend/lib/actions/course/date/default-course-date-models", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(payload, findFn, userId) {
    let course = findFn("course", payload.course.id);
    let planbook = findFn("planbook", _lodash.default.get(course, "relationships.planbook.data.id"));
    let user = findFn("user", userId);
    return {
      course,
      planbook,
      user
    };
  }
});