define("cc-frontend/templates/admin/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BcNfRaWi",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[[33,2,[\"attributes\",\"firstName\"]],\" \",[33,2,[\"attributes\",\"lastName\"]]],null]],null]],[1,\"\\n\"],[8,[39,3],null,[[\"@model\",\"@sidebarComponent\",\"@detailComponent\"],[[36,2],\"admin/user/sidebar\",\"admin/user/detail\"]],null]],[],false,[\"page-title\",\"concat\",\"model\",\"admin/skeleton\"]]",
    "moduleName": "cc-frontend/templates/admin/user.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});