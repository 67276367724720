define("cc-frontend/components/d-s/dropdown-menu/icon-row.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "icon-row": "_icon-row_1rgsxr"
  };
  _exports.default = _default;
});