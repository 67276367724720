define("cc-frontend/lib/actions/course/calendar/CHANGE_UNIT_COLOR", ["exports", "cc-frontend/lib/patch-updater", "lodash"], function (_exports, _patchUpdater, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CHANGE_UNIT_COLOR = void 0;
  const CHANGE_UNIT_COLOR = {
    name: "CHANGE_UNIT_COLOR",
    params: {
      courseId: "string",
      cardStackId: "string",
      color: "string",
      previousColor: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.color", payload.color).patch;

      let summaryPatch = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.color", payload.color).patch;

      return [patch, summaryPatch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.color", payload.previousColor).patch;

      let summaryPatch = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.color", payload.previousColor).patch;

      return [patch, summaryPatch];
    },

    narrative(payload, findFn, userId) {
      let course = findFn("course", payload.courseId);
      let models = {
        course: course,
        planbook: findFn("planbook", _lodash.default.get(course, "relationships.planbook.data.id")),
        unit: findFn("card-stack", payload.cardStackId) || findFn("card-stack-summary", payload.cardStackId)
      };
      let courseTitle = Ember.get(models, "course.attributes.title");
      return {
        title: "Changed Unit Color",
        titleForAnalytics: "Unit - Changed Color",
        description: `Changed the color of your unit to ${payload.color}`,
        context: {
          courseTitle,
          planbookTitle: Ember.get(models, "planbook.attributes.title"),
          unitId: payload.cardStackId,
          courseId: Ember.get(models, "course.id"),
          planbookId: Ember.get(models, "planbook.id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${course.id}`,
          to: [`course-edits:course-${course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        }
      };
    }

  };
  _exports.CHANGE_UNIT_COLOR = CHANGE_UNIT_COLOR;
  var _default = CHANGE_UNIT_COLOR;
  _exports.default = _default;
});