define("cc-frontend/routes/planbook/dates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DatesRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        date: {
          refreshModel: true
        }
      });
    }

    afterModel() {
      this.replaceWith("planbook.show.planner", {
        queryParams: {
          perspective: "week"
        }
      });
    }

  }

  _exports.default = DatesRoute;
});