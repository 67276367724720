define("cc-frontend/components/planner/menus/day-menu.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "day-label": "_day-label_rg910w"
  };
  _exports.default = _default;
});