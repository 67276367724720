define("cc-frontend/services/session-store", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SessionStore = (_dec = Ember.inject.service, (_class = class SessionStore extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    init() {
      super.init(...arguments);
      this._memoryStore = {};
    } // /**
    //  * persistValue("promtoinCode", "YES1234") =>
    //  * # localStorage.setItem("promotionCode", {promotionCode: "YES1234"})
    //  * @param  {[type]} key   [description]
    //  * @param  {[type]} _data [description]
    //  * @return {[type]}       [description]
    //  */
    // persistValue(key, _data) {
    //   let data = JSON.stringify(_data || {})
    //   localStorage.setItem(key, {key: data})
    // }
    //
    // getValue(key){
    //   let data = localStorage.getItem(key)
    //   return JSON.parse(data)[key]
    // }

    /**
     * Local storage can only set strings, so we stringify all objects.
     * Since JSON.stringify can only stringify objects, all things we set have to be objects
     */


    persistObject(key, _data) {
      if (typeof _data !== "object") new Error("You can only persist objects through this function.");
      let data = JSON.stringify(_data || {});

      if (window.localStorage) {
        try {
          localStorage.setItem(key, data);
        } catch (error) {
          this._memoryStore[key] = data;
          console.log("Error setting local storage.");
          console.log(error);
        }
      }

      return _data;
    }

    fetchObject(key) {
      let data;

      if (window.localStorage) {
        data = localStorage.getItem(key);
      } else {
        data = this._memoryStore[key];
      }

      return (0, _lodash.isNil)(data) ? null : JSON.parse(data);
    }

    clear(key) {
      if (window.localStorage) {
        localStorage.removeItem(key);
      } else {
        this._memoryStore[key] = null;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SessionStore;
});