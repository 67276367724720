define("cc-frontend/components/icons/shrink-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M11.5,23.999c-0.276,0 -0.5,-0.224 -0.5,-0.5v-7.793l-3.146,3.146c-0.094,0.094 -0.22,0.146 -0.354,0.146c-0.134,0 -0.259,-0.052 -0.354,-0.146c-0.095,-0.094 -0.146,-0.219 -0.146,-0.353c0,-0.134 0.052,-0.259 0.146,-0.354l4,-4c0.046,-0.046 0.101,-0.083 0.163,-0.109c0.06,-0.025 0.125,-0.038 0.191,-0.038c0.066,0 0.131,0.013 0.191,0.038c0.062,0.025 0.117,0.062 0.163,0.109l3.999,3.999c0.095,0.096 0.147,0.221 0.147,0.355c0,0.134 -0.052,0.259 -0.146,0.354c-0.094,0.095 -0.22,0.146 -0.354,0.146c-0.134,0 -0.259,-0.052 -0.354,-0.146l-3.146,-3.147v7.793c0,0.276 -0.224,0.5 -0.5,0.5Z"
      ></path>
      <path
        d="M11.5,10.999c-0.066,0 -0.13,-0.013 -0.191,-0.038c-0.062,-0.026 -0.116,-0.062 -0.162,-0.108l-4,-4c-0.095,-0.095 -0.147,-0.22 -0.147,-0.354c0,-0.134 0.052,-0.259 0.146,-0.353c0.094,-0.094 0.22,-0.147 0.354,-0.147c0.134,0 0.259,0.052 0.354,0.146l3.146,3.147v-7.793c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v7.793l3.146,-3.146c0.094,-0.094 0.22,-0.146 0.354,-0.146c0.134,0 0.259,0.052 0.354,0.146c0.095,0.094 0.146,0.219 0.146,0.353c0,0.134 -0.052,0.259 -0.146,0.354l-4,4c-0.047,0.047 -0.101,0.083 -0.163,0.109c-0.061,0.024 -0.125,0.037 -0.191,0.037Z"
      ></path>
      <path
        d="M1.5,12.999c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h19c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-19Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "0I38T0+n",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M11.5,23.999c-0.276,0 -0.5,-0.224 -0.5,-0.5v-7.793l-3.146,3.146c-0.094,0.094 -0.22,0.146 -0.354,0.146c-0.134,0 -0.259,-0.052 -0.354,-0.146c-0.095,-0.094 -0.146,-0.219 -0.146,-0.353c0,-0.134 0.052,-0.259 0.146,-0.354l4,-4c0.046,-0.046 0.101,-0.083 0.163,-0.109c0.06,-0.025 0.125,-0.038 0.191,-0.038c0.066,0 0.131,0.013 0.191,0.038c0.062,0.025 0.117,0.062 0.163,0.109l3.999,3.999c0.095,0.096 0.147,0.221 0.147,0.355c0,0.134 -0.052,0.259 -0.146,0.354c-0.094,0.095 -0.22,0.146 -0.354,0.146c-0.134,0 -0.259,-0.052 -0.354,-0.146l-3.146,-3.147v7.793c0,0.276 -0.224,0.5 -0.5,0.5Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M11.5,10.999c-0.066,0 -0.13,-0.013 -0.191,-0.038c-0.062,-0.026 -0.116,-0.062 -0.162,-0.108l-4,-4c-0.095,-0.095 -0.147,-0.22 -0.147,-0.354c0,-0.134 0.052,-0.259 0.146,-0.353c0.094,-0.094 0.22,-0.147 0.354,-0.147c0.134,0 0.259,0.052 0.354,0.146l3.146,3.147v-7.793c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v7.793l3.146,-3.146c0.094,-0.094 0.22,-0.146 0.354,-0.146c0.134,0 0.259,0.052 0.354,0.146c0.095,0.094 0.146,0.219 0.146,0.353c0,0.134 -0.052,0.259 -0.146,0.354l-4,4c-0.047,0.047 -0.101,0.083 -0.163,0.109c-0.061,0.024 -0.125,0.037 -0.191,0.037Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M1.5,12.999c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h19c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-19Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/shrink-vertical.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});