define("cc-frontend/components/planner/menus/day-menu/primary/title.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "day-label": "_day-label_1nfea0"
  };
  _exports.default = _default;
});