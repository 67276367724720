define("cc-frontend/models/planbook-calendar-date-default", ["exports", "cc-frontend/lib/structor"], function (_exports, _structor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PlanbookCalendarDateDefault extends _structor.default {
    defineStruct() {
      this.field("rotationId");
      this.field("semesterId");
      this.field("cardStackId");
    }

  }

  _exports.default = PlanbookCalendarDateDefault;
});