define("cc-frontend/components/icons/change-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    {{! Synchronize Arrows Square 1 }}
    <g>
      <path
        d="M6.5,21c-1.93,0 -3.5,-1.57 -3.5,-3.5v-7.068l-2.223,1.482c-0.083,0.055 -0.178,0.084 -0.277,0.084c-0.168,0 -0.323,-0.083 -0.416,-0.223c-0.074,-0.111 -0.1,-0.244 -0.074,-0.375c0.026,-0.131 0.102,-0.244 0.213,-0.318l3,-2c0.029,-0.019 0.056,-0.03 0.07,-0.036c0.079,-0.033 0.143,-0.046 0.207,-0.046c0.064,0 0.128,0.012 0.188,0.037c0.034,0.014 0.061,0.025 0.09,0.045l2.999,2c0.111,0.074 0.187,0.187 0.213,0.318c0.026,0.131 0,0.264 -0.074,0.375c-0.093,0.139 -0.249,0.223 -0.416,0.223c-0.099,0 -0.195,-0.029 -0.277,-0.084l-2.223,-1.482v7.068c0,1.378 1.122,2.5 2.5,2.5h9c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-9Z"
      ></path>
      <path
        d="M20.5,15c-0.023,0 -0.067,-0.007 -0.089,-0.013c-0.014,-0.001 -0.043,-0.007 -0.071,-0.016c-0.027,-0.009 -0.055,-0.022 -0.083,-0.038c-0.016,-0.007 -0.023,-0.011 -0.037,-0.021l-2.998,-1.999c-0.229,-0.153 -0.292,-0.464 -0.139,-0.693c0.093,-0.139 0.248,-0.223 0.416,-0.223c0.098,0 0.194,0.029 0.277,0.083l2.224,1.484v-7.064c0,-1.379 -1.121,-2.5 -2.5,-2.5h-9c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h9c1.93,0 3.5,1.57 3.5,3.5v7.064l2.223,-1.482c0.083,-0.055 0.179,-0.084 0.277,-0.084c0.168,0 0.323,0.083 0.416,0.223c0.074,0.111 0.101,0.244 0.074,0.375c-0.026,0.131 -0.102,0.244 -0.213,0.318l-3,2c-0.014,0.009 -0.038,0.022 -0.054,0.028c-0.009,0.006 -0.037,0.02 -0.068,0.03c-0.024,0.008 -0.052,0.014 -0.081,0.018c0,0.001 -0.032,0.01 -0.074,0.01Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "oUxAAuWU",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n\"],[1,\"  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M6.5,21c-1.93,0 -3.5,-1.57 -3.5,-3.5v-7.068l-2.223,1.482c-0.083,0.055 -0.178,0.084 -0.277,0.084c-0.168,0 -0.323,-0.083 -0.416,-0.223c-0.074,-0.111 -0.1,-0.244 -0.074,-0.375c0.026,-0.131 0.102,-0.244 0.213,-0.318l3,-2c0.029,-0.019 0.056,-0.03 0.07,-0.036c0.079,-0.033 0.143,-0.046 0.207,-0.046c0.064,0 0.128,0.012 0.188,0.037c0.034,0.014 0.061,0.025 0.09,0.045l2.999,2c0.111,0.074 0.187,0.187 0.213,0.318c0.026,0.131 0,0.264 -0.074,0.375c-0.093,0.139 -0.249,0.223 -0.416,0.223c-0.099,0 -0.195,-0.029 -0.277,-0.084l-2.223,-1.482v7.068c0,1.378 1.122,2.5 2.5,2.5h9c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-9Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M20.5,15c-0.023,0 -0.067,-0.007 -0.089,-0.013c-0.014,-0.001 -0.043,-0.007 -0.071,-0.016c-0.027,-0.009 -0.055,-0.022 -0.083,-0.038c-0.016,-0.007 -0.023,-0.011 -0.037,-0.021l-2.998,-1.999c-0.229,-0.153 -0.292,-0.464 -0.139,-0.693c0.093,-0.139 0.248,-0.223 0.416,-0.223c0.098,0 0.194,0.029 0.277,0.083l2.224,1.484v-7.064c0,-1.379 -1.121,-2.5 -2.5,-2.5h-9c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h9c1.93,0 3.5,1.57 3.5,3.5v7.064l2.223,-1.482c0.083,-0.055 0.179,-0.084 0.277,-0.084c0.168,0 0.323,0.083 0.416,0.223c0.074,0.111 0.101,0.244 0.074,0.375c-0.026,0.131 -0.102,0.244 -0.213,0.318l-3,2c-0.014,0.009 -0.038,0.022 -0.054,0.028c-0.009,0.006 -0.037,0.02 -0.068,0.03c-0.024,0.008 -0.052,0.014 -0.081,0.018c0,0.001 -0.032,0.01 -0.074,0.01Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/change-arrows.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});