define("cc-frontend/helpers/query", ["exports", "cc-frontend/services/socket", "ember-could-get-used-to-this", "lodash-es", "tracked-built-ins"], function (_exports, _socket, _emberCouldGetUsedToThis, _lodashEs, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let QueryResource = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class QueryResource extends _emberCouldGetUsedToThis.Resource {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "query", _descriptor, this);

      _initializerDefineProperty(this, "results", _descriptor2, this);

      _initializerDefineProperty(this, "isFetching", _descriptor3, this);

      _initializerDefineProperty(this, "nextOffset", _descriptor4, this);

      _defineProperty(this, "queryName", null);

      _defineProperty(this, "queryId", null);

      _defineProperty(this, "lastOffset", 0);

      _defineProperty(this, "limit", 200);

      _defineProperty(this, "queryAttempts", 0);
    }

    setup() {
      if (this.args.positional) {
        this.queryName = this.args.positional[0];
        this.queryId = this.args.positional[1];
        this.lastOffset = this.args.positional[2] || this.lastOffset;
        this.limit = this.args.positional[3] || this.limit;
      }

      this._fetchQuery();
    }

    get generatedQuery() {
      if ((0, _lodashEs.isNil)(this._generatedQuery)) {
        this._generatedQuery = this.query.generateQuery(this.queryName, this.queryId, () => {
          this._fetchQuery();
        });
      }

      return this._generatedQuery;
    }

    _fetchQuery() {
      if ((0, _lodashEs.isNil)(this.queryName) || (0, _lodashEs.isNil)(this.queryId)) return false;
      this.isFetching = true;
      return this.query.fetch(this.generatedQuery, this.lastOffset, this.limit).then(response => {
        if (response.data === undefined) return;
        let meta = response.meta || {};

        if (response.data.results.length === 0) {
          this.results = new _trackedBuiltIns.TrackedArray([]);
        } else {
          // Set the whole array to the new results
          if (this.lastOffset === 0) {
            this.results = new _trackedBuiltIns.TrackedArray(response.data.results);
          } else {
            // append to the existing array
            (0, _lodashEs.forEach)(response.data.results, result => {
              this.results[result.offset] = result;
            });
          }
        }

        this.nextOffset = meta.nextOffset;
        this.isFetching = false;
      }).catch(error => {
        this.isFetching = false;
        this.queryAttempts++;
        let newDelay = (0, _socket.fibonacciBackoff)(this.queryAttempts, 1000, 60000);
        console.error(`FAILED_QUERY #${this.queryAttempts}: ${this.generatedQuery.type}:${this.generatedQuery.id}`, error);
        Ember.run.later(() => this._fetchQuery(), newDelay);
      });
    }

    update() {
      // The entire query changes
      if (this.args.positional) {
        if (this.queryName !== this.args.positional[0] || this.queryId !== this.args.positional[1]) {
          // we need to refetch everything
          this.queryName = this.args.positional[0];
          this.queryId = this.args.positional[1];

          this._resetQuery();

          this._fetchQuery(); // We're only updating the offset

        } else if (this.args.positional[2] && this.lastOffset < this.args.positional[2]) {
          this.lastOffset = this.args.positional[2];

          this._fetchQuery();
        }
      }
    }

    _resetQuery() {
      this.query.unsubscribeToQuery(this.generatedQuery.queryInstanceId);
      this._generatedQuery = null;
    }

    teardown() {
      this.query.unsubscribeToQuery(this.generatedQuery.queryInstanceId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "query", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedBuiltIns.TrackedArray([]);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isFetching", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "nextOffset", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = QueryResource;
});