define("cc-frontend/instance-initializers/persister", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {// let persister = appInstance.lookup("service:persister")
  }

  var _default = {
    name: "persister",
    initialize: initialize,
    after: ["socket"]
  };
  _exports.default = _default;
});