define("cc-frontend/helpers/format-time", ["exports", "@glint/environment-ember-loose/ember-component/helper", "lodash-es"], function (_exports, _helper, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatTime;

  function formatTime(time, standardOrMilitary) {
    let {
      hour,
      minute
    } = time;
    let formattedMinute = (0, _lodashEs.padStart)(minute.toString(), 2, "0"); // This is probably an option we want to have a flag for.

    if (hour === 23 && minute === 59) return "End of Day";

    if (standardOrMilitary === "12hrs") {
      let meridian = hour < 12 ? "am" : "pm";
      let idHour;

      if (hour === 0) {
        idHour = "12";
      } else if (hour > 12) {
        idHour = (hour - 12).toString();
      } else {
        idHour = hour.toString();
      }

      return `${idHour}:${formattedMinute}${meridian}`;
    } else {
      let formattedHour = (0, _lodashEs.padStart)(hour.toString(), 2, "0");
      return `${formattedHour}:${formattedMinute}`;
    }
  } // We only have this so we can get typing with glint.


  const formatTimeHelper = (0, _helper.helper)(params => {
    return formatTime(params[0], params[1]);
  });
});