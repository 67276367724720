define("cc-frontend/lib/actions/all", ["exports", "lodash", "cc-frontend/lib/actions/app-state", "cc-frontend/lib/actions/class-website", "cc-frontend/lib/actions/course-calendar", "cc-frontend/lib/actions/print-configurator", "cc-frontend/lib/actions/routine", "cc-frontend/lib/actions/session", "cc-frontend/lib/actions/user"], function (_exports, _lodash, appStateOther, _classWebsite, _courseCalendar, _printConfigurator, _routine, _session, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let allActions = [appStateOther.default, appStateOther, _classWebsite.default, _printConfigurator.default, _user.default, _routine.default, _courseCalendar.default, _session.default];

  let allKeys = _lodash.default.keys(_lodash.default.assign({}, ...allActions));

  let allKeysAsStrings = _lodash.default.reduce(_lodash.default.sortBy(allKeys), (str, key) => `${str}"${key}"\n`, ""); // If we end up importing names of actions (like they do in redux), we'd use
  // this to generate that set. Right now, babel doesn't error if we import
  // a named export that doesn't exist
  // let mapOfActionNamesToStrings = _.chain(allActions)
  //   .flatMap(actionMap => _.map(actionMap, (val, key) => key))
  //   .reduce((acc, key) => _.assign(acc, {[key]: key}), {})
  //   .reduce((acc, val, key) => {
  //     return `${acc}export const ${key} = "${key}"\n`
  //   }, "")
  //   .value()
  // console.log('mapOfActionNamesToStrings')
  // console.log(mapOfActionNamesToStrings)
  // export const actionNames = mapOfActionNamesToStrings


  var _default = _lodash.default.assign({}, ...allActions);

  _exports.default = _default;
});