define("cc-frontend/helpers/fast-date", ["exports", "cc-frontend/lib/date-formatter"], function (_exports, _dateFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fastDate = fastDate;
  _exports.default = void 0;

  function fastDate(params, hash) {
    let dateString = params[0];
    let decoration = params[1];
    let dateObject = (0, _dateFormatter.default)(dateString);
    return dateObject(decoration);
  }

  var _default = Ember.Helper.helper(fastDate);

  _exports.default = _default;
});