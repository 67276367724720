define("cc-frontend/services/query", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Query = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class Query extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "_queries", []);

      _defineProperty(this, "inFlightQueriesCount", 0);

      _initializerDefineProperty(this, "socket", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "rpc", _descriptor3, this);

      _initializerDefineProperty(this, "fastboot", _descriptor4, this);
    }

    /**
     * Returns  a query that  can  be  used to fetch
     * @param  string type                 [description]
     * @param  string id                   [description]
     * @param  function invalidationCallback [description]
     * @return query                      [description]
     */
    generateQuery(type, id, invalidationCallback) {
      let query = {
        queryInstanceId: `${type}:${id}`,
        id: id,
        type: type,
        invalidationCallback: invalidationCallback
      };

      this._queries.push(query);

      Ember.get(this, "socket").subscribeToQuery(type, id);
      return query;
    }

    unsubscribeToQuery(queryInstanceId) {
      _lodash.default.remove(this._queries, query => query.id === queryInstanceId); // send unsubscribeToQuery to server

    }
    /**
     * Gets the query
     * @param  string type   [description]
     * @param  string id     [description]
     * @param  number offset [description]
     * @param  number limit  [description]
     * @return promise
     */


    fetch(query, offset = 0, limit = 100) {
      let type = Ember.get(query, "type");
      let id = Ember.get(query, "id");
      let shoebox = Ember.get(this, "fastboot.shoebox");
      let shoeboxStore = shoebox.retrieve("my-store") || {};
      let shoeboxedResponse = shoeboxStore[`${type}.${id}.${offset}.${limit}`];
      this.inFlightQueriesCount++;

      if (shoeboxedResponse !== null && shoeboxedResponse !== undefined) {
        // Delete it so we can make fresh queries
        shoeboxStore[`${type}.${id}.${offset}.${limit}`] = null;
        this.inFlightQueriesCount++;
        return Ember.RSVP.resolve(shoeboxedResponse);
      } else {
        return Ember.get(this, "rpc").execute("FETCH_QUERY", type, id, {
          offset,
          limit
        }).then(response => {
          _lodash.default.forEach(response.included, datum => Ember.get(this, "store").insertDocument(datum)); // Store it in the shoebox for later retrieval


          if (Ember.get(this, "fastboot.isFastBoot")) {
            let shoebox = Ember.get(this, "fastboot.shoebox");
            shoebox.retrieve("my-store")[`${type}.${id}.${offset}.${limit}`] = response.data;
          } // Return it


          this.inFlightQueriesCount--;
          return response;
        });
      }
    }

    invalidate(type, id) {
      let queriesToInvalidate = _lodash.default.filter(this._queries, query => query.type === type && query.id === id);

      _lodash.default.forEach(queriesToInvalidate, query => {
        query.invalidationCallback();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Query;
});