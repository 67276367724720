define("cc-frontend/components/print/course-date-annotations", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PrintCourseDateAnnotations = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{#if courseDate.hasVisibleAnnotations}}
      {{#unless hideCourseTitle}}
        <div class="ccp-lesson__title-block">
          <h3 class="ccp-routine__course-title">{{course.attributes.title}}</h3>
        </div>
      {{/unless}}
      <div class="ccp-lesson-section">
        {{#each courseDate.visibleAnnotations as |annotation|}}
          <h4 class="ccp-lesson-section__title take-section-color">{{annotation.attributes.title}}</h4>
          <div class="ccp-lesson-section__value">{{{annotation.attributes.description}}}</div>
        {{/each}}
      </div>
    {{/if}}
  
  */
  {
    "id": "VZynkNhN",
    "block": "[[[1,\"\\n\"],[41,[33,1,[\"hasVisibleAnnotations\"]],[[[41,[51,[33,3]],[[[1,\"      \"],[10,0],[14,0,\"ccp-lesson__title-block\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"ccp-routine__course-title\"],[12],[1,[33,4,[\"attributes\",\"title\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"ccp-lesson-section\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,1,[\"visibleAnnotations\"]]],null]],null],null,[[[1,\"        \"],[10,\"h4\"],[14,0,\"ccp-lesson-section__title take-section-color\"],[12],[1,[30,1,[\"attributes\",\"title\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"ccp-lesson-section__value\"],[12],[2,[30,1,[\"attributes\",\"description\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"annotation\"],false,[\"if\",\"courseDate\",\"unless\",\"hideCourseTitle\",\"course\",\"each\",\"-track-array\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec(_class = (_class2 = class PrintCourseDateAnnotations extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = PrintCourseDateAnnotations;
});