define("cc-frontend/components/icons/filter-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M10.988,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-9.743l-7.709,-10.358c-0.193,-0.257 -0.299,-0.576 -0.299,-0.899c0,-0.827 0.673,-1.5 1.5,-1.5h18.019c0.323,0 0.642,0.106 0.9,0.3c0.661,0.496 0.796,1.438 0.301,2.099l-7.712,10.356v8.245c0,0.469 -0.224,0.918 -0.6,1.2l-2,1.5c-0.258,0.194 -0.578,0.3 -0.9,0.3Zm-8.008,-23c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.108 0.036,0.214 0.1,0.3l7.809,10.493c0.064,0.085 0.099,0.191 0.099,0.298v9.909c0,0.276 0.224,0.5 0.5,0.5c0.108,0 0.214,-0.035 0.3,-0.1l2,-1.5c0.125,-0.094 0.2,-0.244 0.2,-0.4v-8.41c0,-0.107 0.035,-0.213 0.099,-0.299l7.812,-10.491c0.165,-0.22 0.12,-0.535 -0.1,-0.7c-0.086,-0.064 -0.192,-0.1 -0.3,-0.1h-18.019Z"
    ></path>
  </svg>
  */
  {
    "id": "hOFLdrZp",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M10.988,24c-0.827,0 -1.5,-0.673 -1.5,-1.5v-9.743l-7.709,-10.358c-0.193,-0.257 -0.299,-0.576 -0.299,-0.899c0,-0.827 0.673,-1.5 1.5,-1.5h18.019c0.323,0 0.642,0.106 0.9,0.3c0.661,0.496 0.796,1.438 0.301,2.099l-7.712,10.356v8.245c0,0.469 -0.224,0.918 -0.6,1.2l-2,1.5c-0.258,0.194 -0.578,0.3 -0.9,0.3Zm-8.008,-23c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.108 0.036,0.214 0.1,0.3l7.809,10.493c0.064,0.085 0.099,0.191 0.099,0.298v9.909c0,0.276 0.224,0.5 0.5,0.5c0.108,0 0.214,-0.035 0.3,-0.1l2,-1.5c0.125,-0.094 0.2,-0.244 0.2,-0.4v-8.41c0,-0.107 0.035,-0.213 0.099,-0.299l7.812,-10.491c0.165,-0.22 0.12,-0.535 -0.1,-0.7c-0.086,-0.064 -0.192,-0.1 -0.3,-0.1h-18.019Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/filter-simple.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});