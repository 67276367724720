define("cc-frontend/lib/reorder-positioned-list", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(newList) {
    // ensure there's more than one item
    if (newList.length === 1) return [];
    return _lodash.default.reduce(newList, (acc, item, index) => {
      let assignNewPosition = false;
      let newPosition; // at first

      if (index === 0) {
        let nextItemPosition = newList[index + 1].attributes.position;

        if (item.attributes.position !== null && item.attributes.position < nextItemPosition) {
          assignNewPosition = false;
        } else {
          assignNewPosition = true;
          newPosition = nextItemPosition - 1000;
        } // at end

      } else if (index === newList.length - 1) {
        let prevItemPosition = _lodash.default.last(acc)[1];

        if (item.attributes.position === null || item.attributes.position <= prevItemPosition) {
          assignNewPosition = true;
          newPosition = prevItemPosition + 1000;
        } else {
          assignNewPosition = false;
        } // in the middle

      } else {
        let nextItemPosition = newList[index + 1].attributes.position;

        let prevItemPosition = _lodash.default.last(acc)[1];

        let itemPosition = item.attributes.position;

        if (nextItemPosition === null) {
          assignNewPosition = false;
        } else if (itemPosition !== null && itemPosition > prevItemPosition && itemPosition < nextItemPosition) {
          assignNewPosition = false;
        } else {
          assignNewPosition = true; // We assign 1 to prevent cards from having the same number

          newPosition = prevItemPosition + Math.floor((nextItemPosition - prevItemPosition) / 2) + 1; // newPosition = prevItemPosition     + 500
        }
      }

      if (assignNewPosition) {
        acc.push([item.id, newPosition, item.attributes.position]);
      } else {
        acc.push([item.id, item.attributes.position]);
      }

      return acc;
    }, []);
  }
});