define("cc-frontend/components/icons/simple-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background:new 0 0 24 24;"
    xml:space="preserve"
  >
    <g>
      <path
        d="M3,7.5C2.724,7.5,2.5,7.276,2.5,7S2.724,6.5,3,6.5h18c0.276,0,0.5,0.224,0.5,0.5S21.276,7.5,21,7.5H3z"
      ></path>
      <path
        d="M3,12.5c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h18c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5H3z"
      ></path>
      <path
        d="M3,17.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h18c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H3z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "Cnc4Mt+i",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"viewBox\",\"0 0 24 24\"],[14,5,\"enable-background:new 0 0 24 24;\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3,7.5C2.724,7.5,2.5,7.276,2.5,7S2.724,6.5,3,6.5h18c0.276,0,0.5,0.224,0.5,0.5S21.276,7.5,21,7.5H3z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3,12.5c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h18c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5H3z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M3,17.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h18c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H3z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/simple-menu.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});