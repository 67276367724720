define("cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_DELETE", ["exports", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models", "moment"], function (_exports, _datesOffCalculator, _patchUpdater, _lodash, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_DATE_ANNOTATION_DELETE = void 0;
  const COURSE_DATE_ANNOTATION_DELETE = {
    name: "COURSE_DATE_ANNOTATION_DELETE",
    params: {
      annotation: "object",
      course: "object",
      calledFrom: "string"
    },

    patches(payload) {
      let newAnnotations = _lodash.default.reject(payload.course.attributes.calendar.dateAnnotations, annotation => {
        return annotation.id === payload.annotation.id;
      });

      let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(newAnnotations);

      let patch = _patchUpdater.default.create("course", payload.course.id).pull("attributes.calendar.dateAnnotations", {
        id: payload.annotation.id
      }).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatches(payload) {
      let oldAnnotations = JSON.parse(JSON.stringify(payload.course.attributes.calendar.dateAnnotations));
      let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(oldAnnotations);

      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dateAnnotations", oldAnnotations).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
      let formattedDateString = (0, _moment.default)(payload.annotation.attributes.dateString).format("ddd, MMM. D, YYYY");
      let courseTitle = payload.course.attributes.title;
      let context = (0, _defaultCourseDateContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        noteTitle: _lodash.default.get(payload.annotation.attributes.title),
        type: payload.annotation.type
      });

      let type = payload.annotation.type === "date-annotation-course-default" ? "default" : "single";
      return {
        title: "Turned class back on",
        titleForAnalytics: "Lesson - Toggle On",
        description: `Turned your ${courseTitle} class back on for ${formattedDateString}`,
        context: newContext,
        activity: (0, _defaultCourseDateActivity.default)(payload, models, "removed")
      };
    }

  };
  _exports.COURSE_DATE_ANNOTATION_DELETE = COURSE_DATE_ANNOTATION_DELETE;
  var _default = COURSE_DATE_ANNOTATION_DELETE;
  _exports.default = _default;
});