define("cc-frontend/services/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Dialog extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "deffered", new Promise(() => {}));

      _defineProperty(this, "templateName", null);

      _defineProperty(this, "isOpen", false);

      _defineProperty(this, "options", null);

      _defineProperty(this, "resolve", null);

      _defineProperty(this, "reject", null);
    }

    blank(templateName, opts) {
      this.deffered = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      let options = Object.assign({
        substrate: true
      }, opts);
      Ember.set(this, "templateName", templateName);
      Ember.set(this, "options", options);
      Ember.set(this, "isOpen", true);
      return this.deffered;
    }

    accept() {
      console.log("ACCEPT", ...arguments);
      this.resolve(...arguments);
      Ember.set(this, "isOpen", false);
    }

    decline() {
      this.reject(...arguments);
      Ember.set(this, "isOpen", false);
    }

  }

  _exports.default = Dialog;
});