define("cc-frontend/components/dialogs/confirm-turn-on-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-success animate" style="display: block;">
    <span class="line tip animate-success-tip"></span>
    <span class="line long animate-success-long"></span>
    <div class="placeholder"></div>
    <div class="fix"></div>
  </div>
  <h1 class="create-annotation__title">
    Great! All new
    {{this.options.courseTitle}}
    {{this.options.rotationTitle}}
    lessons are on and visible!
  </h1>
  {{!-- <h2 class="create-annotation__description">
    When you go to the Lesson & Unit Planner, new {{rotationTitle}} {{courseTitle}} lessons will be hidden.
    Don't worry -- you can still turn the lesson on for a specific {{rotationTitle}}. Just go to the "Day Menu".
  </h2> --}}
  <div class="create-annotation__submit-buttons">
    <button class="create-annotation__submit-button focus-on-insert" {{action @accept}}>
      Ok!
    </button>
  </div>
  */
  {
    "id": "IsjsDUzY",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-success animate\"],[14,5,\"display: block;\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"line tip animate-success-tip\"],[12],[13],[1,\"\\n  \"],[10,1],[14,0,\"line long animate-success-long\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"placeholder\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"fix\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Great! All new\\n  \"],[1,[30,0,[\"options\",\"courseTitle\"]]],[1,\"\\n  \"],[1,[30,0,[\"options\",\"rotationTitle\"]]],[1,\"\\n  lessons are on and visible!\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"create-annotation__submit-button focus-on-insert\"],[4,[38,0],[[30,0],[30,1]],null],[12],[1,\"\\n    Ok!\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\"],false,[\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/confirm-turn-on-default.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});