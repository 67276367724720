define("cc-frontend/components/planner/csp/search", ["exports", "@ember-decorators/component", "@ember-decorators/object", "cc-frontend/app", "lodash", "cc-frontend/lib/object-id-gen"], function (_exports, _component, _object, _app, _lodash, _objectIdGen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Search = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    <div class="csp-search-interface">
      <div class="csp-search-bar {{if this.isSearchVisible 'show'}}">
        <Input @value={{this.query}} class="csp-search-bar__input" placeholder="What would you like to search for?" />
      </div>
  
      <div class="csp-search-panes">
        {{#each @standardSetIds as |id index|}}
          <Planner::Csp::StandardSet @standardSetId={{id}} @jurisdictionsIsFulfilled={{@jurisdictionsIsFulfilled}} @jurisdictions={{@jurisdictions}} @selectSet={{this.selectSet}} @toggleStandard={{this.onToggleStandard}} @class="csp-standard-set" @removeSet={{this.removeSet}} @results={{this.results}} @paneIndex={{index}} @showStandardCoverage={{@showStandardCoverage}} @standardsCoverage={{@standardsCoverage}} @selectedStandardIds={{@selectedStandardIds}} />
        {{/each}}
        {{#if this.isSearchVisible}}
          <div class="csp-add-search-pane-button hint--left" {{on "click" this.addPane}} aria-label="Compare standards by adding a pane">
            <div class="csp-add-search-pane-button__text">
              {{partial "icons/ios7-browser-outline"}}
              <span class="csp-add-search-pane-button__text__header">Side-by-Side View</span> Click to Compare Standards
            </div>
          </div>
        {{/if}}
      </div>
    </div>
  
  */
  {
    "id": "wbMFVnSA",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"csp-search-interface\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"csp-search-bar \",[52,[30,0,[\"isSearchVisible\"]],\"show\"]]]],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"csp-search-bar__input\"],[24,\"placeholder\",\"What would you like to search for?\"]],[[\"@value\"],[[30,0,[\"query\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"csp-search-panes\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@standardSetId\",\"@jurisdictionsIsFulfilled\",\"@jurisdictions\",\"@selectSet\",\"@toggleStandard\",\"@class\",\"@removeSet\",\"@results\",\"@paneIndex\",\"@showStandardCoverage\",\"@standardsCoverage\",\"@selectedStandardIds\"],[[30,2],[30,4],[30,5],[30,0,[\"selectSet\"]],[30,0,[\"onToggleStandard\"]],\"csp-standard-set\",[30,0,[\"removeSet\"]],[30,0,[\"results\"]],[30,3],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[2,3]],null],[41,[30,0,[\"isSearchVisible\"]],[[[1,\"        \"],[11,0],[24,0,\"csp-add-search-pane-button hint--left\"],[24,\"aria-label\",\"Compare standards by adding a pane\"],[4,[38,5],[\"click\",[30,0,[\"addPane\"]]],null],[12],[1,\"\\n          \"],[10,0],[14,0,\"csp-add-search-pane-button__text\"],[12],[1,\"\\n            \"],[19,\"icons/ios7-browser-outline\",[]],[1,\"\\n            \"],[10,1],[14,0,\"csp-add-search-pane-button__text__header\"],[12],[1,\"Side-by-Side View\"],[13],[1,\" Click to Compare Standards\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@standardSetIds\",\"id\",\"index\",\"@jurisdictionsIsFulfilled\",\"@jurisdictions\",\"@showStandardCoverage\",\"@standardsCoverage\",\"@selectedStandardIds\"],true,[\"if\",\"input\",\"each\",\"-track-array\",\"planner/csp/standard-set\",\"on\",\"partial\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed("standardSetIds.[]", "hasSelectedSet"), _dec5 = (0, _object.observes)("query"), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec(_class = (_class2 = class Search extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "algolia", _descriptor, this);

      _initializerDefineProperty(this, "notifier", _descriptor2, this);

      _defineProperty(this, "jurisdictions", void 0);

      _defineProperty(this, "jurisdictionsIsFulfilled", void 0);

      _defineProperty(this, "standardSetIds", void 0);

      _defineProperty(this, "setStandardSetIds", void 0);

      _defineProperty(this, "toggleStandard", void 0);

      _defineProperty(this, "showStandardCoverage", void 0);

      _defineProperty(this, "selectedStandardIds", void 0);

      _defineProperty(this, "standardsCoverage", void 0);

      _defineProperty(this, "searchErrorCount", 0);

      _defineProperty(this, "hasSelectedSet", false);

      _defineProperty(this, "results", null);

      _defineProperty(this, "query", null);
    }

    get isSearchVisible() {
      return this.get("hasSelectedSet") || _lodash.default.chain(this.standardSetIds).filter(function (s) {
        return !_lodash.default.includes(s, "blank");
      }).value().length > 0;
    }

    watchQuery() {
      Ember.run.scheduleOnce("afterRender", this, _lodash.default.throttle(this.searchAlgolia, 100));
    }

    searchAlgolia() {
      let query = _lodash.default.truncate(this.query, {
        length: 511
      });

      if (query === "" || Ember.isNone(query)) {
        this.set("results", null);
        return;
      }

      this.get("algolia.index").search(query, {
        attributesToRetrieve: "id",
        tagFilters: [this.standardSetIds],
        hitsPerPage: 1000
      }).then(data => {
        this.searchErrorCount = 0;
        return this.set("results", _lodash.default.map(data.hits, "id"));
      }).catch(error => {
        this.searchErrorCount++;

        if (this.searchErrorCount > 10) {
          _app.notifySentry.captureMessage("Algolia search error", error);

          this.notifier.warning("", {
            notificationId: this.query,
            title: `We're having some trouble searching right now.`,
            message: "Please try again later.",
            duration: 5000,
            type: "is-processing"
          });
        }
      });
    }

    selectSet(id, oldId) {
      var _window, _window$analytics;

      (_window = window) === null || _window === void 0 ? void 0 : (_window$analytics = _window.analytics) === null || _window$analytics === void 0 ? void 0 : _window$analytics.track("Standards Search - Select Set");
      var index = this.standardSetIds.indexOf(oldId);
      this.standardSetIds.replace(index, 1, [id]);
      this.setStandardSetIds(this.standardSetIds);
      this.set("hasSelectedSet", true);
    }

    removeSet(id) {
      var _window2, _window2$analytics;

      (_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$analytics = _window2.analytics) === null || _window2$analytics === void 0 ? void 0 : _window2$analytics.track("Standards Search - Remove Set");
      if (this.standardSetIds.length == 1) return;
      this.standardSetIds.removeObject(id);
      this.setStandardSetIds(this.standardSetIds);
    }

    addPane() {
      var _window3, _window3$analytics;

      (_window3 = window) === null || _window3 === void 0 ? void 0 : (_window3$analytics = _window3.analytics) === null || _window3$analytics === void 0 ? void 0 : _window3$analytics.track("Standards Search - Add Pane");
      this.standardSetIds.pushObject("blank" + _objectIdGen.default.create());
    }

    onToggleStandard(standard) {
      this.toggleStandard(standard);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "algolia", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifier", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isSearchVisible", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isSearchVisible"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "watchQuery", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "watchQuery"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectSet", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "selectSet"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeSet", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "removeSet"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addPane", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "addPane"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onToggleStandard", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "onToggleStandard"), _class2.prototype)), _class2)) || _class);
  _exports.default = Search;
});