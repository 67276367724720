define("cc-frontend/lib/actions/lesson/LESSON_CARD_PASTE", ["exports", "lodash", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _lodash, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_PASTE = void 0;
  const LESSON_CARD_PASTE = {
    name: "LESSON_CARD_PASTE",
    params: {
      cardStackId: "string",
      cardToReplaceId: "string",
      cardToPaste: "object",
      cardToReplace: "object"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardToReplaceId
      });

      if (payload.cardToReplace.type === "card-standards" && payload.cardToPaste.type === "card-standards") {
        patch.addToSet("embedded", "attributes.standards", ["$each", payload.cardToPaste.attributes.standards]);
      } else {
        patch.set("embedded", `type`, payload.cardToPaste.type);

        _lodash.default.each(payload.cardToPaste.attributes, (val, key) => {
          if (key === "position") return;
          patch.set("embedded", `attributes.${key}`, val);
        });
      }

      return [patch.patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardToReplaceId
      }).set("embedded", `type`, payload.cardToReplace.type);

      _lodash.default.each(JSON.parse(JSON.stringify(payload.cardToReplace.attributes)), (val, key) => {
        patch.set("embedded", `attributes.${key}`, val);
      });

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let cardToPasteTitle = payload.cardToPaste.attributes.title;
      let cardToReplaceTitle = payload.cardToReplace.attributes.title;
      let context = (0, _defaultLessonContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        cardToPasteTitle,
        cardToPasteId: payload.cardToPaste.parentCardStack,
        cardToReplaceTitle,
        cardToReplaceId: payload.cardToReplaceId
      });

      return {
        title: "Pasted a Lesson Card",
        titleForAnalytics: "Lesson Card - Paste",
        description: `Pasted "${cardToPasteTitle}" on top of "${cardToReplaceTitle}" in your ${context.courseTitle} lesson on ${context.date}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models)
      };
    }

  };
  _exports.LESSON_CARD_PASTE = LESSON_CARD_PASTE;
  var _default = LESSON_CARD_PASTE;
  _exports.default = _default;
});