define("cc-frontend/components/kb/entry/part/minimal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each (get (has-many-altogether @entry "children") "content") as |child|}}
    <LinkTo
      @route="help.article"
      @model={{kb-link-id child.attributes.title child.id}}
      class={{concat "kb-minimal-part " child.attributes.color}}
    >
  
      <div class="kb-minimal-part__icon">
        {{{child.attributes.icon}}}
      </div>
      <div class="kb-minimal-part__title">{{child.attributes.title}}</div>
    </LinkTo>
  {{/each}}
  */
  {
    "id": "gMw0vRdW",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[28,[37,3],[[30,1],\"children\"],null],\"content\"],null]],null]],null],null,[[[1,\"  \"],[8,[39,4],[[16,0,[28,[37,5],[\"kb-minimal-part \",[30,2,[\"attributes\",\"color\"]]],null]]],[[\"@route\",\"@model\"],[\"help.article\",[28,[37,6],[[30,2,[\"attributes\",\"title\"]],[30,2,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n\\n    \"],[10,0],[14,0,\"kb-minimal-part__icon\"],[12],[1,\"\\n      \"],[2,[30,2,[\"attributes\",\"icon\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"kb-minimal-part__title\"],[12],[1,[30,2,[\"attributes\",\"title\"]]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]],null]],[\"@entry\",\"child\"],false,[\"each\",\"-track-array\",\"get\",\"has-many-altogether\",\"link-to\",\"concat\",\"kb-link-id\"]]",
    "moduleName": "cc-frontend/components/kb/entry/part/minimal.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});