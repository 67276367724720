define("cc-frontend/lib/actions/course/calendar/MOVE_UNIT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "lodash"], function (_exports, _patchUpdater, _unitUtils, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "MOVE_UNIT",
    params: {
      unitId: "string",
      amount: "number",
      course: "object",
      planbook: "object",
      newStartDate: "string",
      rotationCalendar: "object"
    },

    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;

      let unit = _lodash.default.find(payload.course.attributes.calendar.units, {
        id: payload.unitId
      });

      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let {
        transferrableArray,
        courseDatesWithoutUnit
      } = (0, _unitUtils.extractUnit)(payload.amount, unit, payload.course.attributes.calendar.dates, consolidatedCourseDatesOff, schoolDays);
      let newStartDate = payload.newStartDate || (0, _unitUtils.findNewDate)(payload.amount, unit.startDate, consolidatedCourseDatesOff, schoolDays);
      let newCourseDates = (0, _unitUtils.insertRange)(transferrableArray, newStartDate, payload.amount, unit, courseDatesWithoutUnit, consolidatedCourseDatesOff, schoolDays);
      let ret;

      try {
        ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
      } catch (e) {
        console.error(e);
        return [];
      }

      let {
        courseDates,
        units
      } = ret;

      let unitIdsOnTheMove = _lodash.default.get(_lodash.default.find(units, {
        id: unit.id
      }), "descendantIds").concat([unit.id]);

      let cardStackIdsOnTheMove = (0, _lodash.default)(courseDates).filter(cd => _lodash.default.includes(cd.attributes.unitIds, unit.id)).map("attributes.cardStackId").value();

      let patch = _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).patch;

      patch.unitIdsOnTheMove = unitIdsOnTheMove;
      patch.cardStackIdsOnTheMove = cardStackIdsOnTheMove;
      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let course = findFn("course", payload.course.id);
      let planbook = findFn("planbook", course.relationships.planbook.data.id);
      let unit = findFn("card-stack", payload.unitId) || findFn("card-stack-summary", payload.unitId) || {};
      let unitTitle = Ember.get(unit, "attributes.title");
      let amount = Math.abs(payload.amount);
      let direction = payload.amount > 0 ? "forward" : "backwards";
      let dayOrDays = payload.amount === 1 ? "day" : "days";
      return {
        title: "Moved Unit",
        titleForAnalytics: "Unit - Move",
        description: `Moved "${unitTitle}" ${direction} ${amount} ${dayOrDays}`,
        context: {
          unitTitle: Ember.get(unit, "attributes.title"),
          courseTitle: Ember.get(course, "attributes.title"),
          planbookTitle: Ember.get(planbook, "attributes.title"),
          unitId: Ember.get(unit, "id"),
          courseId: Ember.get(course, "id"),
          planbookId: Ember.get(planbook, "id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `unit-${payload.unitId}`,
          to: [`course-edits:course-${course.id}`, `planbook-edits:planbook-${planbook.id}`]
        }
      };
    }

  };
  _exports.default = _default;
});