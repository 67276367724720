define("cc-frontend/lib/actions/course/date/COURSE_DATE_UNFORCE_ON", ["exports", "cc-frontend/lib/find-course-date", "cc-frontend/lib/patch-updater", "lodash", "lodash-es", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models"], function (_exports, _findCourseDate, _patchUpdater, _lodash, _lodashEs, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareUnforceOn = prepareUnforceOn;
  _exports.default = _exports.COURSE_DATE_UNFORCE_ON = void 0;

  function prepareUnforceOn(payload) {
    return {
      name: COURSE_DATE_UNFORCE_ON.name,
      payload: payload,
      patches: COURSE_DATE_UNFORCE_ON.patches(payload),
      undoPatches: COURSE_DATE_UNFORCE_ON.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(COURSE_DATE_UNFORCE_ON.narrative, payload)
    };
  }

  const COURSE_DATE_UNFORCE_ON = {
    name: "COURSE_DATE_UNFORCE_ON",

    patches(payload) {
      // I think we need to recalc the datesOff
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(payload.course, payload.dateString);

      if (courseDate) {
        let patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
          id: courseDate.id
        }).set("embedded", "attributes.isForcedOn", false).pull("attributes.calendar.datesForcedOn", payload.dateString).inc("attributes.calendar.version", 1);

        return [patch.patch];
      } else {
        throw Error("CourseDate is undefined!!");
      }
    },

    undoPatches(payload) {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(payload.course, payload.dateString);

      if (courseDate) {
        let patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
          id: courseDate.id
        }).set("embedded", "attributes.isForcedOn", true).push("attributes.calendar.datesForcedOn", payload.dateString).inc("attributes.calendar.version", 1);

        return [patch.patch];
      } else {
        throw Error("CourseDate is undefined!!");
      }
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId); // @ts-ignore no types for moment

      let formattedDateString = moment(payload.dateString).format("ddd, MMM. D, YYYY");
      let courseTitle = payload.course.attributes.title;
      let context = (0, _defaultCourseDateContext.default)(payload, models);

      let newContext = _lodash.default.assign({}, context, {
        unforcedOn: true
      });

      return {
        title: "Turned class back on",
        titleForAnalytics: "Lesson - Toggle Off",
        description: `Turned your ${courseTitle} class back on for ${formattedDateString}`,
        context: newContext,
        activity: (0, _defaultCourseDateActivity.default)(payload, models)
      };
    }

  };
  _exports.COURSE_DATE_UNFORCE_ON = COURSE_DATE_UNFORCE_ON;
  var _default = COURSE_DATE_UNFORCE_ON;
  _exports.default = _default;
});