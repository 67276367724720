define("cc-frontend/helpers/make-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeDate = makeDate;
  _exports.default = void 0;

  function makeDate(params, hash) {
    return params[0] ? dateFns.parse(params[0]) : null;
  }

  var _default = Ember.Helper.helper(makeDate);

  _exports.default = _default;
});