define("cc-frontend/components/planner/menus/week-menu", ["exports", "@ember-decorators/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DS::DropdownMenu @iconColumns="3" @arrowPlacement="TOP_RIGHT">
    <div class="g-dropdown-menu__title">
      <Planner::Menus::Titles::Primary @title="Week Menu" />
    </div>
    {{#if (includes @planbook.meta.permissions "can-edit-plan")}}
      <DS::DropdownMenu::SingleButtonRow @iconName="icons/link-simple" @isAtTopOfMenu={{true}}>
        <Planner::Menus::Parts::ShareLink
          @planbook={{@planbook}}
          @date={{@startDate}}
          @perspective="week"
          @closeMenu={{@closeMenu}}
        >
          Share Link
        </Planner::Menus::Parts::ShareLink>
      </DS::DropdownMenu::SingleButtonRow>
    {{/if}}
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/print-text"
      @clickAction={{this.printOrDownload}}
      @isAtTopOfMenu={{if (includes @planbook.meta.permissions "can-edit-plan") false true}}
    >
      Print or Download
    </DS::DropdownMenu::SingleButtonRow>
    {{! not implemented}}
    {{!
        <div class="g-dropdown-menu__single-button-row-divider"></div>
  
        <DS::DropdownMenu::SingleButtonRow @isSecondary=true>
          Edit Rotation Days & Order
        </DS::DropdownMenu::SingleButtonRow>
      }}
  </DS::DropdownMenu>
  */
  {
    "id": "NNe6Tk85",
    "block": "[[[8,[39,0],null,[[\"@iconColumns\",\"@arrowPlacement\"],[\"3\",\"TOP_RIGHT\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"g-dropdown-menu__title\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@title\"],[\"Week Menu\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,3],[[30,1,[\"meta\",\"permissions\"]],\"can-edit-plan\"],null],[[[1,\"    \"],[8,[39,4],null,[[\"@iconName\",\"@isAtTopOfMenu\"],[\"icons/link-simple\",true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@planbook\",\"@date\",\"@perspective\",\"@closeMenu\"],[[30,1],[30,2],\"week\",[30,3]]],[[\"default\"],[[[[1,\"\\n        Share Link\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,4],null,[[\"@iconName\",\"@clickAction\",\"@isAtTopOfMenu\"],[\"icons/print-text\",[30,0,[\"printOrDownload\"]],[52,[28,[37,3],[[30,1,[\"meta\",\"permissions\"]],\"can-edit-plan\"],null],false,true]]],[[\"default\"],[[[[1,\"\\n    Print or Download\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@planbook\",\"@startDate\",\"@closeMenu\"],false,[\"d-s/dropdown-menu\",\"planner/menus/titles/primary\",\"if\",\"includes\",\"d-s/dropdown-menu/single-button-row\",\"planner/menus/parts/share-link\"]]",
    "moduleName": "cc-frontend/components/planner/menus/week-menu.hbs",
    "isStrictMode": false
  });

  let WeekMenu = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec(_class = (_class2 = class WeekMenu extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "courses", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "startDate", void 0);

      _defineProperty(this, "endDate", void 0);

      _defineProperty(this, "dateRangeString", void 0);

      _defineProperty(this, "isFilteringClasses", void 0);

      _defineProperty(this, "classesLinedUp", void 0);

      _defineProperty(this, "perspective", void 0);

      _defineProperty(this, "openOnHover", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _defineProperty(this, "hiddenCourseIds", []);

      _initializerDefineProperty(this, "modalManager", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);
    }

    // Actions
    printOrDownload() {
      if (this.session.isAuthenticated !== true) {
        this.session.mustSignInToProceed("Print/Download Day");
        return;
      }

      this.closeMenu();

      if (_lodash.default.includes(this.session.user.content.meta.permissions, "can-print-week")) {
        this.modalManager.openModal("planner/modals/print-download", {
          courseIds: _lodash.default.chain(this.courses).map(course => course.id).reject(courseId => _lodash.default.includes(this.hiddenCourseIds, courseId)).value(),
          isFilteringClasses: this.isFilteringClasses,
          classesLinedUp: this.classesLinedUp,
          planbookId: this.planbook.id,
          startDate: this.startDate,
          endDate: this.endDate,
          currentUserId: this.session.userId,
          dateRangeString: this.dateRangeString,
          layout: this.perspective,
          canPrint: _lodash.default.includes(this.session.user.content.meta.permissions, "can-print-week")
        });
      } else {
        this.modalManager.openModal("planner/modals/print-paywall", {});
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "modalManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "printOrDownload", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "printOrDownload"), _class2.prototype)), _class2)) || _class);
  _exports.default = WeekMenu;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, WeekMenu);
});