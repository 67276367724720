define("cc-frontend/components/planner/card-stacks/cards/parts/text-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="text-block" ...attributes data-text-block>
    {{yield}}
  </div>
  */
  {
    "id": "tTKiRXB1",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[\"text-block\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/text-block.css\"]]]]]],[17,1],[24,\"data-text-block\",\"\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"local-class\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/parts/text-block.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});