define("cc-frontend/templates/icons/-social-google-drive-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KbhoWSn6",
    "block": "[[[10,\"svg\"],[14,\"data-name\",\"Layer 1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M97.79 455.13l79.1-137h316.43l-79.11 137z\"],[14,\"fill\",\"#3777e3\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M335.11 318.09h158.21L335.11 44H176.89z\"],[14,\"fill\",\"#ffcf63\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M18.68 318.09l79.11 137L256 181 176.89 44z\"],[14,\"fill\",\"#11a861\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/icons/-social-google-drive-color.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});