define("cc-frontend/helpers/prismic-as-html", ["exports", "prismic-dom"], function (_exports, _prismicDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prismicAsHtml = prismicAsHtml;
  _exports.default = void 0;

  function prismicAsHtml(params
  /*, hash*/
  ) {
    return _prismicDom.default.RichText.asHtml(params[0] || []);
  }

  var _default = Ember.Helper.helper(prismicAsHtml);

  _exports.default = _default;
});