define("cc-frontend/components/planner/course/outline-and-detail", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="course-outline-with-detail">
    {{#if this.showEverything}}
      <div local-class="course-outline-with-detail__row">
        <Planner::Course::OutlineAndDetail::Outline
          @focusedItemId={{this.localFocusedItemId}}
          @course={{@course}}
          @planbook={{@planbook}}
          @rotationCalendar={{@rotationCalendar}}
          @onLessonSelect={{@onLessonSelect}}
          @onUnitSelect={{@onUnitSelect}}
          @selectedDateStrings={{@selectedDateStrings}}
          @selectedUnitIds={{@selectedUnitIds}}
          @onNewFocusedItemId={{this.hasNewFocusedItemId}}
          @canBeEditable={{@canBeEditable}}
          @isSelectable={{@isSelectable}}
        />
  
        <div local-class="course-outline-with-detail__detail">
          <div class="course-outline-detail">
            {{#if (or this.localFocusedItemId this.focusedItemId)}}
              <Planner::Course::OutlineAndDetail::Detail
                @course={{this.course}}
                @planbook={{this.planbook}}
                @rotationCalendar={{this.rotationCalendar}}
                @focusedItemId={{this.localFocusedItemId}}
                @canBeEditable={{this.canBeEditable}}
                @editCourseTitleAndOrder={{@editCourseTitleAndOrder}}
              />
            {{/if}}
          </div>
        </div>
      </div>
    {{else}}
      <DS::ThreeDotSpinner />
    {{/if}}
  </div>
  */
  {
    "id": "bo8FVNVs",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"course-outline-with-detail\"],[[\"from\"],[\"cc-frontend/components/planner/course/outline-and-detail.css\"]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"showEverything\"]],[[[1,\"    \"],[10,0],[15,0,[29,[[28,[37,0],[\"course-outline-with-detail__row\"],[[\"from\"],[\"cc-frontend/components/planner/course/outline-and-detail.css\"]]]]]],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@focusedItemId\",\"@course\",\"@planbook\",\"@rotationCalendar\",\"@onLessonSelect\",\"@onUnitSelect\",\"@selectedDateStrings\",\"@selectedUnitIds\",\"@onNewFocusedItemId\",\"@canBeEditable\",\"@isSelectable\"],[[30,0,[\"localFocusedItemId\"]],[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,0,[\"hasNewFocusedItemId\"]],[30,8],[30,9]]],null],[1,\"\\n\\n      \"],[10,0],[15,0,[29,[[28,[37,0],[\"course-outline-with-detail__detail\"],[[\"from\"],[\"cc-frontend/components/planner/course/outline-and-detail.css\"]]]]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"course-outline-detail\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"localFocusedItemId\"]],[30,0,[\"focusedItemId\"]]],null],[[[1,\"            \"],[8,[39,4],null,[[\"@course\",\"@planbook\",\"@rotationCalendar\",\"@focusedItemId\",\"@canBeEditable\",\"@editCourseTitleAndOrder\"],[[30,0,[\"course\"]],[30,0,[\"planbook\"]],[30,0,[\"rotationCalendar\"]],[30,0,[\"localFocusedItemId\"]],[30,0,[\"canBeEditable\"]],[30,10]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]]],[13]],[\"@course\",\"@planbook\",\"@rotationCalendar\",\"@onLessonSelect\",\"@onUnitSelect\",\"@selectedDateStrings\",\"@selectedUnitIds\",\"@canBeEditable\",\"@isSelectable\",\"@editCourseTitleAndOrder\"],false,[\"local-class\",\"if\",\"planner/course/outline-and-detail/outline\",\"or\",\"planner/course/outline-and-detail/detail\",\"d-s/three-dot-spinner\"]]",
    "moduleName": "cc-frontend/components/planner/course/outline-and-detail.hbs",
    "isStrictMode": false
  });

  let Outline = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec(_class = (_class2 = class Outline extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "focusedItemId", void 0);

      _defineProperty(this, "canBeEditable", void 0);

      _defineProperty(this, "isSelectable", void 0);

      _defineProperty(this, "onNewFocusedItemId", void 0);

      _defineProperty(this, "localFocusedItemId", null);

      _defineProperty(this, "showEverything", false);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);
    }

    didReceiveAttrs() {
      if (this.focusedItemId) {
        Ember.run.join(() => {
          Ember.set(this, "localFocusedItemId", this.focusedItemId);
        });
      }
    }

    didInsertElement() {
      // We do this because everything will wait to render until the outline...
      // which makes nothing appear on the screen for a second or two
      Ember.run.later(() => Ember.set(this, "showEverything", true), 1);
    }

    hasNewFocusedItemId(id) {
      this.onNewFocusedItemId(id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appState", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "hasNewFocusedItemId", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hasNewFocusedItemId"), _class2.prototype)), _class2)) || _class);
  _exports.default = Outline;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Outline);
});