define("cc-frontend/components/icons/ios-plus-no-padding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ...attributes
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 0C6.14407 0 6.26087 0.116795 6.26087 0.26087V5.73913H11.7391C11.8832 5.73913 12 5.85593 12 6C12 6.14407 11.8832 6.26087 11.7391 6.26087H6.26087V11.7391C6.26087 11.8832 6.14407 12 6 12C5.85593 12 5.73913 11.8832 5.73913 11.7391V6.26087H0.26087C0.116795 6.26087 0 6.14407 0 6C0 5.85593 0.116795 5.73913 0.26087 5.73913H5.73913V0.26087C5.73913 0.116795 5.85593 0 6 0Z"
    ></path>
  </svg>
  */
  {
    "id": "GnfCe71l",
    "block": "[[[11,\"svg\"],[24,\"width\",\"12\"],[24,\"height\",\"12\"],[24,\"viewBox\",\"0 0 12 12\"],[24,\"fill\",\"none\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[17,1],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M6 0C6.14407 0 6.26087 0.116795 6.26087 0.26087V5.73913H11.7391C11.8832 5.73913 12 5.85593 12 6C12 6.14407 11.8832 6.26087 11.7391 6.26087H6.26087V11.7391C6.26087 11.8832 6.14407 12 6 12C5.85593 12 5.73913 11.8832 5.73913 11.7391V6.26087H0.26087C0.116795 6.26087 0 6.14407 0 6C0 5.85593 0.116795 5.73913 0.26087 5.73913H5.73913V0.26087C5.73913 0.116795 5.85593 0 6 0Z\"],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/ios-plus-no-padding.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});