define("cc-frontend/components/planner/standards-tracker/redirect-to-setup", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="planbook-setup-notice" class="planbook-setup-notice">
    <div local-class="planbook-setup-notice__headline" class="planbook-setup-notice__headline">
      This planbook isn't setup, yet!
    </div>
    <div local-class="planbook-setup-notice__description" class="planbook-setup-notice__description">
      This planbook doesn't have any classes yet. Without classes, your lessons won't have a place to
      call home.
      <br />
      Head over to your
      <LinkTo @route="planbookv5.planner.week">
        Planbook
      </LinkTo>
      and click the
      <div local-class="add-button">
        <Icons::AddNoCircleHeavier />
      </div>
      to finish setting up!
    </div>
  </div>
  */
  {
    "id": "0B40tZSo",
    "block": "[[[10,0],[15,0,[29,[\"planbook-setup-notice \",[28,[37,0],[\"planbook-setup-notice\"],[[\"from\"],[\"cc-frontend/components/planner/standards-tracker/redirect-to-setup.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"planbook-setup-notice__headline \",[28,[37,0],[\"planbook-setup-notice__headline\"],[[\"from\"],[\"cc-frontend/components/planner/standards-tracker/redirect-to-setup.css\"]]]]]],[12],[1,\"\\n    This planbook isn't setup, yet!\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"planbook-setup-notice__description \",[28,[37,0],[\"planbook-setup-notice__description\"],[[\"from\"],[\"cc-frontend/components/planner/standards-tracker/redirect-to-setup.css\"]]]]]],[12],[1,\"\\n    This planbook doesn't have any classes yet. Without classes, your lessons won't have a place to\\n    call home.\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    Head over to your\\n    \"],[8,[39,1],null,[[\"@route\"],[\"planbookv5.planner.week\"]],[[\"default\"],[[[[1,\"\\n      Planbook\\n    \"]],[]]]]],[1,\"\\n    and click the\\n    \"],[10,0],[15,0,[29,[[28,[37,0],[\"add-button\"],[[\"from\"],[\"cc-frontend/components/planner/standards-tracker/redirect-to-setup.css\"]]]]]],[12],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    to finish setting up!\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"local-class\",\"link-to\",\"icons/add-no-circle-heavier\"]]",
    "moduleName": "cc-frontend/components/planner/standards-tracker/redirect-to-setup.hbs",
    "isStrictMode": false
  });

  let RedirectToSetupComponent = (_dec = (0, _component.tagName)(""), _dec(_class = class RedirectToSetupComponent extends Ember.Component {}) || _class);
  _exports.default = RedirectToSetupComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RedirectToSetupComponent);
});