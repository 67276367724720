define("cc-frontend/templates/layouts/blank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nTRJCl7y",
    "block": "[[[10,0],[15,0,[29,[\"ember-dialog-dialog \",[36,0],\" \",[52,[33,2],\"substrate\"],\" js-opened-from-menu\"]]],[12],[1,\"\\n  \"],[11,3],[24,0,\"btn-exit-modal-string\"],[4,[38,3],[[30,0],\"decline\"],null],[12],[1,\"\\n    Close\\n    \"],[10,1],[14,0,\"close-modal-times\"],[12],[1,\"\\n      ×\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"dialog-content\"],[14,\"tabindex\",\"-1\"],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"      \"],[10,0],[14,0,\"dialog-body\"],[12],[1,\"\\n        \"],[19,[33,4],[]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[46,\"dialog-body\",null,[[\"layout\",\"contextObject\",\"context\",\"class\"],[[33,7],[33,8],[33,9],\"dialog-body\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],true,[\"className\",\"if\",\"substrate\",\"action\",\"templateName\",\"partial\",\"component\",\"template\",\"contextObject\",\"context\"]]",
    "moduleName": "cc-frontend/templates/layouts/blank.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});