define("cc-frontend/templates/new-group/show/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xQQpVt2F",
    "block": "[[[1,[28,[35,0],[\"Setup\"],null]],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"school-backdrop\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"school-backdrop__fade\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"school-backdrop__pattern\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"school-backdrop__gradient\"],[15,5,[28,[37,2],[[28,[37,3],[\"background-image: linear-gradient(90deg, \",[30,1,[\"attributes\",\"branding\",\"primaryColor\"]],\", \",[30,1,[\"attributes\",\"branding\",\"secondaryColor\"]]],null]],null]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"modal-manager-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-manager\"],[12],[1,\"\\n    \"],[10,0],[14,5,\"z-index: 1000\"],[12],[1,\"\\n      \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@model\",\"@order\"],[[28,[37,6],null,[[\"school\"],[[30,1]]]],1]],[[\"default\"],[[[[1,\"\\n          \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@model\"],false,[\"page-title\",\"default-navbar\",\"html-safe\",\"concat\",\"animated-container\",\"school/setup\",\"hash\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});