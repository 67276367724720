define("cc-frontend/lib/actions/course/calendar/MOVE_LESSON_BY_AMOUNT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "lodash-es"], function (_exports, _patchUpdater, _unitUtils, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareBumpLessonForward = prepareBumpLessonForward;
  _exports.MOVE_LESSON_BY_AMOUNT = void 0;

  function prepareBumpLessonForward(payload) {
    return {
      name: MOVE_LESSON_BY_AMOUNT.name,
      payload: payload,
      patches: MOVE_LESSON_BY_AMOUNT.patches(payload),
      undoPatches: MOVE_LESSON_BY_AMOUNT.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(MOVE_LESSON_BY_AMOUNT.narrative, payload)
    };
  }

  const MOVE_LESSON_BY_AMOUNT = {
    name: "MOVE_LESSON_BY_AMOUNT",

    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;
      let cardStackId = (0, _unitUtils.getCardStackIdForDate)(payload.course.attributes.calendar.dates, payload.dateString);
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let {
        transferrableArray,
        courseDatesWithoutLesson
      } = (0, _unitUtils.extractLesson)(cardStackId, payload.course.attributes.calendar.dates);
      let newStartDate = (0, _unitUtils.findNewDate)(payload.amount, payload.dateString, // @ts-ignore unsure why it's erroring Scott says ok to ignore for now
      consolidatedCourseDatesOff, schoolDays);
      let newCourseDates = (0, _unitUtils.insertRange)(transferrableArray, newStartDate, payload.amount, {}, courseDatesWithoutLesson, consolidatedCourseDatesOff, schoolDays);
      let ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
      let {
        courseDates,
        units
      } = ret;

      let patch = _patchUpdater.default.create("course", payload.course.id).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).inc(`attributes.calendar.version`, 1).patch; // patch.unitIdsOnTheMove   = unitIdsOnTheMove
      // patch.cardStackIdsOnTheMove = cardStackIdsOnTheMove


      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).inc("attributes.calendar.version", 1).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      var _models$lesson, _models$lesson$attrib;

      let cardStackId = (0, _unitUtils.getCardStackIdForDate)(payload.course.attributes.calendar.dates, payload.dateString);
      let course = findFn("course", payload.course.id);
      let models = {
        course: course,
        planbook: findFn("planbook", course.relationships.planbook.data.id),
        lesson: findFn("card-stack", cardStackId) || findFn("card-stack-summary", cardStackId)
      };
      let courseId = models.course.id;
      let planbookId = models.planbook.id;
      let lessonTitle = ((_models$lesson = models.lesson) === null || _models$lesson === void 0 ? void 0 : (_models$lesson$attrib = _models$lesson.attributes) === null || _models$lesson$attrib === void 0 ? void 0 : _models$lesson$attrib.title) || "";
      let amount = Math.abs(payload.amount);
      let direction = payload.amount > 0 ? "forward" : "backwards";
      let dayOrDays = payload.amount === 1 ? "day" : "days";
      return {
        title: "Moved Lesson",
        titleForAnalytics: "Lesson - Move",
        description: `Moved "${lessonTitle}" ${direction} ${amount} ${dayOrDays}`,
        context: {
          triggeredBy: "Bump button",
          lessonTitle: models.lesson ? models.lesson.attributes.title : "[NEW LESSON]",
          courseTitle: models.course.attributes.title,
          planbookTitle: models.planbook.attributes.title,
          cardStackId: models.lesson ? models.lesson.id : "",
          courseId: models.course.id,
          planbookId: models.planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `lesson-${cardStackId}`,
          to: [`course-edits:course-${courseId}`, `planbook-edits:planbook-${planbookId}`]
        }
      };
    }

  };
  _exports.MOVE_LESSON_BY_AMOUNT = MOVE_LESSON_BY_AMOUNT;
});