define("cc-frontend/components/planner/card-stacks/add-to-routine-button.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "add-button": "_add-button_16hrjw"
  };
  _exports.default = _default;
});