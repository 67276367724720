define("cc-frontend/helpers/json-stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.jsonStringify = jsonStringify;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function jsonStringify(params) {
    let doc = params[0]; // return Ember.String.htmlSafe(`<pre><code class="language-json">${JSON.stringify(doc, null, 1)}</code></pre>`)

    let text;

    try {
      text = Prism.highlight(JSON.stringify(doc, null, 1), Prism.languages.json);
    } catch (e) {
      console.log("e", e);
    }

    return Ember.String.htmlSafe(`<pre><code>${text}</code></pre>`);
  }

  class JsonStringify extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "compute", jsonStringify);
    }

  }

  _exports.default = JsonStringify;
});