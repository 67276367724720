define("cc-frontend/components/planner/navs/subnav", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <!-- sub-nav -->
  <div local-class="sub-nav" class="test-planbook-sub-nav">
    <LinkTo
      @route="planbookv5.planner.day"
      class="{{local-class "sub-nav__item"}} test-day-link"
      data-test-day-view
    >
      Day
    </LinkTo>
    <LinkTo
      @route="planbookv5.planner.week"
      class="{{local-class "sub-nav__item"}} test-week-link"
      data-test-week-view
    >
      Week
    </LinkTo>
    <LinkTo
      @route="planbookv5.planner.month"
      class="{{local-class "sub-nav__item"}} test-month-link"
      data-test-month-view
    >
      Month
    </LinkTo>
    {{!--
        {{#link-to 'planbookv5.planner.year' local-class="sub-nav__item"}}Year{{/link-to}}
      --}}
    <LinkTo
      @route="planbookv5.planner.timeline"
      class="{{local-class "sub-nav__item"}} test-timeline-link"
      data-test-unit-timeline-view
    >
      Unit Timeline
    </LinkTo>
    <LinkTo
      @route="planbookv5.planner.outline"
      class="{{local-class "sub-nav__item"}} test-outline-link"
      data-test-outline-view
    >
      Outline
    </LinkTo>
  </div>
  */
  {
    "id": "wV22gmMe",
    "block": "[[[3,\" sub-nav \"],[1,\"\\n\"],[10,0],[15,0,[29,[\"test-planbook-sub-nav \",[28,[37,0],[\"sub-nav\"],[[\"from\"],[\"cc-frontend/components/planner/navs/subnav.css\"]]]]]],[12],[1,\"\\n  \"],[8,[39,1],[[16,0,[29,[[28,[37,0],[\"sub-nav__item\"],[[\"from\"],[\"cc-frontend/components/planner/navs/subnav.css\"]]],\" test-day-link\"]]],[24,\"data-test-day-view\",\"\"]],[[\"@route\"],[\"planbookv5.planner.day\"]],[[\"default\"],[[[[1,\"\\n    Day\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],[[16,0,[29,[[28,[37,0],[\"sub-nav__item\"],[[\"from\"],[\"cc-frontend/components/planner/navs/subnav.css\"]]],\" test-week-link\"]]],[24,\"data-test-week-view\",\"\"]],[[\"@route\"],[\"planbookv5.planner.week\"]],[[\"default\"],[[[[1,\"\\n    Week\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],[[16,0,[29,[[28,[37,0],[\"sub-nav__item\"],[[\"from\"],[\"cc-frontend/components/planner/navs/subnav.css\"]]],\" test-month-link\"]]],[24,\"data-test-month-view\",\"\"]],[[\"@route\"],[\"planbookv5.planner.month\"]],[[\"default\"],[[[[1,\"\\n    Month\\n  \"]],[]]]]],[1,\"\\n\"],[1,\"  \"],[8,[39,1],[[16,0,[29,[[28,[37,0],[\"sub-nav__item\"],[[\"from\"],[\"cc-frontend/components/planner/navs/subnav.css\"]]],\" test-timeline-link\"]]],[24,\"data-test-unit-timeline-view\",\"\"]],[[\"@route\"],[\"planbookv5.planner.timeline\"]],[[\"default\"],[[[[1,\"\\n    Unit Timeline\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],[[16,0,[29,[[28,[37,0],[\"sub-nav__item\"],[[\"from\"],[\"cc-frontend/components/planner/navs/subnav.css\"]]],\" test-outline-link\"]]],[24,\"data-test-outline-view\",\"\"]],[[\"@route\"],[\"planbookv5.planner.outline\"]],[[\"default\"],[[[[1,\"\\n    Outline\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"local-class\",\"link-to\"]]",
    "moduleName": "cc-frontend/components/planner/navs/subnav.hbs",
    "isStrictMode": false
  });

  let PlanbookMainComponent = (_dec = (0, _component.tagName)(""), _dec(_class = class PlanbookMainComponent extends Ember.Component {}) || _class);
  _exports.default = PlanbookMainComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookMainComponent);
});