define("cc-frontend/components/planner/card-stacks/cards/parts/add-comment-form", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="add-comment-form">
    <ScribeEditor
      @value={{this.commentText}}
      @onValueUpdate={{this.updateComment}}
      @class={{local-class "comment-box"}}
      @placeholder="Leave a comment"
      {{did-insert this.focusOnInput}}
    />
  
    <div local-class="add-comment-form-buttons">
      <div local-class="add-comment-form-buttons__cancel" {{on "click" @toggleCommentField}}>
        Cancel
      </div>
      <div
        local-class="add-comment-form-buttons__submit"
        {{on "click" (fn @addComment this.commentText)}}
      >
        <Icons::CheckNoCircle />
        Add
      </div>
    </div>
  </div>
  */
  {
    "id": "JYnVxKJm",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"add-comment-form\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/add-comment-form.css\"]]]]]],[12],[1,\"\\n  \"],[8,[39,1],[[4,[38,2],[[30,0,[\"focusOnInput\"]]],null]],[[\"@value\",\"@onValueUpdate\",\"@class\",\"@placeholder\"],[[30,0,[\"commentText\"]],[30,0,[\"updateComment\"]],[28,[37,0],[\"comment-box\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/add-comment-form.css\"]]],\"Leave a comment\"]],null],[1,\"\\n\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"add-comment-form-buttons\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/add-comment-form.css\"]]]]]],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[[28,[37,0],[\"add-comment-form-buttons__cancel\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/add-comment-form.css\"]]]]]],[4,[38,3],[\"click\",[30,1]],null],[12],[1,\"\\n      Cancel\\n    \"],[13],[1,\"\\n    \"],[11,0],[16,0,[29,[[28,[37,0],[\"add-comment-form-buttons__submit\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/add-comment-form.css\"]]]]]],[4,[38,3],[\"click\",[28,[37,4],[[30,2],[30,0,[\"commentText\"]]],null]],null],[12],[1,\"\\n      \"],[8,[39,5],null,null,null],[1,\"\\n      Add\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@toggleCommentField\",\"@addComment\"],false,[\"local-class\",\"scribe-editor\",\"did-insert\",\"on\",\"fn\",\"icons/check-no-circle\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/parts/add-comment-form.hbs",
    "isStrictMode": false
  });

  let AddCommentFormComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class AddCommentFormComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "commentText", _descriptor, this);
    }

    updateComment(text) {
      this.commentText = text;
    }

    focusOnInput(element) {
      Ember.run.scheduleOnce("afterRender", this, () => {
        element.focus();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "commentText", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateComment", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateComment"), _class.prototype)), _class));
  _exports.default = AddCommentFormComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AddCommentFormComponent);
});