define("cc-frontend/components/kb/search/main", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/app", "lodash-es", "tracked-built-ins"], function (_exports, _glimmerComponent, _app, _lodashEs, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert this.onDidInsertElement}}>
  
    <PowerSelectMultiple
      @searchPlaceholder="Find anything (like attaching files, printing, creating units)"
      @placeholder="Find anything (like attaching files, printing, creating units)"
      @options={{this.articles}}
      @search={{this.searchAlgolia}}
      @searchEnabled={{true}}
      @selected={{this.selected}}
      @defaultHighlighted={{false}}
      @onChange={{this.selectArticle}}
      @triggerClass={{concat "kb-search " @searchBarClass}}
      @dropdownClass={{concat "kb-search " @searchBarClass}}
      @afterOptionsComponent="kb/search/after-options"
      @loadingMessage={{false}}
      as |article|
    >
      {{{article.attributes.title}}}
    </PowerSelectMultiple>
  </div>
  */
  {
    "id": "7RtptPM9",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"onDidInsertElement\"]]],null],[12],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@searchPlaceholder\",\"@placeholder\",\"@options\",\"@search\",\"@searchEnabled\",\"@selected\",\"@defaultHighlighted\",\"@onChange\",\"@triggerClass\",\"@dropdownClass\",\"@afterOptionsComponent\",\"@loadingMessage\"],[\"Find anything (like attaching files, printing, creating units)\",\"Find anything (like attaching files, printing, creating units)\",[30,0,[\"articles\"]],[30,0,[\"searchAlgolia\"]],true,[30,0,[\"selected\"]],false,[30,0,[\"selectArticle\"]],[28,[37,2],[\"kb-search \",[30,1]],null],[28,[37,2],[\"kb-search \",[30,1]],null],\"kb/search/after-options\",false]],[[\"default\"],[[[[1,\"\\n    \"],[2,[30,2,[\"attributes\",\"title\"]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"],[13]],[\"@searchBarClass\",\"article\"],false,[\"did-insert\",\"power-select-multiple\",\"concat\"]]",
    "moduleName": "cc-frontend/components/kb/search/main.hbs",
    "isStrictMode": false
  });

  let KbSearch = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class KbSearch extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "algolia", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "selected", _descriptor4, this);

      _initializerDefineProperty(this, "matches", _descriptor5, this);

      _initializerDefineProperty(this, "articles", _descriptor6, this);

      _initializerDefineProperty(this, "searchTerm", _descriptor7, this);
    }

    onDidInsertElement() {
      return this.algolia.help.search("").then(data => {
        if (this.isDestroyed || this.isDestroying) return;
        this.articles = data.hits;
      });
    }

    searchAlgolia(searchQuery) {
      let term = (0, _lodashEs.truncate)(searchQuery, {
        length: 511
      });
      this.searchTerm = term;
      return this.algolia.help.search(term).then(data => {
        let results = (0, _lodashEs.map)(data.hits, hit => {
          return {
            id: hit.id,
            attributes: {
              title: hit._highlightResult.attributes.title.value
            },
            relationships: {
              ancestors: hit.relationships.ancestors
            }
          };
        });
        if (this.isDestroyed || this.isDestroying) return;
        this.matches = results;
        return results;
      }).catch(function (err) {
        (0, _app.handleError)(err);
      });
    }

    selectArticle(the_matches) {
      let matches = (0, _lodashEs.compact)(the_matches);
      let match;

      if (matches.length === 0) {
        match = (0, _lodashEs.first)(this.matches);
      } else {
        match = (0, _lodashEs.first)(matches);
      }

      if ((0, _lodashEs.isNil)(match)) return;
      let ancestorIds = (0, _lodashEs.map)(match.relationships.ancestors.data, ancestor => ancestor.id);
      let parentId = (0, _lodashEs.last)(ancestorIds);
      this.store.dispatch("KB_SELECT_ARTICLE_FROM_SEARCH", {
        searchQuery: this.searchTerm,
        articleTitle: match.attributes.title,
        articleId: match.id,
        ancestorIds: ancestorIds,
        parentId: parentId
      });
      this.router.transitionTo("help.article", match.id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "algolia", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selected", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "matches", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "articles", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "searchTerm", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDidInsertElement", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchAlgolia", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "searchAlgolia"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectArticle", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectArticle"), _class.prototype)), _class));
  _exports.default = KbSearch;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KbSearch);
});