define("cc-frontend/lib/actions/course/calendar/COURSE_DATE_BUMP_LESSON", ["exports", "lodash-es", "cc-frontend/lib/actions/course/calendar/MOVE_LESSON_BY_AMOUNT"], function (_exports, _lodashEs, _MOVE_LESSON_BY_AMOUNT) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareBumpLesson = prepareBumpLesson;
  _exports.COURSE_DATE_BUMP_LESSON = void 0;

  function prepareBumpLesson(payload) {
    return {
      name: COURSE_DATE_BUMP_LESSON.name,
      payload: payload,
      patches: COURSE_DATE_BUMP_LESSON.patches(payload),
      undoPatches: COURSE_DATE_BUMP_LESSON.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(COURSE_DATE_BUMP_LESSON.narrative, payload)
    };
  }

  const COURSE_DATE_BUMP_LESSON = {
    name: "COURSE_DATE_BUMP_LESSON",

    patches(payload) {
      let movePatches = _MOVE_LESSON_BY_AMOUNT.MOVE_LESSON_BY_AMOUNT.patches({
        dateString: payload.dateString,
        amount: 1,
        course: payload.course,
        planbook: payload.planbook,
        rotationCalendar: payload.rotationCalendar
      });

      return movePatches;
    },

    undoPatches(payload) {
      let movePatches = _MOVE_LESSON_BY_AMOUNT.MOVE_LESSON_BY_AMOUNT.undoPatches({
        dateString: payload.dateString,
        amount: 1,
        course: payload.course,
        planbook: payload.planbook,
        rotationCalendar: payload.rotationCalendar
      });

      return movePatches;
    },

    narrative(payload, findFn, userId) {
      let course = findFn("course", payload.course.id);
      let planbook = findFn("planbook", Ember.get(course, "relationships.planbook.data.id")); // @ts-ignore no types for moment

      let formattedDateString = moment(payload.dateString).format("ddd, MMM. D, YYYY");
      return {
        title: `Bumped lesson before turning class off`,
        titleForAnalytics: `Lesson - Toggle Off and Bump`,
        description: `Turned class off on ${formattedDateString} and bumped the lesson`,
        context: {
          courseTitle: Ember.get(course, "attributes.title"),
          planbookTitle: Ember.get(planbook, "attributes.title"),
          courseId: Ember.get(course, "id"),
          planbookId: Ember.get(planbook, "id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${course.id}`,
          to: [`course-edits:course-${course.id}`, `planbook-edits:planbook-${planbook.id}`]
        }
      };
    }

  };
  _exports.COURSE_DATE_BUMP_LESSON = COURSE_DATE_BUMP_LESSON;
});