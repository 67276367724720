define("cc-frontend/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function formatCurrency([quantity, decimals, sign, separator], namedArgs) {
    sign = sign !== undefined ? sign : namedArgs.sign !== undefined ? namedArgs.sign : "$";
    decimals = decimals !== undefined ? decimals : namedArgs.decimals !== undefined ? namedArgs.decimals : 2;
    separator = separator !== undefined ? separator : namedArgs.separator !== undefined ? namedArgs.separator : ",";
    let amount = parseFloat(quantity);
    let amount_rounded = amount.toFixed(decimals);
    let decimal_part = amount_rounded.toString().split(".")[1];
    let number_part = amount_rounded.toString().split(".")[0];
    let number = number_part.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    let integer = sign.toString() + " " + number;
    return decimals ? `${integer}.${decimal_part}` : integer;
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});