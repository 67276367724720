define("cc-frontend/components/planner/course/create-edit/create-modal.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_1jmefa",
    "modal-inner": "_modal-inner_1jmefa",
    "--is-small": "_--is-small_1jmefa",
    "modal-inner__close": "_modal-inner__close_1jmefa"
  };
  _exports.default = _default;
});