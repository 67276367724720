define("cc-frontend/lib/actions/lesson/LESSON_FIRST_CARDS_EDIT", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "moment"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_FIRST_CARDS_EDIT = void 0;
  // TODO This needs to be relooked at
  const LESSON_FIRST_CARDS_EDIT = {
    name: "LESSON_FIRST_CARDS_EDIT",

    patches(payload) {
      return [_patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.cards", _lodash.default.cloneDeep(payload.cards)).set("relationships.course.data", {
        id: payload.course.id,
        type: "course"
      }).set("relationships.planbook.data", {
        id: payload.planbook.id,
        type: "planbook"
      }).set("meta.cardsUpdatingFromTemplate", false).patch // Internal flag we use when the container-lesson sets the templates
      ];
    },

    undoPatches(payload) {
      return [_patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.cards", null).set("meta.cardsUpdatingFromTemplate", true).patch // Internal flag we use when the container-lesson sets the templates
      ];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let formattedDateString = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
      return {
        title: "Edit the Cards on a lesson",
        titleForAnalytics: "Lesson - Edited Cards",
        description: `Edited a lesson on ${formattedDateString} to ${_lodash.default.get(models, "course.attributes.title")}`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models, "created")
      };
    }

  };
  _exports.LESSON_FIRST_CARDS_EDIT = LESSON_FIRST_CARDS_EDIT;
  var _default = LESSON_FIRST_CARDS_EDIT;
  _exports.default = _default;
});