define("cc-frontend/components/planner/planbook/pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (find-doc "planbook-summary" @planbookId) as |fetchedPlanbook|}}
    {{#if fetchedPlanbook.isFulfilled}}
      {{#let
        (find-doc "user-summary" fetchedPlanbook.content.relationships.owner.data.id)
        as |fetchedUser|
      }}
        <User::Avatar @userSummary={{fetchedUser.content}} @avatarSizeClass="user-avatar--lg" />
        <div class="planbook-pill__text">
          <div class="planbook-pill__owner-name">
            {{fetchedUser.content.attributes.firstName}}
            {{fetchedUser.content.attributes.lastName}}
          </div>
          <div class="planbook-pill__planbook-title">
            {{fetchedPlanbook.content.attributes.title}}
          </div>
        </div>
        <div class="planbook-pill__background">
          <div class="planbook-pill__background__fade"></div>
          <div
            class="planbook-pill__background__image {{fetchedPlanbook.content.attributes.cover}}"
          ></div>
        </div>
      {{/let}}
    {{/if}}
  {{/let}}
  */
  {
    "id": "RI9s1SaV",
    "block": "[[[44,[[28,[37,1],[\"planbook-summary\",[30,1]],null]],[[[41,[30,2,[\"isFulfilled\"]],[[[44,[[28,[37,1],[\"user-summary\",[30,2,[\"content\",\"relationships\",\"owner\",\"data\",\"id\"]]],null]],[[[1,\"      \"],[8,[39,3],null,[[\"@userSummary\",\"@avatarSizeClass\"],[[30,3,[\"content\"]],\"user-avatar--lg\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"planbook-pill__text\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"planbook-pill__owner-name\"],[12],[1,\"\\n          \"],[1,[30,3,[\"content\",\"attributes\",\"firstName\"]]],[1,\"\\n          \"],[1,[30,3,[\"content\",\"attributes\",\"lastName\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"planbook-pill__planbook-title\"],[12],[1,\"\\n          \"],[1,[30,2,[\"content\",\"attributes\",\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"planbook-pill__background\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"planbook-pill__background__fade\"],[12],[13],[1,\"\\n        \"],[10,0],[15,0,[29,[\"planbook-pill__background__image \",[30,2,[\"content\",\"attributes\",\"cover\"]]]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[3]]]],[]],null]],[2]]]],[\"@planbookId\",\"fetchedPlanbook\",\"fetchedUser\"],false,[\"let\",\"find-doc\",\"if\",\"user/avatar\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/pill.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});