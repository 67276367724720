define("cc-frontend/components/planner/menus/day-menu/add-no-school-day", ["exports", "@ember-decorators/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.menuName "BUMP_OR_DELETE")}}
    <ToElsewhere
      @named="day-menu-title"
      @send={{component
        "planner/menus/date-annotation/ask-if-bump-title"
        goBack=@goBack
        title=(if
          this.moreThanOneCourseWithALesson
          "Do you want to bump these lessons?"
          "Do you want to bump this lesson?"
        )
      }}
    />
  
    <ToElsewhere
      @named="day-menu-body"
      @send={{component
        "planner/menus/date-annotation/ask-if-bump-body"
        bumpButton=(fn this.afterAskIfBump "BUMP")
        deleteButton=(fn this.afterAskIfBump "DELETE")
      }}
    />
  {{/if}}
  {{#if (eq this.menuName "EDIT_ANNOTATION")}}
    <ToElsewhere
      @named="day-menu-title"
      @send={{component
        "planner/menus/date-annotation/edit-annotation-title"
        goBack=this.goBackFromAnnotation
        title="Add No School Day"
      }}
    />
  
    <ToElsewhere
      @named="day-menu-body"
      @send={{component
        "planner/modals/edit-annotation-body"
        title=null
        description=null
        minimumStartDate=@dateString
        dateIs="FIXED_START_RANGE"
        startDate=@dateString
        endDate=@dateString
        allowRangeToggle=true
        datesAreEditable=true
        submitAction=this.createAnnotation
        submitButtonText="Create"
        closeModal=@closeMenu
      }}
    />
  {{/if}}
  */
  {
    "id": "le3lia+r",
    "block": "[[[41,[28,[37,1],[[30,0,[\"menuName\"]],\"BUMP_OR_DELETE\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"day-menu-title\",[50,\"planner/menus/date-annotation/ask-if-bump-title\",0,null,[[\"goBack\",\"title\"],[[30,1],[52,[30,0,[\"moreThanOneCourseWithALesson\"]],\"Do you want to bump these lessons?\",\"Do you want to bump this lesson?\"]]]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"day-menu-body\",[50,\"planner/menus/date-annotation/ask-if-bump-body\",0,null,[[\"bumpButton\",\"deleteButton\"],[[28,[37,4],[[30,0,[\"afterAskIfBump\"]],\"BUMP\"],null],[28,[37,4],[[30,0,[\"afterAskIfBump\"]],\"DELETE\"],null]]]]]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[30,0,[\"menuName\"]],\"EDIT_ANNOTATION\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"day-menu-title\",[50,\"planner/menus/date-annotation/edit-annotation-title\",0,null,[[\"goBack\",\"title\"],[[30,0,[\"goBackFromAnnotation\"]],\"Add No School Day\"]]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"day-menu-body\",[50,\"planner/modals/edit-annotation-body\",0,null,[[\"title\",\"description\",\"minimumStartDate\",\"dateIs\",\"startDate\",\"endDate\",\"allowRangeToggle\",\"datesAreEditable\",\"submitAction\",\"submitButtonText\",\"closeModal\"],[null,null,[30,2],\"FIXED_START_RANGE\",[30,2],[30,2],true,true,[30,0,[\"createAnnotation\"]],\"Create\",[30,3]]]]]],null],[1,\"\\n\"]],[]],null]],[\"@goBack\",\"@dateString\",\"@closeMenu\"],false,[\"if\",\"eq\",\"to-elsewhere\",\"component\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/add-no-school-day.hbs",
    "isStrictMode": false
  });

  let DayMenuAddNoSchoolDayComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = class DayMenuAddNoSchoolDayComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "courses", void 0);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "alreadyReceivedCourse", false);

      _defineProperty(this, "goBack", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _defineProperty(this, "rotationCalendar", void 0);

      _defineProperty(this, "bumpDeleteOrNothing", "NOTHING");

      _defineProperty(this, "needsTobumpDeleteOrNothing", void 0);

      _defineProperty(this, "menuName", void 0);

      _defineProperty(this, "moreThanOneCourseWithALesson", void 0);

      _defineProperty(this, "coursesWithLessons", void 0);

      _initializerDefineProperty(this, "courseCalendarEditor", _descriptor, this);

      _initializerDefineProperty(this, "planbookEditor", _descriptor2, this);
    }

    didReceiveAttrs() {
      if (this.courses === null || this.courses === undefined || this.alreadyReceivedCourse === true) return;
      this.coursesWithLessons = _lodash.default.filter(this.courses, course => {
        return this.courseCalendarEditor.doesCourseDateHaveLesson(course, this.dateString);
      });
      this.set("moreThanOneCourseWithALesson", this.coursesWithLessons.length > 1);

      if (this.coursesWithLessons.length > 0) {
        this.set("menuName", "BUMP_OR_DELETE");
      } else {
        this.set("menuName", "EDIT_ANNOTATION");
      }
    }

    goBackFromAnnotation() {
      if (this.bumpDeleteOrNothing !== "NOTHING") {
        this.set("menuName", "BUMP_OR_DELETE");
      } else {
        this.goBack();
      }
    }

    afterAskIfBump(bumpDeleteOrNothing) {
      this.set("bumpDeleteOrNothing", bumpDeleteOrNothing);
      this.set("menuName", "EDIT_ANNOTATION");
    }

    createAnnotation({
      title,
      description,
      startDate,
      endDate
    }) {
      this.planbookEditor.addNoSchoolDay({
        bumpDeleteOrNothing: this.bumpDeleteOrNothing,
        title: title,
        description: description,
        startDate: startDate,
        endDate: endDate,
        planbook: this.planbook,
        rotationCalendar: this.rotationCalendar,
        courses: this.coursesWithLessons,
        isOff: true,
        dateString: this.dateString
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "courseCalendarEditor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "planbookEditor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "goBackFromAnnotation", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "goBackFromAnnotation"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "afterAskIfBump", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "afterAskIfBump"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createAnnotation", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "createAnnotation"), _class2.prototype)), _class2)) || _class);
  _exports.default = DayMenuAddNoSchoolDayComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DayMenuAddNoSchoolDayComponent);
});