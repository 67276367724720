define("cc-frontend/components/planner/menus/day-menu/change-end-of-school-year", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.menuName "CONFIRM")}}
    <ToElsewhere
      @named="day-menu-title"
      @send={{component "planner/menus/titles/blank"}}
      @outsideParams={{hash isBlank=true}}
    />
  
    <ToElsewhere
      @named="day-menu-body"
      @send={{component
        "planner/menus/day-menu/change-end-of-school-year/confirm-body"
        confirmButton=this.changeEndOfSchoolYear
        dateString=@dateString
        cancelButton=@goBack
      }}
    />
  {{else if (eq this.menuName "ALERT_TOO_LONG")}}
    <ToElsewhere
      @named="day-menu-title"
      @send={{component "planner/menus/titles/blank"}}
      @outsideParams={{hash isBlank=true}}
    />
  
    <ToElsewhere
      @named="day-menu-body"
      @send={{component
        "planner/menus/day-menu/change-end-of-school-year/alert-too-long-body"
        daysAfterStartDate=this.daysAfterStartDate
        confirmButton=@goBack
      }}
    />
  {{else}}
    <ToElsewhere @named="day-menu-title" @send={{component "ds/three-dot-spinner"}} />
  {{/if}}
  */
  {
    "id": "2gaC+308",
    "block": "[[[41,[28,[37,1],[[30,0,[\"menuName\"]],\"CONFIRM\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@named\",\"@send\",\"@outsideParams\"],[\"day-menu-title\",[50,\"planner/menus/titles/blank\",0,null,null],[28,[37,4],null,[[\"isBlank\"],[true]]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"day-menu-body\",[50,\"planner/menus/day-menu/change-end-of-school-year/confirm-body\",0,null,[[\"confirmButton\",\"dateString\",\"cancelButton\"],[[30,0,[\"changeEndOfSchoolYear\"]],[30,1],[30,2]]]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"menuName\"]],\"ALERT_TOO_LONG\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@named\",\"@send\",\"@outsideParams\"],[\"day-menu-title\",[50,\"planner/menus/titles/blank\",0,null,null],[28,[37,4],null,[[\"isBlank\"],[true]]]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"day-menu-body\",[50,\"planner/menus/day-menu/change-end-of-school-year/alert-too-long-body\",0,null,[[\"daysAfterStartDate\",\"confirmButton\"],[[30,0,[\"daysAfterStartDate\"]],[30,2]]]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@named\",\"@send\"],[\"day-menu-title\",[50,\"ds/three-dot-spinner\",0,null,null]]],null],[1,\"\\n\"]],[]]]],[]]]],[\"@dateString\",\"@goBack\"],false,[\"if\",\"eq\",\"to-elsewhere\",\"component\",\"hash\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/change-end-of-school-year.hbs",
    "isStrictMode": false
  });

  let DayMenuChangeSchooolYearDates = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class DayMenuChangeSchooolYearDates extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "daysAfterStartDate", 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rotationEditor", _descriptor2, this);
    }

    get menuName() {
      let semester = this.args.rotationCalendar.attributes.semesters[0];

      if (dateFns.differenceInDays(this.args.dateString, semester.startDate) > 365) {
        this.daysAfterStartDate = dateFns.differenceInDays(this.args.dateString, semester.startDate);
        return "ALERT_TOO_LONG";
      } else {
        return "CONFIRM";
      }
    }

    changeEndOfSchoolYear() {
      this.args.closeMenu();
      this.rotationEditor.changeSchoolYearDates({
        courses: this.args.courses,
        planbook: this.args.planbook,
        rotationCalendar: this.args.rotationCalendar,
        dateString: this.args.dateString,
        startOrEnd: "END"
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rotationEditor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeEndOfSchoolYear", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeEndOfSchoolYear"), _class.prototype)), _class));
  _exports.default = DayMenuChangeSchooolYearDates;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DayMenuChangeSchooolYearDates);
});