define("cc-frontend/components/planner/menus/add-card/google-classroom-post/body", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-mt-4"></div>
  
  {{! Assignment }}
  <DS::DropdownMenu::SingleButtonRow
    @clickAction={{fn @addCardToBottom "card-google-classroom" "ASSIGNMENT"}}
    data-test-import-lessons
  >
    Assignment
  </DS::DropdownMenu::SingleButtonRow>
  
  {{! Short Answer Question }}
  <DS::DropdownMenu::SingleButtonRow
    @clickAction={{fn @addCardToBottom "card-google-classroom" "SHORT_ANSWER_QUESTION"}}
    data-test-import-lessons
  >
    Short Answer Question
  </DS::DropdownMenu::SingleButtonRow>
  
  {{! Multiple Choice  Answer Question }}
  <DS::DropdownMenu::SingleButtonRow
    @clickAction={{fn @addCardToBottom "card-google-classroom" "MULTIPLE_CHOICE_QUESTION"}}
    data-test-import-lessons
  >
    Multiple Choice Question
  </DS::DropdownMenu::SingleButtonRow>
  
  {{! Material }}
  <DS::DropdownMenu::SingleButtonRow
    @clickAction={{fn @addCardToBottom "card-google-classroom" "MATERIAL"}}
    data-test-import-lessons
  >
    Material
  </DS::DropdownMenu::SingleButtonRow>
  
  {{! Announcement }}
  <DS::DropdownMenu::SingleButtonRow
    @clickAction={{fn @addCardToBottom "card-google-classroom" "ANNOUNCEMENT"}}
    data-test-import-lessons
  >
    Announcement
  </DS::DropdownMenu::SingleButtonRow>
  */
  {
    "id": "FAUoxh+V",
    "block": "[[[10,0],[14,0,\"tw-mt-4\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],[[24,\"data-test-import-lessons\",\"\"]],[[\"@clickAction\"],[[28,[37,1],[[30,1],\"card-google-classroom\",\"ASSIGNMENT\"],null]]],[[\"default\"],[[[[1,\"\\n  Assignment\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],[[24,\"data-test-import-lessons\",\"\"]],[[\"@clickAction\"],[[28,[37,1],[[30,1],\"card-google-classroom\",\"SHORT_ANSWER_QUESTION\"],null]]],[[\"default\"],[[[[1,\"\\n  Short Answer Question\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],[[24,\"data-test-import-lessons\",\"\"]],[[\"@clickAction\"],[[28,[37,1],[[30,1],\"card-google-classroom\",\"MULTIPLE_CHOICE_QUESTION\"],null]]],[[\"default\"],[[[[1,\"\\n  Multiple Choice Question\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],[[24,\"data-test-import-lessons\",\"\"]],[[\"@clickAction\"],[[28,[37,1],[[30,1],\"card-google-classroom\",\"MATERIAL\"],null]]],[[\"default\"],[[[[1,\"\\n  Material\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],[[24,\"data-test-import-lessons\",\"\"]],[[\"@clickAction\"],[[28,[37,1],[[30,1],\"card-google-classroom\",\"ANNOUNCEMENT\"],null]]],[[\"default\"],[[[[1,\"\\n  Announcement\\n\"]],[]]]]]],[\"@addCardToBottom\"],false,[\"d-s/dropdown-menu/single-button-row\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/menus/add-card/google-classroom-post/body.hbs",
    "isStrictMode": false
  });

  class AddGooglePostBody extends _glimmerComponent.default {}

  _exports.default = AddGooglePostBody;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AddGooglePostBody);
});