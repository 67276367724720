define("cc-frontend/components/icons/arrow-minimal-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M5.649,24c-0.143,0 -0.279,-0.061 -0.374,-0.168c-0.183,-0.207 -0.163,-0.524 0.043,-0.706l12.575,-11.126l-12.575,-11.125c-0.207,-0.183 -0.226,-0.5 -0.044,-0.706c0.096,-0.107 0.232,-0.169 0.375,-0.169c0.122,0 0.24,0.045 0.331,0.125l12.576,11.126c0.029,0.026 0.056,0.052 0.081,0.08c0.369,0.416 0.332,1.051 -0.08,1.416l-12.577,11.128c-0.092,0.081 -0.209,0.125 -0.331,0.125Z"
    ></path>
  </svg>
  */
  {
    "id": "WMnSOKDc",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M5.649,24c-0.143,0 -0.279,-0.061 -0.374,-0.168c-0.183,-0.207 -0.163,-0.524 0.043,-0.706l12.575,-11.126l-12.575,-11.125c-0.207,-0.183 -0.226,-0.5 -0.044,-0.706c0.096,-0.107 0.232,-0.169 0.375,-0.169c0.122,0 0.24,0.045 0.331,0.125l12.576,11.126c0.029,0.026 0.056,0.052 0.081,0.08c0.369,0.416 0.332,1.051 -0.08,1.416l-12.577,11.128c-0.092,0.081 -0.209,0.125 -0.331,0.125Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/arrow-minimal-right.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});