define("cc-frontend/components/d-s/dropdown-menu.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "dropdown-menu": "_dropdown-menu_rqqqjs",
    "--arrow": "_--arrow_rqqqjs",
    "--arrow-right": "_--arrow-right_rqqqjs",
    "--arrow-bottom-right": "_--arrow-bottom-right_rqqqjs",
    "--arrow-middle-right": "_--arrow-middle-right_rqqqjs",
    "--arrow-else-right": "_--arrow-else-right_rqqqjs",
    "--arrow-center": "_--arrow-center_rqqqjs",
    "dropdown-menu--columns-DYNAMIC": "_dropdown-menu--columns-DYNAMIC_rqqqjs",
    "dropdown-menu--columns-4": "_dropdown-menu--columns-4_rqqqjs",
    "dropdown-menu--columns-3": "_dropdown-menu--columns-3_rqqqjs",
    "dropdown-menu--columns-2": "_dropdown-menu--columns-2_rqqqjs"
  };
  _exports.default = _default;
});