define("cc-frontend/helpers/abbreviate-weekday", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abbreviateWeekday = abbreviateWeekday;
  _exports.default = void 0;

  function abbreviateWeekday(params
  /*, hash*/
  ) {
    var s = params[0] || "";
    var map = {
      sunday: "Su",
      monday: "M",
      tuesday: "Tu",
      wednesday: "W",
      thursday: "Th",
      friday: "F",
      saturday: "Sa"
    };
    return map[s] || s;
  }

  var _default = Ember.Helper.helper(abbreviateWeekday);

  _exports.default = _default;
});