define("cc-frontend/lib/actions/user/SET_USERS_LAST_PRINT_OPTIONS", ["exports", "cc-frontend/lib/patch-updater", "lodash-es/partial"], function (_exports, _patchUpdater, _partial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareSetUserLastPrintOptions;

  function prepareSetUserLastPrintOptions(payload) {
    return {
      name: "SET_USERS_LAST_PRINT_OPTIONS",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _partial.default)(narrative, payload)
    };
  }

  const patches = function (payload) {
    let patch = _patchUpdater.default.create("user", payload.currentUserId).set(`attributes.printOptions.${payload.perspective}`, payload.settingsToSave).patch;

    return [patch];
  };

  const undoPatches = null;

  const narrative = function (payload, findFn, userId) {
    return {
      title: `Print`,
      titleForAnalytics: "Print",
      description: `Print`,
      context: payload.contextForAnalytics
    };
  };
});