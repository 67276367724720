define("cc-frontend/templates/-common-brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OB18F1ux",
    "block": "[[[8,[39,0],[[24,0,\"btn btn-link btn-clear navbar-brand\"]],[[\"@route\",\"@model\"],[\"user\",[36,1]]],[[\"default\"],[[[[1,\"\\n  \"],[19,\"icons/cc-word-mark-separated\",[]],[1,\"\\n  \"],[10,1],[14,0,\"navbar-brand-text\"],[12],[1,\"\\n    Home\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],true,[\"link-to\",\"currentUser\",\"partial\"]]",
    "moduleName": "cc-frontend/templates/-common-brand.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});