define("cc-frontend/lib/actions/planbook/PLANBOOK_SET_FLAG", ["exports", "cc-frontend/lib/patch-updater"], function (_exports, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_SET_FLAG = void 0;
  const PLANBOOK_SET_FLAG = {
    name: "PLANBOOK_SET_FLAG",
    params: {
      planbookId: "string",
      field: "string",
      value: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).set(`attributes.flags.${payload.field}`, payload.value).patch;

      return [patch];
    }

  };
  _exports.PLANBOOK_SET_FLAG = PLANBOOK_SET_FLAG;
  var _default = PLANBOOK_SET_FLAG;
  _exports.default = _default;
});