define("cc-frontend/components/scribe-editor", ["exports", "@ember-decorators/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{{this._value}}}{{!}}
  */
  {
    "id": "NpZdKHeH",
    "block": "[[[2,[30,0,[\"_value\"]]]],[],false,[]]",
    "moduleName": "cc-frontend/components/scribe-editor.hbs",
    "isStrictMode": false
  });

  let ScribeEditor = (_dec = (0, _component.tagName)("div"), _dec2 = (0, _component.attributeBindings)("contenteditable", "placeholder"), _dec3 = (0, _component.classNameBindings)("isEmpty"), _dec4 = Ember.computed("isEditable"), _dec5 = Ember.computed("value", "isUserTyping"), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class ScribeEditor extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isEditable", true);

      _defineProperty(this, "isUserTyping", false);

      _defineProperty(this, "plaintext", false);

      _defineProperty(this, "toolbarId", "#ckeditor-toolbar");
    }

    // onFinishedEditing: () => {},
    // onValueUpdate: () => {},
    get contenteditable() {
      return this.isEditable ? "true" : false;
    }

    get isEmpty() {
      // if (get(this, "isUserTyping") === true) return false
      let value = (Ember.get(this, "value") || "").replace("<p>", "").replace("</p>", "").replace(" ", "");
      return value === "";
    } // Variables:


    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      Ember.set(this, "_receivedValue", Ember.get(this, "value"));
      if (Ember.get(this, "isUserTyping")) return;

      if (Ember.get(this, "value") !== Ember.get(this, "_value")) {
        // Ember.run.scheduleOnce('afterRender', () => this.get('ckeditor').setData(get(newAttrs, 'value.value') || ""))
        if (Ember.get(this, "ckeditorLoaded")) {
          let data = this.ckeditor.getData();
          let value = Ember.get(this, "_receivedValue");
          if (data === value) return;
          Ember.set(this, "isSettingData", true);
          this.ckeditor.setData(value);
        } else {
          Ember.set(this, "_value", Ember.get(this, "value"));
        }
      }

      if (window.__LOAD_CKEDITOR_ON_INSERT__ === true) {
        Ember.run.later(() => this.loadCkeditor(), 500);
      } // This ensures that we always have the content up to date


      Ember.run.later(() => this.setContent(), 200);
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.set("scribe", null);

      if (this.createdToolbar === true) {
        Ember.$("#" + this.toolbarId).remove();
      }

      if (this.ckeditor) {
        this.ckeditor.removeAllListeners();
      }

      if (this.ckeditor && !this.isDestroyed && !this.isDestroying) {
        try {
          this.ckeditor.destroy();
        } catch (e) {
          console.log("Error removing ckeditor", e);
        }
      }

      Ember.$("#ckeditor-toolbar").addClass("is-hidden");
    }

    click(e) {
      this.loadCkeditor();

      if (e.target.nodeName === "A") {
        try {
          window.open(e.target.href, "_blank");
        } catch (error) {
          console.log(swal);
          swal({
            type: "error",
            title: `The browser can't open this link.`,
            text: `There might be a typo. Need help? Email us at <a style="color:#545454" href="mailto:support@commoncurriculum.com">support@commoncurriculum.com</a><br><a href=${e.target.href} target="_blank">${e.target.href}</a>`
          });
        }
      }

      return true;
    }

    mouseEnter() {
      this.loadCkeditor();
    }

    didInsertElement() {
      super.didInsertElement(...arguments);

      if (this && this.$() && !this.isDestroyed && !this.isDestroying) {
        try {
          this.$().attr("data-gramm", false);
        } catch (e) {
          console.log("Error disabling grammarly");
        }
      }
    }

    focusIn() {
      let text = this.$().text();
      Ember.set(this, "originalValue", Ember.get(this, "value"));
      Ember.set(this, "focusInWordCount", _lodash.default.words(text).length);
      Ember.set(this, "isUserTyping", true); // Ember.run.later(this, this.loadCkeditor, 5)

      this.loadCkeditor();
      Ember.run.later(() => Ember.$("#ckeditor-toolbar").removeClass("is-hidden"), 200);
      return true;
    }

    _onFocusOut() {
      let text = this.$().text();

      let focusOutWordCount = _lodash.default.words(text).length;

      let wordCountChange = focusOutWordCount - Ember.get(this, "focusInWordCount");

      if (Ember.get(this, "value") !== Ember.get(this, "originalValue") && typeof this.onFinishedEditing === "function") {
        this.onFinishedEditing(Ember.get(this, "ckeditor").getData(), Ember.get(this, "originalValue"), wordCountChange);
      }

      this.set("isUserTyping", false);
      Ember.run.later(this, this.setContent, 200);
      Ember.$("#ckeditor-toolbar").addClass("is-hidden");
      return true;
    }

    keyDown(event) {
      if (!event.metaKey) {
        return this.set("isUserTyping", true);
      }
    }

    setContent() {
      if (this.isUserTyping) return;
      if (this.isDestroyed || this.isDestroying) return;

      if (this.ckeditorLoaded === true) {
        if (Ember.get(this, "ckeditor").getData() !== Ember.get(this, "_receivedValue")) {
          this.ckeditor.setData(Ember.get(this, "_receivedValue"));
        }
      } else {
        Ember.set(this, "_value", Ember.get(this, "_receivedValue"));
      }
    }

    loadCkeditor() {
      if (this.ckeditorLoaded === true) return;
      if (this.editable === false) return;
      this.$().ckeditor({
        title: ""
      });
      this.set("ckeditor", this.$().ckeditorGet());
      this.set("ckeditorLoaded", true);
      this.ckeditor.setData(Ember.get(this, "_value"));
      this.ckeditor.on("change", this._onCkeditorChange.bind(this));
      this.ckeditor.on("blur", this._onFocusOut.bind(this));
      this.ckeditor.on("afterSetData", this._onCkeditorAfterSetData.bind(this));
      let client = filestack.init("AcTuk5i2R9OYgN972g8oBz"); // convert all base64 images

      setTimeout(() => {
        if (this.isDestroyed || this.isDestroying || this.element === null) return;
        this.element.querySelectorAll("img").forEach(image => {
          if (image.src.match("data:image") !== null) {
            client.upload(image.src, {}, {
              filename: image.alt || ""
            }).then(upload => {
              image.setAttribute("data-cke-saved-src", upload.url);
              image.setAttribute("src", upload.url);
              this.ckeditor.fire("blur");
            });
          }
        });
      }, 1000);
    }

    _onCkeditorChange() {
      if (this.isSettingData) return;
      let data = this.ckeditor.getData();
      if (this.onValueUpdate) this.onValueUpdate(this.ckeditor.getData());
    }

    _onCkeditorAfterSetData() {
      if (this.isDestroyed || this.isDestroying) return;
      this.set("isSettingData", false);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "contenteditable", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "contenteditable"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isEmpty", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isEmpty"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = ScribeEditor;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ScribeEditor);
});