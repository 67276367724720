define("cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_HIDE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models", "moment"], function (_exports, _patchUpdater, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_DATE_ANNOTATION_HIDE = void 0;
  const COURSE_DATE_ANNOTATION_HIDE = {
    name: "COURSE_DATE_ANNOTATION_HIDE",
    params: {
      annotation: "object",
      course: "object"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dateAnnotations", {
        id: payload.annotation.id
      }).set("embedded", `attributes.showAnnotation`, false).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dateAnnotations", {
        id: payload.annotation.id
      }).set("embedded", `attributes.showAnnotation`, true).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
      let courseTitle = payload.course.attributes.title;
      let date = (0, _moment.default)(payload.annotation.attributes.dateString).format("ddd, MMM. D, YYYY");
      return {
        title: "Chose To Hide A Class Note",
        titleForAnalytics: "Lesson - Hide Annotation",
        description: `Completely hide your ${courseTitle} class on ${date}`,
        context: (0, _defaultCourseDateContext.default)(payload, models),
        activity: (0, _defaultCourseDateActivity.default)(payload, models, "hide")
      };
    }

  };
  _exports.COURSE_DATE_ANNOTATION_HIDE = COURSE_DATE_ANNOTATION_HIDE;
  var _default = COURSE_DATE_ANNOTATION_HIDE;
  _exports.default = _default;
});