define("cc-frontend/components/planner/rotation-calendar/rotation", ["exports", "@glimmer/component", "lodash-es"], function (_exports, _component, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let RotationComponent = (_dec = Ember.computed("args.rotationCalendar.attributes.dates", "args.dateString"), (_class = class RotationComponent extends _component.default {
    get rotation() {
      let day = (0, _lodashEs.find)(this.args.rotationCalendar.attributes.dates, day => {
        return day.type === "rotation-date-custom" && day.attributes.dateString === this.args.dateString;
      });
      let rotationId = day === null || day === void 0 ? void 0 : day.attributes.rotationId;
      return (0, _lodashEs.find)(this.args.rotationCalendar.attributes.rotations, rotation => rotation.id === rotationId);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "rotation", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "rotation"), _class.prototype)), _class));

  var _default = Ember._setComponentTemplate(Ember.HTMLBars.template(
  /*
    
      {{yield this.rotation}}
    
  */
  {
    "id": "9DcvX4kD",
    "block": "[[[1,\"\\n    \"],[18,1,[[30,0,[\"rotation\"]]]],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  }), RotationComponent);

  _exports.default = _default;
});