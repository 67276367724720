define("cc-frontend/components/planner/perspectives/month/date-box.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "date-box-wrapper": "_date-box-wrapper_1if3mh",
    "date-box__header": "_date-box__header_1if3mh",
    "date-box__header__left": "_date-box__header__left_1if3mh",
    "date-box__header__right": "_date-box__header__right_1if3mh",
    "--mouse-is-inside": "_--mouse-is-inside_1if3mh",
    "date-box__header__expand-button": "_date-box__header__expand-button_1if3mh",
    "date-box__header__menu-trigger": "_date-box__header__menu-trigger_1if3mh",
    "date-box__header__date-string": "_date-box__header__date-string_1if3mh",
    "date-box__header__date-string__rotation-day": "_date-box__header__date-string__rotation-day_1if3mh",
    "date-box__body": "_date-box__body_1if3mh"
  };
  _exports.default = _default;
});