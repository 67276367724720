define("cc-frontend/helpers/embedded-has-many", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = embeddedHasMany;

  function embeddedHasMany(modelName, keyName, relationshipType) {
    return Ember.computed(`${modelName}.${keyName}.[]`, function () {
      var ObjectPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      let ids = Ember.get(this, `${modelName}.${keyName}`);
      return ObjectPromiseProxy.create({
        promise: Ember.RSVP.all(_lodash.default.map(ids, id => Ember.get(this, "store").find(relationshipType, id)))
      });
    });
  }
});