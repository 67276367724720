define("cc-frontend/lib/actions/lesson/LESSON_CARD_GC_SCHEDULE_CLASS", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareGCScheduleClass;

  function prepareGCScheduleClass(payload) {
    return {
      name: "LESSON_CARD_GC_SCHEDULE_CLASS",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  const patches = function (payload) {
    let removeOld = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.card.id
    }).push("embedded", "attributes.enabledGoogleClassIds", payload.classSetting.googleClassId).pull("embedded", "attributes.classSettings", {
      googleClassId: payload.classSetting.googleClassId
    }).patch; // // It's good practice to clone objects passed in so we don't accidentally see
    // changes appearing in the UI that aren't being persisted to the cardStack.


    let addNew = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.card.id
    }).push("embedded", "attributes.enabledGoogleClassIds", payload.classSetting.googleClassId).push("embedded", "attributes.classSettings", (0, _lodashEs.cloneDeep)(payload.classSetting)).inc("embedded", "attributes._revision", 1).patch;

    return [removeOld, addNew];
  };

  const narrative = function (payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    return {
      title: "Scheduled a Google Classroom Class",
      titleForAnalytics: "Lesson Card - GC Post - Schedule",
      description: `Scheduled "${payload.className}" to post on "${payload.dateString}".`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models, "created")
    };
  };
});