define("cc-frontend/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rMrNpvIL",
    "block": "[[[10,0],[14,0,\"four-oh-four\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"four-oh-four__title\"],[12],[1,\"\\n    That page looks like it doesn't exist\\n  \"],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"four-oh-four__subtitle\"],[12],[1,\"\\n    Something wrong?\\n    \"],[10,3],[14,\"onclick\",\"window.Intercom('showNewMessage')\"],[12],[1,\"\\n      Click here\\n    \"],[13],[1,\"\\n    to contact support or\\n    \"],[10,3],[14,6,\"/\"],[12],[1,\"\\n      click here\\n    \"],[13],[1,\"\\n    to go to the homepage.\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/templates/not-found.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});