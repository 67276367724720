define("cc-frontend/components/planner/navs/subnav.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sub-nav": "_sub-nav_1ulp7h",
    "sub-nav__item": "_sub-nav__item_1ulp7h"
  };
  _exports.default = _default;
});