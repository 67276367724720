define("cc-frontend/components/d-s/time-zone-select.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "time-select": "_time-select_12wxym",
    "select-time-zone": "_select-time-zone_12wxym",
    "select-time-zone__option": "_select-time-zone__option_12wxym"
  };
  _exports.default = _default;
});