define("cc-frontend/components/d-s/time-zone-select", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <select local-class="select-time-zone" {{on "change" (pick "target.value" this.selectZone)}}>
    {{#each this.regions as |group|}}
      <optgroup label={{group.region}}>
        {{#each group.zones as |zone|}}
          <option value={{zone.value}} selected={{eq zone.value this.selectedZone}}>
            {{zone.name}}
          </option>
        {{/each}}
      </optgroup>
    {{/each}}
  </select>
  */
  {
    "id": "YP0XJfuf",
    "block": "[[[11,\"select\"],[16,0,[29,[[28,[37,0],[\"select-time-zone\"],[[\"from\"],[\"cc-frontend/components/d-s/time-zone-select.css\"]]]]]],[4,[38,1],[\"change\",[28,[37,2],[\"target.value\",[30,0,[\"selectZone\"]]],null]],null],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"regions\"]]],null]],null],null,[[[1,\"    \"],[10,\"optgroup\"],[15,\"label\",[30,1,[\"region\"]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"zones\"]]],null]],null],null,[[[1,\"        \"],[10,\"option\"],[15,2,[30,2,[\"value\"]]],[15,\"selected\",[28,[37,5],[[30,2,[\"value\"]],[30,0,[\"selectedZone\"]]],null]],[12],[1,\"\\n          \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"group\",\"zone\"],false,[\"local-class\",\"on\",\"pick\",\"each\",\"-track-array\",\"eq\"]]",
    "moduleName": "cc-frontend/components/d-s/time-zone-select.hbs",
    "isStrictMode": false
  });

  const regions = [{
    region: "United States - Common",
    zones: [{
      name: "New York",
      value: "America/New_York"
    }, {
      name: "Chicago",
      value: "America/Chicago"
    }, {
      name: "Denver",
      value: "America/Denver"
    }, {
      name: "Los Angeles",
      value: "America/Los_Angeles"
    }, {
      name: "Anchorage",
      value: "America/Anchorage"
    }, {
      name: "Honolulu, Pacific",
      value: "Pacific/Honolulu"
    }]
  }, {
    region: "United States",
    zones: [{
      name: "Adak",
      value: "America/Adak"
    }, {
      name: "Boise",
      value: "America/Boise"
    }, {
      name: "Detroit",
      value: "America/Detroit"
    }, {
      name: "Indianapolis, Indiana",
      value: "America/Indiana/Indianapolis"
    }, {
      name: "Knox, Indiana",
      value: "America/Indiana/Knox"
    }, {
      name: "Marengo, Indiana",
      value: "America/Indiana/Marengo"
    }, {
      name: "Petersburg, Indiana",
      value: "America/Indiana/Petersburg"
    }, {
      name: "Tell City, Indiana",
      value: "America/Indiana/Tell_City"
    }, {
      name: "Vevay, Indiana",
      value: "America/Indiana/Vevay"
    }, {
      name: "Vincennes, Indiana",
      value: "America/Indiana/Vincennes"
    }, {
      name: "Winamac, Indiana",
      value: "America/Indiana/Winamac"
    }, {
      name: "Juneau",
      value: "America/Juneau"
    }, {
      name: "Louisville, Kentucky",
      value: "America/Kentucky/Louisville"
    }, {
      name: "Monticello, Kentucky",
      value: "America/Kentucky/Monticello"
    }, {
      name: "Menominee",
      value: "America/Menominee"
    }, {
      name: "Metlakatla",
      value: "America/Metlakatla"
    }, {
      name: "Nome",
      value: "America/Nome"
    }, {
      name: "Beulah, North Dakota",
      value: "America/North_Dakota/Beulah"
    }, {
      name: "Center, North Dakota",
      value: "America/North_Dakota/Center"
    }, {
      name: "New Salem, North Dakota",
      value: "America/North_Dakota/New_Salem"
    }, {
      name: "Phoenix",
      value: "America/Phoenix"
    }, {
      name: "Sitka",
      value: "America/Sitka"
    }, {
      name: "Yakutat",
      value: "America/Yakutat"
    }]
  }, {
    region: "Africa",
    zones: [{
      name: "Algeria",
      value: "Africa/Algiers"
    }, {
      name: "Angola",
      value: "Africa/Lagos"
    }, {
      name: "Benin",
      value: "Africa/Lagos"
    }, {
      name: "Botswana",
      value: "Africa/Maputo"
    }, {
      name: "Burkina Faso",
      value: "Africa/Abidjan"
    }, {
      name: "Burundi",
      value: "Africa/Maputo"
    }, {
      name: "Cameroon",
      value: "Africa/Lagos"
    }, {
      name: "Central African Rep.",
      value: "Africa/Lagos"
    }, {
      name: "Chad",
      value: "Africa/Ndjamena"
    }, {
      name: "Comoros",
      value: "Africa/Nairobi"
    }, {
      name: "Congo (Dem. Rep.): Lagos",
      value: "Africa/Lagos"
    }, {
      name: "Congo (Dem. Rep.): Maputo",
      value: "Africa/Maputo"
    }, {
      name: "Congo (Rep.)",
      value: "Africa/Lagos"
    }, {
      name: "Côte d'Ivoire",
      value: "Africa/Abidjan"
    }, {
      name: "Djibouti",
      value: "Africa/Nairobi"
    }, {
      name: "Egypt",
      value: "Africa/Cairo"
    }, {
      name: "Equatorial Guinea",
      value: "Africa/Lagos"
    }, {
      name: "Eritrea",
      value: "Africa/Nairobi"
    }, {
      name: "Eswatini (Swaziland)",
      value: "Africa/Johannesburg"
    }, {
      name: "Ethiopia",
      value: "Africa/Nairobi"
    }, {
      name: "Gabon",
      value: "Africa/Lagos"
    }, {
      name: "Gambia",
      value: "Africa/Abidjan"
    }, {
      name: "Ghana",
      value: "Africa/Accra"
    }, {
      name: "Guinea",
      value: "Africa/Abidjan"
    }, {
      name: "Guinea-Bissau",
      value: "Africa/Bissau"
    }, {
      name: "Kenya",
      value: "Africa/Nairobi"
    }, {
      name: "Lesotho",
      value: "Africa/Johannesburg"
    }, {
      name: "Liberia",
      value: "Africa/Monrovia"
    }, {
      name: "Libya",
      value: "Africa/Tripoli"
    }, {
      name: "Madagascar",
      value: "Africa/Nairobi"
    }, {
      name: "Malawi",
      value: "Africa/Maputo"
    }, {
      name: "Mali",
      value: "Africa/Abidjan"
    }, {
      name: "Mauritania",
      value: "Africa/Abidjan"
    }, {
      name: "Mayotte",
      value: "Africa/Nairobi"
    }, {
      name: "Morocco",
      value: "Africa/Casablanca"
    }, {
      name: "Mozambique",
      value: "Africa/Maputo"
    }, {
      name: "Namibia",
      value: "Africa/Windhoek"
    }, {
      name: "Niger",
      value: "Africa/Lagos"
    }, {
      name: "Nigeria",
      value: "Africa/Lagos"
    }, {
      name: "Rwanda",
      value: "Africa/Maputo"
    }, {
      name: "Sao Tome & Principe",
      value: "Africa/Sao_Tome"
    }, {
      name: "Senegal",
      value: "Africa/Abidjan"
    }, {
      name: "Sierra Leone",
      value: "Africa/Abidjan"
    }, {
      name: "Somalia",
      value: "Africa/Nairobi"
    }, {
      name: "South Africa",
      value: "Africa/Johannesburg"
    }, {
      name: "South Sudan",
      value: "Africa/Juba"
    }, {
      name: "Spain",
      value: "Africa/Ceuta"
    }, {
      name: "St Helena",
      value: "Africa/Abidjan"
    }, {
      name: "Sudan",
      value: "Africa/Khartoum"
    }, {
      name: "Tanzania",
      value: "Africa/Nairobi"
    }, {
      name: "Togo",
      value: "Africa/Abidjan"
    }, {
      name: "Tunisia",
      value: "Africa/Tunis"
    }, {
      name: "Uganda",
      value: "Africa/Nairobi"
    }, {
      name: "Western Sahara",
      value: "Africa/El_Aaiun"
    }, {
      name: "Zambia",
      value: "Africa/Maputo"
    }, {
      name: "Zimbabwe",
      value: "Africa/Maputo"
    }]
  }, {
    region: "America",
    zones: [{
      name: "Anguilla",
      value: "America/Port_of_Spain"
    }, {
      name: "Antigua & Barbuda",
      value: "America/Port_of_Spain"
    }, {
      name: "Argentina: Buenos Aires, Argentina",
      value: "America/Argentina/Buenos_Aires"
    }, {
      name: "Argentina: Catamarca, Argentina",
      value: "America/Argentina/Catamarca"
    }, {
      name: "Argentina: Cordoba, Argentina",
      value: "America/Argentina/Cordoba"
    }, {
      name: "Argentina: Jujuy, Argentina",
      value: "America/Argentina/Jujuy"
    }, {
      name: "Argentina: La Rioja, Argentina",
      value: "America/Argentina/La_Rioja"
    }, {
      name: "Argentina: Mendoza, Argentina",
      value: "America/Argentina/Mendoza"
    }, {
      name: "Argentina: Rio Gallegos, Argentina",
      value: "America/Argentina/Rio_Gallegos"
    }, {
      name: "Argentina: Salta, Argentina",
      value: "America/Argentina/Salta"
    }, {
      name: "Argentina: San Juan, Argentina",
      value: "America/Argentina/San_Juan"
    }, {
      name: "Argentina: San Luis, Argentina",
      value: "America/Argentina/San_Luis"
    }, {
      name: "Argentina: Tucuman, Argentina",
      value: "America/Argentina/Tucuman"
    }, {
      name: "Argentina: Ushuaia, Argentina",
      value: "America/Argentina/Ushuaia"
    }, {
      name: "Aruba",
      value: "America/Curacao"
    }, {
      name: "Bahamas",
      value: "America/Nassau"
    }, {
      name: "Barbados",
      value: "America/Barbados"
    }, {
      name: "Belize",
      value: "America/Belize"
    }, {
      name: "Bolivia",
      value: "America/La_Paz"
    }, {
      name: "Brazil: Araguaina",
      value: "America/Araguaina"
    }, {
      name: "Brazil: Bahia",
      value: "America/Bahia"
    }, {
      name: "Brazil: Belem",
      value: "America/Belem"
    }, {
      name: "Brazil: Boa Vista",
      value: "America/Boa_Vista"
    }, {
      name: "Brazil: Campo Grande",
      value: "America/Campo_Grande"
    }, {
      name: "Brazil: Cuiaba",
      value: "America/Cuiaba"
    }, {
      name: "Brazil: Eirunepe",
      value: "America/Eirunepe"
    }, {
      name: "Brazil: Fortaleza",
      value: "America/Fortaleza"
    }, {
      name: "Brazil: Maceio",
      value: "America/Maceio"
    }, {
      name: "Brazil: Manaus",
      value: "America/Manaus"
    }, {
      name: "Brazil: Noronha",
      value: "America/Noronha"
    }, {
      name: "Brazil: Porto Velho",
      value: "America/Porto_Velho"
    }, {
      name: "Brazil: Recife",
      value: "America/Recife"
    }, {
      name: "Brazil: Rio Branco",
      value: "America/Rio_Branco"
    }, {
      name: "Brazil: Santarem",
      value: "America/Santarem"
    }, {
      name: "Brazil: Sao Paulo",
      value: "America/Sao_Paulo"
    }, {
      name: "Canada: Atikokan",
      value: "America/Atikokan"
    }, {
      name: "Canada: Blanc-Sablon",
      value: "America/Blanc-Sablon"
    }, {
      name: "Canada: Cambridge Bay",
      value: "America/Cambridge_Bay"
    }, {
      name: "Canada: Creston",
      value: "America/Creston"
    }, {
      name: "Canada: Dawson",
      value: "America/Dawson"
    }, {
      name: "Canada: Dawson Creek",
      value: "America/Dawson_Creek"
    }, {
      name: "Canada: Edmonton",
      value: "America/Edmonton"
    }, {
      name: "Canada: Fort Nelson",
      value: "America/Fort_Nelson"
    }, {
      name: "Canada: Glace Bay",
      value: "America/Glace_Bay"
    }, {
      name: "Canada: Goose Bay",
      value: "America/Goose_Bay"
    }, {
      name: "Canada: Halifax",
      value: "America/Halifax"
    }, {
      name: "Canada: Inuvik",
      value: "America/Inuvik"
    }, {
      name: "Canada: Iqaluit",
      value: "America/Iqaluit"
    }, {
      name: "Canada: Moncton",
      value: "America/Moncton"
    }, {
      name: "Canada: Nipigon",
      value: "America/Nipigon"
    }, {
      name: "Canada: Pangnirtung",
      value: "America/Pangnirtung"
    }, {
      name: "Canada: Rainy River",
      value: "America/Rainy_River"
    }, {
      name: "Canada: Rankin Inlet",
      value: "America/Rankin_Inlet"
    }, {
      name: "Canada: Regina",
      value: "America/Regina"
    }, {
      name: "Canada: Resolute",
      value: "America/Resolute"
    }, {
      name: "Canada: St Johns",
      value: "America/St_Johns"
    }, {
      name: "Canada: Swift Current",
      value: "America/Swift_Current"
    }, {
      name: "Canada: Thunder Bay",
      value: "America/Thunder_Bay"
    }, {
      name: "Canada: Toronto",
      value: "America/Toronto"
    }, {
      name: "Canada: Vancouver",
      value: "America/Vancouver"
    }, {
      name: "Canada: Whitehorse",
      value: "America/Whitehorse"
    }, {
      name: "Canada: Winnipeg",
      value: "America/Winnipeg"
    }, {
      name: "Canada: Yellowknife",
      value: "America/Yellowknife"
    }, {
      name: "Caribbean NL",
      value: "America/Curacao"
    }, {
      name: "Cayman Islands",
      value: "America/Panama"
    }, {
      name: "Chile: Punta Arenas",
      value: "America/Punta_Arenas"
    }, {
      name: "Chile: Santiago",
      value: "America/Santiago"
    }, {
      name: "Colombia",
      value: "America/Bogota"
    }, {
      name: "Costa Rica",
      value: "America/Costa_Rica"
    }, {
      name: "Cuba",
      value: "America/Havana"
    }, {
      name: "Curaçao",
      value: "America/Curacao"
    }, {
      name: "Dominica",
      value: "America/Port_of_Spain"
    }, {
      name: "Dominican Republic",
      value: "America/Santo_Domingo"
    }, {
      name: "Ecuador",
      value: "America/Guayaquil"
    }, {
      name: "El Salvador",
      value: "America/El_Salvador"
    }, {
      name: "French Guiana",
      value: "America/Cayenne"
    }, {
      name: "Greenland: Danmarkshavn",
      value: "America/Danmarkshavn"
    }, {
      name: "Greenland: Nuuk",
      value: "America/Nuuk"
    }, {
      name: "Greenland: Scoresbysund",
      value: "America/Scoresbysund"
    }, {
      name: "Greenland: Thule",
      value: "America/Thule"
    }, {
      name: "Grenada",
      value: "America/Port_of_Spain"
    }, {
      name: "Guadeloupe",
      value: "America/Port_of_Spain"
    }, {
      name: "Guatemala",
      value: "America/Guatemala"
    }, {
      name: "Guyana",
      value: "America/Guyana"
    }, {
      name: "Haiti",
      value: "America/Port-au-Prince"
    }, {
      name: "Honduras",
      value: "America/Tegucigalpa"
    }, {
      name: "Jamaica",
      value: "America/Jamaica"
    }, {
      name: "Martinique",
      value: "America/Martinique"
    }, {
      name: "Mexico: Bahia Banderas",
      value: "America/Bahia_Banderas"
    }, {
      name: "Mexico: Cancun",
      value: "America/Cancun"
    }, {
      name: "Mexico: Chihuahua",
      value: "America/Chihuahua"
    }, {
      name: "Mexico: Hermosillo",
      value: "America/Hermosillo"
    }, {
      name: "Mexico: Matamoros",
      value: "America/Matamoros"
    }, {
      name: "Mexico: Mazatlan",
      value: "America/Mazatlan"
    }, {
      name: "Mexico: Merida",
      value: "America/Merida"
    }, {
      name: "Mexico: Mexico City",
      value: "America/Mexico_City"
    }, {
      name: "Mexico: Monterrey",
      value: "America/Monterrey"
    }, {
      name: "Mexico: Ojinaga",
      value: "America/Ojinaga"
    }, {
      name: "Mexico: Tijuana",
      value: "America/Tijuana"
    }, {
      name: "Montserrat",
      value: "America/Port_of_Spain"
    }, {
      name: "Nicaragua",
      value: "America/Managua"
    }, {
      name: "Panama",
      value: "America/Panama"
    }, {
      name: "Paraguay",
      value: "America/Asuncion"
    }, {
      name: "Peru",
      value: "America/Lima"
    }, {
      name: "Puerto Rico",
      value: "America/Puerto_Rico"
    }, {
      name: "St Barthelemy",
      value: "America/Port_of_Spain"
    }, {
      name: "St Kitts & Nevis",
      value: "America/Port_of_Spain"
    }, {
      name: "St Lucia",
      value: "America/Port_of_Spain"
    }, {
      name: "St Maarten (Dutch)",
      value: "America/Curacao"
    }, {
      name: "St Martin (French)",
      value: "America/Port_of_Spain"
    }, {
      name: "St Pierre & Miquelon",
      value: "America/Miquelon"
    }, {
      name: "St Vincent",
      value: "America/Port_of_Spain"
    }, {
      name: "Suriname",
      value: "America/Paramaribo"
    }, {
      name: "Trinidad & Tobago",
      value: "America/Port_of_Spain"
    }, {
      name: "Turks & Caicos Is",
      value: "America/Grand_Turk"
    }, {
      name: "Uruguay",
      value: "America/Montevideo"
    }, {
      name: "Venezuela",
      value: "America/Caracas"
    }, {
      name: "Virgin Islands (UK)",
      value: "America/Port_of_Spain"
    }, {
      name: "Virgin Islands (US)",
      value: "America/Port_of_Spain"
    }]
  }, {
    region: "Antarctica",
    zones: [{
      name: "Casey",
      value: "Antarctica/Casey"
    }, {
      name: "Davis",
      value: "Antarctica/Davis"
    }, {
      name: "DumontDUrville",
      value: "Antarctica/DumontDUrville"
    }, {
      name: "Mawson",
      value: "Antarctica/Mawson"
    }, {
      name: "Palmer",
      value: "Antarctica/Palmer"
    }, {
      name: "Rothera",
      value: "Antarctica/Rothera"
    }, {
      name: "Syowa",
      value: "Antarctica/Syowa"
    }, {
      name: "Troll",
      value: "Antarctica/Troll"
    }, {
      name: "Vostok",
      value: "Antarctica/Vostok"
    }, {
      name: "Australia",
      value: "Antarctica/Macquarie"
    }]
  }, {
    region: "Asia",
    zones: [{
      name: "Afghanistan",
      value: "Asia/Kabul"
    }, {
      name: "Armenia",
      value: "Asia/Yerevan"
    }, {
      name: "Azerbaijan",
      value: "Asia/Baku"
    }, {
      name: "Bahrain",
      value: "Asia/Qatar"
    }, {
      name: "Bangladesh",
      value: "Asia/Dhaka"
    }, {
      name: "Bhutan",
      value: "Asia/Thimphu"
    }, {
      name: "Brunei",
      value: "Asia/Brunei"
    }, {
      name: "Cambodia",
      value: "Asia/Bangkok"
    }, {
      name: "China: Shanghai",
      value: "Asia/Shanghai"
    }, {
      name: "China: Urumqi",
      value: "Asia/Urumqi"
    }, {
      name: "Cyprus: Famagusta",
      value: "Asia/Famagusta"
    }, {
      name: "Cyprus: Nicosia",
      value: "Asia/Nicosia"
    }, {
      name: "East Timor",
      value: "Asia/Dili"
    }, {
      name: "Georgia",
      value: "Asia/Tbilisi"
    }, {
      name: "Hong Kong",
      value: "Asia/Hong_Kong"
    }, {
      name: "India",
      value: "Asia/Kolkata"
    }, {
      name: "Indonesia: Jakarta",
      value: "Asia/Jakarta"
    }, {
      name: "Indonesia: Jayapura",
      value: "Asia/Jayapura"
    }, {
      name: "Indonesia: Makassar",
      value: "Asia/Makassar"
    }, {
      name: "Indonesia: Pontianak",
      value: "Asia/Pontianak"
    }, {
      name: "Iran",
      value: "Asia/Tehran"
    }, {
      name: "Iraq",
      value: "Asia/Baghdad"
    }, {
      name: "Israel",
      value: "Asia/Jerusalem"
    }, {
      name: "Japan",
      value: "Asia/Tokyo"
    }, {
      name: "Jordan",
      value: "Asia/Amman"
    }, {
      name: "Kazakhstan: Almaty",
      value: "Asia/Almaty"
    }, {
      name: "Kazakhstan: Aqtau",
      value: "Asia/Aqtau"
    }, {
      name: "Kazakhstan: Aqtobe",
      value: "Asia/Aqtobe"
    }, {
      name: "Kazakhstan: Atyrau",
      value: "Asia/Atyrau"
    }, {
      name: "Kazakhstan: Oral",
      value: "Asia/Oral"
    }, {
      name: "Kazakhstan: Qostanay",
      value: "Asia/Qostanay"
    }, {
      name: "Kazakhstan: Qyzylorda",
      value: "Asia/Qyzylorda"
    }, {
      name: "Korea (North)",
      value: "Asia/Pyongyang"
    }, {
      name: "Korea (South)",
      value: "Asia/Seoul"
    }, {
      name: "Kuwait",
      value: "Asia/Riyadh"
    }, {
      name: "Kyrgyzstan",
      value: "Asia/Bishkek"
    }, {
      name: "Laos",
      value: "Asia/Bangkok"
    }, {
      name: "Lebanon",
      value: "Asia/Beirut"
    }, {
      name: "Macau",
      value: "Asia/Macau"
    }, {
      name: "Malaysia: Kuala Lumpur",
      value: "Asia/Kuala_Lumpur"
    }, {
      name: "Malaysia: Kuching",
      value: "Asia/Kuching"
    }, {
      name: "Mongolia: Choibalsan",
      value: "Asia/Choibalsan"
    }, {
      name: "Mongolia: Hovd",
      value: "Asia/Hovd"
    }, {
      name: "Mongolia: Ulaanbaatar",
      value: "Asia/Ulaanbaatar"
    }, {
      name: "Myanmar (Burma)",
      value: "Asia/Yangon"
    }, {
      name: "Nepal",
      value: "Asia/Kathmandu"
    }, {
      name: "Oman",
      value: "Asia/Dubai"
    }, {
      name: "Pakistan",
      value: "Asia/Karachi"
    }, {
      name: "Palestine: Gaza",
      value: "Asia/Gaza"
    }, {
      name: "Palestine: Hebron",
      value: "Asia/Hebron"
    }, {
      name: "Philippines",
      value: "Asia/Manila"
    }, {
      name: "Qatar",
      value: "Asia/Qatar"
    }, {
      name: "Russia: Anadyr",
      value: "Asia/Anadyr"
    }, {
      name: "Russia: Barnaul",
      value: "Asia/Barnaul"
    }, {
      name: "Russia: Chita",
      value: "Asia/Chita"
    }, {
      name: "Russia: Irkutsk",
      value: "Asia/Irkutsk"
    }, {
      name: "Russia: Kamchatka",
      value: "Asia/Kamchatka"
    }, {
      name: "Russia: Khandyga",
      value: "Asia/Khandyga"
    }, {
      name: "Russia: Krasnoyarsk",
      value: "Asia/Krasnoyarsk"
    }, {
      name: "Russia: Magadan",
      value: "Asia/Magadan"
    }, {
      name: "Russia: Novokuznetsk",
      value: "Asia/Novokuznetsk"
    }, {
      name: "Russia: Novosibirsk",
      value: "Asia/Novosibirsk"
    }, {
      name: "Russia: Omsk",
      value: "Asia/Omsk"
    }, {
      name: "Russia: Sakhalin",
      value: "Asia/Sakhalin"
    }, {
      name: "Russia: Srednekolymsk",
      value: "Asia/Srednekolymsk"
    }, {
      name: "Russia: Tomsk",
      value: "Asia/Tomsk"
    }, {
      name: "Russia: Ust-Nera",
      value: "Asia/Ust-Nera"
    }, {
      name: "Russia: Vladivostok",
      value: "Asia/Vladivostok"
    }, {
      name: "Russia: Yakutsk",
      value: "Asia/Yakutsk"
    }, {
      name: "Russia: Yekaterinburg",
      value: "Asia/Yekaterinburg"
    }, {
      name: "Saudi Arabia",
      value: "Asia/Riyadh"
    }, {
      name: "Singapore",
      value: "Asia/Singapore"
    }, {
      name: "Sri Lanka",
      value: "Asia/Colombo"
    }, {
      name: "Syria",
      value: "Asia/Damascus"
    }, {
      name: "Taiwan",
      value: "Asia/Taipei"
    }, {
      name: "Tajikistan",
      value: "Asia/Dushanbe"
    }, {
      name: "Thailand",
      value: "Asia/Bangkok"
    }, {
      name: "Turkmenistan",
      value: "Asia/Ashgabat"
    }, {
      name: "United Arab Emirates",
      value: "Asia/Dubai"
    }, {
      name: "Uzbekistan: Samarkand",
      value: "Asia/Samarkand"
    }, {
      name: "Uzbekistan: Tashkent",
      value: "Asia/Tashkent"
    }, {
      name: "Vietnam: Bangkok",
      value: "Asia/Bangkok"
    }, {
      name: "Vietnam: Ho Chi Minh",
      value: "Asia/Ho_Chi_Minh"
    }, {
      name: "Yemen",
      value: "Asia/Riyadh"
    }]
  }, {
    region: "Atlantic",
    zones: [{
      name: "Bermuda",
      value: "Atlantic/Bermuda"
    }, {
      name: "Cape Verde",
      value: "Atlantic/Cape_Verde"
    }, {
      name: "Falkland Islands",
      value: "Atlantic/Stanley"
    }, {
      name: "Faroe Islands",
      value: "Atlantic/Faroe"
    }, {
      name: "Iceland",
      value: "Atlantic/Reykjavik"
    }, {
      name: "Portugal: Azores",
      value: "Atlantic/Azores"
    }, {
      name: "Portugal: Madeira",
      value: "Atlantic/Madeira"
    }, {
      name: "South Georgia & the South Sandwich Islands",
      value: "Atlantic/South_Georgia"
    }, {
      name: "Spain",
      value: "Atlantic/Canary"
    }]
  }, {
    region: "Australia",
    zones: [{
      name: "Adelaide",
      value: "Australia/Adelaide"
    }, {
      name: "Brisbane",
      value: "Australia/Brisbane"
    }, {
      name: "Broken Hill",
      value: "Australia/Broken_Hill"
    }, {
      name: "Darwin",
      value: "Australia/Darwin"
    }, {
      name: "Eucla",
      value: "Australia/Eucla"
    }, {
      name: "Hobart",
      value: "Australia/Hobart"
    }, {
      name: "Lindeman",
      value: "Australia/Lindeman"
    }, {
      name: "Lord Howe",
      value: "Australia/Lord_Howe"
    }, {
      name: "Melbourne",
      value: "Australia/Melbourne"
    }, {
      name: "Perth",
      value: "Australia/Perth"
    }, {
      name: "Sydney",
      value: "Australia/Sydney"
    }]
  }, {
    region: "Europe",
    zones: [{
      name: "Albania",
      value: "Europe/Tirane"
    }, {
      name: "Andorra",
      value: "Europe/Andorra"
    }, {
      name: "Austria",
      value: "Europe/Vienna"
    }, {
      name: "Belarus",
      value: "Europe/Minsk"
    }, {
      name: "Belgium",
      value: "Europe/Brussels"
    }, {
      name: "Bosnia & Herzegovina",
      value: "Europe/Belgrade"
    }, {
      name: "Britain (UK)",
      value: "Europe/London"
    }, {
      name: "Bulgaria",
      value: "Europe/Sofia"
    }, {
      name: "Croatia",
      value: "Europe/Belgrade"
    }, {
      name: "Czech Republic",
      value: "Europe/Prague"
    }, {
      name: "Denmark",
      value: "Europe/Copenhagen"
    }, {
      name: "Estonia",
      value: "Europe/Tallinn"
    }, {
      name: "Finland",
      value: "Europe/Helsinki"
    }, {
      name: "France",
      value: "Europe/Paris"
    }, {
      name: "Germany: Berlin",
      value: "Europe/Berlin"
    }, {
      name: "Germany: Zurich",
      value: "Europe/Zurich"
    }, {
      name: "Gibraltar",
      value: "Europe/Gibraltar"
    }, {
      name: "Greece",
      value: "Europe/Athens"
    }, {
      name: "Guernsey",
      value: "Europe/London"
    }, {
      name: "Hungary",
      value: "Europe/Budapest"
    }, {
      name: "Ireland",
      value: "Europe/Dublin"
    }, {
      name: "Isle of Man",
      value: "Europe/London"
    }, {
      name: "Italy",
      value: "Europe/Rome"
    }, {
      name: "Jersey",
      value: "Europe/London"
    }, {
      name: "Latvia",
      value: "Europe/Riga"
    }, {
      name: "Liechtenstein",
      value: "Europe/Zurich"
    }, {
      name: "Lithuania",
      value: "Europe/Vilnius"
    }, {
      name: "Luxembourg",
      value: "Europe/Luxembourg"
    }, {
      name: "Malta",
      value: "Europe/Malta"
    }, {
      name: "Moldova",
      value: "Europe/Chisinau"
    }, {
      name: "Monaco",
      value: "Europe/Monaco"
    }, {
      name: "Montenegro",
      value: "Europe/Belgrade"
    }, {
      name: "Netherlands",
      value: "Europe/Amsterdam"
    }, {
      name: "North Macedonia",
      value: "Europe/Belgrade"
    }, {
      name: "Norway",
      value: "Europe/Oslo"
    }, {
      name: "Poland",
      value: "Europe/Warsaw"
    }, {
      name: "Portugal",
      value: "Europe/Lisbon"
    }, {
      name: "Romania",
      value: "Europe/Bucharest"
    }, {
      name: "Russia: Astrakhan",
      value: "Europe/Astrakhan"
    }, {
      name: "Russia: Kaliningrad",
      value: "Europe/Kaliningrad"
    }, {
      name: "Russia: Kirov",
      value: "Europe/Kirov"
    }, {
      name: "Russia: Moscow",
      value: "Europe/Moscow"
    }, {
      name: "Russia: Samara",
      value: "Europe/Samara"
    }, {
      name: "Russia: Saratov",
      value: "Europe/Saratov"
    }, {
      name: "Russia: Simferopol",
      value: "Europe/Simferopol"
    }, {
      name: "Russia: Ulyanovsk",
      value: "Europe/Ulyanovsk"
    }, {
      name: "Russia: Volgograd",
      value: "Europe/Volgograd"
    }, {
      name: "San Marino",
      value: "Europe/Rome"
    }, {
      name: "Serbia",
      value: "Europe/Belgrade"
    }, {
      name: "Slovakia",
      value: "Europe/Prague"
    }, {
      name: "Slovenia",
      value: "Europe/Belgrade"
    }, {
      name: "Spain",
      value: "Europe/Madrid"
    }, {
      name: "Svalbard & Jan Mayen",
      value: "Europe/Oslo"
    }, {
      name: "Sweden",
      value: "Europe/Stockholm"
    }, {
      name: "Switzerland",
      value: "Europe/Zurich"
    }, {
      name: "Turkey",
      value: "Europe/Istanbul"
    }, {
      name: "Ukraine: Kiev",
      value: "Europe/Kiev"
    }, {
      name: "Ukraine: Simferopol",
      value: "Europe/Simferopol"
    }, {
      name: "Ukraine: Uzhgorod",
      value: "Europe/Uzhgorod"
    }, {
      name: "Ukraine: Zaporozhye",
      value: "Europe/Zaporozhye"
    }, {
      name: "Vatican City",
      value: "Europe/Rome"
    }, {
      name: "Åland Islands",
      value: "Europe/Helsinki"
    }]
  }, {
    region: "Indian",
    zones: [{
      name: "British Indian Ocean Territory",
      value: "Indian/Chagos"
    }, {
      name: "Christmas Island",
      value: "Indian/Christmas"
    }, {
      name: "Cocos (Keeling) Islands",
      value: "Indian/Cocos"
    }, {
      name: "French Southern & Antarctic Lands: Kerguelen",
      value: "Indian/Kerguelen"
    }, {
      name: "French Southern & Antarctic Lands: Reunion",
      value: "Indian/Reunion"
    }, {
      name: "Maldives",
      value: "Indian/Maldives"
    }, {
      name: "Mauritius",
      value: "Indian/Mauritius"
    }, {
      name: "Réunion",
      value: "Indian/Reunion"
    }, {
      name: "Seychelles",
      value: "Indian/Mahe"
    }]
  }, {
    region: "Pacific",
    zones: [{
      name: "Antarctica",
      value: "Pacific/Auckland"
    }, {
      name: "Chile",
      value: "Pacific/Easter"
    }, {
      name: "Cook Islands",
      value: "Pacific/Rarotonga"
    }, {
      name: "Ecuador",
      value: "Pacific/Galapagos"
    }, {
      name: "Fiji",
      value: "Pacific/Fiji"
    }, {
      name: "French Polynesia: Gambier",
      value: "Pacific/Gambier"
    }, {
      name: "French Polynesia: Marquesas",
      value: "Pacific/Marquesas"
    }, {
      name: "French Polynesia: Tahiti",
      value: "Pacific/Tahiti"
    }, {
      name: "Guam",
      value: "Pacific/Guam"
    }, {
      name: "Kiribati: Enderbury",
      value: "Pacific/Enderbury"
    }, {
      name: "Kiribati: Kiritimati",
      value: "Pacific/Kiritimati"
    }, {
      name: "Kiribati: Tarawa",
      value: "Pacific/Tarawa"
    }, {
      name: "Marshall Islands: Kwajalein",
      value: "Pacific/Kwajalein"
    }, {
      name: "Marshall Islands: Majuro",
      value: "Pacific/Majuro"
    }, {
      name: "Micronesia: Chuuk",
      value: "Pacific/Chuuk"
    }, {
      name: "Micronesia: Kosrae",
      value: "Pacific/Kosrae"
    }, {
      name: "Micronesia: Pohnpei",
      value: "Pacific/Pohnpei"
    }, {
      name: "Nauru",
      value: "Pacific/Nauru"
    }, {
      name: "New Caledonia",
      value: "Pacific/Noumea"
    }, {
      name: "New Zealand: Auckland",
      value: "Pacific/Auckland"
    }, {
      name: "New Zealand: Chatham",
      value: "Pacific/Chatham"
    }, {
      name: "Niue",
      value: "Pacific/Niue"
    }, {
      name: "Norfolk Island",
      value: "Pacific/Norfolk"
    }, {
      name: "Northern Mariana Islands",
      value: "Pacific/Guam"
    }, {
      name: "Palau",
      value: "Pacific/Palau"
    }, {
      name: "Papua New Guinea: Bougainville",
      value: "Pacific/Bougainville"
    }, {
      name: "Papua New Guinea: Port Moresby",
      value: "Pacific/Port_Moresby"
    }, {
      name: "Pitcairn",
      value: "Pacific/Pitcairn"
    }, {
      name: "Samoa (American)",
      value: "Pacific/Pago_Pago"
    }, {
      name: "Samoa (western)",
      value: "Pacific/Apia"
    }, {
      name: "Solomon Islands",
      value: "Pacific/Guadalcanal"
    }, {
      name: "Tokelau",
      value: "Pacific/Fakaofo"
    }, {
      name: "Tonga",
      value: "Pacific/Tongatapu"
    }, {
      name: "Tuvalu",
      value: "Pacific/Funafuti"
    }, {
      name: "US minor outlying islands: Honolulu",
      value: "Pacific/Honolulu"
    }, {
      name: "US minor outlying islands: Pago Pago",
      value: "Pacific/Pago_Pago"
    }, {
      name: "US minor outlying islands: Wake",
      value: "Pacific/Wake"
    }, {
      name: "Vanuatu",
      value: "Pacific/Efate"
    }, {
      name: "Wallis & Futuna",
      value: "Pacific/Wallis"
    }]
  }];
  let TimeZoneSelect = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class TimeZoneSelect extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "planbookEditor", _descriptor, this);

      _defineProperty(this, "regions", regions);

      _defineProperty(this, "_userSelectedZone", null);
    }

    get selectedZone() {
      if (this._userSelectedZone === null) {
        return this.args.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else {
        return this._userSelectedZone;
      }
    }

    selectZone(zone) {
      this.args.selectZone(zone);
      this.updateIntegration(zone);
    }

    updateIntegration(userSelectedZone) {
      this._userSelectedZone = userSelectedZone;
      this.planbookEditor.updatePlanbookIntegration(this.args.planbookId, "googleClassroom", "timeZoneToPostIn", userSelectedZone);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "planbookEditor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectZone", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectZone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIntegration", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateIntegration"), _class.prototype)), _class));
  _exports.default = TimeZoneSelect;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TimeZoneSelect);
});