define("cc-frontend/lib/actions/lesson/PULL_LESSON_BACKWARDS", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "cc-frontend/utils/filter-dates/course", "lodash", "lodash-es", "moment", "cc-frontend/models/transferrable-date"], function (_exports, _patchUpdater, _unitUtils, _course, _lodash, _lodashEs, _moment, _transferrableDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePullLessonBackwards = preparePullLessonBackwards;
  _exports.PULL_LESSON_BACKWARDS = void 0;

  function preparePullLessonBackwards(payload) {
    return {
      name: PULL_LESSON_BACKWARDS.name,
      payload: payload,
      patches: PULL_LESSON_BACKWARDS.patches(payload),
      undoPatches: PULL_LESSON_BACKWARDS.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(PULL_LESSON_BACKWARDS.narrative, payload)
    };
  }

  const PULL_LESSON_BACKWARDS = {
    name: "PULL_LESSON_BACKWARDS",

    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;
      let consolidatedDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);

      let dates = _lodash.default.reduce(payload.course.attributes.calendar.dates, (acc, date, index) => {
        if (date.type === "course-date-custom" && date.attributes.dateString === payload.dateString) {
          let clonedDate = _lodash.default.cloneDeep(date);

          clonedDate.attributes.cardStackId = null; // this is a weird edge case where you pull back on the last lesson of a unit

          if (clonedDate.attributes.unitEnd.length > 0 && acc[index - 1]) {
            _lodash.default.forEach(clonedDate.attributes.unitEnd, id => // @ts-ignore this is unsafe, we should refactor
            acc[index - 1].attributes.unitEnd.push(id));

            clonedDate.attributes.unitEnd = [];
          }

          acc.push(clonedDate);
        } else {
          acc.push(date);
        }

        return acc;
      }, []);

      let courseDateTrainCarStarts = (0, _lodashEs.find)((0, _course.filterCourseDateCustom)(payload.course), date => {
        return date.attributes.dateString > payload.dateString && !(0, _lodashEs.isNil)(date.attributes.cardStackId);
      });
      let {
        transferrableArray,
        courseDatesWithoutTrainCar
      } = extractTrainCar(courseDateTrainCarStarts === null || courseDateTrainCarStarts === void 0 ? void 0 : courseDateTrainCarStarts.attributes.dateString, dates, consolidatedDatesOff, schoolDays);
      let newStartDate = payload.dateString;
      let newCourseDates = (0, _unitUtils.simpleInsertRange)(transferrableArray, newStartDate, courseDatesWithoutTrainCar, consolidatedDatesOff, schoolDays);
      let ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedDatesOff, schoolDays);
      let {
        courseDates,
        units
      } = ret;

      let patch = _patchUpdater.default.create("course", payload.course.id).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).inc(`attributes.calendar.version`, 1).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).inc("attributes.calendar.version", 1).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      var _models$lesson, _models$lesson$attrib;

      let course = findFn("course", payload.course.id);
      let cardStackId = (0, _unitUtils.getCardStackIdForDate)(payload.course.attributes.calendar.dates, payload.dateString);
      let models = {
        course: course,
        planbook: findFn("planbook", course.relationships.planbook.data.id),
        lesson: findFn("card-stack", cardStackId) || findFn("card-stack-summary", cardStackId)
      };
      let lessonTitle = (_models$lesson = models.lesson) === null || _models$lesson === void 0 ? void 0 : (_models$lesson$attrib = _models$lesson.attributes) === null || _models$lesson$attrib === void 0 ? void 0 : _models$lesson$attrib.title;
      let originalDate = payload.dateString;
      let formattedDateString = (0, _moment.default)(originalDate).format("ddd, MMM. D, YYYY");
      let description = Ember.isNone(cardStackId) ? `Shifted lessons backwards onto ${formattedDateString}` : `Deleted "${lessonTitle}" from ${formattedDateString} and shifted lessons backwards`;
      return {
        title: "Pulled Next Lessons Backward",
        titleForAnalytics: "Lesson - Pulled lessons backwards",
        description: description,
        context: {
          lessonTitle: models.lesson ? models.lesson.attributes.title : "[NEW LESSON]",
          courseTitle: models.course.attributes.title,
          planbookTitle: models.planbook.attributes.title,
          cardStackId: models.lesson ? models.lesson.id : "",
          courseId: models.course.id,
          planbookId: models.planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${models.course.id}`,
          to: [`course-edits:course-${models.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        }
      };
    }

  };
  _exports.PULL_LESSON_BACKWARDS = PULL_LESSON_BACKWARDS;

  function formatDateAsISO(date) {
    return dateFns.format(date, "YYYY-MM-DD");
  }

  function extractTrainCar(dateString, courseDates, datesOff, schoolDays) {
    let date = dateFns.parse(dateString);

    let courseDateMap = _lodash.default.keyBy(courseDates, "attributes.dateString");

    let iterations = 0;
    let isIterating = true;
    let transferrableArray = [];
    let modifiedDates = {};

    while (isIterating && iterations < 500) {
      iterations++;
      let dateString = formatDateAsISO(date);

      if (!_lodash.default.includes(schoolDays, dateFns.getDay(date))) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      if (_lodash.default.includes(datesOff, dateString)) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      let courseDate = courseDateMap[dateString] ? courseDateMap[dateString] : undefined;

      if (courseDate === undefined) {
        isIterating = false;
        continue;
      }

      if (courseDate.attributes.cardStackId === null && courseDate.attributes.unitStart.length === 0) {
        isIterating = false;
        continue;
      }

      transferrableArray.push(new _transferrableDate.default({
        cardStackId: courseDate.attributes.cardStackId,
        unitStart: courseDate.attributes.unitStart,
        unitEnd: courseDate.attributes.unitEnd
      }));
      modifiedDates[dateString] = _lodash.default.cloneDeep(courseDate);
      modifiedDates[dateString].attributes.cardStackId = null;
      modifiedDates[dateString].attributes.unitStart = [];
      modifiedDates[dateString].attributes.unitEnd = [];
      modifiedDates[dateString].attributes.unitIds = [];
      date = dateFns.addDays(date, 1);
    }

    let courseDatesWithoutTrainCar = _lodash.default.map(courseDates, courseDate => modifiedDates[courseDate.attributes.dateString] || courseDate);

    return {
      transferrableArray,
      courseDatesWithoutTrainCar
    };
  }
});