define("cc-frontend/mixins/animated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didRender() {
      this._super(...arguments);

      let inClass = Ember.get(this, "animateInClass") || "fadeInDown";
      let outClass = Ember.get(this, "animateOutClass") || "bounceOutDown";

      if (Ember.get(this, "state.isOpen") === true) {
        Ember.run.scheduleOnce("afterRender", () => {
          if (this.isDestroyed || this.isDestroying) return;
          Ember.set(this, "hasOpened", true);
          Ember.set(this, "hasOpenedClasses", "animated animated-fast " + inClass);
          Ember.set(this, "overlayClasses", "animated animated-fast fadeIn");
        });
      } else {
        if (Ember.get(this, "hasOpened") !== true) return;
        Ember.run.scheduleOnce("afterRender", () => {
          if (this.isDestroyed || this.isDestroying) return;
          Ember.set(this, "hasOpenedClasses", "animated " + outClass);
        });
        Ember.run.later(() => {
          if (this.isDestroyed || this.isDestroying) return;
          Ember.set(this, "overlayClasses", "animated animated-fast modalFadeOut");
        }, 250);
        Ember.run.later(() => {
          if (this.isDestroyed || this.isDestroying) return;
          Ember.set(this, "hasOpened", false);
        }, 750);
      }
    }

  });

  _exports.default = _default;
});