define("cc-frontend/components/planner/planbook/special-events/list-modal.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_1j4wm3",
    "header__close": "_header__close_1j4wm3",
    "header__text": "_header__text_1j4wm3",
    "body": "_body_1j4wm3",
    "annotation-list": "_annotation-list_1j4wm3",
    "annotation": "_annotation_1j4wm3",
    "text-side": "_text-side_1j4wm3",
    "title-date": "_title-date_1j4wm3",
    "date": "_date_1j4wm3",
    "title": "_title_1j4wm3",
    "description": "_description_1j4wm3",
    "edit-buttons": "_edit-buttons_1j4wm3",
    "delete-button": "_delete-button_1j4wm3",
    "edit-button": "_edit-button_1j4wm3"
  };
  _exports.default = _default;
});