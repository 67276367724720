define("cc-frontend/components/user/avatar-with-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="user-avatar-with-name">
    <User::Avatar @userSummary={{@userSummary}} @avatarSizeClass="user-avatar--md" />
    <div class="user-avatar-with-name__text">
      <div class="user-avatar-with-name__text__first-name">
        {{@userSummary.attributes.firstName}}
      </div>
      <div class="user-avatar-with-name__text__last-name">
        {{@userSummary.attributes.lastName}}
      </div>
      <div class="user-avatar-with-name__text__email">
        {{@userSummary.attributes.email}}
      </div>
    </div>
  </div>
  {{yield}}
  */
  {
    "id": "24PlBwnQ",
    "block": "[[[10,0],[14,0,\"user-avatar-with-name\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@userSummary\",\"@avatarSizeClass\"],[[30,1],\"user-avatar--md\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"user-avatar-with-name__text\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"user-avatar-with-name__text__first-name\"],[12],[1,\"\\n      \"],[1,[30,1,[\"attributes\",\"firstName\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"user-avatar-with-name__text__last-name\"],[12],[1,\"\\n      \"],[1,[30,1,[\"attributes\",\"lastName\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"user-avatar-with-name__text__email\"],[12],[1,\"\\n      \"],[1,[30,1,[\"attributes\",\"email\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,2,null]],[\"@userSummary\",\"&default\"],false,[\"user/avatar\",\"yield\"]]",
    "moduleName": "cc-frontend/components/user/avatar-with-name.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});