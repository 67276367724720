define("cc-frontend/components/icons/power-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M11.992,19.498c-0.922,0 -1.825,-0.181 -2.681,-0.538c-3.563,-1.484 -5.255,-5.59 -3.772,-9.153c0.71,-1.703 2.036,-3.037 3.736,-3.756c0.062,-0.026 0.128,-0.04 0.195,-0.04c0.201,0 0.382,0.12 0.461,0.305c0.051,0.124 0.052,0.26 0.002,0.383c-0.05,0.124 -0.145,0.221 -0.269,0.273c-1.457,0.616 -2.594,1.76 -3.202,3.22c-1.272,3.054 0.179,6.574 3.233,7.846c0.734,0.305 1.507,0.46 2.297,0.46c0,0 0,0 0.001,0c2.436,0 4.614,-1.45 5.548,-3.694c1.271,-3.054 -0.179,-6.573 -3.233,-7.845c-0.123,-0.051 -0.219,-0.148 -0.27,-0.271c-0.051,-0.123 -0.051,-0.26 0.001,-0.383c0.077,-0.187 0.259,-0.307 0.462,-0.307c0.066,0 0.13,0.013 0.191,0.038c3.564,1.484 5.256,5.59 3.773,9.153c-1.091,2.618 -3.631,4.309 -6.473,4.309v0Z"
      ></path>
      <path
        d="M12,11.497c-0.276,0 -0.5,-0.224 -0.5,-0.5v-6.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v6.5c0,0.275 -0.225,0.5 -0.5,0.5Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "5jKK4Lg1",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M11.992,19.498c-0.922,0 -1.825,-0.181 -2.681,-0.538c-3.563,-1.484 -5.255,-5.59 -3.772,-9.153c0.71,-1.703 2.036,-3.037 3.736,-3.756c0.062,-0.026 0.128,-0.04 0.195,-0.04c0.201,0 0.382,0.12 0.461,0.305c0.051,0.124 0.052,0.26 0.002,0.383c-0.05,0.124 -0.145,0.221 -0.269,0.273c-1.457,0.616 -2.594,1.76 -3.202,3.22c-1.272,3.054 0.179,6.574 3.233,7.846c0.734,0.305 1.507,0.46 2.297,0.46c0,0 0,0 0.001,0c2.436,0 4.614,-1.45 5.548,-3.694c1.271,-3.054 -0.179,-6.573 -3.233,-7.845c-0.123,-0.051 -0.219,-0.148 -0.27,-0.271c-0.051,-0.123 -0.051,-0.26 0.001,-0.383c0.077,-0.187 0.259,-0.307 0.462,-0.307c0.066,0 0.13,0.013 0.191,0.038c3.564,1.484 5.256,5.59 3.773,9.153c-1.091,2.618 -3.631,4.309 -6.473,4.309v0Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12,11.497c-0.276,0 -0.5,-0.224 -0.5,-0.5v-6.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v6.5c0,0.275 -0.225,0.5 -0.5,0.5Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/power-simple.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});