define("cc-frontend/components/planner/menus/unit-menu/primary/body-read-only", ["exports", "@glimmer/component", "cc-frontend/lib/actions/lesson/LESSON_COPY"], function (_exports, _component, _LESSON_COPY) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DS::DropdownMenu::SingleButtonRow
    @isAtTopOfMenu={{true}}
    @iconName="icons/copy-doc"
    @clickAction={{this.copyUnit}}
  >
    Copy Unit
  </DS::DropdownMenu::SingleButtonRow>
  <DS::DropdownMenu::SingleButtonRow
    @iconName="icons/print-text"
    @clickAction={{this.printOrDownload}}
  >
    Print or Download
  </DS::DropdownMenu::SingleButtonRow>
  */
  {
    "id": "G/kHD7VE",
    "block": "[[[8,[39,0],null,[[\"@isAtTopOfMenu\",\"@iconName\",\"@clickAction\"],[true,\"icons/copy-doc\",[30,0,[\"copyUnit\"]]]],[[\"default\"],[[[[1,\"\\n  Copy Unit\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/print-text\",[30,0,[\"printOrDownload\"]]]],[[\"default\"],[[[[1,\"\\n  Print or Download\\n\"]],[]]]]]],[],false,[\"d-s/dropdown-menu/single-button-row\"]]",
    "moduleName": "cc-frontend/components/planner/menus/unit-menu/primary/body-read-only.hbs",
    "isStrictMode": false
  });

  let UnitMenuPrimaryReadOnly = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class UnitMenuPrimaryReadOnly extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modalManager", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);
    }

    copyUnit() {
      if (this.session.isAuthenticated !== true) {
        this.session.mustSignInToProceed("Copy Lesson");
        return;
      }

      if (this.session.userId === null) return;
      this.store.dispatchPreparedAction((0, _LESSON_COPY.prepareLessonCopy)({
        lessonToCopy: this.args.unitCardStack,
        cardStackId: this.args.unitCardStack.id,
        currentUserId: this.session.userId
      }));
      this.args.closeMenu();
    }

    printOrDownload() {
      this.modalManager.openModal("planner/modals/print-download", {
        courseId: this.args.courseId,
        unitId: this.args.unitCardStack.id,
        startDate: this.args.unitInfo.startDate,
        endDate: this.args.unitInfo.endDate,
        layout: "course-unit",
        canPrint: true
      });
      this.args.closeMenu();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "copyUnit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "copyUnit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printOrDownload", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "printOrDownload"), _class.prototype)), _class));
  _exports.default = UnitMenuPrimaryReadOnly;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UnitMenuPrimaryReadOnly);
});