define("cc-frontend/components/icons/ios-cloud-upload-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <path
      d="M193.3 260.4l-11.6-11.6 74.5-74.3 74.5 74.3-11.7 11.6-54.6-54.6v241.8h-16.5V205.8z"
    ></path>
    <path
      d="M399.3 183.6c0-1.2.2-2.4.2-3.6 0-64.3-52.8-116.4-116.8-116.4-46.1 0-85.8 27.1-104.4 66.3-8.1-4.1-17.1-6.4-26.8-6.4-29.6 0-54.1 23.6-58.9 52C57.4 187.6 32 222.2 32 261.8c0 49.7 40.1 90.2 89.6 90.2H213v-16h-90.6c-40.9 0-74.2-33.5-74.2-74.6 0-31.8 20.2-61.2 50.2-71.6l8.4-2.9 1.5-8.8c3.6-21.6 22.1-39.3 43.9-39.3 6.9 0 13.7 1.6 19.9 4.8l13.5 6.8 6.5-13.7c16.6-34.9 52.1-57.4 90.4-57.4 55.3 0 100.9 43.3 100.9 98.9 0 13.3-.2 20.3-.2 20.3l15.2.1c36.6.5 65.6 33.4 65.6 70.3 0 36.8-29.8 66.9-66.5 67.1H297v16h101c45 0 82-37.3 82-82.8s-35.5-85.5-80.7-85.6z"
    ></path>
  </svg>
  */
  {
    "id": "bZDbp8Oq",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"512\"],[14,\"height\",\"512\"],[14,\"viewBox\",\"0 0 512 512\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M193.3 260.4l-11.6-11.6 74.5-74.3 74.5 74.3-11.7 11.6-54.6-54.6v241.8h-16.5V205.8z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M399.3 183.6c0-1.2.2-2.4.2-3.6 0-64.3-52.8-116.4-116.8-116.4-46.1 0-85.8 27.1-104.4 66.3-8.1-4.1-17.1-6.4-26.8-6.4-29.6 0-54.1 23.6-58.9 52C57.4 187.6 32 222.2 32 261.8c0 49.7 40.1 90.2 89.6 90.2H213v-16h-90.6c-40.9 0-74.2-33.5-74.2-74.6 0-31.8 20.2-61.2 50.2-71.6l8.4-2.9 1.5-8.8c3.6-21.6 22.1-39.3 43.9-39.3 6.9 0 13.7 1.6 19.9 4.8l13.5 6.8 6.5-13.7c16.6-34.9 52.1-57.4 90.4-57.4 55.3 0 100.9 43.3 100.9 98.9 0 13.3-.2 20.3-.2 20.3l15.2.1c36.6.5 65.6 33.4 65.6 70.3 0 36.8-29.8 66.9-66.5 67.1H297v16h101c45 0 82-37.3 82-82.8s-35.5-85.5-80.7-85.6z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/ios-cloud-upload-outline.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});