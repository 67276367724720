define("cc-frontend/lib/actions/lesson/LESSON_TITLE_INTERMEDIATE_UPDATE", ["exports", "cc-frontend/lib/patch-updater"], function (_exports, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_TITLE_INTERMEDIATE_UPDATE = void 0;
  const LESSON_TITLE_INTERMEDIATE_UPDATE = {
    name: "LESSON_TITLE_INTERMEDIATE_UPDATE",
    params: {
      value: "string",
      cardStackId: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", payload.value).patch;

      let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.title", payload.value).patch;

      return [patchForCardStackSummary, patch];
    },

    undoPatches: null,
    narrative: null
  };
  _exports.LESSON_TITLE_INTERMEDIATE_UPDATE = LESSON_TITLE_INTERMEDIATE_UPDATE;
  var _default = LESSON_TITLE_INTERMEDIATE_UPDATE;
  _exports.default = _default;
});