define("cc-frontend/routes/planbook/setup/templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),

    model(params) {
      let hash = this.modelFor("planbook");
      hash.lesson = Ember.get(this, "store").find("lesson", params.lesson_id);
      return hash(hash);
    }

  });

  _exports.default = _default;
});