define("cc-frontend/components/planner/card-stacks/lesson-simple-commentable.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson": "_lesson_rte7pl",
    "lesson-title": "_lesson-title_rte7pl _lesson-block__text__lesson-title-row__title_cgefpq",
    "card": "_card_rte7pl",
    "add-comment-button": "_add-comment-button_rte7pl",
    "card-text": "_card-text_rte7pl",
    "card__title": "_card__title_rte7pl _title_d9i22d",
    "card__value": "_card__value_rte7pl _value_r5c7im",
    "card-comment-sidebar": "_card-comment-sidebar_rte7pl",
    "card-comment": "_card-comment_rte7pl",
    "--form": "_--form_rte7pl",
    "card-comment__user": "_card-comment__user_rte7pl",
    "card-comment__text": "_card-comment__text_rte7pl",
    "card-comment__timestamp": "_card-comment__timestamp_rte7pl"
  };
  _exports.default = _default;
});