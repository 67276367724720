define("cc-frontend/components/icons/double-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
    {{! Navigation Arrows Down 1 }}
    <g>
      <path
        d="M12.002,23.691c-0.04,0 -0.08,-0.002 -0.12,-0.007c-0.265,-0.032 -0.502,-0.165 -0.667,-0.375l-9.972,-12.692c-0.137,-0.175 -0.213,-0.394 -0.213,-0.616c0,-0.267 0.103,-0.518 0.292,-0.707c0.188,-0.189 0.439,-0.293 0.706,-0.294h2.472c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-2.471l9.971,12.691l9.971,-12.691l-2.471,0.001c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h2.471c0.222,0 0.44,0.076 0.615,0.212c0.21,0.165 0.344,0.401 0.376,0.666c0.033,0.265 -0.04,0.527 -0.205,0.738l-9.972,12.692c-0.05,0.063 -0.107,0.12 -0.169,0.169c-0.175,0.139 -0.389,0.213 -0.614,0.213Z"
      ></path>
      <path
        d="M12.002,15.691c-0.04,0 -0.08,-0.002 -0.12,-0.007c-0.265,-0.032 -0.502,-0.165 -0.667,-0.375l-9.972,-12.692c-0.137,-0.175 -0.213,-0.394 -0.213,-0.616c0,-0.267 0.103,-0.518 0.292,-0.707c0.188,-0.189 0.439,-0.293 0.706,-0.294l19.943,-0.002c0.222,0 0.441,0.076 0.615,0.212c0.21,0.164 0.344,0.401 0.376,0.666c0.033,0.265 -0.04,0.527 -0.205,0.738l-9.972,12.695c-0.05,0.063 -0.107,0.12 -0.169,0.169c-0.175,0.139 -0.389,0.213 -0.614,0.213Zm-9.973,-13.691l9.971,12.691l9.97,-12.693l-19.941,0.002Z"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "+RhCodhO",
    "block": "[[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[3,\"Generated by IJSVG (https://github.com/iconjar/IJSVG)\"],[1,\"\\n\"],[1,\"  \"],[10,\"g\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12.002,23.691c-0.04,0 -0.08,-0.002 -0.12,-0.007c-0.265,-0.032 -0.502,-0.165 -0.667,-0.375l-9.972,-12.692c-0.137,-0.175 -0.213,-0.394 -0.213,-0.616c0,-0.267 0.103,-0.518 0.292,-0.707c0.188,-0.189 0.439,-0.293 0.706,-0.294h2.472c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5h-2.471l9.971,12.691l9.971,-12.691l-2.471,0.001c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h2.471c0.222,0 0.44,0.076 0.615,0.212c0.21,0.165 0.344,0.401 0.376,0.666c0.033,0.265 -0.04,0.527 -0.205,0.738l-9.972,12.692c-0.05,0.063 -0.107,0.12 -0.169,0.169c-0.175,0.139 -0.389,0.213 -0.614,0.213Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M12.002,15.691c-0.04,0 -0.08,-0.002 -0.12,-0.007c-0.265,-0.032 -0.502,-0.165 -0.667,-0.375l-9.972,-12.692c-0.137,-0.175 -0.213,-0.394 -0.213,-0.616c0,-0.267 0.103,-0.518 0.292,-0.707c0.188,-0.189 0.439,-0.293 0.706,-0.294l19.943,-0.002c0.222,0 0.441,0.076 0.615,0.212c0.21,0.164 0.344,0.401 0.376,0.666c0.033,0.265 -0.04,0.527 -0.205,0.738l-9.972,12.695c-0.05,0.063 -0.107,0.12 -0.169,0.169c-0.175,0.139 -0.389,0.213 -0.614,0.213Zm-9.973,-13.691l9.971,12.691l9.97,-12.693l-19.941,0.002Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/double-arrow-down.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});