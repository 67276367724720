define("cc-frontend/components/planner/card-stacks/lesson-simple-read-only.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson": "_lesson_17dayt",
    "lesson-title": "_lesson-title_17dayt _lesson-block__text__lesson-title-row__title_cgefpq",
    "card": "_card_17dayt",
    "card-google-classroom": "_card-google-classroom_17dayt",
    "card__title": "_card__title_17dayt _title_d9i22d",
    "card__value": "_card__value_17dayt _value_r5c7im",
    "odd-message": "_odd-message_17dayt",
    "big-body": "_big-body_17dayt",
    "standards-list-item": "_standards-list-item_17dayt",
    "gc-banner": "_gc-banner_17dayt",
    "gc-banner__text": "_gc-banner__text_17dayt"
  };
  _exports.default = _default;
});