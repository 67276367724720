define("cc-frontend/services/in-app-notice", ["exports", "cc-frontend/helpers/find-document"], function (_exports, _findDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InAppNoticeService = (_dec = Ember.inject.service, _dec2 = (0, _findDocument.default)("contentfulEntryId", "in-app-message"), (_class = class InAppNoticeService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isVisible", false);

      _defineProperty(this, "contentfulEntryId", null);

      _defineProperty(this, "notice", null);

      _defineProperty(this, "campaignId", null);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "contentfulEntry", _descriptor2, this);
    }

    newNotice(notice) {
      Ember.set(this, "contentfulEntryId", notice.attributes.contentfulEntryId);
      Ember.set(this, "notice", notice);

      if (Ember.get(this, "isVisible") === false) {
        Ember.set(this, "isVisible", true);
      }

      if (window && window.analytics) {
        window.analytics.track("In App Notice - Received", {
          campaignId: notice.attributes.campaignId,
          contentfulEntryName: Ember.get(this, "contentfulEntry.attributes.name"),
          contentfulEntryId: notice.attributes.contentfulEntryId
        });
      }
    }

    close() {
      Ember.set(this, "isVisible", false);
    }

    replyAndClose() {
      let notice = Ember.get(this, "notice");
      Ember.set(this, "isVisible", false);
      console.log("notice", notice);

      if (window && window.Intercom) {
        window.Intercom("showNewMessage", Ember.get(this, "contentfulEntry.attributes.intercomMessageSeed"));
      }

      if (window && window.analytics) {
        window.analytics.track("In App Notice - Reply & Close", {
          campaignId: notice.attributes.campaignId,
          contentfulEntryName: Ember.get(this, "contentfulEntry.attributes.name"),
          contentfulEntryId: notice.attributes.contentfulEntryId
        });
      }
    }

    replyAndCloseWithEmoji(emoji) {
      let notice = Ember.get(this, "notice");
      Ember.set(this, "isVisible", false);

      if (window && window.analytics) {
        window.analytics.track("In App Notice - Reply & Close With Emoji", {
          campaignId: notice.attributes.campaignId,
          emojiResponse: emoji,
          contentfulEntryName: Ember.get(this, "contentfulEntry.attributes.name"),
          contentfulEntryId: notice.attributes.contentfulEntryId
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contentfulEntry", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = InAppNoticeService;
});