define("cc-frontend/components/head-layout", ["exports", "ember-cli-head/components/head-layout", "lodash-es"], function (_exports, _headLayout, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!
    for some very very strange reason, prettier inserts guid="%cursor:0%" into the element here.
    I turn prettier off so it doesn't and this ends up working
  }}
  {{! prettier-ignore }}
  {{#-in-element this.headElement}}
    <meta name="ember-cli-head-start" content="" />
    {{{this.titleTag}}}
    <HeadContent />
    <meta name="ember-cli-head-end" content="" />
  {{/-in-element}}
  */
  {
    "id": "lqJVkGIz",
    "block": "[[[40,[[[1,\"  \"],[10,\"meta\"],[14,3,\"ember-cli-head-start\"],[14,\"content\",\"\"],[12],[13],[1,\"\\n  \"],[2,[30,0,[\"titleTag\"]]],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[10,\"meta\"],[14,3,\"ember-cli-head-end\"],[14,\"content\",\"\"],[12],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"headElement\"]],null]],[],false,[\"in-element\",\"head-content\"]]",
    "moduleName": "cc-frontend/components/head-layout.hbs",
    "isStrictMode": false
  });

  let HeadLayout = (_dec = Ember.inject.service, _dec2 = Ember.computed("headData.title"), (_class = class HeadLayout extends _headLayout.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "headData", _descriptor, this);
    }

    get titleTag() {
      if ((0, _lodashEs.isNil)(this.get("headData.title"))) return null;
      return Ember.String.htmlSafe(`<title>${this.get("headData.title") || ""}</title>`);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "titleTag", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "titleTag"), _class.prototype)), _class));
  _exports.default = HeadLayout;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HeadLayout);
});