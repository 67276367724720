define("cc-frontend/components/planner/menus/titles/with-back-arrow.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header-bar": "_header-bar_92f5ku",
    "header-bar__back-arrow": "_header-bar__back-arrow_92f5ku"
  };
  _exports.default = _default;
});