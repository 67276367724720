define("cc-frontend/components/planner/card-stacks/provider", ["exports", "@ember-decorators/component", "cc-frontend/helpers/find-many"], function (_exports, _component, _findMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.cardStack.isFulfilled this.cardStack.content this.units.content}}
  */
  {
    "id": "4F43r90z",
    "block": "[[[18,1,[[30,0,[\"cardStack\",\"isFulfilled\"]],[30,0,[\"cardStack\",\"content\"]],[30,0,[\"units\",\"content\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/provider.hbs",
    "isStrictMode": false
  });

  class ObjectPromiseProxy extends Ember.ObjectProxy.extend(Ember.PromiseProxyMixin) {} // With the 4 different forks this can take, it's useful to log all the options when debugging
  // Set this to true to see what's up.


  const LOG_STATES = false;
  let CardStackProvider = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _findMany.default)("unitIds", "card-stack-summary"), _dec5 = Ember.computed("cardStackId", "cardStackTemplateId", "dateString", "phantomCardStackId"), _dec(_class = (_class2 = class CardStackProvider extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "phantomCardStacks", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "cardStackId", void 0);

      _defineProperty(this, "phantomCardStackId", void 0);

      _defineProperty(this, "cardStackTemplateId", void 0);

      _defineProperty(this, "unitIds", void 0);

      _defineProperty(this, "useCardStackSummary", false);

      _defineProperty(this, "useStudentVersion", false);

      _defineProperty(this, "course", null);

      _defineProperty(this, "usedAs", void 0);

      _defineProperty(this, "ownerId", null);

      _defineProperty(this, "defaultPermissions", []);

      _defineProperty(this, "relationships", []);

      _defineProperty(this, "lastProxy", null);

      _defineProperty(this, "lastCardStackId", null);

      _defineProperty(this, "lastReceivedCardStackId", null);

      _defineProperty(this, "lastPhantomCardStackId", null);

      _defineProperty(this, "isShowingPhantomCardStack", void 0);

      _defineProperty(this, "lastDateString", null);

      _initializerDefineProperty(this, "units", _descriptor3, this);
    }

    log(...args) {
      console.log(`CSPROVIDER: ${this.usedAs}:${this.dateString}`, ...args);
    }

    /**
     * Either return the actual lesson or a phantom lesson
     * We watch the dateString b/c we might have a bunch of null cardStackIds and the datestring triggers
     * the revalidation.
     */
    get cardStack() {
      // If we had a cardStack and now we don't (e.g. we moved the lesson that was in this place)
      // we reset all the caches.
      if (LOG_STATES) this.log(this.ownerId, {
        cardStackId: this.cardStackId,
        lastCardStackId: this.lastCardStackId,
        lastReceivedCardStackId: this.lastReceivedCardStackId,
        lastPhantomCardStackId: this.lastPhantomCardStackId
      }); // I think this is all the ways we'd want to invalidate this. BUT I had like a gajillion
      // cases before (commented out) so if we get bugs, see if I'm missing something

      if ( // We were using a phantom card stack but moved something in
      this.isShowingPhantomCardStack === true && this.cardStackId !== this.lastPhantomCardStackId && this.phantomCardStackId !== this.lastPhantomCardStackId || // We moved a card stack out
      this.isShowingPhantomCardStack === false && this.cardStackId === null && this.lastCardStackId !== null || // We moved a new card stack into this place
      this.isShowingPhantomCardStack === false && this.cardStackId !== this.lastCardStackId || // We changed the card stack id
      this.isShowingPhantomCardStack === false && this.cardStackId !== this.lastReceivedCardStackId // (this.cardStackId === null && this.lastCardStackId !== null) || // we moved a card stack out.
      // this.cardStackId !== this.lastCardStackId || // we mvoed a new card stack into this place
      // (this.cardStackId === null && // we're dealing with a case where we need the phantom card id
      // this.phantomCardStackId !== undefined && // we actually passed a phantom card stack id in
      //   this.phantomCardStackId !== this.lastPhantomCardStackId) // it's not the same as last time
      ) {
          if (LOG_STATES) this.log("IS_INVALIDATING", this.dateString, this.cardStackId, this.lastCardStackId);
          this.lastCardStackId = null;
          this.lastProxy = null;
          this.lastDateString = null;
          if (this.ownerId === null || this.dateString === null) throw Error("Missing ownerId and dateString");
          this.phantomCardStacks.invalidateDate(this.ownerId, this.cardStackId, this.dateString);
        }

      this.lastPhantomCardStackId = this.phantomCardStackId;
      this.lastReceivedCardStackId = this.cardStackId;
      return this.cardStackId ? this._returnExistingCardStack() : this._returnPhantomCardStack();
    }
    /**
     * If we've already returned it, we'll just return the proxy
     * If we haven't returned anything yet, we look it up in the store
     */


    _returnExistingCardStack() {
      if (this.cardStackId === null) return;
      this.isShowingPhantomCardStack = false;
      this.lastPhantomCardStackId = null; // This means the coursedate has a lesson. If it's a lesson we created in the branch
      // below, we want to just return that so things don't jump around the page.

      if (this.lastCardStackId === this.cardStackId) {
        // If we're hitting this path, something changed and it was probably the
        // template id. So, we check if we need to update the template
        if (this.useCardStackSummary !== true) {
          this.phantomCardStacks.applyTemplateCardsToCardStackIfNeeded(this.cardStackId, this.cardStackTemplateId);
        } // Return the last proxy


        if (LOG_STATES) this.log("RETURN_EXISTING_CARD_STACK__LAST_PROXY");
        return this.lastProxy;
      } else {
        // Find the cardStack
        let model = this.useCardStackSummary ? "card-stack-summary" : "card-stack";
        model = this.useStudentVersion ? "class-website-lesson" : model;
        let promise = this.store.find(model, this.cardStackId, false); // Check if it needs cards

        if (this.useCardStackSummary !== true) {
          promise.then(_cardStack => {
            if (this.isDestroyed || this.isDestroying) return;
            this.phantomCardStacks.applyTemplateCardsToCardStackIfNeeded(this.cardStackId, this.cardStackTemplateId);
          });
        } // Cache the date


        this.lastDateString = this.dateString; // cache the card stack id

        this.lastCardStackId = this.cardStackId; // cache the proxy

        if (LOG_STATES) this.log("RETURN_EXISTING_CARD_STACK__NEW_PROXY", this.dateString);
        return this.lastProxy = ObjectPromiseProxy.create({
          promise: promise
        });
      }
    }
    /**
     * If we've returned soemthing before, we return it again
     * If we haven't, we request the service for one
     */


    _returnPhantomCardStack() {
      if (this.ownerId === null) return null;
      this.isShowingPhantomCardStack = true; // If we've already returned a proxy, we'll just return it again
      // but we'll check if we should update the cards if needed
      //

      if (this.lastProxy && this.lastDateString === this.dateString) {
        if (this.useCardStackSummary !== true) {
          this.phantomCardStacks.applyTemplateCardsToCardStackIfNeeded(this.lastCardStackId, this.cardStackTemplateId);
        }

        if (LOG_STATES) this.log("RETURN_PHANTOM__LAST_PROXY", this.dateString);
        return this.lastProxy;
      } // We'll find a card stack for the date. The service caches the card stacks
      // so the same one gets returned for the same date and course/planbook


      let cardStack = this.phantomCardStacks.findCardStackForDate(this.ownerId, this.usedAs, this.dateString, this.defaultPermissions, this.relationships, this.useCardStackSummary || false); // Apply the template cards if needed

      if (this.useCardStackSummary !== true) {
        this.phantomCardStacks.applyTemplateCardsToCardStackIfNeeded(cardStack.id, this.cardStackTemplateId);
      } // Cache the date string so we can tell phantom card stacks from each other


      this.lastDateString = this.dateString; // Cache the id that's returned to us

      this.lastCardStackId = cardStack.id;
      this.lastPhantomCardStackId = cardStack.id; // Cache the promise and return it

      if (LOG_STATES) this.log("RETURN_PHANTOM__NEW_PROXY", this.dateString, this.usedAs, this.toString());
      return this.lastProxy = ObjectPromiseProxy.create({
        promise: Ember.RSVP.Promise.resolve(cardStack)
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "phantomCardStacks", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "units", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "cardStack", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "cardStack"), _class2.prototype)), _class2)) || _class);
  _exports.default = CardStackProvider;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CardStackProvider);
});