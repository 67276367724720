define("cc-frontend/components/school/reviews/manager", ["exports", "@glimmer/component", "cc-frontend/lib/actions/lesson/LESSON_CHANGE_REVIEW_STATUS", "cc-frontend/services/socket", "lodash-es"], function (_exports, _component, _LESSON_CHANGE_REVIEW_STATUS, _socket, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="manager">
    <div local-class="back">
      <LinkTo @route="new-group.show" class={{local-class "back-btn"}}>
        <Icons::ArrowLeft />
      </LinkTo>
    </div>
    <div local-class="list">
      <School::Reviews::Manager::List
        @group={{@group}}
        @allReviews={{this.reviews}}
        @filteredReviews={{this.filteredReviews}}
        @filter={{@filter}}
      />
  
    </div>
    {{log "scs" @selectedCardStackId}}
    <div local-class="detail">
      <School::Reviews::Manager::Detail
        @group={{@group}}
        @selectedReview={{this.selectedReview}}
        @selectedCardStackId={{@selectedCardStackId}}
        @markForRevision={{this.markForRevision}}
        @approvePlan={{this.approvePlan}}
      />
  
    </div>
  </div>
  */
  {
    "id": "jI9VW2iz",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"manager\"],[[\"from\"],[\"cc-frontend/components/school/reviews/manager.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"back\"],[[\"from\"],[\"cc-frontend/components/school/reviews/manager.css\"]]]]]],[12],[1,\"\\n    \"],[8,[39,1],[[16,0,[28,[37,0],[\"back-btn\"],[[\"from\"],[\"cc-frontend/components/school/reviews/manager.css\"]]]]],[[\"@route\"],[\"new-group.show\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"list\"],[[\"from\"],[\"cc-frontend/components/school/reviews/manager.css\"]]]]]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@group\",\"@allReviews\",\"@filteredReviews\",\"@filter\"],[[30,1],[30,0,[\"reviews\"]],[30,0,[\"filteredReviews\"]],[30,2]]],null],[1,\"\\n\\n  \"],[13],[1,\"\\n  \"],[1,[54,[\"scs\",[30,3]]]],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"detail\"],[[\"from\"],[\"cc-frontend/components/school/reviews/manager.css\"]]]]]],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@group\",\"@selectedReview\",\"@selectedCardStackId\",\"@markForRevision\",\"@approvePlan\"],[[30,1],[30,0,[\"selectedReview\"]],[30,3],[30,0,[\"markForRevision\"]],[30,0,[\"approvePlan\"]]]],null],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13]],[\"@group\",\"@filter\",\"@selectedCardStackId\"],false,[\"local-class\",\"link-to\",\"icons/arrow-left\",\"school/reviews/manager/list\",\"log\",\"school/reviews/manager/detail\"]]",
    "moduleName": "cc-frontend/components/school/reviews/manager.hbs",
    "isStrictMode": false
  });

  let ManagerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ManagerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "query", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "_reviews", _descriptor3, this);

      _initializerDefineProperty(this, "isFetchingQuery", _descriptor4, this);

      _initializerDefineProperty(this, "queryAttempts", _descriptor5, this);

      _defineProperty(this, "preparedQuery", null);
    }

    get reviews() {
      Ember.run.later(() => {
        let preparedQuery = this.fetchQuery();
      }, 15);
      return this._reviews;
    }

    get filteredReviews() {
      return (0, _lodashEs.filter)(this.reviews, review => {
        if (this.args.filter === "ALL") return true;
        if (this.args.filter === "REVISE_AND_RESUBMIT") return review.status === "REVISE_AND_RESUBMIT";
        if (this.args.filter === "SUBMITTED") return review.isRequested === true;
        if (this.args.filter === "APPROVED") return review.status === "APPROVED";
        return false;
      });
    }

    get selectedReview() {
      let review = (0, _lodashEs.find)(this.filteredReviews, review => review.cardStackId === this.args.selectedCardStackId);

      if ((0, _lodashEs.isNil)(review)) {
        review = this.filteredReviews[0]; // need to do a later because it was getting stuck in the same tracked property

        Ember.run.later(() => {
          if (review) this.args.setNewCardStackId(review.cardStackId);
        }, 15);
        return review;
      } else {
        return review;
      }
    }

    fetchQuery(hasBeenInvalidated = false) {
      if (this.isDestroyed || this.isDestroying) return;
      if ((0, _lodashEs.isNil)(this.args.group.id)) return;
      if (hasBeenInvalidated !== true && this.preparedQuery !== null) return;

      if ((0, _lodashEs.isNil)(this.preparedQuery)) {
        this.preparedQuery = this.query.generateQuery("reviews_for_group", this.args.group.id, () => {
          this.fetchQuery(true);
        });
      }

      this.query.fetch(this.preparedQuery, 0, 200).then(response => {
        if (this.isDestroyed || this.isDestroying) return;
        this._reviews = response.data.results;
        this.isFetchingQuery = false;
      }).catch(error => {
        this.queryAttempts++;
        let newDelay = (0, _socket.fibonacciBackoff)(this.queryAttempts, 1000, 60000);

        if (this.preparedQuery !== null) {
          console.error(`FAILED_QUERY #${this.queryAttempts}: ${this.preparedQuery.type}:${this.preparedQuery.id}`, error);
        }

        Ember.run.later(() => this.fetchQuery(), newDelay);
      });
    }

    findNextCardStackId(cardStackId) {
      let index = (0, _lodashEs.findIndex)(this.filteredReviews, review => review.cardStackId === cardStackId);
      let newReview = this.filteredReviews[index % this.filteredReviews.length - 1];

      if ((0, _lodashEs.isNil)(newReview)) {
        let firstReview = this.filteredReviews[0];
        return firstReview ? firstReview.cardStackId : null;
      } else {
        return newReview.cardStackId;
      }
    }

    approvePlan(cardStack) {
      let newCardStackId = this.findNextCardStackId(cardStack.get("id"));
      let newReviews = (0, _lodashEs.map)(cardStack.get("attributes.reviews"), review => {
        let r = (0, _lodashEs.cloneDeep)(review);
        r.status = "APPROVED";
        r.isRequested = false;
        r.lastStatusGivenAt = new Date().toISOString();
        return r;
      });
      this.store.dispatchPreparedAction((0, _LESSON_CHANGE_REVIEW_STATUS.prepareChangeReviewStatus)({
        cardStackId: cardStack.get("id"),
        oldReviews: cardStack.get("attributes.reviews"),
        newReviews: newReviews
      }));
      this.args.setNewCardStackId(newCardStackId);
    }

    markForRevision(cardStack) {
      let newCardStackId = this.findNextCardStackId(cardStack.get("id"));
      let newReviews = (0, _lodashEs.map)(cardStack.get("attributes.reviews"), review => {
        let r = (0, _lodashEs.cloneDeep)(review);
        r.status = "REVISE_AND_RESUBMIT";
        r.isRequested = false;
        r.lastStatusGivenAt = new Date().toISOString();
        return r;
      });
      this.store.dispatchPreparedAction((0, _LESSON_CHANGE_REVIEW_STATUS.prepareChangeReviewStatus)({
        cardStackId: cardStack.get("id"),
        oldReviews: cardStack.get("attributes.reviews"),
        newReviews: newReviews
      }));
      this.args.setNewCardStackId(newCardStackId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "query", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_reviews", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isFetchingQuery", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "queryAttempts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "approvePlan", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "approvePlan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markForRevision", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "markForRevision"), _class.prototype)), _class));
  _exports.default = ManagerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ManagerComponent);
});