define("cc-frontend/components/user/home/school-network", ["exports", "@ember-decorators/component", "cc-frontend/helpers/find-many", "lodash"], function (_exports, _component, _findMany, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserHomeSchoolNetwork = (_dec = (0, _component.classNames)("user-home__body__schools__school"), _dec2 = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
  
      <div class="user-home__body__schools__school__header">
        <div class="user-home__body__schools__school__header__title">
          {{@group.attributes.title}}
        </div>
        <LinkTo @route="new-group.show" @model={{@group.id}} class="user-home__body__schools__school__header__go-to-group">
          Visit
        </LinkTo>
      </div>
  
      {{!-- {{#if schools}}
        <div class="user-home__body__schools__school__body tw-flex tw-flex-row tw-flex-wrap tw-p-8">
          {{#each schools.content as |school|}}
            {{#link-to "new-group.show" school.id class="user-home__body__schools__school__header__go-to-group tw-w-full tw-flex-grow tw-text-center tw-text-3xl"}}
              {{school.attributes.title}}
            {{/link-to}}
          {{/each}}
  
        </div>
      {{/if}} --}}
  
      {{yield}}
  
    {{yield}}
  
  */
  {
    "id": "B7F87Hkc",
    "block": "[[[1,\"\\n\\n    \"],[10,0],[14,0,\"user-home__body__schools__school__header\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"user-home__body__schools__school__header__title\"],[12],[1,\"\\n        \"],[1,[30,1,[\"attributes\",\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,0],[[24,0,\"user-home__body__schools__school__header__go-to-group\"]],[[\"@route\",\"@model\"],[\"new-group.show\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        Visit\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[1,\"\\n    \"],[18,2,null],[1,\"\\n\\n  \"],[18,2,null],[1,\"\\n\"]],[\"@group\",\"&default\"],false,[\"link-to\",\"yield\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec3 = Ember.inject.service, _dec4 = Ember.computed("group.attributes.memberships.length"), _dec5 = (0, _findMany.default)("schoolIds", "group"), _dec(_class = _dec2(_class = (_class2 = class UserHomeSchoolNetwork extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "schools", _descriptor2, this);
    }

    get schoolIds() {
      return _lodash.default.chain(Ember.get(this, "group.attributes.memberships")).filter(membership => membership.type === "group-membership-group").filter(membership => membership.attributes.usedAs === "school").map(membership => membership.attributes.groupId).value();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "schoolIds", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "schoolIds"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "schools", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = UserHomeSchoolNetwork;
});