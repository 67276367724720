define("cc-frontend/components/planner/card-stacks/routine.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "routine-not-updating-from-template-notice": "_routine-not-updating-from-template-notice_k4sk6g",
    "routine-not-updating-from-template-notice__header": "_routine-not-updating-from-template-notice__header_k4sk6g",
    "routine-not-updating-from-template-notice__subheader": "_routine-not-updating-from-template-notice__subheader_k4sk6g",
    "routine-not-updating-from-template-notice__action-link": "_routine-not-updating-from-template-notice__action-link_k4sk6g",
    "routine-not-updating-from-template-notice__more-prompt": "_routine-not-updating-from-template-notice__more-prompt_k4sk6g",
    "routine-not-updating-from-template-notice__extra": "_routine-not-updating-from-template-notice__extra_k4sk6g"
  };
  _exports.default = _default;
});