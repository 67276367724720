define("cc-frontend/components/planner/google-classroom/parts/text-input.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "inputs": "_inputs_14m3u4",
    "title": "_title_14m3u4",
    "points": "_points_14m3u4",
    "description": "_description_14m3u4",
    "top-row": "_top-row_14m3u4",
    "top-row__section": "_top-row__section_14m3u4",
    "first": "_first_14m3u4",
    "headers": "_headers_14m3u4",
    "select-trigger": "_select-trigger_14m3u4",
    "select-dropdown": "_select-dropdown_14m3u4",
    "drop-fade-below": "_drop-fade-below_14m3u4",
    "choice": "_choice_14m3u4",
    "choice-add": "_choice-add_14m3u4",
    "choice-input": "_choice-input_14m3u4",
    "choice-icon": "_choice-icon_14m3u4",
    "choice-move": "_choice-move_14m3u4",
    "choice-remove": "_choice-remove_14m3u4",
    "drop-fade-above": "_drop-fade-above_14m3u4"
  };
  _exports.default = _default;
});