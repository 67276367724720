define("cc-frontend/models/course-date-combined", ["exports", "@marcj/marshal"], function (_exports, _marshal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CourseDateCombined = (_class = class CourseDateCombined {
    constructor() {
      _initializerDefineProperty(this, "cardStackId", _descriptor, this);

      _initializerDefineProperty(this, "cardStackTemplateId", _descriptor2, this);

      _initializerDefineProperty(this, "dateString", _descriptor3, this);

      _initializerDefineProperty(this, "hasLesson", _descriptor4, this);

      _initializerDefineProperty(this, "hasNoTemplate", _descriptor5, this);

      _initializerDefineProperty(this, "hasVisibleAnnotations", _descriptor6, this);

      _initializerDefineProperty(this, "hideFromCalendar", _descriptor7, this);

      _initializerDefineProperty(this, "isAfterSchoolYear", _descriptor8, this);

      _initializerDefineProperty(this, "isBeforeSchoolYear", _descriptor9, this);

      _initializerDefineProperty(this, "isForcedOn", _descriptor10, this);

      _initializerDefineProperty(this, "isInsideSchoolYear", _descriptor11, this);

      _initializerDefineProperty(this, "isNewLesson", _descriptor12, this);

      _initializerDefineProperty(this, "isOff", _descriptor13, this);

      _initializerDefineProperty(this, "isOffByDefault", _descriptor14, this);

      _initializerDefineProperty(this, "showLesson", _descriptor15, this);

      _initializerDefineProperty(this, "showNoticeOfDanglingLesson", _descriptor16, this);

      _initializerDefineProperty(this, "unitEnd", _descriptor17, this);

      _initializerDefineProperty(this, "unitIds", _descriptor18, this);

      _initializerDefineProperty(this, "unitStart", _descriptor19, this);

      _initializerDefineProperty(this, "visibleAnnotations", _descriptor20, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cardStackId", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cardStackTemplateId", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateString", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hasLesson", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasNoTemplate", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasVisibleAnnotations", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hideFromCalendar", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isAfterSchoolYear", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isBeforeSchoolYear", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isForcedOn", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isInsideSchoolYear", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isNewLesson", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isOff", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isOffByDefault", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showLesson", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "showNoticeOfDanglingLesson", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "unitEnd", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "unitIds", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "unitStart", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "visibleAnnotations", [_marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class);
  _exports.default = CourseDateCombined;
});