define("cc-frontend/helpers/start-case", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startCase = startCase;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function startCase(params) {
    return _lodash.default.startCase(params[0]);
  }

  class StartCase extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "compute", startCase);
    }

  }

  _exports.default = StartCase;
});