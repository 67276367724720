define("cc-frontend/components/planner/modals/template-library.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_uru44o",
    "modal-inner": "_modal-inner_uru44o",
    "modal-inner__close": "_modal-inner__close_uru44o"
  };
  _exports.default = _default;
});