define("cc-frontend/lib/actions/planbook/lessons/PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY", ["exports", "cc-frontend/lib/patch-updater", "lodash", "lodash-es", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-activity", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-context"], function (_exports, _patchUpdater, _lodash, _lodashEs, _defaultPlanbookLessonsActivity, _defaultPlanbookLessonsContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePasteAllLessonsForDay = preparePasteAllLessonsForDay;
  _exports.default = _exports.PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY = void 0;

  function preparePasteAllLessonsForDay(payload) {
    return {
      name: PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY.name,
      payload: payload,
      patches: PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY.patches(payload),
      undoPatches: PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY.narrative, payload)
    };
  }

  const PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY = {
    name: "PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY",

    patches(payload) {
      let patches = _lodash.default.compact(_lodash.default.map(payload.cardStacksForCourses, hash => {
        if (hash.oldCardStackId) {
          return _patchUpdater.default.create("card-stack", hash.oldCardStackId).set("attributes.cards", hash.newCardStackAttrs.cards).set("attributes.title", hash.newCardStackAttrs.title).patch;
        }
      }));

      let firstEditPatches = payload.firstEditPatches || [];
      return firstEditPatches.concat(patches);
    },

    undoPatches(payload) {
      let patches = _lodash.default.compact(_lodash.default.map(payload.cardStacksForCourses, hash => {
        if (hash.oldCardStack) {
          return _patchUpdater.default.create("card-stack", hash.oldCardStack.id).set("attributes.cards", hash.oldCardStack.attributes.cards).set("attributes.title", hash.oldCardStack.attributes.title).patch;
        }
      }));

      let firstEditUndoPatches = payload.firstEditUndoPatches || [];
      return firstEditUndoPatches.concat(patches);
    },

    narrative(payload, findFn, userId) {
      let planbook = findFn("planbook", payload.planbook.id); // @ts-ignore no types for moment

      let formattedDate = moment(payload.dateString).format("ddd, MMM. D, YYYY");
      return {
        title: "Pasted an entire day of lessons",
        titleForAnalytics: "Lesson - Paste Entire Day",
        description: `Pasted a day's worth of lessons on ${formattedDate}`,
        context: (0, _defaultPlanbookLessonsContext.default)(planbook, payload),
        activity: (0, _defaultPlanbookLessonsActivity.default)(payload.planbook.id, userId)
      };
    }

  };
  _exports.PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY = PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY;
  var _default = PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY;
  _exports.default = _default;
});