define("cc-frontend/models/date-annotation-course-custom-single", ["exports", "cc-frontend/lib/structor"], function (_exports, _structor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateAnnotationCourseCustomSingleAttributes extends _structor.default {
    defineStruct() {
      this.field("title");
      this.field("description", "<p></p>");
      this.field("dateString");
      this.field("isOff");
      this.field("showAnnotation", false);
    }

  }

  _exports.default = DateAnnotationCourseCustomSingleAttributes;
});