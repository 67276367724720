define("cc-frontend/components/planner/menus/share-link/confirm-link-sharing-body", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="g-dropdown-menu__subheader --left-aligned tw-text-left tw-mt-4"
    data-test-link-sharing-menu
  >
    To share the link, you need to turn on Link Sharing. Link Sharing lets your planbook be visible to
    anyone you share the link with.
  </div>
  <div class="tw-flex tw-flex-col">
    <Planner::Menus::Parts::OutlineButton
      @onClick={{this.confirmButton}}
      data-test-link-sharing-button
    >
      Yes, Turn on Link Sharing
    </Planner::Menus::Parts::OutlineButton>
    <Planner::Menus::Parts::OutlineButton @isLinkStyle={{true}} @onClick={{this.cancelButton}}>
      No, Keep Link Sharing off
    </Planner::Menus::Parts::OutlineButton>
  </div>
  */
  {
    "id": "KrIcj691",
    "block": "[[[10,0],[14,0,\"g-dropdown-menu__subheader --left-aligned tw-text-left tw-mt-4\"],[14,\"data-test-link-sharing-menu\",\"\"],[12],[1,\"\\n  To share the link, you need to turn on Link Sharing. Link Sharing lets your planbook be visible to\\n  anyone you share the link with.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"tw-flex tw-flex-col\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-link-sharing-button\",\"\"]],[[\"@onClick\"],[[30,0,[\"confirmButton\"]]]],[[\"default\"],[[[[1,\"\\n    Yes, Turn on Link Sharing\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,0],null,[[\"@isLinkStyle\",\"@onClick\"],[true,[30,0,[\"cancelButton\"]]]],[[\"default\"],[[[[1,\"\\n    No, Keep Link Sharing off\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"planner/menus/parts/outline-button\"]]",
    "moduleName": "cc-frontend/components/planner/menus/share-link/confirm-link-sharing-body.hbs",
    "isStrictMode": false
  });

  let ConfirmLinkSharingBodyComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = (_class2 = class ConfirmLinkSharingBodyComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "planbookId", void 0);

      _defineProperty(this, "goToLink", void 0);

      _defineProperty(this, "goBack", void 0);

      _initializerDefineProperty(this, "rpc", _descriptor, this);
    }

    //actions
    confirmButton() {
      this.rpc.planbook_collaboration_change_public_permission({
        planbookId: this.planbookId,
        role: "VIEWER"
      });
      this.goToLink();
    }

    cancelButton() {
      this.rpc.planbook_collaboration_change_public_permission({
        planbookId: this.planbookId,
        role: "NONE"
      });
      this.goBack();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "confirmButton", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "confirmButton"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancelButton", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelButton"), _class2.prototype)), _class2)) || _class);
  _exports.default = ConfirmLinkSharingBodyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ConfirmLinkSharingBodyComponent);
});