define("cc-frontend/components/planner/google-classroom/parts/class-settings.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "class-settings__wrapper": "_class-settings__wrapper_u513t6",
    "--is-posting-immediately": "_--is-posting-immediately_u513t6",
    "class-settings": "_class-settings_u513t6",
    "left-side": "_left-side_u513t6",
    "right-side": "_right-side_u513t6",
    "status-icon": "_status-icon_u513t6",
    "class-name-block": "_class-name-block_u513t6",
    "class-name": "_class-name_u513t6",
    "class-status": "_class-status_u513t6",
    "class-settings__buttons": "_class-settings__buttons_u513t6",
    "btn-schedule": "_btn-schedule_u513t6",
    "--enabled": "_--enabled_u513t6",
    "--basic": "_--basic_u513t6",
    "btn-schedule__info": "_btn-schedule__info_u513t6",
    "btn-schedule__text": "_btn-schedule__text_u513t6",
    "btn-schedule__text__status": "_btn-schedule__text__status_u513t6",
    "btn-schedule__text__date-time": "_btn-schedule__text__date-time_u513t6",
    "btn-trash": "_btn-trash_u513t6",
    "dropdown-menu": "_dropdown-menu_u513t6",
    "schedule-time-menu": "_schedule-time-menu_u513t6",
    "schedule-date__header": "_schedule-date__header_u513t6",
    "schedule-time__header": "_schedule-time__header_u513t6",
    "schedule-date": "_schedule-date_u513t6",
    "schedule-time": "_schedule-time_u513t6",
    "post-warning-box": "_post-warning-box_u513t6",
    "post-warning-message": "_post-warning-message_u513t6"
  };
  _exports.default = _default;
});