define("cc-frontend/components/planner/card-stacks/cards/card-value-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::CardStacks::Cards::Parts::TopPart>
    <Planner::CardStacks::Cards::Parts::MoveButton
      @hideMoveButton={{@hideMoveButton}}
      @permissions={{@permissions}}
    />
    <Planner::CardStacks::Cards::Parts::TextBlock>
      <Planner::CardStacks::Cards::Parts::HtmlValue
        @modelId={{@card.id}}
        @value={{@card.attributes.value}}
        @permissions={{@permissions}}
        @cardStackId={{@cardStackId}}
        @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
      />
      <Planner::CardStacks::Cards::Parts::Attachments
        @card={{@card}}
        @cardStackId={{@cardStackId}}
        @permissions={{@permissions}}
        @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
      />
      <!-- Comments -->
      <Planner::CardStacks::Cards::Parts::Comments
        @comments={{@card.attributes.comments}}
        @cardStackId={{@cardStackId}}
        @cardId={{@card.id}}
        @permissions={{@permissions}}
        @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
      />
    </Planner::CardStacks::Cards::Parts::TextBlock>
  </Planner::CardStacks::Cards::Parts::TopPart>
  */
  {
    "id": "LPtEj6dK",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@hideMoveButton\",\"@permissions\"],[[30,1],[30,2]]],null],[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@modelId\",\"@value\",\"@permissions\",\"@cardStackId\",\"@modelsNeededForFirstEdit\"],[[30,3,[\"id\"]],[30,3,[\"attributes\",\"value\"]],[30,2],[30,4],[30,5]]],null],[1,\"\\n    \"],[8,[39,4],null,[[\"@card\",\"@cardStackId\",\"@permissions\",\"@modelsNeededForFirstEdit\"],[[30,3],[30,4],[30,2],[30,5]]],null],[1,\"\\n    \"],[3,\" Comments \"],[1,\"\\n    \"],[8,[39,5],null,[[\"@comments\",\"@cardStackId\",\"@cardId\",\"@permissions\",\"@modelsNeededForFirstEdit\"],[[30,3,[\"attributes\",\"comments\"]],[30,4],[30,3,[\"id\"]],[30,2],[30,5]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@hideMoveButton\",\"@permissions\",\"@card\",\"@cardStackId\",\"@modelsNeededForFirstEdit\"],false,[\"planner/card-stacks/cards/parts/top-part\",\"planner/card-stacks/cards/parts/move-button\",\"planner/card-stacks/cards/parts/text-block\",\"planner/card-stacks/cards/parts/html-value\",\"planner/card-stacks/cards/parts/attachments\",\"planner/card-stacks/cards/parts/comments\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/card-value-html.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});