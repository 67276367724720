define("cc-frontend/components/planner/planbook/create-edit/main.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "main-screen": "_main-screen_q8ib6h _main-screen_1o1brh",
    "nav-box-wrapper": "_nav-box-wrapper_q8ib6h _nav-box--base_1o1brh",
    "EVALUATE_TEMPLATES": "_EVALUATE_TEMPLATES_q8ib6h",
    "STATE_EDIT_TEMPLATE": "_STATE_EDIT_TEMPLATE_q8ib6h",
    "STATE_COURSE_ONBOARDING": "_STATE_COURSE_ONBOARDING_q8ib6h",
    "STATE_TEMPLATES_ONBOARDING": "_STATE_TEMPLATES_ONBOARDING_q8ib6h",
    "STATE_AFTER_APPLYING_FIRST_TEMPLATE": "_STATE_AFTER_APPLYING_FIRST_TEMPLATE_q8ib6h",
    "STATE_SCHEDULE_ONBOARDING": "_STATE_SCHEDULE_ONBOARDING_q8ib6h",
    "nav-box__top--inherited": "_nav-box__top--inherited_q8ib6h _nav-box__top_1o1brh",
    "nav-box__top": "_nav-box__top_q8ib6h",
    "nav-box__head": "_nav-box__head_q8ib6h _nav-box__head_1o1brh",
    "--evaluate-templates": "_--evaluate-templates_q8ib6h",
    "nav-box__subhead": "_nav-box__subhead_q8ib6h _nav-box__subhead_1o1brh",
    "--list": "_--list_q8ib6h",
    "nav-box--grid": "_nav-box--grid_q8ib6h",
    "nav-box--edit-template-grid": "_nav-box--edit-template-grid_q8ib6h",
    "evaluate-button": "_evaluate-button_q8ib6h",
    "back-button": "_back-button_q8ib6h",
    "edit-template": "_edit-template_q8ib6h",
    "nav-box__main": "_nav-box__main_q8ib6h",
    "nav-box__back-arrow": "_nav-box__back-arrow_q8ib6h d-s-shadowed-button",
    "nav-box__next-arrow": "_nav-box__next-arrow_q8ib6h d-s-shadowed-button",
    "nav-box__next-arrow-wrapper": "_nav-box__next-arrow-wrapper_q8ib6h",
    "nav-box__back-arrow-wrapper": "_nav-box__back-arrow-wrapper_q8ib6h",
    "nav-box__evaluate-buttons": "_nav-box__evaluate-buttons_q8ib6h",
    "default-focus": "_default-focus_q8ib6h",
    "evaluate-button__icon": "_evaluate-button__icon_q8ib6h",
    "evaluate-button__text": "_evaluate-button__text_q8ib6h",
    "final-screen": "_final-screen_q8ib6h",
    "final-screen__video": "_final-screen__video_q8ib6h",
    "nav-box--final-screen": "_nav-box--final-screen_q8ib6h"
  };
  _exports.default = _default;
});