define("cc-frontend/components/kb/contact-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="kb-header-wrapper kb-header-wrapper--home">
    <div class="kb-nav-bar kb-nav-bar--book">
      <div class="kb-nav-bar__left-side">
        <LinkTo @route="help" class="kb-nav-bar__logo">
          <Icons::CcWordMark />
        </LinkTo>
      </div>
      <div class="kb-nav-bar__right-side">
        {{! <div class="kb-nav-bar__btn kb-nav-bar__btn--link">Back to Cc</div> }}
      </div>
    </div>
    <div class="kb-home-header">
      <h1 class="kb-home-header__title">What's going on?</h1>
      <div class="kb-home-header__search-bar">
        <Kb::Search::ContactUs @searchBarClass="kb-search--contact-us" />
      </div>
      <div class="kb-contact-us__button">
  
      </div>
    </div>
  </div>
  */
  {
    "id": "O9cTdace",
    "block": "[[[10,0],[14,0,\"kb-header-wrapper kb-header-wrapper--home\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"kb-nav-bar kb-nav-bar--book\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"kb-nav-bar__left-side\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"kb-nav-bar__logo\"]],[[\"@route\"],[\"help\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],null,null,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"kb-nav-bar__right-side\"],[12],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"kb-home-header\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"kb-home-header__title\"],[12],[1,\"What's going on?\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"kb-home-header__search-bar\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@searchBarClass\"],[\"kb-search--contact-us\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"kb-contact-us__button\"],[12],[1,\"\\n\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link-to\",\"icons/cc-word-mark\",\"kb/search/contact-us\"]]",
    "moduleName": "cc-frontend/components/kb/contact-us.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});