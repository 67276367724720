define("cc-frontend/templates/admin/user-actions/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HniwlJoj",
    "block": "[[[10,0],[14,0,\"col-md-6\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"\\n    Action\\n  \"],[13],[1,\"\\n  \"],[10,\"pre\"],[12],[1,\"    \"],[10,\"code\"],[14,0,\"language-json\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[[33,1,[\"attributes\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"col-md-6\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"\\n    Patches\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"    \"],[1,[28,[35,0],[[30,1,[\"content\",\"attributes\"]]],null]],[1,\"\\n\"]],[1]],null],[13]],[\"patch\"],false,[\"json-stringify\",\"model\",\"each\",\"-track-array\",\"patches\"]]",
    "moduleName": "cc-frontend/templates/admin/user-actions/action.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});