define("cc-frontend/lib/actions/lesson/LESSON_CARD_GC_UNSCHEDULE_CLASS", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareGCUnscheduleClass;

  function prepareGCUnscheduleClass(payload) {
    return {
      name: "LESSON_CARD_GC_UNSCHEDULE_CLASS",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodash.partial)(narrative, payload)
    };
  }

  const patches = function (payload) {
    let removeOld = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.googleCardId
    }).pull("embedded", "attributes.classSettings", {
      googleClassId: payload.classSetting.googleClassId
    }).inc("embedded", "attributes._revision", 1).patch;

    let addNew = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.googleCardId
    }).pull("embedded", "attributes.enabledGoogleClassIds", payload.classSetting.googleClassId).push("embedded", "attributes.classSettings", payload.classSetting).inc("embedded", "attributes._revision", 1).patch;

    return [removeOld, addNew];
  };

  const narrative = function (payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    return {
      title: "Scheduled a Google Classroom Class",
      titleForAnalytics: "Lesson Card - GC Post - Unschedule",
      description: `Unscheduled "${payload.className}" from posting on "${payload.dateString}".`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models, "deleted")
    };
  };
});