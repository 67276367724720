define("cc-frontend/components/planner/planbook/create-edit/templates-by-name/course-pill.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "course-pill": "_course-pill_2odqgb",
    "--is-selected": "_--is-selected_2odqgb",
    "course-pill__icon": "_course-pill__icon_2odqgb",
    "course-pill__title": "_course-pill__title_2odqgb"
  };
  _exports.default = _default;
});