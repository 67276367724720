define("cc-frontend/components/planner/planbook/collaborator", ["exports", "@glimmer/component", "cc-frontend/app", "cc-frontend/helpers/find-document"], function (_exports, _component, _app, _findDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="planbook-collaborators__row planbook-collaborators__row--with-underline">
    <div class="planbook-collaborators__name-col">
      <div class="planbook-collaborators__existing-collab-name tw-leading-none">
        {{#if (eq @collaborator.type "planbook-collaborator-group")}}
          {{this.group.attributes.title}}
        {{/if}}
        {{#if (eq @collaborator.type "planbook-collaborator-user")}}
          {{this.user.attributes.firstName}}
          {{this.user.attributes.lastName}}
        {{/if}}
        {{#if (eq @collaborator.type "planbook-collaborator-email")}}
          {{@collaborator.attributes.email}}
          <br />
          <span class="tw-text-lg tw-text-grey">
            Invite Email sent!
          </span>
        {{/if}}
      </div>
    </div>
    {{#if @canChangeCollaborators}}
      <div class="planbook-collaborators__role-col">
        <Group::PlanbookCollaboratorSelect
          @collaborator={{@collaborator}}
          @planbookId={{@planbook.id}}
          @triggerClass="planbook-collaborators__select-trigger planbook-collaborators__select-trigger--no-underline"
        />
      </div>
      <div class="planbook-collaborators__delete-col">
        <div
          class="planbook-collaborators__remove"
          {{on "click" (fn this.removeCollaborator @collaborator.id)}}
        >
          {{partial "icons/ios-trash-outline"}}
        </div>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "fagKj/O7",
    "block": "[[[10,0],[14,0,\"planbook-collaborators__row planbook-collaborators__row--with-underline\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"planbook-collaborators__name-col\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"planbook-collaborators__existing-collab-name tw-leading-none\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1,[\"type\"]],\"planbook-collaborator-group\"],null],[[[1,\"        \"],[1,[30,0,[\"group\",\"attributes\",\"title\"]]],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[30,1,[\"type\"]],\"planbook-collaborator-user\"],null],[[[1,\"        \"],[1,[30,0,[\"user\",\"attributes\",\"firstName\"]]],[1,\"\\n        \"],[1,[30,0,[\"user\",\"attributes\",\"lastName\"]]],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[30,1,[\"type\"]],\"planbook-collaborator-email\"],null],[[[1,\"        \"],[1,[30,1,[\"attributes\",\"email\"]]],[1,\"\\n        \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"tw-text-lg tw-text-grey\"],[12],[1,\"\\n          Invite Email sent!\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"planbook-collaborators__role-col\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@collaborator\",\"@planbookId\",\"@triggerClass\"],[[30,1],[30,3,[\"id\"]],\"planbook-collaborators__select-trigger planbook-collaborators__select-trigger--no-underline\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"planbook-collaborators__delete-col\"],[12],[1,\"\\n      \"],[11,0],[24,0,\"planbook-collaborators__remove\"],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"removeCollaborator\"]],[30,1,[\"id\"]]],null]],null],[12],[1,\"\\n        \"],[19,\"icons/ios-trash-outline\",[]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@collaborator\",\"@canChangeCollaborators\",\"@planbook\"],true,[\"if\",\"eq\",\"group/planbook-collaborator-select\",\"on\",\"fn\",\"partial\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/collaborator.hbs",
    "isStrictMode": false
  });

  let PlanbookCollaboratorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _findDocument.default)("args.collaborator.attributes.groupId", "group"), _dec5 = (0, _findDocument.default)("args.collaborator.attributes.userId", "user"), _dec6 = Ember._action, (_class = class PlanbookCollaboratorComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _initializerDefineProperty(this, "dialog", _descriptor3, this);

      _initializerDefineProperty(this, "group", _descriptor4, this);

      _initializerDefineProperty(this, "user", _descriptor5, this);
    }

    removeCollaborator(collaboratorId, event) {
      event.preventDefault();
      Ember.get(this, "dialog").blank("dialogs/confirm-delete-planbook-collaborator", {
        className: "dialog--create-annotation"
      }).then(() => {
        this.rpc.planbook_collaboration_remove_collaborator({
          planbookId: this.args.planbook.id,
          collaboratorId: collaboratorId
        });
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "group", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "removeCollaborator", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeCollaborator"), _class.prototype)), _class));
  _exports.default = PlanbookCollaboratorComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookCollaboratorComponent);
});